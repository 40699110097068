import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { QuantityDropdownInput } from "../../../components"
import { hideModal, changeInvoiceStatus } from "../../../actions"
import moment from "moment"

import "./styles.scss"

const statusMap = {
  Платена: "paid",
  Неплатена: "not-paid",
  "Платена частично": "partially",
}

const reverseStatusMap = {
  paid: "Платена",
  "not-paid": "Неплатена",
  partially: "Платена частично",
}

const InvoiceStatusChangeModal = ({ hideModal, data, changeInvoiceStatus }) => {
  const [status, setStatus] = useState("")
  const [total, setTotal] = useState("")
  useEffect(() => {
    setStatus(reverseStatusMap[data.invoice.status])
  }, [data])

  useEffect(() => {
    if (status === "Платена") {
      const total = data.invoice.products.reduce((acc, val) => {
        return acc + val.total
      }, 0)
      setTotal(total / 100)
    } else if (status === "Неплатена") {
      setTotal(0)
    }
    //eslint-disable-next-line
  }, [status])
  const statusChange = data.invoice.history
    .sort((a, b) => moment(a.createdAt).isBefore(moment(b.createdAt)))
    .find((el) => el.data.prevStatus)
  return (
    <div className="invoice-status-change-modal-container">
      <div className="top-container">
        <h3>Редактиране на статус</h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      {statusChange && (
        <div className="flex-container">
          <div className="input-element">
            <p>Предходен статус</p>
            <Input.Text
              value={reverseStatusMap[statusChange.data.prevStatus]}
              disabled
            />
          </div>
          <div className="input-element">
            <p>Платена сума</p>
            <QuantityDropdownInput
              disabled
              noDropdown
              quantityValue={statusChange.data.Стойност}
              quantityTypeValue={{ value: "" }}
            />
          </div>
        </div>
      )}
      <div className="flex-container">
        <div className="input-element">
          <p>Статус</p>
          <Input.Dropdown
            placeholder="Статус"
            value={status}
            options={["Неплатена", "Платена частично", "Платена"]}
            onChange={(value) => setStatus(value.value)}
          />
        </div>
        <div className="input-element">
          <p>Платена сума</p>
          <QuantityDropdownInput
            disabled={status !== "Платена частично"}
            noDropdown
            quantityValue={total}
            quantityTypeValue={{ value: "" }}
            onSetQuantity={(value) => setTotal(value)}
          />
        </div>
      </div>
      <Button.Raised
        text="Запази промени"
        onClick={() => {
          changeInvoiceStatus({
            _id: data.invoice._id,
            status: statusMap[status],
            total: total,
          })
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  changeInvoiceStatus: (payload) => dispatch(changeInvoiceStatus(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceStatusChangeModal)
