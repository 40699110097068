import React from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import moment from "moment"
import { updateOrderStatus, showModal } from "../../actions"
import "./styles.scss"

const mapStatus = {
  new: { text: "Нова заявка", color: "#F7E05D" },
  ordered: { text: "Направена поръчка", color: "#005EA3" },
  "in-transit": { text: "В транзит - очаква се", color: "#6041ED" },
  delivered: { text: "Доставена", color: "#26ACE2" },
  accrued: { text: "Заприходена поръчка", color: "#25E083" },
  canceled: { text: "Отказана", color: "#FF7590" },
  exported: { text: "Изписана суровина", color: "#E6EEF4" },
}

const StorageRawMaterialsItem = ({
  request,
  onSelect,
  checked,
  showModal,
  popup,
  onOpenPopup,
}) => {
  return (
    <div
      className={`raw-materials-item-container ${checked && "selected"} ${
        request.quantity < request.criticalQuantity && "red-border"
      }`}
    >
      <div className="medium small-font">{request.material}</div>
      <div className="medium">
        {moment(request.createdAt).format("DD.MM.YYYYг.")}
      </div>
      <div className="small">
        {request.quantity} {request.units}.
      </div>
      <div className="medium">
        {moment(request.deliveryDate).format("DD.MM.YYYYг.")}
      </div>
      <div className="medium">{request.from.fullName}</div>
      <div className="medium">
        {request.provider && request.provider.firmName}
      </div>
      <div className="small">
        {request.price && `${request.price.toFixed(2)} лв.`}
      </div>
      <div className="small">
        {!!request.payments.length && `На ${request.payments.length} вноски`}
      </div>
      <div className="medium">
        {request.deliveredAt &&
          moment(request.deliveredAt).format("DD.MM.YYYYг.")}
      </div>
      <div className="big status">
        <div className="status-main">
          <div
            className="status-bar"
            style={{ backgroundColor: mapStatus[request.status].color }}
          />
          {mapStatus[request.status].text}

          {/* <Section.Popup
            ref={(ref) => {
              orderStatus = ref
            }}
            className="profile-status"
            content={
              <StorageRawMaterialsOrderStatus
                statusTypes={mapStatus}
                currentStatus={request.status}
                onClick={(status) => {
                  updateOrderStatus({
                    _id: request._id,
                    status,
                  })
                  orderStatus.hide()
                }}
                onClose={() => orderStatus.hide()}
              />
            }
          > */}
          {request.status !== "new" && (
            <Button.Icon
              name="pen"
              size={14}
              color="#26ACE2"
              className="pen"
              onClick={() => {
                onOpenPopup()
                popup.show()
              }}
            />
          )}
          {/* </Section.Popup> */}
        </div>
        <Button.Icon
          name="timer-clock"
          size={14}
          color="#26ace2"
          className="clock"
          onClick={() =>
            showModal({
              type: "StorageOrderHistory",
              overflow: "visible",
              width: "800px",
              data: request.history,
            })
          }
        />
        <Button.Checkbox noLabel checked={checked} onChange={onSelect} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  updateOrderStatus: (payload) => dispatch(updateOrderStatus(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageRawMaterialsItem)
