import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { changeStatus } from '../../actions'

class StatusDetection extends React.Component {
  constructor(props) {
    super(props)
    this.timeout = null
  }

  componentDidMount() {
    this.firstPrepare()
    this.props.history.listen(() => {
      if (!this.props.user) return
      this.changeStatus('Working')
      this.timeout = setTimeout(() => {
        this.changeStatus('Away')
      }, global.config.timeoutDetection)
    })
  }

  firstPrepare() {
    if (!this.props.user) return
    this.changeStatus('Working')
    this.timeout = setTimeout(() => {
      this.changeStatus('Away')
    }, global.config.timeoutDetection)
  }

  changeStatus(status) {
    if (!this.props.user) return
    if (status === 'Working') {
      clearTimeout(this.timeout)
      this.props.changeStatus({ status: 'Working' })
    }
    if (status === 'Away') {
      this.props.changeStatus({ status: 'Away' })
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => ({
  user: state.user.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  changeStatus: (payload) => dispatch(changeStatus(payload)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StatusDetection)
