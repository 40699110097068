import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import moment from "moment"
import { hideModal, createInquiry, clearResults } from "../../../actions"
import { FilterProducts, ProductListItem, ClientAutocomplete } from "../.."
import "./styles.scss"
import { Divider } from "@mui/material"
import { isBudgetValid, getProductNameKMU } from "../../../utilities/helpers"

const nameMap = {
  Тръба: "Материал",
  КМУ: "Ориентация",
  Резервоар: "Ориентация",
}

const ProductsList = ({ onClose, products = [], onAdd }) => {
  const [selected, setSelected] = React.useState("")
  const getProductVariation = (item) => {
    // let { name } = item.productType
    return item.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")
  }

  return (
    <div className="products-list-container">
      <div className="products-list-container-title">
        <h4>Изберете продукт</h4>
        <div className="close-button">
          <Button.Icon
            onClick={onClose}
            name="plus"
            size={20}
            color="#7E7E7E"
          />
        </div>
      </div>
      <div className="products-list-container-scroll">
        {products.map((product) => {
          let text = product.productType.name
          if (product.productType.name === "Тръба") {
            const typeOfDiameter = product.basicInfo.find(
              (el) => el.name === "Произвежда се по"
            )
            const SN = product.basicInfo.find((el) => el.name === "Коравина")
            if (typeOfDiameter.value === "Вътрешен диаметър") {
              const diameter = product.basicInfo.find(
                (el) => el.name === "Вътрешен диаметър"
              )
              text = `SN${SN?.value}_ID${diameter?.value}`
            } else {
              const diameter = product.basicInfo.find(
                (el) => el.name === "Външен диаметър"
              )
              text = `SN${SN?.value}_OD${diameter?.value}`
            }
          }
          return (
            <div
              className="product-list-item-wrap"
              onClick={() => {
                setSelected(product._id)
              }}
            >
              <div className="product-list-item-title">
                {product?.friendlyNames?.join(", ") || text}
              </div>
              {product.productType.name !== "Тръба" && product.productType.name !== "КМУ" && (
                <div className="product-list-item-variation">
                  {getProductVariation(product)}
                </div>
              )}
              {product.productType.name === "КМУ" && (
                <div className="product-list-item-variation">
                  {getProductNameKMU(product.basicInfo)}
                </div>
              )}
              <div
                className={`radio-circle ${
                  selected === product._id ? "active" : ""
                }`}
              >
                <div className="radio-circle-inner" />
              </div>
            </div>
          )
        })}
      </div>
      <div className="products-list-container-footer">
        <Button.Raised
          disabled={selected === ""}
          onClick={() => {
            onAdd(selected)
          }}
          text="Добави"
          className="button-green"
        />
      </div>
    </div>
  )
}

const AddProductQueryModal = ({ closeModal, create, more, clear }) => {
  const popupRefItem = React.useRef(null)
  const productsSearchRef = React.useRef(null)
  React.useEffect(() => {
    const { width } = productsSearchRef.current.getBoundingClientRect()
    popupRefItem.current.style.width = "290px"
    popupRefItem.current.style.height = "410px"
    popupRefItem.current.style.bottom = "-20px"
    popupRefItem.current.style.display = "none"
    popupRefItem.current.style.left = `${width - 10}px`
  }, [])

  React.useEffect(() => {
    if (more.length) {
      popupRefItem.current.style.display = "block"
    }
  }, [more])

  React.useEffect(() => {
    return () => {}
  })
  const [startDate, setStartDate] = useState(moment().toDate())
  const [client, setClient] = useState("")
  const [object, setObject] = useState("")
  const [budget, setBudget] = useState("")
  const [products, setProducts] = useState([])

  const getProductName = (item) => {
    let { name } = item.productType
    if (nameMap[name]) {
      const basicInfoDetail = item.basicInfo.find(
        (b) => b.name === nameMap[name]
      )
      if (basicInfoDetail) {
        name += ` | ${basicInfoDetail.value}`
      }
    }
    return name
  }

  const getProductVariation = (item) => {
    // let { name } = item.productType
    return item.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")
  }

  const updateProduct = (key, value, index) => {
    const _products = products.slice(0)
    const found = _products[index]
    found[key] = value
    setProducts(_products)
  }

  const removeProduct = (index) => {
    const _products = products.slice(0)
    _products.splice(index, 1)
    setProducts(_products)
  }

  const onClosePopup = () => {
    popupRefItem.current.style.display = "none"
  }
  return (
    <div className="add-product-query-modal-container">
      <div ref={productsSearchRef} className="left-container">
        <h3>Ново запитване</h3>
        <span>Клиент</span>
        <ClientAutocomplete
          onChooseClient={(data) => {
            setClient(data.name)
          }}
          onChange={(event) => setClient(event.target.value)}
        />
        {/* <Input.Text
          placeholder="Име на фирмата"
          value={client}
          onChange={(e) => setClient(e.target.value)}
        /> */}
        <span>Обект</span>
        <Input.Text
          placeholder="Обект"
          value={object}
          onChange={(e) => setObject(e.target.value)}
        />
        <div className="flex-container border budget-date">
          <div className="budget-container">
            <span>Бюджет</span>
            <Input.Text
              placeholder="Бюджет"
              value={budget}
              onChange={(e) => {
                if (isBudgetValid(e.target.value)) {
                  setBudget(e.target.value)
                }
              }}
            />
          </div>
          <div className="date-container">
            <span>Начална дата</span>
            <Input.Datepicker
              placeholder="Дата"
              pickDate={startDate}
              onChange={(date) => setStartDate(moment(date).toDate())}
            />
          </div>
        </div>
        <Divider style={{ margin: "25px 0px" }} />
        <FilterProducts
          onChangeType={() => onClosePopup()}
          onSelect={(product) => {
            const newProducts = products.slice(0)
            newProducts.push({
              _id: product._id,
              name: getProductName(product),
              amount: 0,
              deliveryDate: new Date(),
              variation: getProductVariation(product),
            })
            setProducts(newProducts)
          }}
        />
      </div>
      <div className="right-container">
        <div className="top-right-container">
          <h3>Продукти към запитването:</h3>
          <div
            className="exit-btn"
            onClick={() => {
              clear()
              closeModal()
            }}
          />
        </div>
        <div className="scroll-container">
          {products.map((p, index) => {
            return (
              <ProductListItem
                item={p}
                key={`${p._id}_${index}`}
                index={index}
                onUpdate={updateProduct}
                onRemove={removeProduct}
              />
            )
          })}
        </div>
        <Button.Raised
          text="Добави Запитване"
          onClick={() => {
            create({ client, object, budget, startDate, products })
          }}
        />
      </div>
      <div ref={popupRefItem} className="more-products-popup">
        <ProductsList
          products={more}
          onClose={() => {
            onClosePopup()
          }}
          onAdd={(productID) => {
            const newProducts = products.slice(0)
            const found = more.find((el) => el._id === productID)
            newProducts.push({
              _id: found._id,
              name: getProductName(found),
              amount: 0,
              deliveryDate: startDate,
              variation: getProductVariation(found),
            })
            setProducts(newProducts)
            onClosePopup()
            // this.props.history.push(`/products/${productID}`)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  more: state.products.more,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(hideModal()),
  create: (payload) => dispatch(createInquiry(payload)),
  clear: () => dispatch(clearResults()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProductQueryModal)
