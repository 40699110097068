import React, { Component } from "react"
import { CSSTransition } from "react-transition-group"
import "./styles.scss"

class Popup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shown: false,
      firstShown: true,
    }
  }

  componentDidMount() {
    const altX =
      this.popupRef.getBoundingClientRect().width -
      this.parentRef.getBoundingClientRect().width
    const popupX =
      this.popupRef.getBoundingClientRect().width +
      this.popupRef.getBoundingClientRect().x
    const popupY =
      this.popupRef.getBoundingClientRect().height +
      this.popupRef.getBoundingClientRect().y
    if (document.body.getBoundingClientRect().width < popupX) {
      this.popupRef.style = `left: -${altX}px`
    }
    if (document.body.getBoundingClientRect().height < popupY) {
      this.popupRef.style = `top: -${
        this.popupRef.getBoundingClientRect().height + 10
      }px; left: ${0}px`
    }
    this.setState({ firstShown: false })
  }

  hide() {
    this.setState({ shown: false })
  }

  show() {
    this.setState({ firstShown: true }, () => {
      const altX =
        this.popupRef.getBoundingClientRect().width -
        this.parentRef.getBoundingClientRect().width
      const popupX =
        this.popupRef.getBoundingClientRect().width +
        this.popupRef.getBoundingClientRect().x
      const popupY =
        this.popupRef.getBoundingClientRect().height +
        this.popupRef.getBoundingClientRect().y
      if (document.body.getBoundingClientRect().width < popupX) {
        this.popupRef.style = `left: -${altX}px`
      }
      if (document.body.getBoundingClientRect().height < popupY) {
        this.popupRef.style = `top: -${
          this.popupRef.getBoundingClientRect().height + 10
        }px; left: ${0}px`
      }

      this.setState({ shown: !this.state.shown })
    })
  }

  render() {
    const { wide, content } = this.props
    return (
      <div
        ref={(ref) => {
          this.parentRef = ref
        }}
        className="popup-element"
      >
        {this.props.children}

        <CSSTransition
          in={this.state.shown}
          classNames="popup-animation"
          timeout={300}
        >
          <div
            ref={(ref) => {
              this.popupRef = ref
            }}
            className={`popup ${this.state.firstShown ? "" : "display-none"}`}
            style={wide && { paddingRight: "60px" }}
          >
            {content}
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default Popup
