import React, { useEffect, useRef, useState, useCallback } from "react";
import './styles.scss';

const TimerController = (props) => {
    const [renderedStreamDuration, setRenderedStreamDuration] = useState("00:00:00"),
      streamDuration = useRef(0),
      previousTime = useRef(0),
      requestAnimationFrameId = useRef(null),
      [isStartTimer, setIsStartTimer] = useState(false),
      [isStopTimer, setIsStopTimer] = useState(false),
      [isPauseTimer, setIsPauseTimer] = useState(false),
      [isResumeTimer, setIsResumeTimer] = useState(false),
      isStartBtnDisabled = isPauseTimer || isResumeTimer || isStartTimer,
      isStopBtnDisabled = !(isPauseTimer || isResumeTimer || isStartTimer);
  
    const updateTimer = useCallback(() => {
      let now = performance.now();
      let dt = now - previousTime.current;
  
      if (dt >= 1000) {
        streamDuration.current = streamDuration.current + Math.round(dt / 1000);
        const formattedStreamDuration = new Date(streamDuration.current * 1000)
          .toISOString()
          .substr(11, 8);
        setRenderedStreamDuration(formattedStreamDuration);
        previousTime.current = now;
      }
      requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, []);
  
    const startTimer = useCallback(() => {
      previousTime.current = performance.now();
      requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, [updateTimer]);
  
    useEffect(() => {
      if (props.record === true) {
        startHandler();
      } else {
        stopHandler();
      }
      if (isStartTimer && !isStopTimer) {
        startTimer();
      }
      if (isStopTimer && !isStartTimer) {
        streamDuration.current = 0;
        cancelAnimationFrame(requestAnimationFrameId.current);
        setRenderedStreamDuration("00:00:00");
      }
    }, [isStartTimer, isStopTimer, startTimer, props.record]);
  
    const startHandler = () => {
      setIsStartTimer(true);
      setIsStopTimer(false);
    };
  
    const stopHandler = () => {
      setIsStopTimer(true);
      setIsStartTimer(false);
      setIsPauseTimer(false);
      setIsResumeTimer(false);
    };
  
    return (
      <div className="timer-controller-wrapper">
        <div className="time-recorded">{renderedStreamDuration}</div>
        <div className="text">В момента записвате гласово съобщение</div>
      </div>
    );
  };

export default TimerController;