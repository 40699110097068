import React, { useState } from "react"
import { Paper } from "@mui/material"
import styles from "./ProductionPipes.module.scss"
import PipesMachines from "../PipesMachines/PipesMachines"

export default function ProductionPipes() {
  const [selectedTab, setSelectedTab] = useState(1)
  return (
    <div className={styles["production-container"]}>
      <div className={styles["production-tabs"]}>
        <div
          onClick={() => setSelectedTab(1)}
          className={`${styles.tab} ${selectedTab === 1 && styles.selected}`}
        >
          Тръби - машини
        </div>
        <div
          onClick={() => setSelectedTab(2)}
          className={`${styles.tab} ${selectedTab === 2 && styles.selected}`}
        >
          Тръби - изпълнители
        </div>
        <div
          onClick={() => setSelectedTab(3)}
          className={`${styles.tab} ${selectedTab === 3 && styles.selected}`}
        >
          Краен продукт
        </div>
        <div
          onClick={() => setSelectedTab(4)}
          className={`${styles.tab} ${selectedTab === 4 && styles.selected}`}
        >
          Допълнителни
        </div>
      </div>
      <Paper elevation={2}>
        {selectedTab === 1 && <PipesMachines />}
        {selectedTab === 2 && <PipesMachines contractor={true} />}
      </Paper>
    </div>
  )
}
