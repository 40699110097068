import React from 'react';
import moment from "moment";
import { history } from "../../config/stores"
import { useSelector } from 'react-redux';

export default function InquiryProductionElement({ data }) {
  const requestData = useSelector((state) => state.production.productSchedule)

    return (
        <div className="inquiry-client-element invoice production" key={data._id + 2}
        onClick={() => {
          if (data.type === 'MachineSchedule') window.open(`/production/pipe/${data._id}`)
          else if (data.type === 'FinishingWorks') {
            const finishingWorks = []
            Object.values(requestData).forEach(finishingWorksArray => {
              finishingWorksArray.forEach(finishingWork => {
                if (finishingWork.request) {
                  const index = finishingWorks.findIndex(el => el._id === finishingWork._id)
                  if (index === -1 && finishingWork.request._id === data.request._id) {
                    finishingWorks.push(finishingWork)
                  }
                }
              })
            })

            history.push(`/production/end-product/${data.request._id}`, { data: { ...data.request, finishingWorks: finishingWorks } })
        } 
          }}>
          <div className="element medium">{data?.worker}</div>
          <div className="element medium overflow">{data?.activity}</div>
          <div className="element small">{moment(data?.startsAt || data?.start).format('DD.MM.YYYY') || ''}</div>
          <div className="element medium overflow">
            {data?.coworkers ? data.coworkers : '--'}
          </div>
          <div className="element small overflow">{(data?.quantity || data?.request?.quantity) ? `${data?.quantity || data?.request?.quantity} бр` : ''}</div>
          <div className="element large last">{`${data.type === 'MachineSchedule' ? data?.produce : ''} ${data.type === 'MachineSchedule' ? 'бр' : '--'}`}</div> 
        </div>
      )
}
