import React from 'react'
import './styles.scss'

export default class Text extends React.Component {
  render () {

    return (
      <input {...this.props} className='inputs-text' />
    )
  }
}
