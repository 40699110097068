import React from "react"
import { Box, Button, Modal, Paper, TextField } from "@mui/material"
import styles from "./AllRequestsMenu.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import moment from "moment"
import { history } from "../../config/stores"

const style = {
  position: "absolute",
  top: "55%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 30,
  margin: "10px",
}

export default function AllRequestsMenu({ open, onClose, data }) {
  const finished = data
    .filter((el) => el.status === "finished")
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })
  const inProduction = data
    .filter((el) => el.status === "in-production")
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })
  const pending = data
    .filter((el) => {
      return (
        el.status === "pending" &&
        moment(el.createdAt).isAfter(
          moment("14.10.2021", "DD.MM.YYYY").endOf("day")
        )
      )
    })
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })
  const sortedData = [...pending, ...inProduction, ...finished]
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <Box sx={style}>
        <Paper className={styles["modal-container"]}>
          <div className={styles["modal-header"]}>
            <h3>Заявки</h3>
            <CloseIcon onClick={onClose} />
          </div>
          <div className={styles["modal-content"]}>
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon fontSize="medium" />,
              }}
            />
            <div className={styles["products-filters"]}>
              <Button
                disableElevation
                disableRipple
                variant="text"
                endIcon={<KeyboardArrowDownIcon />}
              >
                Продукт
              </Button>
              <Button
                disableElevation
                disableRipple
                variant="text"
                endIcon={<KeyboardArrowDownIcon />}
              >
                К-во
              </Button>
              <Button
                disableElevation
                disableRipple
                variant="text"
                endIcon={<KeyboardArrowDownIcon />}
              >
                Срок
              </Button>
              <Button
                disableElevation
                disableRipple
                variant="text"
                endIcon={<KeyboardArrowDownIcon />}
              >
                Проекти
              </Button>
            </div>
            <div className={styles["products-container"]}>
              {sortedData.map((product, index) => (
                <Paper
                  onClick={() => {
                    history.push(`/productionv2/edit/${product._id}`)
                  }}
                  key={index}
                  sx={
                    product.status === "pending" && {
                      backgroundColor: "#26ACE2",
                      color: "white",
                    }
                  }
                  className={`${styles["product"]} ${
                    product.status === "in-production" && styles.progress
                  } ${product.status === "finished" && styles.done}`}
                >
                  <p>
                    {product?.product?.friendlyNames.join(", ").substr(0, 15) ||
                      product?.product?.productType?.name}
                  </p>
                  <p>{product.quantity}</p>
                  <p>{moment(product.deadline).format("DD.MM.YYYY")}</p>
                  <p>
                    {" "}
                    {product.inquiry && product.inquiry.object.substr(0, 20)}
                    {product.inquiry && product.inquiry.object.length > 20 && (
                      <span>...</span>
                    )}
                  </p>
                </Paper>
              ))}
            </div>
          </div>
        </Paper>
      </Box>
    </Modal>
  )
}
