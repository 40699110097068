import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { OfferProduct } from "../OfferCreationModal/OffectProduct"
import {
  hideModal,
  searchOfferClients,
  resetResults,
  createProformInvoice,
  editProformInvoice,
  deleteProformInvoice,
  getProductTypes,
  getInquiries,
  editHPRequest,
  createProformStorage,
  editProformStorage,
} from "../../../actions"
import { SingleRadioButton, ClientAutocomplete } from "../../../components"
import moment from "moment"
import "./styles.scss"
import { isNaN } from "lodash"

const getProductValue = (product) => {
  if (product.singleUnitPrice) {
    return product.singleUnitPrice || 0
  } else if (product.expenses) {
    const materialCost = product.expenses.material.reduce((acc, val) => {
      return acc + val.total || 0
    }, 0)
    const staffCost =
      product.expenses.staff &&
      product.expenses.staff.reduce((acc, val) => {
        return acc + val.total
      }, 0)
    const otherCost =
      product.expenses.other &&
      product.expenses.other.reduce((acc, val) => {
        return acc + val.total
      }, 0)
    const customCost =
      product.expenses.custom &&
      product.expenses.custom.reduce((acc, val) => {
        return (
          acc +
          val.elements.reduce((acc, val) => {
            return acc + val.total
          }, 0)
        )
      }, 0)
    return materialCost + staffCost + otherCost + customCost
  } else {
    return 0
  }
}

function ProformInvoiceModal({
  hideModal,
  data,
  clientResults,
  productTypes,
  searchOfferClients,
  createProformStorage,
  editProformStorage,
  editProformInvoice,
  editHPRequest,
  inquiries,
  deleteProformInvoice,
  getList,
}) {
  const [client, setClient] = useState("")
  const [EIK, setEIK] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [reason, setReason] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [products, setProducts] = useState([])
  const [typeOfDeal, setTypeOfDeal] = useState("За проект")
  const [project, setProject] = useState("")
  const [projectID, setProjectID] = useState("")

  useEffect(() => {
    getList()
  }, [getList])

  useEffect(() => {
    if (data?.forEdit) {
      const products = data?.documentsOpen.sales.map((product) => {
        return {
          itemName: product.product,
          singleUnitPrice: product.price,
          deliveryDate: data.deliveryDate,
          quantity: product.quantity,
          total: product.price * product.quantity,
        }
      })
      setProducts(products)
      setBankAccount(data?.proform.bankData?.bankAccount)
      setClient(data?.proform.client.name)
      setEIK(data?.proform.client.uic)
      setPhone(data?.proform.client.phone)
      setEmail(data?.proform.client.email)
      setAddress(data?.proform.client.address)
      setReason(data?.proform.reason)
    }
  }, [data])

  console.log(project)

  return (
    <div className="proform-invoice-hp-container">
      <div className="left-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>
            {data.forEdit
              ? "Редакция на проформа фактура"
              : "Създаване на проформа фактура"}
          </h3>
          <div className="exit-btn" onClick={hideModal} />
        </div>
        <p>Тип продажба</p>
        <div className="flex-container">
          <SingleRadioButton
            label="За проект"
            selected={typeOfDeal === "За проект"}
            onClick={() => {
              setTypeOfDeal("За проект")
            }}
          />
          <SingleRadioButton
            label="Свободна продажба"
            selected={typeOfDeal === "Свободна продажба"}
            onClick={() => setTypeOfDeal("Свободна продажба")}
          />
        </div>
        {typeOfDeal === "За проект" && (
          <div className="input-container">
            <p>Проект</p>
            <Input.Dropdown
              placeholder=""
              value={project}
              
              options={inquiries.map((inquiry) => inquiry.object)}
              onChange={(value) => setProject(value.value)}
            />
          </div>
        )}
        <div className="flex-container space-between">
          <div className="input-element">
            <p>Клиент</p>
            <ClientAutocomplete
              onChooseClient={(data) => {
                setClient(data.name)
                if (data.uic) {
                  setEIK(data.uic)
                } else {
                  setEIK("")
                }
                if (data.phone) {
                  setPhone(data.phone)
                } else {
                  setPhone("")
                }
                if (data.email) {
                  setEmail(data.email)
                } else {
                  setEmail("")
                }
                if (data.address) {
                  setAddress(data.address)
                } else {
                  setAddress("")
                }
              }}
              onChange={(event) => setClient(event.target.value)}
            />
            {/* <div className="input-wrapper">
              <Input.Text
                value={client}
                onChange={(event) => {
                  onChange(event, searchOfferClients)
                  setClient(event.target.value)
                }}
              />
              {clientResults.length > 0 && (
                <div className="client-results-dropdown">
                  {clientResults.map((client) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setAddress(client.address)
                          setEmail(client.email)
                          setClient(client.name)
                          setPhone(client.phone)
                          setEIK(client.uic)
                          resetResults()
                        }}
                      >
                        {client.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div> */}
          </div>
          <div className="input-element">
            <p>ЕИК</p>
            <Input.Text
              value={EIK}
              onChange={(event) => setEIK(event.target.value)}
            />
          </div>
          <div className="input-element">
            <p>Телефон</p>
            <Input.Text
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container space-between">
          <div className="input-element">
            <p>Имейл</p>
            <Input.Text
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="input-element large">
            <p>Адрес</p>
            <Input.Text
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div className="input-element">
          <p>Основание</p>
          <Input.Text
            value={reason}
            onChange={(event) => setReason(event.target.value)}
          />
        </div>
        <div className="input-element small">
          <p>Ваша банкова сметка</p>
          <Input.Dropdown
            placeholder="Моля изберете"
            value={bankAccount}
            options={["ДСК", "УниКредит", "Райфайзен"]}
            onChange={(value) => setBankAccount(value.value)}
          />
        </div>
      </div>
      <div className="right-container">
        <div className="flex-container">
          {data?.forEdit && (
            <Button.Raised
              text="Изтрий документ"
              className="delete-btn"
              onClick={() => {
                deleteProformInvoice({
                  _id: data?.proformaInvoice._id,
                })
              }}
            />
          )}

          <Button.Raised
            text={`${data?.forEdit ? "Редактирай" : "Създай"}`}
            onClick={() => {
              const chosenProducts = products.map((product) => {
                let cost = product.price
                return {
                  itemName: product.product,
                  quantity: parseInt(product.quantity),
                  deliveryDate: product.deliveryDate,
                  singleUnitPrice: product.singleUnitPrice || cost,
                  total: cost * parseInt(product.quantity),
                }
              })
              if (data.forEdit) {
                editProformStorage({
                  _id: data.proform._id,
                  request: data._id,
                  client: {
                    name: client,
                    uic: EIK,
                    phone: phone,
                    email: email,
                    address: address,
                    attentionOf: client,
                  },
                  reason: reason,
                  bankData: {
                    bankAccount: bankAccount,
                  },
                  products: chosenProducts,
                })
              } else {
                const products = data.sales.map((product) => {
                  let cost = product.price
                  return {
                    itemName: product.product,
                    quantity: parseInt(product.quantity),
                    deliveryDate: data.deliveryDate,
                    singleUnitPrice: cost,
                    total: cost * parseInt(product.quantity),
                  }
                })
                createProformStorage({
                  ...(typeOfDeal === "За проект" && {
                    inquiry: inquiries.find(
                      (inquiry) => inquiry.object === project
                    )._id,
                  }),
                  request: data._id,
                  client: {
                    name: client,
                    uic: EIK,
                    phone: phone,
                    email: email,
                    address: address,
                    attentionOf: client,
                  },
                  reason: reason,
                  bankData: {
                    bankAccount: bankAccount,
                  },
                  products: products,
                })
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiries: state.inquiries.data,
  data: state.modal.data,
  productTypes: state.products.types,
  clientResults: state.inquiries.clientResults,
})

const mapDispatchToProps = (dispatch) => ({
  createProformStorage: (payload) => dispatch(createProformStorage(payload)),
  editProformStorage: (payload) => dispatch(editProformStorage(payload)),
  hideModal: () => dispatch(hideModal()),
  getProductTypes: () => dispatch(getProductTypes()),
  getList: () => dispatch(getInquiries()),
  searchOfferClients: (payload) => dispatch(searchOfferClients(payload)),
  resetResults: () => dispatch(resetResults()),
  createProformInvoice: (payload) => dispatch(createProformInvoice(payload)),
  editProformInvoice: (payload) => dispatch(editProformInvoice(payload)),
  deleteProformInvoice: (payload) => dispatch(deleteProformInvoice(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProformInvoiceModal)
