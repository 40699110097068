import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { ContactsTypes, GeneralTypes } from "../actions"
import { API_URL } from "../config/settings"

export const getContactsEpic = (action$) => {
  return action$.pipe(
    ofType(ContactsTypes.GET_CONTACTS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/businessCard/browse`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response)
            obs.next({
              type: ContactsTypes.GET_CONTACTS_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getCurrentContactEpic = (action$) => {
  return action$.pipe(
    ofType(ContactsTypes.GET_SINGLE_CONTACT),
    switchMap(({ payload }) => {
      return Api.get(`${API_URL}/businessCard/${payload?._id}`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response)
            obs.next({
              type: ContactsTypes.GET_SINGLE_CONTACT_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addContactEpic = (action$) => {
  return action$.pipe(
    ofType(ContactsTypes.ADD_CONTACT),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/businessCard`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response)
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editContactEpic = (action$) => {
  return action$.pipe(
    ofType(ContactsTypes.EDIT_CONTACT),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/businessCard`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response)
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteContactEpic = (action$) => {
  return action$.pipe(
    ofType(ContactsTypes.DELETE_CONTACT),
    switchMap(({ payload }) => {
      return Api.delete(`${API_URL}/businessCard`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response)
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}