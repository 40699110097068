import React from "react"
import "./styles.scss"
import _ from "lodash"
import { Input } from "@makedonski/admin-ui"
import { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getMachineActivity, showLoading } from "../../actions"
import moment from "moment"

export default function CommentsActivityDashboard({ id, tableData, type, quantity, comments, dashboardSection, setDashboardSection, onClose, onSend, activityHistory }) {
  const dispatch = useDispatch();
  const newestComment = useRef();
  const activityRef = useRef();

  const savedActivity = useSelector((state) => state.production.savedMachineActivity)
  const nextPage = useSelector((state) => state.production.nextPage)


  const [ commentQuery, setCommentQuery ] = useState('');


  const handleScrolling = () => {
    if (activityRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = activityRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && nextPage && scrollTop !== 0) {
        loadPageOnScroll()
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  const loadPageOnScroll = () => {
    let payload = { request: id, pageNumber: nextPage, pageSize: 10 }

    dispatch(showLoading())
    dispatch(getMachineActivity(payload)) 
  }

  // useEffect(() => {
  //   newestComment.current.scrollIntoView({behavior: 'smooth'});
  // }, [comments])

  useEffect(() => {

    if (activityHistory) {
      dispatch(showLoading())
      dispatch(getMachineActivity({ request: id, pageNumber: 1 }))
    }
  }, [])

  return (
    <div className="dashboard-tab-section-wrapper">
      <div className="dashboard-top-wrapper">
        <div className="headers-wrapper">
          <h3
            className={dashboardSection === "comments" ? "selected" : "comment"}
            onClick={() => setDashboardSection("comments")}
          >
            Коментари
          </h3>
          <h3
            className={
              dashboardSection === "activity" ? "selected" : "activity"
            }
            onClick={() => setDashboardSection("activity")}
          >
            Активност
          </h3>
        </div>
        <img
          src={require("../../assets/icons/closeIcon.svg")}
          className="close-icon"
          onClick={onClose}
        />
      </div>

      <div className="dashboard-main-wrapper">
        {dashboardSection === "comments" && (
          <div className="tab-wrapper comments">
            <div className="comments-layout top">
                {comments?.map((comment, index) => {
                    return (
                    <div 
                    ref={index === comments.length - 1 ? newestComment : null}
                    className={comment.commentType === 'audio' ? 'recorded-wrapper' : 'comment-wrapper'}>
                        {comment.commentType === 'audio' && 
                        <>
                        <div className="text-wrapper">
                            <div className="comment-title">Гласово съобщение</div>
                            <div className="date">{moment(comment?.updatedAt).format('DD.MM.YYYY, HH:mm')}</div>
                        </div>
                        
                        <audio
                        onEnded={() => {
                          if (document.getElementById(`playAudio${index}`)?.classList.contains("fa-pause")) {
                            document.getElementById(`playAudio${index}`).classList.add("fa-play");
                            document.getElementById(`playAudio${index}`).classList.remove("fa-pause");
                          }
                        }}
                         controls
                         controlsList="nodownload"
                         type="audio/webm"
                         src={comment?.url || ""}></audio>
                        </>}

                         {comment.commentType === 'text' && 
                         <>
                         <div className="text-wrapper">
                           <div className="comment-title">{comment.owner.fullName}</div>
                           <div className="date">{moment(comment?.updatedAt).format('DD.MM.YYYY, HH:mm')}</div>
                         </div>

                         <div className="comment-wrapper">{comment.text}</div>
                         </>}
                    </div>)
                })}
            </div>

            <div className="comments-layout bottom">
              <div className="message-box-wrapper">
                <Input.Text placeholder="Коментар" value={commentQuery} onChange={(e) => setCommentQuery(e.target.value)} />
                <img
                  src={require("../../assets/icons/send-message.svg")}
                  className="send-icon"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onSend(commentQuery, comments[0]?.machineSceduleWork)
                      setCommentQuery('')
                    }
                  }}
                  onClick={() => {
                    onSend(commentQuery, comments[0]?.machineSceduleWork)
                    setCommentQuery('')
                }} 
                />
              </div>
            </div>
          </div>
        )}

        {dashboardSection === "activity" && (
        <div className="tab-wrapper activity" ref={activityRef} onScroll={delayedScrolling}>
          {!activityHistory && tableData?.map((activity, index) => {
            return (          
          <div className='single-activity-wrapper'>
            <div className='icon-date-wrapper'>
              <img src={require('../../assets/icons/blueCircle.svg')} />
              <div className='activity-date'>{moment(activity.createdAt).format('HH:mm | DD.MM.YYYY')}</div>
            </div>

            <div className='activity-description'> 
             <span>{activity?.performers?.join(', ')}</span>
             {` завърши ${tableData.length - index} / ${quantity} бр. (тегло: ${activity?.weight} кг, дължина: ${activity?.length} м): ${comments[index]?.text || 'Гласово съобщение'}`}
             {/* {`${<span>{comments[index]?.owner?.fullName || ''}</span>} завърши ${tableData.length - index} / ${quantity} бр.: ${comments[index]?.text || ''}`} */}
            </div>
          </div>)
          })}


          {activityHistory && type !== 'other-request' && savedActivity?.filter(el => el?.request === id)?.map((activity, index) => {
            return (          
          <div className='single-activity-wrapper'>
            <div className='icon-date-wrapper'>
              <img src={require('../../assets/icons/blueCircle.svg')} />
              <div className='activity-date'>{moment(activity.createdAt).format('HH:mm | DD.MM.YYYY')}</div>
            </div>

            <div className='activity-description'> 
             <span>{activity?.user?.fullName}: &nbsp;</span>
             {activity?.title === 'промени дейност' ?
              activity?.title :
             `създаде заявка с ${activity?.metadata?.finishingWorks?.length} дейности`}

             <div className="finishing-works-wrapper">
              {!activity?.metadata?.machineSettings && 
              activity?.metadata?.finishingWorks?.map((el, index) => {
                return (
                  <div className="finishing-work">
                    <h3 className="title">{`Дейност ${index + 1}`}</h3>
                    <div className="finishing-work-row"><span className="light">Име: &nbsp;</span> {`${el?.activity}`}</div>
                    <div className="finishing-work-row"><span className="light">Ден: &nbsp;</span>{moment(el?.startsAt).format('DD.MM.YYYY')}</div>
                    <div className="finishing-work-row"><span className="light">Час: &nbsp;</span>{moment(el?.startsAt).format('HH:mm')}</div>
                    <div className="finishing-work-row"><span className="light">Времетраене: &nbsp;</span>{`${Math.floor(el?.durationMinutes / 60)}ч ${el?.durationMinutes % 60}мин`}</div>
                    <div className="finishing-work-row"><span className="light">Изпълнители: &nbsp;</span>{el?.performers?.join(', ') || ''}</div>
                    <div className="finishing-work-row">
                      {el?.materials?.map((material, matIndex) => {
                        return (
                          <div className="finishing-work-row">
                            <span className="light">{`Материал ${matIndex + 1}`}: </span>
                            {`${material?.name} | ${material?.quantity} бр.`}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}

              {activity?.metadata?.machineSettings &&
              <div className="finishing-work">
                {/* <h3 className="title">{`Дейност ${index + 1}`}</h3> */}
                <div className="finishing-work-row"><span className="light">Име: &nbsp;</span> {`${activity?.metadata?.activity}`}</div>
                <div className="finishing-work-row"><span className="light">Ден: &nbsp;</span>{moment(activity?.metadata?.start).format('DD.MM.YYYY')}</div>
                <div className="finishing-work-row"><span className="light">Час: &nbsp;</span>{moment(activity?.metadata?.start).format('HH:mm')}</div>
                <div className="finishing-work-row"><span className="light">Времетраене: &nbsp;</span>{`${Math.floor(activity?.metadata?.duration / 60)}ч ${activity?.metadata?.duration % 60}мин`}</div>
                <div className="finishing-work-row"><span className="light">Изпълнители: &nbsp;</span>{activity?.metadata?.performers?.join(', ') || ''}</div>
                <div className="finishing-work-row">
                  {activity?.metadata?.machineSettings?.materials?.map((material, matIndex) => {
                    return (
                      <div className="finishing-work-row">
                        <span className="light">{`Материал ${matIndex + 1}`}: </span>
                        {`${material?.name} | ${material?.ratio} %`}
                      </div>
                    )
                  })}
                </div>
              </div>}
             </div>
            </div>
          </div>)
          })}

          {activityHistory && type === 'other-request' && savedActivity?.filter(el => el?.request === id)?.map((activity, index) => {
            return (
              <div className='single-activity-wrapper'>
              <div className='icon-date-wrapper'>
                <img src={require('../../assets/icons/blueCircle.svg')} />
                <div className='activity-date'>{moment(activity.createdAt).format('HH:mm | DD.MM.YYYY')}</div>
              </div>
  
              <div className='activity-description'> 
               <span>{activity?.user?.fullName}: &nbsp;</span>
               {activity?.title === 'промени дейност' ?
                activity?.title :
               `създаде заявка с ${activity?.metadata?.finishingWorks?.length} дейности`}
  
               <div className="finishing-works-wrapper">
               <div className="finishing-work">
                  {/* <h3 className="title">{`Дейност ${index + 1}`}</h3> */}
                  <div className="finishing-work-row"><span className="light">Име: &nbsp;</span> {`${activity?.metadata?.activity}`}</div>
                  <div className="finishing-work-row"><span className="light">Описание: &nbsp;</span> {`${activity?.metadata?.description}`}</div>
                  <div className="finishing-work-row"><span className="light">Коментар: &nbsp;</span> {`${activity?.metadata?.comment}`}</div>
                  <div className="finishing-work-row"><span className="light">Ден: &nbsp;</span>{moment(activity?.metadata?.start).format('DD.MM.YYYY')}</div>
                  <div className="finishing-work-row"><span className="light">Час: &nbsp;</span>{moment(activity?.metadata?.start).format('HH:mm')}</div>
                  <div className="finishing-work-row"><span className="light">Изпълнители: &nbsp;</span>{activity?.metadata?.performers?.join(', ') || ''}</div>
                </div>
               </div>
              </div>
            </div>
            )
          })}

          <div className='line-wrapper' />
        </div>
        )}
      </div>
    </div>
  )
}
