import React, { useState } from "react"
import { connect } from "react-redux"
import { searchSuppliers } from "../../actions"
import _ from "lodash"
import "./styles.scss"

const onSearchInit = (event, searchSuppliers) => {
  event.persist()
  onSearchDelayed(event, searchSuppliers)
}

const onSearch = (event, searchSuppliers) => {
  searchSuppliers({ firmName: event.target.value })
}

const onSearchDelayed = _.debounce(
  (event, searchSuppliers) => onSearch(event, searchSuppliers),
  300
)

const SupplierAutocomplete = ({
  searchSuppliers,
  searchedSuppliers,
  onChooseClient,
  onChange,
}) => {
  const [filter, setFilter] = useState("")
  const [focus, setFocus] = useState(false)
  return (
    <div className="client-autocomplete-container">
      <input
        type="text"
        placeholder="Клиент"
        value={filter}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setTimeout(() => {
            setFocus(false)
          }, 150)
        }}
        onChange={(event) => {
          setFilter(event.target.value)
          onSearchInit(event, searchSuppliers)
          onChange(event)
        }}
      />
      {focus && searchedSuppliers.length > 0 && (
        <div className="clients-dropdown">
          {searchedSuppliers.map((client) => {
            return (
              <div
                className="client-option"
                onClick={() => {
                  setFilter(client.firmName)
                  onChooseClient(client)
                }}
              >
                {client.firmName}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  searchedSuppliers: state.references.searchedSuppliers,
})

const mapDispatchToProps = (dispatch) => ({
  searchSuppliers: (payload) => dispatch(searchSuppliers(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierAutocomplete)
