import React, { useState, useEffect } from "react"
import { Input } from "@makedonski/admin-ui"
import "./styles.scss"
import { API_URL } from "../../../../config/settings"

function ActProduct({ product, productTypes, onQuantityChange, forEdit }) {
  const [quantity, setQuantity] = useState(1)

  useEffect(() => {
    if (forEdit) {
      setQuantity(product.quantity)
    }
    //eslint-disable-next-line
  }, [])

  let infoString = product.item
    ? product.item.basicInfo.map((el) => el.value).join(" ")
    : product.basicInfo
    ? product.basicInfo.map((el) => el.value).join(" ")
    : ""

  return (
    <div className="act-product-container">
      <div className="left-part">
        <div className="flex-container">
          <p id="bold">
            {Boolean(product.item)
              ? product?.item?.friendlyNames.length > 0
                ? product?.item?.friendlyNames?.join(", ")
                : productTypes.length > 0 &&
                  productTypes.find(
                    (type) => type._id === product.item.productType?._id
                  )?.name
              : product?.friendlyNames.length > 0
              ? product?.friendlyNames?.join(", ")
              : productTypes.length > 0 &&
                productTypes.find(
                  (type) => type._id === product.productType?._id
                ).name}
          </p>
          <div
            className="product-icon"
            onClick={() => window.open(`/products/${product._id}`)}
          />
        </div>
        <p id="light">{infoString}</p>
      </div>
      <div className="right-part">
        <p>К-во</p>
        <Input.Text
          value={quantity}
          onChange={(event) => {
            onQuantityChange(event.target.value)
            setQuantity(event.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default ActProduct
