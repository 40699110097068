import React, { useState } from "react";
import InquiryClientInvoices from "./InquiryClientInvoices";
import moment from "moment";
import "./styles.scss";
import { history } from '../../config/stores'

const InquiryClientElement = ({ data, onEdit }) => {
  const [ isInvoicesTabActive, setIsInvoicesTabActive ] = useState(false); 
  const dropdownArrow = require("../../assets/icons/arrowDown.svg");

  return (
    <>
    <div className="inquiry-client-element" key={data._id}>
      <div className="element medium first overflow">{data.name || "--"}</div>
      <div className="element small overflow">{data.projects || "Няма"}</div>
      <div className="element small overflow">
        {moment(data.clientSince).format("DD.MM.YYYY") || "--"}
      </div>
      <div className="element medium flex">
        {data.invoices ? (
          !data.invoices.length ? (
            <p style={{color: '#25E083', fontWeight: '400'}}>Няма</p>
          ) : (
            <>
              <p id="red">
                {data.invoices && data.invoices.length === 1
                  ? `${data.invoices && data.invoices.length} Фактура`
                  : `${data.invoices && data.invoices.length} Фактури`}
              </p>
              <img
              src={dropdownArrow}
              className="dropdown-arrow"
              style={isInvoicesTabActive ? {transform: 'rotate(180deg)'} : {}}
              onClick={() => setIsInvoicesTabActive(!isInvoicesTabActive)}></img>
            </>
          )
        ) : (
          <>Няма</>
        )}
      </div>
      <div className="element medium overflow">{data.uic || "--"}</div>
      <div className="element small overflow">{data.phone || "--"}</div>
      <div className="element medium overflow">{data.email || "--"}</div>
      <div className="element large flex">
        <p className="overflow">{data.address || "--"}</p>
        <div className="icon pen" 
        // onClick={onEdit} 
        onClick={() => {history.push(`/inquiries/edit/${data?._id}`, { data: data })}}
        />
      </div>
    </div>
    {isInvoicesTabActive && <InquiryClientInvoices invoices={data.invoices} />}
    </>
  )
}

export default InquiryClientElement
