import React from 'react'
import './styles.scss'
import { Input, Button, Section } from "@makedonski/admin-ui"
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../config/stores'
import { Alerts } from '@makedonski/socourt-utilities/lib/Alerts'
import { createClient, editClient, createSupplier, editSupplier } from '../../actions'

export default function InquiryAdd() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [ payload, setPayload ] = useState({name: '', uic: '', phone: '', email: '', address: ''})
  const [ edit, setEdit ] = useState(true)

  useEffect(() => {
    typeof state?.data === 'object' ? setPayload(state.data) : setEdit(false)
  }, [])

  return (
    <div className='add-request-wrapper'>
        <div className='add-request-container'>
        <div className="add-request-top">
          {!edit ? 
          <>
           <h2>{`Добавяне на ${state.data === 'client' ? 'клиент' : 'контрагент'}`}</h2>
            <div className="buttons-wrapper">
                <Button.Raised 
                 text='Откажи'
                 className='secondary-btn'
                 onClick={() => history.goBack()}
                 />
                 <Button.Raised
                 text='Добави'
                 disabled={!payload?.name}
                 onClick={() => {
                  switch(state.data) {
                    case 'client': {
                      let spacesCount = payload.name.split('')?.filter(char => char === ' ')?.length
                      if (spacesCount === payload.name?.length) {
                        return Alerts.error('Невалидно име на фирма!')
                      }
                      if (!payload.email.match(/[\w]+\-?\_?@[\w]+.[\w]{1,}\-?\_?/g)) {
                        return Alerts.error('Невалиден имейл адрес!')
                      }

                      dispatch(createClient(payload))
                      history.goBack()
                      break;
                    }
                    case 'supplier': {
                      let spacesCount = payload.name.split('')?.filter(char => char === ' ')?.length
                      if (spacesCount === payload.name?.length) {
                        return Alerts.error('Невалидно име на фирма!')
                      }

                      if (!payload.email.match(/[\w]+\-?\_?@[\w]+.[\w]{1,}\-?\_?/g)) {
                        return Alerts.error('Невалиден имейл адрес!')
                      }
                      
                      let supplierPayload = {...payload}
                      supplierPayload.firmName = supplierPayload.name
                      delete supplierPayload.name
                      dispatch(createSupplier(supplierPayload))
                      history.goBack()
                      break;
                    }
                  }
                 }}
                 />
            </div>
          </> :
          <>
          <h2>{`Редакция на ${state.data.name ? 'клиент' : 'контрагент'}`}</h2>
            <div className="buttons-wrapper">
              <Button.Raised 
              text='Изтрий'
              className='secondary-btn delete'
              onClick={() => history.goBack()}
              />

              <Button.Raised 
              text='Запази'
              disabled={'name' in payload ? !(payload?.name) : !(payload?.firmName)}
              onClick={() => {
                if (payload?.name) {
                  let spacesCount = payload.name.split('')?.filter(char => char === ' ')?.length
                  if (spacesCount === payload.name?.length) {
                    return Alerts.error('Невалидно име на фирма!')
                  }
                }
                else if (payload?.firmName){
                  let spacesCount = payload.firmName.split('')?.filter(char => char === ' ')?.length
                  if (spacesCount === payload.firmName?.length) {
                    return Alerts.error('Невалидно име на фирма!')
                  }
                }

                if (payload?.email) {
                  if (!payload.email.match(/[\w]+\-?\_?@[\w]+.[\w]{1,}\-?\_?/g)) {
                    return Alerts.error('Невалиден имейл адрес!')
                  }
                } 

                payload.name ? dispatch(editClient(payload)) : dispatch(editSupplier(payload))
                history.goBack()
              }}/>
            </div>
          </>}
        </div>

        <div className="add-request-main">
          <div className="add-request-main-inputs-top">

            <div className="input-section-wrapper">
              <label htmlFor="name-input">Име на фирма</label>
              <Input.Text
              value={!edit ? payload.name : ('name' in payload ? payload?.name : payload?.firmName)}
              onChange={(e) => {
                !edit && setPayload({...payload, name: e.target.value})
                edit &&
                (state.data?.name ? setPayload({...payload, name: e.target.value}) :
                setPayload({...payload, firmName: e.target.value}))
              }} />
            </div>

            <div className="input-section-wrapper">
              <label htmlFor="details-input">ЕИК</label>
              <Input.Text id="description-input"
              value={payload.uic}
              onChange={(e) => setPayload({...payload, uic: e.target.value})} />
            </div>

            <div className="input-section-wrapper">
              <label htmlFor="details-input">Телефон</label>
              <Input.Text id="description-input"
              value={payload.phone}
              onChange={(e) => {
                if (e.target.value === '') {
                  setPayload({...payload, phone: e.target.value})
                }
                else if ((/[\d]+/g).test(e.target.value)) {
                  setPayload({...payload, phone: e.target.value.match(/[\d]+/g)[0]})
                }
                }} />
            </div>

            <div className="input-section-wrapper">
              <label htmlFor="details-input">Имейл</label>
              <Input.Text id="description-input"
              value={payload.email}
              onChange={(e) => setPayload({...payload, email: e.target.value})} />
            </div>
        </div>
        
        <div className="add-request-main-inputs-bottom">
            <div className="input-section-wrapper address">
                <label htmlFor="details-input">Адрес</label>
                <Input.Text
                value={payload.address}
                onChange={(e) => {
                  setPayload({...payload, address: e.target.value})}
                } />
            </div>

          </div>
        </div>
        </div>

    </div>
  )
}
