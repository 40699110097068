import React from 'react';
import './styles.scss';
import { RadioButton } from "../.."
import { useState, useEffect } from 'react';
import { hideModal } from "../../../actions"
import { useDispatch, useSelector } from 'react-redux';
import { emit } from '../../../utilities/helpers';
import { socket } from '../../../actions';

export default function FilterModal({ data }) {
    const dispatch = useDispatch()
    const users = useSelector((state) => state.tasks.users)

    const periodTypes = [ { name: 'Всички дати', value: 'all-dates'}, { name: 'Избери дата/период', value: 'choose-date-period'} ]
    const priorityTypes = [ { name: 'Нисък приоритет', value: 'low' }, { name: 'Среден приоритет', value: 'medium' }, { name: 'Висок приоритет', value: 'high' }]

    const [ periodType, setPeriodType ] = useState('')
    const [ priorityType, setPriorityType ] = useState('')
    const [ searchValue, setSearchValue ] = useState('');

    const [ chosen, setChosen ] = useState([]);
    const [ performersList, setPerformersList ] = useState(['Александър Димитров', 'Ивана Стоянова', 'Йоан Петров', 'Стефан Иванвов']);
    const [ searchQuery, setSearchQuery ] = useState('');
    const [ isCheckListActive, setIsCheckListActive ] = useState(false);
    // let performersListLowerCase = data ? data.slice().map(performer => performer = performer.toLowerCase()) : []
  
    useEffect(() => {
      emit(socket, { action: 'task/getUsers' })
    }, []);

  return (
    <div className='filter-modal-container'>
      <div className='modal-inner-container'>
        <div className='top-wrapper'>
            <h3>Сортирай по</h3>
            <img className='close-btn' src={require('../../../assets/icons/closeIcon.svg')} onClick={() => dispatch(hideModal())} />
        </div>

        <div className='main-wrapper'>
            <div className='filter-type-wrapper'>
                <div className='title'>Период</div>

                <div className='options-wrapper flex col'>
                    {periodTypes.map(period => {
                       return <RadioButton
                       changed={() => {
                         setPeriodType(period.value)
                       }}
                       key={period.value}
                       id={period.value}
                       isSelected={period.value === periodType}
                       label={period.name}
                       value={period.value}
                     />
                    })}
                </div>
            </div>

            <div className='filter-type-wrapper'>
                <div className='title'>Приоритет</div>

                {priorityTypes.map(period => {
                  return <RadioButton
                   changed={() => {
                     setPriorityType(period.value)
                   }}
                   key={period.value}
                   id={period.value}
                   isSelected={period.value === priorityType}
                   label={period.name}
                   value={period.value}
                   color={priorityType === 'low' ? 'green' : priorityType === 'medium' ? 'yellow' : priorityType === 'high' ? 'red' : ''}
                 />
                })}
            </div>

            <div className='filter-type-wrapper'>
                <div className='flex wide'>
                  <div className='title flex'>Назначени</div>
                  <img className='dropdown-icon' src={require('../../../assets/icons/arrowDown.svg')} onClick={() => setIsCheckListActive(!isCheckListActive)} />
                </div>

                {isCheckListActive &&
                <>
                 <div className='search-wrapper'>
                  <div className='input-wrapper'>
                    <img src={require('../../../assets/icons/searchIcon.svg')} />
                    <input type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                  </div>
                 </div>

                <div className="check-list-wrapper">
                <div className="check-list-top">
                <div
                  className={`checkbox-container ${users?.length === chosen?.length && 'selected'}`}
                  // className={`checkbox-container ${data?.assignedTo?.length === chosen?.length && 'selected'}`}
                  onClick={() => {
                    // if (data?.assignedTo?.length === chosen?.length) {
                    if (users?.length === chosen?.length) {
                      setChosen([]);
                    } else {
                      setChosen(data)
                    }
                  }}
                  >
                  {users?.length === chosen?.length && <div className='checkbox' />}
                  {/* {data?.assignedTo?.length === chosen?.length && <div className='checkbox' />} */}
                </div>
                <div className={`performer ${users?.length === chosen?.length && 'selected'}`}>Виж всички</div>
                {/* <div className={`performer ${data?.assignedTo?.length === chosen?.length && 'selected'}`}>Виж всички</div> */}
      
                </div>
      
                {performersList && chosen &&
                performersList?.filter(el => el.toLowerCase().includes(searchQuery.toLowerCase())).map((performer, index) => {
                  const checked = chosen.findIndex(el => el === performer) !== -1;
                  return (
                    <div className="check-list" key={`checklist-performer-${index}`}>
                      <div
                        className={`checkbox-container ${checked && 'selected'}`}
                        onClick={() => {
                          const newChosen = chosen.slice(0);
                          const index = newChosen.findIndex(el => el === performer)
                          if (index === -1) {
                            newChosen.push(performer)
                          } else {
                            newChosen.splice(index, 1)  
                          }
                          setChosen(newChosen)
                        }}
                        >
                        {checked && <div className='checkbox' />}
                      </div>
                      <div className={`performer ${checked && 'selected'}`}>{performer}</div>
                    </div>
                  )
                })}
                </div>
                </>}
                      
            </div>
        </div>

        <div className='bottom-wrapper'>
            <div className='title'>Изчисти филтри</div>
            <button className='primary-btn' onClick={() => dispatch(hideModal())}>Запази</button>
        </div>
      </div>
    </div>
  )
}
