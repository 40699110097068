import React, { useState, useEffect } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import {
  getMachineSettings,
  getMachines,
  deleteMachineSettings,
  showLoading,
  setSelectedMachine,
  editMachineSettings,
} from "../../actions"
import { QuantityDropdownInput } from "../../components"
import { connect } from "react-redux"
import moment from "moment"

import "./styles.scss"

const MaterialElement = ({
  edit,
  index,
  quantity,
  name,
  onEditMaterialData,
  onDeleteMaterial,
  isRatio,
  disabled,
}) => {
  return (
    <div className="flex-container flex-start material-wrapper">
      <div className="input-container">
        <div className="flex-container flex-start">
          <p>Материал {++index}</p>
          {edit && <div className="remove-btn" onClick={onDeleteMaterial} />}
        </div>
        <Input.Text
          disabled={disabled}
          value={name}
          onChange={(event) => {
            onEditMaterialData({ key: "name", value: event.target.value })
          }}
        />
      </div>
      <div className="input-container flex margin-left">
        <p>Съотношение</p>
        <QuantityDropdownInput
          disabled={disabled}
          noCurrency
          noDropdown
          quantityValue={quantity}
          quantityTypeValue={{ value: isRatio ? "%" : "бр." }}
          onSetQuantity={(quantity) => {
            onEditMaterialData({ key: "ratio", value: quantity })
          }}
        />
      </div>
    </div>
  )
}

const SettingsElement = ({ name, selected, onClick }) => {
  return (
    <div
      className={`setting-container ${selected && "selected"}`}
      onClick={onClick}
    >
      {name}
      <div className="icon-right" />
    </div>
  )
}

const MachineSettings = ({
  getMachineSettings,
  getMachines,
  machineSettings,
  machineData,
  deleteMachineSettings,
  showLoading,
  setSelectedMachine,
  selectedMachine,
  editMachineSettings,
}) => {
  const [edit, setEdit] = useState(false)
  const [currentSetting, setCurrentSetting] = useState(null)
  const [selectedMachineTablet, setSelectedMachineTablet] = useState('')
  const [selectedSettings, setSelectedSettings] = useState({
    name: "",
    machine: "",
    schedule: "",
    filledBy: "",
    isSearchable: true,
    materials: [
      {
        name: "",
        ratio: 0,
      },
    ],
    geometry: {
      innerDiameter: "",
      caliber: "",
      f1f2: "",
      stiffnes: "",
      weight: "",
      vacuum: "",
    },
    extruderSJ90: {
      motor: {
        current: "",
        revolutions: "",
      },
      profiles: ["", "", "", "", "", "", "", "", ""],
      material: "",
    },
    extruderSJ65: {
      motor: {
        current: "",
        revolutions: "",
      },
      profiles: ["", "", "", "", "", "", "", "", ""],
      material: "",
    },
    windingSystem: "",
    injector: "",
    buzzSaw: {
      min: "",
      move: "",
    },
    note: "",
  })
  useEffect(() => {
    getMachineSettings()
  }, [getMachineSettings])

  useEffect(() => {
    getMachines()
  }, [])

  useEffect(() => {
    if (machineData) {
      setSelectedMachineTablet(machineData.find(machine => machine._id === localStorage.getItem('machine'))?.name)
    }
  }, [machineData])

  useEffect(() => {
    if (machineSettings) {
      setCurrentSetting(machineSettings[selectedMachine])
    }
  }, [machineSettings, selectedMachine])

    useEffect(() => {
    setSelectedMachine(selectedMachineTablet)
  }, [selectedMachineTablet])
  
  return (
    <div className="machine-settings-container">
      <div className="flex-container hight align-top">
        <div className="content-container">
          <div className="flex-container">
            <h3>Запазени настройки</h3>
            <div
              className="plus-icon"
              onClick={() => {
                const newCurrentSetting = currentSetting.slice(0)
                newCurrentSetting.push({
                  name: "added-setting",
                  machine: "",
                  schedule: "",
                  filledBy: "",
                  isSearchable: true,
                  materials: [
                    {
                      name: "",
                      ratio: 0,
                    },
                  ],
                  geometry: {
                    innerDiameter: "",
                    caliber: "",
                    f1f2: "",
                    stiffnes: "",
                    weight: "",
                    vacuum: "",
                  },
                  extruderSJ90: {
                    motor: {
                      current: "",
                      revolutions: "",
                    },
                    profiles: ["", "", "", "", "", "", "", "", ""],
                    material: "",
                  },
                  extruderSJ65: {
                    motor: {
                      current: "",
                      revolutions: "",
                    },
                    profiles: ["", "", "", "", "", "", "", "", ""],
                    material: "",
                  },
                  windingSystem: "",
                  injector: "",
                  buzzSaw: {
                    min: "",
                    move: "",
                  },
                  note: "",
                })
                setSelectedSettings({
                  name: "added-setting",
                  machine: "",
                  schedule: "",
                  filledBy: "",
                  isSearchable: true,
                  materials: [
                    {
                      name: "",
                      ratio: 0,
                    },
                  ],
                  geometry: {
                    innerDiameter: "",
                    caliber: "",
                    f1f2: "",
                    stiffnes: "",
                    weight: "",
                    vacuum: "",
                  },
                  extruderSJ90: {
                    motor: {
                      current: "",
                      revolutions: "",
                    },
                    profiles: ["", "", "", "", "", "", "", "", ""],
                    material: "",
                  },
                  extruderSJ65: {
                    motor: {
                      current: "",
                      revolutions: "",
                    },
                    profiles: ["", "", "", "", "", "", "", "", ""],
                    material: "",
                  },
                  windingSystem: "",
                  injector: "",
                  buzzSaw: {
                    min: "",
                    move: "",
                  },
                  note: "",
                })
                setCurrentSetting(newCurrentSetting)
              }}
            />
          </div>
          <div className="choose-machine-text">Моля, изберете машина за устройството:</div>
          <div className="flex-container machines">
            {machineData?.sort()?.map(machine => {
              return (<div 
                key={machine._id} 
                className={`machine-tablet ${selectedMachineTablet === machine.name ? 'selected' : ''}`}
                onClick={() => {
                  localStorage.setItem('machine', machine._id)
                  setSelectedMachineTablet(machine.name)
                  setSelectedMachine(machine.name)
                  }}>
                {machine.name}
                </div>)
            })}
          </div>
          {/* <Button.UnderlinedTab
            className="underlined-margin"
            value={selectedMachineTablet}
            buttons={["OD800", "OD2000", "OD2400"]}
            onChange={(name) => setSelectedMachine(name)}
          /> */}
          <div className="scroll-container">
            {currentSetting &&
              currentSetting.map((setting) => {
                return (
                  <SettingsElement
                    onClick={() => setSelectedSettings(setting)}
                    name={setting.name}
                    selected={setting._id === selectedSettings._id}
                  />
                )
              })}
          </div>
        </div>
        <div
          className={`content-container large ${
            selectedSettings.name === "" && "align-center"
          }`}
        >
          {selectedSettings.name !== "" ? (
            <>
              <div className="flex-container shadow flex-wrap">
                <div className="input-element">
                  <Input.Text
                    disabled={!edit}
                    value={selectedSettings.name}
                    onChange={(event) => {
                      const newSettings = { ...selectedSettings }
                      newSettings.name = event.target.value
                      setSelectedSettings(newSettings)
                    }}
                  />
                  <div className="flex-container">
                    <div className="flex-container">
                      <p>Създаденa на: </p>
                      <p id="bold">
                        {moment(selectedSettings.createdAt).format(
                          "DD.MM.YYYY"
                        )}
                      </p>
                    </div>
                    <div className="flex-container margin-left">
                      <p>Създаденa от: </p>
                      <div className="creator-profile-picture" />
                      <p id="bold">Иван Герасимов</p>
                    </div>
                  </div>
                </div>
                {edit ? (
                  <div className="flex-container">
                    <Button.Raised
                      className="button-red"
                      clear
                      text="Изтрий"
                      onClick={() => {
                        showLoading()
                        deleteMachineSettings({ _id: selectedSettings._id })
                      }}
                    />
                    <Button.Raised
                      className="button-green"
                      text="Запази"
                      onClick={() => {
                        showLoading()
                        editMachineSettings(selectedSettings)
                        setEdit(false)
                      }}
                    />
                  </div>
                ) : (
                  <Button.Raised
                    text="Редактирай"
                    clear
                    onClick={() => setEdit(true)}
                  />
                )}
              </div>
              <div className="content-container-inner">
                <h3>Настройки на машината</h3>
                <div className="flex-container">
                  <h5>Материали</h5>
                  {edit && (
                    <Input.Dropdown
                      disabled={!edit}
                      value={selectedSettings.materials.length.toString()}
                      options={[1, 2, 3, 4, 5, 6].slice(
                        selectedSettings.materials.length
                      )}
                      onChange={(value) => {
                        const newSettings = { ...selectedSettings }
                        const newMaterialsArray = Array.apply(
                          null,
                          Array(parseInt(value.value))
                        ).map((el, index) => {
                          if (newSettings.materials[index]) {
                            return newSettings.materials[index]
                          } else {
                            return { name: "", ratio: 0 }
                          }
                        })
                        newSettings.materials = newMaterialsArray
                        setSelectedSettings(newSettings)
                      }}
                    />
                  )}
                </div>
                <div className="flex-container flex-wrap flex-start">
                  {selectedSettings.materials.map((material, index) => {
                    return (
                      <div className="material-data-wrapper">
                        <MaterialElement
                          disabled={!edit}
                          name={material.name}
                          quantity={
                            material.ratio ? material.ratio : material.quantity
                          }
                          isRatio={material.ratio}
                          index={index}
                          edit={edit}
                          onEditMaterialData={(data) => {
                            const newSettings = { ...selectedSettings }
                            newSettings.materials[index][data.key] =
                              data.key === "ratio"
                                ? parseInt(data.value)
                                : data.value
                            setSelectedSettings(newSettings)
                          }}
                          onDeleteMaterial={() => {
                            const newSettings = { ...selectedSettings }
                            newSettings.materials.splice(index, 1)
                            setSelectedSettings(newSettings)
                          }}
                        />
                        {edit && (
                          <div className="material-details-container">
                            <div className="flex-container">
                              <p>На склад:</p>
                              <p id="bold">134 кг</p>
                            </div>
                            <div className="seperator" />
                            <div className="flex-container">
                              <p>Ед. Цена:</p>
                              <p id="bold">4.10 лв/кг</p>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                <h5>Геометрични размери, коравина и тегло</h5>
                <div className="flex-container flex-start flex-wrap">
                  <div className="input-container margin-right not-so-small-inputs">
                    <p>Вътрешен диаметър мм</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.geometry.innerDiameter}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.geometry.innerDiameter = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container margin-right not-so-small-inputs">
                    <p>Калибър мм</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.geometry.caliber}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.geometry.caliber = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container margin-right not-so-small-inputs">
                    <p>ф 1 | ф2</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.geometry.f1f2}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.geometry.f1f2 = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  {edit && (
                    <>
                      <div className="input-container margin-right not-so-small-inputs">
                        <p>Коравина SN</p>
                        <Input.Text
                          value={selectedSettings.geometry.stiffnes}
                          onChange={(event) => {
                            const newSettings = { ...selectedSettings }
                            newSettings.geometry.stiffnes = event.target.value
                            setSelectedSettings(newSettings)
                          }}
                        />
                      </div>
                      <div className="input-container margin-right not-so-small-inputs">
                        <p>Тегло кг/м</p>
                        <Input.Text
                          value={selectedSettings.geometry.weight}
                          onChange={(event) => {
                            const newSettings = { ...selectedSettings }
                            newSettings.geometry.weight = event.target.value
                            setSelectedSettings(newSettings)
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="flex-container flex-wrap">
                  <h5>Екструдер за профил SJ90, t</h5>
                  {edit && (
                    <Input.Dropdown
                      value={selectedSettings.extruderSJ90.profiles.length.toString()}
                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                      onChange={(value) => {
                        const newSettings = { ...selectedSettings }
                        const newProfilesArray = Array.apply(
                          null,
                          Array(parseInt(value.value))
                        ).map((el, index) => {
                          if (newSettings.extruderSJ90.profiles[index]) {
                            return newSettings.extruderSJ90.profiles[index]
                          } else {
                            return ""
                          }
                        })
                        newSettings.extruderSJ90.profiles = newProfilesArray
                        setSelectedSettings(newSettings)
                      }}
                    />
                  )}
                </div>
                <div className="flex-container flex-start flex-wrap">
                  {selectedSettings.extruderSJ90.profiles.map(
                    (profile, index) => {
                      return (
                        <div className="input-container small-inputs margin-right">
                          <p>{index + 1}</p>
                          <Input.Text
                            disabled={!edit}
                            value={profile}
                            onChange={(event) => {
                              const newSettings = { ...selectedSettings }
                              newSettings.extruderSJ90.profiles[index] =
                                event.target.value
                              setSelectedSettings(newSettings)
                            }}
                          />
                        </div>
                      )
                    }
                  )}
                </div>
                <div className="flex-container flex-start">
                  <div className="input-container margin-right">
                    <p>Двигател ток А</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.extruderSJ90.motor.current}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.extruderSJ90.motor.current =
                          event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container margin-right">
                    <p>Двигател оɗ/min</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.extruderSJ90.motor.revolutions}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.extruderSJ90.motor.revolutions =
                          event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                </div>
                <div className="flex-container flex-wrap">
                  <h5>Екструдер за профил SJ65, t</h5>
                  {edit && (
                    <Input.Dropdown
                      value={selectedSettings.extruderSJ65.profiles.length.toString()}
                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                      onChange={(value) => {
                        const newSettings = { ...selectedSettings }
                        const newProfilesArray = Array.apply(
                          null,
                          Array(parseInt(value.value))
                        ).map((el, index) => {
                          if (newSettings.extruderSJ65.profiles[index]) {
                            return newSettings.extruderSJ65.profiles[index]
                          } else {
                            return ""
                          }
                        })
                        newSettings.extruderSJ65.profiles = newProfilesArray
                        setSelectedSettings(newSettings)
                      }}
                    />
                  )}
                </div>
                <div className="flex-container flex-start flex-wrap">
                  {selectedSettings.extruderSJ65.profiles.map(
                    (profile, index) => {
                      return (
                        <div className="input-container small-inputs margin-right">
                          <p>{index + 1}</p>
                          <Input.Text
                            disabled={!edit}
                            value={profile}
                            onChange={(event) => {
                              const newSettings = { ...selectedSettings }
                              newSettings.extruderSJ65.profiles[index] =
                                event.target.value
                              setSelectedSettings(newSettings)
                            }}
                          />
                        </div>
                      )
                    }
                  )}
                </div>
                <div className="flex-container flex-start">
                  <div className="input-container margin-right">
                    <p>Двигател ток А</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.extruderSJ65.motor.current}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.extruderSJ65.motor.current =
                          event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container margin-right">
                    <p>Двигател оɗ/min</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.extruderSJ65.motor.revolutions}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.extruderSJ65.motor.revolutions =
                          event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                </div>
                <div className="flex-container flex-start flex-wrap">
                  <div className="input-container not-so-small-inputs margin-right">
                    <p>Вакуум</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.geometry.vacuum}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.geometry.vacuum = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container not-so-small-inputs margin-right">
                    <p>Дюза</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.injector}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.injector = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container not-so-small-inputs margin-right">
                    <p>Навиваща система честота Hz</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.windingSystem}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.windingSystem = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container not-so-small-inputs margin-right">
                    <p>Циркуляр за рязане - мин</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.buzzSaw.min}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.buzzSaw.min = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                  <div className="input-container not-so-small-inputs margin-right">
                    <p>Циркуляр за рязане - ход</p>
                    <Input.Text
                      disabled={!edit}
                      value={selectedSettings.buzzSaw.move}
                      onChange={(event) => {
                        const newSettings = { ...selectedSettings }
                        newSettings.buzzSaw.move = event.target.value
                        setSelectedSettings(newSettings)
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="empty-state-container">
              Изберете настройка, която да визуализирате
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  machineData: state.production.machines,
  machineSettings: state.machineSettings.machineSettings,
  selectedMachine: state.machineSettings.selectedMachine,
})
const mapDispachToProps = (dispatch) => ({
  getMachineSettings: (payload) => dispatch(getMachineSettings(payload)),
  getMachines: () => dispatch(getMachines()),
  deleteMachineSettings: (payload) => dispatch(deleteMachineSettings(payload)),
  showLoading: (payload) => dispatch(showLoading(payload)),
  setSelectedMachine: (payload) => dispatch(setSelectedMachine(payload)),
  editMachineSettings: (payload) => dispatch(editMachineSettings(payload)),
})

export default connect(mapStateToProps, mapDispachToProps)(MachineSettings)
