import React from 'react'
import moment from 'moment'
import { history } from '../../../config/stores'
import '../styles.scss'
import { getProductNameKMU } from '../../../utilities/helpers'

const nameMap = {
  'Вътрешен диаметър': 'ID',
  'Външен диаметър': 'OD',
  Коравина: 'SN'
}

const getProductData = (basicInfo) => {
  return basicInfo
    .map((el) => {
      const name = nameMap[el.name] ? nameMap[el.name] : el.name
      return `${name}-${el.value}`
    })
    .join(' _ ')
}

export default function Request ({ data }) {
  const requestStatus = {
    pending: '#26ACE2', // blue
    'in-production': '#F7E05D', // yellow
    finished: '#25E083' // green
  }

  const productInfo = data?.product?.productType?.name === 'КМУ' ? getProductNameKMU(data.product.basicInfo) : data.product ? getProductData(data.product.basicInfo) : ''
  return (
    <div
      onClick={() => {
        if (data?.product?.productType?.name === 'Тръба') {
          history.push(`/production/view/${data._id}`, { data: data })
        }
        else {
          history.push(`/production/view/${data._id}`, { data: data })
        }
       
      }
    }
      className={'single-request-wrapper' + (data?.status === 'pending' ? ' new' : '')}
      style={{ borderColor: requestStatus[data?.status], textDecoration: 'none' }}
    >
      <div className='product-name-wrapper element largest'>
        <div className='title'>{data?.product?.productType?.name || data?.activity}</div>
        <div className='description'>{productInfo || ''}</div>
      </div>
      {/* <div className='element small'><div>{data.quantity} {data?.product?.mainUnit}</div></div>
      <div className='element medium'>
        <div>{moment(data?.deadline || data?.end).format('DD.MM.YYYY')}</div>
      </div> */}
      <div className='element large'><div>{data?.inquiry?.object || ''}</div></div>
      <div className='element large'><div>{data?.activity !== undefined ? data?.activity : '--'}</div></div>
      <div className='element large'><div>{data?.inquiry?.client !== undefined ? data?.inquiry?.client : '--'}</div></div>
      {/* <div className='element large'><div>{data?.activity !== undefined ? data?.activity : data?.finishingWorks?.length === 0 ? '' : data?.finishingWorks[0]}</div></div> */}
    </div>
  )
}
