import React, { Component } from "react"
import { connect } from "react-redux"
import { signIn } from "../../actions"
import { Button, Input } from "@makedonski/admin-ui"

import "./styles.scss"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
    }
  }

  handleLogin() {
    const { email, password } = this.state
    this.props.signIn({ ...this.state })
  }

  render() {
    return (
      <div className="login-main">
        <div className="login-container">
          <div className="login-form-content">
            <div className="login-image-logo" />
            <div className="login-input">
              <h4> HYDROPRO ERP </h4>
              <Input.Text
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value })
                }}
                className="main-input"
                placeholder="Username"
              />
              <Input.Text
                value={this.state.password}
                onChange={(e) => {
                  this.setState({ password: e.target.value })
                }}
                type="password"
                placeholder="Password"
                onKeyUp={(e) => {
                  e.keyCode === 13 && this.handleLogin()
                }}
              />
              <Button.Raised
                text="Log in"
                onClick={this.handleLogin.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispachToProps = (dispatch) => ({
  signIn: (payload) => dispatch(signIn(payload)),
})

export default connect(mapStateToProps, mapDispachToProps)(Login)
