import { Avatar, Paper } from "@mui/material"
import React from "react"
import styles from "./Activity.module.scss"

const activities = [
  {
    time: "12:45",
    date: "02.01.2022",
    avatar: "",
    user: "Иван Иванов",
    activity: "asd",
  },
]

export default function Activity() {
  return (
    <Paper elevation={2} className={styles["activities-container"]}>
      <h3>Активност</h3>
      <div className={styles["activities-list"]}>
        {activities.map((activity) => (
          <div className={styles["activity"]}>
            <p>
              {activity.time} | {activity.date}
            </p>
            <div className={styles["activity-content"]}>
              <Avatar />
              <p>
                {activity.user} {activity.activity}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Paper>
  )
}
