import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { SingleRadioButton, ClientAutocomplete } from "../../../components"
import _ from "lodash"
import {
  hideModal,
  searchProduct,
  clearResults,
  getProductTypes,
  searchOfferClients,
  resetResults,
  searchActSuppliers,
  editHPRequest,
  createActOfAcceptanceStorage,
  editActOfAcceptanceStorage,
} from "../../../actions"
import "./styles.scss"

const onChange = (event, search) => {
  event.persist()
  onChangeDelayed(event, search)
}

const onChangeDelayed = _.debounce(
  (event, search) =>
    search({
      search: event.target.value,
    }),
  500
)

function ActOfAcceptance({
  editActStorage,
  hideModal,
  productTypes,
  searchProduct,
  clearResults,
  editHPRequest,
  getProductTypes,
  searchResult,
  searchResults,
  resetResults,
  createActStorage,
  data,
  editAct,
  searchActSuppliers,
  actSuppliers,
  inquiries,
}) {
  const [productResults, setProductResults] = useState([])
  const [chosenProducts, setChosenProducts] = useState([])
  const [clientName, setClientName] = useState("")
  const [uic, setUIC] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [deliveryService, setDeliveryService] = useState("")
  const [driver, setDriver] = useState("")
  const [truckRegistration, setTruckRegistration] = useState("")
  const [shippingNumber, setShippingNumber] = useState("")
  const [description, setDescription] = useState("")
  const [typeOfDeal, setTypeOfDeal] = useState("За проект")
  const [project, setProject] = useState("")

  useEffect(() => {
    if (data?.forEdit) {
      setChosenProducts(data?.act.products)
      setClientName(data?.act.client.name)
      setUIC(data?.act.client.uic)
      setPhone(data?.act.client.phone)
      setEmail(data?.act.client.email)
      setAddress(data?.act.client.address)
      setDeliveryService(data?.act.deliveryService)
      setDriver(data?.act.driver)
      setTruckRegistration(data?.act.truckRegistration)
      setShippingNumber(data?.act.shippingNumber)
      setDescription(data?.act.description)
    }
  }, [data])

  useEffect(() => {
    getProductTypes()
    resetResults()
  }, [getProductTypes, resetResults])

  useEffect(() => {
    let newSearchResults = []
    if (searchResult) {
      newSearchResults = [...searchResults, searchResult]
    } else {
      newSearchResults = [...searchResults]
    }
    setProductResults(newSearchResults)
  }, [searchResults, searchResult])

  return (
    <div className="act-of-acceptance-hp-container">
      <div className="left-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3>{data.forEdit ? "Редакция на ППП" : "Създаване на ППП"}</h3>
          <div className="close-btn" onClick={() => hideModal()} />
        </div>
        <p>Тип продажба</p>
        <div className="flex-container">
          <SingleRadioButton
            label="За проект"
            selected={typeOfDeal === "За проект"}
            onClick={() => {
              setTypeOfDeal("За проект")
            }}
          />
          <SingleRadioButton
            label="Свободна продажба"
            selected={typeOfDeal === "Свободна продажба"}
            onClick={() => setTypeOfDeal("Свободна продажба")}
          />
        </div>
        {typeOfDeal === "За проект" && (
          <div className="input-container">
            <p>Проект</p>
            <Input.Dropdown
              placeholder=""
              value={project}
              style={{ width: "100%" }}
              options={inquiries.map((inquiry) => inquiry.object)}
              onChange={(value) => setProject(value.value)}
            />
          </div>
        )}
        <div className="flex-container space-between">
          <div className="input-container">
            <p>Клиент</p>
            <ClientAutocomplete
              onChooseClient={(data) => {
                setClientName(data.name)
                if (data.uic) {
                  setUIC(data.uic)
                } else {
                  setUIC("")
                }
                if (data.phone) {
                  setPhone(data.phone)
                } else {
                  setPhone("")
                }
                if (data.email) {
                  setEmail(data.email)
                } else {
                  setEmail("")
                }
                if (data.address) {
                  setAddress(data.address)
                } else {
                  setAddress("")
                }
              }}
              onChange={(event) => setClientName(event.target.value)}
            />
            {/* <div className="input-wrapper">
              <Input.Text
                value={clientName}
                onChange={(event) => {
                  onChange(event, searchOfferClients)
                  setClientName(event.target.value)
                }}
              />
              {clientResults.length > 0 && (
                <div className="client-results-dropdown">
                  {clientResults.map((client) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setAddress(client.address)
                          setEmail(client.email)
                          setClientName(client.name)
                          setPhone(client.phone)
                          setUIC(client.uic)
                          resetResults()
                        }}
                      >
                        {client.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div> */}
          </div>
          <div className="input-container">
            <p>ЕИК</p>
            <Input.Text
              value={uic}
              onChange={(event) => setUIC(event.target.value)}
            />
          </div>
          <div className="input-container">
            <p>Телефон</p>
            <Input.Text
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container border-bottom space-between">
          <div className="input-container">
            <p>Имейл</p>
            <Input.Text
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="input-container big">
            <p>Адрес</p>
            <Input.Text
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container space-between">
          <div className="input-container">
            <p>Доставчик</p>
            <div className="input-wrapper">
              <Input.Text
                value={deliveryService.name}
                onChange={(event) => {
                  onChange(event, searchActSuppliers)
                  setDeliveryService({ name: event.target.value })
                }}
              />
              {actSuppliers?.length > 0 && (
                <div className="client-results-dropdown">
                  {actSuppliers?.map((supplier) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setDeliveryService(supplier)
                          resetResults()
                        }}
                      >
                        {supplier?.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="input-container">
            <p>Шофьор</p>
            <Input.Text
              value={driver}
              onChange={(event) => setDriver(event.target.value)}
            />
          </div>
          <div className="input-container">
            <p>Рег. номер</p>
            <Input.Text
              value={truckRegistration}
              onChange={(event) => setTruckRegistration(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container space-between">
          <div className="input-container">
            <p>Товарителница</p>
            <Input.Text
              value={shippingNumber}
              onChange={(event) => setShippingNumber(event.target.value)}
            />
          </div>
          <div className="input-container big">
            <p>Описание</p>
            <Input.Text
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
        </div>
        {/* <div className="input-container big">
          <p>Вид продукт</p>
          <Input.Dropdown
            placeholder="Моля изберете"
            value={productType}
            options={productTypes.map((type) => type.name)}
            onChange={(value) => {
              setProductResults([])
              setProductType(value.value)
            }}
          />
        </div> */}
        {/* <div className="flex-container flex-end">
          <p
            onClick={() => {
              if (!productType) {
                Alerts.error("Грешка", "Моля изберете тип продукт!")
              } else {
                let type = productTypes.find(
                  (type) => type.name === productType
                )
                searchProduct({
                  productType: type._id,
                  basicInfo: [],
                })
              }
            }}
          >
            Добави към ППП
          </p>
          <div className="arrow-right" />
          {productResults?.filter((el) => el.productType.name === productType)
            .length > 0 && (
            <div className="search-results-popup">
              <div className="title-container">
                <h4>Изберете продукт</h4>
                <div
                  className="close-popup-btn"
                  onClick={() => setProductResults([])}
                />
              </div>
              <div className="scroll-container">
                {productResults
                  ?.filter((el) => el.productType.name === productType)
                  ?.map((product) => {
                    let infoString
                    if (product.productType.name === "Тръба") {
                      const typeOfDiameter = product.basicInfo.find(
                        (el) => el.name === "Произвежда се по"
                      )
                      const SN = product.basicInfo.find(
                        (el) => el.name === "Коравина"
                      )
                      if (typeOfDiameter.value === "Вътрешен диаметър") {
                        const diameter = product.basicInfo.find(
                          (el) => el.name === "Вътрешен диаметър"
                        )
                        infoString = `SN${SN.value}_ID${diameter.value}`
                      } else {
                        const diameter = product.basicInfo.find(
                          (el) => el.name === "Външен диаметър"
                        )
                        infoString = `SN${SN.value}_ОD${diameter.value}`
                      }
                    } else {
                      infoString = product.basicInfo
                        .map((el) => {
                          return el.value
                        })
                        .join("_")
                    }
                    return (
                      <div className="product-container">
                        <div
                          className="product-name"
                          onClick={() => setProductToAdd(product)}
                        >
                          <p id="large">
                            {product.productType.name === "Тръба"
                              ? infoString
                              : product.productType.name}
                          </p>
                          {product.productType.name !== "Тръба" && (
                            <p id="small">{infoString}</p>
                          )}
                        </div>
                        <SingleRadioButton
                          selected={productToAdd._id === product._id}
                          onClick={() => setProductToAdd(product)}
                        />
                      </div>
                    )
                  })}
              </div>
              <Button.Raised
                text="Добави"
                onClick={() => {
                  if (
                    chosenProducts.findIndex(
                      (el) => el._id === productToAdd._id
                    ) === -1
                  ) {
                    let newChosenProducts = chosenProducts.slice(0)
                    newChosenProducts.push({
                      ...productToAdd,
                      deliveryDate: moment().toDate(),
                      quantity: 1,
                    })
                    setProductResults([])
                    setChosenProducts(newChosenProducts)
                    clearResults()
                  }
                }}
              />
            </div>
          )}
        </div> */}
      </div>
      <div className="right-container">
        {/* <div className="flex-container space-between">
          <h3>Продукти към ППП</h3>
          <div className="close-btn" onClick={() => hideModal()} />
        </div>
        <div className="scroll-container"> */}
        {/* {chosenProducts.map((product, index) => {
            return (
              <ActProduct
                forEdit={data.forEdit}
                product={product}
                productTypes={productTypes}
                onQuantityChange={(value) => {
                  let newProducts = chosenProducts.slice(0)
                  newProducts[index].quantity = value
                  setChosenProducts(newProducts)
                }}
              />
            )
          })} */}
        {/* </div> */}
        <Button.Raised
          text={`${data?.forEdit ? "Редактирай ППП" : "Създай ППП"}`}
          onClick={() => {
            if (data.forEdit) {
              const products = chosenProducts.map((product) => {
                let cost = product.price
                return {
                  itemName: product.product,
                  quantity: parseInt(product.quantity),
                  deliveryDate: data.deliveryDate,
                  singleUnitPrice: cost,
                  total: cost * parseInt(product.quantity),
                }
              })
              editActStorage({
                _id: data.act._id,
                request: data._id,
                client: {
                  name: clientName,
                  uic: uic,
                  phone: phone,
                  email: email,
                  address: address,
                  attentionOf: clientName,
                },
                products: products,
                description: description,
                deliveryService: deliveryService._id
                  ? deliveryService._id
                  : deliveryService.name,
                driver: driver,
                truckRegistration: truckRegistration,
                shippingNumber: shippingNumber,
              })
            } else {
              const products = data.sales.map((product) => {
                let cost = product.price
                return {
                  itemName: product.product,
                  quantity: parseInt(product.quantity),
                  deliveryDate: data.deliveryDate,
                  singleUnitPrice: cost,
                  total: cost * parseInt(product.quantity),
                }
              })
              createActStorage({
                request: data._id,
                client: {
                  name: clientName,
                  uic: uic,
                  phone: phone,
                  email: email,
                  address: address,
                  attentionOf: clientName,
                },
                products: products,
                description: description,
                deliveryService: deliveryService._id
                  ? deliveryService._id
                  : deliveryService.name,
                driver: driver,
                truckRegistration: truckRegistration,
                shippingNumber: shippingNumber,
              })
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiries: state.inquiries.data,
  productTypes: state.products.types,
  searchResults: state.products.more,
  searchResult: state.products.found,
  clientResults: state.inquiries.clientResults,
  actSuppliers: state.inquiries.actSuppliers,
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  editHPRequest: (payload) => dispatch(editHPRequest(payload)),
  hideModal: (payload) => dispatch(hideModal(payload)),
  searchProduct: (payload) => dispatch(searchProduct(payload)),
  clearResults: () => dispatch(clearResults()),
  getProductTypes: () => dispatch(getProductTypes()),
  searchOfferClients: (payload) => dispatch(searchOfferClients(payload)),
  resetResults: () => dispatch(resetResults()),
  createActStorage: (payload) =>
    dispatch(createActOfAcceptanceStorage(payload)),
  editActStorage: (payload) => dispatch(editActOfAcceptanceStorage(payload)),
  searchActSuppliers: (payload) => dispatch(searchActSuppliers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActOfAcceptance)
