import React from 'react';
import './styles.scss';
import { useState } from 'react';
import { hideModal } from "../../../actions"
import { useDispatch } from 'react-redux';

export default function DeleteModal({ data }) {
    const dispatch = useDispatch()

  return (
    <div className='delete-modal-container'>
      <div className='modal-inner-container'>
        <div className='top-wrapper'>
            <h3>{data?.text || 'Изтриване на задача'}</h3>
            <img className='close-btn' src={require('../../../assets/icons/closeIcon.svg')} onClick={() => dispatch(hideModal())} />
        </div>

        <div className='main-wrapper'>
            <h2>Сигурни ли сте, че искате да изтриете този елемент?</h2>
        </div>

        <div className='bottom-wrapper'>
            <button className='secondary-btn' onClick={() => dispatch(hideModal())}>Назад</button>
            <button className='primary-btn red' onClick={() => data.onDelete()}>Изтрий</button>
        </div>
      </div>
    </div>
  )
}
