import React from 'react'
import { connect } from 'react-redux'
import { Button, Section } from '@makedonski/admin-ui'
import { hideModal, changeWorkingOn } from '../../../actions'
import { Notification, UserFilter } from '../..'
import './styles.scss'

const WorkFlowModal = ({ hide, inquiry, data, transfer }) => {
  let showUsers = React.useRef()
  return (
    <div className="workflow-modal-container">
      <div className="workflow-top-container">
        <h2>Работещи по заявката</h2>
        <div className="exit-btn" onClick={hide} />
      </div>
      <div className="scroll-container">
        {inquiry.working_on.map((notification) => {
          return <Notification key={notification._id} item={notification} dotColour="#26ace2" />
        })}
      </div>
      <div className="workflow-bottom-container">
        <Section.Popup
          ref={(ref) => {
            showUsers = ref
          }}
          content={
            <UserFilter
              singleSelect
              onSelectUsers={(user, reason) => {
                transfer({ user, reason, _id: data.inquiry })
                showUsers.hide()
              }}
            />
          }
        >
          <Button.Raised text="Прехвърли" onClick={() => showUsers.show()} />
        </Section.Popup>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  inquiry: state.inquiries.current,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  transfer: (payload) => dispatch(changeWorkingOn(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkFlowModal)
