import React, { useRef, useState } from "react"
import Scrollbars from "react-custom-scrollbars-2"
import { handleUpdate } from "../../utilities/pagination"
import "./styles.scss"
import { history } from "../../config/stores"

const MobileContactsGrid = ({ docs, current, setCurrent, fetch }) => {
  const tableRef = useRef()
  const [innerLoading, setInnerLoading] = useState(false)
  return (
    <Scrollbars
      style={{ height: "90%", marginTop: "15px", marginBottom: "10px" }}
      onUpdate={(values) => {
        if (fetch)
          handleUpdate(
            values,
            docs,
            current,
            setCurrent,
            innerLoading,
            setInnerLoading,
            fetch,
            {
              pageNumber: current,
              pageSizeLimit: 20,
              sortBy: { createdAt: -1 },
              onSuccess: () => setInnerLoading(false),
            }
          )
      }}
      ref={tableRef}
      renderView={(props) => (
        <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
      )}
    >
      <div
        className="full-width full-height flex column"
        style={{ gap: "30px" }}
      >
        {docs?.docs?.map((el) => (
          <>
            <div
              className="mobile-contact-upper-container"
              onClick={() => history.push(`/current-contact/${el?._id}`)}
            >
              <b className="mobile-contact-name">{el?.name}</b>
              <p className="mobile-contact-position">{el?.position}</p>
            </div>
            <div className="mobile-contact-lower-container">
              {el?.companyName}
            </div>
          </>
        ))}
      </div>
    </Scrollbars>
  )
}

export default MobileContactsGrid
