import React from "react"
import { connect } from "react-redux"
import { Input } from "@makedonski/admin-ui"
import { UploadFile, FileItem } from "../../.."
import Files from "../../../../utilities/files"
import "./styles.scss"
import ClearIcon from "@mui/icons-material/Clear"

const units = [
  { label: "Метър", value: "м" },
  { label: "Брой", value: "бр" },
  { label: "Килограм", value: "кг" },
  { label: "Литър", value: "л" },
  { label: "Кубик", value: "м3" },
]

const OthersView = ({ loading, stopLoading, product, setProduct }) => {
  const VariationValue = React.useMemo(() => {
    const val = product.basicInfo.find((el) => el.name === "Вариация")
    if (val) return val.value
    return ""
  }, [product.basicInfo])
  const weight = React.useMemo(() => {
    const val = product.basicInfo.find((el) => el.name === "Тежест на единца")
    if (val) return val.value
    return ""
  }, [product.basicInfo])
  return (
    <div className="kmu-view-container">
      <h3>Обща информация</h3>
      <div className="flex-container space-between">
        <div className="regular-container">
          <p>Мерна единица</p>
          <Input.Dropdown
            placeholder=""
            value={product.mainUnit}
            options={units}
            onChange={(value) => {
              setProduct("mainUnit", value.value)
            }}
          />
        </div>
        <div className="regular-container flex-2">
          <p>Вариация</p>
          <Input.Text
            value={VariationValue}
            onChange={(event) => {
              const newBasicInfo = product.basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Вариация"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Вариация",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setProduct("basicInfo", newBasicInfo)
            }}
          />
        </div>
      </div>
      <div className="flex-container">
        <div className="regular-container flex-1">
          <span>Тежест на единца</span>
          <Input.Text
            placeholder="кг"
            value={weight}
            onChange={(event) => {
              const newBasicInfo = product.basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Тежест на единца"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Тежест на единца",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setProduct("basicInfo", newBasicInfo)
            }}
          />
        </div>
      </div>
      <h3>Наименование за клиент</h3>
      <div className="flex-container">
        <div className="flex-container no-margin">
          <div className="flex-container no-margin">
            <Input.Text
              value={product.friendlyNames}
              onChange={(event) => {
                setProduct("friendlyNames", event.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-control">
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за клиенти</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  if (Array.isArray(product.schematicsClient)) {
                    product.schematicsClient.forEach((el) => data.push(el))
                  }
                  setProduct("schematicsClient", data)
                  stopLoading()
                })
              }}
            />
          </div>
          {product.schematicsClient && Array.isArray(product.schematicsClient)
            ? product.schematicsClient.map((el, index) => {
                return (
                  <FileItem
                    {...el}
                    onRemove={() => {
                      let newDiagrams = product.schematicsClient.slice(0)
                      newDiagrams.splice(index, 1)
                      setProduct("schematicsClient", newDiagrams)
                    }}
                  />
                )
              })
            : product.schematicsClient && (
                <FileItem
                  {...product.schematicsClient}
                  onRemove={() => {
                    setProduct("schematicsClient", null)
                  }}
                />
              )}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за производство</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  if (Array.isArray(product.schematicsProduction)) {
                    product.schematicsProduction.forEach((el) => data.push(el))
                  }
                  setProduct("schematicsProduction", data)
                  stopLoading()
                })
              }}
            />
          </div>
          {product.schematicsProduction &&
          Array.isArray(product.schematicsProduction)
            ? product.schematicsProduction.map((el, index) => {
                return (
                  <FileItem
                    {...el}
                    onRemove={() => {
                      let newDiagrams = product.schematicsProduction.slice(0)
                      newDiagrams.splice(index, 1)
                      setProduct("schematicsProduction", newDiagrams)
                    }}
                  />
                )
              })
            : product.schematicsProduction && (
                <FileItem
                  {...product.schematicsProduction}
                  onRemove={() => {
                    setProduct("schematicsProduction", null)
                  }}
                />
              )}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Снимки</span>
            <UploadFile
              accept={"image/*"}
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                Files.uploadFiles(e.target.files).then((result) => {
                  const imgs = product.images.slice(0)
                  const newImgs = result.map((el) => el.location)
                  const all = [...imgs, ...newImgs]
                  setProduct("images", all)
                  stopLoading()
                })
              }}
            />
          </div>
          {product.images && (
            <div className="images-scroll">
              {product.images.map((img, idx) => {
                return (
                  <>
                    <img key={`Image-${idx}`} src={img} alt="item" />
                    <ClearIcon
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        let newImages = product.images.slice(0)
                        newImages.splice(idx, 1)
                        setProduct("images", newImages)
                      }}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(OthersView)
