import React, { useState, useEffect, useMemo } from "react"
import styles from "./Monthly.module.scss"
import { Paper } from "@mui/material"
import { times } from "lodash"
import moment from "moment"
import { isEmpty } from "lodash"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

const machines = ["OD800", "OD2000", "OD2400"]

export default function Monthly({
  dateProp = moment(),
  initialDate,
  setDate,
  month,
  schedule,
  contractor = false,
}) {
  const [placeholderSchedule, setPlaceholderSchedule] = useState({})
  useEffect(() => {
    if (contractor === true && isEmpty(schedule)) {
      setPlaceholderSchedule({
        "Изпълнител 1": {},
        "Изпълнител 2": {},
        "Изпълнител 3": {},
      })
    } else if (contractor === true && !isEmpty(schedule)) {
      setPlaceholderSchedule(schedule)
    }
  }, [schedule, contractor])
  const date = useMemo(() => {
    return moment(month, "DD.MM.YYYY").toDate()
  }, [month])
  // const heightRef = useRef()
  // const [containerHeight, setcontainerHeight] = useState(0)
  // const [scrollHeight, setScrollHeight] = useState(0)
  // useResizeObserver(heightRef, (ref) => {
  //   setScrollHeight(ref.target.scrollHeight)
  //   setcontainerHeight(ref.contentRect.height)
  // })

  // const [cellHeight, setCellHeight] = useState(0)
  // const calendarNavRef = useRef()
  // useEffect(() => {
  //   const calendarNavigation = calendarNavRef.current
  //   calendarNavigation.style.setProperty("--days-to-show", weekend ? 7 : 5)
  //   if (monthly) setCellHeight((scrollHeight - 50) / daysToShow)
  //   else setCellHeight((containerHeight - 50) / daysToShow - 1)
  // }, [scrollHeight, containerHeight, daysToShow])

  // const currentDayRef = useRef()
  // useEffect(() => {
  //   if (currentDayRef?.current)
  //     currentDayRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //       inline: "center",
  //     })
  // }, [currentDayRef?.current])
  // const scrollHours = () =>
  //   document.querySelectorAll(".current").forEach((session, i) => {
  //     session.scrollIntoView({
  //       behavior: "auto",
  //       block: "center",
  //       inline: "center",
  //     })
  //   })
  // useEffect(() => {
  //   setTimeout(() => scrollHours(), 200)
  // }, [date])

  return (
    <div className={styles["calendar-weekly-container"]}>
      <div className={styles["inner-container"]}>
        <div className={styles["calendar-navigation"]}>
          <div className={`${styles["day-arrows"]} ${styles.cell}`}>
            <ArrowBackIosIcon
              style={{ color: "#005EA3", cursor: "pointer" }}
              onClick={() => {
                const newWeek = { ...month }
                newWeek.startOfWeek.subtract(1, "weeks").startOf("isoWeek")
                newWeek.endOfWeek.subtract(1, "weeks").endOf("isoWeek")
                setDate(newWeek)
                // history.replace({
                //   pathname: `/productionv2/${params.type}/${
                //     params.period
                //   }/${moment(newWeek.startOfWeek).format("DD")}-${moment(
                //     newWeek.startOfWeek
                //   ).format("DD")}-${moment(newWeek.startOfWeek).format(
                //     "MM"
                //   )}-${moment(newWeek.startOfWeek).format("YYYY")}`,
                // })
              }}
            />
            <ArrowForwardIosIcon
              style={{ color: "#005EA3", cursor: "pointer" }}
              onClick={() => {
                const newWeek = { ...month }
                newWeek.startOfWeek.add(1, "weeks").startOf("isoWeek")
                newWeek.endOfWeek.add(1, "weeks").endOf("isoWeek")
                setDate(newWeek)
              }}
            />
          </div>
          {times(month.daysInMonth() - (moment(month).date() - 1)).map(
            (el, i) => (
              <div
                key={`cell-date-${i}`}
                className={`${styles["cell-date"]} ${
                  moment(date).add(i, "days").isSame(moment(), "day") &&
                  styles.today
                }`}
              >
                <p className={styles.bold}>
                  {moment(date).add(i, "days").format("DD [\n]MMMM")}
                </p>
                <p>{moment(date).add(i, "days").format("dddd")}</p>
              </div>
            )
          )}
        </div>
        <div className={styles["scroll-container"]}>
          {contractor === false
            ? machines.map((machine, i) => (
                <div className={styles.col}>
                  <div className={styles["cell-machine"]}>
                    <p>Машина {machine}</p>
                  </div>
                  {times(month.daysInMonth() - (moment(month).date() - 1)).map(
                    (i) => (
                      <div
                        key={`cell-day-${i}`}
                        className={`${styles.cell} ${styles["cell-day"]}`}
                      >
                        {Object.keys(schedule)?.map((session, j) => {
                          if (schedule[session].length > 1) {
                            schedule[session].map((el, k) => {
                              return (
                                <>
                                  {session === machine &&
                                    moment(el.createdAt).date() === i &&
                                    moment(el.createdAt).year() ===
                                      moment(month).year() && (
                                      <Paper
                                        key={k}
                                        elevation={2}
                                        className={styles["weekly-action"]}
                                      >
                                        <p>test</p>
                                      </Paper>
                                    )}
                                </>
                              )
                            })
                          } else {
                            return (
                              <>
                                {session === machine &&
                                  moment(schedule[session].createdAt).date() ===
                                    i &&
                                  moment(schedule[session].createdAt).year() ===
                                    moment(month).year() && (
                                    <Paper
                                      elevation={2}
                                      className={styles["weekly-action"]}
                                    >
                                      <p>test</p>
                                    </Paper>
                                  )}
                              </>
                            )
                          }
                        })}
                      </div>
                    )
                  )}
                </div>
              ))
            : Object.keys(placeholderSchedule).map((contractor, i) => (
                <div className={styles.col}>
                  <div className={styles["cell-machine"]}>
                    <p>{contractor}</p>
                  </div>
                  {times(month.daysInMonth() - (moment(month).date() - 1)).map(
                    (i) => (
                      <div
                        key={`cell-day-${i}`}
                        className={`${styles.cell} ${styles["cell-day"]}`}
                      >
                        {Object.keys(schedule)?.map((session, j) => {
                          if (schedule[session].length > 1) {
                            schedule[session].map((el, k) => {
                              return (
                                <>
                                  {session === contractor &&
                                    moment(el.startsAt).date() === i &&
                                    moment(el.startsAt).year() ===
                                      moment(month).year() && (
                                      <Paper
                                        key={k}
                                        elevation={2}
                                        className={styles["weekly-action"]}
                                      >
                                        <p>test</p>
                                      </Paper>
                                    )}
                                </>
                              )
                            })
                          } else {
                            return (
                              <>
                                {session === contractor &&
                                  moment(schedule[session].startsAt).date() ===
                                    i &&
                                  moment(schedule[session].startsAt).year() ===
                                    moment(month).year() && (
                                    <Paper
                                      elevation={2}
                                      className={styles["weekly-action"]}
                                    >
                                      <p>test</p>
                                    </Paper>
                                  )}
                              </>
                            )
                          }
                        })}
                      </div>
                    )
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}
