import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { showModal } from '../../../actions'
import './styles.scss'

const Request = ({ onClick, disabled, item, payload, showModal, status }) => {
  const getProductVariation = (product) => {
    // let { name } = item.productType
    if (!product) return item.description || 'Не-е-дефиниран'
    return `${product.basicInfo
      .filter((b) => {
        if (b.name === 'Материал') return false
        if (b.name === 'Ориентация') return false
        return true
      })
      .map((b) => {
        if (typeof b.value === 'string') {
          const words = b.value.split(' ')
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(' ')
          }
        }
        return b.value
      })
      .join('_')}`
  }

  let altName = null
  if (item.product && item.product.productType.name === 'Тръба') {
    const type = item.product.basicInfo.find(
      (el) => el.name === 'Произвежда се по'
    )?.value
    const SN = item.product.basicInfo.find((el) => el.name === 'Коравина').value
    if (type === 'Вътрешен диаметър') {
      const ID = item.product.basicInfo.find(
        (el) => el.name === 'Вътрешен диаметър'
      )?.value
      altName = `SN${SN}ID${ID}`
    } else {
      const OD = item.product.basicInfo.find(
        (el) => el.name === 'Външен диаметър'
      )?.value
      altName = `SN${SN}OD${OD}`
    }
  }
  return (
    <div
      className={`request-container ${status} ${disabled && 'disabled'}`}
      onClick={onClick}
    >
      <div className='name'>
        <p id='bigger'>
          {altName || (item.product
            ? item.product.productType.name
            : item.activity)}
        </p>
        <p>{getProductVariation(item.product)}</p>
      </div>
      <div className='quantity'>
        {item.quantity} {item.product?.mainUnit}
      </div>
      <div className='date'>{moment(item.deadline).format('DD.MM.YYYY')}</div>
      <div className='project-container'>
        {item.inquiry && item.inquiry.object}
      </div>
      <div
        className='comments-icon'
        onClick={() => {
          showModal({
            type: 'commentsModal',
            width: '60%',
            data: {
              id: item._id,
              refType: 'ProductionRequests',
              comments: item.comments.length > 0 ? item.comments : []
            }
          })
        }}
      />
      {item.status === 'pending' && <div className='green-arrow' />}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload))
})

export default connect(null, mapDispatchToProps)(Request)
