import React from 'react'
import { Button } from '@makedonski/admin-ui'
import './styles.scss'

const Empty = ({ text = 'Празен компонент', onClick }) => {
  return (
    <div className="component-empty">
      {text}
      {onClick && (
        <div className="button-container">
          <Button.Raised onClick={onClick} text="Добави" className="button-green" />
        </div>
      )}
    </div>
  )
}

export default Empty
