import React from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import { hideModal, setCroppedData } from "../../../actions"
import "./styles.scss"
import ImageCropComponent from "../../ImageCropComponent/ImageCropComponent"
import { history } from "../../../config/stores"
import Files from "../../../utilities/files"
import { base64toFile } from "../../../utilities/helpers"

function AddContactImageModal({ hide, data, setCroppedData }) {
  return (
    <div className="add-expenses-modal-container">
      <div className="modal-heading">
        <h3>Селектиране на визитка</h3>
        <div className="exit-btn">
          <Button.Icon name="plus" color="#152331" onClick={hide} />
        </div>
      </div>
      <div className="modal-content" style={{ display: "block" }}>
        <ImageCropComponent
          image={data?.image}
          options={{ clear: true, open: false }}
          onSuccess={(res) => {
            Files.uploadFile(base64toFile(res?.base64)).then((file) =>
              setCroppedData({ base64: res?.base64, url: file?.location })
            )
            history.push("/add-contact/scanned")
            hide()
          }}
        />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  setCroppedData: (payload) => dispatch(setCroppedData(payload)),
})

export default connect(null, mapDispatchToProps)(AddContactImageModal)
