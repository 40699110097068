import React from "react"
import { Input } from "@makedonski/admin-ui"
import "./styles.scss"

const units = ["м", "бр", "кг", "л", "м3"]

const UnitDropdown = ({
  textValue,
  onTextChange,
  unitsValue,
  onUnitsChange,
  unitsDisabled,
}) => {
  return (
    <div className="unit-dropdown-container">
      <Input.Text
        placeholder=" - "
        value={textValue}
        onChange={(e) => onTextChange(e.target.value)}
      />
      <Input.Dropdown
        placeholder=" - "
        value={unitsValue}
        options={unitsDisabled ? [unitsValue] : units}
        onChange={(value) => onUnitsChange(value.value)}
      />
    </div>
  )
}
export default UnitDropdown
