import React from "react"
import { Input, Button } from "@makedonski/admin-ui"
import { nanoid } from "nanoid"
import { searchMap } from "../../utilities/searchMap"
import { RadioButton } from ".."
import "./styles.scss"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

export default ({ types, onSearch }) => {
  const [type, setType] = React.useState("")
  const [typeName, setTypeName] = React.useState("")
  const getTypeRenderMap = React.useMemo(() => {
    if (searchMap[typeName]) return searchMap[typeName]
    return []
  }, [typeName])
  const [search, setSearch] = React.useState({})
  const updateSearch = (key, value) => {
    const n = { ...search }
    n[key] = value
    setSearch(n)
  }

  const isSelectedOption = (key, value) => {
    if (!search[key]) return false
    if (search[key] === value) return true
    return false
  }
  React.useEffect(() => {
    setSearch({})
  }, [type])

  const renderOptionType = (input) => {
    return (
      <div className="form-control" key={`${input.name}-key`}>
        <div className="form-control-label">{input.name}</div>
        <div className="radio-group-horizontal">
          {input.options.map((opt) => {
            return (
              <RadioButton
                changed={() => {
                  updateSearch(input.name, opt)
                }}
                id={nanoid()}
                isSelected={isSelectedOption(input.name, opt)}
                label={opt}
                value={opt}
                key={`${input.name}-key`}
              />
            )
          })}
        </div>
      </div>
    )
  }
  const renderInputType = (input) => {
    return (
      <div className="form-control" key={`${input.name}-key`}>
        <div className="form-control-label">{input.name}</div>
        <Input.Text
          placeholder={input.name}
          value={search[input.name]}
          onChange={(e) => {
            updateSearch(input.name, e.target.value)
          }}
        />
      </div>
    )
  }

  return (
    <>
      <div className="form-control">
        <Input.Dropdown
          placeholder="Изберете продукт"
          value={type}
          options={types}
          onChange={(c) => {
            setType(c.value)
            setTypeName(c.label)
            //onSearch && onSearch(search, c.value)
          }}
        />
      </div>
      {getTypeRenderMap.map((el) => {
        if (el.type === "option") return renderOptionType(el)
        return renderInputType(el)
      })}
      <div className="button-container">
        <Button.Raised
          onClick={() => {
            if (!type) {
              Alerts.error("Грешка!", "Моля изберете тип продукт")
            } else {
              onSearch && onSearch(search, type)
            }
          }}
          text="Преглед на продукт"
          className="button-green"
        />
      </div>
    </>
  )
}
