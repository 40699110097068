import React from 'react';
import './styles.scss';
import { Input, Button } from "@makedonski/admin-ui";
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isBudgetValid } from '../../../utilities/helpers';
import { hideModal, markMachineActivityAsDefective, getSingleSchedule } from '../../../actions';

export default function AddProductScrappingModal() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.modal.data);

  return (
    <div className='add-product-scrapping-modal-wrapper'>
     <div className='headings-wrapper'>
        <h1>Бракуване на продукция</h1>
      </div>

      <p>Сигурни ли сте, че искате да бракувате избраната продукция?</p>

      <div className='buttons-wrapper'>
        <Button.Raised text="Потвърди" 
        onClick={() => {
            // if (data?.isDefective) {
            //     dispatch(markMachineActivityAsDefective({ 
            //     //   machineSchedule: data.machineSchedule,
            //       _id: data.machineScheduleWork,
            //       defective: false
            //       }))
            //   }

            //   else if (!data?.isDefective) {
            //     dispatch(markMachineActivityAsDefective({ 
            //     //   machineSchedule: data.machineSchedule,
            //       _id: data.machineScheduleWork,
            //       defective: true
            //       }))
            //   }

            dispatch(markMachineActivityAsDefective({ _id: data._id }))
            // dispatch(getSingleSchedule({ _id: data._id }))
            data.renderTable()
        }}></Button.Raised>
        <Button.Raised text="Откажи" className="white" onClick={() => dispatch(hideModal())}></Button.Raised>
      </div>
    </div>
  )
}
