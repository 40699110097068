import React from 'react';
import { TableHeader } from '../TableHeader';
import InquirySingleProduct from './InquirySingleProduct';
import './styles.scss';

export default function InquiryOfferProducts({ products }) {
    const headerElements = [
        {
          name: "Продукт",
          size: 1.9
        },
        {
          name: "Количество",
          size: 1
        },
        {
          name: "Срок за доставка",
          size: 1
        },
        {
          name: "Стойност",
          size: 5
        }
    ]

  return (
    <div className='products-wrapper'>
      <TableHeader elements={headerElements} justifyContent='flex-start' />

      <div className='products-list-container'>
      {products.map((product) => {
          return <InquirySingleProduct product={product} />
        })}
      </div>
    </div>
  )
}
