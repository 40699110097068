import { ProvidersTypes } from "../actions"

const initialState = {
  data: [],
  current: null,
}

const providers = (state = initialState, action) => {
  switch (action.type) {
    case ProvidersTypes.GET_PROVIDERS_SUCCESS:
      return { ...state, data: action.payload }
    default:
      return state
  }
}

export default providers
