import React from 'react';
import './styles.scss';
import { RadioButton } from "../"
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { socket } from '../../actions';
import { emit } from '../../utilities/helpers';
import moment from 'moment';
import { Input } from '@makedonski/admin-ui';

export default function FilterPopup({ type, hide, data, sortTypes, setFilters, filtersPayload, setFiltersPayload, list, resetFiltersInquiry }) {
  const users = useSelector((state) => state.tasks.users)

  const periodTypes = [ { name: 'Всички дати', value: 'all-dates'}, { name: 'Избери дата/период', value: 'choose-dates'} ]
  const priorityTypes = [ { name: 'Нисък приоритет', value: 'low', color: 'green' }, { name: 'Среден приоритет', value: 'medium', color: 'yellow' }, { name: 'Висок приоритет', value: 'high', color: 'red' }]

  const [ periodType, setPeriodType ] = useState('')
  const [ searchValue, setSearchValue ] = useState('');

  const [ chosen, setChosen ] = useState([]);
  const [ chosenPriorities, setChosenPriorities ] = useState([]);
  const [ performersList, setPerformersList ] = useState([]);
  const [ listId, setListId ] = useState([]);
  const [ isCheckListActive, setIsCheckListActive ] = useState(false);
  const [ searchQuery, setSearchQuery ] = useState('');

  const handleUsers = (newChosen) => {
    let newList = newChosen.slice()
    newList = newList.map((user) => users.find(el => el.fullName === user)._id)
    setListId(newList)
    setFiltersPayload({ ...filtersPayload, assignedTo: newList })
  }

  const resetFilters = () => {
    if (type === "tasks") {
      setFiltersPayload({
        startDate: null,
        endDate: null,
        priority: '',
        assignedTo: []
      })
  
      emit(socket, { action: 'task/getTasks', payload: {} })
    }

    else if (type === "inquiries") {
      setFiltersPayload({ machines: [], performers: [] })
    }
    hide()
  }

  useEffect(() => {
    if (type === "tasks") {
      if (data.length) {
        if (typeof data[0] ==='object') {
          let newUsers = JSON.parse(JSON.stringify(users))
          newUsers = newUsers.map(user => user.fullName)
          setPerformersList(newUsers)
        }
      }
  
      if (!filtersPayload.startDate && !filtersPayload.endDate) setPeriodType('all-dates')
      else if (filtersPayload.startDate || filtersPayload.endDate) {
        setPeriodType('choose-dates')
      }
  
      if (filtersPayload.priority?.length) setChosenPriorities(filtersPayload.priority)
      if (filtersPayload.assignedTo?.length) {
        setIsCheckListActive(!isCheckListActive)
  
        let newList = [...filtersPayload.assignedTo]
        newList = newList.map(userId => users.find(el => el._id === userId).fullName)
        setChosen(newList)
      }
    }

    else if (type === "inquiries") {
     if (list) setPerformersList(list)
     if (sortTypes && sortTypes[0]?.value === 'all-machines') setChosen(filtersPayload.machines)
     else if (sortTypes && sortTypes[0]?.value === 'all-performers') setChosen(filtersPayload.performers)
    }
  }, [data])

  useEffect(() => {
    if (periodType === 'all-dates' && type === "tasks") {
     setFiltersPayload({ ...filtersPayload, startDate: null, endDate: null })     
    }
  }, [periodType])

  return (
    <div className='filter-popup-screen-wrapper'>
      <div className='filter-popup-container'>
      <div className='popup-inner-container'>
      <div className='modal-inner-container'>
        <div className='top-wrapper'>
            <h3>Сортирай по</h3>
            <img className='close-btn' src={require('../../assets/icons/closeIcon.svg')} onClick={hide} />
        </div>

        <div className='main-wrapper'>
          {type === "tasks" ? 
          <>
          <div className='filter-type-wrapper'>
                <div className='title'>Период</div>

                <div className='options-wrapper flex col'>
                    {periodTypes?.map(period => {
                       return <RadioButton
                       changed={() => {
                         setPeriodType(period.value)
                       }}
                       key={period.value}
                       id={period.value}
                       isSelected={period.value === periodType}
                       label={period.name}
                       value={period.value}
                     />
                    })}
                </div>

                {periodType === 'choose-dates' && 
                <div className='dates-wrapper'>
                  <div className="input-wrapper">
                    {(Object.keys(filtersPayload)?.length) &&
                    <Input.Datepicker
                      // minDate={moment().toDate()}
                      placeholder={filtersPayload?.startDate ? moment(filtersPayload?.startDate).format('DD.MM.YYYY') : ''}
                      // pickDate={payload?.startDate}
                      onChange={(e) => {
                        setFiltersPayload({ ...filtersPayload, startDate: e, endDate: moment(e).startOf("minute").isSame(moment(filtersPayload?.endDate).startOf("minute")) || moment(e).isAfter(moment(filtersPayload?.endDate)) ? null : filtersPayload?.endDate})}}
                    />}
                  </div>

                  <div className='dash'>-</div>

                  <div className="input-wrapper">
                      {(Object.keys(filtersPayload)?.length) &&
                      <Input.Datepicker
                      // disabled={!filtersPayload?.startDate}
                      minDate={filtersPayload?.startDate ? (filtersPayload?.startDate) : null}
                      placeholder={filtersPayload?.endDate ? moment(filtersPayload?.endDate).format('DD.MM.YYYY') : ''}
                      onChange={(e) => {
                        setFiltersPayload({ ...filtersPayload, endDate: moment(e).endOf('day').endOf('hour').toDate() })
                      }}
                    />}
                 </div>
                </div>}
            </div>

            <div className='filter-type-wrapper'>
                <div className='title'>Приоритет</div>

                {priorityTypes?.map((priority, index) => {
                   const checked = chosenPriorities.findIndex(el => el === priority.value) !== -1;
                  return (
                  <div className="check-list priority" key={`priority-${index}-${priority.value}`}>
                  <div
                    className={`checkbox-container ${checked && 'selected'} ${priority.color}`}
                    onClick={() => {
                      const newChosen = chosenPriorities.slice(0);
                      const index = newChosen.findIndex(el => el === priority.value)
                      if (index === -1) {
                        newChosen.push(priority.value)
                      } else {
                        newChosen.splice(index, 1)  
                      }
                      setChosenPriorities(newChosen)
                      setFiltersPayload({ ...filtersPayload, priority: newChosen })
                    }}
                    >
                    {checked && <div className={`checkbox ${priority.color}`} />}
                  </div>
                  <div className={`priority ${checked && 'selected'}`}>{priority.name}</div>
                  </div>)
                })}
            </div>

            <div className='filter-type-wrapper'>
                <div className='flex wide'>
                  <div className='title flex'>Назначени</div>
                  <img className='dropdown-icon' src={require('../../assets/icons/arrowDown.svg')} onClick={() => setIsCheckListActive(!isCheckListActive)} />
                </div>

                {isCheckListActive &&
                <>
                 <div className='search-wrapper'>
                  <div className='input-wrapper'>
                    <img src={require('../../assets/icons/searchIcon.svg')} />
                    <input type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                  </div>
                 </div>

                <div className="check-list-wrapper">
                <div className="check-list-top">
                <div
                  className={`checkbox-container ${users?.length === chosen?.length && 'selected'}`}
                  // className={`checkbox-container ${data?.assignedTo?.length === chosen?.length && 'selected'}`}
                  onClick={() => {
                    // if (data?.assignedTo?.length === chosen?.length) {
                    if (users?.length === chosen?.length) {
                      setChosen([]);
                    } else {
                      setChosen(performersList)
                      handleUsers(performersList)
                    }
                  }}
                  >
                  {users?.length === chosen?.length && <div className='checkbox' />}
                </div>
                <div className={`performer ${users?.length === chosen?.length && 'selected'}`}>Виж всички</div>
                </div>
      
                {performersList && chosen &&
                performersList?.filter(el => el.toLowerCase().includes(searchValue.toLowerCase())).map((performer, index) => {
                  const checked = chosen.findIndex(el => el === performer) !== -1;
                  return (
                    <div className="check-list" key={`checklist-performer-${index}`}>
                      <div
                        className={`checkbox-container ${checked && 'selected'}`}
                        onClick={() => {
                          const newChosen = chosen.slice(0);
                          const index = newChosen.findIndex(el => el === performer)
                          if (index === -1) {
                            newChosen.push(performer)
                          } else {
                            newChosen.splice(index, 1)  
                          }
                          setChosen(newChosen)
                          handleUsers(newChosen)
                        }}
                        >
                        {checked && <div className='checkbox' />}
                      </div>
                      <div className={`performer ${checked && 'selected'}`}>{performer}</div>
                    </div>
                  )
                })}
                </div>
                </>}
                      
            </div>
          </> : 
          type === "inquiries" ? 
          <div className='filter-type-wrapper'>
          <div className='title'>{sortTypes[0]?.value === 'all-machines' ? 'Машини' : 'Работници'}</div>

          {sortTypes[0]?.value === 'all-performers' &&
          <div className="check-list-top-wrapper">
          <Input.Text
             placeholder="Търси"
             value={searchQuery}
             onChange={(e) => {
             setSearchQuery(e.target.value)}}>
           </Input.Text>
        </div>}

          <div className='check-list-wrapper high'>
          {sortTypes?.filter(el => el.value.toLowerCase().includes(searchQuery.toLowerCase()))?.map((priority, index) => {
             const checked = chosen.findIndex(el => el === priority.value) !== -1;
            return (
            <div className="check-list priority" key={`priority-${index}-${priority.value}`}>
            <div
              className={`checkbox-container ${checked && 'selected'} ${priority.color}`}
              onClick={() => {
                if (priority.value.includes('all')) {
                  if (performersList?.length === chosen?.length) {
                    setChosen([]);
                    if (sortTypes[0]?.value === 'all-machines') setFiltersPayload({ ...filtersPayload, machines: [] })
                    else if (sortTypes[0]?.value === 'all-performers') setFiltersPayload({ ...filtersPayload, performers: [] })
                  } else {
                    setChosen(performersList)
                    if (sortTypes[0]?.value === 'all-machines') setFiltersPayload({ ...filtersPayload, machines: performersList })
                    else if (sortTypes[0]?.value === 'all-performers') setFiltersPayload({ ...filtersPayload, performers: performersList })
                    // handleUsers(performersList)
                  }
                }
                else {
                  const newChosen = chosen.slice(0);
                  const index = newChosen.findIndex(el => el === priority.value)
                  if (index === -1) {
                    newChosen.push(priority.value)
                  } else {
                    newChosen.splice(index, 1)  
                  }
                  setChosen(newChosen)
                  if (sortTypes[0]?.value === 'all-machines') setFiltersPayload({ ...filtersPayload, machines: newChosen })
                  else if (sortTypes[0]?.value === 'all-performers') setFiltersPayload({ ...filtersPayload, performers: newChosen })
                }
              }}
              >
              {priority.value.includes('all') ?
              performersList?.length === chosen?.length && <div className='checkbox' /> :
              checked && <div className={`checkbox ${priority.color}`} />}
            </div>
            <div className={`priority ${checked && 'selected'}`}>{priority.name}</div>
            </div>)
          })}
          </div>
      </div> : 
      <></>}
        </div>

        <div className='bottom-wrapper'>
            <div className='title pointer' 
            // onClick={type === "inquiries" ? resetFiltersInquiry : resetFilters}
            onClick={() => {
              if (type === "tasks") {
                resetFilters()
              }
              else if (type === "inquiries") {
                resetFiltersInquiry()
                setFiltersPayload({ machines: [], performers: [] })
                hide()
              }
            }}
            >Изчисти филтри</div>
            <button className='primary-btn' onClick={() => {
              let payload = JSON.parse(JSON.stringify(filtersPayload))

              if (type === "tasks") {
                if (!payload?.startDate) delete payload.startDate
                if (!payload?.endDate) delete payload.endDate
                if (payload?.endDate && !payload?.startDate) payload.endDate = moment(payload.endDate).startOf('day').startOf('hour').toDate()
                if (!payload?.priority?.length) delete payload?.priority
                if (!payload?.assignedTo?.length) delete payload?.assignedTo
                setFilters(payload)
              }

              else if (type === "inquiries") {
                setFilters(payload)
              }

              hide()
            }}>Запази</button>
        </div>
      </div>
      </div>
      </div>
    </div>
  )
}
