export const SocketTypes = {
    SOCKET_CONNECT: "socket/SOCKET_CONNECT",
    SOCKET_CONNECTED: "socket/SOCKET_CONNECTED",
    SOCKET_DISCONNECT: "socket/SOCKET_DISCONNECT",
    // SOCKET_CONNECTED_SUCCESS: "socket/SOCKET_CONNECTED_SUCCESS",
}

export const connectSocket = (payload) => ({
    type: SocketTypes.SOCKET_CONNECT,
    payload,
  })

// export const connectSocketSuccess = (payload) => ({
//     type: SocketTypes.SOCKET_CONNECTED_SUCCESS,
//     payload,
//   })

export const disconnectSocket = () => ({
    type: SocketTypes.SOCKET_DISCONNECT,
  })

// export const disconnectSocketSuccess = (payload) => ({
//     type: SocketTypes.SOCKET_DISCONNECTED_SUCCESS,
//     payload,
//   })