import React, { useState, useRef, useEffect } from "react"
import ReactCrop from "react-image-crop"
import { Inputs } from "../Inputs"
import "react-image-crop/dist/ReactCrop.css"
import "./styles.scss"

const getCroppedImg = (image, crop) => {
  const canvas = document.createElement("canvas")
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext("2d")

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Canvas is empty"))
        return
      }
      blob.name = "cropped-image.jpeg"

      // Convert blob to base64
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64 = reader.result
        resolve({
          blobUrl: window.URL.createObjectURL(blob),
          base64: base64,
        })
      }
    }, "image/jpeg")
  })
}

const ImageCropComponent = ({
  image,
  trigger,
  outerStyles,
  options = {
    open: false,
    clear: true,
    skip: false,
    noButton: false,
    disabled: false,
  },
  onSuccess,
}) => {
  const [crop, setCrop] = useState({})
  const [croppedImage, setCroppedImage] = useState(null)
  const imgRef = useRef(null)

  useEffect(() => {
    console.log(trigger)
    if (trigger) handleContinue()
  }, [trigger])

  const handleCropComplete = (crop) => makeClientCrop(crop)

  const makeClientCrop = async (crop) => {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageObject = await getCroppedImg(imgRef.current, crop)
      setCroppedImage(croppedImageObject)
    }
  }

  const handleClearCrop = () => {
    setCrop({})
    setCroppedImage(null)
  }

  const openCroppedImage = () => {
    if (croppedImage?.blobUrl) window.open(croppedImage?.blobUrl)
  }

  const handleContinue = () => {
    if (croppedImage) {
      onSuccess(croppedImage)
      handleClearCrop()
    } else if (options.skip) onSuccess(null)
    else console.error("Cropped image is unavailable.")
  }

  return (
    <>
      <div
        className={`outer-cropper-container ${
          outerStyles?.background && "background"
        }`}
        style={{
          maxHeight: outerStyles?.maxHeight || "700px",
          maxWidth: outerStyles?.maxWidth || "800px",
          height: outerStyles?.height,
          width: outerStyles?.width,
        }}
      >
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          onComplete={handleCropComplete}
          disabled={options.disabled}
          imageStyle={{
            maxHeight: "100%",
            maxWidth: "100%",
            width: "auto",
            height: "auto",
          }}
        >
          <img
            ref={imgRef}
            src={image}
            alt="Crop preview"
            className="inner-image"
          />
        </ReactCrop>
      </div>
      {!options.noButton && (
        <div
          className="flex w-full items-center justify-between"
          style={{ marginTop: "30px" }}
        >
          {options.clear && (
            <Inputs.Button
              text={"Изчисти"}
              className={"custom-button-container delete"}
              onClick={handleClearCrop}
            />
          )}
          {options.open && (
            <Inputs.Button
              text={"Изтегли"}
              className={"custom-button-container transparent"}
              onClick={openCroppedImage}
            />
          )}
          <Inputs.Button
            text={"Продължи"}
            className={"custom-button-container"}
            selected
            disabled={!options.skip && !croppedImage}
            onClick={handleContinue}
          />
        </div>
      )}
    </>
  )
}
export default ImageCropComponent
