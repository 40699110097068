import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Paper,
  Divider,
  InputBase,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  TextField,
  Box,
  IconButton,
} from "@mui/material"
import styles from "./Projects.module.scss"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CloseIcon from "@mui/icons-material/Close"
import ProjectCard from "../ProjectCard/ProjectCard"
import { Input, Section } from "@makedonski/admin-ui"
import moment from "moment"
import { showModal, deleteInquiry, getZones } from "../../actions"
import { connect } from "react-redux"
import ProjectFilters from "../ProjectFilters/ProjectFilters"
import { reverse, sortBy } from "lodash"

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

const disabledTextfieldStyles = {
  "& input.Mui-disabled": {
    "-webkit-text-fill-color": "rgba(0, 0, 0, 1)",
  },
}

const mapStatuses = {
  projection: "Проектиране",
  "choosing-provider": "Избор на доставчик",
  auction: "Търг за изпълнители",
  lost: "Загубен",
  finished: "Приключил",
  other: "Друго",
}

function Projects({
  showModal,
  inquiries,
  activeThisWeek,
  activeThisMonth,
  getZones,
  zones,
  onReachEnd
}) {
  const [initialInquiriesState, setInitialInquiriesState] = useState([])
  const [filteredInquiries, setFilteredInquiries] = useState([])
  const [sortStatuses, setSortStatuses] = useState([])
  const [reverseSort, setReverseSort] = useState(false)
  const [sortZones, setSortZones] = useState([])
  useEffect(() => {
    getZones()
    setInitialInquiriesState(
      inquiries.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
    )
    let initialStatusesState = [
      ...new Set(inquiries?.map((inquiry) => inquiry.status)),
    ]
    let initialStatusesWithChecked = initialStatusesState.map((status) => ({
      status,
      checked: false,
    }))
    setSortStatuses(initialStatusesWithChecked)
    let initialZonesState = zones?.map((zone) => ({
      zone: zone.name,
      checked: false,
    }))
    setSortZones(initialZonesState)
  }, [inquiries])

  const [sortParams, setSortParams] = useState(null)
  const [startDate, setStartDate] = useState(moment().toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [periodSearchAnchor, setPeriodSearchAnchor] = useState(null)
  const periodSearchOpen = Boolean(periodSearchAnchor)
  const handlePeriodSearchOpen = (event) => {
    setPeriodSearchAnchor(event.currentTarget)
  }
  const handlePeriodSearchClose = () => {
    setPeriodSearchAnchor(null)
  }

  const [query, setQuery] = useState("")
  const [searchQuery, setSearchQuery] = useState("Име на обект")
  const [radioBtnValue, setRadioBtnValue] = useState("object")
  const [radioBtnPeriod, setRadioBtnPeriod] = useState("createdAt")
  const [periodSearchQuery, setPeriodSearchQuery] = useState(
    "Дата на създаване"
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // useEffect(() => {
  //   if (Object.values(query).length === 0 || Object.values(query)[0] === "") {
  //     setFilteredInquiries(initialInquiriesState)
  //   }
  // }, [query])

  const handleFilter = (e) => {
    e.preventDefault()

    if (query !== "") {
      if (radioBtnValue === "object" || radioBtnValue === "client") {
        setFilteredInquiries(
          initialInquiriesState.filter((inquiry) =>
            inquiry[radioBtnValue].toLowerCase().includes(query.toLowerCase())
          )
        )
      }
      if (radioBtnValue === "refNumber") {
        setFilteredInquiries(
          initialInquiriesState.filter((inquiry) =>
            inquiry.refNumbers.find((refNumber) => refNumber.includes(query))
          )
        )
      }
      if (radioBtnValue === "assignee") {
        setFilteredInquiries(
          initialInquiriesState.filter((inquiry) =>
            inquiry.working_on[0]?.user?.fullName
              .toLowerCase()
              .includes(query.toLowerCase())
          )
        )
      }
    }
  }

  const handleFilterStatuses = (e) => {
    e.preventDefault()
    let statusesToFilter = sortStatuses.filter(
      (status) => status.checked === true
    )
    let parsedStatusesToFilter = statusesToFilter.map(({ status }) => status)
    setFilteredInquiries(
      initialInquiriesState.filter((inquiry) =>
        parsedStatusesToFilter.includes(inquiry.status)
      )
    )
  }

  const handleFilterZones = (e) => {
    e.preventDefault()
    let zonesToFilter = sortZones.filter((zone) => zone.checked === true)
    let parsedZonesToFilter = zonesToFilter.map(({ zone }) => zone)
    let filteredInq = initialInquiriesState.filter((inquiry) => {
      if (inquiry?.zones?.length > 0) {
        let zoneNames = inquiry?.zones?.map((zone) => zone.name)
        return parsedZonesToFilter.some((zoneName) =>
          zoneNames.includes(zoneName)
        )
      }
    })
    setFilteredInquiries(filteredInq)
  }

  const handleRadioBtnPeriodChange = (event) => {
    switch (event.target.value) {
      case "createdAt":
        setPeriodSearchQuery("Дата на създаване")
        break
      case "updatedAt":
        setPeriodSearchQuery("Последна активност")
        break
      default:
        setPeriodSearchQuery("Дата на създаване")
        break
    }
    setRadioBtnPeriod(event.target.value)
  }

  const handleRadioBtnChange = (event) => {
    switch (event.target.value) {
      case "object":
        setSearchQuery("Име на обект")
        break
      case "client":
        setSearchQuery("Име на клиент")
        break
      case "refNumber":
        setSearchQuery("Номер на оферта")
        break
      case "assignee":
        setSearchQuery("Име на отговорник")
        break
      // default:
      //   setSearchQuery("Име на обект")
      //   break
    }
    setRadioBtnValue(event.target.value)
  }
  
  useEffect(() => {
    // if (sortParams !== null) {
    //   const sorted = [...initialInquiriesState]
    //   if (sortParams === "budget") {
    //     if (reverseSort) {
    //       setFilteredInquiries(
    //         sorted.sort(
    //           (a, b) =>
    //             Number(b.budget.replace(/[, ]+/g, "").trim()) -
    //             Number(a.budget.replace(/[, ]+/g, "").trim())
    //         )
    //       )
    //     } else {
    //       setFilteredInquiries(
    //         sorted.sort(
    //           (a, b) =>
    //             Number(a.budget.replace(/[, ]+/g, "").trim()) -
    //             Number(b.budget.replace(/[, ]+/g, "").trim())
    //         )
    //       )
    //     }
    //   } else if (sortParams === "createdAt") {
    //     if (reverseSort) {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           const dateA = moment(a[sortParams])
    //           const dateB = moment(b[sortParams])
    //           return dateA.diff(dateB)
    //         })
    //       )
    //     } else {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           const dateA = moment(a[sortParams])
    //           const dateB = moment(b[sortParams])
    //           return dateB.diff(dateA)
    //         })
    //       )
    //     }
    //   } else if (sortParams === "updatedAt") {
    //     if (reverseSort) {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           const dateA = moment(a[sortParams])
    //           const dateB = moment(b[sortParams])
    //           return dateA.diff(dateB)
    //         })
    //       )
    //     } else {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           const dateA = moment(a[sortParams])
    //           const dateB = moment(b[sortParams])
    //           return dateB.diff(dateA)
    //         })
    //       )
    //     }
    //   } else if (sortParams === "assignee") {
    //     if (reverseSort) {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           return a.workin_on[0].user?.fullName.localeCompare(
    //             b.workin_on[0].user?.fullName
    //           )
    //         })
    //       )
    //     } else {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           return b.workin_on[0].user?.fullName.localeCompare(
    //             a.workin_on[0].user?.fullName
    //           )
    //         })
    //       )
    //     }
    //   } else if (
    //     sortParams === "offers" ||
    //     sortParams === "proform_invoices" ||
    //     sortParams === "invoices" ||
    //     sortParams === "actsofacceptance"
    //   ) {
    //     if (reverseSort && sortParams === "offers") {
    //       setFilteredInquiries(
    //         sorted
    //           .sort((a, b) => {
    //             return b[sortParams].length - a[sortParams].length
    //           })
    //           .sort(
    //             (a, b) =>
    //               Number(b["offers"].refNumber) - Number(a["offers"].refNumber)
    //           )
    //       )
    //     }
    //     if (!reverseSort && sortParams === "offers") {
    //       setFilteredInquiries(
    //         sorted
    //           .sort((a, b) => {
    //             return a[sortParams].length - b[sortParams].length
    //           })
    //           .sort(
    //             (a, b) =>
    //               Number(a["offers"].refNumber) - Number(b["offers"].refNumber)
    //           )
    //       )
    //     }
    //     if (reverseSort && sortParams !== "offers") {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           return b[sortParams].length - a[sortParams].length
    //         })
    //       )
    //     }
    //     if (!reverseSort && sortParams !== "offers") {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           return a[sortParams].length - b[sortParams].length
    //         })
    //       )
    //     }
    //   } else if (sortParams === "status") {
    //     setFilteredInquiries(
    //       sortBy(sorted, [
    //         function (o) {
    //           return o[sortParams] === sortStatuses
    //         },
    //       ]).reverse()
    //     )
    //   } else if (sortParams === "object" || sortParams === "client") {
    //     if (reverseSort) {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           return a[sortParams]
    //             .toLowerCase()
    //             .localeCompare(b[sortParams].toLowerCase())
    //         })
    //       )
    //     } else {
    //       setFilteredInquiries(
    //         sorted.sort((a, b) => {
    //           return b[sortParams]
    //             .toLowerCase()
    //             .localeCompare(a[sortParams].toLowerCase())
    //         })
    //       )
    //     }
    //   }
    // } else {
    //   setFilteredInquiries(initialInquiriesState)
    // }

    setFilteredInquiries(initialInquiriesState)
  }, [sortParams, reverseSort, sortStatuses])

  const handlePeriodFilter = (e) => {
    e.preventDefault()
    setFilteredInquiries(initialInquiriesState)
    if (startDate && endDate) {
      const filtered = [...initialInquiriesState]
      const firstDate = moment(startDate).format("YYYY-MM-DD")
      const secondDate = moment(endDate).format("YYYY-MM-DD")
      setFilteredInquiries(
        filtered.filter((inquiry) => {
          return (
            moment(inquiry[radioBtnPeriod]).format("YYYY-MM-DD") >= firstDate &&
            moment(inquiry[radioBtnPeriod]).format("YYYY-MM-DD") <= secondDate
          )
        })
      )
    }
    // setDateValues([null, null])
  }

  // if (inquiries.length === 0) {
  //   return (
  //     <div className="single-project-main-container">
  //       <Section.Loader loading text="Зареждане на данни" />
  //     </div>
  //   )
  // }
  return (
    <div className={styles["projects-container"]}>
      <Paper elevation={2} className={styles.projects}>
        <div className={styles["projects-header"]}>
          {/* <div className={styles["search-container"]}>
            <Paper
              component={"form"}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                inputProps={{
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation()
                      e.preventDefault()
                    }
                  },
                }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Търси по"
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Button
                style={{ textTransform: "none", color: "black" }}
                disableElevation
                disableRipple
                disableFocusRipple
                className={styles["filter-button"]}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#26ACE2" }} />}
                onClick={handleOpen}
              >
                {searchQuery}
              </Button>
              <Menu
                PaperProps={{ style: { padding: "15px" } }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div className={styles["filters-options"]}>
                  <h5>Търсене по</h5>
                  <CloseIcon
                    fontSize="small"
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <FormControl>
                  <RadioGroup
                    value={radioBtnValue}
                    onChange={handleRadioBtnChange}
                  >
                    <FormControlLabel
                      value="object"
                      control={<Radio sx={radioStyles} />}
                      label="Име на обект"
                    />
                    <FormControlLabel
                      value="client"
                      control={<Radio sx={radioStyles} />}
                      label="Име на клиент"
                    />
                    <FormControlLabel
                      value="refNumber"
                      control={<Radio sx={radioStyles} />}
                      label="Номер на оферта"
                    />
                    <FormControlLabel
                      value="assignee"
                      control={<Radio sx={radioStyles} />}
                      label="Име на отговорник"
                    />
                  </RadioGroup>
                  <Button
                    sx={{
                      background: "#26ACE2",
                      "&:hover": {
                        background: "#26ACE2",
                      },
                    }}
                    onClick={handleClose}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    fullWidth
                  >
                    Задай
                  </Button>
                </FormControl>
              </Menu>
            </Paper>
            <Button
              onClick={handleFilter}
              variant="contained"
              sx={{
                padding: "6px 25px",
                backgroundColor: "#E6EEF4",
                "&:hover": {
                  background: "#E6EEF4",
                },
                color: "black",
              }}
            >
              Търси
            </Button>
          </div>
          <div className={styles["datepicker-container"]}>
            <Input.Datepicker
              className={styles.datepicker}
              placeholder="Дата"
              pickDate={startDate}
              onChange={(date) => {
                setStartDate(date)
              }}
            />
            <Box sx={{ m: 1 }}>-</Box>
            <Input.Datepicker
              className={styles.datepicker}
              placeholder="Дата"
              pickDate={endDate}
              onChange={(date) => {
                setEndDate(date)
              }}
            />
            <TextField
              value={periodSearchQuery}
              size="small"
              disabled
              style={{
                width: "220px",
                margin: "0px 10px",
              }}
              sx={disabledTextfieldStyles}
              InputProps={{
                style: { fontSize: "14px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      onClick={handlePeriodSearchOpen}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Menu
              PaperProps={{ style: { padding: "15px" } }}
              anchorEl={periodSearchAnchor}
              open={periodSearchOpen}
              onClose={handlePeriodSearchClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div className={styles["filters-options"]}>
                <h5>Търси по</h5>
                <CloseIcon
                  fontSize="small"
                  onClick={handlePeriodSearchClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <FormControl>
                <RadioGroup
                  value={radioBtnPeriod}
                  onChange={handleRadioBtnPeriodChange}
                >
                  <FormControlLabel
                    value="createdAt"
                    control={<Radio sx={radioStyles} />}
                    label="Дата на създаване"
                  />
                  <FormControlLabel
                    value="updatedAt"
                    control={<Radio sx={radioStyles} />}
                    label="Последна активност"
                  />
                </RadioGroup>
              </FormControl>
            </Menu>
            <Button
              onClick={(e) => {
                handlePeriodFilter(e)
              }}
              variant="contained"
              sx={{
                padding: "6px 25px",
                backgroundColor: "#E6EEF4",
                "&:hover": {
                  background: "#E6EEF4",
                },
                color: "black",
              }}
            >
              Търси
            </Button>
          </div> */}
          <div>
            {/* <Button
              onClick={() => {
                setFilteredInquiries(initialInquiriesState)
                setQuery("")
                setStartDate(moment().toDate())
                setEndDate(moment().toDate())
                setSortParams("")
              }}
              variant="contained"
              sx={{
                backgroundColor: "#26ACE2",
                "&:hover": {
                  background: "#26ACE2",
                },
                color: "white",
              }}
            >
              Reset Filters
            </Button> */}
            <Button
              onClick={() =>
                showModal({
                  type: "addProductQueryModal",
                  width: "1100px",
                  overflow: "visible",
                })
              }
              variant="contained"
              sx={{
                backgroundColor: "#25E083",
                "&:hover": {
                  background: "#25E083",
                },
                color: "white",
              }}
            >
              Добави запитване
            </Button>
          </div>
        </div>
        <div className={styles["all-projects"]}>
          {inquiries.length == 0 ? (
            <p style={{ textAlign: "center", justifyContent: "center" }}>
              Все още няма създадени добавени проекти. Започнете като натиснете
              бутона “Добави запитване”
            </p>
          ) : (
            <>
              <ProjectFilters
                reverseSort={reverseSort}
                setReverseSort={setReverseSort}
                sortZones={sortZones}
                setSortZones={setSortZones}
                handleFilterStatuses={handleFilterStatuses}
                handleFilterZones={handleFilterZones}
                sortStatuses={sortStatuses}
                setSortStatuses={setSortStatuses}
                statuses={mapStatuses}
                sortParams={sortParams}
                setSortParams={setSortParams}
              />
              {filteredInquiries.map((inquiry, index) => (
                <div
                  key={inquiry?._id}
                  onClick={() => {
                    window.open(`/projects/${inquiry._id}`)
                  }}
                >
                  <ProjectCard
                    additionalStatuses={inquiry?.additionalStatuses}
                    key={inquiry?._id}
                    budget={inquiry?.budget}
                    client={inquiry?.client}
                    createdAt={inquiry?.createdAt}
                    invoices={inquiry?.invoices}
                    object={inquiry?.object}
                    offers={inquiry?.offers}
                    updatedAt={inquiry?.updatedAt}
                    proform_invoices={inquiry?.proform_invoices}
                    status={mapStatuses[inquiry?.status] || inquiry?.status}
                    // assignee={inquiry?.working_on?.[0].user}
                    assignee={inquiry?.working_on?.[0]?.user || '-'}
                    actsofacceptance={inquiry?.actsofacceptance}
                    zones={inquiry?.zones}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <Paper className={styles["statistics-container"]}>
          <p className={styles["total-projects"]}>
            Общ брой проекти: <b>{inquiries?.length}</b>
          </p>
          <p className={styles["total-projects"]}>
            Активни тази седмица: <b>{activeThisWeek}</b>
          </p>
          <p>
            Активни текущ месец: <b>{activeThisMonth}</b>
          </p>
        </Paper>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  zones: state.inquiries.zones
})
const mapDispatchToProps = (dispatch) => ({
  getZones: () => dispatch(getZones()),
  showModal: (payload) => dispatch(showModal(payload)),
  removeInquiry: (payload) => dispatch(deleteInquiry(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
