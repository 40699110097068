export const ContactsTypes = {
  SET_CROPPED_DATA: "contacts/SET_CROPPED_DATA",
  GET_CONTACTS: "contacts/GET_CONTACTS",
  GET_CONTACTS_SUCCESS: "contacts/GET_CONTACTS_SUCCESS",
  GET_SINGLE_CONTACT: "contacts/GET_SINGLE_CONTACT",
  GET_SINGLE_CONTACT_SUCCESS: "contacts/GET_SINGLE_CONTACT_SUCCESS",
  ADD_CONTACT: "contacts/ADD_CONTACT",
  ADD_CONTACT_SUCCESS: "contacts/ADD_CONTACT_SUCCESS",
  EDIT_CONTACT: "contacts/EDIT_CONTACT",
  EDIT_CONTACT_SUCCESS: "contacts/EDIT_CONTACT_SUCCESS",
  DELETE_CONTACT: "contacts/DELETE_CONTACT",
}

export const setCroppedData = (payload) => ({
  type: ContactsTypes.SET_CROPPED_DATA,
  payload,
})
export const getContacts = (payload) => ({
  type: ContactsTypes.GET_CONTACTS,
  payload,
})
export const getCurrentContact = (payload) => ({
  type: ContactsTypes.GET_SINGLE_CONTACT,
  payload,
})
export const addContact = (payload) => ({
  type: ContactsTypes.ADD_CONTACT,
  payload,
})
export const editContact = (payload) => ({
  type: ContactsTypes.EDIT_CONTACT,
  payload,
})
export const deleteContact = (payload) => ({
  type: ContactsTypes.DELETE_CONTACT,
  payload,
})
