/* eslint-disable */
import React from "react"
import "./styles.scss"
import { forwardRef } from "react"
import Select, { components } from "react-select"
import ArrowDown from "../../../assets/icons/arrowDown.svg"

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: "100%",
    height: 39,
    display: "flex",
    border: "1px solid #005ea3",
    borderRadius: "7px",
    fontSize: "16px",
    fontFamily: "Montserrat, sans-serif",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "1em",
    color: "red",
    fontWeight: 400,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        className="close-icon"
        style={{
          backgroundImage: `url(${ArrowDown})`,
          width: "20px",
          height: "20px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
    </components.DropdownIndicator>
  )
}

const SingleSelect = forwardRef(
  (
    {
      optionsArray,
      disabled,
      onChange,
      label,
      compulsory,
      className,
      outerClassName,
      outerStyle,
      value,
      isMulti,
    },
    ref
  ) => {
    return (
      <div className={`${outerClassName} input-container`} style={outerStyle}>
        {label && <label className="input-header">{label}</label>}

        <Select
          options={optionsArray.map((opt) => {
            return { label: opt.label, value: opt.value }
          })}
          ref={ref}
          isDisabled={disabled}
          styles={customStyles}
          className={className}
          placeholder={null}
          value={value}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          onChange={onChange}
        />
      </div>
    )
  }
)

export default SingleSelect
