import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import { Button, Input, Icon, Section } from "@makedonski/admin-ui"
import moment from "moment"
import { Inputs } from "../../components/Inputs"
import {
  QuantityDropdownInput,
  ProductionCommentsSection,
} from "../../components"
import {
  getSingleSchedule,
  checkTimeSlot,
  updateSchedule,
  showLoading,
  finishRequest,
  searchRequests,
  searchStorageProducts,
  getPerformers,
  editGeneralComment,
  finishFinishingWork,
  getCurrentAction,
  editActionItem,
  resetCurrentAction,
} from "../../actions"

import "./styles.scss"

const DURATION_OPTIONS = [
  { label: "30 мин", value: "30" },
  { label: "1 час", value: "60" },
  { label: "1ч. 30м.", value: "90" },
  { label: "2 часа", value: "120" },
  { label: "2ч. 30м.", value: "150" },
  { label: "3 часа", value: "180" },
  { label: "3ч. 30м.", value: "210" },
  { label: "4 часа", value: "240" },
  { label: "4ч. 30м.", value: "270" },
  { label: "5 часа", value: "300" },
  { label: "5ч. 30м.", value: "330" },
  { label: "6 часа", value: "360" },
  { label: "6ч. 30м.", value: "390" },
  { label: "7 часа", value: "420" },
  { label: "7ч. 30м.", value: "450" },
  { label: "8 часа", value: "480" },
]

const Schedule = ({
  currentSchedule,
  machineSchedules,
  checkTimeSlotAction,
  getSingleScheduleAction,
  updateScheduleAction,
  loading,
  finishRequestAction,
  results,
  storageProductResults,
  searchRequests,
  searchStorageProducts,
  performers,
  getPerformers,
  editComment,
  finishFinishingWork,
  currentAction,
  getCurrentAction,
  editActionItem,
  resetCurrentAction,
}) => {
  const { id } = useParams()
  let finishWorksRef = useRef(null)
  const [otherActionPayload, setOtherActionPayload] = useState({
    activity: "",
    description: "",
    start: null,
    end: null,
    performers: [""],
  })
  const history = useHistory()
  const [isEditing, setIsEditing] = useState(false)
  const [isTimeSlotAvailable, setIsTimeSlotAvailable] = useState(true)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [activitiesNumber, setActivitiesNumber] = useState(1)
  const [performerField, setPerformerField] = useState(null)
  const [activitiesToFinish, setActivitiesToFinish] = useState([])
  const [activities, setActivities] = useState([
    {
      request: currentSchedule && currentSchedule.request._id,
      activity: "",
      date: moment().toDate(),
      startsAt: moment().toDate(),
      performers: [],
      rate: "12",
      durationMinutes: "30",
      comments: [{ text: "" }],
      materials: [{ name: "", quantity: "", total: 0, ratio: null }],
    },
  ])
  const isFinished = React.useMemo(() => {
    if (currentSchedule) {
      if (currentSchedule.request) {
        if (currentSchedule.request.status === "finished") return true
      }
    }
    return false
  }, [currentSchedule])
  const prepareScheduled = (scheduled) => {
    if (!scheduled) return {}
    const { _id, product, ...rest } = scheduled
    rest.duration = `${moment
      .duration(moment(currentSchedule.end).diff(moment(currentSchedule.start)))
      .asMinutes()}`
    return rest
  }
  const [query, setQuery] = useState({
    ...prepareScheduled(currentSchedule),
  })

  useEffect(() => {
    setQuery({ ...prepareScheduled(currentSchedule) })
    setIsEditing(false)
    //eslint-disable-next-line
  }, [currentSchedule])

  useEffect(() => {
    getSingleScheduleAction({ _id: id })
    //eslint-disable-next-line
  }, [getSingleScheduleAction])

  const updateQuery = (key, value) => {
    const dup = { ...query }
    dup[key] = value
    setQuery(dup)
  }

  useEffect(() => {
    getPerformers({ search: "" })
  }, [getPerformers])

  useEffect(() => {
    if (!currentAction) {
      getCurrentAction(id)
    }
  }, [currentAction, getCurrentAction, id])

  useEffect(() => {
    if (currentAction) {
      setOtherActionPayload(currentAction)
    }
  }, [currentAction])

  useEffect(() => {
    return () => {
      resetCurrentAction()
    }
  }, [resetCurrentAction])

  useEffect(() => {
    if (currentSchedule) {
      const activities = (currentSchedule.request &&
        currentSchedule.request.finishingWorks.map((work) => {
          return {
            _id: work._id ? work._id : null,
            request: currentSchedule.request._id,
            activity: work.activity,
            date: moment(work.date).toDate(),
            startsAt: moment().startOf("hour").toDate(),
            performers: work.performers,
            rate: "12",
            durationMinutes: work.durationMinutes,
            comments: work.comments ? work.comments : [{ text: "" }],
            materials: work.materials
              ? work.materials
              : [{ name: "", quantity: "", total: 0, ratio: null }],
            state: work.state,
          }
        })) || [
        {
          request: currentSchedule && currentSchedule.request._id,
          activity: "",
          date: moment().toDate(),
          startsAt: moment().toDate(),
          performers: [],
          rate: "12",
          durationMinutes: "30",
          comments: [{ text: "" }],
          materials: [{ name: "", quantity: "", total: 0, ratio: null }],
        },
      ]
      setActivities(activities)
      setActivitiesNumber(activities.length)
    }
  }, [currentSchedule])

  const updateMachineSettings = (key, value) => {
    const update = { ...query }
    update.machineSettings[key] = value
    setQuery(update)
  }

  useEffect(() => {
    searchRequests({ search: "" })
    searchStorageProducts({ search: "" })
  }, [searchStorageProducts, searchRequests])

  useEffect(() => {
    const { start } = query
    if (start !== "") {
      checkTimeSlotAction({
        start: moment(start),
        end: moment(start).add(query.duration, "minutes"),
        groupBy: "_id",
      })
    }
    //eslint-disable-next-line
  }, [query.start, query.duration])

  useEffect(() => {
    if (query.machine && Object.keys(machineSchedules).length > 0) {
      const schedules =
        (machineSchedules[query.machine.name] &&
          machineSchedules[query.machine.name].filter((el) => el._id !== id)) ||
        []
      setIsTimeSlotAvailable(schedules.length === 0)
      setShowErrorPopup(!!schedules.length)
    } else {
      setIsTimeSlotAvailable(true)
      setShowErrorPopup(false)
    }
    //eslint-disable-next-line
  }, [machineSchedules, query.machine, query.start])

  const getProductVariation = (product) => {
    if (!product) return "Не-е-дефиниран"
    return `${product.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")}`
  }
  const totalProducts = [...results, ...storageProductResults]

  let tubeName = ""
  if (currentSchedule?.product.productType.name === "Тръба") {
    const type = currentSchedule.product.basicInfo.find(
      (el) => el.name === "Произвежда се по"
    )
    const SN = currentSchedule.product.basicInfo.find(
      (el) => el.name === "Коравина"
    )
    if (type.value === "Вътрешен диаметър") {
      const ID = currentSchedule.product.basicInfo.find(
        (el) => el.name === "Вътрешен диаметър"
      )
      tubeName = `SN${SN?.value}ID${ID?.value}`
    } else {
      const OD = currentSchedule.product.basicInfo.find(
        (el) => el.name === "Външен диаметър"
      )
      tubeName = `SN${SN?.value}OD${OD?.value}`
    }
  }
  return (
    <div className="schedule-view-container">
      <div className="item-container">
        {currentSchedule ? (
          <>
            <div className="item-wrapper">
              <div className="item-headings">
                <h2 id="link">
                  <div
                    className="back-button ripple"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    <Icon name="angle-left" size="35px" color="#005EA3" />
                  </div>
                  {currentSchedule.activity}
                </h2>
                <h4
                  id="link"
                  onClick={() =>
                    history.push(`/products/${currentSchedule.product._id}`)
                  }
                >
                  <b>
                    {currentSchedule.product.productType.name !== "Тръба"
                      ? currentSchedule.product.productType.name
                      : tubeName}
                  </b>
                  {getProductVariation(currentSchedule.product)}
                </h4>
              </div>
              <div className="item-actions">
                {isFinished && (
                  <Button.Raised
                    className="finished"
                    clear
                    text="Приключен"
                    onClick={() => {}}
                  />
                )}
                {!isFinished && (
                  <>
                    <Button.Raised
                      clear
                      text={isEditing ? "Откажи" : "Редактирай"}
                      onClick={() => {
                        if (!isEditing) {
                          setIsEditing(true)
                        } else {
                          setIsEditing(false)
                        }
                      }}
                    />
                    {!isEditing ? (
                      <Section.Popup
                        ref={(ref) => {
                          finishWorksRef = ref
                        }}
                        content={
                          <div className="finish-popup-container">
                            <div className="top-container">
                              <h4>Избери дейности</h4>
                              <div
                                className="close-btn"
                                onClick={() => finishWorksRef.hide()}
                              />
                            </div>
                            <div className="scroll-container">
                              {activities.map((activity, index) => {
                                const foundIndex = activitiesToFinish.findIndex(
                                  (el) => el._id === activity._id
                                )
                                return (
                                  <div
                                    className={`flex-container ${
                                      activity.state === "finished" &&
                                      "finished"
                                    }`}
                                  >
                                    <p>{`Дейност-${index + 1}`}</p>
                                    <div
                                      className={`checkbox-container ${
                                        (foundIndex !== -1 ||
                                          activity.state === "finished") &&
                                        "selected"
                                      }`}
                                      onClick={() => {
                                        let newActivitiesToFinish = activitiesToFinish.slice(
                                          0
                                        )
                                        if (foundIndex === -1) {
                                          newActivitiesToFinish.push(activity)
                                        } else {
                                          newActivitiesToFinish.splice(
                                            foundIndex,
                                            1
                                          )
                                        }
                                        setActivitiesToFinish(
                                          newActivitiesToFinish
                                        )
                                      }}
                                    >
                                      {(foundIndex !== -1 ||
                                        activity.state === "finished") && (
                                        <div className="checkmark" />
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <Button.Raised
                              text="Завърши"
                              onClick={() => {
                                finishFinishingWork({
                                  request: currentSchedule.request._id,
                                  ids: activitiesToFinish.map(
                                    (activity) => activity._id
                                  ),
                                })
                                finishWorksRef.hide()
                              }}
                            />
                          </div>
                        }
                      >
                        <Button.Raised
                          text={isEditing ? "Запиши" : "Завърши"}
                          onClick={() => {
                            const finishedIndex = activities.findIndex(
                              (el) => el.state === "work-in-proggress"
                            )
                            if (
                              currentSchedule.product.productType.name ===
                              "Тръба"
                            ) {
                              finishRequestAction({
                                _id: currentSchedule.request._id,
                              })
                            } else {
                              if (finishedIndex === -1) {
                                finishRequestAction({
                                  _id: currentSchedule.request._id,
                                })
                              } else {
                                finishWorksRef.show()
                              }
                            }
                          }}
                        />
                      </Section.Popup>
                    ) : (
                      <Button.Raised
                        text={isEditing ? "Запиши" : "Завърши"}
                        onClick={() => {
                          const {
                            machine,
                            end,
                            __v,
                            updatedAt,
                            createdAt,
                            ...update
                          } = query

                          const updateID = update.id
                          delete update.id
                          update.finishingWorks = activities
                          loading({ text: "Записване на данните..." })
                          updateScheduleAction({ _id: updateID, ...update })
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="edit-schedule-section auto-height">
              <h3>Основна информация</h3>
              <div className="flex-container">
                <div className="input-elements bigger">
                  <div className="flex-container responsive">
                    <div className="input-elements fifth">
                      <p>К-во</p>
                      <QuantityDropdownInput
                        quantityValue={query.quantity}
                        onSetQuantity={(val) => {
                          updateQuery("quantity", val)
                        }}
                        quantityTypeValue={{ value: "бр" }}
                        noDropdown
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="input-elements fifth">
                      <p>Ден</p>
                      <Input.Datepicker
                        placeholder="Дата"
                        disabled={!isEditing}
                        pickDate={moment(query.start).toDate()}
                        onChange={(date) => updateQuery("start", date)}
                      />
                    </div>
                    <div className="input-elements fifth">
                      <p>Час</p>
                      <div className="flex-container">
                        <Input.Datepicker
                          className={
                            isTimeSlotAvailable ? "" : "error-time-slot"
                          }
                          disabled={!isEditing}
                          placeholder={`Час ${
                            showErrorPopup ? "true" : "false"
                          }`}
                          pickDate={moment(query.start).toDate()}
                          onChange={(date) => {
                            updateQuery("start", date)
                            // checkMachineAvailability()
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Час"
                          dateFormat="HH:mm"
                        />
                        {showErrorPopup &&
                          isEditing &&
                          currentSchedule.product.productType.name ===
                            "Тръба" && (
                            <div className="error-time-slot-container">
                              <h2>Дублиране на час</h2>
                              <p className="error-helper-text">
                                Зададеният час и времетраене не съответсват с
                                графика за деня на машина OD800. Моля сменете
                                часа
                              </p>
                              <div
                                className="close-btn"
                                onClick={() => {
                                  setShowErrorPopup(false)
                                }}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="input-elements fifth">
                      <p>Времетраене</p>
                      <Input.Dropdown
                        disabled={!isEditing}
                        options={DURATION_OPTIONS}
                        value={
                          parseInt(query.duration) > 480
                            ? `${(
                                parseInt(query.duration) / 60
                              ).toString()} часа`
                            : query.duration
                        }
                        placeholder=""
                        onChange={({ value }) => {
                          updateQuery("duration", value)
                        }}
                      />
                    </div>
                    <div className="input-elements fifth">
                      <p>До</p>
                      <Input.Datepicker
                        disabled={!isEditing}
                        placeholder="Дата"
                        pickDate={moment(query.start)
                          .add(query.duration, "minutes")
                          .toDate()}
                        onChange={(date) => {
                          const diffInMinutes = moment(query.start).diff(
                            moment(date),
                            "minutes"
                          )
                          updateQuery("duration", Math.abs(diffInMinutes))
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {currentSchedule.product.productType.name === "Тръба" && (
              <div className="edit-schedule-section auto-height">
                <h3>Настройки на машината</h3>
                <div className="flex-container">
                  <div className="machine-container">
                    <Button.Raised text={currentSchedule.machine.name} />
                  </div>
                </div>
                <h4>Материали</h4>
                <div className="flex-container">
                  {query.machineSettings && (
                    <div className="flex-container flex-wrap">
                      {query.machineSettings.materials?.map((mat, index) => {
                        return (
                          <div className="material-container">
                            <div className="elements-flex-2">
                              <p>Материал {index + 1}</p>
                              <Input.Text
                                value={mat.name}
                                onChange={(e) => {
                                  const mats = query.machineSettings.materials.slice(
                                    0
                                  )
                                  mats[index].name = e.target.value
                                  updateMachineSettings("materials", mats)
                                }}
                                disabled={!isEditing}
                              />
                            </div>
                            <div className="elements-flex-1">
                              <p>Съотношение</p>
                              <QuantityDropdownInput
                                noCurrency
                                disabled={!isEditing}
                                quantityValue={
                                  mat.ratio ? mat.ratio : mat.quantity
                                }
                                quantityTypeValue={
                                  mat.ratio ? { value: "%" } : { value: "бр" }
                                }
                                onSetQuantity={(val) => {
                                  const mats = query.machineSettings.materials.slice(
                                    0
                                  )
                                  if (mats[index].ratio) {
                                    mats[index].ratio = val
                                  } else {
                                    mats[index].quantity = val
                                  }

                                  updateMachineSettings("materials", mats)
                                }}
                                noDropdown
                              />
                            </div>
                            {query.machineSettings.materials.length !==
                              index + 1 && <div className="spacer" />}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>

                <h4>Геометрични размери, коравина и тегло</h4>
                {query.machineSettings && (
                  <div className="inputs-container">
                    <div className="inputs-container-fields">
                      <p>ID мм</p>
                      <Input.Text
                        value={query.machineSettings.geometry.innerDiameter}
                        onChange={(e) => {
                          const gm = { ...query.machineSettings.geometry }
                          gm.innerDiameter = e.target.value
                          updateMachineSettings("geometry", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="inputs-container-fields">
                      <p>Калибър мм</p>
                      <Input.Text
                        value={query.machineSettings.geometry.caliber}
                        onChange={(e) => {
                          const gm = { ...query.machineSettings.geometry }
                          gm.caliber = e.target.value
                          updateMachineSettings("geometry", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="inputs-container-fields">
                      <p>ф 1 | ф2</p>
                      <Input.Text
                        value={query.machineSettings.geometry.f1f2}
                        onChange={(e) => {
                          const gm = { ...query.machineSettings.geometry }
                          gm.f1f2 = e.target.value
                          updateMachineSettings("geometry", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="inputs-container-fields" />
                    <div className="inputs-container-fields" />
                    {/* <div className="inputs-container-fields">
                <p>Коравина SN</p>
                <Input.Text value={currentSchedule.machineSettings.geometry.stiffnes} disabled />
              </div>
              <div className="inputs-container-fields">
                <p>Тегло кг/м</p>
                <Input.Text value={currentSchedule.machineSettings.geometry.weight} disabled />
              </div> */}
                  </div>
                )}

                <h4>Екструдер за профил SJ90, t</h4>
                {query.machineSettings && (
                  <div className="inputs-container">
                    {query.machineSettings.extruderSJ90.profiles.map(
                      (el, index) => {
                        return (
                          <div className="inputs-container-width">
                            <p>{index + 1}</p>
                            <Input.Text
                              value={el}
                              onChange={(e) => {
                                const update = {
                                  ...query.machineSettings.extruderSJ90,
                                }
                                update.profiles[index] = e.target.value
                                updateMachineSettings("extruderSJ90", update)
                              }}
                              disabled={!isEditing}
                            />
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
                {query.machineSettings && (
                  <div className="inputs-container">
                    <div className="elements-flex-1 row-flex">
                      <div className="inputs-container-fields">
                        <p>Двигател ток А</p>
                        <Input.Text
                          value={
                            query.machineSettings.extruderSJ90.motor.current
                          }
                          onChange={(e) => {
                            const update = {
                              ...query.machineSettings.extruderSJ90,
                            }
                            update.motor.current = e.target.value
                            updateMachineSettings("extruderSJ90", update)
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                      <div className="inputs-container-fields">
                        <p>Двигател оɗ/min</p>
                        <Input.Text
                          value={
                            query.machineSettings.extruderSJ90.motor.revolutions
                          }
                          onChange={(e) => {
                            const update = {
                              ...query.machineSettings.extruderSJ90,
                            }
                            update.motor.revolutions = e.target.value
                            updateMachineSettings("extruderSJ90", update)
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="elements-flex-1" />
                  </div>
                )}

                <h4>Екструдер за профил SJ65, t</h4>
                {query.machineSettings && (
                  <div className="inputs-container">
                    {currentSchedule.machineSettings.extruderSJ65.profiles.map(
                      (el, index) => {
                        return (
                          <div className="inputs-container-width">
                            <p>{index + 1}</p>
                            <Input.Text
                              value={el}
                              onChange={(e) => {
                                const update = {
                                  ...query.machineSettings.extruderSJ65,
                                }
                                update.profiles[index] = e.target.value
                                updateMachineSettings("extruderSJ65", update)
                              }}
                              disabled={!isEditing}
                            />
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
                {query.machineSettings && (
                  <div className="inputs-container">
                    <div className="elements-flex-1 row-flex">
                      <div className="inputs-container-fields">
                        <p>Двигател ток А</p>
                        <Input.Text
                          value={
                            query.machineSettings.extruderSJ65.motor.current
                          }
                          onChange={(e) => {
                            const update = {
                              ...query.machineSettings.extruderSJ65,
                            }
                            update.motor.current = e.target.value
                            updateMachineSettings("extruderSJ65", update)
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                      <div className="inputs-container-fields">
                        <p>Двигател оɗ/min</p>
                        <Input.Text
                          value={
                            query.machineSettings.extruderSJ65.motor.revolutions
                          }
                          onChange={(e) => {
                            const update = {
                              ...query.machineSettings.extruderSJ65,
                            }
                            update.motor.revolutions = e.target.value
                            updateMachineSettings("extruderSJ65", update)
                          }}
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="elements-flex-1" />
                  </div>
                )}
                {query.machineSettings && (
                  <div className="inputs-container">
                    <div className="inputs-container-fields">
                      <p>Вакуум</p>
                      <Input.Text
                        value={query.machineSettings.geometry.vacuum}
                        onChange={(e) => {
                          const gm = { ...query.machineSettings.geometry }
                          gm.vacuum = e.target.value
                          updateMachineSettings("geometry", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="inputs-container-fields">
                      <p>Дюза</p>
                      <Input.Text
                        value={query.machineSettings.injector}
                        onChange={(e) => {
                          let gm = query.machineSettings.injector
                          gm = e.target.value
                          updateMachineSettings("injector", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="inputs-container-fields">
                      <p>Нав. сис. честота Hz</p>
                      <Input.Text
                        value={query.machineSettings.windingSystem}
                        onChange={(e) => {
                          let gm = query.machineSettings.windingSystem
                          gm = e.target.value
                          updateMachineSettings("windingSystem", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="inputs-container-fields">
                      <p>Циркуляр за рязане - мин</p>
                      <Input.Text
                        value={query.machineSettings.buzzSaw.min}
                        onChange={(e) => {
                          const gm = { ...query.machineSettings.buzzSaw }
                          gm.min = e.target.value
                          updateMachineSettings("buzzSaw", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="inputs-container-fields">
                      <p>Циркуляр за рязане - ход</p>
                      <Input.Text
                        value={query.machineSettings.buzzSaw.move}
                        onChange={(e) => {
                          const gm = { ...query.machineSettings.buzzSaw }
                          gm.move = e.target.value
                          updateMachineSettings("buzzSaw", gm)
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {currentSchedule.product.productType.name !== "Тръба" && (
              <div className="edit-schedule-section">
                <div className="flex-container flex-start">
                  <h3>Довършителни дейности</h3>
                  <Input.Dropdown
                    disabled={!isEditing}
                    placeholder="брой"
                    value={activitiesNumber.toString()}
                    options={[1, 2, 3, 4, 5, 6, 7]}
                    onChange={(value) => {
                      const newActivities = Array.from(
                        Array(value.value).keys()
                      ).map((act, index) => {
                        return {
                          request: currentSchedule._id,
                          activity: activities[index]
                            ? activities[index].activity
                            : "",
                          date: activities[index]
                            ? activities[index].date
                            : moment().toDate(),
                          startsAt: activities[index]
                            ? activities[index].startsAt
                            : moment().toDate(),
                          performers: activities[index]
                            ? activities[index].performers
                            : [],
                          rate: "12",
                          durationMinutes: activities[index]
                            ? activities[index].durationMinutes
                            : "30",
                          comments: activities[index]
                            ? activities[index].comments
                            : [],
                          materials: activities[index]
                            ? activities[index].materials
                            : [{ name: "", quantity: "" }],
                        }
                      })
                      setActivities(newActivities)
                      setActivitiesNumber(value.value)
                    }}
                  />
                </div>
                {activities
                  .sort((a, b) => a.date.valueOf() - b.date.valueOf())
                  .map((activity, index) => {
                    return (
                      <>
                        <div
                          key={`${index}-activity`}
                          className="flex-container"
                        >
                          <div className="input-elements">
                            <p id="bold">Дейност {index + 1}</p>
                            <Input.Text
                              disabled={
                                !isEditing || activity.state === "finished"
                              }
                              value={activity.activity}
                              onChange={(event) => {
                                const newActivities = activities.slice(0)
                                newActivities[index].activity =
                                  event.target.value
                                setActivities(newActivities)
                              }}
                            />
                          </div>
                          <div className="input-elements">
                            <div className="flex-container flex-start">
                              <div className="input-elements smaller">
                                <p>Ден</p>
                                <Input.Datepicker
                                  disabled={
                                    !isEditing || activity.state === "finished"
                                  }
                                  placeholder="Дата"
                                  pickDate={activity.date}
                                  onChange={(date) => {
                                    const newActivities = activities.slice(0)
                                    newActivities[index].date = date
                                    setActivities(newActivities)
                                  }}
                                />
                              </div>
                              <div className="input-elements smaller">
                                <p>Час</p>
                                <Input.Datepicker
                                  disabled={
                                    !isEditing || activity.state === "finished"
                                  }
                                  placeholder="Час"
                                  pickDate={activity.date}
                                  onChange={(date) => {
                                    const newActivities = activities.slice(0)
                                    newActivities[index].date = date
                                    setActivities(newActivities)
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  timeCaption="Час"
                                  dateFormat="HH:mm"
                                />
                              </div>
                              <div className="input-elements smaller">
                                <p>Времетраене</p>
                                <Input.Dropdown
                                  disabled={
                                    !isEditing || activity.state === "finished"
                                  }
                                  placeholder=""
                                  options={DURATION_OPTIONS}
                                  value={activity.durationMinutes.toString()}
                                  onChange={({ value }) => {
                                    let newActivities = activities.slice(0)
                                    newActivities[index].durationMinutes = value
                                    setActivities(newActivities)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`flex-container margin-bottom`}>
                          <div className="input-elements">
                            <p>Коментар</p>
                            <Input.Text
                              value={
                                activity.comments.length > 0
                                  ? activity.comments[0].text
                                  : ""
                              }
                              onBlur={() => {
                                if (activity._id) {
                                  editComment({
                                    _id: activity.comments[0]._id,
                                    text: activity.comments[0].text,
                                    ref: activity._id,
                                    refType: "finishingWorks",
                                  })
                                }
                              }}
                              onChange={(event) => {
                                let newActivities = activities.slice(0)
                                newActivities[index].comments[0].text =
                                  event.target.value
                                setActivities(newActivities)
                              }}
                              disabled={
                                !isEditing || activity.state === "finished"
                              }
                            />
                          </div>
                          <div className="input-elements">
                            <div className="input-elements flex-wrap">
                              {activity.performers.map(
                                (performer, performerIndex) => {
                                  return (
                                    <div className="performer-wrapper">
                                      <div className="flex-container">
                                        <p>{`Изпълнител ${
                                          1 + performerIndex
                                        }`}</p>
                                        {isEditing &&
                                          activity.state !== "finished" &&
                                          activity.performers.length > 1 && (
                                            <div
                                              className="remove-btn"
                                              onClick={() => {
                                                let newActivities = activities.slice(
                                                  0
                                                )
                                                newActivities[
                                                  index
                                                ].performers.splice(
                                                  performerIndex,
                                                  1
                                                )
                                                setActivities(newActivities)
                                              }}
                                            />
                                          )}
                                      </div>
                                      <Input.Text
                                        disabled={
                                          !isEditing ||
                                          activity.state === "finished"
                                        }
                                        value={performer}
                                        onFocus={() =>
                                          setPerformerField(performerIndex)
                                        }
                                        onChange={(event) => {
                                          let newActivities = activities.slice(
                                            0
                                          )
                                          newActivities[index].performers[
                                            performerIndex
                                          ] = event.target.value
                                          setActivities(newActivities)
                                        }}
                                      />
                                      {performerField === performerIndex && (
                                        <div className="performers-container">
                                          {performers
                                            .filter((el) =>
                                              el
                                                .toLowerCase()
                                                .includes(
                                                  performer.toLowerCase()
                                                )
                                            )
                                            .map((performer) => {
                                              return (
                                                <p
                                                  onClick={() => {
                                                    let newActivities = activities.slice(
                                                      0
                                                    )
                                                    newActivities[
                                                      index
                                                    ].performers[
                                                      performerIndex
                                                    ] = performer
                                                    setPerformerField(null)
                                                    setActivities(newActivities)
                                                  }}
                                                >
                                                  {performer}
                                                </p>
                                              )
                                            })}
                                        </div>
                                      )}
                                    </div>
                                  )
                                }
                              )}
                              <Button.Icon
                                name="plus"
                                size={14}
                                color="blue"
                                className=""
                                iconStyles=""
                                onClick={() => {
                                  if (
                                    isEditing &&
                                    activity.state !== "finished"
                                  ) {
                                    const newActivities = activities.slice(0)
                                    newActivities[index].performers.push("")
                                    setActivities(newActivities)
                                  }
                                }}
                              />
                            </div>
                            {/* <div className="input-elements third">
                          <p>Норма</p>
                          <QuantityDropdownInput
                            noCurrency
                            quantityTypeValue={{ value: "бр./м" }}
                            noDropdown
                          />
                        </div> */}
                          </div>
                        </div>
                        <div
                          className={`materials-container ${
                            1 + index === activitiesNumber && "no-border"
                          }`}
                        >
                          <div className="flex-container flex-start">
                            <h4>Материали</h4>
                            <Input.Dropdown
                              disabled={
                                !isEditing || activity.state === "finished"
                              }
                              placeholder=""
                              value={activity.materials.length.toString()}
                              options={[1, 2, 3, 4, 5]}
                              onChange={(value) => {
                                const newMaterials = Array.from(
                                  Array(value.value).keys()
                                ).map((mat, index) => {
                                  return {
                                    name: activity.materials[index]
                                      ? activity.materials[index].name
                                      : "",
                                    quantity: activity.materials[index]
                                      ? activity.materials[index].quantity
                                      : "",
                                  }
                                })
                                let newActivities = activities.slice(0)
                                newActivities[index].materials = newMaterials
                                setActivities(newActivities)
                              }}
                            />
                          </div>
                          <div className="materials-wrap">
                            {activity.materials.map((el, materialIndex) => {
                              return (
                                <div className="flex-container flex-start">
                                  <div className="input-elements small">
                                    <div className="flex-container">
                                      <p>Материал {1 + materialIndex}</p>
                                      {isEditing &&
                                        activity.state !== "finished" && (
                                          <div
                                            className="remove-btn"
                                            onClick={() => {
                                              let newActivities = activities.slice(
                                                0
                                              )
                                              newActivities[
                                                index
                                              ].materials.splice(
                                                materialIndex,
                                                1
                                              )
                                              setActivities(newActivities)
                                            }}
                                          />
                                        )}
                                    </div>
                                    <Inputs.AutoSuggest
                                      disabled={
                                        !isEditing ||
                                        activity.state === "finished"
                                      }
                                      placeholder=""
                                      ref={React.createRef()}
                                      options={totalProducts
                                        .filter((product) => {
                                          if (product.material) {
                                            return product.material
                                              .toLowerCase()
                                              .includes(el.name.toLowerCase())
                                          } else {
                                            return product.type
                                              .toLowerCase()
                                              .includes(el.name.toLowerCase())
                                          }
                                        })
                                        .map((el) => {
                                          if (el.material) {
                                            return { title: el.material, ...el }
                                          } else {
                                            return { title: el.type, ...el }
                                          }
                                        })}
                                      updateOptions={(payload) => {}}
                                      value={el.name}
                                      setValue={(newValue) => {
                                        let newActivities = activities.slice(0)
                                        newActivities[index].materials[
                                          materialIndex
                                        ].material = newValue
                                        setActivities(newActivities)
                                      }}
                                      onSuggestionClick={(result) => {
                                        let newActivities = activities.slice(0)
                                        const singlePrice = result.pricing
                                          ? result.pricing.singleUnitPrice
                                          : result.price
                                        newActivities[index].materials[
                                          materialIndex
                                        ].material = result.material
                                          ? result.material
                                          : result.type
                                        newActivities[index].materials[
                                          materialIndex
                                        ].total =
                                          parseInt(el.quantity) * singlePrice
                                        setActivities(newActivities)
                                      }}
                                      handleFocus={() => {}}
                                    />
                                  </div>
                                  <div className="input-elements smaller">
                                    <p>Количество</p>
                                    <Input.Text
                                      value={el.quantity}
                                      disabled={
                                        !isEditing ||
                                        activity.state === "finished"
                                      }
                                      onChange={(event) => {
                                        let newActivities = activities.slice(0)
                                        const product = totalProducts.find(
                                          (product) => {
                                            if (product.material) {
                                              return (
                                                product.material === el.name
                                              )
                                            } else {
                                              return product.type === el.name
                                            }
                                          }
                                        )
                                        const singlePrice = product.pricing
                                          ? product.pricing.singleUnitPrice
                                          : product.price
                                        newActivities[index].materials[
                                          materialIndex
                                        ].quantity = event.target.value
                                        newActivities[index].materials[
                                          materialIndex
                                        ].total =
                                          parseInt(event.target.value) *
                                          singlePrice
                                        setActivities(newActivities)
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </>
                    )
                  })}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex-container shadow space-between">
              <div className="flex-container">
                <div className="icon back" onClick={() => history.goBack()} />
                <h1>Допълнителни</h1>
              </div>
              <div className="flex-container">
                <Button.Raised
                  disabled={otherActionPayload.status === "finished"}
                  clear
                  text={isEditing ? "Откажи" : "Редактирай"}
                  onClick={() => setIsEditing((prev) => !prev)}
                />
                <Button.Raised
                  disabled={otherActionPayload.status === "finished"}
                  text={
                    isEditing
                      ? "Запази"
                      : otherActionPayload.status === "finished"
                      ? "Завършен"
                      : "Завърши"
                  }
                  onClick={() => {
                    if (isEditing) {
                      editActionItem(otherActionPayload)
                      setIsEditing(false)
                    } else {
                      editActionItem({
                        _id: otherActionPayload._id,
                        status: "finished",
                      })
                    }
                  }}
                />
              </div>
            </div>
            <h3>Основна информация</h3>
            <div className="flex-container space-between">
              <div className="input-element">
                <p>Дейност</p>
                <Input.Text
                  disabled={
                    otherActionPayload.status === "finished" || !isEditing
                  }
                  value={otherActionPayload.activity}
                  onChange={(event) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.activity = event.target.value
                    setOtherActionPayload(newActionPayload)
                  }}
                />
              </div>
              <div className="input-element">
                <p>Описание</p>
                <Input.Text
                  disabled={
                    otherActionPayload.status === "finished" || !isEditing
                  }
                  value={otherActionPayload.description}
                  onChange={(event) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.description = event.target.value
                    setOtherActionPayload(newActionPayload)
                  }}
                />
              </div>
            </div>
            <div className="flex-container space-between">
              <div className="input-element">
                <p>Начало</p>
                <Input.Datepicker
                  disabled={
                    otherActionPayload.status === "finished" || !isEditing
                  }
                  placeholder="Дата"
                  pickDate={
                    otherActionPayload.start
                      ? moment(otherActionPayload.start).toDate()
                      : ""
                  }
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.start = date
                    setOtherActionPayload(newActionPayload)
                  }}
                />
              </div>
              <div className="input-element">
                <p>Начален час</p>
                <Input.Datepicker
                  disabled={
                    otherActionPayload.status === "finished" || !isEditing
                  }
                  placeholder="Час"
                  pickDate={
                    otherActionPayload.start
                      ? moment(otherActionPayload.start).toDate()
                      : ""
                  }
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.start = date
                    setOtherActionPayload(newActionPayload)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Час"
                  dateFormat="HH:mm"
                />
              </div>
              <div className="input-element">
                <p>Крайна дата</p>
                <Input.Datepicker
                  disabled={
                    otherActionPayload.status === "finished" || !isEditing
                  }
                  placeholder="Дата"
                  pickDate={
                    otherActionPayload.end
                      ? moment(otherActionPayload.end).toDate()
                      : ""
                  }
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.end = date
                    setOtherActionPayload(newActionPayload)
                  }}
                />
              </div>
              <div className="input-element">
                <p>Краен час</p>
                <Input.Datepicker
                  disabled={
                    otherActionPayload.status === "finished" || !isEditing
                  }
                  placeholder="Час"
                  pickDate={
                    otherActionPayload.end
                      ? moment(otherActionPayload.end).toDate()
                      : ""
                  }
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.end = date
                    setOtherActionPayload(newActionPayload)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Час"
                  dateFormat="HH:mm"
                />
              </div>
              <div className="input-element">
                <p>Изпълнители</p>
                {otherActionPayload.performers.map((perfomer) => {
                  return <p id="bold">{perfomer}</p>
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <ProductionCommentsSection
        comments={currentSchedule?.comments || []}
        scheduleID={currentSchedule?._id}
        data={{ id: currentSchedule?._id, refType: "ProductionRequests" }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentSchedule: state.production.currentSchedule,
  machineSchedules: state.production.machineSchedules,
  results: state.storage.search,
  storageProductResults: state.storage.storageProductResults,
  performers: state.production.performers,
  currentAction: state.production.currentAction,
})

const mapDispatchToProps = (dispatch) => ({
  getSingleScheduleAction: (payload) => dispatch(getSingleSchedule(payload)),
  checkTimeSlotAction: (payload) => dispatch(checkTimeSlot(payload)),
  updateScheduleAction: (payload) => dispatch(updateSchedule(payload)),
  finishRequestAction: (payload) => dispatch(finishRequest(payload)),
  loading: (payload) => dispatch(showLoading(payload)),
  searchRequests: (payload) => dispatch(searchRequests(payload)),
  searchStorageProducts: (payload) => dispatch(searchStorageProducts(payload)),
  getPerformers: (payload) => dispatch(getPerformers(payload)),
  editComment: (payload) => dispatch(editGeneralComment(payload)),
  finishFinishingWork: (payload) => dispatch(finishFinishingWork(payload)),
  getCurrentAction: (payload) => dispatch(getCurrentAction(payload)),
  editActionItem: (payload) => dispatch(editActionItem(payload)),
  resetCurrentAction: (payload) => dispatch(resetCurrentAction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
