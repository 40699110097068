import React, { useState, useEffect } from "react"
import { Input, Button } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import {
  SingleRadioButton,
  QuantityDropdownInput,
  UploadFile,
  ClientAutocomplete,
} from "../../../components"
import {
  hideModal,
  showLoading,
  hideLoading,
  editHPInvoice,
  getInquiries,
} from "../../../actions"
import Files from "../../../utilities/files"
import "./styles.scss"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { isEmpty } from "lodash"

function ReadyProductEditInvoiceModal({
  hideModal,
  editHPInvoice,
  inquiries,
  getList,
  data,
  showLoading,
  hideLoading,
}) {
  const [bankAccount, setBankAccount] = useState("")
  const [marginFacilities, setMarginFacilities] = useState(0)
  const [marginHydro, setMarginHydro] = useState(0)
  const [typeOfDeal, setTypeOfDeal] = useState("За проект")
  const [clientName, setClientName] = useState("")
  const [clientId, setClientId] = useState("")
  const [uic, setUIC] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [project, setProject] = useState("")
  const [orders, setOrders] = useState([])
  const [reason, setReason] = useState("")
  const [totalBefore, setTotalBefore] = useState(0)
  const [totalBeforeNoQuantity, setTotalBeforeNoQuantity] = useState(0)
  const [quantityCheck, setQuantityCheck] = useState([])
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (!isEmpty(data)) {
      setProject(data.project)
      setBankAccount(data.invoice.bankData.bankAccount)
      setReason(data.invoice.reason)
      setClientName(data.invoice.client.name)
      setUIC(data.invoice.client.uic)
      setPhone(data.invoice.client.phone)
      setEmail(data.invoice.client.email)
      setAddress(data.invoice.client.address)
      setClientId(data.invoice.clientId)
      setMarginFacilities(data.invoice.marginFacilities)
      setMarginHydro(data.invoice.marginHydro)
      setTotalBefore(
        data.invoice.products.reduce((acc, val) => {
          return acc + parseFloat(val.singleUnitPrice) * parseInt(val.quantity)
        }, 0)
      )
      setTotalBeforeNoQuantity(
        data.invoice.products.reduce((acc, val) => {
          return acc + parseFloat(val.singleUnitPrice)
        }, 0)
      )
      setQuantityCheck(data.invoice.products.map((el) => el.quantity))
      setOrders(
        data.sales.map((el) => {
          return {
            _id: el.request,
            quantity: el.quantity,
            price: el.price,
            units: el.units,
          }
        })
      )
    }
  }, [data])

  useEffect(() => {
    getList()
  }, [getList])

  const totalAfter = orders.reduce((acc, val) => {
    return acc + parseFloat(val.price) * parseInt(val.quantity)
  }, 0)
  const totalAfterNoQuantity = orders.reduce((acc, val) => {
    return acc + parseFloat(val.price)
  }, 0)
  useEffect(() => {
    setMarginFacilities(totalAfter - totalBefore || "0")
    setMarginHydro(
      (
        ((totalAfterNoQuantity - totalBeforeNoQuantity) /
          totalBeforeNoQuantity) *
        100
      ).toFixed(0)
    )
  }, [totalAfter, totalAfterNoQuantity])

  return (
    <div className="ready-product-sell-container">
      <div className="top-container">
        <h3>Редакция на фактура</h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <p>Тип продажба</p>
      <div className="flex-container">
        <SingleRadioButton
          label="За проект"
          selected={typeOfDeal === "За проект"}
          onClick={() => {
            setTypeOfDeal("За проект")
          }}
        />
        <SingleRadioButton
          label="Свободна продажба"
          selected={typeOfDeal === "Свободна продажба"}
          onClick={() => setTypeOfDeal("Свободна продажба")}
        />
      </div>
      {typeOfDeal === "За проект" && (
        <div className="input-container">
          <p>Проект</p>
          <Input.Dropdown
            placeholder=""
            value={project}
            options={inquiries.map((inquiry) => inquiry.object)}
            onChange={(value) => setProject(value.value)}
          />
        </div>
      )}
      {orders.map((el, index) => {
        return (
          <div className="flex-container space-between">
            <div className="input-container relative">
              <p>Количество</p>
              <QuantityDropdownInput
                noDropdown
                noCurrency
                quantityTypeValue={{ value: el.units }}
                quantityValue={el.quantity}
                onSetQuantity={(value) => {
                  const newOrders = orders.slice(0)
                  newOrders[index].quantity = value
                  setOrders(newOrders)
                  setTotalBefore(
                    data.reduce((acc, val) => {
                      return acc + parseFloat(val.price) * parseInt(value)
                    }, 0)
                  )
                }}
              />
              {quantityCheck[index] < el.quantity && (
                <p id="quantity-check">
                  Количеството е по-голямо от наличното!
                </p>
              )}
            </div>
            <div className="input-container">
              <p>Ед. стойност</p>
              <QuantityDropdownInput
                noDropdown
                quantityValue={el.price}
                quantityTypeValue={{ value: el.units }}
                onSetQuantity={(value) => {
                  const newOrders = orders.slice(0)
                  newOrders[index].price = value
                  setOrders(newOrders)
                }}
              />
            </div>
            <div className="input-container">
              <p>Общо</p>
              <QuantityDropdownInput
                disabled
                noDropdown
                quantityValue={el.price * el.quantity}
                quantityTypeValue={{ value: el.units }}
                // onSelectType={(type) => console.log(type)}
              />
            </div>
          </div>
        )
      })}
      <div className="flex-container space-between">
        <div className="input-element">
          <p>Клиент</p>
          <ClientAutocomplete
            onChooseClient={(data) => {
              setClientName(data.name)
              setClientId(data._id)
              if (data.uic) {
                setUIC(data.uic)
              } else {
                setUIC("")
              }
              if (data.phone) {
                setPhone(data.phone)
              } else {
                setPhone("")
              }
              if (data.email) {
                setEmail(data.email)
              } else {
                setEmail("")
              }
              if (data.address) {
                setAddress(data.address)
              } else {
                setAddress("")
              }
            }}
            onChange={(event) => setClientName(event.target.value)}
          />
        </div>
        <div className="input-element">
          <p>ЕИК</p>
          <Input.Text value={uic} />
        </div>
        <div className="input-element">
          <p>Телефон</p>
          <Input.Text value={phone} />
        </div>
        <div className="input-element">
          <p>Е-Мейл</p>
          <Input.Text value={email} />
        </div>
        <div className="input-element">
          <p>Адрес</p>
          <Input.Text value={address} />
        </div>
      </div>
      <div className="input-container">
        <p>Ваша банкова сметка</p>
        <Input.Dropdown
          placeholder="Моля изберете"
          value={bankAccount}
          options={["ДСК", "УниКредит", "Райфайзен"]}
          onChange={(value) => setBankAccount(value.value)}
        />
      </div>
      <div className="input-container">
        <p>Основание</p>
        <Input.Text
          value={reason}
          onChange={(event) => setReason(event.target.value)}
        />
      </div>
      <div className="flex-container">
        <div className="input-container">
          <p>Документи</p>
          {file ? (
            <div className="flex-container">
              <p id="file" onClick={() => window.open(file.url)}>
                {file.name}
              </p>
              <div className="remove-icon" onClick={() => setFile(null)} />
            </div>
          ) : (
            <UploadFile
              onChange={(event) => {
                showLoading()
                event.persist()
                const name = event.target.files[0].name
                Files.uploadFile(event.target.files[0])
                  .then((data) => {
                    setFile({ name: name, url: data.location })
                    hideLoading()
                  })
                  .catch((err) => {
                    hideLoading()
                  })
              }}
            />
          )}
        </div>
      </div>
      <div className="margin-container">
        <p>
          Марж: {totalAfter - totalBefore || "0"} лв. |{" "}
          {(
            ((totalAfterNoQuantity - totalBeforeNoQuantity) /
              totalBeforeNoQuantity) *
            100
          ).toFixed(0)}{" "}
          %
        </p>
      </div>
      <div className="flex-container flex-end">
        <Button.Raised
          text="Редактирай"
          onClick={() => {
            let payload = {
              _id: data.invoice._id,
              reason: reason,
              inquiry: orders[0]._id,
              products: orders.map((order) => {
                return {
                  _id: order._id,
                  item: order._id,
                  quantity: order.quantity,
                  singleUnitPrice: order.price,
                  total: order.price * order.quantity,
                }
              }),
            }
            if (project) {
              payload.project = project
            }
            if (!clientName) {
              Alerts.error("Грешка!", "Моля изберете клиент")
            } else {
              payload.client = {
                name: clientName,
                uic: uic,
                phone: phone,
                email: email,
                address: address,
              }
              payload.clientId = clientId
            }
            payload.bankData = {
              bankAccount,
              marginFacilities: isNaN(marginFacilities) ? 0 : marginFacilities,
              marginHydro: isNaN(Number(marginHydro)) ? 0 : Number(marginHydro),
            }
            editHPInvoice(payload)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  inquiries: state.inquiries.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  editHPInvoice: (payload) => dispatch(editHPInvoice(payload)),
  getList: (payload) => dispatch(getInquiries(payload)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadyProductEditInvoiceModal)
