import React, { useRef } from "react"
import { connect } from "react-redux"
import { Input, Button, Icon } from "@makedonski/admin-ui"
import Files from "../../../utilities/files"
// import moment from 'moment'
import { hideModal, createProduct } from "../../../actions"
import { RadioButton, FileItem } from "../.."

import "./styles.scss"

function NewProductModal({ hide, data, create }) {
  const [_orientation, _setOrientation] = React.useState("")
  const [product, setProduct] = React.useState({
    basicInfo: {
      type: "Тръба",
      icon: "tube.icon",
      properties: [
        { units: "мм" },
        { units: "мм" },
        { units: "мм" },
        { units: "мм" },
      ],
    },
    warehouseInfo: [
      {
        type: "Общо произведени",
        units: "бр",
      },
      {
        type: "На склад",
        units: "бр",
      },
      {
        type: "Средна цена",
        units: "бр",
      },
    ],
    availableComponents: [
      { units: "бр" },
      { units: "бр" },
      { units: "бр" },
      { units: "бр" },
    ],
  })

  const updateProduct = (key, value) => {
    setProduct({ ...product, [key]: value })
  }
  const drawing = useRef(null)
  const images = useRef(null)
  return (
    <div className="new-product-modal-container">
      <div className="modal-heading">
        <h3>Добавяне на продукт - HydroPro</h3>
        <div className="exit-btn">
          <Button.Icon name="plus" color="#152331" onClick={hide} />
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-section">
          <h4>Обща информация</h4>
          <div className="form-control">
            <div className="form-control-label">Продукт</div>
            <Input.Text
              placeholder="Име на продукт"
              value={product.value}
              onChange={(e) => {
                updateProduct("name", e.target.value)
              }}
            />
          </div>

          <div className="form-control">
            <div className="form-control-label">Вариация</div>
            <Input.Text
              placeholder="Име на вариацията"
              value={product.variation}
              onChange={(e) => {
                updateProduct("variation", e.target.value)
              }}
            />
          </div>

          <div className="form-control">
            <div className="form-control-label">Ориентация</div>
            <div className="radio-group-horizontal">
              <RadioButton
                changed={() => {
                  _setOrientation("horizontal")
                  updateProduct("orientation", "horizontal")
                }}
                id="3"
                isSelected={_orientation === "horizontal"}
                label="Хоризонтала"
                value="horizontal"
              />
              <RadioButton
                changed={() => {
                  _setOrientation("vertical")
                  updateProduct("orientation", "vertical")
                }}
                id="4"
                isSelected={_orientation === "vertical"}
                label="Вертикала"
                value="vertical"
              />
            </div>
          </div>

          <div className="form-control">
            <div className="images-control-wrap">
              <div className="images-control">
                <span>Чертежи</span>
                <div
                  onClick={() => {
                    if (drawing) {
                      drawing.current.click()
                    }
                  }}
                  className="input-file-control"
                >
                  Добави файл
                  <Icon name="chat---add-file" size="14px" color="#7E7E7E" />
                  <input
                    type="file"
                    ref={drawing}
                    accept="*"
                    onChange={(e) => {
                      const { name } = e.target.files[0]
                      Files.uploadFile(e.target.files[0]).then((result) => {
                        updateProduct("diagram", { name, url: result.location })
                      })
                    }}
                  />
                </div>
              </div>
              {product.diagram && (
                <FileItem {...product.diagram} onRemove={() => {}} />
              )}
            </div>
            <div className="images-control-wrap">
              <div
                onClick={() => {
                  if (images) {
                    images.current.click()
                  }
                }}
                className="images-control"
              >
                <span>Снимки</span>
                <div className="input-file-control">
                  Добави файл
                  <Icon name="chat---add-file" size="14px" color="#7E7E7E" />
                  <input
                    type="file"
                    ref={images}
                    multiple
                    accept={"image/*"}
                    onChange={(e) => {
                      Files.uploadFiles(e.target.files).then((result) => {
                        updateProduct(
                          "images",
                          result.map((el) => el.location)
                        )
                      })
                    }}
                  />
                </div>
              </div>
              {product.images && (
                <div className="images-scroll">
                  {product.images.map((img, idx) => {
                    return <img src={img} alt="item" />
                  })}
                </div>
              )}
            </div>
          </div>
          <h4 className="child-heading">Елементи на тръбата</h4>
          {product.basicInfo.properties.map((property, index) => {
            return (
              <div className="form-control" key={index}>
                <div className="form-control-label">Елемент {index + 1}</div>
                <div className="form-control-inline">
                  <div className="form-control-inline-item">
                    <Input.Text
                      placeholder="Вид елемент"
                      value={property.name}
                      onChange={(e) => {
                        const newValue = e.target.value
                        const basicInfo = { ...product.basicInfo }
                        basicInfo.properties[index].name = newValue
                        updateProduct("basicInfo", basicInfo)
                      }}
                    />
                  </div>
                  <div className="form-control-inline-item-small">
                    <Input.Text
                      placeholder="мм"
                      value={property.num}
                      onChange={(e) => {
                        const newValue = e.target.value
                        const basicInfo = { ...product.basicInfo }
                        basicInfo.properties[index].num = newValue
                        updateProduct("basicInfo", basicInfo)
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          })}
          <div className="form-control-label inline">
            Добави елемент
            <Button.Icon
              onClick={() => {
                const basicInfo = { ...product.basicInfo }
                basicInfo.properties.push({ units: "мм" })
                updateProduct("basicInfo", basicInfo)
              }}
              size="12px"
              name="plus"
              color="#26ACE2"
              className="icon-button-add"
            />
          </div>
        </div>
        <div className="column-devider" />
        <div className="modal-section">
          <h4>Складова информация</h4>
          <div className="form-control inline">
            {product.warehouseInfo.map((info, index) => {
              return (
                <div
                  key={index}
                  className={`form-control-inner ${
                    index === 0 ? "double" : ""
                  }`}
                >
                  <div className="form-control-label">{info.type}</div>
                  <Input.Text
                    placeholder="брой"
                    value={info.count}
                    onChange={(e) => {
                      const newValue = e.target.value
                      const warehouseInfo = [...product.warehouseInfo]
                      warehouseInfo[index].count = newValue
                      updateProduct("warehouseInfo", warehouseInfo)
                    }}
                  />
                </div>
              )
            })}
          </div>
          <h4 className="child-heading">Елементи на склад:</h4>

          {product.availableComponents.map((component, index) => {
            return (
              <div className="form-control" key={index}>
                <div className="form-control-label">Елемент {index + 1}</div>
                <div className="form-control-inline">
                  <div className="form-control-inline-item">
                    <Input.Text
                      placeholder="Вид елемент"
                      value={component.type}
                      onChange={(e) => {
                        const newValue = e.target.value
                        const availableComponents = [
                          ...product.availableComponents,
                        ]
                        availableComponents[index].type = newValue
                        updateProduct(
                          "availableComponents",
                          availableComponents
                        )
                      }}
                    />
                  </div>
                  <div className="form-control-inline-item-small">
                    <Input.Text
                      placeholder={component.units || "мм"}
                      value={component.count}
                      onChange={(e) => {
                        const newValue = e.target.value
                        const availableComponents = [
                          ...product.availableComponents,
                        ]
                        availableComponents[index].count = newValue
                        updateProduct(
                          "availableComponents",
                          availableComponents
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          })}

          <div className="form-control-label inline">
            Добави елемент
            <Button.Icon
              onClick={() => {
                const availableComponents = [...product.availableComponents]
                availableComponents.push({ units: "бр" })
                updateProduct("availableComponents", availableComponents)
              }}
              size="12px"
              name="plus"
              color="#26ACE2"
              className="icon-button-add"
            />
          </div>
          <div className="button-container">
            <Button.Raised
              onClick={() => {
                create({ ...product, provider: data.provider })
              }}
              text="Добави"
              className="button-green"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  create: (payload) => dispatch(createProduct(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewProductModal)
