import React from "react"
import { forwardRef } from "react"
import "./styles.scss"

const Checkboxes = forwardRef(
  (
    {
      className,
      options,
      disabled,
      onChange,
      value,
      label,
      compulsory,
      column,
      style,
      innerFields,
      input,
      special,
    },
    ref
  ) => {
    return (
      <div className="input-container" style={style}>
        <label
          style={{
            display: "flex",
            width: "100%",
            fontSize: "0.7rem",
            marginTop: "1%",
            color: className && "red",
          }}
        >
          {label}{" "}
          {compulsory && (
            <span style={{ paddingLeft: "5px", color: className && "red" }}>
              *
            </span>
          )}
        </label>
        <div
          className={`checkboxes-container ${column && "column"} ${
            special && "special"
          }`}
        >
          {options?.map((opt, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className={`checkbox-container ${column && "column"} ${
                    special && "special"
                  }`}
                >
                  <div
                    className="flex items-center pointer"
                    style={{ width: "95%" }}
                    onClick={() => {
                      if (disabled || !onChange) return
                      onChange(opt)
                    }}
                  >
                    <div className="outer-square">
                      <div
                        className={`tick ${special && "special"} ${
                          value &&
                          value?.filter(
                            (val) =>
                              val.insuranceType === opt.value ||
                              val === opt.value
                          ).length > 0 &&
                          "selected"
                        }`}
                      />
                    </div>
                    <label style={{ paddingLeft: "6px", fontSize: "13px" }}>
                      {opt?.label}
                    </label>
                  </div>
                  {input && input(opt)}
                </div>
                {innerFields && innerFields(opt)}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    )
  }
)

export default Checkboxes
