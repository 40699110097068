/* eslint-disable */
import React, { useEffect } from "react"
import "./styles.scss"

const ModalComponent = ({ children, open, position }) => {

  return (
    <div className={`modal-component ${open && "show"} ${position}`}>
      {children}
    </div>
  )
}
export default ModalComponent
