import React, { useEffect, useRef, useState } from "react"
import { Inputs } from "../"
import { Button } from "@makedonski/admin-ui"
import { useDispatch } from "react-redux"
import { hideLoading, showLoading } from "../../actions"
import Files from "../../utilities/files"
import moment from "moment"
import "./styles.scss"
import { Controller } from "react-hook-form"

const forProductOptions = [
  { label: "Тръба", value: "Тръба" },
  { label: "КМУ", value: "КМУ" },
  { label: "Шахти", value: "Шахти" },
  { label: "Линеен отводнител", value: "Линеен отводнител" },
  { label: "Резервоари", value: "Резервоари" },
  { label: "Фитинги", value: "Фитинги" },
  { label: "Друго", value: "Друго" },
]

const ContactForm = ({
  control,
  register,
  errors,
  setValue,
  watch,
  currentUser,
  edit,
  canEdit,
}) => {
  const isMobile = window.innerWidth < 950
  const uploadRef = useRef()
  const dispatch = useDispatch()
  const handleUpload = async ({ target: { files } }) => {
    dispatch(showLoading())
    Files.uploadFiles(files).then((result) => {
      dispatch(hideLoading())
      if (watch("files")?.length)
        setValue("files", [
          ...watch("files"),
          ...result?.map((file) => ({
            url: file?.location,
            name: file?.location?.split("/")?.[4],
            createdAt: moment().toISOString(),
            from: currentUser?.fullName,
          })),
        ])
      else
        setValue("files", [
          ...result?.map((file) => ({
            url: file?.location,
            name: file?.location?.split("/")?.[4],
            createdAt: moment().toISOString(),
            from: currentUser?.fullName,
          })),
        ])
    })
  }

  console.log(canEdit)
  return (
    <>
      {" "}
      <h3 style={{ fontSize: "18px" }}>Основна информация</h3>
      <div
        className="flex w-full items-center wrap"
        style={{ gap: "20px", marginTop: "10px" }}
      >
        <Inputs.TextInput
          label={"Имена"}
          outerStyle={{ width: isMobile ? "100%" : "30%" }}
          inputClassName={errors.name && "failed"}
          disabled={!canEdit}
          {...register(`name`, { shouldValidate: true })}
        />
        <Inputs.TextInput
          label={"Позиция"}
          outerStyle={{ width: isMobile ? "100%" : "30%" }}
          inputClassName={errors.position && "failed"}
          disabled={!canEdit}
          {...register(`position`, { shouldValidate: true })}
        />
        <Inputs.TextInput
          label={"Компания"}
          outerStyle={{ width: isMobile ? "100%" : "30%" }}
          inputClassName={errors.companyName && "failed"}
          disabled={!canEdit}
          {...register(`companyName`, { shouldValidate: true })}
        />
        <Inputs.TextInput
          label={"Имейл"}
          outerStyle={{ width: isMobile ? "100%" : "30%" }}
          inputClassName={errors.email && "failed"}
          disabled={!canEdit}
          {...register(`email`)}
        />
        <Inputs.TextInput
          label={"Телефон"}
          outerStyle={{ width: isMobile ? "100%" : "30%" }}
          inputClassName={errors.phoneNumber && "failed"}
             disabled={!canEdit}
          {...register(`phoneNumber`)}
        />
        <Inputs.TextInput
          label={"Държава"}
          outerStyle={{ width: isMobile ? "100%" : "30%" }}
          inputClassName={errors.country && "failed"}
          disabled={!canEdit}
          {...register(`country`)}
        />
        <Controller
          control={control}
          name={`forProducts`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.SingleSelect
              label={"За продукт"}
              outerStyle={{
                width: isMobile ? "100%" : "35%",
              }}
              isMulti
              value={value && value}
              compulsory
              disabled={!canEdit}
              optionsArray={forProductOptions}
              onChange={(e) => setValue(`forProducts`, e)}
              className={error && "failed"}
            />
          )}
          rules={{ required: true }}
        />
        <Inputs.TextInput
          label={"Контакт от"}
          outerStyle={{ width: isMobile ? "100%" : "56%" }}
          inputClassName={errors.contactFrom && "failed"}
          disabled={!canEdit}
          {...register(`contactFrom`, { shouldValidate: true })}
        />
      </div>
      {!edit && (
        <>
          <h3 style={{ fontSize: "18px", marginTop: "50px" }}>Допълнителни</h3>
          <div className="w-full">
            <p style={{ fontSize: "14px" }}>Файлове</p>
            {isMobile ? (
              <div className="full-width">
                <Inputs.Button
                  text={"Добави файл"}
                  className={"dashed"}
                  onClick={() => uploadRef.current.click()}
                  style={{ width: "100%" }}
                />
                {!!watch("files")?.length &&
                  watch("files")?.map((file, i) => (
                    <div
                      className="flex items-center w-full"
                      style={{ height: "40px", marginTop: "20px" }}
                    >
                      <div
                        className="mobile-file-container"
                        onClick={() => window.open(file?.url)}
                      >
                        {file?.name}
                      </div>
                      <img
                        src={require("../../assets/icons/closeIcon-red.svg")}
                        style={{ width: "20px", height: "20px" }}
                        className="close-popup-icon"
                        onClick={() => {
                          const newFiles = watch("files")?.slice(0)
                          newFiles.splice(i, 1)
                          setValue("files", newFiles)
                        }}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="flex items-center w-full">
                <Button.Icon
                  onClick={() => uploadRef.current.click()}
                  name="plus"
                  size={20}
                  color="#26ACE2"
                />
                {!!watch("files")?.length &&
                  watch("files")?.map((file, i) => (
                    <div
                      className="flex items-center"
                      style={{ gap: "10px", marginRight: "20px" }}
                      key={file?.url?.split("/")?.[4]}
                    >
                      <a
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(file?.url)}
                      >
                        {file?.name}
                      </a>
                      <img
                        src={require("../../assets/icons/closeIcon-red.svg")}
                        className="close-popup-icon"
                        onClick={() => {
                          const newFiles = watch("files")?.slice(0)
                          newFiles.splice(i, 1)
                          setValue("files", newFiles)
                        }}
                      />
                    </div>
                  ))}
              </div>
            )}

            <Inputs.TextInput
              label={"Коментар"}
              outerStyle={{ width: "100%", marginTop: "20px" }}
              inputClassName={errors.mainComment && "failed"}
              disabled={!canEdit}
              {...register(`mainComment`, { shouldValidate: true })}
            />
          </div>
          <input
            ref={uploadRef}
            style={{ display: "none" }}
            type="file"
            multiple
            onChange={(e) => handleUpload(e)}
          />
        </>
      )}
    </>
  )
}
export default ContactForm
