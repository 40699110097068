const searchMap = {
  Тръба: [
    {
      type: 'option',
      name: 'Материал',
      options: ['Полиетилен', 'Полипропилен'],
    },
    {
      type: 'input',
      name: 'Коравина',
    },
    {
      type: 'input',
      name: 'Вътрешен диаметър',
    },
    {
      type: 'input',
      name: 'Външен диаметър',
    },
    {
      type: 'option',
      name: 'Муфа',
      options: ['Гумено уплътнение', 'Ел. муфа', 'Резба'],
    },
  ],
  КМУ: [
    {
      type: 'option',
      name: 'Ориентация',
      options: ['Хоризонтална', 'Вертикална'],
    },
    {
      type: 'input',
      name: 'NS',
    },
    {
      type: 'input',
      name: 'Вариация',
    },
  ],
  Шахти: [
    {
      type: 'input',
      name: 'Диаметър',
    },
    {
      type: 'input',
      name: 'Височина',
    },
    {
      type: 'option',
      name: 'Муфа',
      options: ['Гумено уплътнение', 'Ел. муфа', 'Резба'],
    },
  ],
  'Линеен отводнител': [
    {
      type: 'input',
      name: 'Коравина',
    },
    {
      type: 'input',
      name: 'Вътрешен диаметър',
    },
    {
      type: 'input',
      name: 'Външен диаметър',
    },
    {
      type: 'input',
      name: 'Гумено оплътнение',
    },
    {
      type: 'option',
      name: 'Муфа',
      options: ['Гумено уплътнение', 'Ел. муфа', 'Резба'],
    },
  ],
  Резервоари: [
    {
      type: 'option',
      name: 'Ориентация',
      options: ['Хоризонтална', 'Вертикална'],
    },
    {
      type: 'input',
      name: 'Обем',
    },
    {
      type: 'input',
      name: 'Вариация',
    },
  ],
  Фитинги: [
    {
      type: 'option',
      name: 'Вид',
      options: ['Муфи', 'Коляно', 'Тройник'],
    },
    {
      type: 'input',
      name: 'Вариация',
    },
  ],
  Други: [
    {
      type: 'input',
      name: 'Вариация',
    },
  ],
}

export { searchMap }
