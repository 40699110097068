import React from "react"
import "./styles.scss"

const StorageProduct = ({ product }) => {
  return (
    <div
      className={`storage-product-container ${
        product.quantity < product.criticalQuantity && "red-border"
      }`}
    >
      <div className="product-type-container">
        <div className="flex-container">
          <p id="bold">{product.product}</p>
        </div>
        <p>{product.variation}</p>
      </div>
      <div className="element">
        {product.quantity} {product.units}.
      </div>
      <div className="element">
        {product.price ? product.price.toFixed(2) : "- -"}лв.
      </div>
      <div className="element larger">
        <p>
          {product.criticalQuantity} {product.units}
        </p>
        <div className="edit" />
      </div>
    </div>
  )
}

export default StorageProduct
