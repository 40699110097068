import React, { useState, useEffect } from "react"
import { Input } from "@makedonski/admin-ui"
import "./styles.scss"
import moment from "moment"
import { isBudgetValid } from "../../../../utilities/helpers"

const units = [
  { label: "Метър", value: "м" },
  { label: "Брой", value: "бр" },
  { label: "Килограм", value: "кг" },
  { label: "Литър", value: "л" },
  { label: "Кубик", value: "м3" },
]

function OfferProduct({
  product,
  productTypes,
  onRemoveProduct,
  onSetQuantity,
  onSetProductDate,
  forEdit,
  currency,
  onUnitChange,
  onSetProductPrice,
  noDropDown,
  isPopupOpen,
  setIsPopupOpen,
  setCurrentProduct,
  setStorageChosenProduct,
  availableProducts,
  HPProducts,
  storageType,
  setStorageType
}) {
  const [cost, setCost] = useState(0)
  const [productQuantity, setQuantity] = useState(1)
  const [productDate, setProductDate] = useState(null)
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    if (forEdit) {
       setQuantity(product.quantity)
      product?.deliveryDate && setProductDate(moment(product.deliveryDate).toDate())
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
     setQuantity(product.quantity)
    product?.deliveryDate && setProductDate(moment(product.deliveryDate).toDate())
    //eslint-disable-next-line
  }, [product.deliveryDate])

  useEffect(() => {
    if (product.singleUnitPrice) {
      let parsedCost = product.singleUnitPrice / 100
      setCost(parsedCost)
    } else {
      if (product.expenses) {
        const materialCost =
          product.expenses.material &&
          product.expenses.material.reduce((acc, val) => {
            return acc + val.total
          }, 0)
        const staffCost =
          product.expenses.staff &&
          product.expenses.staff.reduce((acc, val) => {
            return acc + val.total
          }, 0)
        const otherCost =
          product.expenses.other &&
          product.expenses.other.reduce((acc, val) => {
            return acc + val.total
          }, 0)
        const customCost =
          product.expenses.custom &&
          product.expenses.custom.reduce((acc, val) => {
            return (
              acc +
              val.elements.reduce((acc, val) => {
                return acc + val.total
              }, 0)
            )
          }, 0)
        let totalCost =
          (materialCost + staffCost + otherCost + customCost) / 100
        setCost(totalCost)
      }
    }
  }, [product, productQuantity])

  let infoString = product.item
    ? product.item.basicInfo.map((el) => el.value).join(" ")
    : product.basicInfo
    ? product.basicInfo.map((el) => el.value).join(" ")
    : ""
  let name = ""
  let variation = ""
  let storageQuantity = 0
  if (product.friendlyNames?.length && product.friendlyNames[0]) {
    name = product.friendlyNames[0]
  } 
  else if(product?.type) {
    name = product?.type
  }
  else if(product?.variation) {
    variation = product?.variation
  }
  else if(product?.quantity) {
    storageQuantity = product?.quantity
  }
  else {
    name =
      product.item &&
      productTypes.length > 0 &&
      productTypes.find((type) => type._id === product.item.productType).name
  }

  // useEffect(() => {
  //   if (!currency) return
  //   else if (currency?.length) setCost(0)
  // }, [currency])

  console.log(cost)

  return (
    <div className="offer-product-container">
      <div className="product-name-container">
        <div className="product-name">
          <p id="bold">
            {name}
            {!name &&
              !product.item &&
              product.productType &&
              product.productType.name}
          </p>
          <p id="light">{infoString || product?.variation || ''}</p>
        </div>
        <div
          className="arrow-right-coloured"
          onClick={() => {
            window.open(`/products/${product._id}`)
          }}
        />
      </div>
      <div className="quantity-container">
        <p>К-во</p>
        <div className="quantity">
          <div className="outer-input-wrapper">
            {storageType === 'warehouse' ?
            (availableProducts ?
            <input
            maxLength={4}
            type="text"
            value={product?.warehouse?._id ? product?.warehouse?.quantity : '--'}
            // value={`${product?.warehouse?._id ? `${productQuantity} / ${product?.storageQuantity}` : '--'}`}
            disabled={!product?.warehouse?._id}
            onChange={(event) => {
              if (!isNaN(event.target.value)) {
                if (product?.storageQuantity >= event.target.value) {
                  setQuantity(event.target.value)
                  onSetQuantity(event.target.value)
                }
              }
            }}
          /> :
          <input
              maxLength={4}
              type="text"
              value={productQuantity}
              onChange={(event) => {
                if (!isNaN(event.target.value)) {
                  setQuantity(event.target.value)
                  onSetQuantity(event.target.value)
                }
              }}
            />) :
            HPProducts ?
            <input
            maxLength={4}
            type="text"
            value={product?.warehouse?._id ? product?.warehouse?.quantity : '--'}
            // value={`${product?.warehouse?._id ? `${productQuantity} / ${product?.storageQuantity}` : '--'}`}
            disabled={!product?.warehouse?._id}
            onChange={(event) => {
              if (!isNaN(event.target.value)) {
                if (product?.storageQuantity >= event.target.value) {
                  setQuantity(event.target.value)
                  onSetQuantity(event.target.value)
                }
              }
            }}
          /> :
          <input
              maxLength={4}
              type="text"
              value={productQuantity}
              onChange={(event) => {
                if (!isNaN(event.target.value)) {
                  setQuantity(event.target.value)
                  onSetQuantity(event.target.value)
                }
              }}
            />}
            {product?.type ?
            <div id="border-margin">{product?.units}</div> :
            <div className="dropdown-wrapper">
              <p id="border">
                {product?.item ? product?.item?.mainUnit : product?.mainUnit}
              </p>
              {!noDropDown && (
                <div
                  className={`arrow-btn ${opened && "opened"}`}
                  onClick={() => setOpened((prev) => !prev)}
                />
              )}

              {opened && (
                <div className="unit-dropdown">
                  {units.map((unit) => {
                    return (
                      <span
                        onClick={() => {
                          setOpened(false)
                          onUnitChange(unit.value)
                        }}
                      >
                        {unit.value}
                      </span>
                    )
                  })}
                </div>
              )}
            </div>}
          </div>
        </div>

        {(availableProducts && HPProducts) &&
        <div className="options-wrapper">
          {/* <p className="bottom-title">Намери в склад:</p> */}
          <div className="buttons-wrapper">
          <p className="bottom-btn" onClick={() => {
          setIsPopupOpen(!isPopupOpen)
          setCurrentProduct(product)
          setStorageType('warehouse')

          if (product?.warehouse?._id && availableProducts?.length) {
            let chosen = availableProducts.find(el => el._id === product.warehouse?._id)
            setStorageChosenProduct({ warehouseId: chosen?._id, quantity: chosen?.quantity, type: 'warehouse' })
          }
          }}>Склад 1</p>

          <p className="bottom-btn" onClick={() => {
          setIsPopupOpen(!isPopupOpen)
          setCurrentProduct(product)
          setStorageType('hp')

          if (product?.warehouse?._id && HPProducts?.length) {
            let chosen = HPProducts.find(el => el._id === product.warehouse?._id)
            setStorageChosenProduct({ warehouseId: chosen?._id, quantity: chosen?.quantity, type: 'hp' })
          }
          }}>Склад 2</p>
          </div>
        </div>}
        {/* <div className="plus-icon" onClick={() => setIsPopupOpen(!isPopupOpen)}  /> */}
      </div>
      <div className="date-container">
        <p>Срок за доставка</p>
        <Input.Datepicker
          placeholder="Дата"
          pickDate={productDate}
          onChange={(date) => {
            setProductDate(date)
            onSetProductDate(date)
          }}
        />
      </div>
      <div className="single-value-container">
        <p>{`Ед. стойност ${currency === 'EUR' ? '€' : 'лв.'}`}</p>
        <Input.Text
          value={cost}
          onChange={(event) => {
            if (isBudgetValid(event.target.value)) {
              onSetProductPrice(event.target.value)
              setCost(event.target.value)
            }
            // const updateCost = parseFloat(event.target.value)
            // if (isNaN(updateCost)) {
            //   onSetProductPrice("")
            //   setCost("")
            // } else {
            //   onSetProductPrice(updateCost)
            //   setCost(updateCost)
            // }
          }}
        />
      </div>
      <div className="single-value-container">
        <p>Общо</p>
        {currency === 'EUR' ? 
        <div className="single-value padding">
          {productQuantity ? (productQuantity * cost).toFixed(2) : cost} €
        </div> :
        <div className="single-value padding">
          {productQuantity ? (productQuantity * cost).toFixed(2) : cost} лв.
        </div>}
      </div>
      <div className="remove-button" onClick={onRemoveProduct} />
    </div>
  )
}

export default OfferProduct
