import React from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import moment from "moment"
import { hideModal } from "../../../actions"

import "./styles.scss"

const StorageOrderHistory = ({ history, hideModal }) => {
  return (
    <div className="storage-order-history-container">
      <div className="modal-heading">
        <h3>Движение на суровината</h3>
        <div className="exit-btn">
          <Button.Icon
            name="plus"
            color="#152331"
            onClick={hideModal}
            size={24}
          />
        </div>
      </div>
      <div className="modal-content">
        {history
          .sort((a, b) => b.createdAt > a.createdAt)
          .map((e, i) => (
            <div key={`history-event-${e._id}`} className="event">
              <p className="time">
                {moment(e.createdAt).format("hh:mm | DD/MM/YYYY")}
              </p>
              <p className="title bold">{e.title}</p>
              {e.data && (
                <>
                  {e.data.Количество && (
                    <p>
                      Количество:
                      <span className="bold">{e.data.Количество}</span>
                    </p>
                  )}
                  {e.data["Поръчано от"] && (
                    <p>
                      Поръчано от:
                      <span className="bold">{e.data["Поръчано от"]}</span>
                    </p>
                  )}
                  {e.data["Заявено от"] && (
                    <p>
                      Заявено от:
                      <span className="bold">{e.data["Заявено от"]}</span>
                    </p>
                  )}
                  {e.data["Коментар"] && (
                    <p>
                      Коментар:
                      <span className="bold">{e.data["Коментар"]}</span>
                    </p>
                  )}
                  {e.data["От"] && (
                    <p>
                      От:
                      <span className="bold">{e.data["От"]}</span>
                    </p>
                  )}
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StorageOrderHistory)
