import { CommentTypes, ProductionTypes } from '../actions'
import moment from 'moment'

const enumSorter = {
  'in-production': 1,
  pending: 0,
  finished: 2
}
const initialState = {
  data: [],
  machines: [],
  schedule: {},
  currentSchedule: null,
  searchedSettings: [],
  machineSchedules: {},
  performers: [],
  productSchedule: {},
  currentEndProduct: [],
  currentAction: null,
  actionSchedule: [],
  endProduct: {},
  nextMachineActivity: false,
  machineScheduleWork: {},
  machineScheduleWorks: {},
  machineWorks: [],
  scheduleRequestsTablet: [],
  comments: [],
  savedMachineActivity: [],
  nextPage: null,
  hasNextPage: false,
  pageNumber: 1,
  productionQuery: `/production/Тръби/Дневен/${moment().format(
    'DD'
  )}-${moment().format('DD')}-${moment().format('MM')}-${moment().format(
    'YYYY'
  )}`
}

const addRequest = (state, payload) => {
  const data = state.data.slice(0)
  data.push(payload)
  return data.sort((a, b) => {
    return enumSorter[a.status] - enumSorter[b.status]
  })
}

const updateRequest = (state, payload) => {
  const data = state.data.slice(0)
  const index = data.findIndex((el) => el._id === payload._id)
  data[index] = payload
  return data
}

const editComment = (state, payload) => {
  if (payload.refType === 'ProductionRequests' && state.data.length > 0) {
    const newData = state.data.slice(0)
    const index = newData.findIndex((el) => el._id === payload.ref._id)
    const commentIndex = newData[index].comments.findIndex(
      (comment) => comment._id === payload._id
    )
    newData[index].comments[commentIndex] = payload
    return newData
  } else {
    return state.data
  }
}

const addComment = (state, payload) => {
  if (payload.refType === 'ProductionRequests' && state.currentSchedule) {
    const newCurrentSchedule = { ...state.currentSchedule }
    newCurrentSchedule.comments.push(payload)
    return newCurrentSchedule
  } else {
    return state.currentSchedule
  }
}

const deleteComment = (state, payload) => {
  if (payload.refType === 'ProductionRequests' && state.currentSchedule) {
    const newCurrentSchedule = { ...state.currentSchedule }
    const commentIndex = newCurrentSchedule.comments.findIndex(
      (comment) => comment._id === payload._id
    )
    newCurrentSchedule.comments.splice(commentIndex, 1)
    return newCurrentSchedule
  } else {
    return state.currentSchedule
  }
}

const addElement = (state, payload) => {
  const newElements = state.data.slice(0)
  newElements.push(payload)
  return newElements
}

const updateElement = (state, payload) => {
  const newElements = state.data.slice(0)
  newElements.map(el => el = payload.el)
  newElements.push(payload)
  return newElements
}



const products = (state = initialState, action) => {
  switch (action.type) {
    case ProductionTypes.POST_PRODUCTION_REQUEST_SUCCESS:
      return { ...state, data: addRequest(state, action.payload) }
    case ProductionTypes.GET_CURRENT_END_PRODUCT_SUCCESS:
      return { ...state, currentEndProduct: action.payload }
    case ProductionTypes.GET_SINGLE_SCHEDULE_SUCCESS:
      return { ...state, currentSchedule: action.payload }
    case ProductionTypes.UPDATE_SCHEDULE_SUCCESS:
      return { ...state, currentSchedule: action.payload }
    case ProductionTypes.PUT_PRODUCTION_REQUEST_SUCCESS:
      return { ...state, data: updateRequest(state, action.payload) }
    case ProductionTypes.GET_MACHINES_SUCCESS:
      return { ...state, machines: action.payload }
    case ProductionTypes.GET_SCHEDULE_SUCCESS:
      return { ...state, schedule: action.payload }
    case ProductionTypes.DELETE_SCHEDULE_REQUEST_SUCCESS:
      return { ...state }
    case ProductionTypes.SEARCH_MACHINE_SETTINGS_SUCCESS:
      return { ...state, searchedSettings: action.payload }
    case ProductionTypes.SAVE_MACHINE_ACTIVITY_SUCCESS:
      return { ...state, savedMachineActivity: action.payload.productionRequestActivity }
    case ProductionTypes.GET_MACHINE_ACTIVITY_SUCCESS:
      return { ...state, savedMachineActivity: action.payload.page === 1 ? action.payload.docs : [...state.savedMachineActivity, ...action.payload.docs],
        pageNumber: action.payload.page, nextPage: action.payload.nextPage }
    case ProductionTypes.MARK_AS_DEFECTIVE_SUCCESS:
      return { ...state }
      // return { ...state, savedMachineActivity: action.payload.productionRequestActivity }
    case ProductionTypes.CHECK_TIME_SLOT_DATA_SUCCESS:
      return { ...state, machineSchedules: action.payload }
    case ProductionTypes.RESET_MACHINE_SETTINGS:
      return { ...state, searchedSettings: [] }
    case ProductionTypes.GET_PRODUCTION_REQESTS_SUCCESS:
      return {
        ...state,
        data: action.payload.sort((a, b) => {
          return enumSorter[a.status] - enumSorter[b.status]
        })
      }
    case ProductionTypes.GET_PRODUCTION_REQUEST_SUCCESS:
      return { ...state, endProduct: action.payload }
    case ProductionTypes.GET_PERFORMERS_SUCCESS:
      return { ...state, performers: action.payload }
    case ProductionTypes.ADD_PERFORMER_SUCCESS:
      return { ...state }
    case ProductionTypes.GET_MACHINE_SCHEDULE_SUCCESS:
      return { ...state, productSchedule: action.payload }
    case CommentTypes.ADD_GENERAL_COMMENT_SUCCESS:
      return { ...state, currentSchedule: addComment(state, action.payload) }
    case CommentTypes.EDIT_GENERAL_COMMENT_SUCCESS:
      return { ...state, data: editComment(state, action.payload) }
    case CommentTypes.DELETE_GENERAL_COMMENT_SUCCESS:
      return { ...state, currentSchedule: deleteComment(state, action.payload) }
    case ProductionTypes.ADD_OTHER_ACTION_SUCCESS:
      return { ...state, data: addElement(state, action.payload) }

    case ProductionTypes.EDIT_ACTION_ITEM_SUCCESS:
      return { ...state, data: updateRequest(state, action.payload) }

    case ProductionTypes.GET_CURRENT_ACTION_SUCCESS:
      return { ...state, currentAction: action.payload }
    case ProductionTypes.GET_ACTION_SCHEDULE_SUCCESS:
      return { ...state, actionSchedule: action.payload }
    case ProductionTypes.RESET_CURRENT_ACTION:
      return { ...state, currentAction: null }
    case ProductionTypes.SET_PRODUCTION_QUERY:
      return { ...state, productionQuery: action.payload }
    case ProductionTypes.ADD_MACHINE_ACTIVITY_SUCCESS:
      return { ...state, machineWorks: [...state.machineWorks, action.payload.machineScheduleProduce ] }
    case ProductionTypes.ADD_COMMENT_MACHINE_SUCCESS:
      return { ...state, comments: [...state.comments, action.payload.comment ] }
    case ProductionTypes.END_WORK_MACHINE_SCHEDULE_SUCCESS:
      return { ...state, machineScheduleWork: action.payload.machineScheduleWork, nextMachineActivity: true, scheduleRequestsTablet: state.scheduleRequestsTablet.filter(el => el._id !== action.payload.machineScheduleWork.machineSchedule) }
    case ProductionTypes.START_WORK_MACHINE_SCHEDULE_SUCCESS:
      return { ...state, machineScheduleWork: action.payload, nextMachineActivity: false }
    case ProductionTypes.CHECK_UNFINISHED_WORK_SUCCESS:
      return { ...state, machineScheduleWork: action.payload.machineScheduleWork }
    case ProductionTypes.CHECK_UNFINISHED_WORKS_SUCCESS:
      return { ...state, machineScheduleWorks: action.payload.machineScheduleWorks }
    case ProductionTypes.GET_UNFINISHED_WORKS_SUCCESS:
      return { ...state, machineScheduleWork: {}, scheduleRequestsTablet: action.payload.page === 1 ? action.payload.docs : [...state.scheduleRequestsTablet, ...action.payload.docs], pageNumber: action.payload.page, hasNextPage: action.payload.hasNextPage, nextPage: action.payload.nextPage }
    default:
      return state
  }
}

export default products
