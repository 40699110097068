import React from "react"
import { connect } from "react-redux"
import { searchRequests, searchStorageProducts } from "../../actions"
import "./styles.scss"

const ProductView = ({
  product,
  storageProductResults,
  search,
  searchStorageProducts,
  results,
}) => {
  React.useEffect(() => {
    search({ search: "" })
    searchStorageProducts({ search: "" })
    //eslint-disable-next-line
  }, [])

  const totalMaterials = [...storageProductResults, ...results]
  return (
    <div className="component-product-view">
      {product.expenses.material.map((material) => {
        let product = totalMaterials.find((el) => material.kind === el.material)
        let tab = "material"
        if (!product) {
          product = totalMaterials.find((el) => el.type === material.kind)
          tab = "product"
        }
        return (
          <div className="expense-container">
            <div
              className="material-name"
              onClick={() => {
                window.open(`/storage/${tab}/${material.kind}`)
              }}
            >
              <p>{material.kind}</p>
              <div className="white-arrow-btn" />
            </div>
            <div className="flex-container coloured padding margin space-between">
              <div className="flex-container responsive no-margin start">
                <p>{material.quantity ? "Количество:" : "Съотношение:"}</p>
                <p id="bold">
                  {material.quantity
                    ? material.quantity + (product && product.units) || "бр."
                    : material.ratio + "%"}
                </p>
              </div>
              <div className="blue-border" />
              <div className="flex-container flex-end no-margin responsive">
                <p>Единична цена:</p>
                <p id="bold">
                  {product
                    ? product.price
                      ? product.price.toFixed(2)
                      : product.pricing.singleUnitPrice.toFixed(2)
                    : 0}
                  лв.
                </p>
              </div>
            </div>
            <div className="flex-container coloured padding margin space-between purple responsive">
              <div className="flex-container no-margin">
                <p>Разход</p>
                <p id="bold">{(material.total / 100).toFixed(2)}лв.</p>
              </div>
              {!material.quantity ? (
                <p>(% x тежест на продукт x eдинична цена)</p>
              ) : (
                <p>(Количество x eдинична цена)</p>
              )}
            </div>
            <div className="flex-container coloured padding space-between">
              <div className="flex-container no-margin start">
                <p>На склад:</p>
                <p id="bold">
                  {product && product.quantity}
                  {(product && product.units) || "бр."}
                </p>
              </div>
            </div>
          </div>
        )
      })}
      {/* {product.expenses.staff.length > 0 && (
        <div className="staff-header">Персонал</div>
      )} */}
      {/* {product.expenses.staff.map((el) => {
        return (
          <div className="flex-container space-between coloured padding">
            <div className="flex-container">
              <p>Вид:</p>
              <p id="bold">{el.type}</p>
            </div>
            <div className="blue-border" />
            <div className="flex-container flex-end">
              <p>Разход:</p>
              <p id="bold">{el.total}лв.</p>
            </div>
          </div>
        )
      })} */}
      {/* {(product.expenses.other.length > 0 ||
        product.expenses.custom.length > 0) && (
        <div className="staff-header">Други</div>
      )} */}
      {/* {product.expenses.other.map((el) => {
        return (
          <div className="flex-container space-between coloured padding">
            <div className="flex-container">
              <p>Вид:</p>
              <p id="bold">{el.type}</p>
            </div>
            <div className="blue-border" />
            <div className="flex-container flex-end">
              <p>Разход:</p>
              <p id="bold">{el.total}лв.</p>
            </div>
          </div>
        )
      })} */}
      {product.expenses.custom.map((expense) => {
        return (
          <>
            <div className="staff-header">{expense.type}</div>
            {expense.elements.map((element) => {
              return (
                <div className="flex-container space-between coloured padding">
                  <div className="flex-container responsive start no-margin">
                    <p>Вид:</p>
                    <p id="bold">{element.description}</p>
                  </div>
                  <div className="blue-border" />
                  <div className="flex-container flex-end responsive no-margin">
                    <p>Разход:</p>
                    <p id="bold">{(element.total / 100).toFixed(2)}лв.</p>
                  </div>
                </div>
              )
            })}
          </>
        )
      })}
    </div>
  )
}

const mapStateToProps = (state) => ({
  storageProductResults: state.storage.storageProductResults,
  results: state.storage.search,
})

const mapDispatchToProps = (dispatch) => ({
  search: (payload) => dispatch(searchRequests(payload)),
  searchStorageProducts: (payload) => dispatch(searchStorageProducts(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductView)
