import moment from "moment"

const query = {
  _id: "",
  machine: "",
  start: moment().startOf("hour").toDate(),
  duration: "30",
  mixture: "",
  comment: "",
  machineSettings: {
    name: "",
    machine: "",
    schedule: "",
    filledBy: "",
    isSearchable: false,
    materials: [
      {
        name: "",
        ratio: 0,
      },
      {
        name: "",
        ratio: 0,
      },
    ],
    geometry: {
      innerDiameter: "",
      caliber: "",
      f1f2: "",
      stiffnes: "",
      weight: "",
      vacuum: "",
    },
    extruderSJ90: {
      motor: {
        current: "",
        revolutions: "",
      },
      profiles: ["", "", "", "", "", "", "", ""],
      material: "",
    },
    extruderSJ65: {
      motor: {
        current: "",
        revolutions: "",
      },
      profiles: ["", "", "", "", "", ""],
      material: "",
    },
    windingSystem: "",
    injector: "",
    buzzSaw: {
      min: "",
      move: "",
    },
    note: "",
  },
  activity: "",
  expense: "56.10",
  finishingWorks: [
    {
      request: "",
      activity: "",
      date: moment().startOf("hour").toDate(),
      startsAt: moment().startOf("hour").toDate(),
      performers: [""],
      rate: "",
      durationMinutes: "",
      comment: "",
      materials: [{ name: "", quantity: 0, ratio: null, total: 0, kind: "" }],
    },
  ],
}

export default query
