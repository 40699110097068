import { ColleaguesModal } from "./ColleaguesModal"
import { ProfileInfo } from "./ProfileInfo"
import { NotificationModal } from "./NotificationModal"
import { WorkFlowModal } from "./WorkFlowModal"
import { AddProductModal } from "./AddProductModal"
import { EditProductModal } from "./EditProductModal"
import { AddProductQueryModal } from "./AddProductQueryModal"
import { FileOffersModal } from "./FileOffersModal"
import { ProjectCommentsModal } from "./ProjectCommentsModal"
import { AddProjectActivityModal } from "./AddProjectActivityModal"
import { NewProductModal } from "./NewProductModal"
import { AddExpensesModal } from "./AddExpensesModal"
import { AddProductionModal } from "./AddProductionModal"
import { AddProductTypeModal } from "./AddProductTypeModal"
import { AddTubeExpensesModal } from "./AddTubeExpensesModal"
import { AddOtherExpensesModal } from "./AddOtherExpensesModal"
import { AddStorageRawMaterialsOrder } from "./AddStorageRawMaterialsOrder"
import { AddStorageRawMaterialsRequest } from "./AddStorageRawMaterialsRequest"
import { StorageRawMaterialsFinishOrder } from "./StorageRawMaterialsFinishOrder"
import { StorageTotal } from "./StorageTotal"
import { StorageOrderHistory } from "./StorageOrderHistory"
import { EditProduct } from "./EditProduct"
import { EditOtherExpensesModal } from "./EditOtherExpensesModal"
import { EditTubeExpensesModal } from "./EditTubeExpensesModal"
import { EditProductionModal } from "./EditProductionModal"
import { OfferCreationModal } from "./OfferCreationModal"
import { InvoiceCreationModal } from "./InvoiceCreationModal"
import { SuccessModal } from "./SuccessModal"
import { ActOfAcceptance } from "./ActOfAcceptanceModal"
import { AddStorageProductModal } from "./AddStorageProductModal"
import { ProductionHistoryModal } from "./ProductionHistoryModal"
import { StorageProductsModal } from "./StorageProductsModal"
import { StorageProductActionModal } from "./StorageProductActionModal"
import { EmailAddressModal } from "./EmailAddressModal"
import { ProductionRequestModal } from "./ProductionRequestModal"
import { ProductOrderModal } from "./ProductOrderModal"
import { CommentsModal } from "./CommentsModal"
import { InvoiceStatusChangeModal } from "./InvoiceStatusChangeModal"
import { InvoiceNotificationModal } from "./InvoiceNotificationModal"
import { HistoryModal } from "./HistoryModal"
import { ProformInvoiceModal } from "./ProformInvoiceModal"
import { ReadyProductCreationModal } from "./ReadyProductCreationModal"
import { ReadyProductOrderModal } from "./ReadyProductOrderModal"
import { ReadyProductAccrualModal } from "./ReadyProductAccrualModal"
import { ReadyProductCreateAccrualModal } from "./ReadyProductCreateAccrualModal"
import { ReadyProductSellModal } from "./ReadyProductSellModal"
import { AddClientModal } from "./AddClientModal"
import { StorageReadyProductsModal } from "./StorageReadyProductsModal"
import { NewVersionModal } from "./NewVersionModal"
import { ProformInvoiceHPModal } from "./ProformInvoiceHPModal"
import { ActOfAcceptanceHPModal } from "./ActOfAcceptanceHPModal"
import { ReadyProductEditInvoiceModal } from "./ReadyProductEditInvoiceModal"
import { AddMachineActivityModal } from "./AddMachineActivityModal"
import { AddProductScrappingModal } from "./AddProductScrappingModal"
import { EndMachineActivityModal } from "./EndMachineActivityModal"
import FilterModal from "./FilterModal/FilterModal"
import { EditModal } from "./EditModal"
import { DeleteModal } from "./DeleteModal"
import AddChecklistModal from "./AddChecklistModal/AddChecklistModal"
import { AddContactImageModal } from "./AddContactImageModal"
import { ContactAssetsModal } from "./ContactAssetsModal"
export const Modals = {
  ColleaguesModal,
  ProfileInfo,
  NotificationModal,
  WorkFlowModal,
  AddProductModal,
  EditProductModal,
  AddProductQueryModal,
  FileOffersModal,
  ProjectCommentsModal,
  AddProjectActivityModal,
  NewProductModal,
  AddExpensesModal,
  AddProductionModal,
  AddProductTypeModal,
  AddTubeExpensesModal,
  AddOtherExpensesModal,
  AddStorageRawMaterialsRequest,
  AddStorageRawMaterialsOrder,
  StorageRawMaterialsFinishOrder,
  StorageTotal,
  StorageOrderHistory,
  EditProduct,
  EditOtherExpensesModal,
  EditTubeExpensesModal,
  EditProductionModal,
  OfferCreationModal,
  InvoiceCreationModal,
  SuccessModal,
  ActOfAcceptance,
  AddStorageProductModal,
  ProductionHistoryModal,
  StorageProductsModal,
  StorageProductActionModal,
  EmailAddressModal,
  ProductionRequestModal,
  ProductOrderModal,
  CommentsModal,
  InvoiceStatusChangeModal,
  InvoiceNotificationModal,
  HistoryModal,
  ProformInvoiceModal,
  ReadyProductCreationModal,
  ReadyProductOrderModal,
  ReadyProductAccrualModal,
  ReadyProductCreateAccrualModal,
  ReadyProductSellModal,
  ReadyProductEditInvoiceModal,
  AddClientModal,
  StorageReadyProductsModal,
  NewVersionModal,
  ProformInvoiceHPModal,
  ActOfAcceptanceHPModal,
  AddMachineActivityModal,
  AddProductScrappingModal,
  EndMachineActivityModal,
  FilterModal,
  EditModal,
  DeleteModal,
  AddChecklistModal,
  AddContactImageModal,
  ContactAssetsModal,
}
