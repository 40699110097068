export const editorConfig = {
    toolbar: [
        { name: 'corrections', items: ['Undo', 'Redo'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
        { name: 'styles', items: ['Format'] },
        { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
        { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText'] },
        { name: 'tools', items: ['Maximize'] },
    ],
    enterMode: 'ENTER_BR',
    plugins: [
        'basicstyles',
        'clipboard',
        'emoji',
        'enterkey',
        'entities',
        'floatingspace',
        'indentlist',
        'justify',
        'link',
        'list',
        'toolbar',
        'undo',
        'wysiwygarea',
        "maximize",
        'format',
        "autogrow"
    ],
}

export const editorConfigNoToolbar = {
    toolbar: [],
    enterMode: 'ENTER_BR',
    plugins: [
        'basicstyles',
        'clipboard',
        'emoji',
        'enterkey',
        'entities',
        'floatingspace',
        'indentlist',
        'justify',
        'link',
        'list',
        'toolbar',
        'undo',
        'wysiwygarea',
        "maximize",
        'format',
        "autogrow"
    ],
}

export const activityCategoryList = {
    'Списък със заготовки': 'list',
    'Отдел разкрой': 'slot',
    'Отдел сглобяване краен продукт': 'integration',
    'Други задачи': 'other'
  }
  
export  const activitiesList = {
    list: [
      'Разкрой на OD 500 - 300мм изход шахта',
      'Муфиране с ел. муфа OD 500 и разкрой на 300мм',
      'Разкрой на стълби /шахти/ OD 500 - H1400',
      'Разкрой на люк  ID 800 H 350',
      'Разкрой на люк  ID 900 H 350',
      'Разкрой на люк  ID 630 H 350',
      'Разкрой на планки за товаро - разтоварна дейност',
      'Разкрой на крака от PN 200 L 500 за  ID 2000 & ID 2400',
      'Разкрой на планки 60x60 за укрепващи колони ID 2400',
      'Разкрой на крака за ЛО ID 315',
      'Прерязване на фунии за ЛО ID 315',
      'Разкрой на рингове за капаци Ф400',
      'Разкрой  на рингове за капаци Ф630',
      'Разкрой на рингове за капаци Ф800',
      'Разкрой на рингове за капаци Ф900',
      'Разкрой на рингове за капаци Ф1000',
      'Огъване на меден проводник за ел. муфа',
      'Подготовка на проводник ел. муфа',
      'Навиване на заваръчна тел на макари',
      'Изработка на ел. компоненти за ПС'
    ],
    slot: [
      'Разкрой и шлайфане на дъна ID 400',
      'Разкрой и шлайфане  на дъна ID 500',
      'Разкрой и шлайфане на дъна ID 630',
      'Разкрой и шлайфане на дъна ID 800',
      'Разкрой и шлайфане на дъна ID 900',
      'Разкрой и шлайфане на дъна ID 1000',
      'Разкрой и шлайфане  на дъна ID 1200',
      'Разкрой и шлайфане на дъна ID 1400',
      'Разкрой и шлайфане на дъна ID 1500',
      'Разкрой и шлайфане на дъна ID 2000',
      'Разкрой и шлайфане на дъна ID 2400',
      'Разкрой на комплект подсилки за дъно с размери 600мм, 300мм, 400мм за ID 2000',
      'Разкрой на комплект подсилки за дъно с размери 600мм, 300мм, 400мм за ID 2400',
      'Разкрой на укрепващи колони Н2350 мм',
      'Прерязване и лепене с машина на рингове за капаци Ф630',
      'Прерязване и лепене с машина  на рингове за капаци Ф800',
      'Прерязване и лепене с машина на рингове за капаци Ф900',
      'Прерязване и лепене с машина на рингове за капаци Ф1000',
      'Разкрой на PE плочи за муфи',
      'Огъване на муфа ID 300',
      'Огъване на муфа ID 315',
      'Огъване на муфа ID 400',
      'Огъване на муфа OD 400',
      'Огъване на муфа ID 500',
      'Огъване на муфа OD 500',
      'Огъване на муфа ID 630',
      'Огъване на муфа OD 630',
      'Огъване на муфа ID 700',
      'Огъване на муфа ID 800',
      'Огъване на муфа OD 800',
      'Огъване на муфа ID 900',
      'Огъване на муфа ID 1000',
      'Огъване на муфа ID 1200',
      'Огъване на муфа ID 1400',
      'Огъване на муфа ID 1500',
      'Огъване на муфа ID 2000',
      'Огъване на муфа ID 2400',
      'Кованена ел. муфа за ID 300',
      'Кованена ел. муфа за ID 315',
      'Кованена ел. муфа за ID 400',
      'Кованена ел. муфа за ID 500',
      'Кованена ел. муфа за ID 630',
      'Кованена ел. муфа за ID 800',
      'Кованена ел. муфа за ID 900',
      'Кованена ел. муфа за ID 1000',
      'Кованена ел. муфа за ID 1200',
      'Кованена ел. муфа за ID 1500',
      'Лепене и коване на гума муфа ID 300',
      'Лепене и коване на гума муфа ID 400',
      'Лепене и коване на гума муфа OD 400',
      'Лепене и коване на гума муфа ID 500',
      'Лепене и коване на гума муфа OD 500',
      'Лепене и коване на гума муфа ID 630',
      'Лепене и коване на гума муфа OD 630',
      'Лепене и коване на гума муфа ID 800',
      'Лепене и коване на гума муфа OD 800',
      'Лепене и коване на гума муфа ID 900',
      'Лепене и коване на гума муфа ID 1000',
      'Лепене и коване на гума муфа ID 1200',
      'Лепене и коване на гума муфа ID 1500',
      'Разкрой на компоненти за S-ka Ф 110 КМУ',
      'Разкрой на компоненти за S -ka Ф 160 КМУ',
      'Разкрой на компоненти за S -ka Ф 200 КМУ',
      'Разкрой на компоненти за S -ka Ф 250 КМУ',
      'Челна заварка S -ka Ф110  с машина за КМУ',
      'Челна заварка S -ka Ф160  с машина за КМУ',
      'Челна заварка S -ka Ф200  с машина за КМУ',
      'Челна заварка S -ka Ф250  с машина за КМУ',
      'Разкрой и разпробиване и окомплектоване филтър Ф 300 КМУ',
      'Разпробиване с CNC по чертеж',
      'Рязане на брак и пълнене в big bag',
      'Разкрой и заварка на кутия за филтър КМУ',
      'Разкрой и заварка на успокоител за КМУ'
    ],
    integration: [
      'Разкрой на тяло',
      'Шлайфане и подготовка за заварка',
      'Разчертаване с лазер',
      'Разпробиване  на отвор',
      'Заварка на дъно',
      'Заварка на подсилки',
      'Заварка на вход и изход шахта',
      'Заварка на люк',
      'Заварка на планки и укрепващи колони',
      'Заварка на стълба',
      'Заварка на крака',
      'Заварка на преграда',
      'Заварка на капак',
      'Разчертаване и разкрой за фитинги',
      'Заварка на фитинги',
      'Муфиране с ел. муфа',
      'Муфиране с муфа гума',
      'Заварка на  товаро-разтоварни планки',
      'Заварка на S -ka на КМУ',
      'Сглобяване детайли мини КМУ ID 630'
    ],
    other: [
      'Товарене на Big bags брак',
      'Доставка на ПЕ материал',
      'Транспорт на готова продукция',
      'Доставка на материали и консумативи',
      'Доставка на ПЕ плочи',
      'Посещение на обект',
      'Авариен ремонт на обект',
      'Ремонт в централен офис Амарант'
    ]
  }