import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Tab } from "@mui/material"
import React, { useState } from "react"

import "./styles.scss"

export default ({ product }) => {
  const [value, setValue] = useState("1")
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const obj = product.basicInfo
    .map((p) => {
      return { [p.name]: p.value }
    })
    .reduce((acc, cur) => {
      const key = Object.keys(cur)[0]
      acc[key] = cur[key]
      return acc
    }, {})
  const unitsObj = product.basicInfo
    .map((el) => {
      return { [el.name]: el.units }
    })
    .reduce((acc, cur) => {
      const key = Object.keys(cur)[0]
      acc[key] = cur[key]
      return acc
    }, {})

  const images = React.useMemo(() => {
    return Array(6)
      .fill()
      .map((item, index) => {
        if (product.images[index]) return product.images[index]
        return ""
      })
  }, [product])

  const schematicsClient = React.useMemo(() => {
    return Array(6)
      .fill()
      .map((item, index) => {
        if (product.schematicsClient[index])
          return product.schematicsClient[index]
        return ""
      })
  }, [product])

  const schematicsProduction = React.useMemo(() => {
    return Array(6)
      .fill()
      .map((item, index) => {
        if (product.schematicsProduction[index])
          return product.schematicsProduction[index]
        return ""
      })
  }, [product])
  return (
    <TabContext value={value}>
      <div className="component-product-details">
        <div className="flex-container padding tabs">
          <TabList onChange={handleChange}>
            <Tab
              label="Обща информация"
              value={"1"}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Допълнения"
              value={"2"}
              sx={{ textTransform: "none" }}
            />
          </TabList>
        </div>
        <TabPanel value="1">
          {Object.keys(obj).map((key) => {
            return (
              <div className="flex-container padding coloured margin start">
                <span>
                  {key === "Коравина"
                    ? "SN"
                    : key === "Вътрешен диаметър"
                    ? "ID"
                    : key === "Външен диаметър"
                    ? "OD"
                    : key}
                  :
                </span>
                <p>
                  {obj[key]}{" "}
                  {key === "Вътрешен диаметър" || key === "Външен диаметър"
                    ? "мм"
                    : key === "Височина"
                    ? "м."
                    : key === "Обем"
                    ? "m³"
                    : key !== "Коравина" && unitsObj[key]}
                </p>
              </div>
            )
          })}
        </TabPanel>
        <TabPanel value="2">
          {schematicsClient[0] && (
            <>
              <div className="flex-container padding coloured">
                <span>Чертежи за клиенти: </span>
              </div>
              {schematicsClient.map((schematic) => {
                if (schematic) {
                  return (
                    <div
                      className="flex-container padding coloured"
                      onClick={() => window.open(schematic.url)}
                    >
                      <div className="file-icon" />
                      <p id="small">{schematic.name}</p>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </>
          )}
          {schematicsProduction[0] && (
            <>
              <div className="flex-container padding coloured">
                <span>Чертежи за производство:</span>
              </div>
              {schematicsProduction.map((schematic) => {
                if (schematic) {
                  return (
                    <div
                      className="flex-container padding coloured"
                      onClick={() => window.open(schematic.url)}
                    >
                      <div className="file-icon" />
                      <p id="small">{schematic.url}</p>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </>
          )}
          {images[0] && (
            <>
              <div className="flex-container padding coloured">
                <span>Снимки:</span>
              </div>
              {images.map((image) => {
                if (image) {
                  return (
                    <div
                      className="flex-container padding coloured"
                      onClick={() => window.open(image)}
                    >
                      <div className="file-icon" />
                      <p id="small">{image}</p>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </>
          )}
        </TabPanel>
        {/* 
      {Array.isArray(product.diagram)
        ? product.diagram.map((diagram) => {
            return (
              <>
                <div className="flex-container padding coloured ">
                  <span>Чертежи:</span>
                </div>
                <div
                  className="flex-container padding coloured margin cursor"
                  onClick={() => window.open(diagram.url)}
                >
                  <div className="file-icon" />
                  <p>{diagram.name}</p>
                </div>
              </>
            )
          })
        : product.diagram && (
            <>
              <div className="flex-container padding coloured ">
                <span>Чертежи:</span>
              </div>
              <div
                className="flex-container padding coloured margin cursor"
                onClick={() => window.open(product.diagram.url)}
              >
                <div className="file-icon" />
                <p>{product.diagram.name}</p>
              </div>
            </>
          )} */}
      </div>
    </TabContext>
  )
}
