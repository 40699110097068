import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import { BrowserRouter } from "react-router-dom"
import HttpsRedirect from "react-https-redirect"
import App from "./App"
import "react-image-lightbox/style.css"
// import registerServiceWorker from "./registerServiceWorker"
import "./config/globals"

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById("root")
)
// registerServiceWorker()
