export const ProductionTypes = {
  POST_PRODUCTION_REQUEST: "production/POST_PRODUCTION_REQUEST",
  POST_PRODUCTION_REQUEST_SUCCESS: "production/POST_PRODUCTION_REQUEST_SUCCESS",
  PUT_PRODUCTION_REQUEST: "production/PUT_PRODUCTION_REQUEST",
  PUT_PRODUCTION_REQUEST_SUCCESS: "production/PUT_PRODUCTION_REQUEST_SUCCESS",
  DELETE_PRODUCTION_REQUEST: "production/DELETE_PRODUCTION_REQUEST",
  DELETE_PRODUCTION_REQUEST_SUCCESS:
    "production/DELETE_PRODUCTION_REQUEST_SUCCESS",
  GET_PRODUCTION_REQESTS: "production/GET_PRODUCTION_REQESTS",
  GET_PRODUCTION_REQESTS_SUCCESS: "production/GET_PRODUCTION_REQESTS_SUCCESS",
  GET_PRODUCTION_REQUEST: "production/GET_PRODUCTION_REQUEST",
  GET_PRODUCTION_REQUEST_SUCCESS: "production/GET_PRODUCTION_REQUEST_SUCCESS",
  GET_MACHINES: "production/GET_MACHINES",
  GET_MACHINES_SUCCESS: "production/GET_MACHINES_SUCCESS",
  DELETE_SCHEDULE_REQUEST: "production/DELETE_SCHEDULE_REQUEST",
  DELETE_SCHEDULE_REQUEST_SUCCESS: "production/DELETE_SCHEDULE_REQUEST_SUCCESS",
  GET_SCHEDULE: "production/GET_SCHEDULE",
  GET_SCHEDULE_SUCCESS: "production/GET_SCHEDULE_SUCCESS",
  GET_SINGLE_SCHEDULE: "production/GET_SINGLE_SCHEDULE",
  GET_SINGLE_SCHEDULE_SUCCESS: "production/GET_SINGLE_SCHEDULE_SUCCESS",
  SEARCH_MACHINE_SETTINGS: "production/SEARCH_MACHINE_SETTINGS",
  SEARCH_MACHINE_SETTINGS_SUCCESS: "production/SEARCH_MACHINE_SETTINGS_SUCCESS",
  RESET_MACHINE_SETTINGS: "production/RESET_MACHINE_SETTINGS",
  CHECK_TIME_SLOT_DATA: "production/CHECK_TIME_SLOT_DATA",
  CHECK_TIME_SLOT_DATA_SUCCESS: "production/CHECK_TIME_SLOT_DATA_SUCCESS",
  UPDATE_SCHEDULE: "production/UPDATE_SCHEDULE",
  UPDATE_SCHEDULE_SUCCESS: "production/UPDATE_SCHEDULE_SUCCESS",
  FINISH_REQUEST: "production/FINISH_REQUEST",
  FINISH_REQUEST_SUCCESS: "production/FINISH_REQUEST_SUCCESS",
  GET_PERFORMERS: "production/GET_PERFORMERS",
  GET_PERFORMERS_SUCCESS: "production/GET_PERFORMERS_SUCCESS",
  ADD_PERFORMER: "production/ADD_PERFORMER",
  ADD_PERFORMER_SUCCESS: "production/ADD_PERFORMER_SUCCESS",
  GET_MACHINE_SCHEDULE: "production/GET_MACHINE_SCHEDULE",
  GET_MACHINE_SCHEDULE_SUCCESS: "production/GET_MACHINE_SCHEDULE_SUCCESS",
  GET_MACHINE_SCHEDULE_BY_PERFORMER: "production/GET_MACHINE_SCHEDULE_BY_PERFORMER",
  GET_MACHINE_SCHEDULE_BY_PERFORMER_SUCCESS: "production/GET_MACHINE_SCHEDULE_BY_PERFORMER_SUCCESS",
  GET_CURRENT_END_PRODUCT: "production/GET_CURRENT_END_PRODUCT",
  GET_CURRENT_END_PRODUCT_SUCCESS: "production/GET_CURRENT_END_PRODUCT_SUCCESS",
  FINISH_FINISHINGWORK: "production/FINISH_FINISHINGWORK",
  FINISH_FINISHINGWORK_SUCCESS: "production/FINISH_FINISHINGWORK_SUCCESS",

  DELETE_SINGLE_FINISHING_WORK: "production/DELETE_SINGLE_FINISHING_WORK",
  DELETE_SINGLE_FINISHING_WORK_SUCCESS: "production/DELETE_SINGLE_FINISHING_WORK_SUCCESS",

  DELETE_END_PRODUCT_SCHEDULE: "production/DELETE_END_PRODUCT_SCHEDULE",
  DELETE_END_PRODUCT_SCHEDULE_SUCCESS: "production/DELETE_END_PRODUCT_SCHEDULE_SUCCESS",

  ADD_MACHINE_ACTIVITY: "production/ADD_MACHINE_ACTIVITY",
  ADD_MACHINE_ACTIVITY_SUCCESS: "production/ADD_MACHINE_ACTIVITY_SUCCESS",
  
  SAVE_MACHINE_ACTIVITY: "production/SAVE_MACHINE_ACTIVITY",
  SAVE_MACHINE_ACTIVITY_SUCCESS: "production/SAVE_MACHINE_ACTIVITY_SUCCESS",
  
  GET_MACHINE_ACTIVITY: "production/GET_MACHINE_ACTIVITY",
  GET_MACHINE_ACTIVITY_SUCCESS: "production/GET_MACHINE_ACTIVITY_SUCCESS",
  
  MARK_AS_DEFECTIVE: "production/MARK_AS_DEFECTIVE",
  MARK_AS_DEFECTIVE_SUCCESS: "production/MARK_AS_DEFECTIVE_SUCCESS",

  CHECK_UNFINISHED_WORK: "production/CHECK_UNFINISHED_WORK",
  CHECK_UNFINISHED_WORK_SUCCESS: "production/CHECK_UNFINISHED_WORK_SUCCESS",

  CHECK_UNFINISHED_WORKS: "production/CHECK_UNFINISHED_WORKS",
  CHECK_UNFINISHED_WORKS_SUCCESS: "production/CHECK_UNFINISHED_WORKS_SUCCESS",

  GET_UNFINISHED_WORKS: "production/GET_UNFINISHED_WORKS",
  GET_UNFINISHED_WORKS_SUCCESS: "production/GET_UNFINISHED_WORKS_SUCCESS",

  END_WORK_MACHINE_SCHEDULE: "production/END_WORK_MACHINE_SCHEDULE",
  END_WORK_MACHINE_SCHEDULE_SUCCESS: "production/END_WORK_MACHINE_SCHEDULE_SUCCESS",

  START_WORK_MACHINE_SCHEDULE: "production/START_WORK_MACHINE_SCHEDULE",
  START_WORK_MACHINE_SCHEDULE_SUCCESS: "production/START_WORK_MACHINE_SCHEDULE_SUCCESS",

  ADD_COMMENT_MACHINE: "production/ADD_COMMENT_MACHINE",
  ADD_COMMENT_MACHINE_SUCCESS: "production/ADD_COMMENT_MACHINE_SUCCESS",

  ADD_OTHER_ACTION: "production/ADD_OTHER_ACTION",
  ADD_OTHER_ACTION_SUCCESS: "production/ADD_OTHER_ACTION_SUCCESS",

  GET_CURRENT_ACTION: "production/GET_CURRENT_ACTION",
  GET_CURRENT_ACTION_SUCCESS: "production/GET_CURRENT_ACTION_SUCCESS",

  GET_ACTION_SCHEDULE: "production/GET_ACTION_SCHEDULE",
  GET_ACTION_SCHEDULE_SUCCESS: "production/GET_ACTION_SCHEDULE_SUCCESS",

  EDIT_ACTION_ITEM: "production/EDIT_ACTION_ITEM",
  EDIT_ACTION_ITEM_SUCCESS: "production/EDIT_ACTION_ITEM_SUCCESS",

  RESET_CURRENT_ACTION: "production/RESET_CURRENT_ACTION",
  SET_PRODUCTION_QUERY: "production/SET_PRODUCTION_QUERY",
}

export const setProductionQuery = (payload) => ({
  type: ProductionTypes.SET_PRODUCTION_QUERY,
  payload,
})

export const resetCurrentAction = (payload) => ({
  type: ProductionTypes.RESET_CURRENT_ACTION,
})

export const editActionItem = (payload) => ({
  type: ProductionTypes.EDIT_ACTION_ITEM,
  payload,
})

export const getActionSchedule = (payload) => ({
  type: ProductionTypes.GET_ACTION_SCHEDULE,
  payload,
})

export const getCurrentAction = (payload) => ({
  type: ProductionTypes.GET_CURRENT_ACTION,
  payload,
})

export const addOtherAction = (payload) => ({
  type: ProductionTypes.ADD_OTHER_ACTION,
  payload,
})

export const finishFinishingWork = (payload) => ({
  type: ProductionTypes.FINISH_FINISHINGWORK,
  payload,
})

export const addMachineActivity = (payload) => ({
  type: ProductionTypes.ADD_MACHINE_ACTIVITY,
  payload,
})

export const addMachineActivitySuccess = (payload) => ({
  type: ProductionTypes.ADD_MACHINE_ACTIVITY_SUCCESS,
  payload,
})

export const saveMachineActivity = (payload) => ({
  type: ProductionTypes.SAVE_MACHINE_ACTIVITY,
  payload,
})

export const saveMachineActivitySuccess = (payload) => ({
  type: ProductionTypes.SAVE_MACHINE_ACTIVITY_SUCCESS,
  payload,
})

export const getMachineActivity = (payload) => ({
  type: ProductionTypes.GET_MACHINE_ACTIVITY,
  payload,
})

export const getMachineActivitySuccess = (payload) => ({
  type: ProductionTypes.GET_MACHINE_ACTIVITY_SUCCESS,
  payload,
})

export const markMachineActivityAsDefective = (payload) => ({
  type: ProductionTypes.MARK_AS_DEFECTIVE,
  payload,
})

export const markMachineActivityAsDefectiveSuccess = (payload) => ({
  type: ProductionTypes.MARK_AS_DEFECTIVE_SUCCESS,
  payload,
})

export const endWorkMachineSchedule = (payload) => ({
  type: ProductionTypes.END_WORK_MACHINE_SCHEDULE,
  payload,
})

export const endWorkMachineScheduleSuccess = (payload) => ({
  type: ProductionTypes.END_WORK_MACHINE_SCHEDULE_SUCCESS,
  payload,
})

export const deleteSingleFinishingWork = (payload) => ({
  type: ProductionTypes.DELETE_SINGLE_FINISHING_WORK,
  payload,
})

export const deleteSingleFinishingWorkSuccess = (payload) => ({
  type: ProductionTypes.DELETE_SINGLE_FINISHING_WORK_SUCCESS,
  payload,
})

export const deleteEndProductSchedule = (payload) => ({
  type: ProductionTypes.DELETE_END_PRODUCT_SCHEDULE,
  payload,
})

export const deleteEndProductScheduleSuccess = (payload) => ({
  type: ProductionTypes.DELETE_END_PRODUCT_SCHEDULE_SUCCESS,
  payload,
})

export const checkUnfinishedWork = (payload) => ({
  type: ProductionTypes.CHECK_UNFINISHED_WORK,
  payload,
})

export const checkUnfinishedWorkSuccess = (payload) => ({
  type: ProductionTypes.CHECK_UNFINISHED_WORK_SUCCESS,
  payload,
})

export const checkUnfinishedWorks = (payload) => ({
  type: ProductionTypes.CHECK_UNFINISHED_WORKS,
  payload,
})

export const checkUnfinishedWorksSuccess = (payload) => ({
  type: ProductionTypes.CHECK_UNFINISHED_WORKS_SUCCESS,
  payload,
})

export const getUnfinishedWorks = (payload) => ({
  type: ProductionTypes.GET_UNFINISHED_WORKS,
  payload,
})

export const getUnfinishedWorksSuccess = (payload) => ({
  type: ProductionTypes.GET_UNFINISHED_WORKS_SUCCESS,
  payload,
})

export const addCommentMachine = (payload) => ({
  type: ProductionTypes.ADD_COMMENT_MACHINE,
  payload,
})

export const addCommentMachineSuccess = (payload) => ({
  type: ProductionTypes.ADD_COMMENT_MACHINE_SUCCESS,
  payload,
})

export const startWorkMachineSchedule = (payload) => ({
  type: ProductionTypes.START_WORK_MACHINE_SCHEDULE,
  payload,
})

export const startWorkMachineSuccess = (payload) => ({
  type: ProductionTypes.START_WORK_MACHINE_SCHEDULE_SUCCESS,
  payload,
})

export const getMachineScheduleByPerformer = (payload) => ({
  type: ProductionTypes.GET_MACHINE_SCHEDULE_BY_PERFORMER,
  payload,
})

export const getMachineSchedule = (payload) => ({
  type: ProductionTypes.GET_MACHINE_SCHEDULE,
  payload,
})

export const getPerformers = (payload) => ({
  type: ProductionTypes.GET_PERFORMERS,
  payload,
})

export const addPerformer = (payload) => ({
  type: ProductionTypes.ADD_PERFORMER,
  payload,
})

export const addPerformerSuccess = (payload) => ({
  type: ProductionTypes.ADD_PERFORMER_SUCCESS,
  payload,
})

export const deleteProductionRequest = (payload) => ({
  type: ProductionTypes.DELETE_PRODUCTION_REQUEST,
  payload,
})

export const postProductionRequest = (payload) => ({
  type: ProductionTypes.POST_PRODUCTION_REQUEST,
  payload,
})

export const postProductionRequestSuccess = (payload) => ({
  type: ProductionTypes.POST_PRODUCTION_REQUEST_SUCCESS,
  payload,
})

export const putProductionRequest = (payload) => ({
  type: ProductionTypes.PUT_PRODUCTION_REQUEST,
  payload,
})

export const putProductionRequestSuccess = (payload) => ({
  type: ProductionTypes.PUT_PRODUCTION_REQUEST_SUCCESS,
  payload,
})

export const getProductionRequests = () => ({
  type: ProductionTypes.GET_PRODUCTION_REQESTS,
})

export const getProductionRequestsSuccess = (payload) => ({
  type: ProductionTypes.GET_PRODUCTION_REQESTS_SUCCESS,
  payload,
})

export const getProductionRequest = (payload) => ({
  type: ProductionTypes.GET_PRODUCTION_REQUEST,
  payload,
})

export const getProductionRequestSuccess = (payload) => ({
  type: ProductionTypes.GET_PRODUCTION_REQUEST_SUCCESS,
  payload,
})

export const getMachines = () => ({
  type: ProductionTypes.GET_MACHINES,
})

export const getMachinesSuccess = (payload) => ({
  type: ProductionTypes.GET_MACHINES_SUCCESS,
  payload,
})

export const deleteScheduleRequest = (payload) => ({
  type: ProductionTypes.DELETE_SCHEDULE_REQUEST,
  payload
})

export const deleteScheduleRequestSuccess = (payload) => ({
  type: ProductionTypes.DELETE_SCHEDULE_REQUEST_SUCCESS,
  payload,
})

export const getSchedule = (payload) => {
  return {
    type: ProductionTypes.GET_SCHEDULE,
    payload,
  }
}

export const getScheduleSuccess = (payload) => ({
  type: ProductionTypes.GET_SCHEDULE_SUCCESS,
  payload,
})

export const getSingleSchedule = (payload) => ({
  type: ProductionTypes.GET_SINGLE_SCHEDULE,
  payload,
})

export const getSingleScheduleSuccess = (payload) => ({
  type: ProductionTypes.GET_SINGLE_SCHEDULE_SUCCESS,
  payload,
})

export const seachMachineSettings = (payload) => ({
  type: ProductionTypes.SEARCH_MACHINE_SETTINGS,
  payload,
})

export const seachMachineSettingsSuccess = (payload) => ({
  type: ProductionTypes.SEARCH_MACHINE_SETTINGS_SUCCESS,
  payload,
})

export const resetMachineSettings = () => ({
  type: ProductionTypes.RESET_MACHINE_SETTINGS,
})

export const checkTimeSlot = (payload) => ({
  type: ProductionTypes.CHECK_TIME_SLOT_DATA,
  payload,
})

export const checkTimeSlotSuccess = (payload) => ({
  type: ProductionTypes.CHECK_TIME_SLOT_DATA_SUCCESS,
  payload,
})

export const updateSchedule = (payload) => ({
  type: ProductionTypes.UPDATE_SCHEDULE,
  payload,
})

export const updateScheduleSuccess = (payload) => ({
  type: ProductionTypes.UPDATE_SCHEDULE_SUCCESS,
  payload,
})

export const finishRequest = (payload) => ({
  type: ProductionTypes.FINISH_REQUEST,
  payload,
})

export const finishRequestSuccess = (payload) => ({
  type: ProductionTypes.FINISH_REQUEST_SUCCESS,
  payload,
})

export const getCurrentEndProduct = (payload) => ({
  type: ProductionTypes.GET_CURRENT_END_PRODUCT_SUCCESS,
  payload,
})
