import React, { useEffect, useState } from "react"
import "./styles.scss"
import { isBudgetValid } from "../../utilities/helpers"

const units = [
  { label: "Метър", value: "м" },
  { label: "Брой", value: "бр" },
  { label: "Килограм", value: "кг" },
  { label: "Литър", value: "л" },
  { label: "Кубик", value: "м3" },
]

const QuantityDropdownInput = ({
  quantityValue,
  quantityTypeValue,
  noDropdown,
  onSelectType,
  onSetQuantity,
  noCurrency,
  disabled,
  disableUnits,
}) => {
  const [opened, setOpened] = useState(false)
  const [quantityType, setQuantityType] = useState(
    quantityTypeValue || units[0]
  )
  const [quantity, setQuantity] = useState(quantityValue || "")

  useEffect(() => {
    setQuantityType(quantityTypeValue)
    setQuantity(quantityValue)
  }, [quantityTypeValue, setQuantityType, quantityValue, setQuantity])

  return (
    <div
      className={`quantity-input-wrapper ${opened && "opened"} ${
        disabled && "disabled"
      }`}
    >
      <input
        type="text"
        value={quantity}
        disabled={disabled}
        onChange={(event) => {
          if (isBudgetValid(event.target.value)) {
            setQuantity(event.target.value)
            onSetQuantity && onSetQuantity(event.target.value)
          }
        }}
      />
      {noDropdown ? (
        <div
          className={`quantity-dropdown-container ${
            quantityType.value && "wide"
          }`}
        >
          {`${noCurrency ? "" : "лв. /"}`}
          {`${quantityType.value}`}
        </div>
      ) : (
        <div
          className="quantity-dropdown-container"
          onClick={() => !disableUnits && setOpened((prev) => !prev)}
        >
          <div className="quantity-type">{quantityType.value}</div>
          {!disableUnits && (
            <div className={`arrow-dropdown ${opened && "opened"}`} />
          )}
          {opened && (
            <div className="dropdown-menu">
              {units.map((unit, index) => {
                return (
                  <div
                    key={`${index}-${unit}`}
                    className="dropdown-element"
                    onClick={() => {
                      setQuantityType(unit)
                      onSelectType(unit)
                    }}
                  >
                    {unit.value}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default QuantityDropdownInput
