import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import React, { useRef, useEffect, useState } from "react"
import styles from "./Daily.module.scss"
import { times, isEmpty } from "lodash"
import moment from "moment"
import { scale } from "../../../utilities/helpers"
import { Paper } from "@mui/material"

const machines = ["OD800", "OD2000", "OD2400"]
const sessions = [
  {
    _id: 1,
    start: "10:00",
    startedAt: "10",
    end: "11:00",
  },
]

export default function Daily({ setWeekDay, weekDay, schedule, contractor }) {
  const [placeholderSchedule, setPlaceholderSchedule] = useState({})
  useEffect(() => {
    if (contractor === true && isEmpty(schedule)) {
      setPlaceholderSchedule({
        "Изпълнител 1": {},
        "Изпълнител 2": {},
        "Изпълнител 3": {},
      })
    } else if (contractor === true && !isEmpty(schedule)) {
      setPlaceholderSchedule(schedule)
    }
  }, [schedule, contractor])
  const widthRef = useRef()
  const [containerWidth, setContainerWidth] = useState(0)
  useEffect(() => {
    if (widthRef.current) setContainerWidth(widthRef.current.scrollWidth)
  }, [widthRef])

  const getCurrentTime = () =>
    moment().hours() * 90 + scale(moment().minutes(), 0, 59, 0, 90) + 50
  const [currentTime, setCurrentTime] = useState(getCurrentTime())

  useEffect(() => {
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(getCurrentTime())
    }, 1000)
    return () => {
      clearInterval(currentTimeInterval)
    }
  }, [])

  const currentTimeRef = useRef()
  useEffect(() => {
    if (currentTimeRef?.current)
      currentTimeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
  }, [currentTimeRef.current])
  return (
    <div className={styles["daily-graph-container"]}>
      <div
        className={styles["current-time"]}
        ref={currentTimeRef}
        style={{
          top: currentTime,
          backgroundColor: isEmpty(sessions) ? "transparent" : "#0bd8de",
        }}
      />
      <div className={styles["daily-calendar"]}>
        <div className={`${styles["day-arrows"]} ${styles.cell}`}>
          <div>
            <ArrowBackIosIcon
              style={{ color: "#005EA3", cursor: "pointer" }}
              onClick={() => {
                let newDay = weekDay.clone()
                newDay.subtract(1, "days")
                setWeekDay(newDay)
              }}
            />
          </div>
          <div>
            <ArrowForwardIosIcon
              style={{ color: "#005EA3", cursor: "pointer" }}
              onClick={() => {
                let newDay = weekDay.clone()
                newDay.add(1, "days")
                setWeekDay(newDay)
              }}
            />
          </div>
        </div>
        {times(24).map((i) => (
          <div
            key={`cell-date-${i}`}
            className={`${styles.cell} ${styles.col} ${styles["cell-date"]}`}
          >
            {i !== 0 && (
              <p style={{ fontWeight: i === moment().hours() ? "700" : "400" }}>
                {i < 10 ? `0${i}` : i}:00
              </p>
            )}
          </div>
        ))}
      </div>
      <div className={styles["machines-container"]} ref={widthRef}>
        {contractor === false ? (
          <>
            {machines.map((machine, i) => (
              <div className={styles["machine-container"]} key={machine}>
                <div className={styles["machine-name"]}>
                  <p>Машина {machine}</p>
                </div>
                {times(24).map((i) => (
                  <div className={styles["cell-day"]}>
                    {schedule[machine]
                      ?.filter(({ start, startedAt }) =>
                        moment(start || startedAt).isSame(
                          moment(weekDay).startOf("day").add(i, "hours"),
                          "hour"
                        )
                      )
                      ?.map(
                        ({
                          _id,
                          quantity,
                          start,
                          startedAt,
                          end,
                          endedAt,
                          request,
                          activity,
                          product,
                          expense,
                        }) => (
                          <Paper
                            elevation={2}
                            key={`cell-session-${_id}`}
                            className={styles["cell-session"]}
                            style={{
                              top: moment(start || startedAt).minutes() * 2,
                              minHeight:
                                Math.abs(
                                  moment(start || startedAt).diff(
                                    moment(
                                      endedAt ||
                                        end ||
                                        moment().add(2, "minutes")
                                    )
                                  ) / 60000
                                ) * 1.5,
                            }}
                          >
                            <p>
                              {product?.productType?.name} * {quantity}
                            </p>
                            <p>
                              Проект: <b>{request?.inquiry?.object}</b>
                            </p>
                            <p>
                              Продукт:{" "}
                              <b>
                                {product?.friendlyNames
                                  .join(", ")
                                  .substr(0, 15) || product?.productType?.name}
                                {product?.friendlyNames.join(", ").length >
                                  15 && "..."}
                              </b>
                            </p>
                          </Paper>
                        )
                      )}
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          Object.keys(placeholderSchedule).map((contractor, i) => (
            <div
              className={`${styles["machine-container"]} ${styles.contractor}`}
              key={contractor}
            >
              <div className={styles["machine-name"]}>
                <p>{contractor}</p>
              </div>
              {times(24).map((i) => (
                <div className={styles["cell-day"]}>
                  {schedule[contractor]
                    ?.filter(({ date }) =>
                      moment(date).isSame(
                        moment(weekDay).startOf("day").add(i, "hours"),
                        "hour"
                      )
                    )
                    ?.map(
                      ({
                        _id,
                        durationInMinutes,
                        quantity,
                        start,
                        date,
                        end,
                        endedAt,
                        request,
                        activity,
                        product,
                        expense,
                      }) => (
                        <Paper
                          elevation={2}
                          key={`cell-session-${_id}`}
                          className={styles["cell-session"]}
                          style={{
                            top: moment(date).minutes() * 2,
                            minHeight: moment(date).add(
                              durationInMinutes,
                              "minutes"
                            ),
                          }}
                        >
                          <p>
                            {request?.product?.productType?.name} *{" "}
                            {request?.quantity}
                          </p>
                          <p>
                            Проект: <b>{request?.inquiry?.object}</b>
                          </p>
                          <p>
                            Продукт:{" "}
                            <b>
                              {product?.friendlyNames
                                .join(", ")
                                .substr(0, 15) || product?.productType?.name}
                              {product?.friendlyNames.length > 15 && "..."}
                            </b>
                          </p>
                        </Paper>
                      )
                    )}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  )
}
