import React, { Component } from "react"
import "./styles.scss"

class UnderlinedTabButtons extends Component {
  render() {
    const { className, classNameInner, tabClassName, buttons } = this.props
    return (
      <div className={`underlined-main ${className || ""}`}>
        <div className={`underlined-container ${classNameInner || ""}`}>
          {buttons.map((el, i) => {
            return (
              <div
                key={i}
                className={`underlined-tab ripple ${tabClassName || ""} ${
                  this.props.selected === el && "selected"
                }`}
                onClick={() => {
                  this.setState({ selected: el })
                  this.props.onChange(el)
                }}
              >
                {el}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default UnderlinedTabButtons
