export const ProvidersTypes = {
  GET_PROVIDERS: 'providers/GET_PROVIDERS',
  GET_PROVIDERS_SUCCESS: 'providers/GET_PROVIDERS_SUCCESS',
}

export const getProviders = (payload) => ({
  type: ProvidersTypes.GET_PROVIDERS,
  payload,
})

export const getProvidersSuccess = (payload) => ({
  type: ProvidersTypes.GET_PROVIDERS_SUCCESS,
  payload,
})
