import React from 'react'
import { connect } from 'react-redux'
import { Input, Button } from '@makedonski/admin-ui'
import { hideModal, updateProductProduction } from '../../../actions'
import './styles.scss'

function AddProductionModal({ hide, data, update }) {
  const [production, setProduction] = React.useState({
    activities: [
      {
        stage: '',
        components: [
          {
            name: '',
            value: '',
          },
        ],
      },
    ],
  })

  const updateProduction = (key, value) => {
    setProduction({ ...production, [key]: value })
  }

  const addActivityComponent = (index) => {
    const activities = production.activities.slice(0)
    activities[index].components.push({
      name: '',
      value: '',
    })
    updateProduction('activities', activities)
  }

  const addActivity = () => {
    const activities = production.activities.slice(0)
    activities.push({
      stage: '',
      components: [
        {
          name: '',
          value: '',
        },
      ],
    })
    updateProduction('activities', activities)
  }

  const updateActivityDetails = (activityIndex, componentIndex, key, value) => {
    const activities = production.activities.slice(0)
    activities[activityIndex].components[componentIndex][key] = value
    updateProduction('activities', activities)
  }

  const updateActivityStage = (activityIndex, value) => {
    const activities = production.activities.slice(0)
    activities[activityIndex].stage = value
    updateProduction('activities', activities)
  }

  return (
    <div className="add-production-modal-container">
      <div className="modal-heading">
        <h3>Скорост за производство</h3>
        <div className="exit-btn">
          <Button.Icon name="plus" color="#152331" onClick={hide} />
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-section big">
          {production.activities.map((activity, idx) => {
            return (
              <div className="activity-item-row">
                <div className="form-control" key={`activity-add-${idx}`}>
                  <div className="form-control-label">Вид дейсност</div>
                  <Input.Text
                    value={activity.stage}
                    onChange={(e) => {
                      updateActivityStage(idx, e.target.value)
                    }}
                  />
                </div>

                {activity.components.map((component, index) => {
                  return (
                    <div className="form-control form-control-single-line">
                      <div className="form-control-inner">
                        <div className="form-control-label">Етап</div>
                        <Input.Text
                          value={component.name}
                          onChange={(e) => {
                            updateActivityDetails(idx, index, 'name', e.target.value)
                          }}
                        />
                      </div>
                      <div className="form-control-inner small-input">
                        <div className="form-control-label">Време</div>
                        <Input.Text
                          placeholder="мин"
                          value={component.value}
                          onChange={(e) => {
                            updateActivityDetails(idx, index, 'value', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
                <div className="form-control">
                  <div className="form-control-label inline small-text">
                    <span>Добави етап</span>
                    <Button.Icon
                      onClick={() => {
                        addActivityComponent(idx)
                      }}
                      size="12px"
                      name="plus"
                      color="#26ACE2"
                      className="icon-button-add"
                    />
                  </div>
                </div>
              </div>
            )
          })}
          <div className="form-control">
            <div className="form-control-label inline small-text">
              <span>
                <b>Добави нов вид дейност</b>
              </span>
              <Button.Icon
                onClick={() => {
                  addActivity()
                }}
                size="12px"
                name="plus"
                color="#26ACE2"
                className="icon-button-add"
              />
            </div>
          </div>
          <div className="button-container">
            <Button.Raised
              onClick={() => {
                update({ production, _id: data.product })
              }}
              text="Добави"
              className="button-green"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  update: (payload) => dispatch(updateProductProduction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProductionModal)
