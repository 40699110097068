import React, { useState, useEffect, useRef, useMemo } from "react"
import { connect } from "react-redux"
import { Button, Input, Section } from "@makedonski/admin-ui"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CloseIcon from "@mui/icons-material/Close"
import {
  TableHeader,
  StorageReadyProduct,
  StorageRawMaterialsOrderStatus,
} from "../../components"
import {
  showModal,
  getHPRequests,
  changeHPOrderStatus,
  filterHPOrders,
  showLoading,
  editHPRequest,
  hideLoading,
} from "../../actions"
import {
  Button as MuiButton,
  Paper,
  Divider,
  InputBase,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  TextField,
  Box,
} from "@mui/material"
import moment from "moment"
import _, { isEmpty } from "lodash"
import "./styles.scss"
import { API_URL } from "../../config/settings"
import Files from "../../utilities/files"
import ClearIcon from "@mui/icons-material/Clear"

const sortMap = {
  product: "text",
  provider: "special-text",
  createdAt: "date",
  deliveryDate: "date",
  deliveredAt: "date",
  quantity: "number",
  "Доставено к-во": "number",
  price: "number",
  payments: "special-number",
  status: "text",
  productCode: "numbers-and-letters",
  quantity2: "number"
}

const mapStatus = {
  new: { text: "Нова заявка", color: "#F7E05D" },
  ordered: { text: "Направена поръчка", color: "#005EA3" },
  "in-transit": { text: "В транзит - очаква се", color: "#6041ED" },
  delivered: { text: "Доставена", color: "#26ACE2" },
  // accrued: { text: "Доставена", color: "#26ACE2" },
  accrued: { text: "Заприходена поръчка", color: "#25E083" },
  canceled: { text: "Отказана", color: "#FF7590" },
  exported: { text: "Изписана суровина", color: "#E6EEF4" },
}

const headerElements = [
  { name: "Вид продукт", size: 1.9, fontSize: 13, whiteSpace: 'pre-line', justifyContent: 'flex-end' },
  { name: "Доставчик", size: 1.9, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Зона", size: 1.9, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Код", size: 1, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Заявено на", size: 0.9, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Доставка до", size: 1, fontSize: 13, whiteSpace: 'pre-line', justifyContent: 'flex-end' },
  { name: "Доставена на", size: 1.2, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Заявено к-во", size: 1.2, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Доставено к-во", size: 1.3, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Цена", size: 0.8, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Плащане", size: 0.9, fontSize: 13, whiteSpace: 'pre-line' },
  { name: "Статус", size: 3.8, fontSize: 13, whiteSpace: 'pre-line' }
]

const onSearchInit = (event, filterOrders) => {
  event.persist()
  onSearchDelayed(event, filterOrders)
}

const onSearch = (event, filterOrders) => {
  filterOrders({ search: event.target.value })
}

const onSearchDelayed = _.debounce(
  (event, filterOrders) => onSearch(event, filterOrders),
  300
)

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

const disabledTextfieldStyles = {
  "& input.Mui-disabled": {
    "-webkit-text-fill-color": "rgba(0, 0, 0, 1)",
  },
}

const StorageReadyProducts = ({
  showModal,
  getHPRequests,
  HPRequests,
  changeHPOrderStatus,
  filterOrders,
  loading,
  editHPRequest,
  hideLoading,
}) => {
  const materialsUploadRef = useRef()
  const purchaseInvoiceRef = useRef()
  const [currentPurchaseInvoice, setCurrentPurchaseInvoice] = useState("")
  const fileInputRef = useRef()
  const [currentInputFileType, setCurrentInputFileType] = useState("")
  const openFileInput = (type) => {
    setCurrentInputFileType(type)
    fileInputRef.current.click()
  }
  const [filteredRequests, setFilteredRequests] = useState([])
  const [productsForQuery, setProductsForQuery] = useState([])
  const [documentsOpen, setDocumentsOpen] = useState({})
  const [closeAnimation, setCloseAnimation] = useState(false)
  const [popupRef, setPopupRef] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedDocumentId, setSelectedDocumentId] = useState("")
  const [sort, setSort] = useState({
    reverse: false,
    filter: null,
    type: null,
  })
  useEffect(() => {
    getHPRequests()
  }, [getHPRequests])
  useEffect(() => {
    setFilteredRequests(HPRequests)
  }, [HPRequests])
  useMemo(() => {
    let findDocument = HPRequests.find(
      (document) => document._id === selectedDocumentId
    )
    if (findDocument) {
      setDocumentsOpen(findDocument)
    }
  }, [HPRequests, selectedDocumentId])
  const totalValue = HPRequests.reduce((acc, val) => {
    if (val.price) {
      return acc + val.price * val.quantity
    } else {
      return acc + 0
    }
  }, 0)

  const [query, setQuery] = useState("")
  const [searchQuery, setSearchQuery] = useState("Вид продукт")
  const [radioBtnValue, setRadioBtnValue] = useState("product")

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleRadioBtnChange = (event) => {
    switch (event.target.value) {
      case "product":
        setSearchQuery("Вид продукт")
        break
      case "code":
        setSearchQuery("Код")
        break
      default:
        setSearchQuery("Вид продукт")
        break
    }

    setRadioBtnValue(event.target.value)
  }

  const handleFilter = (e) => {
    e.preventDefault()
    if (query !== "") {
      if (radioBtnValue === "product") {
        setFilteredRequests(
          HPRequests.filter((request) =>
            request[radioBtnValue].toLowerCase().includes(query.toLowerCase())
          )
        )
      }
      if (radioBtnValue === "code") {
        setFilteredRequests(
          HPRequests.filter((request) => request.productCode?.includes(query))
        )
      }
    }
  }
  const [startDate, setStartDate] = useState(moment().toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [radioBtnPeriod, setRadioBtnPeriod] = useState("createdAt")
  const [periodSearchAnchor, setPeriodSearchAnchor] = useState(null)
  const periodSearchOpen = Boolean(periodSearchAnchor)
  const handlePeriodSearchOpen = (event) => {
    setPeriodSearchAnchor(event.currentTarget)
  }
  const handlePeriodSearchClose = () => {
    setPeriodSearchAnchor(null)
  }
  const [periodSearchQuery, setPeriodSearchQuery] = useState(
    "Дата на създаване"
  )
  const handleRadioBtnPeriodChange = (event) => {
    switch (event.target.value) {
      case "createdAt":
        setPeriodSearchQuery("Дата на създаване")
        break
      case "updatedAt":
        setPeriodSearchQuery("Последна активност")
        break
      default:
        setPeriodSearchQuery("Дата на създаване")
        break
    }
    setRadioBtnPeriod(event.target.value)
  }
  const handlePeriodFilter = (e) => {
    e.preventDefault()
    setFilteredRequests(HPRequests)
    if (startDate && endDate) {
      const filtered = [...HPRequests]
      const firstDate = moment(startDate).format("YYYY-MM-DD")
      const secondDate = moment(endDate).format("YYYY-MM-DD")
      setFilteredRequests(
        filtered.filter((request) => {
          return (
            moment(request[radioBtnPeriod]).format("YYYY-MM-DD") >= firstDate &&
            moment(request[radioBtnPeriod]).format("YYYY-MM-DD") <= secondDate
          )
        })
      )
    }
  }
  const [actionsAnchor, setActionsAnchor] = useState(null)
  const actionsAnchorOpen = Boolean(actionsAnchor)
  const handleActionsOpen = (event) => {
    setActionsAnchor(event.currentTarget)
  }
  const handleActionsClose = () => {
    setActionsAnchor(null)
  }

  const handleFileUpload = (e) => {
    loading({ text: "Качване на файлове..." })
    const promises = []
    Array.from(e.target.files).forEach((f) => {
      const fileObject = {
        name: f.name,
        type: currentInputFileType,
      }
      const result = new Promise((resolve) => {
        Files.uploadFile(f).then((res) => {
          fileObject.url = res.location
          resolve(fileObject)
        })
      })
      promises.push(result)
    })
    // Promise.all(promises).then((data) => {
    //   props.addDocuments({ documents: data, _id: inquiry._id })
    // })
  }
  if (HPRequests.length === 0) {
    return (
      <div className="single-project-main-container">
        <Section.Loader loading text="Зареждане на данни" />
      </div>
    )
  }

  return (
    <div className="storage-ready-products-container">
      {selectedDocumentId !== "" && (
        <div className="storage-documents-overlay-container">
          <div
            className={`storage-documents-container ${
              closeAnimation && "close"
            }`}
          >
            <div className="flex-container space-between">
              <h3>Документи</h3>
              <input
                ref={fileInputRef}
                type="file"
                accepts="*"
                className="file-input-hidden"
                onChange={handleFileUpload}
                multiple
              />
              <p
                id="hide-button"
                onClick={() => {
                  setCloseAnimation(true)
                  setTimeout(() => {
                    setCloseAnimation(false)
                    setDocumentsOpen({})
                    setSelectedDocumentId("")
                    setProductsForQuery([])
                  }, 200)
                }}
              >
                Скрий {">"}
              </p>
            </div>
            <div className={`flex-container border-bottom space-between`}>
              <p>Фактура покупка</p>
              <input
                type="file"
                ref={purchaseInvoiceRef}
                style={{ display: "none" }}
                onChange={(event) => {
                  showLoading()
                  event.persist()
                  const name = event.target.files[0].name
                  Files.uploadFile(event.target.files[0])
                    .then((data) => {
                      hideLoading()
                      editHPRequest({
                        _id: documentsOpen._id,
                        invoice: { name: name, url: data.location },
                      })
                    })
                    .catch((err) => {
                      hideLoading()
                    })
                }}
              />
              <Button.Raised
                text="Добави"
                className="clear-border-green"
                onClick={() =>
                  purchaseInvoiceRef && purchaseInvoiceRef.current.click()
                }
              />
            </div>
            <div className="invoice-container">
              {documentsOpen?.invoice && (
                <>
                  <div className="flex-container margin-bottom">
                    <p
                      id="file-name"
                      onClick={() => window.open(documentsOpen?.invoice?.url)}
                    >
                      {documentsOpen?.invoice?.name}
                    </p>
                    <ClearIcon
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        editHPRequest({
                          _id: documentsOpen._id,
                          invoice: null,
                        })
                      }}
                    />
                    <div
                      className="file-icon printer"
                      onClick={() => window.open(documentsOpen?.invoice?.url)}
                    />
                    <div className="file-icon history" />
                  </div>
                  <div className="flex-container  margin-bottom">
                    {/* <p id="unpaid">НЕПЛАТЕНА</p> */}
                    {/* <p id="date">| 22.07.2021, 14:36 | Иван Герасимов</p> */}
                  </div>
                  <div className="flex-container margin-bottom">
                    <div className="flex-container no-margin">
                      <p id="blue">Кредитно</p>
                      <div className="plus-icon" />
                    </div>
                    <div className="flex-container">
                      <p id="blue">Дебитно</p>
                      <div className="plus-icon" />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* {documentsOpen?.posts &&
              documentsOpen?.posts?.invoice &&
              documentsOpen?.posts?.invoice?.name && (
                <div className="invoice-container">
                  <div className="flex-container margin-bottom">
                    <p
                      id="file-name"
                      onClick={() =>
                        window.open(documentsOpen?.posts?.invoice?.url)
                      }
                    >
                      {documentsOpen?.posts?.invoice?.name}
                    </p>
                    <div className="file-icon pen" />
                    <div className="file-icon printer" />
                    <div className="file-icon history" />
                  </div>
                  <div className="flex-container  margin-bottom">
                    <p id="unpaid">НЕПЛАТЕНА</p>
                    <p id="date">| 22.07.2021, 14:36 | Иван Герасимов</p>
                  </div>
                  <div className="flex-container margin-bottom">
                    <div className="flex-container no-margin">
                      <p id="blue">Кредитно</p>
                      <div className="plus-icon" />
                    </div>
                    <div className="flex-container">
                      <p id="blue">Дебитно</p>
                      <div className="plus-icon" />
                    </div>
                  </div>
                </div>
              )} */}

            <div
              className={`flex-container ${
                !documentsOpen?.invoices && "border-bottom"
              } space-between`}
            >
              <p>Фактура продажба</p>
              <Button.Raised
                text="Създай"
                className="blue"
                disabled={
                  !productsForQuery.length ||
                  productsForQuery.findIndex(
                    (product) => product.status !== "accrued"
                  ) !== -1
                }
                onClick={() => {
                  setCloseAnimation(true)
                  setTimeout(() => {
                    setCloseAnimation(false)
                    setDocumentsOpen(null)
                    setSelectedDocumentId("")
                  }, 200)
                  showModal({
                    type: "readyProductSell",
                    overflow: "visible",
                    width: "1200px",
                    data: productsForQuery,
                  })
                  setProductsForQuery([])
                }}
              />
            </div>
            {documentsOpen?.invoices?.map((invoice) => {
              return (
                <div className="invoice-container" key={invoice._id}>
                  <div className="flex-container margin-bottom">
                    <p id="file-name">{documentsOpen.product}</p>
                    <div
                      className="file-icon pen"
                      onClick={() => {
                        setTimeout(() => {
                          setCloseAnimation(false)
                          setDocumentsOpen(null)
                          setSelectedDocumentId("")
                        }, 500)
                        showModal({
                          type: "readyProductEditInvoiceModal",
                          width: "90%",
                          data: {
                            invoice: invoice,
                            sales: documentsOpen.sales,
                            project: documentsOpen.project,
                          },
                        })
                      }}
                    />
                    <div
                      className="file-icon printer"
                      onClick={() =>
                        window.open(`${API_URL}/files/invoice/${invoice._id}`)
                      }
                    />
                    <div className="file-icon history" />
                  </div>
                  <div className="flex-container  margin-bottom">
                    {invoice.status === "not-paid" ? (
                      <p id="unpaid">НЕПЛАТЕНА</p>
                    ) : (
                      <p id="paid">ПЛАТЕНА</p>
                    )}
                    <p id="date">
                      | {moment(invoice.createdAt).format("DD.MM.YYYY")},{" "}
                      {moment(invoice.createdAt).format("HH:mm")}|{" "}
                      {invoice.client.name}
                    </p>
                  </div>
                  <div className="flex-container margin-bottom">
                    <div className="flex-container no-margin">
                      <p id="blue">Кредитно</p>
                      <div className="plus-icon" />
                    </div>
                    <div className="flex-container">
                      <p id="blue">Дебитно</p>
                      <div className="plus-icon" />
                    </div>
                  </div>
                </div>
              )
            })}
            <div
              className={`flex-container ${
                !documentsOpen?.actsOfAcceptance && "border-bottom"
              } space-between`}
            >
              <p>ППП</p>
              <Button.Raised
                text="Създай"
                className="blue"
                onClick={() => {
                  setCloseAnimation(true)
                  setTimeout(() => {
                    setCloseAnimation(false)
                    setSelectedDocumentId("")

                    setDocumentsOpen(null)
                  }, 500)
                  showModal({
                    type: "actOfAcceptanceHPModal",
                    width: "90%",
                    data: documentsOpen,
                  })
                }}
              />
            </div>
            {documentsOpen?.actsOfAcceptance?.map((act) => {
              return (
                <div className="invoice-container" key={act._id}>
                  <div className="flex-container margin-bottom">
                    <p id="file-name">{documentsOpen.product}</p>
                    <div
                      className="file-icon pen"
                      onClick={() => {
                        setTimeout(() => {
                          setCloseAnimation(false)
                          setDocumentsOpen(null)
                          setSelectedDocumentId("")
                        }, 500)
                        showModal({
                          type: "actOfAcceptanceHPModal",
                          width: "90%",
                          data: { act, forEdit: true },
                        })
                      }}
                    />
                    <div
                      className="file-icon printer"
                      onClick={() =>
                        window.open(
                          `${API_URL}/files/act-of-acceptance/${act._id}`
                        )
                      }
                    />
                    <div className="file-icon history" />
                  </div>
                  {/* <div className="flex-container  margin-bottom">
                      {invoice.status === "not-paid" ? (
                        <p id="unpaid">НЕПЛАТЕНА</p>
                      ) : (
                        <p id="paid">ПЛАТЕНА</p>
                      )}
                      <p id="date">
                        | {moment(invoice.createdAt).format("DD.MM.YYYY")},{" "}
                        {moment(invoice.createdAt).format("HH:mm")}|{" "}
                        {invoice.client.name}
                      </p>
                    </div>
                    <div className="flex-container margin-bottom">
                      <div className="flex-container no-margin">
                        <p id="blue">Кредитно</p>
                        <div className="plus-icon" />
                      </div>
                      <div className="flex-container">
                        <p id="blue">Дебитно</p>
                        <div className="plus-icon" />
                      </div>
                    </div> */}
                </div>
              )
            })}

            <div
              className={`flex-container border-bottom
               space-between`}
            >
              <p>Проформа</p>
              <Button.Raised
                text="Създай"
                className="blue"
                onClick={() => {
                  setCloseAnimation(true)
                  setTimeout(() => {
                    setCloseAnimation(false)
                    setSelectedDocumentId("")

                    setDocumentsOpen(null)
                  }, 500)
                  showModal({
                    type: "proformInvoiceHPModal",
                    width: "90%",
                    data: documentsOpen,
                  })
                }}
              />
            </div>
            {documentsOpen?.proformaInvoices?.map((proform) => {
              return (
                <div className="invoice-container" key={proform._id}>
                  <div className="flex-container margin-bottom">
                    <p id="file-name">{documentsOpen.product}</p>
                    <div
                      className="file-icon pen"
                      onClick={() => {
                        setTimeout(() => {
                          setCloseAnimation(false)
                          setDocumentsOpen(null)
                          setSelectedDocumentId("")
                        }, 500)
                        showModal({
                          type: "proformInvoiceHPModal",
                          width: "90%",
                          data: { proform, documentsOpen, forEdit: true },
                        })
                      }}
                    />
                    <div
                      className="file-icon printer"
                      onClick={() =>
                        window.open(
                          `${API_URL}/files/proformInvoice/${proform._id}`
                        )
                      }
                    />
                    <div className="file-icon history" />
                  </div>
                  {/* <div className="flex-container  margin-bottom">
                      {invoice.status === "not-paid" ? (
                        <p id="unpaid">НЕПЛАТЕНА</p>
                      ) : (
                        <p id="paid">ПЛАТЕНА</p>
                      )}
                      <p id="date">
                        | {moment(invoice.createdAt).format("DD.MM.YYYY")},{" "}
                        {moment(invoice.createdAt).format("HH:mm")}|{" "}
                        {invoice.client.name}
                      </p>
                    </div>
                    <div className="flex-container margin-bottom">
                      <div className="flex-container no-margin">
                        <p id="blue">Кредитно</p>
                        <div className="plus-icon" />
                      </div>
                      <div className="flex-container">
                        <p id="blue">Дебитно</p>
                        <div className="plus-icon" />
                      </div>
                    </div> */}
                </div>
              )
            })}

            <div className="flex-container border-bottom space-between">
              <p>Добави документи и материали</p>
              <input
                type={"file"}
                multiple
                style={{ display: "none" }}
                ref={materialsUploadRef}
                onChange={(e) => {
                  loading({ text: "Качване на файлове..." })
                  const namesArr = Object.keys(e.target.files).map((key) => {
                    return e.target.files[key].name
                  })
                  Files.uploadFiles(e.target.files).then((res) => {
                    let data = res.map((el, index) => {
                      return { name: namesArr[index], url: el.location }
                    })
                    editHPRequest({
                      _id: documentsOpen._id,
                      files: [...documentsOpen.files, ...data],
                    })
                  })
                  hideLoading()
                }}
              />
              <Button.Raised
                text="Добави"
                className="clear-border-green"
                onClick={() => {
                  if (materialsUploadRef) {
                    materialsUploadRef.current.click()
                  }
                }}
              />
            </div>
            {documentsOpen?.files?.map((file, i) => {
              return (
                <div className="dоcument-container" key={file._id}>
                  <div className="flex-container">
                    <p id="file-name" onClick={() => window.open(file.url)}>
                      {file.name}
                    </p>
                    <ClearIcon
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        let newFiles = documentsOpen.files.slice(0)
                        newFiles.splice(i, 1)
                        editHPRequest({
                          _id: documentsOpen._id,
                          files: newFiles,
                        })
                      }}
                    />
                    <div
                      className="file-icon download"
                      onClick={() => window.open(file.url)}
                    />
                  </div>
                </div>
              )
            })}
            {/* {documentsOpen.posts &&
              documentsOpen.posts.files.map((file) => {
                return (
                  <div className="dоcument-container">
                    <div className="flex-container">
                      <p id="file-name" onClick={() => window.open(file.url)}>
                        {file.name}
                      </p>
                      <div className="file-icon pen" />
                      <div className="file-icon download" />
                    </div>
                    <p id="date">03.02.2020, 14:36 | Вера Иванова</p>
                  </div>
                )
              })} */}
          </div>
        </div>
      )}
      <div className={"projects-header"}>
        <div className={"search-container"}>
          <Paper
            component={"form"}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation()
                    e.preventDefault()
                  }
                },
              }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Търси по"
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <MuiButton
              style={{ textTransform: "none", color: "black" }}
              disableElevation
              disableRipple
              disableFocusRipple
              className={"filter-button"}
              endIcon={<KeyboardArrowDownIcon style={{ color: "#26ACE2" }} />}
              onClick={handleOpen}
            >
              {searchQuery}
            </MuiButton>
            <Menu
              PaperProps={{ style: { padding: "15px" } }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div className={"filters-options"}>
                <h5>Търсене по</h5>
                <CloseIcon
                  fontSize="small"
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <FormControl>
                <RadioGroup
                  value={radioBtnValue}
                  onChange={handleRadioBtnChange}
                >
                  <FormControlLabel
                    value="product"
                    control={<Radio sx={radioStyles} />}
                    label="Вид продукт"
                  />
                  <FormControlLabel
                    value="code"
                    control={<Radio sx={radioStyles} />}
                    label="Код"
                  />
                </RadioGroup>
                <MuiButton
                  sx={{
                    background: "#26ACE2",
                    "&:hover": {
                      background: "#26ACE2",
                    },
                  }}
                  onClick={handleClose}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  variant="contained"
                  fullWidth
                >
                  Задай
                </MuiButton>
              </FormControl>
            </Menu>
          </Paper>
          <MuiButton
            onClick={handleFilter}
            variant="contained"
            sx={{
              padding: "6px 25px",
              backgroundColor: "#E6EEF4",
              "&:hover": {
                background: "#E6EEF4",
              },
              color: "black",
            }}
          >
            Търси
          </MuiButton>
        </div>
        <div className={"datepicker-container"}>
          <Input.Datepicker
            className={`datepicker`}
            placeholder="Дата"
            pickDate={startDate}
            onChange={(date) => {
              setStartDate(date)
            }}
          />
          <Box sx={{ m: 1 }}>-</Box>
          <Input.Datepicker
            className={`datepicker`}
            placeholder="Дата"
            pickDate={endDate}
            onChange={(date) => {
              setEndDate(date)
            }}
          />
          <TextField
            value={periodSearchQuery}
            size="small"
            disabled
            style={{
              width: "220px",
              margin: "0px 10px",
            }}
            sx={disabledTextfieldStyles}
            InputProps={{
              style: { fontSize: "14px" },
              endAdornment: (
                <InputAdornment position="end">
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    onClick={handlePeriodSearchOpen}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Menu
            PaperProps={{ style: { padding: "15px" } }}
            anchorEl={periodSearchAnchor}
            open={periodSearchOpen}
            onClose={handlePeriodSearchClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div className={"filters-options"}>
              <h5>Търси по</h5>
              <CloseIcon
                fontSize="small"
                onClick={handlePeriodSearchClose}
                style={{ cursor: "pointer" }}
              />
            </div>
            <FormControl>
              <RadioGroup
                value={radioBtnPeriod}
                onChange={handleRadioBtnPeriodChange}
              >
                <FormControlLabel
                  value="createdAt"
                  control={<Radio sx={radioStyles} />}
                  label="Дата на създаване"
                />
                <FormControlLabel
                  value="updatedAt"
                  control={<Radio sx={radioStyles} />}
                  label="Последна активност"
                />
              </RadioGroup>
            </FormControl>
          </Menu>
          <MuiButton
            onClick={(e) => {
              handlePeriodFilter(e)
            }}
            variant="contained"
            sx={{
              padding: "6px 25px",
              backgroundColor: "#E6EEF4",
              "&:hover": {
                background: "#E6EEF4",
              },
              color: "black",
            }}
          >
            Търси
          </MuiButton>
        </div>
        <div>
          <MuiButton
            onClick={() => {
              setFilteredRequests(HPRequests)
              setQuery("")
              setStartDate(moment().toDate())
              setEndDate(moment().toDate())
            }}
            variant="contained"
            sx={{
              backgroundColor: "#26ACE2",
              "&:hover": {
                background: "#26ACE2",
              },
              color: "white",
            }}
          >
            Reset Filters
          </MuiButton>
          {/* <MuiButton
            onClick={handleActionsOpen}
            variant="contained"
            sx={{
              backgroundColor: "#25E083",
              "&:hover": {
                background: "#25E083",
              },
              color: "white",
            }}
          >
            Действия
          </MuiButton> */}
          <MuiButton
            variant="contained"
            sx={{
              backgroundColor: "#25E083",
              "&:hover": {
                background: "#25E083",
              },
              color: "white",
            }}
            onClick={() => {
            showModal({
              type: "readyProductCreateAccrual",
              overflow: "visible",
              width: "1200px",
              data: productsForQuery,
            })
            handleActionsClose()
          }}
          >
            Заприходи
          </MuiButton>
          <Menu
            PaperProps={{ style: { padding: "15px" } }}
            anchorEl={actionsAnchor}
            open={actionsAnchorOpen}
            onClose={handleActionsClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseIcon
                fontSize="small"
                onClick={handleActionsClose}
                style={{ cursor: "pointer" }}
              />
            </div>
            <Button.Raised
              text="Ново заприхождаване"
              onClick={() => {
                showModal({
                  type: "readyProductCreateAccrual",
                  overflow: "visible",
                  width: "1200px",
                  data: productsForQuery,
                })
                handleActionsClose()
              }}
            />
            {/* <Button.Raised
              text="Нова заявка"
              onClick={() => {
                showModal({
                  type: "readyProductCreation",
                  overflow: "visible",
                  width: "1000px",
                  data: "",
                })
                handleActionsClose()
              }}
            />
            <Button.Raised
              text="Поръчай"
              className="clear-border"
              disabled={
                !productsForQuery.length ||
                productsForQuery.findIndex(
                  (product) => product.status !== "new"
                ) !== -1
              }
              onClick={() => {
                showModal({
                  type: "readyProductOrder",
                  overflow: "visible",
                  width: "1200px",
                  data: productsForQuery,
                })
                setProductsForQuery([])
                handleActionsClose()
              }}
            />
            <Button.Raised
              text="Заприходи"
              className="clear-border"
              disabled={
                !productsForQuery.length ||
                productsForQuery.findIndex(
                  (product) => product.status !== "delivered"
                ) !== -1
              }
              onClick={() => {
                showModal({
                  type: "readyProductAccrual",
                  overflow: "visible",
                  width: "1200px",
                  data: productsForQuery,
                })
                setProductsForQuery([])
                handleActionsClose()
              }}
            />
            <Button.Raised
              text="Продай"
              className="clear-border last"
              disabled={!productsForQuery.length || !(productsForQuery.findIndex((product) => product.status === "delivered" ) !== -1)}
              // disabled={
              //   !productsForQuery.length ||
              //   productsForQuery.findIndex(
              //     (product) => product.status !== "accrued"
              //   ) !== -1
              // }
              onClick={() => {
                showModal({
                  type: "readyProductSell",
                  overflow: "visible",
                  width: "1200px",
                  data: productsForQuery,
                })
                setProductsForQuery([])
                handleActionsClose()
              }}
            /> */}
          </Menu>
        </div>
      </div>
      <TableHeader
        cursor
        elements={headerElements}
        justifyContent="flex-start"
        onSetSort={(type) => {
          setSort({ reverse: !sort.reverse, filter: type, type: sortMap[type] })
        }}
        sort={sort}
      />
      <div className="scroll-container">
        <Section.Popup
          ref={(ref) => {
            setPopupRef(ref)
          }}
          className="item-status"
          content={
            <StorageRawMaterialsOrderStatus
              statusTypes={mapStatus}
              currentStatus={selectedItem && selectedItem.status}
              onClick={(status) => {
                loading()
                changeHPOrderStatus({
                  _id: selectedItem && selectedItem._id,
                  status,
                })
                setSelectedItem(null)
                popupRef.hide()
              }}
              onClose={() => {
                setSelectedItem(null)
                popupRef.hide()
              }}
            />
          }
        ></Section.Popup>
        {filteredRequests
          .sort((a, b) => {
            if (sort.filter) {
              if (sort.type === "text") {
                const nameA = a[sort.filter].toLowerCase()
                const nameB = b[sort.filter].toLowerCase()
                if (sort.reverse) {
                  if (nameA < nameB) {
                    return -1
                  } else {
                    return 1
                  }
                } else {
                  if (nameA > nameB) {
                    return -1
                  } else {
                    return 1
                  }
                }
              } else if (sort.type === "number") {
                if (sort.reverse) {
                  return a[sort.filter] - b[sort.filter]
                } else {
                  return b[sort.filter] - a[sort.filter]
                }
              } else if (sort.type === "date") {
                if (a[sort.filter] && b[sort.filter]) {
                  if (sort.reverse) {
                    return moment(a[sort.filter]) - moment(b[sort.filter])
                    // return moment(a[sort.filter]).isBefore(
                    //   moment(b[sort.filter])
                  } else {
                    return moment(b[sort.filter]) - moment(a[sort.filter]
                    )
                  }
                } else {
                  return true
                }
              } else if (sort.type === "special-text") {
                if (a[sort.filter] && b[sort.filter]) {
                  const nameA = a[sort.filter].firmName.toLowerCase()
                  const nameB = b[sort.filter].firmName.toLowerCase()
                  if (sort.reverse) {
                    if (nameA < nameB) {
                      return -1
                    } else {
                      return 1
                    }
                  } else {
                    if (nameA > nameB) {
                      return -1
                    } else {
                      return 1
                    }
                  }
                } else {
                  return true
                }
              } else if (sort.type === "special-number") {
                if (sort.reverse) {
                  return a[sort.filter]?.length - b[sort.filter]?.length
                } else {
                  return b[sort.filter]?.length - a[sort.filter]?.length
                }
              } else if (sort.type === "numbers-and-letters") {
                if (sort.reverse) {
                  return a[sort.filter]?.localeCompare(b[sort.filter])
                } else {
                  return b[sort.filter]?.localeCompare(a[sort.filter])
                }
              } else {
                return true
              }
            } else {
              return true
            }
          })
          // .map((product) => {
          //   let newProduct = JSON.parse(JSON.stringify(product))
          //   if (product.status === 'accrued') newProduct.status = 'delivered'
          //   return newProduct
          // })
          .map((product) => {
            return (
              <StorageReadyProduct
                key={product._id}
                onOpenPopup={() => {
                  setSelectedItem(product)
                  popupRef.show()
                }}
                onOpenHistory={() => {
                  showModal({
                    type: "historyModal",
                    overflow: "visible",
                    width: "500px",
                    data: {
                      history: product.history,
                      title: "История на продукция",
                    },
                  })
                }}
                onOpenDocuments={() => {
                  let newProductsForQuery = productsForQuery.slice(0)
                  const index = newProductsForQuery.findIndex(
                    (el) => el === product
                  )
                  if (index === -1) {
                    newProductsForQuery.push(product)
                  } else {
                    newProductsForQuery.splice(index, 1)
                  }
                  setProductsForQuery(newProductsForQuery)
                  setSelectedDocumentId(product._id)
                }}
                checked={
                  productsForQuery.findIndex((el) => el === product) !== -1
                }
                onSelect={() => {
                  let newProductsForQuery = productsForQuery.slice(0)
                  const index = newProductsForQuery.findIndex(
                    (el) => el === product
                  )
                  if (index === -1) {
                    newProductsForQuery.push(product)
                  } else {
                    newProductsForQuery.splice(index, 1)
                  }
                  setProductsForQuery(newProductsForQuery)
                }}
                data={product}
              />
            )
          })}
      </div>
      <div className="scroll-footer">
        <div className="flex-container">
          <h3>Обща стойност на склада:</h3>
          <p id="bold">
            {totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}лв.
          </p>
          <Button.Icon
            name="info"
            size={26}
            color="#a2bfd5"
            onClick={() => {
              showModal({
                type: "storageReadyProducts",
                overflow: "visible",
                width: "700px",
                data: HPRequests,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  HPRequests: state.storage.HPRequests,
})

const mapDispatchToProps = (dispatch) => ({
  editHPRequest: (payload) => dispatch(editHPRequest(payload)),
  loading: (payload) => dispatch(showLoading(payload)),
  hideLoading: () => dispatch(hideLoading()),
  showModal: (payload) => dispatch(showModal(payload)),
  getHPRequests: () => dispatch(getHPRequests()),
  changeHPOrderStatus: (payload) => dispatch(changeHPOrderStatus(payload)),
  filterOrders: (payload) => dispatch(filterHPOrders(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageReadyProducts)
