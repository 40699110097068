export const ReferenceTypes = {
  GET_REFERENCES: "references/GET_REFERENCES",
  GET_REFERENCES_SUCCESS: "references/GET_REFERENCES_SUCCESS",

  CREATE_CLIENT: "references/CREATE_CLIENT",
  CREATE_CLIENT_SUCCESS: "references/CREATE_CLIENT_SUCCESS",

  CREATE_SUPPLIER: "references/CREATE_SUPPLIER",
  CREATE_SUPPLIER_SUCCESS: "references/CREATE_SUPPLIER_SUCCESS",

  EDIT_CLIENT: "references/EDIT_CLIENT",
  EDIT_CLIENT_SUCCESS: "references/EDIT_CLIENT_SUCCESS",

  EDIT_SUPPLIER: "references/EDIT_SUPPLIER",
  EDIT_SUPPLIER_SUCCESS: "references/EDIT_SUPPLIER_SUCCESS",

  GET_CLIENTS: "references/GET_CLIENTS",
  GET_CLIENTS_SUCCESS: "references/GET_CLIENTS_SUCCESS",

  GET_SUPPLIERS: "references/GET_SUPPLIERS",
  GET_SUPPLIERS_SUCCESS: "references/GET_SUPPLIERS_SUCCESS",

  SEARCH_SUPPLIERS: "references/SEARCH_SUPPLIERS",
  SEARCH_SUPPLIERS_SUCCESS: "references/SEARCH_SUPPLIERS_SUCCESS",

  RESET_CLIENTS: "references/RESET_CLIENTS",
  RESET_SUPPLIERS: "references/RESET_SUPPLIERS",

  SEARCH_CLIENTS: "references/SEARCH_CLIENTS",
  SEARCH_CLIENTS_SUCCESS: "references/SEARCH_CLIENTS_SUCCESS",

  SEARCH_INQUIRY_SUPPLIERS: "references/SEARCH_INQUIRY_SUPPLIERS",
  SEARCH_INQUIRY_SUPPLIERS_SUCCESS: "references/SEARCH_INQUIRY_SUPPLIERS_SUCCESS",

  FILTER_CLIENTS_DATES: "references/FILTER_CLIENTS_DATES",
  FILTER_CLIENTS_DATES_SUCCESS: "references/FILTER_CLIENTS_DATES_SUCCESS",

  FILTER_INQUIRY_SUPPLIERS: "references/FILTER_INQUIRY_SUPPLIERS",
  FILTER_INQUIRY_SUPPLIERS_SUCCESS: "references/FILTER_INQUIRY_SUPPLIERS_SUCCESS"
}

export const resetSuppliers = (payload) => ({
  type: ReferenceTypes.RESET_SUPPLIERS,
  payload,
})

export const resetClients = (payload) => ({
  type: ReferenceTypes.RESET_CLIENTS,
  payload,
})

export const searchSuppliers = (payload) => ({
  type: ReferenceTypes.SEARCH_SUPPLIERS,
  payload,
})

export const getSuppliers = (payload) => ({
  type: ReferenceTypes.GET_SUPPLIERS,
  payload,
})

export const getClients = (payload) => ({
  type: ReferenceTypes.GET_CLIENTS,
  payload,
})

export const createSupplier = (payload) => ({
  type: ReferenceTypes.CREATE_SUPPLIER,
  payload,
})

export const createClient = (payload) => ({
  type: ReferenceTypes.CREATE_CLIENT,
  payload,
})

export const editSupplier = (payload) => ({
  type: ReferenceTypes.EDIT_SUPPLIER,
  payload,
})

export const editClient = (payload) => ({
  type: ReferenceTypes.EDIT_CLIENT,
  payload,
})

export const getReferences = (payload) => ({
  type: ReferenceTypes.GET_REFERENCES,
  payload,
})



//inquiry clients
export const searchInquiryClients = (payload) => ({
  type: ReferenceTypes.SEARCH_CLIENTS,
  payload,
})

export const searchInquiryClientsSuccess = (payload) => ({
  type: ReferenceTypes.SEARCH_CLIENTS_SUCCESS,
  payload,
})

// inquiry suppliers
export const searchInquirySuppliers = (payload) => ({
  type: ReferenceTypes.SEARCH_INQUIRY_SUPPLIERS,
  payload,
})

export const searchInquirySuppliersSuccess = (payload) => ({
  type: ReferenceTypes.SEARCH_INQUIRY_SUPPLIERS_SUCCESS,
  payload,
})

export const filterInquiryDatesSuppliers = (payload) => ({
  type: ReferenceTypes.FILTER_INQUIRY_SUPPLIERS,
  payload,
})

export const filterInquiryDatesSuppliersSuccess = (payload) => ({
  type: ReferenceTypes.FILTER_INQUIRY_SUPPLIERS_SUCCESS,
  payload,
})




// sales
export const filterInquiryDatesClients = (payload) => ({
  type: ReferenceTypes.FILTER_CLIENTS_DATES,
  payload,
})

export const filterInquiryDatesClientsSuccess = (payload) => ({
  type: ReferenceTypes.FILTER_CLIENTS_DATES_SUCCESS,
  payload,
})