import { ReferenceTypes } from "../actions"

const initialState = {
  clients: [],
  suppliers: [],
  pageNumber: 1,
  searchClients: [],
  searchedSuppliers: [],
  supplierPage: 1,
  nextPage: null
}

const editClients = (state, payload) => {
  let newClients = state.clients.slice(0)
  const index = newClients.findIndex((el) => el._id === payload._id)
  newClients.splice(index, 1, payload)
  return newClients
}

const editSuppliers = (state, payload) => {
  let newSuppliers = state.suppliers.slice(0)
  const index = newSuppliers.findIndex((el) => el._id === payload._id)
  newSuppliers.splice(index, 1, payload)
  return newSuppliers
}

const loadClients = (state, payload) => {
  const oldClients = [ ...state.clients ];
  const newClients = payload.docs;
  let clientsResult = []

  if (payload.page !== 1) {
    clientsResult = [...oldClients, ...newClients];
  }
  else {
    clientsResult = payload.docs;
  }
  return clientsResult;
}
const loadSuppliers = (state, payload) => {
  const oldSuppliers = [ ...state.suppliers ];
  const newSuppliers = payload.docs;
  let suppliersResult = []

  // if (payload.page !== 1) {
  //   suppliersResult = [...oldSuppliers, ...newSuppliers];
  // }
  // else {
  //   suppliersResult = payload.docs;
  // }
  if (!payload.hasPrevPage || oldSuppliers.length === 0) {
    suppliersResult = payload.docs;
  }
  else {
    suppliersResult = [...oldSuppliers, ...newSuppliers];
  }
  return suppliersResult;
}

const references = (state = initialState, { type, payload }) => {
  switch (type) {
    case ReferenceTypes.RESET_CLIENTS:
      return { ...state, clients: [] }
    case ReferenceTypes.RESET_SUPPLIERS:
      return { ...state, suppliers: [] }
    case ReferenceTypes.GET_REFERENCES_SUCCESS:
      return { ...state, searchClients: payload }
    case ReferenceTypes.GET_CLIENTS:
      if (payload.pageNumber === 1) {
        return { ...state, clients: [] }
      } else {
        return { ...state }
      }
    case ReferenceTypes.CREATE_CLIENT:
      return { ...state, clients: [...state.clients, payload] }
    case ReferenceTypes.CREATE_SUPPLIER:
      return { ...state, suppliers: [...state.clients, payload] }
    case ReferenceTypes.GET_SUPPLIERS:
      if (payload.pageNumber === 1) {
        return { ...state, suppliers: [] }
      } else {
        return { ...state }
      }
    case ReferenceTypes.GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        pageNumber: payload.page,
        nextPage: payload.nextPage,
        suppliers: [...state.suppliers, ...payload.docs],
        supplierPage: payload.nextPage,
      }
    case ReferenceTypes.EDIT_CLIENT_SUCCESS:
      return { ...state, clients: editClients(state, payload) }
    case ReferenceTypes.EDIT_SUPPLIER_SUCCESS:
      return { ...state, suppliers: editSuppliers(state, payload) }
    case ReferenceTypes.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        pageNumber: payload.page,
        nextPage: payload.nextPage,
        clients: [...state.clients, ...payload.docs],
      }
    case ReferenceTypes.SEARCH_CLIENTS_SUCCESS:
      return {...state, pageNumber: payload.page, nextPage: payload.nextPage, clients: loadClients(state, payload)}
      // return {...state, searchClients: action.payload}
    case ReferenceTypes.FILTER_CLIENTS_DATES_SUCCESS:
      return { ...state, clients: payload.docs }
    case ReferenceTypes.SEARCH_SUPPLIERS_SUCCESS:
      return { ...state, suppliers: payload }
    case ReferenceTypes.SEARCH_INQUIRY_SUPPLIERS_SUCCESS:
      return { ...state, nextPage: payload.nextPage, supplierPage: payload.nextPage, pageNumber: payload.page, suppliers: loadSuppliers(state, payload) }
      // return { ...state, suppliers: payload.docs }
    case ReferenceTypes.FILTER_INQUIRY_SUPPLIERS_SUCCESS:
      return { ...state, suppliers: payload.docs }
    default:
      return state
  }
}

export default references
