export const UserTypes = {
  GET_CURRENT_USER_START: 'user/GET_CURRENT_USER_START',
  GET_CURRENT_USER_SUCCESS: 'user/GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'user/GET_CURRENT_USER_FAILURE',
  UPDATE: 'user/UPDATE',
  FILTER: 'user/FILTER',
  CHANGE_WORKING_STATUS: 'user/CHANGE_WORKING_STATUS',
  CHANGE_WORKING_STATUS_SUCCESS: 'user/CHANGE_WORKING_STATUS_SUCCESS',
  CHANGE_STATUS: 'user/CHANGE_STATUS',
  CHANGE_STATUS_SUCCESS: 'user/CHANGE_STATUS_SUCCESS',
  GET_COLLEAGUES: 'user/GET_COLLEAGUES',
  GET_COLLEAGUES_SUCCESS: 'user/GET_COLLEAGUES_SUCCESS',
  GET_FILTERED_USERS: 'user/GET_FILTERED_USERS',
  GET_FILTERED_USERS_SUCCESS: 'user/GET_FILTERED_USERS_SUCCESS',

  GET_USER_TASKS: 'user/GET_USER_TASKS',
  GET_USER_TASKS_SUCCESS: 'user/GET_USER_TASKS_SUCCESS',

  GET_CURRENT_USER_ROLE: 'users2/GET_CURRENT_USER_ROLE',
  GET_CURRENT_USER_ROLE_SUCCESS: 'users2/GET_CURRENT_USER_ROLE_SUCCESS',

  SET_PUSH_TOKEN: 'user/SET_PUSH_TOKEN',
  SET_PUSH_TOKEN_SUCCESS: 'user/SET_PUSH_TOKEN_SUCCESS',
}

export const setPushToken = (payload) => ({
  type: UserTypes.SET_PUSH_TOKEN,
  payload,
})

export const setPushTokenSuccess = (payload) => ({
  type: UserTypes.SET_PUSH_TOKEN_SUCCESS,
  payload,
})

export const getUserTasks = (payload) => ({
  type: UserTypes.GET_USER_TASKS,
  payload,
})

export const getCurrentUserRole = (payload) => ({
  type: UserTypes.GET_CURRENT_USER_ROLE,
  payload,
})
export const getCurrentUserRoleSuccess = (payload) => ({
  type: UserTypes.GET_CURRENT_USER_ROLE_SUCCESS,
  payload,
})

export const getFilteredUsers = (payload) => ({
  type: UserTypes.GET_FILTERED_USERS,
  payload,
})

export const getCurrentUser = (payload) => ({
  type: UserTypes.GET_CURRENT_USER_START,
  payload,
})
export const update = (payload) => ({
  type: UserTypes.SIGN_IN,
  payload,
})
export const filter = (payload) => ({
  type: UserTypes.FILTER,
  payload,
})
export const setUserWorkingStatus = (payload) => ({
  type: UserTypes.CHANGE_WORKING_STATUS,
  payload,
})
export const changeStatus = (payload) => ({
  type: UserTypes.CHANGE_STATUS,
  payload,
})
export const changeStatusSuccess = (payload) => ({
  type: UserTypes.CHANGE_STATUS_SUCCESS,
  payload,
})
export const getColleagues = (payload) => ({
  type: UserTypes.GET_COLLEAGUES,
  payload,
})
