/* eslint-disable */
import React from "react"
import "./styles.scss"
import { forwardRef } from "react"

const TextAreaInput = forwardRef(
  (
    {
      outerStyle,
      outerClassName,
      inputClassName,
      inputStyle,
      label,
      compulsory,
      disabled,
      suffix,
      chatFont,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${outerClassName} input-container`} style={outerStyle}>
        {label && (
          <label className="input-header">
            {label}{" "}
            {compulsory && (
              <span style={{ padding: "0px 8px", color: "#E80111" }}>*</span>
            )}
          </label>
        )}

        <div
          className={`prefix-input-container ${inputClassName}`}
          style={inputStyle}
        >
          <textarea
            className={`textArea-container ${chatFont}`}
            disabled={disabled}
            {...props}
            ref={ref}
          />
          {suffix && suffix}
        </div>
      </div>
    )
  }
)

export default TextAreaInput
