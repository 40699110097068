export const CommentTypes = {
  ADD_GENERAL_COMMENT: "comments/ADD_GENERAL_COMMENT",
  ADD_GENERAL_COMMENT_SUCCESS: "comments/ADD_GENERAL_COMMENT_SUCCESS",
  EDIT_GENERAL_COMMENT: "comments/EDIT_GENERAL_COMMENT",
  EDIT_GENERAL_COMMENT_SUCCESS: "comments/EDIT_GENERAL_COMMENT_SUCCESS",
  DELETE_GENERAL_COMMENT: "comments/DELETE_GENERAL_COMMENT",
  DELETE_GENERAL_COMMENT_SUCCESS: "comment/DELETE_GENERAL_COMMENT_SUCCESS",
}

export const addGeneralComment = (payload) => ({
  type: CommentTypes.ADD_GENERAL_COMMENT,
  payload,
})

export const editGeneralComment = (payload) => ({
  type: CommentTypes.EDIT_GENERAL_COMMENT,
  payload,
})

export const deleteGeneralComment = (payload) => ({
  type: CommentTypes.DELETE_GENERAL_COMMENT,
  payload,
})
