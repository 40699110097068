import React, { useRef } from 'react'
import './styles.scss'

export default ({ accept, multiple, onChange }) => {
  const currentRef = useRef(null)
  return (
    <div
      className="upload-file-container"
      onClick={() => {
        if (currentRef) {
          currentRef.current.click()
        }
      }}
    >
      <p>Добави файл</p>
      <div className="file-icon" />
      <input
        type="file"
        multiple={multiple}
        ref={currentRef}
        accept={accept || '*'}
        onChange={(e) => onChange(e)}
        style={{ display: 'none' }}
      />
    </div>
  )
}
