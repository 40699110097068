import React, { useState, useEffect, useRef } from "react"
import { Input, Button } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import { Lottie } from "@crello/react-lottie";
import * as animationData from "../../../assets/animations/loading.json";
// import * as animationData from "../../assets/animations/loading.json";
import _, { add } from "lodash"
import {
  SingleRadioButton,
  QuantityDropdownInput,
  UploadFile,
  ClientAutocomplete,
} from "../../../components"
import {
  hideModal,
  sellHPOrder,
  getInquiries,
  showLoading,
  hideLoading,
  searchInquiries
} from "../../../actions"
import Files from "../../../utilities/files"
import "./styles.scss"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

function ReadyProductSellModal({
  hideModal,
  sellHPOrder,
  inquiries,
  getList,
  data,
  showLoading,
  hideLoading,
  searchInquiries,
  projects,
  inquiriesPage
}) {
  const [bankAccount, setBankAccount] = useState("")
  const [marginFacilities, setMarginFacilities] = useState(0)
  const [marginHydro, setMarginHydro] = useState(0)
  const [typeOfDeal, setTypeOfDeal] = useState("За проект")
  const [clientName, setClientName] = useState("")
  const [clientId, setClientId] = useState("")
  const [uic, setUIC] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [project, setProject] = useState("")
  const [orders, setOrders] = useState([])
  const [comment, setComment] = useState("")
  const [reason, setReason] = useState("")
  const [totalBefore, setTotalBefore] = useState(0)
  const [totalBeforeNoQuantity, setTotalBeforeNoQuantity] = useState(0)
  const [quantityCheck, setQuantityCheck] = useState([])
  const [file, setFile] = useState(null)

  const [projectResults, setProjectResults] = useState({})
  const [chosenProject, setChosenProject] = useState(null)
  const [focusedElement, setFocusedElement] = useState(null)
  const [loading, setLoading] = useState(false)
  const scrollRef = useRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
  };

  useEffect(() => {
    if (data.length) {
      setTotalBefore(
        data.reduce((acc, val) => {
          return acc + parseFloat(val.price) * parseInt(val.quantity)
        }, 0)
      )
      setTotalBeforeNoQuantity(
        data.reduce((acc, val) => {
          return acc + parseFloat(val.price)
        }, 0)
      )
      setQuantityCheck(data.map((el) => el.quantity))
      setOrders(
        data.map((el) => {
          return {
            _id: el._id,
            quantity: el.quantity,
            price: el.price,
            units: el.units,
          }
        })
      )
    }
  }, [data])

  useEffect(() => {
    getList()
  }, [getList])

  useEffect(() => {
    searchInquiries({pageSize: 20, pageNumber: inquiriesPage, sortBy: {object: 1}})
    // let updatedProjects = projects.map(project => {
    //   return { ...project, productsInvolved: project.orders.filter(order => order.product).map(order => order.product._id) }
    // })
    // console.log(updatedProjects)
    // setProjectResults(updatedProjects)
  }, [])

  const totalAfter = orders.reduce((acc, val) => {
    return acc + parseFloat(val.price) * parseInt(val.quantity)
  }, 0)
  const totalAfterNoQuantity = orders.reduce((acc, val) => {
    return acc + parseFloat(val.price)
  }, 0)

  const onReachEnd = () => {
    if (inquiriesPage) {
      let payload = {pageSize: 20, pageNumber: inquiriesPage, sortBy: {object: 1}}
      // if (query) {
      //   payload.object = query
      // }
      setLoading(true)
      searchInquiries(payload)
    }
  }

  const handleScrolling = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight } = scrollRef.current
      const innerHeight = scrollRef.current.getBoundingClientRect().height
      if (scrollTop + innerHeight >= scrollHeight - 10) {
        onReachEnd()
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  useEffect(() => {
    setMarginFacilities(totalAfter - totalBefore || "0")
    setMarginHydro(
      (
        ((totalAfterNoQuantity - totalBeforeNoQuantity) /
          totalBeforeNoQuantity) *
        100
      ).toFixed(0)
    )
  }, [totalAfter, totalAfterNoQuantity])

  return (
    <div className="ready-product-sell-container">
      <div className="top-container">
        <h3>Продажба</h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <p>Тип продажба</p>
      <div className="flex-container">
        <SingleRadioButton
          label="За проект"
          selected={typeOfDeal === "За проект"}
          onClick={() => {
            setTypeOfDeal("За проект")
          }}
        />
        <SingleRadioButton
          label="Свободна продажба"
          selected={typeOfDeal === "Свободна продажба"}
          onClick={() => setTypeOfDeal("Свободна продажба")}
        />
      </div>
      {typeOfDeal === "За проект" && (
        <div className="input-container relative">
          <p>Проект</p>
          {/* <Input.Dropdown
            placeholder=""
            value={project}
            options={projects.map((project) => project.object)}
            // options={inquiries.map((inquiry) => inquiry.object)}
            onChange={(value) => setProject(value.value)}
          /> */}

            <Input.Text
              value={chosenProject?.object}
              onFocus={() => {
                setFocusedElement(true)
              }}
              onBlur={() => {
                setTimeout(() => {
                  setFocusedElement(null)
                  setLoading(false)
                  if (chosenProject) {
                    searchInquiries({pageSize: 20, pageNumber: 1, sortBy: {object: 1}})
                  }
                }, 300)
              }}
              id='project-input'
              placeholder='Избор на проект'
              onChange={(e) => e.target.value = ''}
              />
             {focusedElement &&
             <div 
              ref={scrollRef}
              onScroll={delayedScrolling}
              className='project-suggestions-dropdown-wrapper'>
                {projects?.map(project => 
                  <div
                  className='single-project-suggestion' 
                  onClick={() => {
                    setChosenProject(project)
                    // setChosenProduct(null)
                    }}>{project?.object}</div>)}

                <div className='loader-wrapper'>
                  {loading && <Lottie config={defaultOptions} width={70} height={70} />}
                </div>
              </div>}
        </div>
      )}
      {orders.map((el, index) => {
        return (
          <div className="flex-container">
            <div className="product-name">{data[index]?.product}</div>
          <div className="flex-container space-between product-details">
            <div className="input-container relative">
              <p>Количество</p>
              <QuantityDropdownInput
                noDropdown
                noCurrency
                quantityTypeValue={{ value: el.units }}
                quantityValue={el.quantity}
                onSetQuantity={(value) => {
                  const newOrders = orders.slice(0)
                  newOrders[index].quantity = value
                  setOrders(newOrders)
                  setTotalBefore(
                    data.reduce((acc, val) => {
                      return acc + parseFloat(val.price) * parseInt(value)
                    }, 0)
                  )
                }}
              />
              {quantityCheck[index] < el.quantity && (
                <div id="quantity-check">
                  Количеството е по-голямо от наличното!
                </div>
              )}
            </div>
            <div className="input-container">
              <p>Ед. стойност</p>
              <QuantityDropdownInput
                noDropdown
                quantityValue={el.price}
                quantityTypeValue={{ value: el.units }}
                onSetQuantity={(value) => {
                  const newOrders = orders.slice(0)
                  newOrders[index].price = value
                  setOrders(newOrders)
                }}
              />
            </div>
            <div className="input-container">
              <p>Общо</p>
              <QuantityDropdownInput
                disabled
                noDropdown
                quantityValue={parseFloat(el.quantity) * parseFloat(el.price)}
                quantityTypeValue={{ value: el.units }}
                // onSelectType={(type) => console.log(type)}
              />
            </div>
            </div>
          </div>
        )
      })}
      <div className="flex-container space-between">
        <div className="input-element">
          <p>Клиент</p>
          <ClientAutocomplete
            onChooseClient={(data) => {
              setClientName(data.name)
              setClientId(data._id)
              if (data.uic) {
                setUIC(data.uic)
              } else {
                setUIC("")
              }
              if (data.phone) {
                setPhone(data.phone)
              } else {
                setPhone("")
              }
              if (data.email) {
                setEmail(data.email)
              } else {
                setEmail("")
              }
              if (data.address) {
                setAddress(data.address)
              } else {
                setAddress("")
              }
            }}
            onChange={(event) => setClientName(event.target.value)}
          />
        </div>
        <div className="input-element">
          <p>ЕИК</p>
          <Input.Text value={uic} />
        </div>
        <div className="input-element">
          <p>Телефон</p>
          <Input.Text value={phone} />
        </div>
        <div className="input-element">
          <p>Е-Мейл</p>
          <Input.Text value={email} />
        </div>
        <div className="input-element">
          <p>Адрес</p>
          <Input.Text value={address} />
        </div>
      </div>
      <div className="input-container">
        <p>Ваша банкова сметка</p>
        <Input.Dropdown
          placeholder="Моля изберете"
          value={bankAccount}
          options={["ДСК", "УниКредит", "Райфайзен"]}
          onChange={(value) => setBankAccount(value.value)}
        />
      </div>
      <div className="input-container">
        <p>Основание</p>
        <Input.Text
          value={reason}
          onChange={(event) => setReason(event.target.value)}
        />
      </div>
      <div className="flex-container">
        <div className="input-container">
          <p>Документи</p>
          {file ? (
            <div className="flex-container">
              <p id="file" onClick={() => window.open(file.url)}>
                {file.name}
              </p>
              <div className="remove-icon" onClick={() => setFile(null)} />
            </div>
          ) : (
            <UploadFile
              onChange={(event) => {
                showLoading()
                event.persist()
                const name = event.target.files[0].name
                Files.uploadFile(event.target.files[0])
                  .then((data) => {
                    setFile({ name: name, url: data.location })
                    hideLoading()
                  })
                  .catch((err) => {
                    hideLoading()
                  })
              }}
            />
          )}
        </div>
      </div>
      <div className="margin-container">
        <p>
          Марж: {totalAfter - totalBefore || "0"} лв. |{" "}
          {(
            ((totalAfterNoQuantity - totalBeforeNoQuantity) /
              totalBeforeNoQuantity) *
            100
          ).toFixed(0)}{" "}
          %
        </p>
      </div>
      <div className="flex-container flex-end">
        <Button.Raised
          text="Продай"
          onClick={() => {
            let payload = {
              reason: reason,
              inquiry: orders[0]._id,
              sell: orders.map((order) => {
                return {
                  _id: order._id,
                  quantity: order.quantity,
                  units: order.units,
                  price: order.price,
                  invoice: file,
                }
              }),
            }
            if (project) {
              payload.project = project
              // sellHPOrder(payload)
            }
            if (!clientName) {
              Alerts.error("Грешка!", "Моля изберете клиент")
            } else {
              payload.client = {
                name: clientName,
                uic: uic,
                phone: phone,
                email: email,
                address: address,
              }
              payload.clientId = clientId
            }
            payload.bankData = {
              bankAccount,
              marginFacilities: isNaN(marginFacilities) ? 0 : marginFacilities,
              marginHydro: isNaN(marginHydro) ? 0 : marginHydro,
            }
            sellHPOrder(payload)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiries: state.inquiries.data,
  data: state.modal.data,
  inquiriesPage: state.inquiries.inquiriesPage,
  projects: state.inquiries.inquiries.map(project => {
    return { ...project, productsInvolved: project.orders.filter(order => order.product).map(order => order.product._id) }
  })
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  sellHPOrder: (payload) => dispatch(sellHPOrder(payload)),
  getList: (payload) => dispatch(getInquiries(payload)),
  searchInquiries: (payload) => dispatch(searchInquiries(payload)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadyProductSellModal)
