import React, { useState, useRef, useEffect } from 'react'
import { Button, Input, Section } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities/lib/Alerts'
import { useSelector, useDispatch } from 'react-redux'
import { CheckListPopup } from '../../components'
import { CommentsActivityDashboard } from '../../components'
import { putProductionRequest, getPerformers, searchRequests, finishFinishingWork, showLoading, getProductionRequests, finishRequest, saveMachineActivity, getMachineActivity, deleteSingleFinishingWork, deleteEndProductSchedule } from '../../actions'
import { useParams, useLocation } from 'react-router-dom'
import { history } from '../../config/stores'
import { validateObjectValues } from '../../utilities/helpers'

import moment from 'moment'

import './styles.scss'

const durationDropdown = [
  { label: '30 мин', value: '30' },
  { label: '1 час', value: '60' },
  { label: '1ч. 30м.', value: '90' },
  { label: '2 часа', value: '120' },
  { label: '2ч. 30м.', value: '150' },
  { label: '3 часа', value: '180' },
  { label: '3ч. 30м.', value: '210' },
  { label: '4 часа', value: '240' },
  { label: '4ч. 30м.', value: '270' },
  { label: '5 часа', value: '300' },
  { label: '5ч. 30м.', value: '330' },
  { label: '6 часа', value: '360' },
  { label: '6ч. 30м.', value: '390' },
  { label: '7 часа', value: '420' },
  { label: '7ч. 30м.', value: '450' },
  { label: '8 часа', value: '480' }
]

const EndProduct = () => {
  const [mainUnit, setMainUnit] = useState('бр.')
  const [activitiesForFinish, setActivitiesForFinish] = useState([])
  const [activityMetadata, setActivityMetadata] = useState([]) //array with edited activities
  const [isDashboardActive, setIsDashboardActive] = useState(false)
  const [ isActivityDelayed, setIsActivityDelayed] = useState(false)
  const [mainData, setMainData] = useState({ quantity: 0, start: null, end: null, duration: 0, activity: '' })
  const [activities, setActivities] = useState([{ activity: '', activityCategory: '', date: null, startsAt: null, durationMinutes: 0, performers: [], comment: '', materials: [{ name: '', quantity: 0, total: 0 }] }])
  const [materialsQuery, setMaterialsQuery] = useState([[]])
  const [focusedMaterial, setFocusedMaterial] = useState(null)
  const [focusedActivity, setFocusedActivity] = useState(null)
  const [currentPopupIndex, setCurrentPopupIndex] = useState(null)
  const [focusedElement, setFocusedElement] = useState(null)
  const [focusedElementActivity, setFocusedElementActivity] = useState(null)
  const [activityQuery, setActivityQuery] = useState('')
  const [activityCategory, setActivityCategory] = useState(null)
  const [edit, setEdit] = useState(false)
  // const [isDateValid, setIsDateValid] = useState(true)
  // const [isTimeValid, setIsTimeValid] = useState(true)

  let popup = useRef(null)
  let finishPopup = useRef(null)
  const plusIcon = require('../../assets/icons/light-plus-icon.svg')
  const dispatch = useDispatch()

  const { id } = useParams()
  const { state } = useLocation()

  const performers = useSelector((state) => state.production.performers)
  const materials = useSelector((state) => state.storage.search)
  const savedActivity = useSelector((state) => state.production.savedMachineActivity)
  const finishingWorksData = useSelector((state) => state.production.data).find(req => req._id === id)?.finishingWorks || []
  // let data = Object.values(useSelector((state) => state.production.productSchedule))[0]
  const data = useSelector((state) => state.production.endProduct)
  const [ requestData, setRequestData ] = useState({})
  const [ finishedActivity, setFinishedActivity ] = useState({})
  const [dashboardSection, setDashboardSection] = useState('comments')
  const [ orientation, setOrientation ] = useState('')
  const [ variation, setVariation ] = useState('')
  let lockedFields = (data?.status === 'pending' || state?.data?.status === 'pending') ? false : !edit

  const finished = finishingWorksData.length ? finishingWorksData.findIndex(el => el.state !== 'finished') === -1 : false
  let lastFinishingWork = null

  const activityCategoryList = {
    'Списък със заготовки': 'list',
    'Отдел разкрой': 'slot',
    'Отдел сглобяване краен продукт': 'integration',
    'Други задачи': 'other'
  }
  
  const activitiesList = {
    list: [
      'Разкрой на OD 500 - 300мм изход шахта',
      'Муфиране с ел. муфа OD 500 и разкрой на 300мм',
      'Разкрой на стълби /шахти/ OD 500 - H1400',
      'Разкрой на люк  ID 800 H 350',
      'Разкрой на люк  ID 900 H 350',
      'Разкрой на люк  ID 630 H 350',
      'Разкрой на планки за товаро - разтоварна дейност',
      'Разкрой на крака от PN 200 L 500 за  ID 2000 & ID 2400',
      'Разкрой на планки 60x60 за укрепващи колони ID 2400',
      'Разкрой на крака за ЛО ID 315',
      'Прерязване на фунии за ЛО ID 315',
      'Разкрой на рингове за капаци Ф400',
      'Разкрой  на рингове за капаци Ф630',
      'Разкрой на рингове за капаци Ф800',
      'Разкрой на рингове за капаци Ф900',
      'Разкрой на рингове за капаци Ф1000',
      'Огъване на меден проводник за ел. муфа',
      'Подготовка на проводник ел. муфа',
      'Навиване на заваръчна тел на макари',
      'Изработка на ел. компоненти за ПС'
    ],
    slot: [
      'Разкрой и шлайфане на дъна ID 400',
      'Разкрой и шлайфане  на дъна ID 500',
      'Разкрой и шлайфане на дъна ID 630',
      'Разкрой и шлайфане на дъна ID 800',
      'Разкрой и шлайфане на дъна ID 900',
      'Разкрой и шлайфане на дъна ID 1000',
      'Разкрой и шлайфане  на дъна ID 1200',
      'Разкрой и шлайфане на дъна ID 1400',
      'Разкрой и шлайфане на дъна ID 1500',
      'Разкрой и шлайфане на дъна ID 2000',
      'Разкрой и шлайфане на дъна ID 2400',
      'Разкрой на комплект подсилки за дъно с размери 600мм, 300мм, 400мм за ID 2000',
      'Разкрой на комплект подсилки за дъно с размери 600мм, 300мм, 400мм за ID 2400',
      'Разкрой на укрепващи колони Н2350 мм',
      'Прерязване и лепене с машина на рингове за капаци Ф630',
      'Прерязване и лепене с машина  на рингове за капаци Ф800',
      'Прерязване и лепене с машина на рингове за капаци Ф900',
      'Прерязване и лепене с машина на рингове за капаци Ф1000',
      'Разкрой на PE плочи за муфи',
      'Огъване на муфа ID 300',
      'Огъване на муфа ID 315',
      'Огъване на муфа ID 400',
      'Огъване на муфа OD 400',
      'Огъване на муфа ID 500',
      'Огъване на муфа OD 500',
      'Огъване на муфа ID 630',
      'Огъване на муфа OD 630',
      'Огъване на муфа ID 700',
      'Огъване на муфа ID 800',
      'Огъване на муфа OD 800',
      'Огъване на муфа ID 900',
      'Огъване на муфа ID 1000',
      'Огъване на муфа ID 1200',
      'Огъване на муфа ID 1400',
      'Огъване на муфа ID 1500',
      'Огъване на муфа ID 2000',
      'Огъване на муфа ID 2400',
      'Кованена ел. муфа за ID 300',
      'Кованена ел. муфа за ID 315',
      'Кованена ел. муфа за ID 400',
      'Кованена ел. муфа за ID 500',
      'Кованена ел. муфа за ID 630',
      'Кованена ел. муфа за ID 800',
      'Кованена ел. муфа за ID 900',
      'Кованена ел. муфа за ID 1000',
      'Кованена ел. муфа за ID 1200',
      'Кованена ел. муфа за ID 1500',
      'Лепене и коване на гума муфа ID 300',
      'Лепене и коване на гума муфа ID 400',
      'Лепене и коване на гума муфа OD 400',
      'Лепене и коване на гума муфа ID 500',
      'Лепене и коване на гума муфа OD 500',
      'Лепене и коване на гума муфа ID 630',
      'Лепене и коване на гума муфа OD 630',
      'Лепене и коване на гума муфа ID 800',
      'Лепене и коване на гума муфа OD 800',
      'Лепене и коване на гума муфа ID 900',
      'Лепене и коване на гума муфа ID 1000',
      'Лепене и коване на гума муфа ID 1200',
      'Лепене и коване на гума муфа ID 1500',
      'Разкрой на компоненти за S-ka Ф 110 КМУ',
      'Разкрой на компоненти за S -ka Ф 160 КМУ',
      'Разкрой на компоненти за S -ka Ф 200 КМУ',
      'Разкрой на компоненти за S -ka Ф 250 КМУ',
      'Челна заварка S -ka Ф110  с машина за КМУ',
      'Челна заварка S -ka Ф160  с машина за КМУ',
      'Челна заварка S -ka Ф200  с машина за КМУ',
      'Челна заварка S -ka Ф250  с машина за КМУ',
      'Разкрой и разпробиване и окомплектоване филтър Ф 300 КМУ',
      'Разпробиване с CNC по чертеж',
      'Рязане на брак и пълнене в big bag',
      'Разкрой и заварка на кутия за филтър КМУ',
      'Разкрой и заварка на успокоител за КМУ'
    ],
    integration: [
      'Разкрой на тяло',
      'Шлайфане и подготовка за заварка',
      'Разчертаване с лазер',
      'Разпробиване  на отвор',
      'Заварка на дъно',
      'Заварка на подсилки',
      'Заварка на вход и изход шахта',
      'Заварка на люк',
      'Заварка на планки и укрепващи колони',
      'Заварка на стълба',
      'Заварка на крака',
      'Заварка на преграда',
      'Заварка на капак',
      'Разчертаване и разкрой за фитинги',
      'Заварка на фитинги',
      'Муфиране с ел. муфа',
      'Муфиране с муфа гума',
      'Заварка на  товаро-разтоварни планки',
      'Заварка на S -ka на КМУ',
      'Сглобяване детайли мини КМУ ID 630'
    ],
    other: [
      'Товарене на Big bags брак',
      'Доставка на ПЕ материал',
      'Транспорт на готова продукция',
      'Доставка на материали и консумативи',
      'Доставка на ПЕ плочи',
      'Посещение на обект',
      'Авариен ремонт на обект',
      'Ремонт в централен офис Амарант'
    ]
  }

  if (finished) {
    lastFinishingWork = finishingWorksData.sort((a, b) => {
      return moment(a.updatedAt).isBefore(b.updatedAt)
    })[0]
  }

  useEffect(() => {
    dispatch(showLoading())
    dispatch(getPerformers())
    dispatch(searchRequests({ search: '' }))
    dispatch(getProductionRequests())
    dispatch(getMachineActivity({ request: id, pageNumber: 1, pageSize: 20 }))
  }, [])

  useEffect(() => {
    !requestData && dispatch(showLoading())

    if (Object.keys(data).length) {
      let newOrientation = data?.product?.basicInfo?.find(el => el.name === 'Ориентация')?.value || ''
      let newVariation = data?.product?.basicInfo?.find(el => el.name === 'Вариация')?.value || ''
      setOrientation(newOrientation)
      setVariation(newVariation)
      lockedFields = data?.status === 'pending' ? false : !edit
      setFinishedActivity(data.finishingWorks)
      setRequestData(data)
    }
    else if (state?.data) {
      let newOrientation = state?.data?.product?.basicInfo?.find(el => el.name === 'Ориентация')?.value || ''
      let newVariation = state?.data?.product?.basicInfo?.find(el => el.name === 'Вариация')?.value || ''
      lockedFields = state?.data?.status === 'pending' ? false : !edit
      setOrientation(newOrientation)
      setVariation(newVariation)
      setFinishedActivity(state?.data?.finishingWorks)
      setRequestData(state.data) 
    }
  }, [data, state?.data])

  useEffect(() => {
    if (requestData) {
      if (requestData?.status === 'pending') {
        const newMainData = { ...mainData }
        const newActivities = activities.slice(0)
        const newMaterialsQuery = Array(requestData?.product?.expenses?.material?.length).fill('')
        newActivities[0].materials = requestData?.product?.expenses?.material
          ? requestData?.product?.expenses?.material.map(mat => {
              return { name: mat.material || '', quantity: 1, total: mat.total ? mat.total : 0 }
            })
          : [{ name: '', quantity: 1, total: 0 }]
        newMainData.quantity = requestData.quantity
        setMainUnit(requestData.product?.mainUnit)
        setMaterialsQuery([newMaterialsQuery])
        setActivities(newActivities)
        setMainData(newMainData)
      } else if (requestData?.status === 'in-production' ||
                 requestData?.state === 'work-in-proggress' ||
                 requestData?.status === 'finished') {
        const newActivities = finishingWorksData.sort((a, b) => {
          return moment(b.createdAt).isBefore(a.createdAt)
        }).map((activity, i) => {
          return {
            _id: activity._id,
            activity: activity.activity,
            date: moment(activity.date).toDate(),
            startsAt: moment(activity.startsAt).toDate(),
            comment: activity?.comments[0]?.text || '',
            durationMinutes: activity.durationMinutes,
            performers: activity.performers,
            materials: activity.materials?.map(material => {
              return { name: material.name, quantity: material.quantity, total: material.total }
            })
          }
        })
        const newMaterialsQuery = finishingWorksData.map(activity => {
          return activity.materials.map(el => el.name || '')
        })
        const newMainData = {
          quantity: requestData.quantity,
          start: finishingWorksData[0] ? moment(finishingWorksData[0].startsAt).toDate() : moment().toDate(),
          duration: finishingWorksData.reduce((acc, val) => {
            return acc + val.durationMinutes
          }, 0),
          activity: requestData?.activity,
          // end: finishingWorksData[0] ? moment(finishingWorksData[0].date).toDate() : moment().toDate(),
          // end: moment(requestData.deadline).toDate()
          end: moment(requestData.start).toDate()
        }
        newMainData.end = moment(newMainData.start).add(newMainData.duration, 'minutes').toDate()
        setMainData(newMainData)
        setMaterialsQuery(newMaterialsQuery)
        setActivities(newActivities)
      }
    }
  }, [requestData, finishingWorksData, edit])

  useEffect(() => {
    let newMainData = { ...mainData }
    let totalDuration = activities.slice(0).reduce((acc, curr) => { return parseInt(acc) + parseInt(curr?.durationMinutes) }, 0)

    if (moment(activities[0]?.startsAt).isBefore(mainData?.start)) {
      newMainData.start = moment(activities[0].startsAt).toDate()      
      // newMainData.start = moment(activities[0].startsAt)      
    }

    // let end = moment(newMainData.start).add(parseInt(totalDuration), 'minutes').toDate()
    let end = moment(activities[activities?.length - 1]?.startsAt).add(parseInt(activities[activities?.length - 1]?.durationMinutes), 'minutes')
    newMainData.duration = parseInt(totalDuration)
    newMainData.end = end

    setMainData(newMainData)
}, [activities])

  return (
    <div className='end-product-container'>
      <div className='shadow-container'>
        <div className='flex-container no-margin space-between'>
          <div className='heading-icons-wrapper'>
            <div className='flex-container heading'>
              <h2>{(requestData?.status === 'in-production' ||
                    requestData?.state === 'work-in-proggress' ||
                    requestData?.status === 'finished')
               ? 'Описание на дейността' : 'Към производство'}</h2>
               {(requestData?.status === 'in-production' || requestData?.state === 'work-in-proggress')
              && !(requestData.status === 'finished') 
              && edit
              && <img className='icon-pointer' src={require('../../assets/icons/bin.svg')}
               onClick={() => {
                Alerts.confirm({
                  title: "Сигурни ли сте, че искате да изтриете цялата дейност?",
                  confirm: "Да",
                  cancel: "Не",
                },
                (answer) => {
                  if (answer) {
                    dispatch(deleteEndProductSchedule({ _id: requestData?._id }))
                    history.push('/production')
                  }
                })
              }} />}
              {(requestData?.status === 'in-production' || requestData?.state === 'work-in-proggress')
              && !(requestData.status === 'finished')
              && !edit && 
              <div className='images-wrapper'>
                <div className='edit-button' onClick={() => setEdit(true)} />
                <img
                    src={require('../../assets/icons/dashboard-apps.svg')} onClick={() => {
                      setIsDashboardActive(!isDashboardActive)
                      setEdit(false)
                    }}
                  />
              </div>}
            </div>
            <p className='product-name'>
            {requestData?.product?.productType?.name} | <span>&nbsp;&nbsp;{orientation} {variation} &nbsp;&nbsp;</span>{(orientation || variation) && '  | '}&nbsp;&nbsp;{moment(requestData?.deadline).format('DD.MM.YYYY')}
            {(requestData?.inquiry?.checklist?.length > 0 && requestData?.inquiry?.checklist[requestData?.inquiry?.checklist?.length - 1]?.action === 'received') && <span className='file-wrapper subtitle'>&nbsp;&nbsp;| <span className='file-name' onClick={() => window.open(requestData?.inquiry?.checklist[requestData?.inquiry?.checklist?.length - 1]?.url)}>Чеклист от клиент</span><img src={require('../../assets/icons/download-icon.svg')} className="download-icon" /></span>}
            {(requestData?.drawing && Object.values(requestData?.drawing)) && <span className='file-wrapper subtitle'>&nbsp;&nbsp;| <span className='file-name' onClick={() => window.open(requestData?.drawing?.url)}>Чертеж</span><img src={require('../../assets/icons/download-icon.svg')} className="download-icon" /></span>}
            {requestData?.requestedFrom?.fullName && <span className='black'>&nbsp;&nbsp; | {requestData?.requestedFrom?.fullName}</span>}
            </p>
          </div>
          {requestData?.status === 'finished'
            ? <p id='finished-date'>Всички дейности са завършени</p>
            : edit
              ? <div className='flex-container no-margin'>
                <Button.Raised
                  clear text='Откажи' onClick={() => setEdit(false)}
                />
                <Button.Raised
                  text='Запази промени' onClick={() => {
                    let total = 0
                    activities.forEach(activity => activity.materials.forEach((mat) => {
                      total = total + mat.total
                    }))

                    let materialsData = []
                    activities.forEach(activity => activity.materials.forEach((mat) => {
                      materialsData.push({ name: mat.name , total: parseInt(mat.total * 100), quantity: mat.quantity })
                    }))

                    const payload = {
                      _id: requestData._id,
                      product: requestData.product._id,
                      inquiry: requestData.inquiry._id,
                      activity: mainData.activity,
                      expense: total,
                      quantity: requestData.quantity,
                      deadline: requestData.deadline,
                      start: mainData.start,
                      duration: mainData.duration,
                      materials: materialsData,
                      finishingWorks: activities.map(activity => {
                        return { ...activity, request: id }
                      })
                    }
                    
                    const activityPayload = {
                      request: requestData._id,
                      title: 'промени дейност',
                      metadata: {...payload, editedActivities: activityMetadata }
                    }

                    const payloadForCheck = JSON.parse(JSON.stringify(payload)); 
                    payloadForCheck.finishingWorks.filter(activity => delete activity.comment)

                    let performers = []
                    payload.finishingWorks.forEach(activity => {
                      performers.push(...activity.performers)
                    })
                    if (new Set(performers).size !== performers.length) {
                      return Alerts.error('Повтарят се изпълнители в повече от една дейност!')
                    }

                    const errors = validateObjectValues(payloadForCheck)
                    if (errors.error) {
                      Alerts.error(`${errors.errorsText} не са въведени!`)
                    } else {
                      dispatch(showLoading())
                      dispatch(saveMachineActivity(activityPayload))
                      dispatch(putProductionRequest(payload))
                    }
                  }}
                />
                </div>
              : (requestData?.status === 'in-production' || requestData?.state === 'work-in-proggress')
                ? <div className='flex-container no-margin'>
                  <Section.Popup
                    ref={(ref) => {
                      finishPopup = ref
                    }}
                    className='activities-popup'
                    content={
                      <>
                        <div className='flex-container no-margin space-between'>
                          <p>Избери дейности</p>
                          <div className='close-button' onClick={() => finishPopup.hide()} />
                        </div>
                        <div className='scroll-container auto' style={{ flex: 1 }} >
                          {finishingWorksData.map((el, index) => {
                            return (
                              <div
                                key={`activity-number-${index}`} className='flex-container space-between no-margin'
                                style={{ opacity: el.state === 'finished' ? 0.5 : 1 }}
                                onClick={() => {
                                  if (el?.state !== 'finished') {
                                    const newActivitiesForFinish = activitiesForFinish.slice(0)
                                    const foundIndex = newActivitiesForFinish.findIndex(activity => activity === el._id)
                                    if (foundIndex === -1) {
                                      newActivitiesForFinish.push(el._id)
                                    } else {
                                      newActivitiesForFinish.splice(foundIndex, 1)
                                    }
                                    setActivitiesForFinish(newActivitiesForFinish)
                                  }
                                }}
                              >
                                <p>{el.activity}</p>
                                <div
                                style={{cursor: el.state === 'finished' ? 'default' : 'pointer'}}
                                className={`checkbox-container ${activitiesForFinish.findIndex(activity => activity === el._id) !== -1 && 'selected'}`}>{(activitiesForFinish.findIndex(activity => activity === el._id) !== -1 || el.state === 'finished') && <div className='checkbox' />}</div>
                              </div>
                            )
                          })}
                        </div>
                        <Button.Raised
                          className={`no-margin ${!activitiesForFinish.length ? 'pale' : ''}`}
                          // disabled={!activitiesForFinish.length}
                          text='Завърши'
                          onClick={() => {
                            dispatch(showLoading())
                            dispatch(finishFinishingWork({ ids: activitiesForFinish, request: finishingWorksData[0].request._id }))
                            finishPopup.hide()
                            
                            activitiesForFinish.forEach(activityId => {
                              finishedActivity.splice(finishedActivity.findIndex(activity => activity._id === activityId), 1)
                            })
                            if (finishedActivity.findIndex(activity => activity.state !== 'finished') === -1) {
                              dispatch(finishRequest({ _id: finishingWorksData[0].request }))
                            }
                          }}
                        />
                      </>
                  }
                  >
                    <Button.Raised
                      className='blue'
                      text='Завърши дейност'
                      onClick={() => {
                        finishPopup.show()
                      }}
                    />
                  </Section.Popup>
                  </div>
                : <div className='flex-container no-margin'>
                  <Button.Raised
                    clear text='Откажи'
                    onClick={() => {
                      history.goBack()
                    }}
                  />
                  <Button.Raised
                    style={{opacity: !(mainData.quantity && mainData.start && mainData.end && mainData.duration && mainData.activity) ? 0.5 : 1}}
                    disabled={!(mainData.quantity && mainData.start && mainData.end && mainData.duration && mainData.activity)}
                    text='Добави в графика'
                    onClick={() => {
                      let total = 0
                      activities.forEach(activity => activity.materials.forEach((mat) => {
                        total = total + mat.total
                      }))
                      const payload = {
                        _id: requestData._id,
                        product: requestData.product._id,
                        inquiry: requestData.inquiry._id,
                        expense: total,
                        quantity: requestData.quantity,
                        deadline: requestData.deadline,
                        start: mainData.start,
                        end: mainData.end,
                        duration: mainData.duration,
                        // comment: activities.map(el => el.comment).join(', '),
                        activity: mainData.activity,
                        finishingWorks: activities.map(activity => {
                          return { ...activity, request: id }
                        })
                      }

                      const activityPayload = {
                        request: requestData._id,
                        title: 'activity',
                        metadata: {...payload, editedActivities: activityMetadata }
                      }

                      let payloadForCheck = JSON.parse(JSON.stringify(payload))
                      payloadForCheck.finishingWorks.forEach(activity => delete activity.comment)
                      const errors = validateObjectValues(payloadForCheck)
                      if (errors.error) {
                        Alerts.error(`${errors.errorsText} не са въведени!`)
                      } else {
                        dispatch(showLoading())
                        dispatch(saveMachineActivity(activityPayload))
                        dispatch(putProductionRequest(payload))
                      }
                    }}
                  />
                  </div>}
        </div>
      </div>
      {isDashboardActive &&
          <CommentsActivityDashboard
          id={id}
          tableData={savedActivity?.length > 0 ? savedActivity : []}
          activityHistory={true}
          // quantity={data?.quantity}
          // comments={commentsDashboard}
          dashboardSection={dashboardSection}
          setDashboardSection={(value) => setDashboardSection(value)}
          onClose={() => setIsDashboardActive(false)}
        //   onSend={(comment, machineScheduleWork) => {
        //     dispatch(addCommentMachine({ commentType: 'text', text: comment, machineScheduleWork: machineScheduleWork, machineSchedule: data._id }))
        //     dispatch(getSingleSchedule({ _id: id }))
        //   }
        // }
           />}
      <div className='shadow-container'>
        <h3>Основна информация</h3>
        <div className='flex-container smaller-margin'>
          <div className='quantity-section-wrapper'>
            <p className='label'>К-во</p>
            <div className='input-wrapper'>
              <Input.Text
                disabled={lockedFields}
                value={mainData.quantity}
                onChange={(e) => {
                  setMainData({...mainData, quantity: e.target.value})
                }}
              />
              <p>{mainUnit}</p>
            </div>
          </div>
          <div className='dates-section-wrapper'>
            <p className='label'>Ден</p>
            {(edit || requestData?.status === 'pending') ? 
            <Input.Datepicker
              disabled={lockedFields}
              placeholder=''
              pickDate={mainData.start}
              minDate={moment().toDate()}
              onChange={(e) => {
                const newMainData = { ...mainData }
                const newActivities = activities.map((activity, index) => {
                  let end = activity.end
                  let start = null
                  let date = null
                  if (index === 0) {
                    if (activity.durationMinutes) {
                      end = moment(e).add(parseInt(activity.durationMinutes), 'minutes').toDate()
                    }
                    start = e
                    date = e
                  } else {
                    let durationUntilNow = 0
                    activities.forEach((el, innerIndex) => {
                      if (innerIndex < index) {
                        durationUntilNow = durationUntilNow + el.durationMinutes || 0
                      }
                    })
                    start = moment(e).add(durationUntilNow, 'minutes').toDate()
                    date = moment(e).add(durationUntilNow, 'minutes').toDate()
                    end = moment(start).add(parseInt(activity.durationMinutes), 'minutes').toDate()
                  }
                  return { ...activity, date: date, startsAt: start, end: end }
                })
                newMainData.start = e
                if (newMainData.duration) {
                  newMainData.end = moment(e).add(parseInt(newMainData.duration), 'minutes')
                }
                setActivities(newActivities)
                setMainData(newMainData)
              }}
            /> :
            <Input.Text 
            value={moment(mainData?.start).format('DD.MM.YYYY') || ''}
            className={(requestData?.status === 'in-production' ||
                        requestData?.state === 'work-in-proggress' ||
                        requestData?.status === 'finished') && 'disabled-input' || 'date-input'}
            disabled={(requestData?.status === 'in-production' ||
                       requestData?.state === 'work-in-proggress' || 
                       requestData?.status === 'finished') && true}
            />}
          </div>
          <div className='hour-section-wrapper'>
            <p className='label'>Час</p>
           {(edit || requestData?.status === 'pending') ? 
            <Input.TimePicker
              disabled={!mainData.start || lockedFields}
              timeIntervals={30}
              pickDate={mainData.start}
              placeholder=''
              onChange={(e) => {
                const newMainData = { ...mainData }
                const newActivities = activities.map((activity, index) => {
                  let end = activity.end
                  let start = null
                  let date = null
                  if (index === 0) {
                    if (activity.durationMinutes) {
                      end = moment(e).add(parseInt(activity.durationMinutes), 'minutes').toDate()
                    }
                    start = e
                    date = e
                  } else {
                    let durationUntilNow = 0
                    activities.forEach((el, innerIndex) => {
                      if (innerIndex < index) {
                        durationUntilNow = durationUntilNow + el.durationMinutes || 0
                      }
                    })
                    start = moment(e).add(durationUntilNow, 'minutes').toDate()
                    date = moment(e).add(durationUntilNow, 'minutes').toDate()
                    end = moment(start).add(parseInt(activity.durationMinutes), 'minutes').toDate()
                  }
                  return { ...activity, date: date, startsAt: start, end: end }
                })
                newMainData.start = e
                if (newMainData.duration) {
                  newMainData.end = moment(e).add(parseInt(newMainData.duration), 'minutes')
                }
                setActivities(newActivities)
                setMainData(newMainData)
              }}
            /> :
            <Input.Text 
              disabled
              className='disabled-input'
              value={moment(mainData.start).format('HH:mm') || null}
            />}
          </div>
          <div className='duration-section-wrapper'>
            <p className='label'>Времетраене</p>
            {/* {(edit || requestData?.status === 'pending') ?
            <Input.Dropdown
              disabled={!mainData.start || lockedFields}
              options={durationDropdown}
              // value={durationDropdown.find(data => parseInt(data.value) === parseInt(mainData.duration))?.label || ''}
              value={mainData.duration.toString() || ''}
              onChange={(e) => {
                if (parseInt(e.value) / activities.length < 30) {
                  Alerts.error('Трябва поне 30 минути на активност')
                } else {
                  const newMainData = { ...mainData }
                  let restOfActivitiesTime = 0
                  const newActivities = activities.map((activity, index) => {
                    const restOfTime = (parseInt(e.value) / activities.length) % 30
                    let singleTime = null
                    let start = null
                    let date = null
                    if (index + 1 === activities.length) {
                      singleTime = parseInt(e.value) - restOfActivitiesTime
                      start = moment(newMainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                      date = moment(newMainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                    } else {
                      start = moment(newMainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                      date = moment(newMainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                      singleTime = (parseInt(e.value) / activities.length) - restOfTime
                      restOfActivitiesTime = restOfActivitiesTime + (parseInt(e.value) / activities.length) - restOfTime
                    }
                    const end = moment(start).add(singleTime.toFixed(0), 'minutes').toDate()
                    return { ...activity, durationMinutes: singleTime, end: end, startsAt: start, date: date }
                  })
                  newMainData.duration = parseInt(e.value)
                  if (newMainData.start) {
                    newMainData.end = moment(newMainData.start).add(parseInt(e.value), 'minutes').toDate()
                  }
                  setActivities(newActivities)
                  setMainData(newMainData)
                }
              }}
            /> :
            <Input.Text
            className='disabled-input'
            disabled
            placeholder=''
            value={durationDropdown.find(data => parseInt(data.value) === parseInt(mainData?.duration))?.label || ''}
            />} */}

            <Input.Text
            className='disabled-input'
            disabled
            placeholder=''
            // value={mainData?.duration}
            value={durationDropdown.find(data => parseInt(data.value) === parseInt(mainData?.duration))?.label || ''}
            />
          </div>
          {(edit || requestData?.status === 'pending') && 
          <div className='input-container no-padding activity'>
            <p className='label'>Дейност</p>
            <Input.Text
              disabled={lockedFields}
              value={mainData.activity}
              onChange={(e) => {
                const newMainData = { ...mainData }
                newMainData.activity = e.target.value
                setMainData(newMainData)
              }}
            />
          </div>}
          {(requestData?.status === 'in-production' ||
            requestData?.state === 'work-in-proggress' ||
            requestData?.status === 'finished') && !edit &&
                  <div className='planned-deadline-section-wrapper'>
                    <p className='label'>Планиран край</p>
                    <div className='blue-data-wrapper'>
                      <p>{moment(mainData?.end).format('DD.MM.YYYY | HH:mm')}</p>
                    </div>
                  </div>}
        </div>
        <p id='small'>*Времетраенето е базирано на последната настройка с тези параметри. </p>
        {/* {!isDateValid && 
            <p className='warning-msg'>Моля обърнете внимание, че датата, която сте посочили е след зададения срок за доставка.</p>
          }
          {!isTimeValid && 
            <p className='warning-msg'>{`Зададеният час и времетраене не съответсват с графика за деня на машина ${selectedBtn}. Моля сменете часа.`}</p>
          } */}
      </div>
      {(requestData?.status === 'pending' || edit) &&
      <div className='shadow-container'>
        <div className='flex-container no-margin'>
          <h3>Дейности</h3>
          <Input.Dropdown
            disabled={lockedFields}
            className='activity-dropdown'
            options={[1, 2, 3, 4]}
            value={activities.length.toString()}
            onChange={(e) => {
              const newActivities = []
              if (!mainData.start || !mainData.duration) {
                Array(e.value).fill('').forEach((el, i) => {
                  if (activities[i]) {
                    newActivities.push({ ...activities[i] })
                  } else {
                    newActivities.push({
                      activity: '',
                      startsAt: '',
                      date: '',
                      durationMinutes: 0,
                      performers: [],
                      materials: requestData?.product?.expenses?.material
                        ? requestData?.product?.expenses?.material.map(mat => {
                            return { name: mat.material || '', quantity: 1, total: mat.total ? mat.total : 0 }
                          })
                        : [{ name: '', quantity: 1, total: 0 }]
                    })
                  }
                })
              } else {
                if (parseInt(mainData.duration) / parseInt(e.value) < 30) {
                  Alerts.error('Трябва поне 30 минути на активност')
                } else {
                  let restOfActivitiesTime = 0
                  Array(e.value).fill('').forEach((el, i) => {
                    const restOfTime = (parseInt(mainData.duration) / parseInt(e.value)) % 30
                    let start = null
                    let date = null
                    let duration = 0
                    if (i === 0) {
                      start = mainData.start
                      date = mainData.start
                    } else {
                      if (i + 1 === activities.length) {
                        start = moment(mainData.start).add(parseInt(e.value) - restOfActivitiesTime, 'minutes').toDate()
                        date = moment(mainData.start).add(parseInt(e.value) - restOfActivitiesTime, 'minutes').toDate()
                      } else {
                        start = moment(mainData.start).add((parseInt(mainData.duration) / parseInt(e.value)) - restOfTime, 'minutes').toDate()
                        date = moment(mainData.start).add((parseInt(mainData.duration) / parseInt(e.value)) - restOfTime, 'minutes').toDate()
                      }
                    }
                    if (i + 1 === Array(e.value).fill('').length) {
                      duration = parseInt(mainData.duration) - restOfActivitiesTime
                      start = moment(mainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                      date = moment(mainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                    } else {
                      start = moment(mainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                      date = moment(mainData.start).add(restOfActivitiesTime, 'minutes').toDate()
                      duration = (parseInt(mainData.duration) / parseInt(e.value)) - restOfTime
                      restOfActivitiesTime = restOfActivitiesTime + (parseInt(mainData.duration) / parseInt(e.value)) - restOfTime
                    }
                    if (activities[i]) {
                      newActivities.push({ ...activities[i], startsAt: start, date: date, durationMinutes: duration })
                    } else {
                      newActivities.push({
                        activity: '',
                        startsAt: start,
                        date: date,
                        durationMinutes: duration,
                        performers: [],
                        materials: requestData?.product?.expenses?.material
                          ? requestData?.product?.expenses?.material.map(mat => {
                              return { name: mat.material || '', quantity: 1, total: mat.total ? mat.total : 0 }
                            })
                          : [{ name: '', quantity: 1, total: 0 }]
                      })
                    }
                  })
                }
              }

              const oldMaterialsMap = newActivities.map(activity => activity.materials.map(material => {
                if (material.name) {
                  return material.name
                } else {
                  return ''
                }
              }))
              setMaterialsQuery(oldMaterialsMap)
              setActivities(newActivities)
            }}
          />
        </div>
      </div>}
      {activities.map((activity, index) => {
        return (
          <div key={`activity-number-${index}`} className='shadow-container'>
            <div className='flex-container wrap'>
              <div className='input-container no-padding activity'>
                <p className='label medium'>Дейност {index + 1}</p>
                {/* <Input.Text
                  disabled={lockedFields || (requestData?.finishingWorks ? requestData?.finishingWorks[index]?.state === 'finished' : '')}
                  value={activity.activity}
                  onChange={(e) => {
                    const newActivities = activities.slice(0)
                    newActivities[index].activity = e.target.value
                    setActivities(newActivities)
                  }}
                /> */}

                {(!edit && requestData?.status !== 'pending') && 
                <div className='dropdowns-wrapper'>
                  <Input.Text
                     disabled
                     value={activity.activityCategory}
                     />
  
                  <Input.Text
                     disabled
                     value={activity.activity}
                     />
                </div>}

                {(edit || requestData?.status === 'pending') && 
                <div className='dropdowns-wrapper'>

                  <Input.Dropdown
                     disabled={lockedFields || (requestData?.finishingWorks ? requestData?.finishingWorks[index]?.state === 'finished' : '')}
                     className='activity-dropdown'
                     options={Object.keys(activityCategoryList)}
                     value={activity.activityCategory}
                     placeholder=''
                     onChange={(e) => {
                      const newActivities = activities.slice(0)
                      newActivities[index].activity = ''
                      newActivities[index].activityCategory = e.value
                       setActivities(newActivities)
                       setActivityCategory(activityCategoryList[e.value])
                       setActivityQuery(e.value)
                     }}
                     />

                   <Input.Dropdown
                     disabled={lockedFields || (requestData?.finishingWorks ? requestData?.finishingWorks[index]?.state === 'finished' : '')}
                     className='activity-dropdown'
                     options={activitiesList[activityCategory]}
                     value={activity.activity}
                     placeholder=''
                     onChange={(e) => {
                      const newActivities = activities.slice(0)
                      newActivities[index].activity = e.value
                      setActivities(newActivities)
                     }}
                     />
              </div>}

              </div>

              <div className='input-container no-padding date no-flex'>
                <p className='label'>Ден</p>
                {(edit || requestData?.status === 'pending') || (edit && requestData?.finishingWorks[index]?.state === 'work-in-proggress') ? 
                <Input.Datepicker
                  // disabled
                  disabled={!mainData.start || lockedFields}
                  placeholder=''
                  pickDate={activity.startsAt}
                  onChange={(date) => {
                    const newActivities = activities.slice(0);
                    if (edit) {
                      newActivities[index].isDelayed = true
                    }

                    // if (savedActivity?.metadata?.finishingWorks[index]?.date === newActivities[index]?.date 
                    //   && savedActivity?.metadata?.finishingWorks[index]?.durationMinutes === newActivities[index]?.durationMinutes) {
                    //     newActivities[index].isDelayed = false
                    //   }

                    newActivities[index].startsAt = date
                    newActivities[index].date = date
                    newActivities[index].end = moment(date).add(parseInt(newActivities[index].durationMinutes), 'minutes').toDate()

                    setActivities(newActivities)

                    let newActivityData = JSON.parse(JSON.stringify(activityMetadata))

                    if (!newActivityData.map(el => el?._id).includes(newActivities[index]?._id)) {
                      let editedActivity = JSON.parse(JSON.stringify(newActivities[index]))
                      editedActivity.editDate = moment()
                      newActivityData.push(editedActivity)
                    }
                    // else if (moment(savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.date).isSame(moment(newActivities[index]?.date), 'day')
                    //       && (moment(savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.date).isSame(moment(newActivities[index]?.date), 'hour')) 
                    //       && savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.durationMinutes === newActivities[index]?.durationMinutes) {
                    //         newActivityData.splice(newActivityData.indexOf(newActivities[index]), 1)
                    // }

                    setActivityMetadata(newActivityData)
                  }}
                /> :
                <Input.Text 
                value={moment(activity.startsAt).format('DD.MM.YYYY') || ''}
                className={(requestData?.status === 'in-production'
                || requestData?.state === 'work-in-proggress' || requestData?.status === 'finished') && 'disabled-input' || 'date-input'}
                disabled={(requestData?.status === 'in-production' ||
                requestData?.state === 'work-in-proggress' || requestData?.status === 'finished') && true}
                />}
              </div>
              <div className='input-container no-padding hour no-flex'>
                <p className='label'>Час</p>
                {(edit || requestData?.status === 'pending') || (edit && requestData?.finishingWorks[index]?.state === 'work-in-proggress') ? 
                <Input.TimePicker
                  // disabled
                  disabled={!mainData.start || lockedFields}
                  timeIntervals={30}
                  pickDate={activity.startsAt}
                  placeholder=''
                  onChange={(e) => {
                    const newActivities = activities.slice(0)

                    if (edit) {
                      newActivities[index].isDelayed = true
                    }

                    newActivities[index].startsAt = e
                    newActivities[index].date = e
                    newActivities[index].end = moment(e).add(parseInt(newActivities[index].durationMinutes), 'minutes').toDate()

                    setActivities(newActivities)

                    let newActivityData = JSON.parse(JSON.stringify(activityMetadata))

                    if (!newActivityData.map(el => el?._id).includes(newActivities[index]?._id)) {
                      let editedActivity = JSON.parse(JSON.stringify(newActivities[index]))
                      editedActivity.editDate = moment()
                      newActivityData.push(editedActivity)
                    }
                    // else if (moment(savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.date).isSame(moment(newActivities[index]?.date), 'day')
                    //       && (moment(savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.date).isSame(moment(newActivities[index]?.date), 'hour')) 
                    //       && savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.durationMinutes === newActivities[index]?.durationMinutes) {
                    //         newActivityData.splice(newActivityData.indexOf(newActivities[index]), 1)
                    // }

                    setActivityMetadata(newActivityData)
                  }}
                /> :
                <Input.Text 
                disabled
                className='disabled-input'
                value={moment(activity.startsAt).format('HH:mm') || null}
                />
                }
              </div>
              <div className='input-container no-padding duration no-flex'>
                <p className='label'>Времетраене</p>
                {(edit || requestData?.status === 'pending') || (edit && requestData?.finishingWorks[index]?.state === 'work-in-proggress') ? 
                <Input.Dropdown
                  // disabled
                  disabled={!mainData.start || lockedFields}
                  options={durationDropdown}
                  value={durationDropdown.find(data => parseInt(data.value) === parseInt(activity?.durationMinutes))?.label || ''}
                  placeholder=''
                  onChange={(e) => {
                    // const newActivities = activities.slice(0)
                    // newActivities[index].durationMinutes = parseInt(e.value)
                    // setActivities(newActivities)
                    const newMainData = { ...mainData }
                    if (newMainData.duration) {
                      newMainData.end = moment(e).add(parseInt(newMainData.duration), 'minutes')
                    }
                    setMainData(newMainData)

                    const newActivities = activities.slice(0)
                    if (edit) {
                      newActivities[index].isDelayed = true
                    }
                    let totalDuration = activities.slice(0).reduce((acc, curr) => { return parseInt(acc) + parseInt(curr?.durationMinutes) }, 0)
                    newActivities[index].durationMinutes = parseInt(e.value)
                    newActivities[index].end = moment(e).add(parseInt(newActivities[index].durationMinutes), 'minutes').toDate()
                    setActivities(newActivities)
                    // setMainData({ ...mainData, duration: parseInt(totalDuration) })

                    let newActivityData = JSON.parse(JSON.stringify(activityMetadata))

                    if (!newActivityData.map(el => el?._id).includes(newActivities[index]?._id)) {
                      let editedActivity = JSON.parse(JSON.stringify(newActivities[index]))
                      editedActivity.editDate = moment()
                      newActivityData.push(editedActivity)
                    }
                    // else if (moment(savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.date).isSame(moment(newActivities[index]?.date), 'day')
                    //       && (moment(savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.date).isSame(moment(newActivities[index]?.date), 'hour')) 
                    //       && savedActivity[savedActivity.length - 1]?.metadata?.finishingWorks[index]?.durationMinutes === newActivities[index]?.durationMinutes) {
                    //         newActivityData.splice(newActivityData.indexOf(newActivities[index]), 1)
                    // }

                    setActivityMetadata(newActivityData)
                  }}
                /> :
                <Input.Text
                  className='disabled-input'
                  disabled
                  // value={activity?.durationMinutes?.toString()}
                  value={durationDropdown.find(data => parseInt(data.value) === parseInt(activity?.durationMinutes))?.label || ''}
                  />}
              </div>
            <div className='input-container no-padding contractor-section-wrapper'>
              <p className='label'>Изпълнители</p>
              <div className='contractors-btn-names-wrapper'>
               {((edit || requestData?.status === 'pending') && !(requestData?.finishingWorks[index]?.state === 'finished')) && 
               <Section.Popup
                  ref={(ref) => {
                    popup = ref
                  }}
                  className='checklist-popup'
                  content={
                    <CheckListPopup
                      data={performers}
                      startDate={mainData?.start}
                      endDate={mainData?.end}
                      handleCheckList={(data) => {
                        if (currentPopupIndex !== null) {
                          const newActivities = activities.slice(0)
                          newActivities[currentPopupIndex].performers = data
                          setActivities(newActivities)
                          setCurrentPopupIndex(null)
                          popup.hide()
                        }
                      }}
                      payloadPerformers={activities[currentPopupIndex]?.performers}
                      onClose={() => {
                        setCurrentPopupIndex(null)
                        popup.hide()
                      }}
                    />
                  }
                >
                  <img
                    src={plusIcon}
                    alt='+'
                    onClick={() => {
                      if (!lockedFields) {
                        setCurrentPopupIndex(index)
                        popup.show()
                      }
                    }}
                  />
                </Section.Popup>}
                  <div className='names-icon-wrapper'>
                    
                    {activity.performers.map((performer, i) => {
                      return (
                        <div className='single-name-icon-wrapper'>
                          {((edit || requestData?.status === 'pending') && !(requestData?.finishingWorks[index]?.state === 'finished'))
                          && <img className='icon-pointer' src={require('../../assets/icons/remove-btn.svg')} onClick={() => {
                           const newActivities = activities.slice(0)
                           newActivities[index].performers = activity?.performers.filter(el => el !== performer)
                           setActivities(newActivities)
                          }}/>}
                          <p key={`performer-number-${i}-${index}`}>{performer}</p>
                        </div>
                        )
                    })}
                  </div>
              </div>
                {/* <div className='horizontal-scroll'>
                  <div className='flex-container'>
                    {activity.performers.map((performer, index) => {
                      return (<p style={{ marginRight: '10px', fontSize: 11 }} key={`performer-number-${index}`}>{performer}</p>)
                    })}
                  </div>
                </div> */}
              </div>
              {!edit && finishedActivity[index]?.state !== 'finished'
              // && savedActivity[0]?.metadata?.editedActivities?.map(el => el?._id)?.includes(activity?._id)
              && savedActivity[0]?.metadata?.editedActivities?.map(el => el?._id)?.includes(activity?._id)
              && <div className='blue-data-wrapper flex'>
                    <span className='medium'>Последна редакция: &nbsp;</span>
                    {/* <p>{moment(savedActivity[0]?.metadata?.editedActivities[savedActivity[0]?.metadata?.editedActivities.length - 1]?.editDate)?.format('DD.MM.YYYY | HH:mm')}</p> */}
                    <p>{moment(savedActivity[0]?.createdAt)?.format('DD.MM.YYYY | HH:mm')}</p>
                    <img src={require('../../assets/icons/revert-arrow-icon.svg')} onClick={() => setIsDashboardActive(true)} />
                 </div>}
              {finishedActivity[index]?.state === 'finished' &&
                <div className='blue-data-wrapper'>Завършена на:&nbsp;<span>{moment(finishedActivity[index]?.updatedAt).format('DD.MM.YYYY')}</span></div>}
              {finishedActivity[index]?.state !== 'finished' && edit &&
                <img className='icon-pointer' src={require('../../assets/icons/remove-btn.svg')}
                onClick={() => {
                  Alerts.confirm({
                    title: "Сигурни ли сте, че искате да изтриете дейността?",
                    confirm: "Да",
                    cancel: "Не",
                  },
                  (answer) => {
                    if (answer) {
                      dispatch(deleteSingleFinishingWork({ _id: requestData?.finishingWorks[index]?._id }))
                      history.push('/production')
                    }
                  })
                }} />}

              {/* {finishedActivity[index]?.state === 'finished' &&
                <div className='blue-data-wrapper'>Завършена на:&nbsp;<span>{moment(finishedActivity[index].updatedAt).format('DD.MM.YYYY')}</span></div>} */}
            </div>
            <div className='flex-container no-margin column'>
            <p className='label'>{(edit || requestData?.status === 'pending' || !(requestData?.finishingWorks ? requestData?.finishingWorks[index]?.state === 'finished' : ''))
            ? 'Коментар' : 'Описание'}</p>
            <Input.Text 
            disabled={lockedFields || (requestData?.finishingWorks ? requestData?.finishingWorks[index]?.state === 'finished' : '')} 
            value={activity.comment}
            onChange={(e) => {
              const newActivities = activities.slice(0)
              newActivities[index].comment = e.target.value
              setActivities(newActivities)
            }}
            />
            </div>
            <div className='flex-container no-margin material'>
              <h3>Материали</h3>
              {(edit || requestData?.status === 'pending') && !(finishedActivity[index]?.state === 'finished') && 
              <Input.Dropdown
                disabled={lockedFields}
                className='activity-dropdown'
                options={[1, 2, 3, 4]}
                value={activities[index].materials.length.toString()}
                onChange={(e) => {
                  const newActivities = activities.slice(0)
                  const newMaterialsQuery = materialsQuery.slice(0)
                  const newMaterials = []
                  Array(e.value).fill('').forEach((el, i) => {
                    if (newActivities[index].materials[i]) {
                      newMaterials.push(newActivities[index].materials[i])
                    } else {
                      newMaterials.push({ name: '', quantity: 1, total: 0 })
                    }
                  })
                  newMaterialsQuery[index] = newMaterials.map(material => {
                    if (material.name) {
                      return material.name
                    } else {
                      return ''
                    }
                  })
                  newActivities[index].materials = newMaterials
                  setMaterialsQuery(newMaterialsQuery)
                  setActivities(newActivities)
                }}
              />}
            </div>
            <div className='flex-container no-margin wrap'>
              {activities[index].materials.map((material, matIndex) => {
                return (
                  <div key={`material-number-${matIndex}`} className='flex-container material align-bottom'>
                    <div className='input-container no-padding material'>
                      <p id='small'>Материал {matIndex + 1}</p>
                      <div className='input-wrapper material'>
                        <Input.Text
                          disabled={lockedFields || finishedActivity[index]?.state === 'finished'}
                          onFocus={() => {
                            setFocusedActivity(index)
                            setFocusedMaterial(matIndex)
                            setFocusedElement(true)
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setFocusedElement(null)
                            }, 300)
                          }}
                          value={materialsQuery[index] && materialsQuery[index][matIndex] || ''}
                          onChange={(e) => {
                            const newMaterialsQuery = materialsQuery.slice(0)
                            newMaterialsQuery[index][matIndex] = e.target.value
                            setMaterialsQuery(newMaterialsQuery)
                          }}
                        />
                        {focusedMaterial === matIndex && focusedActivity === index && focusedElement &&
                          <div className='dropdown-container'>
                            <div className='suggestions-wrapper'>
                            {materials
                            .filter(mat => mat.material.toLowerCase().includes(materialsQuery[index][matIndex].toLowerCase()))
                            .filter(el => {
                                return !materialsQuery[index].includes(el.material) && el.material
                              // let materialsArray = activities[index].materials.slice(0).map(material => material.name)
                              //   return !materialsArray.includes(el.material) && el.material
                              })
                            .map((mat, i) => {
                              return (
                                <div
                                  className='suggestion'
                                  key={`material-name-${i}`}
                                  onClick={() => {
                                    const newActivities = activities.slice(0)
                                    const newMaterialsQuery = materialsQuery.slice(0)
                                    newActivities[index].materials[matIndex] = { name: mat.material || '', quantity: 1, total: mat.price }
                                    newMaterialsQuery[index][matIndex] = mat.material
                                    setMaterialsQuery(newMaterialsQuery)
                                    setActivities(newActivities)
                                    setFocusedMaterial(null)
                                  }}
                                >{mat.material}
                                </div>
                              )
                            })}
                            </div>
                          </div>}
                      </div>
                    </div>
                    <div className='quantity-section-wrapper material'>
                    <p className='label' id='small'>Количество</p>
                      
                      <div className='input-wrapper'>
                       <Input.Text
                        disabled={lockedFields || finishedActivity[index]?.state === 'finished'}
                        value={material.quantity}
                        onChange={(e) => {
                          const newActivities = activities.slice(0)
                          const found = materials.find(mat => mat.material === newActivities[index].materials[matIndex].name)
                          newActivities[index].materials[matIndex].quantity = e.target.value
                          newActivities[index].materials[matIndex].total = parseInt(e.target.value) * found?.price
                          setActivities(newActivities)
                        }}
                      />
                       <p>{mainUnit}</p>
                      </div>
                    </div>

                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default EndProduct