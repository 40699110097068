import React, { useState } from "react"
import { SingleRadioButton } from "../../../../components"
import { Button } from "@makedonski/admin-ui"
import "./styles.scss"

function ExpensePopup({ createExpense, onlyQuantity }) {
  const [storageType, setStorageType] = useState("Суровини")
  const [quantityType, setQuantityType] = useState("Количество")
  return (
    <div className="add-expense-popup">
      <h3>От склад</h3>
      <SingleRadioButton
        label="Суровини"
        onClick={(value) => setStorageType(value)}
        selected={storageType === "Суровини"}
      />
      <SingleRadioButton
        label="Продукти"
        onClick={(value) => setStorageType(value)}
        selected={storageType === "Продукти"}
      />
      <h3>Начин на изчисление</h3>
      <SingleRadioButton
        disabled={onlyQuantity}
        label="Количество"
        onClick={(value) => {
          if (!onlyQuantity) {
            setQuantityType(value)
          }
        }}
        selected={quantityType === "Количество"}
      />
      <SingleRadioButton
        disabled={onlyQuantity}
        label="Съотношение"
        onClick={(value) => {
          if (!onlyQuantity) {
            setQuantityType(value)
          }
        }}
        selected={quantityType === "Съотношение"}
      />
      <Button.Raised
        text="Избери"
        className="button-green"
        onClick={() => {
          let data = { type: storageType }
          if (quantityType === "Съотношение") {
            data = { ...data, ratio: "", quantity: null }
          } else {
            data = { ...data, ratio: null, quantity: 0 }
          }
          createExpense(data)
        }}
      />
    </div>
  )
}

export default ExpensePopup
