import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react"
import { Button } from "@makedonski/admin-ui"
import _ from "lodash"
import "./styles.scss"

const AutoSuggest = (
  {
    className,
    placeholder,
    options,
    updateOptions,
    value,
    setValue,
    onSuggestionClick,
    selected,
    handleFocus,
    disabled,
  },
  ref
) => {
  const scrollRef = useRef(null)
  const inputRef = useRef()
  useImperativeHandle(ref, () => ({
    closeSuggest: () => setSuggestOpen(false),
  }))

  const [suggestOpen, setSuggestOpen] = useState(false)
  const [index, setIndex] = useState(null)

  useEffect(() => {
    updateOptions("")
    //eslint-disable-next-line
  }, [])
  const updateIndex = (up = true) => {
    let newIndex = index === null ? -1 : index
    newIndex = up ? newIndex + 1 : newIndex - 1
    if (!up && newIndex < 0) newIndex = 0
    if (up && newIndex > options.length - 1) newIndex = 0
    setIndex(newIndex)
    if (scrollRef.current) scrollRef.current.scrollTop = newIndex * 35
  }

  const onChangeDelayed = useCallback(
    _.debounce((title) => updateOptions(title), 200)
  )

  const handleChange = (e) => {
    e.persist()
    setValue(e.target.value)
    onChangeDelayed(e.target.value)
  }
  return (
    <div className={`auto-suggestion-content-container ${className || ""}`}>
      <div className="text-input-container">
        <input
          disabled={disabled}
          ref={inputRef}
          className="text-input"
          value={value}
          placeholder={placeholder || "Search:"}
          onFocus={() => {
            if (!disabled) {
              setSuggestOpen(true)
              handleFocus && handleFocus()
            }
          }}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => {
            if (e.keyCode === 40) updateIndex(true)
            else if (e.keyCode === 38) updateIndex(false)
            if (e.keyCode === 13) {
              const option = options[index]
              if (!option) return
              setValue(option.title)
              setIndex(-1)
              setSuggestOpen(false)
              onSuggestionClick(option)
            }
          }}
        />
      </div>
      {suggestOpen && (
        <Button.Icon
          name="plus"
          size={14}
          onClick={() => setSuggestOpen(false)}
          className="tick"
        />
      )}
      <div
        ref={scrollRef}
        className={`suggestions-container ${
          suggestOpen && options.length ? "active" : ""
        }`}
      >
        {suggestOpen &&
          options.map((option, i) => {
            return (
              <div
                key={`sugggestion-${i}`}
                className={`suggestion text12regular cursor-pointer ${
                  index === i ? "suggestion-active" : ""
                }`}
                onClick={() => {
                  setValue(option.title)
                  setIndex(-1)
                  setSuggestOpen(false)
                  onSuggestionClick(option)
                }}
              >
                {option.title}{" "}
                {option.variation && "(" + option.variation + ")"}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default forwardRef(AutoSuggest)
