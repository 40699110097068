import React, { useState } from 'react'
import './ProductionPipes.scss'

export default function ProductionPipes ({ onSetRequestType }) {
  const [selectedTab, setSelectedTab] = useState('Тръби - машини')

  return (
    <div className='production-pipes-container'>
      <div className='production-tabs'>
        <div
          onClick={() => {
            onSetRequestType('Тръби - машини')
            setSelectedTab('Тръби - машини')
          }}
          className={selectedTab === 'Тръби - машини' ? 'tab selected' : 'tab'}
        >
          Тръби - машини
        </div>
        <div
          onClick={() => {
            onSetRequestType('Тръби - изпълнители')
            setSelectedTab('Тръби - изпълнители')
          }}
          className={selectedTab === 'Тръби - изпълнители' ? 'tab selected' : 'tab'}
        >
          Тръби - изпълнители
        </div>
        <div
          onClick={() => {
            onSetRequestType('Краен продукт')
            setSelectedTab('Краен продукт')
          }}
          className={selectedTab === 'Краен продукт' ? 'tab selected' : 'tab'}
        >
          Краен продукт
        </div>
        <div
          onClick={() => {
            onSetRequestType('Допълнителни')
            setSelectedTab('Допълнителни')
          }}
          className={selectedTab === 'Допълнителни' ? 'tab selected' : 'tab'}
        >
          Допълнителни
        </div>
      </div>
    </div>
  )
}
