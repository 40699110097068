import React from 'react';
import './styles.scss';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from "@makedonski/admin-ui";
import { showModal, checkUnfinishedWork, getMachines, startWorkMachineSchedule, getMachineSchedule, getSingleSchedule, checkUnfinishedWorks, getUnfinishedWorks } from "../../actions";
import { history } from "../../config/stores"
import { getProductName } from '../../utilities/helpers';
import moment from 'moment';
import { addMachineActivity } from '../../actions';

export default function MachineTabletView() {
    const machineData = useSelector(state => state.production.productSchedule);
    const machineWorks = useSelector(state => state.production.machineWorks);
    const machineScheduleWork = useSelector(state => state.production.machineScheduleWork);
    const scheduleRequestsTablet = useSelector(state => state.production.scheduleRequestsTablet);
    const schedule = useSelector(state => state.production.currentSchedule);
    // const currentActivityData = useSelector(state => state.production.currentSchedule);
    const machines = useSelector((state) => state.production.machines)
    const machineOptions = useSelector((state) => state.production.machines)

    const [ requestData, setRequestData ] = useState({});
    const [ currentActivityData, setCurrentActivityData ] = useState({});
    const [ isAdded, setIsAdded ] = useState(false);
    const [ isStarted, setIsStarted ] = useState(false);
    const [ firstStart, setFirstStart ] = useState(false);
    const [ tableData, setTableData ] = useState([]);
    const [ isEmpty, setIsEmpty ] = useState(true);
    const [ isActivityEmpty, setIsActivityEmpty ] = useState(true);
    const [ machineWorkId, setMachineWorkId ] = useState('');
    const [ selectedMachineTablet, setSelectedMachineTablet ] = useState('')
    const dispatch = useDispatch(); 
    const { id } = useParams();

    let selectedMachine = localStorage.getItem('machine');
    let isUpdated = selectedMachine === currentActivityData?.machine?._id

    const tableHeaderElements = [
        {name: 'Брой', size: 'small'}, 
        {name: 'Тегло', size: 'medium'}, 
        {name: 'Размер', size: 'small'}, 
        {name: 'Дата', size: 'medium'}, 
        {name: 'Час', size: 'medium'},
        {name: 'Брак', size: 'small'},
    ];

  useEffect(() => {
    dispatch(getMachines())
    dispatch(getMachineSchedule({ start: moment().subtract(20, 'days').startOf('day').startOf('hour'),  end: moment().endOf('day').endOf('hour')}))
    dispatch(getUnfinishedWorks({ machine: selectedMachine, _id: id }))
    dispatch(getMachines())
    setSelectedMachineTablet(selectedMachine)
    dispatch(getSingleSchedule({ _id: id }))
    isUpdated = selectedMachine === currentActivityData?.machine?._id
  }, [selectedMachine]);

  useEffect(() => {
    if (scheduleRequestsTablet) {
      setCurrentActivityData(scheduleRequestsTablet[0])
    }
  }, [scheduleRequestsTablet])

  useEffect(() => {
    if (!isUpdated) {
      setIsActivityEmpty(true)
      setIsEmpty(true)
      setIsStarted(false)
    }
  }, [isUpdated]);

  useEffect(() => {
    if (currentActivityData?.unfinishedWork?.length && machineWorkId) {
      setIsEmpty(false)
      setIsStarted(true)
      dispatch(getSingleSchedule({ _id: id }))
    }
    else if (!machineWorkId) { 
      // dispatch(getUnfinishedWorks({ machine: selectedMachine, _id: id }))
      setIsEmpty(false)
      setIsStarted(false)
      setIsActivityEmpty(true)
    }
  }, [selectedMachine, machineWorkId]);

  useEffect(() => {
      if (schedule?.work?.length  && schedule?._id === id) {
        setTableData(schedule.work[0].produce.reverse())
        setIsActivityEmpty(false)
        setIsStarted(true)
      }

      if (machineScheduleWork?._id || currentActivityData?.unfinishedWork?.length) {
        if (machineScheduleWork?._id && firstStart) {
          setMachineWorkId(machineScheduleWork._id)
          setFirstStart(false)
        }
        else if (currentActivityData?.unfinishedWork?.length && currentActivityData?._id === id) {
          setMachineWorkId(currentActivityData.unfinishedWork[0]._id)
        }
      }
    
    if (isAdded) {
      dispatch(getSingleSchedule({ _id: id }))
      setIsAdded(false)
    }

    if (currentActivityData?._id !== id) {
      dispatch(getSingleSchedule({ _id: id }))
    }
  }, [currentActivityData, machineWorks, machineScheduleWork, selectedMachine, schedule, currentActivityData]);

  useEffect(() => {
    if (isAdded) {
      dispatch(getSingleSchedule({ _id: id }))
      setIsAdded(false)
    }
  }, [tableData]);

  //machineScheduleWork.machineSchedule = id
    
  return (
    <div className='tablet-view-wrapper'>
        <div className="flex-container-machine">
            {machineOptions?.map(machine => {
              return (<div 
                key={machine._id} 
                className={`machine-tablet ${selectedMachine === machine._id ? 'selected' : ''}`}
                onClick={() => {
                  localStorage.setItem('machine', machine._id)
                  selectedMachine = localStorage.getItem('machine');
                  setSelectedMachineTablet(machine.name)
                  history.goBack()
                  }}>
                Машина {machine.name}
                </div>)
            })}
          </div>
        <div className='screen-caption-wrapper'>
            <h2>Работа по дейност</h2>
            <img src={require('../../assets/icons/schedule-request.svg')}
               onClick={() => currentActivityData?._id && history.push(`/machine-activity-view/${currentActivityData._id}`)} />
        </div>
        <div className='machine-top-wrapper'>
          <div className={`machine-data-wrapper margin ${isEmpty ? 'empty' : ''}`}>
           {isEmpty ? 
           <div className='data-element regular'>Няма предстояща дейност за деня</div> :
           <>
             <img src={require('../../assets/icons/swap-black.svg')} className='icon revert' onClick={() => history.push('/machine-requests-view')} />
             <div className='data-element bold'>{currentActivityData?.activity}</div>
             <div className='data-element bold'>{getProductName(currentActivityData?.product?.basicInfo)}</div>
             <div className='data-element'>Количество: <span>{`${currentActivityData?.quantity}  ${currentActivityData?.product?.mainUnit}`}</span></div>
             <div className='data-element'>Ден: <span>{moment(currentActivityData?.start).format('DD.MM.YYYY')}</span></div>
             <div className='data-element'>Начален час: <span>{moment(currentActivityData?.start).format('HH:mm')}</span></div>
             <div className='data-element'>Краен час: <span>{moment(currentActivityData?.end).format('HH:mm')}</span></div>
             <div className='data-element'>Времетраене: 
             {currentActivityData?.end && <span>{` ${(moment(currentActivityData?.end)).diff(moment(currentActivityData?.start), 'hours')}ч ${moment(currentActivityData?.end).diff(moment(currentActivityData?.start), 'minutes') % 60} мин`}</span>}
             </div>
           </>}
          </div>

          {isStarted ?
           <div className='buttons-wrapper'>
             <Button.Raised text="Изработен 1 брой" className="big" 
                            onClick={() => {
                            dispatch(showModal({
                              type: "addMachineActivityModal",
                              data: { 
                                machineScheduleWork: machineWorkId,
                                machineSchedule: id,
                                // machineScheduleWork: machineScheduleWork?._id,
                                // machineSchedule: currentActivityData?._id,
                                // renderTable() { dispatch(getSingleSchedule({ _id: machineScheduleWork.machineSchedule }))}
                                renderTable() { dispatch(getSingleSchedule({ _id: id }))}
                              },
                              overflow: "visible",
                            }))
                            setIsAdded(true)
                          }}></Button.Raised>

             <Button.Raised text="Край" className="red small" disabled={currentActivityData?.work[0] && currentActivityData?.work[0]?.produce?.length === 0}
                            onClick={() => {
                               dispatch(showModal({
                                 type: "endMachineActivityModal",
                                 data: { machineScheduleWork: machineWorkId, machineSchedule: id},
                                //  data: { machineScheduleWork: machineScheduleWork._id, machineSchedule: currentActivityData._id},
                                 overflow: "visible",
                               }))
                             }}></Button.Raised>
           </div> :
           <Button.Raised text="Старт работа" disabled={isEmpty} 
           onClick={() => {
            setIsStarted(true);
            setFirstStart(true)
            // if (machineScheduleWork) {
            //   dispatch(startWorkMachineSchedule({ machineSchedule: id }));
            //   // dispatch(startWorkMachineSchedule({ machineSchedule: machineScheduleWork.machineSchedule }));
            // }
            // else {
            //   // dispatch(startWorkMachineSchedule({ machineSchedule: requestData._id }));
            //   dispatch(startWorkMachineSchedule({ machineSchedule: id }));
            // }

            dispatch(startWorkMachineSchedule({ machineSchedule: id }));
            dispatch(getSingleSchedule({ _id: id }))
            }}></Button.Raised>}
          </div>

          <div className={`machine-bottom-wrapper ${isEmpty ? 'hidden' : ''}`}>
            <h4 className='title'>История на дейност</h4>
            
            <div className={`machine-data-wrapper large ${isActivityEmpty ? 'empty' : ''}`}>
                {isActivityEmpty ? 
                <div className='data-element regular'>Няма налична дейност</div> :
                <div className='table-wrapper'>
                    <div className='table-header-wrapper'>
                        {tableHeaderElements.map((el, i) => {
                            return (
                                <div className={`element bold ${el.size}`} key={`element-table-tablet-view-${i}`}>
                                  <div>{el.name}</div>
                                  {/* <img src={require('../../assets/icons/arrow-table-down.svg')} /> */}
                                </div>)
                        })}
                    </div>

                    <div className='table-content-wrapper'>
                        {tableData?.map((el, i) => {
                            return (
                                <div className={`element-wrapper ${el?.defective && 'selected'}`} key={el?._id}>
                                  <div className='element small border'><span>{tableData?.length - i}</span> / {currentActivityData?.quantity}</div>
                                  <div className='element medium border'>{el?.weight} кг</div>
                                  <div className='element small border'>{el?.length} м</div>
                                  <div className='element medium border'>{moment(el?.date).format('DD.MM.YYYY')}</div>
                                  <div className='element medium'>{moment(el?.date).format('HH:mm')}</div>
                                  <div className='element small'>
                                  <div
                                    className={`checkbox-container ${el?.defective && 'selected'}`}
                                    onClick={() => {
                                      // if (data?.length === chosen?.length) {
                                      //   setChosen([]);
                                      // } else {
                                      //   setChosen(data)
                                      // }

                                      dispatch(showModal({
                                        type: "addProductScrappingModal",
                                        data: { 
                                          _id: el._id,
                                          scheduleId: id,
                                          isDefective: el?.defective || false,
                                          renderTable() { 
                                            setIsAdded(true)
                                            dispatch(getSingleSchedule({ _id: id }))
                                          }
                                        },
                                        overflow: "visible",
                                      }))
                                    }}
                                    >
                                    {el?.defective && <div className='checkbox' />}
                                  </div>
                                  </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}
            </div>
          </div>

    </div>
  )
}
