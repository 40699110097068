import React, { useEffect, useRef, useState } from "react"
import moment from "moment"
import "./styles.scss"

const mapStatus = {
  new: { text: "Нова заявка", color: "#F7E05D" },
  ordered: { text: "Направена поръчка", color: "#005EA3" },
  "in-transit": { text: "В транзит - очаква се", color: "#6041ED" },
  delivered: { text: "Доставена", color: "#26ACE2" },
  accrued: { text: "Заприходена поръчка", color: "#25E083" },
  canceled: { text: "Отказана", color: "#FF7590" },
  exported: { text: "Изписана суровина", color: "#E6EEF4" },
}

const StorageReadyProduct = ({
  checked,
  onSelect,
  onOpenDocuments,
  onOpenHistory,
  data,
  onOpenPopup,
}) => {
  return (
    <div
      className={`storage-ready-product-container ${checked && "checked"} 
      ${data.quantity < data.criticalQuantity && "red-border"}`}
    >
      <div className="element medium bold">{data.product}</div>
      <div className="element medium">
        {data.provider ? data.provider.firmName : "- -"}
      </div>
      <div className="element medium">
        {data.quantity2 === 0 ? "Централен склад" : "Склад Изток"}
      </div>
      <div className="element small">{data.productCode}</div>
      <div className="element small">
        {data.createdAt ? moment(data.createdAt).format("DD.MM.YYYY") : "- -"}
      </div>
      <div className="element small">
        {data.deliveryDate ? moment(data.deliveryDate).format("DD.MM.YYYY") : "- -"}
      </div>
      <div className="element medium-small">
        {data.deliveredAt
          ? moment(data.deliveredAt).format("DD.MM.YYYY")
          : "- -"}
      </div>
      <div className="element medium-small">
        {data.quantity} {data.units}
      </div>
      <div className="element medium-small">- -</div>
      <div className="element small">
        {data.price ? `${data.price.toFixed(2)}лв.` : "- -"}
      </div>
      <div className="element small">
        {(data.payments.length &&
          `На ${data.payments.length} ${
            data.payments.length < 2 ? "Вноска" : "Вноски"
          }`) ||
          "- -"}
      </div>
      <div className="element large">
        <div
          className="status-container"
          style={{ borderLeft: `solid 4px ${mapStatus[data.status].color}` }}
        >
          <p>{mapStatus[data.status].text}</p>
        </div>
        <div className="buttons">
        {data.status !== "new" && (
            <div className="icon edit" onClick={onOpenPopup} />
          )}
        <div className="icon history" onClick={onOpenHistory} />
        <div className="icon documents" onClick={onOpenDocuments} />
        <div
          className={`light-checkbox ${checked && "checked"}`}
          onClick={onSelect}
        >
          {checked && <div className="checkmark" />}
        </div>
        </div>
      </div>
    </div>
  )
}

export default StorageReadyProduct
