import React, { useEffect, useState, useRef } from "react"
import { Button as ButtonAdminUi, Input } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import moment from "moment"
import { TableHeaderInquiry, InquiryClientElement } from "../../components"
import {
  showModal,
  showLoading,
  getReferences,
  getClients,
  resetClients,
  searchInquiryClients,
  filterInquiryDatesClients,
} from "../../actions"
import _ from "lodash"
import "./styles.scss"
import "./InquiriesHeader.scss"
import InquiriesHeader from "./InquiriesHeader"
import { useSelector, useDispatch } from "react-redux"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

const headerElements = [
  {
    name: "Име на клиент",
    size: 2,
    fixedWidth: 120,
    type: 'name',
    filter: true,
    sort: false
  },
  {
    name: "Проекти",
    size: 1,
    fixedWidth: 90
  },
  {
    name: "Клиент от",
    size: 1,
    fixedWidth: 90,
    filter: true,
    sort: false
  },
  {
    name: "Неплатени фактури",
    size: 2,
    fixedWidth: 120
  },
  {
    name: "ЕИК",
    size: 2,
    fixedWidth: 120,
    filter: true,
    sort: false
  },

  {
    name: "Телефон",
    size: 1,
    fixedWidth: 90,
    filter: true,
    sort: false
  },
  {
    name: "Имейл",
    size: 2,
    fixedWidth: 120,
    filter: true,
    sort: false
  },
  {
    name: "Адрес",
    size: 3.5,
    fixedWidth: 200,
    type: 'address',
    filter: true,
    sort: false
  },
]

const InquiriesClientsComponent = ({
  showModal,
  showLoading,
  getReferences,
  clients,
  getClients,
  nextPage,
  pageNumber,
  resetClients,
}) => {
  const [filter, setFilter] = useState("")
  const [sortTableHeader, setSortTableHeader] = useState(false);
  const [sort, setSort] = useState(true)
  const [selectedSortValue, setSelectedSortValue] = useState('')
  const [searchQuery, setSearchQuery] = useState("")
  const [searchValue, setSearchValue] = useState("name")
  const [searchDateValue, setSearchDateValue] = useState("createdAt")
  const [startDate, setStartDate] = useState(moment().toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const [clientsTab, setClientsTab] = useState("clients")
  const clientsInnerRef = useRef()
  const dispatch = useDispatch()

  const filterOptions = {
    'name': 'Име на клиент',
    'phone': 'Телефон',
    'email': 'Имейл',
    'address': 'Адрес',
    'uic': 'ЕИК'
  }
  // const filterOptions = [
  //   { label: "Име на клиент", value: "name" },
  //   { label: "Телефон", value: "phone" },
  //   { label: "Имейл", value: "email" },
  //   { label: "Адрес", value: "address" },
  //   { label: "ЕИК", value: "uic" },
  // ]

  const dateFilterOptions = [
    { label: "Дата на създаване", value: "createdAt" },
    { label: "Последна редакция", value: "updatedAt" },
  ]

  const selectedSortValues = {
    'Име на клиент': 'name',
    'Клиент от': 'createdAt',
    'ЕИК': 'uic',
    'Телефон': 'phone',
    'Имейл': 'email',
    'Адрес': 'address'
  }

  const handleScrolling = () => {
    if (clientsInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = clientsInnerRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && nextPage && scrollTop !== 0) {
        loadPageOnScroll()
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  const loadPageOnScroll = () => {
      let payloadObj = {pageNumber: nextPage, pageSize: 20}
      if (searchQuery) {
        payloadObj[searchValue] = searchQuery
      }
      if (areDatesSelected) {
        payloadObj.field = searchDateValue
        payloadObj.from = moment(startDate).startOf("day")
        payloadObj.to = moment(endDate).endOf("day")
      }
      if (selectedSortValues[selectedSortValue]) {
        let sortType = selectedSortValues[selectedSortValue]
        payloadObj.sortBy = { [sortType]: sort ? 1 : -1 }
      }

      showLoading()
      dispatch(searchInquiryClients(payloadObj))
  }
  
  const handleSearch = () => {
    clientsInnerRef.current && clientsInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    let searchPayload = { [searchValue]: searchQuery, pageNumber: 1, pageSize: 20 }

    if (areDatesSelected) {
      searchPayload.field = searchDateValue
      searchPayload.from = moment(startDate).startOf("day")
      searchPayload.to = moment(endDate).endOf("day")
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue]
      searchPayload.sortBy = { [sortType]: sort ? 1 : -1 }
    }

    showLoading()
    dispatch(searchInquiryClients(searchPayload));
  }

  const handleDatesFilter = () => {
    if (moment(startDate).startOf("day").isAfter(moment(endDate).startOf("day"))) {
      return Alerts.error("Зададената начална дата на търсене е след крайната дата!")
    }

    clientsInnerRef.current && clientsInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    let searchPayload = { field: searchDateValue, from: moment(startDate).startOf("day"), to: moment(endDate).endOf("day"), pageNumber: 1, pageSize: 20 }
    setAreDatesSelected(true);
    if (searchQuery) {
      searchPayload[searchValue] = searchQuery
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue]
      searchPayload.sortBy = { [sortType]: sort ? 1 : -1 }
    }

    showLoading()
    dispatch(filterInquiryDatesClients(searchPayload))
  }

  const handleSort = (type, sortValue) => {
    let sortType = selectedSortValues[type];
    let payloadSort = { 
      sortBy: { [sortType]: sortValue ? 1 : -1 },
      pageNumber: 1,
      pageSize: 20
    };

    if (searchQuery) {
      payloadSort[searchValue] = searchQuery;
    }
    if (areDatesSelected) {
      payloadSort.from = moment(startDate).startOf("day");
      payloadSort.to = moment(endDate).endOf("day");
      payloadSort.field = searchDateValue;
    }

    clientsInnerRef.current && clientsInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    setSort(sortValue)
    setSelectedSortValue(type)
    showLoading()
    dispatch(getClients(payloadSort))
  }

  useEffect(() => {
    showLoading()
    setClientsTab("clients")
    getClients({ pageNumber: 1, pageSize: 20 })
    // return setClientsTab('')
  }, [])

  // useEffect(() => {
  //   clientsInnerRef.current && clientsInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
  // }, [areDatesSelected, searchQuery, isSortActive])

  return (
    <div className="inquiries-clients-component">
      <InquiriesHeader
        headerElements={headerElements}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={filterOptions}
        startDate={startDate}
        endDate={endDate}
        tab={clientsTab}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        dateFilterOptions={dateFilterOptions}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        searchDateValue={searchDateValue}
        setSearchDateValue={setSearchDateValue}
        handleDatesFilter={handleDatesFilter}
        areDatesSelected={areDatesSelected}
        setAreDatesSelected={setAreDatesSelected}
        selectedSortValue={selectedSortValue}
        setSelectedSortValue={setSelectedSortValue}
        sort={sort}
        setSort={setSort}
      />

      <TableHeaderInquiry
        elements={headerElements}
        handleSort={handleSort}
        justifyContent="flex-start"
        selectedSortValue={selectedSortValue}
      />
      <div
        className="scroll-container"
        ref={clientsInnerRef}
        onScroll={delayedScrolling}
      >
        {clients.map((client, i) => {
          return (
            <InquiryClientElement
              data={client}
              key={client._id + i}
              onEdit={() =>
                showModal({
                  type: "addClient",
                  overflow: "visible",
                  width: "60%",
                  data: { type: "client", forEdit: true, client: client },
                })
              }
            />
          ) 
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  clients: state.references.clients,
  pageNumber: state.references.pageNumber,
  nextPage: state.references.nextPage
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  getReferences: (payload) => dispatch(getReferences(payload)),
  getClients: (payload) => dispatch(getClients(payload)),
  showLoading: () => dispatch(showLoading()),
  resetClients: () => dispatch(resetClients()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InquiriesClientsComponent)
