import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input } from '@makedonski/admin-ui'
import moment from 'moment'
import { hideModal, getColleagues, showModal } from '../../../actions'
import './styles.scss'

const iconsMap = {
  Working: '#17F607',
  Away: '#FFFF00',
  Offline: '#FF0000'
}

const workingTypeMap = {
  inOffice: 'В офиса',
  inMeeting: 'В среща',
  onSite: 'На обект',
  fromHome: 'От вкъщи',
  feelingSick: 'Болничен',
  vacation: 'Отпуск'
}

class ColleaguesModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchQuery: ''
    }
  }

  componentDidMount () {
    this.props.getColleagues({ name: '' })
  }

  onScroll (event) {
    const scrolledTo = event.target.scrollHeight - event.target.scrollTop
    const endOfScroll = event.target.clientHeight
    if (scrolledTo === endOfScroll && this.props.nextPage) {
      this.props.getColleagues({
        page: this.props.nextPage,
        limit: 10
      })
    }
  }

  render () {
    return (
      <div className='colleagues-container'>
        <div className='top-container'>
          <div className='title-container'>
            <h2> Всички колеги </h2>
            <Input.Text
              placeholder='Търси по име'
              value={this.state.searchQuery}
              onChange={(value) => {
                this.setState({
                  searchQuery: value.target.value
                })
              }}
            />
          </div>
          <div className='exit-btn' onClick={() => this.props.hideModal()} />
        </div>
        <div className='scroll-container'>
          <div className='content-container'>
            {this.props.colleagues.map((colleague, i) => {
              if (
                colleague.fullName
                  .toLowerCase()
                  .includes(this.state.searchQuery.toLowerCase())
              ) {
                return (
                  <div className='item' key={i}>
                    <div className='profile-info'>
                      <div className='profile-image-container'>
                        <div
                          className='profile-image ripple'
                          style={{
                            backgroundImage: `url(${
                              colleague.coverPhoto ||
                              global.config.defaultAvatar
                            })`
                          }}
                        />
                        <div className='profile-calendar-container'>
                          <div
                            className='profile-calendar'
                            style={{
                              backgroundColor: `${iconsMap[colleague.status]}`
                            }}
                          />
                        </div>
                      </div>
                      <div className='profile-name'>
                        <p>{colleague.fullName}</p>
                        <p id='small'>{colleague.occupation}</p>
                        <div id='smaller'>
                          {workingTypeMap[colleague.workingType]}{' '}
                          <div
                            className='working-icon'
                            style={{
                              backgroundImage: `url(${
                                iconsMap[colleague.workingType]
                              })`
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='contact-info'>
                      <div className='phone-container'>
                        <Button.Icon
                          name='phone'
                          size={16}
                          color='blue'
                          className=''
                          iconStyles=''
                          onClick={() => console.log()}
                        />
                        <p>{colleague.phone || 'Не е въведен'}</p>
                      </div>
                      <div className='email-container'>
                        <Button.Icon
                          name='mail'
                          size={16}
                          color='blue'
                          className=''
                          iconStyles=''
                          onClick={() => console.log()}
                        />
                        <p>{colleague.email}</p>
                      </div>
                    </div>
                    <div className='personal-info-container'>
                      <div className='birthday-container'>
                        <Button.Icon
                          name='birthday'
                          size={16}
                          color='blue'
                          className=''
                          iconStyles=''
                          onClick={() => console.log()}
                        />
                        <p>{moment(colleague.birthday).format('DD/MM/YYYY')}</p>
                      </div>
                      <div className='scroll-container'>
                        {colleague.description
                          ? (
                            <p id='description'>{colleague.description}</p>
                            )
                          : (
                            <p id='description'>Няма описание</p>
                            )}
                      </div>
                    </div>
                    {/* <div
                      className="activity-link-container"
                      onClick={() => {
                        // this.props.showModal({
                        //   type: 'profileEditModal',
                        //   data: { user: colleague, readOnly: true },
                        //   width: '50%',
                        // })
                      }}
                    >
                      {"Активност >"}
                    </div> */}
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  colleagues: state.user.data,
  nextPage: state.user.nextPage
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: (payload) => dispatch(hideModal(payload)),
  getColleagues: (payload) => dispatch(getColleagues(payload)),
  showModal: (payload) => dispatch(showModal(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ColleaguesModal)
