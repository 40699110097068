import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts, Auth } from "@makedonski/socourt-utilities"
import {
  AuthTypes,
  signInSuccess,
  UserTypes,
  StarterTypes,
  updateProfileSuccess,
  GeneralTypes,
  hideModal,
} from "../actions"
import { API_URL } from "../config/settings"

export const signInEpic = (action$) => {
  return action$.pipe(
    ofType(AuthTypes.SIGN_IN),
    switchMap(({ payload }) => {
      console.log("signInEpic payload", payload)
      return Api.post(`${API_URL}/auth/login`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            Auth.authenticate(response.token, { ...response.user }).then(() => {
              obs.next({
                type: UserTypes.GET_CURRENT_USER_SUCCESS,
                payload: response.user,
              })
              obs.next(signInSuccess())
              if (window.innerWidth < 950) window.location.href = "/contacts"
              else window.location.href = "/"
              obs.complete()
            })
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: StarterTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
export const updateProfileEpic = (action$) => {
  return action$.pipe(
    ofType(AuthTypes.UPDATE_PROFILE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/user`,
        JSON.stringify({ ...payload, _id: Auth.getUser()._id })
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            Auth.authenticate(Auth.getToken(), { ...response.user }).then(
              () => {
                // const { href } = window.location;
                // window.location.href = href;
                obs.next(updateProfileSuccess(response.user))
                obs.next(hideModal())
                obs.complete()
              }
            )
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateUserEpic = (action$) => {
  return action$.pipe(
    ofType(AuthTypes.UPDATE_USER),
    switchMap(({ payload: { data, isCurrentUser } }) => {
      return Api.put(`${API_URL}/user`, JSON.stringify(data)).pipe(
        // return Api.put(`${API_URL}/user`, JSON.stringify(typeof data === 'string' ? { _id: localStorage.getItem('userId') } : data)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            Auth.authenticate(Auth.getToken(), { ...response.user }).then(
              () => {
                obs.complete()
              }
            )
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
