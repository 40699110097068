export const AuthTypes = {
  SIGN_IN: "auth/SIGN_IN",
  SIGN_IN_SUCCESS: "auth/SIGN_IN_SUCCESS",
  UPDATE_PROFILE: "auth/UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "auth/UPDATE_PROFILE_SUCCESS",
  UPDATE_USER: "auth/UPDATE_USER",
  UPDATE_USER_SUCCESS: "auth/UPDATE_USER_SUCCESS",
}

export const signIn = (payload) => ({
  type: AuthTypes.SIGN_IN,
  payload,
})

export const signInSuccess = (payload) => ({
  type: AuthTypes.SIGN_IN_SUCCESS,
  payload,
})
export const updateProfile = (payload) => ({
  type: AuthTypes.UPDATE_PROFILE,
  payload,
})
export const updateUser = (payload) => ({
  type: AuthTypes.UPDATE_USER,
  payload,
})
export const updateUserSuccess = (payload) => ({
  type: AuthTypes.UPDATE_USER_SUCCESS,
  payload,
})
export const updateProfileSuccess = (payload) => ({
  type: AuthTypes.UPDATE_PROFILE_SUCCESS,
  payload,
})
