import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import moment from "moment"
import Files from "../../../utilities/files"
import { updateProfile, hideModal } from "../../../actions"
import "./styles.scss"

function ProfileInfo({ currentUser, updateProfile, hideModal }) {
  const [readOnly, setReadOnly] = useState(true)
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [birthday, setBirthDay] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [occupation, setOccupation] = useState("")
  const [description, setDescription] = useState("")
  const [coverPhoto, setCoverPhoto] = useState("")
  useEffect(() => {
    setName(currentUser.firstName)
    setLastName(currentUser.lastName)
    setBirthDay(moment(currentUser.birthday).toDate())
    setPhoneNumber(currentUser.phone)
    setEmail(currentUser.email)
    setOccupation(currentUser.occupation)
    setDescription(currentUser.description)
    setCoverPhoto(currentUser.coverPhoto || global.config.defaultAvatar)
  }, [currentUser])
  return (
    <div className="profile-info-container">
      <div className="top-container">
        <h2>Личен профил</h2>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <div className="profile-content-container">
        <div className="left-content-container">
          <div
            className="image"
            style={{
              backgroundImage: `url(${coverPhoto})`,
              backgroundSize: "cover",
            }}
          />
          <Button.UploadButton
            text="Промени"
            disabled={readOnly}
            onChange={(event) => {
              event.persist()
              Files.uploadFile(event.target.files[0]).then((data) => {
                setCoverPhoto(data.location)
              })
            }}
          />
        </div>
        <div className="right-content-container">
          <div className="name-container">
            <Input.Text
              placeholder="Име"
              value={name}
              disabled={readOnly}
              onChange={(event) => setName(event.target.value)}
            />
            <Input.Text
              placeholder="Фамилия"
              value={lastName}
              disabled={readOnly}
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
          <Input.Datepicker
            pickDate={birthday}
            placeholder="ДД/ММ/ГГГГ"
            disabled={readOnly}
            minDate={moment().subtract(100, "year").toDate()}
            onChange={(date) => setBirthDay(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <Input.Text
            placeholder="Телефон"
            value={phoneNumber}
            disabled={readOnly}
            onChange={(event) => setPhoneNumber(event.target.value)}
          />
          <Input.Text
            placeholder="И-мейл"
            value={email}
            disabled
            onChange={(event) => setEmail(event.target.value)}
          />
          <Input.Text
            placeholder="Работна позиция"
            value={occupation}
            disabled={readOnly}
            onChange={(event) => setOccupation(event.target.value)}
          />
          <Input.Textarea
            placeholder="Описание"
            value={description}
            disabled={readOnly}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
      </div>
      <div className="bottom-container">
        <Button.Raised
          text={readOnly ? "Промени" : "Запази промените"}
          onClick={() => {
            if (readOnly) {
              setReadOnly(false)
            } else {
              updateProfile({
                _id: currentUser._id,
                firstName: name,
                lastName,
                coverPhoto,
                phone: phoneNumber,
                occupation,
                description,
                birthday,
              })
              setReadOnly(true)
            }
          }}
        />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (payload) => dispatch(updateProfile(payload)),
  hideModal: () => dispatch(hideModal()),
})

export default connect(null, mapDispatchToProps)(ProfileInfo)
