import { ProductTypes } from '../actions'

const initialState = {
  types: [],
  data: [],
  current: null,
  found: null,
  more: [],
  totalProducts: []
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case ProductTypes.GET_ALL_PRODUCTS_SUCCESS:
      return { ...state, totalProducts: action.payload }
    case ProductTypes.GET_PRODUCT_TYPES_SUCCESS:
      return { ...state, types: action.payload }
    case ProductTypes.UPDATE_PRODUCT_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.GET_PRODUCT_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.CREATE_PRODUCT_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.UPDATE_EXPENSE_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.UPDATE_PRODUCTION_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.EDIT_EXPENSE_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.EDIT_PRODUCTION_SUCCESS:
      return { ...state, current: action.payload }
    case ProductTypes.SEARCH_PRODUCT_SUCCESS:
      return { ...state, found: action.payload }
    case ProductTypes.SEARCH_PRODUCT_MORE_THAN_ONE_SUCCESS:
      return { ...state, more: action.payload }
    case ProductTypes.CLEAR_FOUND:
      return { ...state, found: null }
    case ProductTypes.CLEAR_RESULTS:
      return { ...state, found: null, more: [] }
    default:
      return state
  }
}

export default products
