import React from "react"
import { Inputs } from "../../Inputs"

const OptionsModal = ({ handleClose, options }) => {
  return (
    <div className="flex justify-center items-center" style={{ height: "40%", width: "98%" }}>
      <div className="delete-modal-container p-8" style={{ width: "60%" }}>
        <div className="flex w-full items-center justify-between">
          <p
            style={{ marginTop: "0px", marginBottom: "0px", fontSize: "15px" }}
          >
            Моля изберете
          </p>
          <img
            src={require("../../../assets/icons/closeIcon.svg")}
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </div>
        <div
          className="flex column w-full justify-center"
          style={{ marginTop: "20px", gap: "15px" }}
        >
          {options?.map((opt) => (
            <Inputs.Button
              text={opt?.label}
              className={"mobile-button"}
              selected
              onClick={() => opt?.callback()}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OptionsModal
