import React, { useState, useEffect, useRef } from "react"
import { Input, Button, Section } from "@makedonski/admin-ui"
import { addOtherAction, getPerformers } from "../../actions"
import { history } from "../../config/stores"
import { useSelector, useDispatch } from "react-redux"
import { CheckListPopup } from "../../components/CheckListPopup"
import "./styles.scss"
import moment from "moment"
import { activityCategoryList, activitiesList } from "../../config/constants"
// import { Button } from '@mui/material'

export default function ProductionAddRequest() {
  const plusIcon = require("../../assets/icons/light-plus-icon.svg");
  let executorsTab = useRef(null);
  const dispatch = useDispatch();
  const performers = useSelector((state) => state.production.performers)

  const [activityCategory, setActivityCategory] = useState(null)
  const [activityQuery, setActivityQuery] = useState('')
  const [ payload, setPayload ] = useState(
    {
    activity: '',
    description: '',
    comment: '',
    start: null,
    end: null,
    performers: []
  })

  useEffect(() => {
    dispatch(getPerformers())
  }, []);
    
  return (
    <div className="add-request-wrapper">
      <div className="add-request-container">
        <div className="add-request-top">
          <h2>Нова заявка</h2>
          <div className="buttons-wrapper">
            <button
            onClick={() => history.goBack()}>Откажи</button>
            <button
            disabled={!payload.start || !payload.end || !payload.activity || !payload.performers}
            onClick={() => {
              dispatch(addOtherAction(payload))
              history.goBack()
              }}>Добави</button>
          </div>
        </div>
        <div className="add-request-main">
          <div className="add-request-main-inputs-top">

            {/* <div className="request-type-section-wrapper">
              <label>Заявка от</label>
              <div className="labels-wrapper">
                <div className="single-option">
                  <input type="radio" name='request-type'></input>
                  <label htmlFor='request-type'>Продукт (Тръба / Краен продукт)</label>
                </div>
                <div className="single-option">
                  <input type="radio" name='request-type'></input>
                  <label htmlFor='request-type'>Друга дейност</label>
                </div>
              </div>
            </div>   */}

            <div className="row top">
               <div className="activity-section-wrapper">
                 <label htmlFor="activity-input">Дейност</label>
                 <Input.Text value={payload.activity} id="activity-input"
                 onChange={(e) => {
                   setPayload({...payload, activity: e.target.value})
                 }} />

                 {/* <div className="dropdowns-wrapper">
                    <Input.Dropdown
                        className='activity-dropdown'
                        options={Object.keys(activityCategoryList)}
                        value={activityQuery}
                        placeholder=''
                        onChange={(e) => {
                          setActivityCategory(activityCategoryList[e.value])
                          setActivityQuery(e.value)
                          setPayload({...payload, activity: ''})
                        }}
                        />
   
                      <Input.Dropdown
                        disabled={!activityQuery}
                        className='activity-dropdown'
                        options={activitiesList[activityCategory]}
                        value={payload.activity}
                        placeholder=''
                        onChange={(e) => {
                         setPayload({...payload, activity: e.value})
                        }}
                        />
                  </div> */}
               </div>
   
               <div className="description-section-wrapper">
                 <label htmlFor="description-input">Описание</label>
                 <Input.Text value={payload.description} id="description-input"
                  onChange={(e) => {
                   setPayload({...payload, description: e.target.value})
                 }} />
               </div>
   
               <div className="comment-section-wrapper">
                 <label htmlFor="comment-input">Коментар</label>
                 <Input.Text value={payload.comment} id="comment-input"
                  onChange={(e) => {
                   setPayload({...payload, comment: e.target.value})
                 }} />
               </div>
            </div>
        </div>
        
        <div className="add-request-main-inputs-bottom">
          <div className="start-date-section-wrapper">
                <label>Начало</label>
                <Input.Datepicker
                placeholder=''
                minDate={moment().toDate()}
                pickDate={payload.start}
                onChange={(e) => {
                  setPayload({...payload, start: e,
                    end: (moment(e).startOf('minute').isSame(moment(payload.end).startOf('minute'))
                    || moment(e).isAfter(moment(payload.end)))
                    ? null : payload.end})
                }} />
            </div>

            <div className="start-hour-section-wrapper">
                <label>Начален час</label>
                <Input.TimePicker
                 pickDate={payload.start}
                 disabled={!payload.start}
                 minDate={moment().startOf('minute')}
                 placeholder=''
                 timeIntervals={30}
                 onChange={(e) => {
                   setPayload({...payload, start: e })
             }}/>
            </div>
            
            <div className="end-date-section-wrapper">
                <label>Крайна дата</label>
                <Input.Datepicker
                 placeholder=''
                 disabled={!payload?.start}
                 minDate={payload?.start}
                 pickDate={payload?.start ? payload.end : null}
                 onChange={(e) => {
                   setPayload({...payload, end: e})
                 }}  />
            </div>

            <div className="end-hour-section-wrapper">
                <label>Краен час</label>
                <Input.TimePicker
                pickDate={payload?.start && (moment(payload?.end).startOf('minute')).isAfter(moment(payload?.start).startOf('minute')) ? payload?.end : null}
                disabled={!payload.end}
                // minDate={moment(payload?.start).startOf('minute').toDate()}
                placeholder=''
                timeIntervals={30}
                onChange={(e) => {
                  setPayload({...payload, end: e })
                  // setPayload({...payload, end: moment(payload.end).startOf('day').add(moment(e).minutes(), 'minutes').toDate() })
                }}
                />
            </div>
            
            <div className="contractors-section-wrapper">
            <label htmlFor="contractor">Изпълнители</label>

            <Section.Popup
            style={{width: 25}}
              ref={(ref) => {
                executorsTab = ref
              }}
              content={
                <CheckListPopup
                  data={performers}
                  handleCheckList={(list) => setPayload({...payload, performers: list})}
                  onClose={() => executorsTab.hide()}
                  startDate={payload?.start}
                  endDate={payload?.end}
                />
              }
            >
              <img
                src={plusIcon}
                alt="+"
                onClick={() => executorsTab.show()}
              ></img>
            </Section.Popup>

            {payload.performers &&
                  <div className='selected-performers-wrapper'>
                    {payload.performers.map(performer => {
                      return (<p>{performer}</p>)
                    })}
                  </div>}
          </div>

          </div>
        </div>
      </div>
    </div>
  )
}
