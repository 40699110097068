import { ofType, ActionsObservable } from 'redux-observable'
import { map, mergeMap, catchError, switchMap } from "rxjs/operators"
import { Api, Alerts, Auth } from '@makedonski/socourt-utilities'
import { SocketTypes } from '../actions'
import moment from 'moment'
import { API_URL } from '../config/settings'
import { fromEvent } from "rxjs"
import { history } from '../config/stores'
import io from "socket.io-client"

let socket = null

export const connectSocket = (action$) =>
  action$.pipe(
    ofType(SocketTypes.SOCKET_CONNECT),
    map(({ payload }) => {
      socket = io(API_URL, {
        query: `token=${Auth.getToken()}&session=${payload}&isWeb=true`,
      })
      return { type: SocketTypes.SOCKET_CONNECTED }
    })
  )

export const socketListener = (action$) =>
  action$.pipe(
    ofType(SocketTypes.SOCKET_CONNECTED),
    mergeMap(() =>
      fromEvent(socket, "socket-message").pipe(
        map(({ action, payload }) => {
          return { type: action, payload }
        }),
        catchError((err) =>
          Alerts.error("Грешка!", err?.response?.error?.message || "")
        )
      )
    )
  )

export const disconnectSocket = (action$) =>
  action$.pipe(
    ofType(SocketTypes.SOCKET_DISCONNECT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        if (socket) socket.disconnect()
        obs.complete()
      })
    )
)

// export const sessionEnded = (action$) =>
//   action$.pipe(
//     ofType("socket/END_SESSION"),
//     switchMap(({ payload }) =>
//       ActionsObservable.create((obs) => {
//         obs.next({
//           type: sessionsTypes.GET_SESSION_SUCCESS,
//           payload: payload.session,
//         })

//         obs.complete()
//       })
//     )
// )