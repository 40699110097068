import React, { useEffect, useRef, useState } from "react"
import { Inputs } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import { validations, defaultValues } from "./contactValidations"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { history } from "../../../config/stores"
import { getRawImageData, getVision } from "../../../utilities/helpers"
import {
  addContact,
  deleteContact,
  editContact,
  getCurrentContact,
  getCurrentUser,
  hideLoading,
  showLoading,
} from "../../../actions"
import ContactForm from "../../../components/ContactComponents/ContactForm"
import "../styles.scss"
import moment from "moment"
import { Cropper } from "react-mobile-cropper"
import "reactjs-popup/dist/index.css"
import "react-mobile-cropper/dist/style.css"

var steps = [
  { label: "Огради имената", field: "name", completed: false, opened: true },
  {
    label: "Огради позицията",
    field: "position",
    completed: false,
    opened: false,
  },
  {
    label: "Огради компанията",
    field: "companyName",
    completed: false,
    opened: false,
  },
  { label: "Огради имейл", field: "email", completed: false, opened: false },
  {
    label: "Огради телефонен номер",
    field: "phoneNumber",
    completed: false,
    opened: false,
  },
]

const EditContactFromImageMobile = () => {
  const contactId = window.location.href.split("/")[5]
  const dispatch = useDispatch()
  const croppedImage =
    useSelector(({ contacts }) => contacts?.croppedData) || null
  const currentUser = useSelector((state) => state.user.currentUser)
  const canEditDelete = currentUser?._id === "5fa2a91bf689c74baedd3a6b"

  const [cropScreen, setCropScreen] = useState({ open: true, step: 0 })
  const [data, setData] = useState(false)
  const cropperRef = useRef()

  useEffect(() => {
    dispatch(getCurrentUser())
    dispatch(
      getCurrentContact({
        _id: contactId,
        onSuccess: (res) => {
          setValue("name", res?.name)
          setValue("position", res?.position)
          setValue("companyName", res?.companyName)
          setValue("phoneNumber", res?.phoneNumber)
          setValue("email", res?.email)
          setValue("country", res?.country)
          setValue("contactFrom", res?.contactFrom)
          setValue("files", res?.files)
          setValue("mainComment", res?.mainComment)
          setValue("comments", res?.comments)
          setValue(
            "forProducts",
            res?.forProducts?.map((prod) => ({ label: prod, value: prod }))
          )
        },
      })
    )
  }, [])

  useEffect(() => {
    if (!croppedImage) setCropScreen({ open: false, step: null })
    else setCropScreen({ open: true, step: 0 })
  }, [croppedImage])

  const handleCrop = () => {
    const cropper = cropperRef.current
    if (cropper) {
      const croppedCanvas = cropper.getCanvas()
      if (croppedCanvas) {
        dispatch(showLoading())
        const base64ImageData = croppedCanvas.toDataURL("image/jpeg")
        handleNextStep(base64ImageData)
        setData(false)
      }
    }
  }

  const handleSkipCrop = () => {
    handleNextStep(null)
    setData(false)
  }

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validations),
    mode: "onSubmit",
    defaultValues: defaultValues,
  })
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = methods

  const handleNextStep = async (res) => {
    if (res) {
      const result = await getVision(getRawImageData(res)).then((res) => {
        const currentStep = steps[cropScreen.step]
        if (!watch("files").length)
          setValue("files", [
            {
              url: croppedImage?.url,
              name: croppedImage?.url?.split("/")?.[4],
              createdAt: moment().toISOString(),
              from: currentUser?.fullName,
            },
          ])
        if (res?.responses?.[0]?.fullTextAnnotation?.text) {
          setValue(
            `${currentStep.field}`,
            res?.responses?.[0]?.fullTextAnnotation?.text
          )
          if (cropScreen.step === 4) setCropScreen({ open: false, step: null })
          else {
            setCropScreen((prevState) => ({
              ...prevState,
              step: prevState.step + 1,
            }))
            steps[cropScreen.step].completed = true
            steps[cropScreen.step].opened = false

            steps[cropScreen.step + 1].opened = true
          }
        }
        dispatch(hideLoading())
      })
    } else {
      if (cropScreen.step === 4) setCropScreen({ open: false, step: null })
      else {
        setCropScreen((prevState) => ({
          ...prevState,
          step: prevState.step + 1,
        }))
        steps[cropScreen.step].completed = true
        steps[cropScreen.step].opened = false

        steps[cropScreen.step + 1].opened = true
      }
      dispatch(hideLoading())
    }
    if (cropperRef.current) {
      console.log(cropperRef.current)
      cropperRef.current.reset()
    }
  }

  const handleEdit = (e) =>
    dispatch(
      editContact({
        ...e,
        ...(e?.forProducts && {
          forProducts: e?.forProducts?.map((prod) => prod?.value),
        }),
        _id: contactId,
        onSuccess: (res) => history.push("/contacts"),
      })
    )

  const handleDelete = (e) =>
    dispatch(
      deleteContact({
        _id: contactId,
        onSuccess: (res) => history.push("/contacts"),
      })
    )
  return (
    <div className="main-container" style={{ height: "93%", padding: "0px" }}>
      {cropScreen.open ? (
        <>
          <div className="mobile-cropper-header">
            <img
              src={require("../../../assets/icons/closeIcon-white.svg")}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                marginLeft: "20px",
              }}
              onClick={() => history.push(`/contacts`)}
            />
            <p className="mobile-step">
              {steps[cropScreen.step].label} - {cropScreen.step + 1}/
              {steps.length}
            </p>
            <div className="fake-element"></div>
          </div>
          <div className="mobile-cropper-container">
            <Cropper
              src={croppedImage?.base64}
              ref={cropperRef}
              onChange={(cropper) => setData(cropper.getImage())}
              className={"cropper"}
            />
          </div>
          <div className="mobile-cropper-footer">
            <Inputs.Button
              text={"Пропусни"}
              className={"white-transparent"}
              style={{ width: "40%", height: "45px" }}
              onClick={handleSkipCrop}
            />
            <Inputs.Button
              text={"Продължи"}
              className={"white"}
              style={{ width: "40%", height: "45px" }}
              onClick={handleCrop}
            />
          </div>
        </>
      ) : (
        <>
          <div className="inner-header-container">
            <div className={`flex w-full items-center`}>
              <img
                src={require("../../../assets/icons/closeIcon.svg")}
                style={{
                  width: "15px",
                  height: "15px",
                  cursor: "pointer",
                  margin: "2px 15px 0px 5px",
                }}
                onClick={() => history.push("/contacts")}
              />
              <h3
                className="inner-title"
                style={{
                  marginLeft: "0px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Нов контакт
              </h3>
            </div>
          </div>
          <div
            className="body-container"
            style={{
              height: "unset",
              padding: "1.5vw",
            }}
          >
            <ContactForm
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              currentUser={currentUser}
              canEdit={true}
            />
            <Inputs.Button
              text={"Запази промени"}
              selected
              style={{ width: "100%", margin: "20px 0px" }}
              onClick={handleSubmit(handleEdit)}
            />
            <Inputs.Button
              text="Изтрий контакт"
              className="delete"
              style={{ width: "100%", margin: "20px 0px" }}
              onClick={() => handleDelete()}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default EditContactFromImageMobile
