import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Button } from '@makedonski/admin-ui'
import {
  hideModal,
  createOffer,
  createOfferEn,
  editOffer,
  editOfferEn,
  searchOfferClients,
  resetResults
} from '../../../actions'
import { ClientAutocomplete } from '../../../components'
import { OfferProduct } from './OffectProduct'
import './styles.scss'
import moment from 'moment'
import { Alerts } from '@makedonski/socourt-utilities/lib/Alerts'

const getProductValue = (product) => {
  if (product.singleUnitPrice) {
    return Number(product.singleUnitPrice) || 0
  } else if (product.expenses) {
    const materialCost = product.expenses.material.reduce((acc, val) => {
      return acc + val.total || 0
    }, 0)
    const staffCost =
      product.expenses.staff &&
      product.expenses.staff.reduce((acc, val) => {
        return acc + val.total
      }, 0)
    const otherCost =
      product.expenses.other &&
      product.expenses.other.reduce((acc, val) => {
        return acc + val.total
      }, 0)
    const customCost =
      product.expenses.custom &&
      product.expenses.custom.reduce((acc, val) => {
        return (
          acc +
          val.elements.reduce((acc, val) => {
            return acc + val.total
          }, 0)
        )
      }, 0)
    return (
      Number(materialCost) +
      Number(staffCost) +
      Number(otherCost) +
      Number(customCost)
    )
  } else {
    return 0
  }
}

function OfferCreationModal ({
  hideModal,
  data,
  productTypes,
  createOffer,
  createOfferEn,
  editOffer,
  editOfferEn
}) {
  const [chosenProducts, setChosenProducts] = useState([])
  const [clientName, setClientName] = useState('')
  const [uic, setUIC] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [attentionOf, setAttentionOf] = useState('')
  const [dueDate, setDueDate] = useState(null)
  const [language, setLanguage] = useState("bg")
  // const [dueDate, setDueDate] = useState(moment().toDate())
  const [deliveryConditions, setDeliveryCondition] = useState('')
  const [paymentConditions, setPaymentConditions] = useState('')
  const [otherDetails, setOtherDetails] = useState('')
  const [bankAccount, setBankAccount] = useState('')
  const [currency, setCurrency] = useState('BGN')
  const [isCurrencyChanged, setIsCurrencyChanged] = useState(false)

  const currencyMap = { 'Лева': 'BGN', 'Евро': 'EUR' }
  const currencyDropdownMap = { 'BGN': 'Лева', 'EUR': 'Евро' }

  useEffect(() => {
    if (data.forEdit) {
      setClientName(data.offer.client.name)
      setUIC(data.offer.client.uic)
      setPhone(data.offer.client.phone)
      setEmail(data.offer.client.email)
      setAddress(data.offer.client.address)
      setAttentionOf(data.offer.client.attentionOf)
      setDueDate(moment(data.offer.dueDate).toDate())
      setDeliveryCondition(data.offer.deliveryConditions)
      setPaymentConditions(data.offer.paymentConditions)
      setOtherDetails(data.offer.otherDetails)
      setBankAccount(data.offer.bankData.bankAccount)
      setCurrency(data.offer.currency)

      const products = data.offer.products.map((product) => {
        const found = data.includedProducts.find(
          (el) => el._id === product.item._id
        )

        if (data?.offer?.currency === 'EUR') {
          return {
            ...found,
            singleUnitPrice:(product.singleUnitPrice / 1.95583).toFixed(2),
            deliveryDate: product.deliveryDate,
            quantity: product.quantity
          }
          // product.singleUnitPrice = (product.singleUnitPrice / 1.95583).toFixed(2)
        }

        return {
          ...found,
          singleUnitPrice: product.singleUnitPrice,
          deliveryDate: product.deliveryDate,
          quantity: product.quantity
        }
      })
      setChosenProducts(products)
    }
  }, [data])

  useEffect(() => {
    if (!data.forEdit && data.includedProducts.length) {
      const readyProducts = data.includedProducts.map((prod) => {
        const quantity = prod.quantity ? prod.quantity : null
        const date = prod.deliveryDate ? prod.deliveryDate : null
        // const date = prod.deliveryDate ? prod.deliveryDate : moment().toDate()
        return {
          ...prod,
          quantity: quantity || prod.orderQuantity,
          deliveryDate: date
        }
      })
      setChosenProducts(readyProducts)
    }
  }, [data])

  const productsTotal =
    chosenProducts.length &&
    chosenProducts.reduce((acc, val) => {
      return acc + getProductValue(val) / 100
    }, 0)

  let totalPrice =
    chosenProducts.length &&
    chosenProducts.reduce((acc, val) => {
      return acc + (getProductValue(val) * val.quantity) / 100
    }, 0)
  const initialProductsWithoutQuantity =
    chosenProducts.length &&
    data.includedProducts.reduce((acc, val) => {
      const chosenProduct = chosenProducts.find((prod) => {
        if (prod.item) {
          return prod.item._id === val._id
        } else {
          return prod._id === val._id
        }
      })
      if (!chosenProduct) {
        return acc + 0
      } else {
        return acc + getProductValue(val) / 100
      }
    }, 0)

    useEffect(() => {
      if (!chosenProducts?.length) return

        const newChosenProducts = chosenProducts.slice(0)
        newChosenProducts.map(product => product.singleUnitPrice = 0)
        setChosenProducts(newChosenProducts)
    
        totalPrice = 0
      
    }, [isCurrencyChanged])

    console.log(chosenProducts)
    console.log(productsTotal)

  return (
    <div className='offer-creation-modal-container'>
      <div className='left-container'>
        <h3>Създаване на оферта</h3>

        {/* <div className="flex-container space-between">
          <div className="input-container">
            <p>Език</p>
            <Input.Dropdown
              placeholder="Моля изберете"
              value={language === "en" ? "Английски" : "Български"}
              options={["Български", "Английски"]}
              onChange={(value) => value.value === "Английски" ? setLanguage("en") : setLanguage("bg")}
            />
          </div>
        </div> */}

        <div className='flex-container space-between'>
          <div className='input-container small'>
            <p>Клиент</p>
            <ClientAutocomplete
              onChooseClient={(data) => {
                setClientName(data.name)
                setAttentionOf(data.name)
                if (data.uic) {
                  setUIC(data.uic)
                } else {
                  setUIC('')
                }
                if (data.phone) {
                  setPhone(data.phone)
                } else {
                  setPhone('')
                }
                if (data.email) {
                  setEmail(data.email)
                } else {
                  setEmail('')
                }
                if (data.address) {
                  setAddress(data.address)
                } else {
                  setAddress('')
                }
              }}
              onChange={(event) => setClientName(event.target.value)}
            />
            {/* <div className="input-wrapper">
              <Input.Text
                value={clientName}
                onChange={(event) => {
                  onChange(event, searchOfferClients)
                  setClientName(event.target.value)
                }}
              />
              {clientResults.length > 0 && (
                <div className="client-results-dropdown">
                  {clientResults.map((client) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setAddress(client.address)
                          setAttentionOf(client.attentionOf)
                          setEmail(client.email)
                          setClientName(client.name)
                          setPhone(client.phone)
                          setUIC(client.uic)
                          resetResults()
                        }}
                      >
                        {client.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div> */}
          </div>
          <div className='input-container small'>
            <p>ЕИК</p>
            <Input.Text
              value={uic}
              onChange={(event) => setUIC(event.target.value)}
            />
          </div>
          <div className='input-container small'>
            <p>Телефон</p>
            <Input.Text
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className='flex-container space-between'>
          <div className='input-container small'>
            <p>Имейл</p>
            <Input.Text
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className='input-container large'>
            <p>Адрес</p>
            <Input.Text
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div className='flex-container space-between'>
          <div className='input-container large'>
            <p>На вниманието на</p>
            <Input.Text
              value={attentionOf}
              onChange={(event) => setAttentionOf(event.target.value)}
            />
          </div>
          <div className='input-container small'>
            <p>Срок на оферта</p>
            <Input.Datepicker
              placeholder='Дата'
              pickDate={dueDate}
              onChange={(date) => {
                const newChosenProducts = chosenProducts
                  .slice(0)
                  .map((product) => {
                    return { ...product }
                    // return { ...product, deliveryDate: date }
                  })
                setChosenProducts(newChosenProducts)
                setDueDate(date)
              }}
            />
          </div>
        </div>
        <div className='input-container large'>
          <p>Условия за доставка</p>
          <Input.Text
            value={deliveryConditions}
            onChange={(event) => setDeliveryCondition(event.target.value)}
          />
        </div>
        <div className='input-container large'>
          <p>Условия на плащане</p>
          <Input.Text
            value={paymentConditions}
            onChange={(event) => setPaymentConditions(event.target.value)}
          />
        </div>
        <div className='input-container large'>
          <p>Допълнително</p>
          <Input.Text
            value={otherDetails}
            onChange={(event) => setOtherDetails(event.target.value)}
          />
        </div>
        <div className='flex-container border-bottom flex-start'>
          <div className='input-container'>
            <p>Ваша банкова сметка</p>
            <Input.Dropdown
              placeholder='Моля, изберете'
              value={bankAccount}
              options={['ДСК', 'УниКредит', 'Райфайзен']}
              onChange={(value) => setBankAccount(value.value)}
            />
          </div>

          <div className='input-container'>
            <p>Валута</p>
            <Input.Dropdown
              placeholder='Моля, изберете'
              value={currencyDropdownMap?.[currency]}
              options={['Лева', 'Евро']}
              onChange={(value) => {
                setCurrency(currencyMap?.[value.value])
                setIsCurrencyChanged(!isCurrencyChanged)
              }}
            />
          </div>
        </div>
        {/* <div className="input-container">
          <p>Вид продукт</p>
          <Input.Dropdown
            placeholder="Моля изберете"
            value={productType}
            options={productTypes.map((type) => type.name)}
            onChange={(value) => {
              setProductResults([])
              setProductType(value.value)
            }}
          />
        </div>
        <div className="flex-container center relative">
          <p
            onClick={() => {
              if (!productType) {
                Alerts.error("Грешка", "Моля изберете тип продукт!")
              } else {
                let type = productTypes.find(
                  (type) => type.name === productType
                )
                searchProduct({
                  productType: type._id,
                  basicInfo: [],
                })
              }
            }}
          >
            Добави към офертa
          </p>
          <div className="arrow-right" />
          {productResults.filter((el) => el.productType.name === productType)
            .length > 0 && (
            <div className="search-results-popup">
              <div className="title-container">
                <h4>Изберете продукт</h4>
                <div
                  className="close-popup-btn"
                  onClick={() => setProductResults([])}
                />
              </div>
              {productResults
                .filter((el) => el.productType.name === productType)
                .map((product) => {
                  let infoString
                  if (product.productType.name === "Тръба") {
                    const typeOfDiameter = product.basicInfo.find(
                      (el) => el.name === "Произвежда се по"
                    )
                    const SN = product.basicInfo.find(
                      (el) => el.name === "Коравина"
                    )
                    if (typeOfDiameter.value === "Вътрешен диаметър") {
                      const diameter = product.basicInfo.find(
                        (el) => el.name === "Вътрешен диаметър"
                      )
                      infoString = `SN${SN.value}_ID${diameter.value}`
                    } else {
                      const diameter = product.basicInfo.find(
                        (el) => el.name === "Външен диаметър"
                      )
                      infoString = `SN${SN.value}_ID${diameter.value}`
                    }
                  } else {
                    infoString = product.basicInfo
                      .map((el) => {
                        return el.value
                      })
                      .join("_")
                  }

                  return (
                    <div className="product-container">
                      <div
                        className="product-name"
                        onClick={() => setProductToAdd(product)}
                      >
                        <p id="large">
                          {product.productType.name === "Тръба"
                            ? infoString
                            : product.productType.name}
                        </p>
                        {product.productType.name !== "Тръба" && (
                          <p id="small">{infoString}</p>
                        )}
                      </div>
                      <SingleRadioButton
                        selected={productToAdd._id === product._id}
                        onClick={() => setProductToAdd(product)}
                      />
                    </div>
                  )
                })}
              <Button.Raised
                text="Добави"
                onClick={() => {
                  if (data.forEdit) {
                    let index = chosenProducts.findIndex((el) => {
                      if (el.item) {
                        return el.item._id === productToAdd._id
                      } else {
                        return el._id === productToAdd._id
                      }
                    })
                    if (index === -1) {
                      let newChosenProducts = chosenProducts.slice(0)
                      newChosenProducts.push({
                        ...productToAdd,
                        deliveryDate: moment().toDate(),
                        quantity: 1,
                      })
                      setProductResults([])
                      setChosenProducts(newChosenProducts)
                    }
                  } else {
                    if (
                      chosenProducts.findIndex(
                        (el) => el._id === productToAdd._id
                      ) === -1
                    ) {
                      let newChosenProducts = chosenProducts.slice(0)
                      newChosenProducts.push({
                        ...productToAdd,
                        deliveryDate: moment().toDate(),
                        quantity: 1,
                      })
                      setProductResults([])
                      setChosenProducts(newChosenProducts)
                    }
                  }
                  clearResults()
                }}
              />
            </div>
          )}
        </div> */}
      </div>
      <div className='right-container'>
        <div className='flex-container space-between'>
          <h3>Продукти към оферта</h3>
          <div className='close-btn' onClick={() => hideModal()} />
        </div>
        <div className='scroll-container'>
          {chosenProducts.map((prod, index) => {
            return (
              <OfferProduct
                onUnitChange={(unitValue) => {
                  const newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].mainUnit = unitValue
                  setChosenProducts(newChosenProducts)
                }}
                currency={currency}
                productTypes={productTypes}
                forEdit={data.forEdit}
                product={prod}
                onSetProductDate={(date) => {
                  const newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].deliveryDate = date
                  setChosenProducts(newChosenProducts)
                }}
                onSetQuantity={(value) => {
                  const newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].quantity = value
                  setChosenProducts(newChosenProducts)
                }}
                onRemoveProduct={() => {
                  const newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts.splice(index, 1)
                  setChosenProducts(newChosenProducts)
                }}
                onSetProductPrice={(value) => {
                  const newChosenProducts = chosenProducts.slice(0)
                  if (!newChosenProducts[index].singleUnitPrice) {
                    newChosenProducts[index] = {
                      ...newChosenProducts[index],
                      singleUnitPrice: Number(value) * 100
                    }
                  } else {
                    newChosenProducts[index].singleUnitPrice =
                      Number(value) * 100
                  }
                  setChosenProducts(newChosenProducts)
                }}
              />
            )
          })}
        </div>
        <div className='margin-value-container'>
          {currency === 'EUR' ? 
          <p id='margin'>
            Марж: {(productsTotal - initialProductsWithoutQuantity).toFixed(2)}
            € |{' '}
            {(
              ((productsTotal - initialProductsWithoutQuantity) /
                productsTotal) *
              100
            ).toFixed(0) || 0}
            %
          </p> :
          <p id='margin'>
            Марж: {(productsTotal - initialProductsWithoutQuantity).toFixed(2)}
            лв. |{' '}
            {(
              ((productsTotal - initialProductsWithoutQuantity) /
                productsTotal) *
              100
            ).toFixed(0) || 0}
            %
          </p>}
        </div>
        <div className='flex-container space-between'>
          <p>Стойност на продуктите</p>
          {currency === 'EUR' ?
          <div className='value-container'>{totalPrice.toFixed(2)} €</div> :
          <div className='value-container'>{totalPrice.toFixed(2)} лв.</div>}
        </div>
        <Button.Raised
          text={`${data.forEdit ? 'Редактирай оферта' : 'Създай оферта'}`}
          onClick={() => {
            if (data.forEdit) {
              const products = chosenProducts.map((product) => {
                let cost =
                  (product.expenses &&
                    product.expenses.material.reduce((acc, val) => {
                      return acc + val.total
                    }, 0)) || 0

                if (currency === 'EUR') {
                  cost = cost * 1.95583
                  product.singleUnitPrice = product.singleUnitPrice * 1.95583
                }
                return {
                  item: product.item ? product.item._id : product._id,
                  quantity: parseInt(product.quantity),
                  deliveryDate: moment(product.deliveryDate).toISOString(),
                  singleUnitPrice: product.singleUnitPrice || cost,
                  total: product.singleUnitPrice
                    ? product.singleUnitPrice * parseInt(product.quantity)
                    : cost * parseInt(product.quantity)
                }
              })
                editOffer({
                  _id: data.offer._id,
                  inquiry: data._id,
                  client: {
                    name: clientName,
                    uic: uic,
                    phone: phone,
                    email: email,
                    address: address,
                    attentionOf: attentionOf
                  },
                  dueDate: dueDate.toISOString(),
                  deliveryConditions: deliveryConditions,
                  paymentConditions: paymentConditions,
                  otherDetails: otherDetails,
                  bankData: {
                    bankAccount: bankAccount,
                    marginFacilities: isNaN(
                      (productsTotal - initialProductsWithoutQuantity) / 100
                    )
                      ? 0
                      : (productsTotal - initialProductsWithoutQuantity) / 100,
                    marginHydro: isNaN(
                      (
                        ((productsTotal - initialProductsWithoutQuantity) /
                          productsTotal) *
                        100
                      ).toFixed(0)
                    )
                      ? 0
                      : (
                          ((productsTotal - initialProductsWithoutQuantity) /
                            productsTotal) *
                          100
                        ).toFixed(0)
                  },
                  products: products,
                  currency: currency
                })
              
            } else {
              if (!dueDate) {
                return Alerts.error('Моля посочете дата за срок на оферта!')
              }

              let isUnvalid = chosenProducts.map(product => product.deliveryDate).includes(null)
              if (isUnvalid) {
                return Alerts.error('Моля посочете дата за срок за доставка!')
              }

              const products = chosenProducts.map((product) => {
                let cost = product.singleUnitPrice
                  ? Number(product.singleUnitPrice)
                  : getProductValue(product)
                // let cost = product.singleUnitPrice
                //   ? Number(product.singleUnitPrice) / 100
                //   : getProductValue(product) / 100

                  if (currency === 'EUR') {
                    console.log(cost)
                    cost = cost * 1.95583  //in coins in bgn
                  }
                  console.log(cost)
                return {
                  item: product._id,
                  quantity: parseInt(product.quantity),
                  deliveryDate: product.deliveryDate.toISOString(),
                  singleUnitPrice: cost,
                  total: cost * parseInt(product.quantity),
                  unit: product?.mainUnit || product?.unit
                  //added unit
                }
              })

              //added currency and mainUnit in products arr
              createOffer({
                inquiry: data._id,
                client: {
                  name: clientName,
                  uic: uic,
                  phone: phone,
                  email: email,
                  address: address,
                  attentionOf: attentionOf
                },
                dueDate: dueDate.toISOString(),
                deliveryConditions: deliveryConditions,
                paymentConditions: paymentConditions,
                otherDetails: otherDetails,
                bankData: {
                  bankAccount: bankAccount,
                  marginFacilities: isNaN(
                    (productsTotal - initialProductsWithoutQuantity) / 100
                  )
                    ? 0
                    : (productsTotal - initialProductsWithoutQuantity) / 100,
                  marginHydro: isNaN(
                    (
                      ((productsTotal - initialProductsWithoutQuantity) /
                        productsTotal) *
                      100
                    ).toFixed(0)
                  )
                    ? 0
                    : (
                        ((productsTotal - initialProductsWithoutQuantity) /
                          productsTotal) *
                        100
                      ).toFixed(0)
                },
                products: products,
                language: language,
                currency: currency
              })

            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  productTypes: state.products.types,
  clientResults: state.inquiries.clientResults
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  createOffer: (payload) => dispatch(createOffer(payload)),
  createOfferEn: (payload) => dispatch(createOfferEn(payload)),
  editOffer: (payload) => dispatch(editOffer(payload)),
  editOfferEn: (payload) => dispatch(editOfferEn(payload)),
  searchOfferClients: (payload) => dispatch(searchOfferClients(payload)),
  resetResults: () => dispatch(resetResults())
})

export default connect(mapStateToProps, mapDispatchToProps)(OfferCreationModal)
