import React, { useEffect, useRef, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  editContact,
  getCurrentContact,
  hideLoading,
  hideModal,
  setCroppedData,
  showLoading,
} from "../../../actions"
import "./styles.scss"
import moment from "moment"
import { Inputs } from "../../Inputs"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { Button } from "@makedonski/admin-ui"
import Files from "../../../utilities/files"
import { history } from "../../../config/stores"
import { fileToBase64, getFileFromUrl } from "../../../utilities/helpers"

function ContactAssetsModal({ hide, data }) {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)
  const contact = useSelector((state) => state.contacts.contact)

  const canEditDelete = currentUser?._id === "5fa2a91bf689c74baedd3a6b"

  const [selTab, setSelTab] = useState("comments")
  const [comment, setComment] = useState(null)

  const uploadRef = useRef()

  const handleUpload = async ({ target: { files } }) => {
    dispatch(showLoading())
    Files.uploadFiles(files).then((result) => {
      handleUpdateFiles(result)
      dispatch(hideLoading())
    })
  }

  useEffect(() => {
    dispatch(
      getCurrentContact({
        _id: data?.contact?._id,
      })
    )
  }, [])

  const handleSendComment = () => {
    if (!comment || !comment?.length) {
      Alerts.error("Грешка", "не сте въвели коментар!")
    } else
      dispatch(
        editContact({
          _id: contact?._id,
          comments: [
            ...contact?.comments,
            {
              createdAt: moment().toISOString(),
              from: currentUser?.fullName,
              text: comment,
            },
          ],
          onSuccess: (res) => {
            setComment(null)
            dispatch(
              getCurrentContact({
                _id: contact?._id,
              })
            )
          },
        })
      )
  }

  const handleUpdateFiles = (files) => {
    if (!files || !files?.length) {
      Alerts.error("Грешка", "не сте избрали файлове!")
    } else
      dispatch(
        editContact({
          _id: contact?._id,
          files: [
            ...contact?.files,
            ...files?.map((file) => ({
              url: file?.location,
              name: file?.location?.split("/")?.[4],
              createdAt: moment().toISOString(),
              from: currentUser?.fullName,
            })),
          ],
          onSuccess: (res) => {
            dispatch(
              getCurrentContact({
                _id: contact?._id,
              })
            )
          },
        })
      )
  }

  return (
    <div className="contact-assets-modal-container">
      <div className="modal-heading">
        <h4>
          {data?.contact?.name || "---"} | {data?.contact?.companyName || "---"}
        </h4>
        <img
          src={require("../../../assets/icons/closeIcon.svg")}
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={() => hide()}
        />
      </div>
      <div className="modal-content" style={{ display: "block" }}>
        <div className="flex items-center w-full">
          <div
            className={`asset-option ${selTab === "comments" && "selected"}`}
            onClick={() => setSelTab("comments")}
          >
            Коментари
          </div>
          <div
            className={`asset-option ${selTab === "files" && "selected"}`}
            onClick={() => {
              setSelTab("files")
              setComment(null)
            }}
          >
            Файлове
          </div>
          {selTab === "files" && (
            <Button.Icon
              onClick={() => uploadRef.current.click()}
              name="plus"
              size={20}
              color="#26ACE2"
            />
          )}
        </div>

        {selTab === "comments" ? (
          <div className="contact-comments-container">
            {contact?.comments?.map((comment) => (
              <>
                <div className="flex items-center w-full justify-between">
                  <p className="comment-user">{comment?.from}</p>
                  <p className="comment-time">
                    {moment(comment?.createdAt).format("DD.MM.YYYY, HH:mm")}
                  </p>
                </div>
                <div className="comment-text-container">{comment?.text}</div>
              </>
            ))}
          </div>
        ) : (
          <div className="contact-comments-container no-gap">
            {contact?.files?.map((file, i) => (
              <>
                <div
                  className="flex items-center w-full justify-between"
                  key={file?.name}
                >
                  <div style={{ width: "70%" }}>
                    <p className="file-name">{file?.name?.slice(0, 40)}</p>
                    <p className="file-data">
                      {moment(file?.createdAt).format("HH:mm | DD.MM.YYYY")} |{" "}
                      {file?.from}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img
                      src={require("../../../assets/icons/scan.svg")}
                      style={{
                        width: "22px",
                        height: "25px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      onClick={async () => {
                        const filePromises = [getFileFromUrl(file?.url)]
                        const newFile = await Promise.all(filePromises)
                        const convertedFile = await fileToBase64(
                          newFile?.[0]
                        ).then((res) => {
                          dispatch(
                            setCroppedData({
                              url: file?.url,
                              base64: res,
                            })
                          )
                          history.push(
                            `/edit-contact/image/${data?.contact?._id}`
                          )
                          hide()
                        })
                      }}
                    />
                    <img
                      src={require("../../../assets/icons/closeIcon-red.svg")}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      onClick={() => {
                        const newFiles = contact?.files?.slice(0)
                        newFiles.splice(i, 1)
                        dispatch(
                          editContact({
                            _id: contact?._id,
                            files: [...newFiles],
                            onSuccess: (res) => {
                              dispatch(
                                getCurrentContact({
                                  _id: contact?._id,
                                })
                              )
                            },
                          })
                        )
                      }}
                    />
                    <img
                      src={require("../../../assets/icons/download-icon-green.svg")}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      onClick={() => window.open(file?.url)}
                    />
                  </div>
                </div>
                <div className="divider" style={{ background: "black" }} />
              </>
            ))}
          </div>
        )}
      </div>
      {selTab === "comments" && (
        <div className="modal-footer">
          <Inputs.TextAreaInput
            placeholder="Коментар"
            outerStyle={{ width: "100%", height: "60px" }}
            inputStyle={{ height: "100%" }}
            value={comment || ""}
            onChange={(e) => setComment(e.target.value)}
            suffix={
              <img
                src={require("../../../assets/icons/sendComment.svg")}
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                onClick={handleSendComment}
              />
            }
          />
        </div>
      )}
      <input
        ref={uploadRef}
        style={{ display: "none" }}
        type="file"
        multiple
        onChange={(e) => handleUpload(e)}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  setCroppedData: (payload) => dispatch(setCroppedData(payload)),
})

export default connect(null, mapDispatchToProps)(ContactAssetsModal)
