import React from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { hideModal, updateProductExpense } from "../../../actions"
import { RadioButton } from "../.."
import "./styles.scss"

function AddExpensesModal({ hide, data, update }) {
  const [expense, setExpense] = React.useState({
    material: {
      name: "",
      pricePerUnit: "", // stotinki
      weight: "", // kg
      total: "",
    },
    staff: [
      {
        type: "Инженер",
        count: "",
        workHours: "",
        pricePerHour: "", // stotinki
        total: "", // stotinki
      },
      {
        type: "Работник",
        count: "",
        workHours: "",
        pricePerHour: "", // stotinki
        total: "", // stotinki
      },
    ],
    other: [
      {
        type: "Ток",
        quantity: "",
        units: "квтч/кг/м",
        singleUnitPrice: "", // stotinki
        total: "", // stotinki
      },
      {
        type: "Вода + Наем",
        quantity: "",
        units: "m3",
        singleUnitPrice: "", // stotinki
        total: "", // stotinki
      },
      {
        type: "Склад и съхранение",
        quantity: "",
        units: "дни",
        singleUnitPrice: "", // stotinki
        total: "", // stotinki
      },
    ],
  })

  const updateExpense = (key, value) => {
    const newExpense = { ...expense, [key]: value }
    setExpense(newExpense)
  }

  const calculateOthers = (index) => {
    const item = [...expense.other]
    if (item[index].quantity && item[index].singleUnitPrice) {
      item[index].total = item[index].quantity * item[index].singleUnitPrice
    } else {
      item[index].total = ""
    }
    updateExpense("other", item)
  }

  const calculateMaterial = (material) => {
    const item = { ...material }
    if (item.pricePerUnit && item.weight) {
      item.total = item.pricePerUnit * item.weight
    } else {
      item.total = ""
    }
    updateExpense("material", item)
  }

  return (
    <div className="add-expenses-modal-container">
      <div className="modal-heading">
        <h3>Разходни норми</h3>
        <div className="exit-btn">
          <Button.Icon name="plus" color="#152331" onClick={hide} />
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-section big">
          <h4>Обща информация</h4>
          <div className="form-control">
            <div className="form-control-label">Материал</div>
            <div className="radio-group-horizontal">
              <RadioButton
                changed={() => {
                  const material = { ...expense.material }
                  material.name = "Полиетилен"
                  updateExpense("material", material)
                }}
                id="3"
                isSelected={expense.material.name === "Полиетилен"}
                label="Полиетилен"
                value="Полиетилен"
              />
              <RadioButton
                changed={() => {
                  const material = { ...expense.material }
                  material.name = "Полипропилен"
                  updateExpense("material", material)
                }}
                id="4"
                isSelected={expense.material.name === "Полипропилен"}
                label="Полипропилен"
                value="Полипропилен"
              />
            </div>
          </div>
          <div className="form-control">
            <div className="form-control inline">
              <div className="form-control-inner">
                <div className="form-control-label">Цена за кг</div>
                <Input.Text
                  value={expense.material.pricePerUnit}
                  onChange={(e) => {
                    const material = { ...expense.material }
                    material.pricePerUnit = e.target.value
                    updateExpense("material", material)
                    calculateMaterial(material)
                  }}
                />
              </div>
              <div className="form-control-inner double">
                <div className="form-control-label">Тежест на продукта</div>
                <Input.Text
                  value={expense.material.weight}
                  onChange={(e) => {
                    const material = { ...expense.material }
                    material.weight = e.target.value
                    updateExpense("material", material)
                    calculateMaterial(material)
                  }}
                />
              </div>
              <div className="form-control-inner">
                <div className="form-control-label">Разход</div>
                <Input.Text value={expense.material.total} disabled />
              </div>
            </div>
          </div>
          {/* <h4 className="child-header">Персонал</h4> */}
          {/* {expense.staff.map((worker, index) => {
            return (
              <div className="form-control">
                <h5>{worker.type}</h5>
                <div className="form-control inline">
                  <div className="form-control-inner">
                    <div className="form-control-label">Брой</div>
                    <Input.Text
                      value={worker.count}
                      onChange={(e) => {
                        const staff = [...expense.staff]
                        staff[index].count = e.target.value
                        updateExpense("staff", staff)
                        calculateExpense(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner">
                    <div className="form-control-label">Работни часа</div>
                    <Input.Text
                      value={worker.workHours}
                      onChange={(e) => {
                        const staff = [...expense.staff]
                        staff[index].workHours = e.target.value
                        updateExpense("staff", staff)
                        calculateExpense(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner">
                    <div className="form-control-label">Надница</div>
                    <Input.Text
                      value={worker.pricePerHour}
                      onChange={(e) => {
                        const staff = [...expense.staff]
                        staff[index].pricePerHour = e.target.value
                        updateExpense("staff", staff)
                        calculateExpense(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner">
                    <div className="form-control-label">Разход</div>
                    <Input.Text disabled value={worker.total} />
                  </div>
                </div>
              </div>
            )
          })} */}
        </div>
        <div className="column-devider" />
        <div className="modal-section">
          <h4>Други разходи</h4>
          {expense.other.map((exp, index) => {
            return (
              <div className="form-control">
                <h5>{exp.type}</h5>
                <div className="form-control inline">
                  <div className="form-control-inner">
                    <div className="form-control-label">Количество</div>
                    <Input.Text
                      value={exp.quantity}
                      onChange={(e) => {
                        const item = [...expense.other]
                        item[index].quantity = e.target.value
                        updateExpense("other", item)
                        calculateOthers(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner">
                    <div className="form-control-label">Ед. Цена</div>
                    <Input.Text
                      value={exp.singleUnitPrice}
                      onChange={(e) => {
                        const item = [...expense.other]
                        item[index].singleUnitPrice = e.target.value
                        updateExpense("other", item)
                        calculateOthers(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner">
                    <div className="form-control-label">Разход</div>
                    <Input.Text
                      disabled
                      value={exp.total}
                      onChange={(e) => {}}
                    />
                  </div>
                </div>
              </div>
            )
          })}
          <div className="button-container">
            <Button.Raised
              onClick={() => {
                update({ _id: data.product, expense })
              }}
              text="Добави"
              className="button-green"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  update: (payload) => dispatch(updateProductExpense(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddExpensesModal)
