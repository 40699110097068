import React, { useState, useEffect, useCallback, useRef } from "react"
import io from "socket.io-client"
import "./styles.scss"
import moment from "moment"
import { Category } from "./Category"
import { emit, useQuery } from "../../utilities/helpers"
import { useSelector, useDispatch } from "react-redux"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { getTasksSuccess, showModal, hideModal, setHorizontalScroll } from "../../actions"
import { API_URL as URL } from "../../config/settings"
import { isEmpty, pick } from "lodash"
import _ from "lodash"
import { history } from "../../config/stores"
import { socket } from "../../actions"

export default function Tasks() {
  const dispatch = useDispatch()
  const tasks = useSelector(state => state.tasks.tasks)
  const categories = useSelector(state => state.tasks.categories)
  const filters = useSelector((state) => state.tasks.filters)
  const horizontalScroll = useSelector((state) => state.tasks.horizontalScroll)
  const lastOpenedTask = useSelector((state) => state.tasks.lastOpenedTask)
  const scrollRef = useRef()

  const scrollRefHorizontal = useRef()
  const scrollRefVertical = useRef()

  const [isActive, setIsActive] = useState(false)
  const [input, setInput] = useState("")

  const { _id, showForm, handleUrlChange } = useQuery()
  const [columns, setColumns] = useState([])
  const [availableFilters, setAvailableFilters] = useState({})
  const [task, setTask] = useState({})
  const [showFilter, setShowFilter] = useState(false)
  // const [filters, setFilters] = useState({})
  const [showNew, setShowNew] = useState(false)
  const [columnName, setColumnName] = useState('')

  const handleCreateColumn = () => {
    emit(socket, { action: 'task/createCategory', payload: { title: input } })
    // setColumnName("")
    // setShowNew(false)

    setIsActive(!isActive)
    setInput("")
}

const reorder = (list, start, end) => {
  const reordered = [...list]
  const [removed] = reordered.splice(start, 1);
  reordered.splice(end, 0, removed);
  return reordered
}

const handleColumnsPositioning = ({ type, source, destination, ...r }) => {
  if (!destination) return
  if (source.droppableId === destination.droppableId && source.index === destination.index) return
  if (type === 'COLUMN') {
      const reordered = reorder(columns, source.index, destination.index)
      setColumns(reordered)
      emit(socket, { action: 'task/reorderCategories', payload: { newOrder: reordered.map((c) => c._id) } })
      return;
  }

  else if (type === 'TASKS') {
    if (destination.droppableId === source.droppableId) {
      const category = columns.find((c) => c._id === source.droppableId);
      let newTasks = category._tasks.map((task) => task._id);
      const reordered = reorder(newTasks, source.index, destination.index)
      newTasks = reorder(category._tasks,  source.index, destination.index)
      let newCategories = [...columns]
      let categoryIndex = columns.findIndex(el => el._id === category._id)

      newCategories.splice(categoryIndex, 1)
      newCategories.splice(categoryIndex, 0, {...category, _tasks: newTasks})

      emit(socket, { action: 'task/reorderTasks', payload: { categoryId: source.droppableId, newOrder: reordered } })
      setColumns(newCategories)
    }

    else if (destination.droppableId !== source.droppableId) { //moving to different list
      const category = columns.find((c) => c._id === source.droppableId);
      const categoryDrag = columns.find((c) => c._id === destination.droppableId);
      const task = category._tasks.find(el => el._id === category._tasks[source.index]._id)  //moved task

      const oldTasks = category._tasks;  //tasks in source category
      oldTasks.splice(source.index, 1);

      const newTasks = categoryDrag._tasks;  //tasks in destination category
      newTasks.splice(destination.index, 0, task).map((task) => task._id);

      const ordered = newTasks.map((task) => task._id)

      emit(socket, { action: 'task/changeTaskCategory', payload: { oldCategoryId: source.droppableId, newCategoryId: destination.droppableId, taskId: task._id, newOrder: ordered } })
      }
  }
}

const props = { socket, task, setTask, setShowFilter }

const delayedScrolling = _.debounce(() => {
  const { scrollTop, scrollHeight, clientHeight, scrollLeft } = scrollRefHorizontal.current
  // scrollRefHorizontal.current.scrollLeft += 100
  dispatch(setHorizontalScroll(scrollLeft))
}, 300)

  useEffect(() => {
      if (!socket) return
      else if (isEmpty(columns)) {
        if (Object.values(filters || {}).length) {
          emit(socket, { action: 'task/getTasks', payload: { filters: filters } })
        }
        else {
          emit(socket, { action: 'task/getTasks', payload: {} }) 
        }
      }
  }, [socket, _id])

  useEffect(() => {
    if (tasks) setColumns(tasks)
  }, [tasks])

  return (
    <div className="tasks-inner-wrapper"  ref={scrollRefHorizontal} onScroll={delayedScrolling}>
      {columns.length ? 
      <DragDropContext onDragEnd={handleColumnsPositioning} style={{ overflow: 'auto' }}>
        <Droppable droppableId="board" type="COLUMN" direction="horizontal">
        {(provided) => <div className="row-droppable" ref={provided.innerRef} {...provided.droppableProps}>
        {columns?.map((column, i) => {
          if (lastOpenedTask?._id === column?._id) {
            return <Category category={column} scrollRef={scrollRef} key={column?._id} index={i} settings={true} {...props}  />
          }
          else {
            return <Category category={column} key={column?._id} index={i} settings={true} {...props}  />
          }
        })}
        {provided.placeholder}
        </div>}
        </Droppable>
      </DragDropContext> : <></>}

        <div className="category-container add-category">
         {isActive ? (
          <div className="add-category-title-wrapper">
            <label>Заглавие</label>
            <input
              type="text"
              onChange={(e) => setInput(e.target.value)}
              value={input}
            ></input>

            <div className="change-category-title-btn-wrapper">
              <button
                onClick={() => setIsActive(!isActive)}
                className="delete-category-btn"
              >
                Откажи
              </button>
              <button
                disabled={input ? false : true}
                onClick={handleCreateColumn}
                className="add-category-btn"
              >
                Добави
              </button>
            </div>
          </div>
        ) : (
          <button className="dashed-btn" onClick={() => setIsActive(!isActive)}>
            Добави категория
          </button>
        )}
        </div>
    </div>
  )
}
