import React, { useState } from "react"
import { connect } from "react-redux"
import moment from "moment"
import { Button, Section } from "@makedonski/admin-ui"
import { updateOrderStatus, showLoading } from "../../actions"
import {
  StorageRawMaterialsItem,
  StorageRawMaterialsOrderStatus,
} from "../../components"

import "./styles.scss"

const mapStatus = {
  new: { text: "Нова заявка", color: "#F7E05D" },
  ordered: { text: "Направена поръчка", color: "#005EA3" },
  // "in-transit": { text: "В транзит - очаква се", color: "#6041ED" },
  delivered: { text: "Доставена", color: "#26ACE2" },
  accrued: { text: "Заприходена поръчка", color: "#25E083" },
  canceled: { text: "Отказана", color: "#FF7590" },
  exported: { text: "Изписана суровина", color: "#E6EEF4" },
}

const StorageRawMaterialsList = ({
  requests,
  selectedRequests,
  setSelectedRequests,
  filter,
  setSortBy,
  updateOrderStatus,
  showLoading
}) => {
  const [selectedItem, setSelectedItem] = useState(null)
  const [reverseSort, setReverseSort] = useState(false)
  const [popupRef, setPopupRef] = useState(null)
  const sortRequests = () => {
    let filtered = requests.filter((r) =>
      r.material.toLowerCase().includes(filter.query.toLowerCase())
    )
    if (filter.period) {
      filtered = filtered.filter((r) =>
        moment(r.createdAt).isBetween(
          moment(filter.startDate),
          filter.endDate,
          "days",
          "[]"
        )
      )
    }
    switch (filter.sortBy) {
      case "material":
        reverseSort
          ? filtered.sort((a, b) => a[filter.sortBy] < b[filter.sortBy])
          : filtered.sort((a, b) => a[filter.sortBy] > b[filter.sortBy])
        break
      case "from":
        reverseSort
          ? filtered.sort((a, b) => a.from.fullName < b.from.fullName)
          : filtered.sort((a, b) => a.from.fullName > b.from.fullName)

        break
      case "provider":
        filtered.sort((a, b) => {
          let tempA = a.provider || { firmName: "яя" }
          let tempB = b.provider || { firmName: "яя" }
          return reverseSort
            ? tempA.firmName < tempB.firmName
            : tempA.firmName > tempB.firmName
        })
        break
      case "quantity":
      case "price":
        filtered.sort((a, b) => {
          let tempA = a[filter.sortBy] || 0
          let tempB = b[filter.sortBy] || 0
          return reverseSort
            ? parseInt(tempA) - parseInt(tempB)
            : parseInt(tempB) - parseInt(tempA)
        })
        break
      case "payment":
        filtered.sort((a, b) => {
          return reverseSort
            ? parseInt(a.payments.length) - parseInt(b.payments.length)
            : parseInt(b.payments.length) - parseInt(a.payments.length)
        })
        break
      default:
        filtered.sort((a, b) => {
          let tempA = a[filter.sortBy] || ""
          let tempB = b[filter.sortBy] || ""
          return reverseSort ? tempA > tempB : tempA < tempB
        })
    }
    return filtered
  }
  return (
    <div className="raw-materials-list-container">
      <div className="raw-materials-list-header">
        <div className="medium larger">
          Суровина:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(
                filter.sortBy === "material" ? !reverseSort : false
              )
              setSortBy("material")
            }}
            className={reverseSort && filter.sortBy === "material" && "rotate"}
          />
        </div>
        <div className="medium">
          Заявено на:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(
                filter.sortBy === "createdAt" ? !reverseSort : false
              )
              setSortBy("createdAt")
            }}
            className={reverseSort && filter.sortBy === "createdAt" && "rotate"}
          />
        </div>
        <div className="small">
          Количество:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(
                filter.sortBy === "quantity" ? !reverseSort : false
              )
              setSortBy("quantity")
            }}
            className={reverseSort && filter.sortBy === "quantity" && "rotate"}
          />
        </div>
        <div className="medium">
          Доставка до:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(
                filter.sortBy === "deliveryDate" ? !reverseSort : false
              )
              setSortBy("deliveryDate")
            }}
            className={
              reverseSort && filter.sortBy === "deliveryDate" && "rotate"
            }
          />
        </div>
        <div className="medium">
          Заявено от:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(filter.sortBy === "from" ? !reverseSort : false)
              setSortBy("from")
            }}
            className={reverseSort && filter.sortBy === "from" && "rotate"}
          />
        </div>
        <div className="medium">
          Доставчик:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(
                filter.sortBy === "provider" ? !reverseSort : false
              )
              setSortBy("provider")
            }}
            className={reverseSort && filter.sortBy === "provider" && "rotate"}
          />
        </div>
        <div className="small">
          Цена:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(filter.sortBy === "price" ? !reverseSort : false)
              setSortBy("price")
            }}
            className={reverseSort && filter.sortBy === "price" && "rotate"}
          />
        </div>
        <div className="small">
          Плащане:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(filter.sortBy === "payment" ? !reverseSort : false)
              setSortBy("payment")
            }}
            className={reverseSort && filter.sortBy === "payment" && "rotate"}
          />
        </div>
        <div className="medium">
          Доставена на:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(
                filter.sortBy === "deliveredAt" ? !reverseSort : false
              )
              setSortBy("deliveredAt")
            }}
            className={
              reverseSort && filter.sortBy === "deliveredAt" && "rotate"
            }
          />
        </div>
        <div className="big">
          Статус:
          <Button.Icon
            isIconOnly
            name="angle-down"
            onClick={() => {
              setReverseSort(filter.sortBy === "status" ? !reverseSort : false)
              setSortBy("status")
            }}
            className={reverseSort && filter.sortBy === "status" && "rotate"}
          />
        </div>
      </div>
      <Section.Popup
        ref={(ref) => {
          setPopupRef(ref)
        }}
        className="item-status"
        content={
          <StorageRawMaterialsOrderStatus
            statusTypes={mapStatus}
            currentStatus={selectedItem && selectedItem.status}
            onClick={(status) => {
              showLoading()
              updateOrderStatus({
                _id: selectedItem && selectedItem._id,
                status,
              })
              setSelectedItem(null)
              popupRef.hide()
            }}
            onClose={() => {
              setSelectedItem(null)
              popupRef.hide()
            }}
          />
        }
      ></Section.Popup>
      <div className="raw-materials-list-content-container">
        {sortRequests().map((request) => {
          return (
            <StorageRawMaterialsItem
              key={`storage-raw-materials-item-${request._id}`}
              request={request}
              checked={selectedRequests.includes(request)}
              onOpenPopup={() => {
                let index = selectedRequests.findIndex(
                  (el) => el._id === request._id
                )
                if (index !== -1) {
                  let newSelectedRequests = selectedRequests.slice(0)
                  newSelectedRequests.splice(index, 1)
                  setSelectedRequests(newSelectedRequests)
                }
                setSelectedItem(request)
              }}
              onSelect={() => {
                if (selectedRequests.includes(request))
                  setSelectedRequests(
                    selectedRequests.filter((r) => r._id !== request._id)
                  )
                else setSelectedRequests([...selectedRequests, request])
              }}
              popup={popupRef}
            />
          )
        })}
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  requests: state.storage.requests,
})

const mapDispatchToProps = (dispatch) => ({
  updateOrderStatus: (payload) => dispatch(updateOrderStatus(payload)),
  showLoading: () => dispatch(showLoading()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageRawMaterialsList)
