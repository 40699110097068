import {
  Button,
  FormControl,
  Paper,
  TextField,
  InputBase,
  Divider,
  Box,
  Autocomplete,
} from "@mui/material"
import { DatePicker } from "@mui/lab"

import React, { useState, useEffect } from "react"
import styles from "./EditRequest.module.scss"
import moment from "moment"
import {
  getInquiries,
  deleteProductionRequest,
  updateSchedule,
} from "../../actions"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { history } from "../../config/stores"

function EditRequest({
  data,
  setEnableEdit,
  deleteProductionRequest,
  enableEdit,
  getList,
  inquiries,
  updateScheduleAction,
}) {
  useEffect(() => {
    getList()
  }, [])
  const [project, setProject] = useState({})
  const [initialProjectName, setInitialProjectName] = useState("")
  const [product, setProduct] = useState({})
  const [dateValue, setDateValue] = useState(null)
  const [quantity, setQuantity] = useState(0)
  const [comment, setComment] = useState("")
  useEffect(() => {
    if (data && inquiries) {
      setDateValue(moment(data?.deadline))
      let initialProject = inquiries.find(
        (inquiry) => inquiry?._id === data?.inquiry?._id
      )
      setProject(initialProject)
      setInitialProjectName(initialProject?.object)
      setQuantity(data?.quantity)
      setComment(data?.comments?.map((comment) => comment.text))
    }
  }, [data, inquiries])
  const [chosenProjectId, setChosenProjectId] = useState(null)
  useEffect(() => {
    if (project) {
      setChosenProjectId(project._id)
    }
  }, [project])
  const availableProducts = React.useMemo(() => {
    if (project?.object === "") return []
    return project?.orders?.map((el) => el.product)?.filter((el) => el !== null)
  }, [project])

  return (
    <div
      className={`${styles["edit-request-container"]} ${
        enableEdit === false && styles.edit
      }`}
    >
      <Paper elevation={2}>
        <Paper elevation={3} className={styles["edit-request-header"]}>
          {enableEdit === false ? (
            <>
              <div className={styles["form-info"]}>
                <h3>Преглед заявка</h3>
                <p>Статус:</p>
                <p style={{ color: "#26ACE2" }}>{data?.status}</p>
              </div>
              <div>
                <Button
                  variant="outlined"
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  sx={{
                    color: "black",
                    backgroundColor: "#F9FAFC",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#F9FAFC",
                    },
                  }}
                  onClick={() => setEnableEdit(true)}
                >
                  Редакция
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  onClick={() => history.push(`/productionv2`)}
                  sx={{
                    color: "white",
                    backgroundColor: "#26ACE2",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#26ACE2",
                    },
                  }}
                >
                  Към производство
                </Button>
              </div>
            </>
          ) : (
            <>
              <h3>Редакция заявка</h3>
              <div>
                <Button
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  variant="outlined"
                  sx={{ borderColor: "#FF7590", color: "black" }}
                  onClick={() =>
                    deleteProductionRequest({ _id: chosenProjectId })
                  }
                >
                  Изтрий
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  sx={{
                    color: "white",
                    backgroundColor: "#25E083",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#25E083",
                    },
                  }}
                  onClick={() => {
                    const payload = {
                      product,
                      quantity,
                      comment,
                      deadline: moment(dateValue),
                    }
                    updateScheduleAction({ _id: chosenProjectId, ...payload }) // TODO}
                  }}
                >
                  Запази
                </Button>
              </div>
            </>
          )}
        </Paper>
        <div className={styles["form-container"]}>
          <div
            className={`${styles["form-upperline"]} ${
              enableEdit && styles.edit
            }`}
          >
            <div>
              <FormControl style={{ width: "300px" }}>
                <label>Проект</label>
                {enableEdit ? (
                  <Autocomplete
                    options={inquiries}
                    getOptionLabel={(option) =>
                      option.object ? option.object : ""
                    }
                    onChange={(e, newValue) => setProject(newValue)}
                    // defaultChecked={
                    //   inquiries.find(
                    //     (inq) => inq.object === initialProjectName
                    //   ) || ""
                    // }
                    renderOption={(props, option) => (
                      <Box component={"li"} {...props}>
                        <p>{option.object}</p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Избор на проект" />
                    )}
                  />
                ) : (
                  <TextField
                    size="small"
                    disabled
                    value={data?.inquiry?.object}
                  />
                )}
              </FormControl>
              <FormControl
                style={{ width: "300px" }}
                className={styles["form-control"]}
              >
                <label>Продукт</label>
                {enableEdit ? (
                  <Autocomplete
                    options={availableProducts}
                    getOptionLabel={(option) =>
                      option?.friendlyNames?.join(", ") ||
                      option?.productType?.name
                    }
                    onChange={(e, newValue) => setProduct(newValue)}
                    renderOption={(props, option) => (
                      <Box component={"li"} {...props}>
                        <p>
                          {option?.friendlyNames?.join(", ") ||
                            option?.productType.name}
                        </p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Избор на продукт" />
                    )}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      size="small"
                      disabled
                      value={
                        data?.product?.friendlyNames?.join(", ") ||
                        data?.product?.productType?.name
                      }
                    />
                    <div
                      className={styles["arrow-right-coloured"]}
                      onClick={() => {
                        window.open(`/products/${data.product._id}`)
                      }}
                    />
                  </div>
                )}
              </FormControl>
            </div>
            <div style={enableEdit ? { marginLeft: "20px" } : {}}>
              <FormControl size="small">
                <label>К-во</label>
                <Paper
                  component={"form"}
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "80px",
                    height: "36px",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    disabled={!enableEdit}
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                  <p>{data?.product?.mainUnit}</p>
                </Paper>
              </FormControl>
              <FormControl className={styles["form-control"]}>
                <label>Срок за производство</label>
                {enableEdit ? (
                  <DatePicker
                    components={{
                      OpenPickerIcon: () => <img src="/calendar-icon.png" />,
                    }}
                    label={"-/-/-"}
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(moment(newValue))
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                ) : (
                  <TextField
                    size="small"
                    disabled
                    value={moment(dateValue).format("DD/MM/YYYY")}
                  />
                )}
              </FormControl>
            </div>
          </div>
          <FormControl>
            <label>Бележки</label>
            <TextField
              fullWidth
              size="small"
              disabled={!enableEdit}
              value={comment}
            />
          </FormControl>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiries: state.inquiries.data,
})

const mapDispatchToProps = (dispatch) => ({
  getList: () => dispatch(getInquiries()),
  deleteProductionRequest: (payload) =>
    dispatch(deleteProductionRequest(payload)),
  updateScheduleAction: (payload) => dispatch(updateSchedule(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRequest)
