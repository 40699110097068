import React from "react";
import { connect } from "react-redux";
import { setUserWorkingStatus } from "../../actions";
import "./styles.scss";

class WorkingStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
    };
    this.linkDetails = {};
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.closeModal();
    }
  }
  handleSelect(e) {
    this.props.setUserWorkingStatus({ workingType: e.target.name });
    this.props.closeModal();
  }
  render() {
    return (
      <div className="working-status" ref={this.wrapperRef}>
        <div className="settings-menu">
          <button name="inOffice" onClick={this.handleSelect.bind(this)}>
            На работа
          </button>
          <button name="inMeeting" onClick={this.handleSelect.bind(this)}>
            В среща
          </button>
          <button name="onSite" onClick={this.handleSelect.bind(this)}>
            На обект
          </button>
          <button name="fromHome" onClick={this.handleSelect.bind(this)}>
            От Вкъщи
          </button>
          <button name="feelingSick" onClick={this.handleSelect.bind(this)}>
            В болничен
          </button>
          <button name="vacation" onClick={this.handleSelect.bind(this)}>
            В отпуск
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  profile: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setUserWorkingStatus: (payload) => dispatch(setUserWorkingStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkingStatus);
