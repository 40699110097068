import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { machineSettingTypes, GeneralTypes, hideLoading } from "../actions"
import { API_URL } from "../config/settings"

export const getMachineSettings = (action$) => {
  return action$.pipe(
    ofType(machineSettingTypes.GET_MACHINE_SETTINGS),
    switchMap(() => {
      return Api.get(`${API_URL}/machines/settings`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: machineSettingTypes.GET_MACHINE_SETTINGS_SUCCESS,
              payload: response.settings,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteMachineSettings = (action$) => {
  return action$.pipe(
    ofType(machineSettingTypes.DELETE_MACHINE_SETTINGS),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/machines/settings`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: machineSettingTypes.DELETE_MACHINE_SETTINGS_SUCCESS,
              payload: response.settings,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editMachineSettings = (action$) => {
  return action$.pipe(
    ofType(machineSettingTypes.EDIT_MACHINE_SETTINGS),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/machines/settings `,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: machineSettingTypes.EDIT_MACHINE_SETTINGS_SUCCESS,
              payload: response.settings,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getSpecificSettings = (action$) => {
  return action$.pipe(
    ofType(machineSettingTypes.GET_SPECIFIC_SETTINGS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/settings/settingsByProduct`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: machineSettingTypes.GET_SPECIFIC_SETTINGS_SUCCESS,
              payload: response.settings,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
