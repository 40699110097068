import React, { useState, useRef, useEffect } from "react"
import {
  hideModal,
  accrueHPOrder,
  showLoading,
  hideLoading,
  createHPRequest,
  getProductTypes,
  getProduct,
  filterHPOrders,
} from "../../../actions"
import {
  QuantityDropdownInput,
  SingleRadioButton,
  ReadyProductsAutocomplete,
} from "../.."
import Files from "../../../utilities/files"
import moment from "moment"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import "./styles.scss"
import { debounce } from "lodash"

const onSearchInit = (event, filterHPOrders) => {
  event.persist()
  onSearchDelayed(event, filterHPOrders)
}

const onSearch = (event, filterHPOrders) => {
  filterHPOrders({ search: event.target.value, allStatuses: true })
}

const onSearchDelayed = debounce(
  (event, filterHPOrders) => onSearch(event, filterHPOrders),
  500
)

function ReadyProductCreateAccrualModal({
  hideModal,
  accrueHPOrder,
  data,
  showLoading,
  hideLoading,
  found,
  createHPRequest,
  types,
  getProductTypes,
  filterHPOrders,
  getProduct,
  more,
}) {
  const [typeOfDeal, setTypeOfDeal] = useState("Съществуващ продукт")
  const [productsToAdd, setProductsToAdd] = useState([
    {
      productType: "",
      product: "",
      quantity: 1,
      deliveryDate: moment().toDate(),
      units: { value: "бр" },
      focused: false,
      filter: "",
      criticalQuantity: "",
    },
  ])

  const [products, setProducts] = useState([])
  const [invoice, setInvoice] = useState(null)
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [issueDate, setIssueDate] = useState(moment().toDate())
  const [additionalFiles, setAdditionalFiles] = useState([])
  const [toExisting, setToExisting] = useState([])
  const [comment, setComment] = useState("")
  let fileInput = useRef()
  let moreFiles = useRef()

  useEffect(() => {
    if (data.length) {
      setProducts(
        data.map((el) => {
          return { ...el, name: el.product }
        })
      )
      setToExisting(data.map(() => false))
    }
  }, [data])

  useEffect(() => {
    getProductTypes()
  }, [getProductTypes])

  return (
    <div className="ready-product-accrual-container create">
      <div className="top-container">
        <h3>Заприхождаване</h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <p>Тип заявка</p>
      <div className="flex-container start padding">
        <SingleRadioButton
          label="Съществуващ продукт"
          selected={typeOfDeal === "Съществуващ продукт"}
          onClick={() => {
            setTypeOfDeal("Съществуващ продукт")
          }}
        />
        <SingleRadioButton
          label="Нов продукт"
          selected={typeOfDeal === "Нов продукт"}
          onClick={() => setTypeOfDeal("Нов продукт")}
        />
      </div>
      {productsToAdd.map((product, index) => {
        return (
          <div className="flex-container space-between">
            <div className="input-element">
              <p>Продукт</p>
              <div className="input-container">
                <Input.Text
                  onFocus={() => {
                    let newProductsToAdd = productsToAdd.slice(0)
                    newProductsToAdd[index].focused = true
                    setProductsToAdd(newProductsToAdd)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      let newProductsToAdd = productsToAdd.slice(0)
                      newProductsToAdd[index].focused = false
                      setProductsToAdd(newProductsToAdd)
                    }, 300)
                  }}
                  // disabled={!product.productType}
                  value={product.product}
                  onChange={(event) => {
                    let newProductsToAdd = productsToAdd.slice(0)
                    newProductsToAdd[index].product = event.target.value
                    newProductsToAdd[index].filter = event.target.value
                    setProductsToAdd(newProductsToAdd)
                    onSearchInit(event, filterHPOrders)
                  }}
                />
                {product.focused && typeOfDeal === "Съществуващ продукт" && (
                  <div className="products-dropdown">
                    {found.map((el) => {
                      const name = el.product
                      return (
                        <div
                          className="product-option"
                          onClick={() => {
                            let newProductsToAdd = productsToAdd.slice(0)
                            newProductsToAdd[index].product = name
                            setProductsToAdd(newProductsToAdd)
                          }}
                        >
                          {name}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="input-element">
              <p>Количество</p>
              <QuantityDropdownInput
                quantityValue={product.quantity}
                quantityTypeValue={product.units}
                onSetQuantity={(value) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].quantity = value
                  setProductsToAdd(newProductsToAdd)
                }}
                onSelectType={(type) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].units = type
                  setProductsToAdd(newProductsToAdd)
                }}
              />
            </div>
            <div className="input-element">
              <p>Нужна до</p>
              <Input.Datepicker
                placeholder="Дата"
                pickDate={product.deliveryDate}
                onChange={(date) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].deliveryDate = date
                  setProductsToAdd(newProductsToAdd)
                }}
              />
            </div>
            <div className="input-element">
              <span>Критично количество</span>
              <QuantityDropdownInput
                quantityValue={product.criticalQuantity}
                quantityTypeValue={product.units}
                onSetQuantity={(value) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].criticalQuantity = value
                  setProductsToAdd(newProductsToAdd)
                }}
                onSelectType={(type) => {}}
                disableUnits
              />
            </div>
          </div>
        )
      })}
      <div className="flex-container space-between">
        <Button.Raised
          className="clear-border"
          text="Добави продукт"
          onClick={() => {
            let newProductsToAdd = productsToAdd.slice(0)
            newProductsToAdd.push({
              productType: "",
              product: "",
              quantity: 1,
              deliveryDate: moment().toDate(),
              units: { value: "бр" },
              filter: "",
              criticalQuantity: "",
            })
            setProductsToAdd(newProductsToAdd)
          }}
        />
        {/* <Button.Raised
          text="Създай заявка"
          onClick={() => {
            createHPRequest(
              productsToAdd.map((product) => {
                return {
                  product: product.product,
                  quantity: product.quantity,
                  deliveryDate: product.deliveryDate,
                  units: product.units.value,
                  criticalQuantity: product.criticalQuantity,
                }
              })
            )
          }}
        /> */}
      </div>

      {products.map((el, index) => {
        return (
          <div className="flex-container space-between border-bottom">
            <div className="input-element no-margin">
              <p>Заприхождаване към</p>
              <div className="flex-container">
                <SingleRadioButton
                  label="Като нова позиция"
                  selected={!toExisting[index]}
                  onClick={() => {
                    const newToExisting = toExisting.slice(0)
                    const newProducts = products.slice(0)
                    newToExisting[index] = false
                    newProducts[index].name = data[index].product
                    setToExisting(newToExisting)
                    setProducts(newProducts)
                  }}
                />
                <SingleRadioButton
                  label="Към съществуваща"
                  selected={toExisting[index]}
                  onClick={() => {
                    const newToExisting = toExisting.slice(0)
                    const newProducts = products.slice(0)
                    newToExisting[index] = true
                    newProducts[index].name = ""
                    setToExisting(newToExisting)
                    setProducts(newProducts)
                  }}
                />
              </div>
            </div>
            {toExisting[index] ? (
              <div className="input-element">
                <p>Продукт</p>
                <ReadyProductsAutocomplete
                  onChooseOrder={(order) => {
                    const newProducts = products.slice(0)
                    newProducts[index].to = order._id
                    newProducts[index].name = order.product
                    setProducts(newProducts)
                  }}
                />
              </div>
            ) : (
              <div className="input-element">
                <p>Продукт</p>
                <Input.Text disabled value={el.name} />
              </div>
            )}

            <div className="input-element">
              <p>Количество</p>
              <Input.Text disabled value={`${el.quantity}  ${el.units}`} />
            </div>
            <div className="input-element">
              <p>Доставено на</p>
              <Input.Datepicker
                disabled
                placeholder="Дата"
                pickDate={moment(el.deliveredAt).toDate()}
                // onChange={(date) => {
                //   console.log(date)
                // }}
              />
            </div>
          </div>
        )
      })}

      <div className="flex-container">
        <div className="input-element no-margin">
          <p>Фактура Номер</p>
          <Input.Text
            onChange={(event) => setInvoiceNumber(event.target.value)}
          />
        </div>
        <div className="input-element no-margin">
          <p>Фактура Издаване</p>
          <Input.Datepicker
            placeholder="Дата"
            pickDate={issueDate}
            onChange={(date) => {
              setIssueDate(date)
            }}
          />
        </div>
        <div className="input-element">
          <p>Добави файл:</p>
          <div className="flex-container">
            {!invoice ? (
              <>
                <input
                  type="file"
                  ref={(ref) => {
                    fileInput = ref
                  }}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    showLoading()
                    event.persist()
                    const name = event.target.files[0].name
                    Files.uploadFile(event.target.files[0])
                      .then((data) => {
                        setInvoice({ name: name, url: data.location })
                        hideLoading()
                      })
                      .catch((err) => {
                        hideLoading()
                      })
                  }}
                />
                <div
                  className="flex-container border-right"
                  onClick={() => fileInput && fileInput.click()}
                >
                  <p id="file">Фактура</p>
                  <div className="attach-icon" />
                </div>
              </>
            ) : (
              <div className="flex-container">
                <p id="file" onClick={() => window.open(invoice.url)}>
                  {invoice.name}
                </p>
                <div className="remove-icon" onClick={() => setInvoice(null)} />
              </div>
            )}
            <div
              className="flex-container"
              onClick={() => moreFiles && moreFiles.click()}
            >
              <input
                type="file"
                ref={(ref) => {
                  moreFiles = ref
                }}
                multiple
                style={{ display: "none" }}
                onChange={(event) => {
                  showLoading()
                  event.persist()
                  const nameArray = Object.keys(event.target.files).map(
                    (key) => {
                      return event.target.files[key].name
                    }
                  )
                  Files.uploadFiles(event.target.files)
                    .then((data) => {
                      let files = additionalFiles.slice(0)
                      data.forEach((el, index) => {
                        files.push({ name: nameArray[index], url: el.location })
                      })
                      setAdditionalFiles(files)
                      hideLoading()
                    })
                    .catch((err) => {
                      hideLoading()
                    })
                }}
              />
              <p id="file">Друг файл</p>
              <div className="attach-icon" />
            </div>
            <div className="flex-container scroll">
              {additionalFiles.map((file, index) => {
                return (
                  <div className="flex-container">
                    <p id="file" onClick={() => window.open(file.url)}>
                      {file.name}
                    </p>
                    <div
                      className="remove-icon"
                      onClick={() => {
                        let newAdditionalFiles = additionalFiles.slice(0)
                        newAdditionalFiles.splice(index, 1)
                        setAdditionalFiles(newAdditionalFiles)
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="input-element width50 no-margin">
        <p>Коментар</p>
        <Input.Text
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </div>
      <div className="flex-container flex-end">
        <Button.Raised
          text="Заприходи"
          onClick={() => {
            if (
              products.findIndex((product, index) => {
                if (toExisting[index]) {
                  return product.name === ""
                } else {
                  return false
                }
              }) === -1
            ) {
              // const payload = products.map((product, index) => {
              //   if (toExisting[index]) {
              //     return {
              //       _id: product._id,
              //       files: additionalFiles,
              //       invoice: {
              //         ...invoice,
              //         invoiceNumber: invoiceNumber,
              //         issueDate: issueDate,
              //       },
              //       to: product.to,
              //       comment: comment,
              //     }
              //   } else {
              //     return {
              //       // _id: product._id,
              //       files: additionalFiles,
              //       invoice: {
              //         ...invoice,
              //         invoiceNumber: invoiceNumber,
              //         issueDate: issueDate,
              //       },
              //       comment: comment,
              //     }
              //   }
              // })
              const payload = [{
                // _id: product._id,
                files: additionalFiles,
                invoice: {
                  ...invoice,
                  invoiceNumber: invoiceNumber,
                  issueDate: issueDate,
                },
                comment: comment,
              }]

              createHPRequest({
                payload: productsToAdd.map((product) => {
                  return {
                    product: product.product,
                    quantity: product.quantity,
                    deliveryDate: product.deliveryDate,
                    units: product.units.value,
                    criticalQuantity: product.criticalQuantity,
                  }
                }),
                onSuccess: (res) =>  { 
                  payload[0]._id = res?.requests?.[0]?._id
                  accrueHPOrder(payload)
                }
              })
              // accrueHPOrder(payload)
            } else {
              Alerts.error("Грешка!", "Моля изберете продукт.")
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  more: state.products.more,
  found: state.storage.found,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  accrueHPOrder: (payload) => dispatch(accrueHPOrder(payload)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  createHPRequest: (payload) => dispatch(createHPRequest(payload)),
  getProductTypes: () => dispatch(getProductTypes()),
  getProduct: (payload) => dispatch(getProduct(payload)),
  filterHPOrders: (payload) => dispatch(filterHPOrders(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadyProductCreateAccrualModal)
