import React from "react";
import moment from "moment";
import "./styles.scss";
import "./InquiryOfferProducts";
import { useState } from "react";
import InquiryOfferProducts from "./InquiryOfferProducts";
import { history } from "../../config/stores";

const InquiryOfferElement = ({ data }) => {
  const [ isProductTabActive, setIsProductTabActive ] = useState(false); 
  const dropdownArrow = require("../../assets/icons/arrowDown.svg");
  const penIcon = require("../../assets/icons/pen-icon.svg");

  return (
    <>
      <div className="inquiry-client-element" >
      <div className="element small">{data.refNumber}</div>
      <div className="element medium overflow">{data.client}</div>
      <div className="element medium overflow">{data.object}</div>
      {data?.products?.length ? 
      <div className="element medium flex">
        {`${data.products.length} ${data.products.length > 1 ? 'оферирани продукти' : 'офериран продукт'}`}
        <img 
        src={dropdownArrow}
        className="dropdown-arrow"
        onClick={() => setIsProductTabActive(!isProductTabActive)}
        style={ isProductTabActive ? { transform: "rotate(180deg)" } : {} }
        ></img>
      </div> :
      <div className="element medium">Няма</div>}
      <div className="element medium">{data.total.toFixed(2) + ' лв.'}</div>
      <div className="element medium">
        {moment(data.createdAt).format("DD.MM.YYYY")}
      </div>
      <div className="element medium">{data.createdBy}</div>
      <div className="element large flex">
        <p>{moment(data.updatedAt).format("DD.MM.YYYY")}</p>

        <div className="icons-wrapper">
          <img src={penIcon} onClick={() => history.push({pathname: `/inquiries/edit-offer/${data.refNumber}`, state: { data: data }})}/>
        </div>
      </div>
    </div>

    {isProductTabActive && <InquiryOfferProducts products={data.products} />}
    </>
  )
}

export default InquiryOfferElement
