export const InquiriesTypes = {
  GET_INQUIRIES: "inquiries/GET_INQUIRIES",
  GET_INQUIRIES_SUCCESS: "inquiries/GET_INQUIRIES_SUCCESS",
  CREATE_INQUIRY: "inquiries/CREATE_INQUIRY",
  CREATE_INQUIRY_SUCCESS: "inquiries/CREATE_INQUIRY_SUCCESS",
  GET_INQUIRY: "inquiries/GET_INQUIRY",
  GET_INQUIRY_SUCCESS: "inquiries/GET_INQUIRY_SUCCESS",
  ADD_PRODUCTS_TO_INQUIRY: "inquiries/ADD_PRODUCTS_TO_INQUIRY",
  ADD_PRODUCTS_TO_INQUIRY_SUCCESS: "inquiries/ADD_PRODUCTS_TO_INQUIRY_SUCCESS",
  ADD_DOCUMENTS_TO_INQUIRY: "inquiries/ADD_DOCUMENTS_TO_INQUIRY",
  ADD_DOCUMENTS_TO_INQUIRY_SUCCESS:
    "inquiries/ADD_DOCUMENTS_TO_INQUIRY_SUCCESS",
  CHANGE_WORKING_ON: "inquiries/CHANGE_WORKING_ON",
  CHANGE_WORKING_ON_SUCCESS: "inquiries/CHANGE_WORKING_ON_SUCCESS",
  ADD_COMMENT: "inquiries/ADD_COMMENT",
  ADD_COMMENT_SUCCESS: "inquiries/ADD_COMMENT_SUCCESS",
  UPDATE_COMMENT: "inquiries/UPDATE_COMMENT",
  UPDATE_COMMENT_SUCCESS: "inquiries/UPDATE_COMMENT_SUCCESS",
  REMOVE_COMMENT: "inquiries/REMOVE_COMMENT",
  REMOVE_COMMENT_SUCCESS: "inquiries/REMOVE_COMMENT_SUCCESS",
  ADD_ACTIONS: "inquiries/ADD_ACTIONS",
  ADD_ACTIONS_SUCCESS: "inquiries/ADD_ACTIONS_SUCCESS",
  UPDATE_INQUIRY: "inquiries/UPDATE_INQUIRY",
  UPDATE_INQUIRY_SUCCESS: "inquiries/UPDATE_INQUIRY_SUCCESS",
  DELETE_INQUIRY: "inquiries/DELETE_INQUIRY",
  DELETE_INQUIRY_SUCCESS: "inquiries/DELETE_INQUIRY_SUCCESS",

  ADD_INVOICE_CREDIT: "inquiries/ADD_INVOICE_CREDIT",
  ADD_INVOICE_DEBIT: "inquiries/ADD_INVOICE_DEBIT",

  CREATE_ZONE: "inquiries/CREATE_ZONE",
  CREATE_ZONE_SUCCESS: "inquiries/CREATE_ZONE_SUCCESS",
  GET_ZONES: "inquiries/GET_ZONES",
  GET_ZONES_SUCCESS: "inquiries/GET_ZONES_SUCCESS",

  CHANGE_INVOICE_STATUS: "inquiries/CHANGE_INVOICE_STATUS",

  EDIT_INVOICE_CREDIT: "inquiries/EDIT_INVOICE_CREDIT",
  EDIT_INVOICE_DEBIT: "inquiries/EDIT_INVOICE_DEBIT",

  DELETE_INVOICE_CREDIT: "inquiries/DELETE_INVOICE_CREDIT",
  DELETE_INVOICE_DEBIT: "inquiries/DELETE_INVOICE_DEBIT",

  CREATE_PROFORM_INVOICE: "inquiries/CREATE_PROFORM_INVOICE",
  CREATE_PROFORM_INVOICE_EN: "inquiries/CREATE_PROFORM_INVOICE_EN",
  EDIT_PROFORM_INVOICE: "inquiries/EDIT_PROFORM_INVOICE",
  EDIT_PROFORM_INVOICE_EN: "inquiries/EDIT_PROFORM_INVOICE_EN",
  DELETE_PROFORM_INVOICE: "inquiries/DELETE_PROFORM_INVOICE",

  GET_ALL_OFFERS: "inquiries/GET_ALL_OFFERS",
  GET_ALL_OFFERS_SUCCESS: "inquiries/GET_ALL_OFFERS_SUCCESS",

  SEARCH_INQUIRIES_OFFERS: "inquiries/SEARCH_INQUIRIES_OFFERS",
  SEARCH_INQUIRIES_OFFERS_SUCCESS: "inquiries/SEARCH_INQUIRIES_OFFERS_SUCCESS",

  SEARCH_INQUIRIES_PRODUCTION: "inquiries/SEARCH_INQUIRIES_PRODUCTION",
  SEARCH_INQUIRIES_PRODUCTION_SUCCESS:
    "inquiries/SEARCH_INQUIRIES_PRODUCTION_SUCCESS",

  FILTER_INQUIRIES_OFFERS: "inquiries/FILTER_INQUIRIES_OFFERS",
  FILTER_INQUIRIES_OFFERS_SUCCESS: "inquiries/FILTER_INQUIRIES_OFFERS_SUCCESS",

  GET_ALL_SALES: "inquiries/GET_ALL_SALES",
  GET_ALL_SALES_SUCCESS: "inquiries/GET_ALL_SALES_SUCCESS",

  SEARCH_SALES: "inquiries/SEARCH_SALES",
  SEARCH_SALES_SUCCESS: "inquiries/SEARCH_SALES_SUCCESS",

  CREATE_CHECKLIST: "checklist/CREATE_CHECKLIST",
  CREATE_CHECKLIST_SUCCESS: "checklist/CREATE_CHECKLIST_SUCCESS",

  CREATE_INQUIRY_STATUS: "/CREATE_INQUIRY_STATUS",
  CREATE_INQUIRY_STATUS_SUCCESS: "/CREATE_INQUIRY_STATUS_SUCCESS",

  GET_INQUIRY_STATUSES: "inquiries/GET_INQUIRY_STATUSES",
  GET_INQUIRY_STATUSES_SUCCESS: "inquiries/GET_INQUIRY_STATUSES_SUCCESS",

  GET_INQUIRY_EXPENSE_MACHINE: "inquiries/GET_INQUIRY_EXPENSE_MACHINE",
  GET_INQUIRY_EXPENSE_MACHINE_SUCCESS:
    "inquiries/GET_INQUIRY_EXPENSE_MACHINE_SUCCESS",

  GET_INQUIRY_EXPENSE_END_PRODUCT: "inquiries/GET_INQUIRY_EXPENSE_END_PRODUCT",
  GET_INQUIRY_EXPENSE_END_PRODUCT_SUCCESS:
    "inquiries/GET_INQUIRY_EXPENSE_END_PRODUCT_SUCCESS",

  GET_INQUIRY_DEFECTIVES: "inquiries/GET_INQUIRY_DEFECTIVES",
  GET_INQUIRY_DEFECTIVES_SUCCESS: "inquiries/GET_INQUIRY_DEFECTIVES_SUCCESS",

  GET_PRODUCTS_PRODUCT: "inquiries/GET_PRODUCTS_PRODUCT",
  GET_PRODUCTS_PRODUCT_SUCCESS: "inquiries/GET_PRODUCTS_PRODUCT_SUCCESS",

  // SEARCH_CLIENTS: "inquiries/SEARCH_CLIENTS",
  // SEARCH_CLIENTS_SUCCESS: "inquiries/SEARCH_CLIENTS_SUCCESS",

  // FILTER_CLIENTS_DATES: "inquiries/FILTER_CLIENTS_DATES",
  // FILTER_CLIENTS_DATES_SUCCESS: "inquiries/FILTER_CLIENTS_DATES_SUCCESS"
}

export const getProductsProduct = (payload) => ({
  type: InquiriesTypes.GET_PRODUCTS_PRODUCT,
  payload,
})

export const deleteProformInvoice = (payload) => ({
  type: InquiriesTypes.DELETE_PROFORM_INVOICE,
  payload,
})

export const editProformInvoice = (payload) => ({
  type: InquiriesTypes.EDIT_PROFORM_INVOICE,
  payload,
})

export const editProformInvoiceEn = (payload) => ({
  type: InquiriesTypes.EDIT_PROFORM_INVOICE_EN,
  payload,
})

export const createProformInvoice = (payload) => ({
  type: InquiriesTypes.CREATE_PROFORM_INVOICE,
  payload,
})

export const createProformInvoiceEn = (payload) => ({
  type: InquiriesTypes.CREATE_PROFORM_INVOICE_EN,
  payload,
})

export const deleteInvoiceDebit = (payload) => ({
  type: InquiriesTypes.DELETE_INVOICE_DEBIT,
  payload,
})

export const deleteInvoiceCredit = (payload) => ({
  type: InquiriesTypes.DELETE_INVOICE_CREDIT,
  payload,
})

export const editInvoiceDebit = (payload) => ({
  type: InquiriesTypes.EDIT_INVOICE_DEBIT,
  payload,
})

export const editInvoiceCredit = (payload) => ({
  type: InquiriesTypes.EDIT_INVOICE_CREDIT,
  payload,
})

export const changeInvoiceStatus = (payload) => ({
  type: InquiriesTypes.CHANGE_INVOICE_STATUS,
  payload,
})

export const addInvoiceDebit = (payload) => ({
  type: InquiriesTypes.ADD_INVOICE_DEBIT,
  payload,
})

export const addInvoiceCredit = (payload) => ({
  type: InquiriesTypes.ADD_INVOICE_CREDIT,
  payload,
})

export const deleteInquiry = (payload) => ({
  type: InquiriesTypes.DELETE_INQUIRY,
  payload,
})

export const deleteInquirySuccess = (payload) => ({
  type: InquiriesTypes.DELETE_INQUIRY_SUCCESS,
  payload,
})

export const updateInquiry = (payload) => ({
  type: InquiriesTypes.UPDATE_INQUIRY,
  payload,
})

export const updateInquirySuccess = (payload) => ({
  type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
  payload,
})

export const addActions = (payload) => ({
  type: InquiriesTypes.ADD_ACTIONS,
  payload,
})

export const addActionsSuccess = (payload) => ({
  type: InquiriesTypes.ADD_ACTIONS_SUCCESS,
  payload,
})

export const deleteComment = (payload) => ({
  type: InquiriesTypes.REMOVE_COMMENT,
  payload,
})

export const deleteCommentSuccess = (payload) => ({
  type: InquiriesTypes.REMOVE_COMMENT_SUCCESS,
  payload,
})

export const updateComment = (payload) => ({
  type: InquiriesTypes.UPDATE_COMMENT,
  payload,
})

export const updateCommentSuccess = (payload) => ({
  type: InquiriesTypes.UPDATE_COMMENT_SUCCESS,
  payload,
})

export const addComment = (payload) => ({
  type: InquiriesTypes.ADD_COMMENT,
  payload,
})

export const addCommentSuccess = (payload) => ({
  type: InquiriesTypes.ADD_COMMENT_SUCCESS,
  payload,
})

export const changeWorkingOn = (payload) => ({
  type: InquiriesTypes.CHANGE_WORKING_ON,
  payload,
})

export const changeWorkingOnSuccess = (payload) => ({
  type: InquiriesTypes.CHANGE_WORKING_ON_SUCCESS,
  payload,
})

export const addDocumentsToInquiry = (payload) => ({
  type: InquiriesTypes.ADD_DOCUMENTS_TO_INQUIRY,
  payload,
})

export const addDocumentsToInquirySuccess = (payload) => ({
  type: InquiriesTypes.ADD_DOCUMENTS_TO_INQUIRY_SUCCESS,
  payload,
})

export const addProductsToInquiry = (payload) => ({
  type: InquiriesTypes.ADD_PRODUCTS_TO_INQUIRY,
  payload,
})

export const addProductsToInquirySuccess = (payload) => ({
  type: InquiriesTypes.ADD_PRODUCTS_TO_INQUIRY_SUCCESS,
  payload,
})

export const createInquiry = (payload) => ({
  type: InquiriesTypes.CREATE_INQUIRY,
  payload,
})

export const createInquirySuccess = (payload) => ({
  type: InquiriesTypes.CREATE_INQUIRY_SUCCESS,
  payload,
})

export const addZone = (payload) => ({
  type: InquiriesTypes.CREATE_ZONE,
  payload,
})

export const getZones = () => ({
  type: InquiriesTypes.GET_ZONES,
})

export const getInquiries = (payload) => ({
  type: InquiriesTypes.GET_INQUIRIES,
  payload,
})

export const getInquiriesSuccess = (payload) => ({
  type: InquiriesTypes.GET_INQUIRIES_SUCCESS,
  payload,
})

export const getInquiry = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY,
  payload,
})

export const getInquirySuccess = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_SUCCESS,
  payload,
})

//offers

export const getInquiryOffers = (payload) => ({
  type: InquiriesTypes.GET_ALL_OFFERS,
  payload,
})

export const getInquiryOffersSuccess = (payload) => ({
  type: InquiriesTypes.GET_ALL_OFFERS_SUCCESS,
  payload,
})

export const searchInquiryOffers = (payload) => ({
  type: InquiriesTypes.SEARCH_INQUIRIES_OFFERS,
  payload,
})

export const searchInquiryOffersSuccess = (payload) => ({
  type: InquiriesTypes.SEARCH_INQUIRIES_OFFERS_SUCCESS,
  payload,
})

export const searchInquiryProduction = (payload) => ({
  type: InquiriesTypes.SEARCH_INQUIRIES_PRODUCTION,
  payload,
})

export const searchInquiryProductionSuccess = (payload) => ({
  type: InquiriesTypes.SEARCH_INQUIRIES_PRODUCTION_SUCCESS,
  payload,
})

export const filterInquiryDatesOffers = (payload) => ({
  type: InquiriesTypes.FILTER_INQUIRIES_OFFERS,
  payload,
})

export const filterInquiryDatesOffersSuccess = (payload) => ({
  type: InquiriesTypes.FILTER_INQUIRIES_OFFERS_SUCCESS,
  payload,
})

//sales
export const getInquirySales = (payload) => ({
  type: InquiriesTypes.GET_ALL_SALES,
  payload,
})

export const getInquirySalesSuccess = (payload) => ({
  type: InquiriesTypes.GET_ALL_SALES_SUCCESS,
  payload,
})

export const searchInquirySales = (payload) => ({
  type: InquiriesTypes.SEARCH_SALES,
  payload,
})

export const searchInquirySalesSuccess = (payload) => ({
  type: InquiriesTypes.SEARCH_SALES_SUCCESS,
  payload,
})

export const createInquiryChecklist = (payload) => ({
  type: InquiriesTypes.CREATE_CHECKLIST,
  payload,
})

export const createInquiryChecklistSuccess = (payload) => ({
  type: InquiriesTypes.CREATE_CHECKLIST_SUCCESS,
  payload,
})

export const createInquiryStatus = (payload) => ({
  type: InquiriesTypes.CREATE_INQUIRY_STATUS,
  payload,
})

export const createInquiryStatusSuccess = (payload) => ({
  type: InquiriesTypes.CREATE_INQUIRY_STATUS_SUCCESS,
  payload,
})

export const getInquiryStatuses = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_STATUSES,
  payload,
})

export const getInquiryStatusesSuccess = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_STATUSES_SUCCESS,
  payload,
})

export const getInquiryExpenseMachine = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_EXPENSE_MACHINE,
  payload,
})

export const getInquiryExpenseMachineSuccess = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_EXPENSE_MACHINE_SUCCESS,
  payload,
})

export const getInquiryExpenseEndProduct = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_EXPENSE_END_PRODUCT,
  payload,
})

export const getInquiryExpenseEndProductSuccess = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_EXPENSE_END_PRODUCT_SUCCESS,
  payload,
})

export const getInquiryDefectives = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_DEFECTIVES,
  payload,
})

export const getInquiryDefectivesSuccess = (payload) => ({
  type: InquiriesTypes.GET_INQUIRY_DEFECTIVES_SUCCESS,
  payload,
})

// clients
// export const searchInquiryClients = (payload) => ({
//   type: InquiriesTypes.SEARCH_CLIENTS,
//   payload,
// })

// export const searchInquiryClientsSuccess = (payload) => ({
//   type: InquiriesTypes.SEARCH_CLIENTS_SUCCESS,
//   payload,
// })

// export const filterInquiryDatesClients = (payload) => ({
//   type: InquiriesTypes.FILTER_CLIENTS_DATES,
//   payload,
// })

// export const filterInquiryDatesClientsSuccess = (payload) => ({
//   type: InquiriesTypes.FILTER_CLIENTS_DATES_SUCCESS,
//   payload,
// })
