import { useEffect, useState } from "react";
import Files from "./files";

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("")
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [permissionsCheck, setPermissions] = useState(true)

  useEffect(() => {
    // Obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error)
      }
      return
    }
    // Manage recorder state.
    if (isRecording) {
      recorder.start()
    } else {
      recorder.stop()
    }
    // Obtain the audio when ready.
    const handleData = (e) => { 
      let exportURL = e.data

      Files.uploadFile(e.data).then((data) => {
        exportURL = data.location

        if (data.location.length !== 0) {
          setAudioURL({
            data: data.location,
            type: e,
            name: e.name,
          })
        }
      })
    }
    recorder.addEventListener("dataavailable", handleData)
    return () => recorder.removeEventListener("dataavailable", handleData)
  }, [recorder, isRecording])

  
  const startRecording = () => {
    setIsRecording(true)
  }
  const stopRecording = () => {
    setIsRecording(false)
  }

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({audio: true, video: false})
    permissions.then((stream) => {
      window.localStream = stream;
        window.localAudio.srcObject = stream;
        window.localAudio.autoplay = true;

      // alert('accepted the permissions');
      // setPermissions(true)
    })
    .catch((err) => {
      setPermissions(false)
      console.log(`${err.name} : ${err.message}`)
    });
  }

  return [audioURL, isRecording, startRecording, stopRecording, checkPermissions, permissionsCheck]
}

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  return new MediaRecorder(stream, { type: "audio/webm" })
}

export default useRecorder;
