import React, { useEffect, useState } from "react"
import moment from "moment";
import './styles.scss';
import { getProductName } from "../../utilities/helpers";
import { TableHeaderInquiry, InquiryExpenseElement } from "../../components"
import { showLoading, getInquiryExpenseMachine, getInquiryExpenseEndProduct } from "../../actions"
import _ from "lodash"
import InquiriesHeader from "../InquiriesClientsComponent/InquiriesHeader";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { FilterPopup } from '../../components';
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts";

const headerElementsMachine = [
  {
    name: "Период",
    size: 2,
    fixedWidth: 150,
    filter: true,
    sort: false
  },
  {
    name: "Машина",
    size: 1.3,
    fixedWidth: 120,
    filter: true,
    sort: false
  },
  {
    name: "Продукт",
    size: 2,
    fixedWidth: 150,
    filter: true,
    sort: false
  },
  {
    name: "Себестойност",
    size: 7,
    fixedWidth: 450,
    filter: true,
    sort: false
  },
]
const headerElementsEndProduct = [
    {
    name: "Период",
    size: 2,
    fixedWidth: 150,
    filter: true,
    sort: false
  },
  {
    name: "Работник",
    size: 1.3,
    fixedWidth: 120,
    filter: true,
    sort: false
  },
  {
    name: "Продукт",
    size: 2,
    fixedWidth: 150,
    filter: true,
    sort: false
  },
  {
    name: "Себестойност",
    size: 7,
    fixedWidth: 450,
    filter: true,
    sort: false
  },
]

const InquiriesExpenseComponent = () => {
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState(true);
  const [headerElements, setHeaderElements] = useState([]);
  const [selectedSortValue, setSelectedSortValue] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("machines");
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [areDatesSelected, setAreDatesSelected] = useState(false);
  const [ isFilterActive, setIsFilterActive ] = useState(false);
  const [ sortTypes, setSortTypes ] = useState([]);
  const [ performersList, setPerformersList ] = useState([]);
  const [ expenseData, setExpenseData ] = useState([]);
  const [ totalExpenseFiltered, setTotalExpenseFiltered ] = useState(0);
  const expenseInnerRef = useRef();

  const [ filtersPayload, setFiltersPayload ] = useState({
    machines: [],
    performers: []
  })
  

  const expenseDataMachine = useSelector(state => state.inquiries.inquiriesExpenseMachine);
  const expenseDataEndProduct = useSelector(state => state.inquiries.inquiriesExpenseEndProduct);
  const totalExpense = useSelector(state => state.inquiries.totalExpense);

  // const filterOptions = [{label: 'Име на клиент', value: 'client'}, {label: 'Обект', value: 'object'}];
  
  const filterOptions = {
    'machines': 'Себестойност машини',
    'endProduct': 'Себестойност краен продукт'
  }

  const selectedSortValues = {
    'Период': 'date',
    'Машина': 'machine',
    'Работник': 'performer',
    'Продукт': 'product',
    'Себестойност': 'expense',
  }

  let machinesList = [ 'OD800', 'OD2000', 'OD2400' ]

  let sortTypesMachines = [ 
    { name: 'Виж всички', value: 'all-machines'}, 
    { name: 'OD800', value: 'OD800'},
    { name: 'OD2000', value: 'OD2000'},
    { name: 'OD2400', value: 'OD2400'} 
 ]

  let sortTypesEndProduct = [ 
    { name: 'Виж всички', value: 'all-performers'}
 ]

  const dispatch = useDispatch();

  const handleDatesFilter = () => {
    if (moment(startDate).startOf("day").isAfter(moment(endDate).startOf("day"))) {
      return Alerts.error("Зададената начална дата на търсене е след крайната дата!")
    }

    expenseInnerRef.current && expenseInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    setAreDatesSelected(true);

    if (searchValue === 'machines') {
      let searchPayload = { start: moment(startDate), to: moment(endDate) }
      dispatch(showLoading())
      dispatch(getInquiryExpenseMachine(searchPayload))
    } 
    else if (searchValue === 'endProduct') {
      let start = moment(startDate).clone()
      let dates = []

      while (start.isSameOrBefore(endDate)) {
        dates.push(start.format("DD.MM.YYYY"))
        start.add(1, "days")
      }

      let newData = []
      newData = expenseDataEndProduct.filter((invoice) =>
      dates.includes(moment(invoice.start).format("DD.MM.YYYY"))
    )

      setExpenseData(newData)

      let newTotalExpense = 0
      newTotalExpense = newData.reduce((acc, curr) => {
       return (acc?.machineCostPrice || 0) + (curr?.machineCostPrice || 0)
      }, 0)

      setTotalExpenseFiltered(newTotalExpense)

      return newData
      // return expenseData
    }
  }

  const handleFilters = (payload) => {
    if (!Object.values(payload)?.length) return
    let newData = JSON.parse(JSON.stringify(expenseData))

    if (searchValue === 'machines') {
      if (payload?.machines?.length) {
        newData = expenseData.filter(el => payload.machines.includes(el.machineName))
      }
      else {
        dispatch(getInquiryExpenseMachine({ start: moment().subtract(1, 'month'), end: moment() }))
      }
    }
    else if (searchValue === 'endProduct') {
      if (payload?.performers?.length) {
        newData = newData.filter(obj =>
          payload.performers.some(name => obj.performers.includes(name))
        );
      }
      else {
        dispatch(getInquiryExpenseEndProduct())
      }

      let newTotalExpense = 0
      newTotalExpense = newData.reduce((acc, curr) => {
       return (acc?.machineCostPrice || 0) + (curr?.machineCostPrice || 0)
      }, 0)

      setTotalExpenseFiltered(newTotalExpense)
    }
    setExpenseData(newData)
  }

  const resetFilters = () => {
    if (searchValue === 'machines') {
      dispatch(getInquiryExpenseMachine({ start: moment().subtract(1, 'month'), end: moment() }))
    } 
    else if (searchValue === 'endProduct') {
      dispatch(getInquiryExpenseEndProduct())
    }
  }

  const handleSort = (selectedSortValue) => {
    // let sorted = searchValue === 'machines' ? expenseDataMachine.slice(0) : expenseDataEndProduct.slice(0)
    let sorted = expenseData.slice(0)

    switch (selectedSortValue) {
      case "Период":
        sorted.sort((a, b) => {     
          return sort
            ? moment(a.start) - moment(b.start)
            : moment(b.start) - moment(a.start)
        })
        break;

      case "Машина":
        sorted.sort((a, b) => {
          return sort
          ? a.machineName.localeCompare(b.machineName)
          : b.machineName.localeCompare(a.machineName)
        })
        break;

      case "Работник":
        sorted.sort((a, b) => {
          return sort
            ? a.performers[0].localeCompare(b.performers[0])
            : b.performers[0].localeCompare(a.performers[0])
        })
        break;

      case "Продукт":
        sorted.sort((a, b) => {
            let productA = getProductName(a?.basicInfo)
            let productB = getProductName(b?.basicInfo)
          return sort
            ? productA.localeCompare(productB)
            : productB.localeCompare(productA)
        })
        break;

      case "Себестойност":
        sorted.sort((a, b) => {
          let numA = Number(a.machineCostPrice)
          let numB = Number(b.machineCostPrice)
          return sort ? numA - numB : numB - numA
        })
        break;
    }
    setExpenseData(sorted)
  }

  useEffect(() => {
    dispatch(showLoading())
    dispatch(getInquiryExpenseMachine({ start: moment().subtract(1, 'month'), end: moment() })) //default should be 1 month
  }, []);

  useEffect(() => {
    if (searchValue === 'machines') {
        dispatch(getInquiryExpenseMachine({ start: moment().subtract(1, 'month'), end: moment() }))
        setHeaderElements(headerElementsMachine)
        setSortTypes(sortTypesMachines)
        setExpenseData(expenseDataMachine)
        setTotalExpenseFiltered(totalExpense)
    }
    else if (searchValue === 'endProduct') {
        dispatch(getInquiryExpenseEndProduct())
        setHeaderElements(headerElementsEndProduct)

        if (expenseData?.length && expenseData[0]?.performers) {
            let newPerformers = expenseData.map(el => el.performers)
            newPerformers = [].concat(...newPerformers)  // [el1, el2, el3]
            setPerformersList(newPerformers)

            newPerformers.map((el, index) => {
             return sortTypesEndProduct.push({ name: el, value: el })
            })

            let newTotalExpense = 0
            newTotalExpense = expenseData.reduce((acc, curr) => {
             return (acc || 0) + (curr?.machineCostPrice || 0)
            }, 0)

            handleDatesFilter()
            setSortTypes(sortTypesEndProduct)
            setTotalExpenseFiltered(newTotalExpense)
        }

        setExpenseData(expenseDataEndProduct)
        // { name: 'Виж всички', value: 'all-performers'}
    }
  }, [searchValue, startDate, endDate]);

  useEffect(() => {
    if (searchValue === 'machines') {
        setExpenseData(expenseDataMachine)
        setTotalExpenseFiltered(totalExpense)
    }
    else if (searchValue === 'endProduct') {
      if (expenseData?.length && expenseData[0]?.performers) {
        let newPerformers = expenseData.map(el => el.performers)
        newPerformers = [].concat(...newPerformers)  // [el1, el2, el3]
        newPerformers = newPerformers.filter((item, index) => newPerformers.indexOf(item) === index)
        setPerformersList(newPerformers)

        newPerformers.map((el, index) => {
         return sortTypesEndProduct.push({ name: el, value: el })
        })

        let newTotalExpense = 0
        newTotalExpense = expenseData.reduce((acc, curr) => {
         return (acc || 0) + (curr?.machineCostPrice || 0)
        }, 0)

        setTotalExpenseFiltered(newTotalExpense)
    }
        // setExpenseData(expenseDataEndProduct)
        setExpenseData(expenseDataEndProduct)
        setSortTypes(sortTypesEndProduct)
        handleDatesFilter()
    }

    setTotalExpenseFiltered(totalExpense)
  }, [expenseDataMachine, expenseDataEndProduct, totalExpense]);

  useEffect(() => {
    if (searchValue === 'machines') {
      setTotalExpenseFiltered(totalExpense)
    }
     if (searchValue === 'endProduct') {
      if (expenseData?.length && expenseData[0]?.performers) {
        let newPerformers = expenseData.map(el => el.performers)
        newPerformers = [].concat(...newPerformers)  // [el1, el2, el3]
        newPerformers = newPerformers.filter((item, index) => newPerformers.indexOf(item) === index)
        setPerformersList(newPerformers)

        newPerformers.map((el, index) => {
         sortTypesEndProduct.push({ name: el, value: el })
        })

        let newTotalExpense = 0
        newTotalExpense = expenseData.reduce((acc, curr) => {
         return (acc || 0) + (curr?.machineCostPrice || 0)
        }, 0)

        setTotalExpenseFiltered(newTotalExpense)
        setSortTypes(sortTypesEndProduct)
    }
    }
  }, [expenseData, searchValue]);

  return (
    <div className="inquiries-clients-component expense">
      <InquiriesHeader 
        headerElements={headerElements}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={filterOptions}
        startDate={startDate}
        endDate={endDate}
        tab="expense"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleDatesFilter={handleDatesFilter}
        areDatesSelected={areDatesSelected}
        setAreDatesSelected={setAreDatesSelected}
        selectedSortValue={selectedSortValue}
        setSelectedSortValue={setSelectedSortValue}
        sort={sort}
        setSort={setSort}
        filtersPayload={filtersPayload}
        setFiltersPayload={(value) => setFiltersPayload(value)}
        isFilterActive={isFilterActive}
        setIsFilterActive={(value) => setIsFilterActive(value)}
         />

      {expenseData && <TableHeaderInquiry
        elements={headerElements}
        justifyContent="flex-start"
        handleSort={handleSort}
        sort={sort}
        setSort={setSort}
        selectedSortValue={selectedSortValue}
        setSelectedSortValue={setSelectedSortValue}
        tab='sales'
      />}
      <div className={`scroll-container ${expenseData?.length ? '': 'empty-state'}`} ref={expenseInnerRef}>

        {expenseData?.length ? expenseData?.map((rowData, i) => {
            return <InquiryExpenseElement key={(rowData?.refNumber || 5) * i} data={rowData} type={searchValue} />
          }) :
          <p>Няма налични справки за този период</p>}

      </div> 

      <div className="bottom-label-container">
        <div className="text">Обща себестойност: <span>{`${totalExpenseFiltered || 0} лв.`}</span></div>
      </div>

      {isFilterActive && 
      <FilterPopup 
      type="inquiries" 
      sortTypes={sortTypes} 
      hide={() => setIsFilterActive(false)} 
      filtersPayload={filtersPayload} 
      setFiltersPayload={(value) => setFiltersPayload(value)} 
      list={searchValue === 'machines' ? machinesList : performersList} 
      setFilters={(payload) => handleFilters(payload)} 
      resetFiltersInquiry={resetFilters} />}

      {/* {isFilterActive && <FilterPopup data={} hide={() => setIsFilterActive(false)} setFilters={} filtersPayload={filtersPayload} setFiltersPayload={(value) => setFiltersPayload(value)} />} */}
    </div>
  )
}

export default InquiriesExpenseComponent;
