import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import ProductionPipes from './ProductionPipes/ProductionPipes'
import './Production.scss'
import { connectSocket, disconnectSocket } from '../../actions'
import { capitalizeFirstLetter } from '../../utilities/helpers'
import { useSelector, useDispatch } from 'react-redux'
import Daily from './Daily/Daily'
import WeeklyMonthly from './WeeklyMonthly/WeeklyMonthly'
import { getProductionRequests, getPerformers } from '../../actions/production'
import ProductionRequest from '../../components/ProductionRequest/ProductionRequest'
import RequestDropdown from '../../components/RequestDropdown/RequestDropdown'
import { Section } from '@makedonski/admin-ui'
import { CheckListPopup } from '../../components/CheckListPopup'

export default function Production () {
  const fileIcon = require('../../assets/icons/fileIcon.svg')
  const calendarIcon = require('../../assets/icons/calendarIcon.svg')
  const [isRequestTabActive, setIsRequestTabActive] = useState(false)
  const [isRequestDropdownActive, setIsRequestDropdownActive] = useState(false)
  const [date, setDate] = useState(moment().startOf('day'))
  const [requestType, setRequestType] = useState('Тръби - машини')
  const [selectedTab, setSelectedTab] = useState('Дневен')

  const dispatch = useDispatch()

  const requestData = useSelector(state => state.production.productSchedule)
  const data = useSelector(state => state.production.data)
  const performers = useSelector((state) => state.production.performers)
  let popupRef = useRef()

  const weekStart = moment().startOf('week').format('DD')
  const weekEnd = moment().endOf('week').format('DD')
  const month = moment().format('MMMM')

  useEffect(() => {
    dispatch(getProductionRequests())
  }, [])

  const renderCurrentPeriod = (period) => {
    switch (period) {
      case 'Дневен':
        return (<Daily requestType={requestType} onSetDate={date => setDate(date)} />)
      case 'Седмичен':
        return (<WeeklyMonthly requestType={requestType} selectedTab={selectedTab} onSetDate={date => setDate(date)} />)
      case 'Месечен':
        return (<WeeklyMonthly requestType={requestType} selectedTab={selectedTab} onSetDate={date => setDate(date)} />)
      default:
        break
    }
  }

  const renderWeekDate = (date) => {
    let weekStart = moment(date).startOf('week').format('DD')
    let weekEnd = moment(date).endOf('week').format('DD')
    
    if (moment(date).startOf('week').format('MMMM') !== 
    moment(date).endOf('week').format('MMMM')) {
      return weekStart + ' - ' + weekEnd + ' ' + 
      (capitalizeFirstLetter(moment(date).clone().add(1, 'month').format('MMMM')))
    }

    return weekStart + ' - ' + weekEnd + ' ' + 
           (capitalizeFirstLetter(moment(date).format('MMMM')))
  }

  return (
    <div className='production-container'>
      {isRequestTabActive &&
        <ProductionRequest
          data={data} isRequestTabActive={isRequestTabActive}
          setIsRequestTabActive={setIsRequestTabActive}
        />}
      <div className='production-top-container'>
        <ProductionPipes onSetRequestType={type => setRequestType(type)} />
        <div className='production-wrapper'>
          <div className='current-date'>
            <img src={calendarIcon} alt='calendar' />
            {selectedTab === 'Дневен' && `${capitalizeFirstLetter(moment(date).format('DD MMMM'))} - ${capitalizeFirstLetter(moment(date).format('dddd'))}`}
            {selectedTab === 'Седмичен' && renderWeekDate(date)}
            {selectedTab === 'Месечен' && (capitalizeFirstLetter(moment(date).format('MMMM')))}
          </div>
          <div className='date-type-wrapper'>
            <ul className='date-type-wrapper-li'>
              <li onClick={() => setSelectedTab('Дневен')} className={selectedTab === 'Дневен' ? 'selected-tab' : ''}>Дневен</li>
              <li onClick={() => setSelectedTab('Седмичен')} className={selectedTab === 'Седмичен' ? 'selected-tab' : ''}>Седмичен</li>
              <li onClick={() => setSelectedTab('Месечен')} className={selectedTab === 'Месечен' ? 'selected-tab' : ''}>Месечен</li>
            </ul>
          </div>
          <div className='file-buttons-wrapper'>
            <button
              className='request-secondary-btn'
              onClick={() => setIsRequestTabActive(!isRequestTabActive)}
            >
              Всички заявки
            </button>
            <button
              className='request-primary-btn'
              onClick={() => setIsRequestDropdownActive(!isRequestDropdownActive)}
            >
              Нова заявка
            </button>
          </div>
          {isRequestDropdownActive && <RequestDropdown />}
        </div>
      </div>
      {renderCurrentPeriod(selectedTab)}
    </div>

  )
}
