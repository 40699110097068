import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { connect } from 'react-redux'
import { showModal, getSpecificSettings } from '../../actions'
import { TableHeader } from '..'
import { Request } from './Request'
import moment from 'moment'
import './styles.scss'

const headerElements = [
  { name: 'Суровина', size: 4 },
  { name: 'К-во', size: 1.3 },
  { name: 'Срок', size: 1 },
  { name: 'Проекти', size: 1.5 }
]

const RequestsComponent = ({
  showModalAction,
  onSetEditSchedule,
  chosenSchedule,
  getSpecificSettings,
  data
}) => {
  const finished = data
    .filter((el) => el.status === 'finished')
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })
  const inProduction = data
    .filter((el) => el.status === 'in-production')
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })
  const pending = data
    .filter((el) => {
      return (
        el.status === 'pending' &&
        moment(el.createdAt).isAfter(
          moment('14.10.2021', 'DD.MM.YYYY').endOf('day')
        )
      )
    })
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })
  const sortedData = [...pending, ...inProduction, ...finished]
  return (
    <div className='requests-container'>
      <div className='top-container'>
        <h1>Заявки</h1>
        <Button.Icon
          name='plus'
          size={14}
          color='blue'
          className=''
          iconStyles=''
          onClick={() =>
            showModalAction({
              type: 'productionRequestModal',
              width: '600px',
              overflow: 'visible'
            })}
        />
      </div>
      <TableHeader elements={headerElements} justifyContent='flex-start' />
      <div className='scroll-container'>
        {sortedData.map((item) => {
          let status = item.status
          if (
            item.product &&
            item.product.productType.name !== 'Тръба' &&
            item.status !== 'finished'
          ) {
            const index = item.finishingWorks.findIndex(
              (el) => el.state === 'work-in-proggress'
            )
            if (index !== -1) {
              status = 'partially'
            }
          }
          return (
            <Request
              disabled={chosenSchedule ? chosenSchedule._id !== item._id : true}
              status={status}
              item={item}
              onClick={() => {
                if (item.product) {
                  getSpecificSettings({ product: item.product._id })
                }
                onSetEditSchedule(item)
              }}
            />
          )
        })}
        {/* <Request
          disabled={chosenSchedule && chosenSchedule.name !== 'nqkvo ime'}
          status="finished"
          onClick={() => onSetEditSchedule({ name: 'nqkvo ime' })}
        />
        <Request
          disabled={chosenSchedule && chosenSchedule.name !== 'vtoro ime'}
          status="production"
          onClick={() => onSetEditSchedule({ name: 'vtoro ime' })}
        /> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  showModalAction: (payload) => dispatch(showModal(payload)),
  getSpecificSettings: (payload) => dispatch(getSpecificSettings(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestsComponent)
