import { Avatar, Paper } from "@mui/material"
import React, { useEffect, useState } from "react"
import styles from "./ProjectCard.module.scss"
import moment from "moment"
import { last } from "lodash"

export default function ProjectCard({
  additionalStatuses = [],
  // budget = 0,
  budget,
  client,
  createdAt,
  invoices,
  proform_invoices,
  offers,
  status,
  searchQuery,
  query,
  refNumbers,
  object,
  lastActivity,
  assignee,
  actsofacceptance,
  zones = [],
  assignees
}) {
  const [price, setPrice] = useState(0)
  const [open, setOpen] = useState(true)
  useEffect(() => {
    // setPrice(Number(budget.replace(/[\W,. ]+/g, "").trim()))
    setPrice(budget)
  }, [])

  return (
    <Paper elevation={2} className={styles["project-card-container"]}>
      
      <p>{`${object?.substr(0, 40)}${object.length > 40 ? "..." : ""}`}</p>
      <p>{`${client?.substr(0, 40)}${client.length > 40 ? "..." : ""}`}</p>
      <p>{price ? `${Number(price).toLocaleString()} лв.` : '--'}</p>
      <div className={styles.user}>
        <Avatar sx={{ width: 32, height: 32 }} src={assignee?.coverPhoto} />
        {assignees.length > 1 ?
        <div className={styles["assignees-wrapper"]}>
          <p>{assignee?.fullName}</p>
          {/* <span
          style={{ padding: "10px", background: "#E6EEF4" }}
          onClick={() => setOpen(!open)}>
            {`+ ${assignees?.length - 1}`}
          </span>
          {assignees.length > 1 && 
            <div className={styles["assignees-list-wrapper"]}>
              {assignees.map((worker, index) => {
                if (index !== 0) {
                  return <div className={styles["assignee"]}>{worker?.user?.fullName}</div>
                }
              })}
            </div>} */}
        </div> :
          <p>{assignee?.fullName}</p>}
      </div>
      <p>{moment(createdAt).format("DD/MM/YYYY")}</p>
      <p>
        {offers.length !== 0
          ? offers.length > 1
            ? `Да, (${(searchQuery === 'Номер на оферта' && refNumbers.find(num => {return num.includes(query)}) !== undefined) ? refNumbers.find(num => {return num.includes(query)}) : offers[0].refNumber})`
            // ? `Да, (${searchQuery === 'Номер на оферта' ? refNumbers.find(num => {return num.includes(query)}) : last(offers).refNumber})`
            : `Да, (${offers[0].refNumber})`
          : "Не"}
      </p>
      <div className={styles["status-container"]}>
        <p className={styles["status-paragraph"]}>{status}</p>
        {additionalStatuses.length > 0 && (
          <span
            style={{
              padding: "10px",
              background: "#E6EEF4",
              color: "black",
              height: "100%",
            }}
          >
            {additionalStatuses.length > 0 && `+${additionalStatuses.length}`}
          </span>
        )}
      </div>
      <div className={styles.zones}>
        {zones?.length > 0 ? <span> {zones[0]?.name}</span> : <p>Няма</p>}
        {zones?.length > 1 && (
          <span style={{ padding: "10px", background: "#E6EEF4" }}>
            {zones?.length > 1 && `+ ${zones?.length - 1}`}
          </span>
        )}
      </div>
      <p>
        {proform_invoices.length !== 0
          ? `Да(${proform_invoices?.length})`
          : "Не"}
      </p>
      <p>{invoices.length !== 0 ? `Да(${invoices?.length})` : "Не"}</p>
      {/* <p>{invoices.length !== 0 ? `Да(${invoices?.length})` : "Не"}</p> */}
      <p>
        {actsofacceptance?.length !== 0
          ? `Да(${actsofacceptance?.length})`
          : "Не"}
      </p>
      <p>{moment(lastActivity).format("DD/MM/YYYY")}</p>
    </Paper>
  )
}

// quantity - Централен склад
// quantity 2 - Склад изток
// Основание - Вид продукт
