import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import S3FileUpload from "react-s3"
import { nanoid } from "nanoid"
import { useState, useEffect, useRef } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { GOOGLE_VISION_API_KEY } from "../config/settings"

export const emit = (socket, { action, ...rest } = {}) => {
  if (!socket) return
  const payload = { action, ...rest }
  console.log("Socket out: ", payload)
  socket.emit("socket-message-v2", payload)
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const useQuery = () => {
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(window.location.search)
  const handleUrlChange = (newValues = {}) => {
    Object.entries(newValues).forEach(([key, value]) => {
      if (!value) query.delete(key)
      else if (query.has(key)) query.set(key, value)
      else query.append(key, value)
    })

    history.replace({ ...location, search: "?" + query.toString() })
  }

  return {
    ...Array.from(query.keys()).reduce(
      (a, key) => ({ ...a, [key]: query.get(key) }),
      {}
    ),
    handleUrlChange,
  }
}

export const scale = (num, in_min, in_max, out_min, out_max) =>
  ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min

export const capitalizeFirstLetter = (word) => {
  return word[0].toUpperCase() + word.substring(1).toLowerCase()
}

export const getProductData = (basicInfo) => {
  const nameMap = {
    "Вътрешен диаметър": "ID",
    "Външен диаметър": "OD",
    Коравина: "SN",
  }

  return basicInfo
    ?.map((el) => {
      const name = nameMap[el.name] ? nameMap[el.name] : el.name
      return `${name}-${el.value}`
    })
    .join(" _ ")
}

export const getProductName = (basicInfo) => {
  const nameMap = {
    "Вътрешен диаметър": "ID",
    "Външен диаметър": "OD",
    Коравина: "SN",
  }

  let initials = basicInfo
    ?.map((el, i) => {
      if (
        el?.name === "Коравина" ||
        el?.name === "Вътрешен диаметър" ||
        el?.name === "Външен диаметър"
      ) {
        const name = nameMap[el.name] || ""
        return `${name}${el?.value}`
      } else if (el?.name === "Вид") {
        return `${el.value}`
      }
    })
    .join("_")

  return initials?.substring(1, initials?.length - 3)
}

export const getProductNameKMU = (basicInfo) => {
  const nameMap = {
    Номенклатура:
      basicInfo.find((el) => el.name === "Номенклатура")?.value || "",
    NS: basicInfo.find((el) => el.name === "NS")?.value || "",
    SF: basicInfo.find((el) => el.name === "SF")?.value || "",
    H: basicInfo.find((el) => el.name === "DN тяло 1")?.value || "", //H/L
    L: basicInfo.find((el) => el.name === "DN тяло 1")?.value || "", //H/L
    ID: basicInfo.find((el) => el.name === "ID/OD")?.value || "",
    OD: basicInfo.find((el) => el.name === "ID/OD")?.value || "",
    "Ревизионен отвор": basicInfo.find((el) => el.name === "H")?.value || "",
    "ВХ/ИЗХ": basicInfo.find((el) => el.name === "ВХ/ИЗХ")?.value || "",
    Капак: basicInfo.find((el) => el.name === "ВХ/ИЗХ")?.value || "",
  }

  let initials = basicInfo
    ?.map((el, i) => {
      if (
        el?.name !== "Номенклатура" &&
        el?.name !== "Ориентация 1" &&
        el?.name !== "Ориентация 2" &&
        el?.name !== "Тежест на единица" &&
        el?.name !== "DN тяло 1" &&
        el?.name !== "DN тяло 2" &&
        el?.name !== "Вертикална"
      ) {
        return `${el.name}_${el?.value}`
      } else if (el?.name === "Номенклатура") {
        return el?.value
      } else if (el?.name === "DN тяло 1" || el?.name === "DN тяло 2") {
        return `${el.units}_${el?.value}`
      }

      return ``
    })
    .join("_")

  return initials
}

export const checkBasicInfo = (basicInfo) => {
  if (basicInfo.length < 11) return false

  let basicInfoCheck = basicInfo.slice()
  basicInfoCheck = basicInfoCheck.filter(
    (info) =>
      info.value === "" || info.value === null || info.value === undefined
  )

  if (basicInfoCheck.length) return false
  else return true
}

const errorsMap = {
  expense: "Разход",
  machine: "Машина",
  quantity: "Количество",
  start: "Начало",
  end: "Край",
  deadline: "Краен срок",
  duration: "Времетраене",
  materials: "Материали",
  comment: "Коментар",
  activity: "Дейност",
  schedule: "График",
  ratio: "Съотношение",
  "materials -> name": "Материали",
  "materials -> ratio": "Материали",
  innerDiameter: "Вътрешен диаметър мм",
  caliber: "Калибър мм",
  stiffnes: "Коравина SN",
  vacuum: "Вакуум",
  current: "Двигател ток А",
  profiles: "Профили",
  performers: "Изпълнители",
  revolutions: "Двигател оɗ/min",
  windingSystem: "Навиваща система честота Hz",
  finishingWorks: "Дейност/дейности",
  injector: "Дюза",
  min: "Циркуляр за рязане - мин",
  move: "Циркуляр за рязане - ход",
}

export const validateObjectValues = (
  object,
  objectNestingText,
  previousKey = null
) => {
  const errorsArray = []
  object &&
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        if (!object[key].length) {
          // errorsArray.push(`${previousKey ? `${errorsMap[previousKey] ? errorsMap[previousKey] : previousKey}` : ''}`)
          errorsArray.push(
            `${
              previousKey
                ? `${
                    errorsMap[previousKey]
                      ? errorsMap[previousKey]
                      : previousKey
                  } -> `
                : ""
            }${errorsMap[key] ? errorsMap[key] : key}`
          )
        } else {
          object[key].forEach((arrayElement) => {
            if (typeof arrayElement === "object") {
              Object.keys(arrayElement).forEach((elementKey) => {
                if (!arrayElement[elementKey]) {
                  errorsArray.push(`${errorsMap[key] ? errorsMap[key] : key}`)
                  // errorsArray.push(`${errorsMap[key] ? errorsMap[key] : key} -> ${errorsMap[elementKey] ? errorsMap[elementKey] : elementKey}`)
                }
              })
            } else {
              if (
                !arrayElement &&
                errorsArray.findIndex((el) => el === key) === -1
              ) {
                // errorsArray.push(`${previousKey ? `${errorsMap[previousKey] ? errorsMap[previousKey] : previousKey}` : ''}`)
                errorsArray.push(
                  `${
                    previousKey
                      ? `${
                          errorsMap[previousKey]
                            ? errorsMap[previousKey]
                            : previousKey
                        } -> `
                      : ""
                  }${errorsMap[key] ? errorsMap[key] : key}`
                )
              }
            }
          })
        }
      } else if (typeof object[key] === "object") {
        if (!object[key]?._isValid) {
          let newErrors
          if (objectNestingText) {
            newErrors = validateObjectValues(
              object[key],
              objectNestingText,
              key
            )
          } else {
            newErrors = validateObjectValues(object[key], objectNestingText)
          }
          newErrors.errorsArray.forEach((error) => {
            if (errorsArray.findIndex((el) => el === error) === -1) {
              errorsArray.push(error)
            }
          })
        }
      } else {
        if (!object[key]) {
          // errorsArray.push(`${previousKey ? `${errorsMap[previousKey] ? errorsMap[previousKey] : previousKey}` : ''}`)
          errorsArray.push(
            `${
              previousKey
                ? `${
                    errorsMap[previousKey]
                      ? errorsMap[previousKey]
                      : previousKey
                  } -> `
                : ""
            }${errorsMap[key] ? errorsMap[key] : key}`
          )
        }
      }
    })
  return {
    error: errorsArray.length,
    errorsText: errorsArray.join(", "),
    errorsArray: errorsArray,
  }
}

export const isBudgetValid = (value) => {
  let reg = /^[0-9]+\.?([0-9]+)?$/g
  if (!reg.test(value) && value !== "") {
    return Alerts.error("Полето може да съдържа само цифри и точка")
  }
  return true

  // if (value.match(/[a-zA-Z\s,!@#$\^&*\(\)\+\{\}\|\[\]\\\;\'\"\?\<\>\`_-]+/g)) {
  //   return Alerts.error("Полето може да съдържа само цифри и точка")
  // }
  // return true
}

export const base64toFile = (base64Data, fileName) => {
  const byteCharacters = atob(getRawImageData(base64Data))
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++)
    byteNumbers[i] = byteCharacters.charCodeAt(i)

  const byteArray = new Uint8Array(byteNumbers)
  const file = new File([byteArray], nanoid(), {
    type: "image/jpg",
  })
  return file
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })

export async function getFileFromUrl(url, name = "test", defaultType) {
  const response = await fetch(url)
  const data = await response.blob()
  return new File([data], name, {
    type: data.type || defaultType,
  })
}

//cropper helpers
const TEXT_DETECTION_FEATURES = {
  type: "TEXT_DETECTION",
  maxResults: 10,
}

export const getRawImageData = (base64String) => {
  const prefixIndex = base64String.indexOf("base64,")
  if (prefixIndex !== -1) return base64String.substring(prefixIndex + 7)
  else return base64String
}

export const getVision = async (image, languageHint = "bg") => {
  try {
    const response = await window.fetch(
      `https://vision.googleapis.com/v1/images:annotate?key=${GOOGLE_VISION_API_KEY}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requests: [
            {
              features: TEXT_DETECTION_FEATURES,
              image: {
                content: image,
              },
              imageContext: {
                languageHints: [languageHint],
              },
            },
          ],
        }),
      }
    )

    const body = await response.json()
    return body
  } catch (err) {
    console.log(err)
    return null
  }
}
