import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import {
  ProductionTypes,
  GeneralTypes,
  hideModal,
  hideLoading,
  getInquiry
} from '../actions'
import { API_URL } from '../config/settings'

import { history } from '../config/stores'

export const createProductionRequest = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.POST_PRODUCTION_REQUEST),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/production/requests`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.POST_PRODUCTION_REQUEST_SUCCESS,
              payload: response.productionRequest
            })
            history.goBack()
            obs.next(
              getInquiry({ _id: response.productionRequest.inquiry._id })
            )
            obs.next(hideLoading())
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateProductionRequest = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.PUT_PRODUCTION_REQUEST),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/production/requests`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.PUT_PRODUCTION_REQUEST_SUCCESS,
              payload: response.productionRequest
            })
            obs.next(hideLoading())
            history.goBack()
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response?.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getProductionRequests = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_PRODUCTION_REQESTS),
    switchMap(() => {
      return Api.get(`${API_URL}/production/requests`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_PRODUCTION_REQESTS_SUCCESS,
              payload: response.productionRequests
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getProductionRequest = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_PRODUCTION_REQUEST),
    switchMap(({ payload }) => {
      return Api.get(`${API_URL}/production/requests/${ payload._id }`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_PRODUCTION_REQUEST_SUCCESS,
              payload: response.productionRequests
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getMachinesRequests = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_MACHINES),
    switchMap(() => {
      return Api.get(`${API_URL}/machines`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_MACHINES_SUCCESS,
              payload: response.machines
            })
            obs.next(hideLoading())

            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const endWorkMachineSchedule = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.END_WORK_MACHINE_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule/endWork`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.END_WORK_MACHINE_SCHEDULE_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const checkUnfinishedWork = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.CHECK_UNFINISHED_WORK),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule/unfinishedWork`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.CHECK_UNFINISHED_WORK_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const checkUnfinishedWorks = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.CHECK_UNFINISHED_WORKS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule/unfinishedWorksCheck`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.CHECK_UNFINISHED_WORKS_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getUnfinishedWorks = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_UNFINISHED_WORKS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule/unfinishedWorks`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_UNFINISHED_WORKS_SUCCESS,
              payload: response.machineSchedules
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const produceMachineSchedule = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.ADD_MACHINE_ACTIVITY),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule/produce`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.ADD_MACHINE_ACTIVITY_SUCCESS,
              payload: response.machineScheduleProduce
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteScheduleRequest = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.DELETE_SCHEDULE_REQUEST),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/machines/schedule/${ payload?._id }`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.DELETE_SCHEDULE_REQUEST_SUCCESS,
              payload: response.machines
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteEndProductScheduleEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.DELETE_END_PRODUCT_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/production/requestsHard/${ payload?._id }`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.DELETE_END_PRODUCT_SCHEDULE_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteSingleFinishingWorkEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.DELETE_SINGLE_FINISHING_WORK),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/finishing-works/schedule/${ payload?._id }`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.DELETE_SINGLE_FINISHING_WORK_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const saveMachineActivityEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.SAVE_MACHINE_ACTIVITY),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/production/requests/activity/save`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.SAVE_MACHINE_ACTIVITY_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getMachineActivityEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_MACHINE_ACTIVITY),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/production/requests/activity/browse`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_MACHINE_ACTIVITY_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const markMachineActivityAsDefectiveEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.MARK_AS_DEFECTIVE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/machines/schedule/produce/markAsDefective`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.MARK_AS_DEFECTIVE_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getScheduleRequest = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_SCHEDULE_SUCCESS,
              payload: response.machines
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getSingleScheduleEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_SINGLE_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.get(`${API_URL}/machines/schedule/${payload._id}`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_SINGLE_SCHEDULE_SUCCESS,
              payload: response.schedule
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchMachineSettingsEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.SEARCH_MACHINE_SETTINGS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/settings/search`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.SEARCH_MACHINE_SETTINGS_SUCCESS,
              payload: response.settings
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const checkMachineScheduleEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.CHECK_TIME_SLOT_DATA),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.CHECK_TIME_SLOT_DATA_SUCCESS,
              payload: response.machines
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateScheduleEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.UPDATE_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/machines/schedule`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(hideLoading())
            obs.next({
              type: ProductionTypes.UPDATE_SCHEDULE_SUCCESS,
              payload: response.schedule
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const finishRequestEpic = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.FINISH_REQUEST),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/production/requests/finish`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(hideLoading())
            obs.next({
              type: ProductionTypes.FINISH_REQUEST_SUCCESS,
              payload: { request: response.productionRequest, updated: true }
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const finishFinishingWorks = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.FINISH_FINISHINGWORK),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/production/requests/works/finish`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(hideLoading())
            history.push('/production')
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteRequest = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.DELETE_PRODUCTION_REQUEST),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/production/requests`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(hideLoading())
            obs.next({
              type: ProductionTypes.GET_PRODUCTION_REQESTS
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getPerformers = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_PERFORMERS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/production/performers`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_PERFORMERS_SUCCESS,
              payload: response.performers
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addPerformer = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.ADD_PERFORMER),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/production/newPerformer`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.ADD_PERFORMER_SUCCESS,
              payload: response
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addCommentMachine = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.ADD_COMMENT_MACHINE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule/comment`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.ADD_COMMENT_MACHINE_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const startWorkMachineSchedule = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.START_WORK_MACHINE_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule/startWork`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.START_WORK_MACHINE_SCHEDULE_SUCCESS,
              payload: response.machineScheduleWork
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getMachineSchedule = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_MACHINE_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/machines/schedule`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_MACHINE_SCHEDULE_SUCCESS,
              payload: response.machines
                ? response.machines
                : response.performers
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getMachineScheduleByPerformer = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_MACHINE_SCHEDULE_BY_PERFORMER),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/finishing-works/schedule`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_MACHINE_SCHEDULE_SUCCESS,
              payload: response.machines
                ? response.machines
                : response.performers
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getCurrentEndProduct = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_CURRENT_END_PRODUCT),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/finishing-works/schedule`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_CURRENT_END_PRODUCT_SUCCESS,
              payload: response
            })
            obs.next(hideModal())
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addOtherAction = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.ADD_OTHER_ACTION),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/other-schedule`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.ADD_OTHER_ACTION_SUCCESS,
              payload: response.otherSchedule
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getCurrentAction = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_CURRENT_ACTION),
    switchMap(({ payload }) => {
      return Api.get(`${API_URL}/other-schedule/${payload}`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_CURRENT_ACTION_SUCCESS,
              payload: response.otherSchedule
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getActionSchedule = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.GET_ACTION_SCHEDULE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/other-schedule/list`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.GET_ACTION_SCHEDULE_SUCCESS,
              payload: response.otherSchedules
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editActionItem = (action$) => {
  return action$.pipe(
    ofType(ProductionTypes.EDIT_ACTION_ITEM),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/other-schedule`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductionTypes.EDIT_ACTION_ITEM_SUCCESS,
              payload: response.otherSchedule._id
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next(hideLoading())
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
