import React from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import moment from "moment"
import { showModal } from "../../actions"
import "./styles.scss"

const Notification = ({ show, dotColour, item }) => {
  const userImage = React.useMemo(() => {
    if (!item) return global.config.defaultAvatar
    if (item.user?.coverPhoto) {
      if (item.user?.coverPhoto.indexOf("sindi-trud-admin.s3") !== -1)
        return global.config.defaultAvatar
      return item.user?.coverPhoto
    }
    return global.config.defaultAvatar
  }, [item])
  if (!item) return null
  return (
    <div className="notification-container">
      <div className="notification-top">
        <div
          className="notification-dot"
          style={{ backgroundColor: dotColour }}
        />
        <div className="date">
          {moment(item.createdAt).format("HH:mm [|] DD.MM.YYYY")}
        </div>
      </div>
      <div className="notification-inner-container">
        <div
          className="notification-content"
          style={{ borderLeft: `solid 2px ${dotColour}` }}
        >
          <Button.Image
            src={userImage}
            name={item.user?.fullName}
            craft={item.user?.occupation}
            className=""
            imageClassName=""
            nameClassName=""
            // onClick={() => console.log()}
          />
          <span
            onClick={() =>
              show({
                type: "notificationModal",
                width: "1250px",
                data: {
                  subject: "Отводяване на игрище",
                  clientName: "Любен Александров",
                  clientEmail: "luyben.a@samokovmunicipality.bg",
                  createdAt: "10.08.2020г. | 10:32",
                  subjectText: `много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг
                    текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много дълъг текст много
                    дълъг текст много дълъг текст много дълъг текст`,
                },
              })
            }
          >
            {item.reason}
          </span>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  show: (payload) => dispatch(showModal(payload)),
})

export default connect(null, mapDispatchToProps)(Notification)
