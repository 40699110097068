import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { QuantityDropdownInput } from "../../../components"
import { SingleRadioButton } from "../../SingleRadioButton"
import {
  hideModal,
  createHPRequest,
  getProductTypes,
  getProduct,
  filterHPOrders,
} from "../../../actions"
import moment from "moment"
import "./styles.scss"
import { debounce } from "lodash"

const onSearchInit = (event, filterHPOrders) => {
  event.persist()
  onSearchDelayed(event, filterHPOrders)
}

const onSearch = (event, filterHPOrders) => {
  filterHPOrders({ search: event.target.value, allStatuses: true })
}

const onSearchDelayed = debounce(
  (event, filterHPOrders) => onSearch(event, filterHPOrders),
  500
)

function ReadyProductCreationModal({
  hideModal,
  createHPRequest,
  types,
  getProductTypes,
  filterHPOrders,
  getProduct,
  more,
  found,
}) {
  const [typeOfDeal, setTypeOfDeal] = useState("Съществуващ продукт")
  const [productsToAdd, setProductsToAdd] = useState([
    {
      productType: "",
      product: "",
      quantity: 1,
      deliveryDate: moment().toDate(),
      units: { value: "бр" },
      focused: false,
      filter: "",
      criticalQuantity: "",
    },
  ])
  useEffect(() => {
    getProductTypes()
  }, [getProductTypes])

  return (
    <div className="ready-product-creation-container">
      <div className="top-container">
        <h3>Нова заявка</h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <p>Тип заявка</p>
      <div className="flex-container start padding">
        <SingleRadioButton
          label="Съществуващ продукт"
          selected={typeOfDeal === "Съществуващ продукт"}
          onClick={() => {
            setTypeOfDeal("Съществуващ продукт")
          }}
        />
        <SingleRadioButton
          label="Нов продукт"
          selected={typeOfDeal === "Нов продукт"}
          onClick={() => setTypeOfDeal("Нов продукт")}
        />
      </div>
      {productsToAdd.map((product, index) => {
        return (
          <div className="flex-container space-between">
            <div className="input-element">
              <p>Продукт</p>
              <div className="input-container">
                <Input.Text
                  onFocus={() => {
                    let newProductsToAdd = productsToAdd.slice(0)
                    newProductsToAdd[index].focused = true
                    setProductsToAdd(newProductsToAdd)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      let newProductsToAdd = productsToAdd.slice(0)
                      newProductsToAdd[index].focused = false
                      setProductsToAdd(newProductsToAdd)
                    }, 300)
                  }}
                  // disabled={!product.productType}
                  value={product.product}
                  onChange={(event) => {
                    let newProductsToAdd = productsToAdd.slice(0)
                    newProductsToAdd[index].product = event.target.value
                    newProductsToAdd[index].filter = event.target.value
                    setProductsToAdd(newProductsToAdd)
                    onSearchInit(event, filterHPOrders)
                  }}
                />
                {product.focused && typeOfDeal === "Съществуващ продукт" && (
                  <div className="products-dropdown">
                    {found.map((el) => {
                      const name = el.product
                      return (
                        <div
                          className="product-option"
                          onClick={() => {
                            let newProductsToAdd = productsToAdd.slice(0)
                            newProductsToAdd[index].product = name
                            setProductsToAdd(newProductsToAdd)
                          }}
                        >
                          {name}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="input-element">
              <p>Количество</p>
              <QuantityDropdownInput
                quantityValue={product.quantity}
                quantityTypeValue={product.units}
                onSetQuantity={(value) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].quantity = value
                  setProductsToAdd(newProductsToAdd)
                }}
                onSelectType={(type) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].units = type
                  setProductsToAdd(newProductsToAdd)
                }}
              />
            </div>
            <div className="input-element">
              <p>Нужна до</p>
              <Input.Datepicker
                placeholder="Дата"
                pickDate={product.deliveryDate}
                onChange={(date) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].deliveryDate = date
                  setProductsToAdd(newProductsToAdd)
                }}
              />
            </div>
            <div className="input-element">
              <span>Критично количество</span>
              <QuantityDropdownInput
                quantityValue={product.criticalQuantity}
                quantityTypeValue={product.units}
                onSetQuantity={(value) => {
                  let newProductsToAdd = productsToAdd.slice(0)
                  newProductsToAdd[index].criticalQuantity = value
                  setProductsToAdd(newProductsToAdd)
                }}
                onSelectType={(type) => {}}
                disableUnits
              />
            </div>
          </div>
        )
      })}
      <div className="flex-container space-between">
        <Button.Raised
          className="clear-border"
          text="Добави продукт"
          onClick={() => {
            let newProductsToAdd = productsToAdd.slice(0)
            newProductsToAdd.push({
              productType: "",
              product: "",
              quantity: 1,
              deliveryDate: moment().toDate(),
              units: { value: "бр" },
              filter: "",
              criticalQuantity: "",
            })
            setProductsToAdd(newProductsToAdd)
          }}
        />
        <Button.Raised
          text="Създай заявка"
          onClick={() => {
            createHPRequest(
              productsToAdd.map((product) => {
                return {
                  product: product.product,
                  quantity: product.quantity,
                  deliveryDate: product.deliveryDate,
                  units: product.units.value,
                  criticalQuantity: product.criticalQuantity,
                }
              })
            )
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  types: state.products.types,
  more: state.products.more,
  found: state.storage.found,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  createHPRequest: (payload) => dispatch(createHPRequest(payload)),
  getProductTypes: () => dispatch(getProductTypes()),
  getProduct: (payload) => dispatch(getProduct(payload)),
  filterHPOrders: (payload) => dispatch(filterHPOrders(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadyProductCreationModal)
