import React, { Component } from "react"
import * as ModalContainer from "react-modal"
import { connect } from "react-redux"
import { hideModal } from "../../actions"
import { Modals } from "../../components"
import "./styles.scss"

ModalContainer.setAppElement("#root")

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen })
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.hideModal()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction.bind(this), false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction.bind(this), false)
  }

  currentModalRender = (type, data) => {
    switch (type) {
      case "newVersionModal":
        return <Modals.NewVersionModal />
      case "colleaguesModal":
        return <Modals.ColleaguesModal />
      case "profileEdit":
        return <Modals.ProfileInfo currentUser={data} />
      case "notificationModal":
        return <Modals.NotificationModal />
      case "workFlowModal":
        return <Modals.WorkFlowModal />
      case "editProductModal":
        return <Modals.EditProductModal />
      case "editProduct":
        return <Modals.EditProduct data={data} />
      case "editOtherExpenses":
        return <Modals.EditOtherExpensesModal data={data} />
      case "editTubeExpenses":
        return <Modals.EditTubeExpensesModal data={data} />
      case "addProductModal":
        return <Modals.AddProductModal />
      case "fileOffersModal":
        return <Modals.FileOffersModal />
      case "projectCommentsModal":
        return <Modals.ProjectCommentsModal />
      case "addProjectActivityModal":
        return <Modals.AddProjectActivityModal />
      case "addProductQueryModal":
        return <Modals.AddProductQueryModal />
      case "newProductModal":
        return <Modals.NewProductModal />
      case "addExpenses":
        return <Modals.AddExpensesModal />
      case "addProduction":
        return <Modals.AddProductionModal />
      case "editProduction":
        return <Modals.EditProductionModal data={data} />
      case "addProductType":
        return <Modals.AddProductTypeModal />
      case "addTubeExpenses":
        return <Modals.AddTubeExpensesModal />
      case "addOtherExpenses":
        return <Modals.AddOtherExpensesModal />
      case "AddStorageRawMaterialsRequest":
        return <Modals.AddStorageRawMaterialsRequest />
      case "AddStorageRawMaterialsOrder":
        return <Modals.AddStorageRawMaterialsOrder selectedRequest={data} />
      case "StorageRawMaterialsFinishOrder":
        return <Modals.StorageRawMaterialsFinishOrder selectedRequests={data} />
      case "StorageTotal":
        return <Modals.StorageTotal total={data} />
      case "StorageOrderHistory":
        return <Modals.StorageOrderHistory history={data} />
      case "offerCreation":
        return <Modals.OfferCreationModal />
      case "invoiceCreation":
        return <Modals.InvoiceCreationModal />
      case "successModal":
        return <Modals.SuccessModal />
      case "actOfAcceptanceModal":
        return <Modals.ActOfAcceptance />
      case "addStorageProduct":
        return <Modals.AddStorageProductModal />
      case "productionHistory":
        return <Modals.ProductionHistoryModal />
      case "storageProducts":
        return <Modals.StorageProductsModal />
      case "storageProductAction":
        return <Modals.StorageProductActionModal />
      case "emailAddressModal":
        return <Modals.EmailAddressModal />
      case "productionRequestModal":
        return <Modals.ProductionRequestModal />
      case "productOrderModal":
        return <Modals.ProductOrderModal />
      case "productOrderCommentModal":
        return <Modals.ProductOrderCommentModal />
      case "commentsModal":
        return <Modals.CommentsModal />
      case "invoiceStatusChange":
        return <Modals.InvoiceStatusChangeModal />
      case "invoiceNotification":
        return <Modals.InvoiceNotificationModal />
      case "historyModal":
        return <Modals.HistoryModal />
      case "proformInvoiceModal":
        return <Modals.ProformInvoiceModal />
      case "readyProductCreation":
        return <Modals.ReadyProductCreationModal />
      case "readyProductOrder":
        return <Modals.ReadyProductOrderModal />
      case "readyProductAccrual":
        return <Modals.ReadyProductAccrualModal />
      case "readyProductCreateAccrual":
        return <Modals.ReadyProductCreateAccrualModal />
      case "readyProductSell":
        return <Modals.ReadyProductSellModal />
      case "addClient":
        return <Modals.AddClientModal />
      case "storageReadyProducts":
        return <Modals.StorageReadyProductsModal />
      case "proformInvoiceHPModal":
        return <Modals.ProformInvoiceHPModal />
      case "actOfAcceptanceHPModal":
        return <Modals.ActOfAcceptanceHPModal />
      case "readyProductEditInvoiceModal":
        return <Modals.ReadyProductEditInvoiceModal />
      case "addMachineActivityModal":
        return <Modals.AddMachineActivityModal />
      case "addProductScrappingModal":
        return <Modals.AddProductScrappingModal />
      case "endMachineActivityModal":
        return <Modals.EndMachineActivityModal />
      case "filterModal":
        return <Modals.FilterModal data={data} />
      case "editModal":
        return <Modals.EditModal data={data} />
      case "deleteModal":
        return <Modals.DeleteModal data={data} />
      case "addChecklistModal":
        return <Modals.AddChecklistModal data={data} />
      case "addContactImageModal":
        return <Modals.AddContactImageModal data={data} />
      case "contactAssetsModal":
        return <Modals.ContactAssetsModal data={data} />
      default:
        return null
    }
  }

  render() {
    let customStyles = {
      overlay: {
        zIndex: 1000,
        overflow: "auto",
        backgroundColor: "rgba(50, 50, 50, 0.7)",
      },
      content: {
        flex: 1,
        top: "50px",
        left: "50%" || this.props?.data?.left,
        bottom: "auto",
        height: this.props.height || "auto",
        minHeight: this.props.minHeight || "unset",
        width: this.props.width || "70%",
        marginTop:
          this.props?.marginTop || this.props?.data?.marginTop || "0px",
        // marginTop: "350px",
        inset: this.props.data?.inset || "50px 40px auto 50%",
        overflow: this.props.overflow || "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0px)",
        border: "none",
        backgroundColor: "#F0F0F0",
        boxShadow: "0px 2px 3px 0px rgba(50, 50, 50, 0.3)",
        padding: "0px",
        marginBottom: "20px",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderRadius: this.props.borderRadius || "5px",
      },
    }
    return (
      <ModalContainer
        id={this.props.id}
        isOpen={this.state.open}
        onRequestClose={this.props.closeModal}
        contentLabel={`${
          this.props.title ? this.props.title : "Missing title"
        }`}
        style={customStyles}
        shouldCloseOnOverlayClick
      >
        <div
          className={`modal-container ${
            this.props.classNames ? this.props.classNames.join(" ") : ""
          }`}
        >
          {this.currentModalRender(this.props.type, this.props.data)}
        </div>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  hideModal: (payload) => dispatch(hideModal(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
