import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, groupBy } from "lodash"
import { Button, Input } from "@makedonski/admin-ui"
import { showModal } from "../../actions"
import { CustomRadio, StorageRawMaterialsList } from "../../components"
import "./styles.scss"

const StorageRawMaterials = ({ machineElements, showModal }) => {
  const [selectedRequests, setSelectedRequests] = useState([])
  const [filter, setFilter] = useState({
    query: "",
    sortBy: "createdAt",
    period: false,
    startDate: new Date(),
    endDate: new Date(),
  })

  const searchQuery = useParams().search
  useEffect(() => {
    if (searchQuery) {
      setFilter({
        query: searchQuery,
        sortBy: "createdAt",
        period: false,
        startDate: new Date(),
        endDate: new Date(),
      })
    }
  }, [searchQuery])

  useEffect(() => {
    //GET logic
  }, [])

  const groups = groupBy(
    machineElements.filter((r) => r.status === "accrued"),
    (item) => item.material.trim()
  )
  const totalStorage = Object.keys(groups).map((group) => ({
    material: group,
    quantity: groups[group].reduce((a, c) => {
      const quantity = parseInt(c.quantity) || 0
      return a + quantity
    }, 0),
    price: groups[group].reduce((a, c) => {
      const price = parseFloat(c.price) || 0
      return a + price
    }, 0),
  }))
  return (
    <div className="raw-materials-container">
      <div className="raw-materials-header">
        <Button.Raised
          className="btn-new"
          text="Нова заявка"
          onClick={() => {
            showModal({
              type: "AddStorageRawMaterialsRequest",
              overflow: "visible",
              width: "800px",
              data: { type: "machineStorage" },
            })
          }}
        />
        <Input.Text
          placeholder="Свободно търсене"
          value={filter.query}
          onChange={(e) => setFilter({ ...filter, query: e.target.value })}
        />
        <div className="period">
          <CustomRadio
            onClick={() => setFilter({ ...filter, period: !filter.period })}
            selected={filter.period}
          />
          <span>За период от:</span>
          <Input.Datepicker
            pickDate={filter.startDate}
            onChange={(startDate) => setFilter({ ...filter, startDate })}
          />
          <span>До:</span>
          <Input.Datepicker
            pickDate={filter.endDate}
            onChange={(endDate) => setFilter({ ...filter, endDate })}
          />
        </div>
        <div className="buttons">
          <Button.Raised
            disabled={
              selectedRequests.length !== 1 ||
              selectedRequests.some((r) => r.status !== "new")
            }
            clear
            className="btn-clear"
            text="Поръчай"
            onClick={() => {
              showModal({
                type: "AddStorageRawMaterialsOrder",
                overflow: "visible",
                width: "1100px",
                data: selectedRequests,
              })
              setSelectedRequests([])
            }}
          />
          <Button.Raised
            disabled={
              selectedRequests.length < 1 ||
              selectedRequests.some((r) => r.status !== "delivered")
            }
            clear
            className="btn-clear"
            text="Заприходи"
            onClick={() => {
              showModal({
                type: "StorageRawMaterialsFinishOrder",
                overflow: "visible",
                width: "800px",
                data: selectedRequests,
              })
              setSelectedRequests([])
            }}
          />
          <Button.Raised
            disabled={
              selectedRequests.length < 1 ||
              selectedRequests.some((r) => r.status !== "accrued")
            }
            clear
            className="btn-clear"
            text="Изпиши"
            onClick={() => {
              showModal({
                type: "StorageRawMaterialsFinishOrder",
                overflow: "visible",
                width: "800px",
                data: selectedRequests,
              })
              setSelectedRequests([])
            }}
          />
        </div>
      </div>
      {isEmpty(machineElements) ? (
        <div className="raw-materials-empty">
          <span>
            Все още няма създадени заявки и поръчки. Започнете като натиснете
            бутона “Нова заяка”
          </span>
        </div>
      ) : (
        <StorageRawMaterialsList
          selectedRequests={selectedRequests}
          setSelectedRequests={(newValue) => setSelectedRequests(newValue)}
          filter={filter}
          setSortBy={(value) => setFilter({ ...filter, sortBy: value })}
        />
      )}
      <div className="raw-materials-total">
        <span>Обща стойност на склада:</span>
        <span>
          {totalStorage.reduce((a, c) => {
            return a + c.price * c.quantity
          }, 0)}
          лв.
        </span>
        <Button.Icon
          name="info"
          size={26}
          color="#a2bfd5"
          onClick={() =>
            showModal({
              type: "StorageTotal",
              overflow: "visible",
              width: "800px",
              data: totalStorage,
            })
          }
        />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  machineElements: state.storage.machineElements,
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StorageRawMaterials)
