import React, { useState, useEffect } from 'react'
import { Alerts } from '@makedonski/socourt-utilities'
import { connect } from 'react-redux'
import { Input, Button } from '@makedonski/admin-ui'
import { SingleRadioButton, ClientAutocomplete } from '../../../components'
import { ActProduct } from './ActProduct'
import { getAvailableProducts } from '../../../actions'
import _ from 'lodash'
import moment from 'moment'
import {
  hideModal,
  searchProduct,
  clearResults,
  getProductTypes,
  searchOfferClients,
  resetResults,
  createAct,
  createActEn,
  editAct,
  editActEn,
  searchActSuppliers,
  getHPRequests
} from '../../../actions'
import './styles.scss'

const onChange = (event, search) => {
  event.persist()
  onChangeDelayed(event, search)
}

const onChangeDelayed = _.debounce(
  (event, search) =>
    search({
      search: event.target.value
    }),
  500
)

function ActOfAcceptance ({
  hideModal,
  productTypes,
  searchProduct,
  availableProducts,
  HPRequests,
  getHPRequests,
  clearResults,
  getProductTypes,
  searchResult,
  searchResults,
  getAvailableProducts,
  resetResults,
  createAct,
  createActEn,
  data,
  editAct,
  editActEn,
  searchActSuppliers,
  actSuppliers
}) {
  const [productType, setProductType] = useState(null)
  const [productResults, setProductResults] = useState([])
  const [productToAdd, setProductToAdd] = useState([])
  const [chosenProducts, setChosenProducts] = useState([])
  const [clientName, setClientName] = useState('')
  const [uic, setUIC] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [deliveryService, setDeliveryService] = useState('')
  const [driver, setDriver] = useState('')
  const [truckRegistration, setTruckRegistration] = useState('')
  const [shippingNumber, setShippingNumber] = useState('')
  const [description, setDescription] = useState('')
  const [language, setLanguage] = useState("bg")

  useEffect(() => {
    if (data.forEdit) {
      setChosenProducts(data.act.products)
      setClientName(data.act.client.name)
      setUIC(data.act.client.uic)
      setPhone(data.act.client.phone)
      setEmail(data.act.client.email)
      setAddress(data.act.client.address)
      setDeliveryService(data.act.deliveryService)
      setDriver(data.act.driver)
      setTruckRegistration(data.act.truckRegistration)
      setShippingNumber(data.act.shippingNumber)
      setDescription(data.act.description)
    }
    else if (!data.forEdit && data.includedProducts?.length) {
      const readyProducts = data.includedProducts.map((prod) => {
        const quantity = prod.quantity ? prod.quantity : null
        const date = prod.deliveryDate ? prod.deliveryDate : null
        // const date = prod.deliveryDate ? prod.deliveryDate : moment().toDate()
        return {
          ...prod,
          quantity: quantity || prod.orderQuantity,
          deliveryDate: date
        }
      })
      setChosenProducts(readyProducts)
    }
  }, [data])

  useEffect(() => {
    getProductTypes()
    resetResults()
  }, [getProductTypes, resetResults])

  //new
  // useEffect(() => {
  //   getAvailableProducts()
  //   getHPRequests()
  //   resetResults()
  // }, [getAvailableProducts, getHPRequests, resetResults])


  //old
  useEffect(() => {
    let newSearchResults = []
    if (searchResult) {
      newSearchResults = [...searchResults, searchResult]
    } else {
      newSearchResults = [...searchResults]
    }
    setProductResults(newSearchResults)
  }, [searchResults, searchResult])

  // //products from both storages -> warehouse and hp
  // useEffect(() => {
  //   let newSearchResults = []
  //   if (searchResult) {
  //     newSearchResults = [...availableProducts, searchResult]
  //   } else {
  //     newSearchResults = [...availableProducts, ...HPRequests]
  //   }
  //   setProductResults(newSearchResults)
  // }, [searchResults, availableProducts, HPRequests])

  //new
  // useEffect(() => {
  //   let newSearchResults = []
  //   if (searchResult) {
  //     newSearchResults = [...availableProducts, searchResult]
  //   } else {
  //     newSearchResults = [...availableProducts]
  //   }
  //   setProductResults(newSearchResults)
  // }, [searchResults, availableProducts])

  // console.log('availableProducts', availableProducts)
  // console.log('HPRequests', HPRequests)
  // console.log('productResults', productResults)

  return (
    <div className='act-of-acceptance-container'>
      <div className='left-container'>
        <h3>Създаване на ППП</h3>

        {/* <div className="flex-container space-between">
          <div className="input-container">
            <p>Език</p>
            <Input.Dropdown
              placeholder="Моля изберете"
              value={language === "en" ? "Английски" : "Български"}
              options={["Български", "Английски"]}
              onChange={(value) => value.value === "Английски" ? setLanguage("en") : setLanguage("bg")}
            />
          </div>
        </div> */}

        <div className='flex-container space-between'>
          <div className='input-container'>
            <p>Клиент</p>
            <ClientAutocomplete
              onChooseClient={(data) => {
                setClientName(data.name)
                if (data.uic) {
                  setUIC(data.uic)
                } else {
                  setUIC('')
                }
                if (data.phone) {
                  setPhone(data.phone)
                } else {
                  setPhone('')
                }
                if (data.email) {
                  setEmail(data.email)
                } else {
                  setEmail('')
                }
                if (data.address) {
                  setAddress(data.address)
                } else {
                  setAddress('')
                }
              }}
              onChange={(event) => setClientName(event.target.value)}
            />
            {/* <div className="input-wrapper">
              <Input.Text
                value={clientName}
                onChange={(event) => {
                  onChange(event, searchOfferClients)
                  setClientName(event.target.value)
                }}
              />
              {clientResults.length > 0 && (
                <div className="client-results-dropdown">
                  {clientResults.map((client) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setAddress(client.address)
                          setEmail(client.email)
                          setClientName(client.name)
                          setPhone(client.phone)
                          setUIC(client.uic)
                          resetResults()
                        }}
                      >
                        {client.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div> */}
          </div>
          <div className='input-container'>
            <p>ЕИК</p>
            <Input.Text
              value={uic}
              onChange={(event) => setUIC(event.target.value)}
            />
          </div>
          <div className='input-container'>
            <p>Телефон</p>
            <Input.Text
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className='flex-container border-bottom space-between'>
          <div className='input-container'>
            <p>Имейл</p>
            <Input.Text
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className='input-container big'>
            <p>Адрес</p>
            <Input.Text
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div className='flex-container space-between'>
          <div className='input-container'>
            <p>Доставчик</p>
            <div className='input-wrapper'>
              <Input.Text
                value={deliveryService.name}
                onChange={(event) => {
                  onChange(event, searchActSuppliers)
                  setDeliveryService({ name: event.target.value })
                }}
              />
              {actSuppliers.length > 0 && (
                <div className='client-results-dropdown'>
                  {actSuppliers.map((supplier) => {
                    return (
                      <div
                        className='result'
                        onClick={() => {
                          setDeliveryService(supplier)
                          resetResults()
                        }}
                      >
                        {supplier.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <div className='input-container'>
            <p>Шофьор</p>
            <Input.Text
              value={driver}
              onChange={(event) => setDriver(event.target.value)}
            />
          </div>
          <div className='input-container'>
            <p>Рег. номер</p>
            <Input.Text
              value={truckRegistration}
              onChange={(event) => setTruckRegistration(event.target.value)}
            />
          </div>
        </div>
        <div className='flex-container space-between'>
          <div className='input-container'>
            <p>Товарителница</p>
            <Input.Text
              value={shippingNumber}
              onChange={(event) => setShippingNumber(event.target.value)}
            />
          </div>
          <div className='input-container big'>
            <p>Описание</p>
            <Input.Text
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
        </div>
        <div className='input-container big'>
          <p>Вид продукт</p>
          <Input.Dropdown
            placeholder='Моля изберете'
            value={productType}
            options={productTypes.map((type) => type.name)}
            onChange={(value) => {
              setProductResults([])
              setProductType(value.value)
            }}
          />
        </div>
        <div className='flex-container flex-end'>
          <p
            onClick={() => {
              if (!productType) {
                Alerts.error('Грешка', 'Моля изберете тип продукт!')
              } else {
                const type = productTypes.find(
                  (type) => type.name === productType
                )
                searchProduct({
                  productType: type._id,
                  basicInfo: []
                })
              }
            }}
          >
            Добави към ППП
          </p>
          <div className='arrow-right' />
          {/* old */}
          {productResults.filter((el) => el.productType.name === productType)
            .length > 0 && (
              <div className='search-results-popup'>
                <div className='title-container'>
                  <h4>Изберете продукт</h4>
                  <div
                    className='close-popup-btn'
                    onClick={() => setProductResults([])}
                  />
                </div>
                <div className='scroll-container'>
                  {productResults
                    .filter((el) => el.productType.name === productType)
                    .map((product) => {
                      let infoString
                      if (product.productType.name === 'Тръба') {
                        const typeOfDiameter = product.basicInfo.find(
                          (el) => el.name === 'Произвежда се по'
                        )
                        const SN = product.basicInfo.find(
                          (el) => el.name === 'Коравина'
                        )
                        if (typeOfDiameter.value === 'Вътрешен диаметър') {
                          const diameter = product.basicInfo.find(
                            (el) => el.name === 'Вътрешен диаметър'
                          )
                          infoString = `SN${SN?.value || ''}_ID${diameter?.value || ''}`
                        } else {
                          const diameter = product.basicInfo.find(
                            (el) => el.name === 'Външен диаметър'
                          )
                          infoString = `SN${SN?.value || ''}_ОD${diameter?.value || ''}`
                        }
                      } else {
                        infoString = product.basicInfo
                          .map((el) => {
                            return el.value
                          })
                          .join('_')
                      }
                      return (
                        <div className='product-container'>
                          <div
                            className='product-name'
                            onClick={() => setProductToAdd(product)}
                          >
                            <p id='large'>
                              {product.productType.name === 'Тръба'
                                ? infoString
                                : product.productType.name}
                            </p>
                            {product.productType.name !== 'Тръба' && (
                              <p id='small'>{infoString}</p>
                            )}
                          </div>
                          <SingleRadioButton
                            selected={productToAdd._id === product._id}
                            onClick={() => setProductToAdd(product)}
                          />
                        </div>
                      )
                    })}
                </div>
                <Button.Raised
                  text='Добави'
                  onClick={() => {
                    if (
                      chosenProducts.findIndex(
                        (el) => el._id === productToAdd._id
                      ) === -1
                    ) {
                      const newChosenProducts = chosenProducts.slice(0)
                      newChosenProducts.push({
                        ...productToAdd,
                        deliveryDate: moment().toDate(),
                        quantity: 1
                      })
                      setProductResults([])
                      setChosenProducts(newChosenProducts)
                      clearResults()
                    }
                  }}
                />
              </div>
          )}

          
          {/* new */}
          {/* {productResults?.filter((el) => el?.type === productType)
            ?.length > 0 && (
              <div className='search-results-popup'>
                <div className='title-container'>
                  <h4>Изберете продукт</h4>
                  <div
                    className='close-popup-btn'
                    onClick={() => setProductResults([])}
                  />
                </div>
                <div className='scroll-container'>
                  {productResults
                    .filter((el) => el?.type === productType)
                    .map((product) => {
                      return (
                        <div className='product-container'>
                          <div
                            className='product-name'
                            onClick={() => setProductToAdd(product)}
                          >
                            <p id='large'>
                              {product?.type === 'Тръба'
                                ? product?.variation
                                : product?.type}
                            </p>
                            {product?.type !== 'Тръба' && (
                              <p id='small'>{product?.variation}</p>
                            )}
                          </div>
                          <SingleRadioButton
                            selected={productToAdd._id === product._id}
                            onClick={() => setProductToAdd(product)}
                          />
                        </div>
                      )
                    })}
                </div>
                <Button.Raised
                  text='Добави'
                  onClick={() => {
                    if (
                      chosenProducts.findIndex(
                        (el) => el._id === productToAdd._id
                      ) === -1
                    ) {
                      const newChosenProducts = chosenProducts.slice(0)
                      newChosenProducts.push({
                        ...productToAdd,
                        deliveryDate: moment().toDate(),
                        quantity: 1
                      })
                      setProductResults([])
                      setChosenProducts(newChosenProducts)
                      clearResults()
                    }
                  }}
                />
              </div>
          )} */}
        </div>
      </div>
      <div className='right-container'>
        <div className='flex-container space-between'>
          <h3>Продукти към ППП</h3>
          <div className='close-btn' onClick={() => hideModal()} />
        </div>
        <div className='scroll-container'>
          {chosenProducts.map((product, index) => {
            return (
              <ActProduct
                forEdit={data.forEdit}
                product={product}
                productTypes={productTypes}
                onQuantityChange={(value) => {
                  const newProducts = chosenProducts.slice(0)
                  newProducts[index].quantity = value
                  setChosenProducts(newProducts)
                }}
              />
            )
          })}
        </div>
        <Button.Raised
          text={`${data.forEdit ? 'Редактирай ППП' : 'Създай ППП'}`}
          onClick={() => {
            if (data.forEdit) {
              const products = chosenProducts.map((product) => {
                const cost =
                  (product.expenses &&
                    product.expenses.material.reduce((acc, val) => {
                      return acc + val.total
                    }, 0)) ||
                  0
                return {
                  item: product.item ? product.item._id : product._id,
                  quantity: parseInt(product.quantity),
                  deliveryDate: product?.deliveryDate ? moment(product?.deliveryDate).toISOString() : null,
                  singleUnitPrice: product.singleUnitPrice || cost,
                  total: product.singleUnitPrice
                    ? product.singleUnitPrice * parseInt(product.quantity)
                    : cost * parseInt(product.quantity)
                }
              })

                editAct({
                  _id: data.act._id,
                  inquiry: data._id,
                  client: {
                    name: clientName,
                    uic: uic,
                    phone: phone,
                    email: email,
                    address: address,
                    attentionOf: clientName
                  },
                  products: products,
                  description: description,
                  deliveryService: deliveryService._id
                    ? deliveryService._id
                    : deliveryService.name,
                  driver: driver,
                  truckRegistration: truckRegistration,
                  shippingNumber: shippingNumber
                })

            } else {
              const products = chosenProducts.map((product) => {
                const cost =
                  product.expenses &&
                  product.expenses.material.reduce((acc, val) => {
                    return acc + val.total
                  }, 0)
                return {
                  item: product._id,
                  quantity: parseInt(product.quantity),
                  deliveryDate: product?.deliveryDate ? product?.deliveryDate?.toISOString() : null,
                  singleUnitPrice: cost,
                  total: cost * parseInt(product.quantity)
                }
              })

                createAct({
                  inquiry: data._id,
                  client: {
                    name: clientName,
                    uic: uic,
                    phone: phone,
                    email: email,
                    address: address,
                    attentionOf: clientName
                  },
                  products: products,
                  description: description,
                  deliveryService: deliveryService?._id
                    ? deliveryService?._id
                    : deliveryService?.name || '',
                  driver: driver,
                  truckRegistration: truckRegistration,
                  shippingNumber: shippingNumber
                })

            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  productTypes: state.products.types,
  searchResults: state.products.more,
  searchResult: state.products.found,
  clientResults: state.inquiries.clientResults,
  actSuppliers: state.inquiries.actSuppliers,
  data: state.modal.data,
  availableProducts: state.storage.availableProducts,
  HPRequests: state.storage.HPRequests
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: (payload) => dispatch(hideModal(payload)),
  searchProduct: (payload) => dispatch(searchProduct(payload)),
  clearResults: () => dispatch(clearResults()),
  getProductTypes: () => dispatch(getProductTypes()),
  searchOfferClients: (payload) => dispatch(searchOfferClients(payload)),
  resetResults: () => dispatch(resetResults()),
  createAct: (payload) => dispatch(createAct(payload)),
  createActEn: (payload) => dispatch(createActEn(payload)),
  editAct: (payload) => dispatch(editAct(payload)),
  editActEn: (payload) => dispatch(editActEn(payload)),
  searchActSuppliers: (payload) => dispatch(searchActSuppliers(payload)),
  getAvailableProducts: () => dispatch(getAvailableProducts()),
  getHPRequests: () => dispatch(getHPRequests())
})

export default connect(mapStateToProps, mapDispatchToProps)(ActOfAcceptance)
