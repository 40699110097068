export const machineSettingTypes = {
  GET_MACHINE_SETTINGS: "settings/GET_MACHINE_SETTINGS",
  GET_MACHINE_SETTINGS_SUCCESS: "settings/GET_MACHINE_SETTINGS_SUCCESS",

  DELETE_MACHINE_SETTINGS: "settings/DELETE_MACHINE_SETTINGS",
  DELETE_MACHINE_SETTINGS_SUCCESS: "settings/DELETE_MACHINE_SETTINGS_SUCCESS",

  EDIT_MACHINE_SETTINGS: "settings/EDIT_MACHINE_SETTINGS",
  EDIT_MACHINE_SETTINGS_SUCCESS: "settings/EDIT_MACHINE_SETTINGS_SUCCESS",

  GET_SPECIFIC_SETTINGS: "settings/GET_SPECIFIC_SETTINGS",
  GET_SPECIFIC_SETTINGS_SUCCESS: "settings/GET_SPECIFIC_SETTINGS_SUCCESS",

  SET_SELECTED_MACHINE: "settings/SET_SELECTED_MACHINE",
}

export const getMachineSettings = (payload) => ({
  type: machineSettingTypes.GET_MACHINE_SETTINGS,
})

export const editMachineSettings = (payload) => ({
  type: machineSettingTypes.EDIT_MACHINE_SETTINGS,
  payload,
})

export const deleteMachineSettings = (payload) => ({
  type: machineSettingTypes.DELETE_MACHINE_SETTINGS,
  payload,
})

export const getSpecificSettings = (payload) => ({
  type: machineSettingTypes.GET_SPECIFIC_SETTINGS,
  payload,
})

export const setSelectedMachine = (payload) => ({
  type: machineSettingTypes.SET_SELECTED_MACHINE,
  payload,
})
