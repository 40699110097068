import React, { Component } from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import { Empty, Tabs, ProductSearch } from "../../components"
import {
  setUserWorkingStatus,
  getProductTypes,
  getVariations,
  getProviders,
  getProduct,
  showModal,
} from "../../actions"
import "./styles.scss"

const ProductsList = ({ onClose, products = [], onAdd }) => {
  const [selected, setSelected] = React.useState("")
  const getProductVariation = (item) => {
    return item.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")
  }

  return (
    <div className="products-list-container">
      <div className="products-list-container-title">
        <h4>Изберете продукт</h4>
        <div className="close-button">
          <Button.Icon
            onClick={onClose}
            name="plus"
            size={20}
            color="#7E7E7E"
          />
        </div>
      </div>
      <div className="products-list-container-scroll">
        {products.map((product) => {
          let text = product.productType.name // Maybe needs to be changed to friendlyNames
          if (product.productType?.name === "Тръба") {
            const typeOfDiameter = product.basicInfo.find(
              (el) => el.name === "Произвежда се по"
            )
            const SN = product.basicInfo.find((el) => el.name === "Коравина")
            if (typeOfDiameter?.value === "Вътрешен диаметър") {
              const diameter = product.basicInfo.find(
                (el) => el.name === "Вътрешен диаметър"
              )
              text = `SN${SN?.value}_ID${diameter?.value}`
            } else {
              const diameter = product.basicInfo.find(
                (el) => el.name === "Външен диаметър"
              )
              text = `SN${SN?.value}_OD${diameter?.value}`
            }
          }
          return (
            <div
              className="product-list-item-wrap"
              onClick={() => {
                setSelected(product._id)
              }}
            >
              <div className="product-list-item-title">
                {product?.friendlyNames?.join(", ") || text}
              </div>
              {product.productType.name !== "Тръба" && (
                <div className="product-list-item-variation">
                  {getProductVariation(product)}
                </div>
              )}
              <div
                className={`radio-circle ${
                  selected === product._id ? "active" : ""
                }`}
              >
                <div className="radio-circle-inner" />
              </div>
            </div>
          )
        })}
      </div>
      <div className="products-list-container-footer">
        <Button.Raised
          disabled={selected === ""}
          onClick={() => {
            onAdd(selected)
          }}
          text="Добави"
          className="button-green"
        />
      </div>
    </div>
  )
}

class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: "",
    }
    this.productsSearchRef = null
  }

  componentDidMount() {
    this.props.getProductTypes()
    this.props.getVariations()
    this.props.getProviders()
    const { x, width } = this.productsSearchRef.getBoundingClientRect()
    this.popupRefItem.style.width = "290px"
    this.popupRefItem.style.height = "410px"
    this.popupRefItem.style.bottom = "50px"
    this.popupRefItem.style.display = "none"
    this.popupRefItem.style.left = `${width + x - 40}px`
  }

  componentDidUpdate(prevProps) {
    if (prevProps.providers !== this.props.providers) {
      this.setState({ selected: this.props.providers[0]._id })
    }
    if (prevProps.more !== this.props.more) {
      if (this.props.more.length) {
        this.popupRefItem.style.display = "block"
      }
    }
  }

  onClosePopup() {
    this.popupRefItem.style.display = "none"
  }

  render() {
    return (
      <div className="products main-container">
        <div
          ref={(ref) => {
            this.productsSearchRef = ref
          }}
          className="content-section with-shadow"
        >
          <div className="flex-container space-between">
            <h3>Продукти</h3>
            <Button.Icon
              onClick={() => {
                this.props.showModal({
                  type: "addProductType",
                  overflow: "visible",
                  width: "600px",
                })
              }}
              size={18}
              name="plus"
              color="#26ACE2"
              className="icon-button-add"
            />
          </div>
          <Tabs
            tabs={this.props.providers.map((el) => ({
              name: el.name,
              id: el._id,
            }))}
            onChange={(id) => this.setState({ selected: id })}
            selected={this.state.selected}
          />
          <ProductSearch
            types={this.props.types.map((el) => ({
              label: el.name,
              value: el._id,
            }))}
            onSearch={(search, type) => {
              const basicInfo = Object.keys(search).map((k) => {
                return {
                  name: k,
                  value: search[k],
                }
              })
              this.props.getProduct({ productType: type, basicInfo })
            }}
          />
        </div>
        <div className="content-section with-shadow section-middle">
          <h3>Разходни норми</h3>
          <Empty text="Първо изберете продукт, за да видите неговите разходни норми" />
        </div>
        <div className="content-section with-shadow">
          <h3>История на производство:</h3>
          <Empty text="Първо изберете продукт, за да видите историята на производство" />
        </div>
        <div
          ref={(ref) => {
            this.popupRefItem = ref
          }}
          className="more-products-popup"
        >
          <ProductsList
            products={this.props.more}
            onClose={() => {
              this.onClosePopup()
            }}
            onAdd={(productID) => {
              this.props.history.push(`/products/${productID}`)
            }}
          />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  profile: state.user.currentUser,
  types: state.products.types,
  more: state.products.more,
  variations: state.variations.data,
  providers: state.providers.data,
})
const mapDispatchToProps = (dispatch) => ({
  setUserWorkingStatus: (payload) => dispatch(setUserWorkingStatus(payload)),
  getProductTypes: () => dispatch(getProductTypes()),
  getVariations: () => dispatch(getVariations()),
  getProviders: () => dispatch(getProviders()),
  getProduct: (payload) => dispatch(getProduct(payload)),
  showModal: (payload) => dispatch(showModal(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)
