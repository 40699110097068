import React, { useState } from "react"
import styles from "./NewVersionModal.module.scss"
import Lottie from "lottie-react"
import animationData from "../../../lotties/HydroPro-New-Version.json"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Button, Tab } from "@mui/material"
import { connect } from "react-redux"
import { hideModal } from "../../../actions"

const lottieStyling = {
  width: "108px",
  height: "108px",
}

function NewVersionModal({ hideModal }) {
  const [value, setValue] = useState("1")
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div className={styles["new-version-modal-container"]}>
      <div className={styles["modal-info"]}>
        <Lottie animationData={animationData} style={lottieStyling} />
        <h3>Налична е нова версия</h3>
        <p>
          Системата има нова версия, моля изчистете Вашата кеш памет, за да
          актуализирате. Изчистване на кеш памет в:
        </p>
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            <Tab
              value={"1"}
              label="Google chrome"
              style={{ textTransform: "none" }}
            />
            <Tab
              value={"2"}
              label="Mozilla firefox"
              style={{ textTransform: "none" }}
            />
            <Tab
              value={"3"}
              label="Microsoft edge"
              style={{ textTransform: "none" }}
            />
          </TabList>
          <TabPanel value="1">
            <p>
              Настройки {`->`} Сигурност {`->`} Изчистване на кеш памет
            </p>
          </TabPanel>
          <TabPanel value="2">
            <p>
              Настройки {`->`} Сигурност {`->`} Изчистване на кеш памет
            </p>
          </TabPanel>
          <TabPanel value="3">
            <p>
              Настройки {`->`} Сигурност {`->`} Изчистване на кеш памет
            </p>
          </TabPanel>
        </TabContext>
        <Button
          variant="contained"
          fullWidth
          disableElevation
          disableRipple
          sx={{ borderColor: "#26ACE2", color: "white" }}
          onClick={hideModal}
        >
          Разбрах
        </Button>
      </div>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
})

export default connect(null, mapDispatchToProps)(NewVersionModal)
