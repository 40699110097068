import React, { useEffect, useMemo, useRef, useState } from "react"
import Scrollbars from "react-custom-scrollbars-2"
import "./styles.scss"
import { history } from "../../config/stores"
import { useDispatch } from "react-redux"
import { getContacts, hideModal, showModal } from "../../actions"
import Popup from "reactjs-popup"
import FiltersPopup from "./FiltersPopup"
import Statistics from "../../components/Statistics"

const headerElements = [
  {
    name: "Име",
    query: {
      key: "name",
      type: "text",
    },
  },
  {
    name: "Позиция",
    query: {
      key: "position",
      type: "text",
    },
  },
  {
    name: "Компания",
    query: {
      key: "companyName",
      type: "text",
    },
  },
  {
    name: "Държава",
    query: {
      key: "country",
      type: "text",
    },
  },
  { name: "Имейл" },
  { name: "Телефон" },
  {
    name: "За продукт",
    query: {
      key: "forProduct",
      type: "checkboxes",
      options: [
        { label: "Тръба", value: "Тръба" },
        { label: "КМУ", value: "КМУ" },
        { label: "Шахти", value: "Шахти" },
        { label: "Линеен отводнител", value: "Линеен отводнител" },
        { label: "Резервоари", value: "Резервоари" },
        { label: "Фитинги", value: "Фитинги" },
        { label: "Друго", value: "Друго" },
      ],
    },
  },
  { name: "Контакт от" },
  { name: "Добавени" },
]

const ContactsGrid = ({ docs, current, setCurrent, fetch }) => {
  const dispatch = useDispatch()
  const tableRef = useRef()
  const [innerLoading, setInnerLoading] = useState(false)

  const [filters, setFilters] = useState({})
  const filter = useMemo(() => filters, [filters])

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values //get elements from object
    const pad = 0 // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight) // must be at least 1.0
    if (t >= 0.97 && docs.totalPages >= current && !innerLoading) {
      setInnerLoading(true)
      dispatch(
        getContacts({
          pageNumber: current,
          pageSizeLimit: 20,
          sortBy: { createdAt: -1 },
          onSuccess: (res) => setInnerLoading(false),
        })
      )
      setCurrent(current + 1)
    }
  }

  useEffect(() => {
    dispatch(
      getContacts({
        pageNumber: 1,
        pageSizeLimit: 20,
        sortBy: { createdAt: -1 },
        onSuccess: (res) => setCurrent(2),
      })
    )
  }, [])

  const mappedFilter = useMemo(() => {
    const { forProduct, name, ...payload } = filter
    if (forProduct) payload.forProducts = forProduct
    if (name) payload.name = name
    return payload
  }, [filter])

  useEffect(() => {
    dispatch(
      getContacts({
        pageNumber: 1,
        pageSizeLimit: 20,
        sortBy: { createdAt: -1 },
        ...mappedFilter,
      })
    )
  }, [fetch, mappedFilter, setCurrent])

  const timerRef = useRef(null)

  useEffect(() => {
    const timer = timerRef.current
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [])

  const handleUpdateDebounced = (...payload) => {
    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => handleUpdate(...payload), 100)
  }

  return (
    <Scrollbars
      style={{ height: "100%", width: "145%" }}
      onUpdate={handleUpdateDebounced}
      ref={tableRef}
      renderView={(props) => (
        <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
      )}
    >
      <div className={`flex w-full`}>
        {headerElements?.map((header, i) => {
          return (
            <div
              className="flex"
              style={{
                width: i === 0 ? "13.5%" : i === 8 ? "16.5%" : "10%",
              }}
              key={header.name}
            >
              <div
                className="flex items-center"
                style={{
                  fontSize: "13px",
                  color: mappedFilter?.[header.query?.key] && "#25e083",
                }}
              >
                {header.name}
                {header.query && (
                  <Popup
                    trigger={
                      <div
                        className={`page-arrow-icon min-w-[16px] h-4 w-4 ml-[6px]`}
                      />
                    }
                    position={
                      i === 0
                        ? "bottom left"
                        : i === header.length - 2
                        ? "bottom right"
                        : "bottom center"
                    }
                    className="anvil"
                    contentStyle={{ width: 300, padding: 0 }}
                  >
                    {(close) => (
                      <FiltersPopup
                        close={close}
                        filterOptions={header.query}
                        filter={filter}
                        handleSubmit={(filter) => setFilters(filter)}
                      />
                    )}
                  </Popup>
                )}
              </div>
            </div>
          )
        })}
      </div>
      {docs?.docs?.map((el, i) => {
        return (
          <>
            <div
              className="table-body-row"
              style={{
                width: "100%",
                cursor: "pointer",
                position: "relative",
                zIndex: "2",
                marginBottom: docs?.docs?.length - 1 === i && "27px",
              }}
              key={el?._id}
            >
              <div
                className="row-data"
                style={{ width: "13.5%", wordBreak: "break-all" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.name?.length > 30
                  ? el?.name?.slice(0, 30) + "..."
                  : el?.name || "---"}
                {el?.name?.length > 30 && (
                  <Popup
                    trigger={
                      <img
                        src={require("../../assets/icons/info-icon.svg")}
                        style={{
                          width: "15px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    }
                    closeOnDocumentClick
                    className="create-contact-popUp anvil"
                    position="bottom right"
                    on={"click"}
                  >
                    {(close) => (
                      <div
                        className="flex column full-width"
                        style={{
                          fontSize: "13px",
                          fontWeight: "bold",
                          lineHeight: "20px",
                        }}
                      >
                        {el?.name}
                      </div>
                    )}
                  </Popup>
                )}
              </div>
              <div
                className="row-data"
                style={{ width: "10%" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.position?.length > 30
                  ? el?.position?.slice(0, 30) + "..."
                  : el?.position}
                {el?.position?.length > 30 && (
                  <Popup
                    trigger={
                      <img
                        src={require("../../assets/icons/info-icon.svg")}
                        style={{
                          width: "15px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    }
                    closeOnDocumentClick
                    className="create-contact-popUp anvil"
                    position="bottom right"
                    on={"click"}
                  >
                    {(close) => (
                      <div
                        className="flex column full-width"
                        style={{
                          fontSize: "13px",
                          fontWeight: "bold",
                          lineHeight: "20px",
                        }}
                      >
                        {el?.position}
                      </div>
                    )}
                  </Popup>
                )}
              </div>
              <div
                className="row-data"
                style={{ width: "10%", wordBreak: "break-all" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.companyName?.length > 30
                  ? el?.companyName?.slice(0, 30) + "..."
                  : el?.companyName || "---"}
                {el?.companyName?.length > 30 && (
                  <Popup
                    trigger={
                      <img
                        src={require("../../assets/icons/info-icon.svg")}
                        style={{
                          width: "15px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    }
                    closeOnDocumentClick
                    className="create-contact-popUp anvil"
                    position="bottom right"
                    on={"click"}
                  >
                    {(close) => (
                      <div
                        className="flex column full-width"
                        style={{
                          fontSize: "13px",
                          fontWeight: "bold",
                          lineHeight: "20px",
                        }}
                      >
                        {el?.companyName}
                      </div>
                    )}
                  </Popup>
                )}
              </div>
              <div
                className="row-data"
                style={{ width: "10%", wordBreak: "break-all" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.country || "---"}
              </div>
              <div
                className="row-data"
                style={{ width: "10%", wordBreak: "break-all" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.email || "---"}
              </div>
              <div
                className="row-data"
                style={{ width: "10%", wordBreak: "break-all" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.phoneNumber || "---"}
              </div>
              <div
                className="row-data"
                style={{ width: "10%", wordBreak: "break-all" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.forProducts?.map(
                  (prod, i) =>
                    `${prod}${el?.forProducts?.length - 1 > i ? ", " : ""}`
                ) || "---"}
              </div>
              <div
                className="row-data"
                style={{ width: "10%", wordBreak: "break-all" }}
                onClick={() => history.push(`/edit-contact/${el?._id}`)}
              >
                {el?.contactFrom || "---"}
              </div>
              <div
                className="row-data"
                style={{ width: "16%", borderRight: "none" }}
              >
                <div
                  className="flex items-center"
                  style={{ width: "91%", position: "relative", zIndex: "4" }}
                >
                  {`${el?.comments?.length || 0} коментара | ${
                    el?.files?.length || 0
                  } файла`}
                  <img
                    src={require("../../assets/icons/dashboard-apps.svg")}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "20px",
                      cursor: "pointer",
                      position: "relative",
                      zIndex: "4",
                    }}
                    onClick={() =>
                      dispatch(
                        showModal({
                          type: "contactAssetsModal",
                          data: {
                            contact: el,
                            inset:
                              window.innerWidth < 1200
                                ? "50px 40px auto 75%"
                                : "50px 40px auto 80%",
                          },
                          hide: () => dispatch(hideModal()),
                          width: "500px",
                          height: "800px",
                        })
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )
      })}
      {innerLoading && (
        <Statistics.LoaderInline
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
          }}
        />
      )}
    </Scrollbars>
  )
}

export default ContactsGrid
