import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input } from "@makedonski/admin-ui"
import { nanoid } from "nanoid"
import { UploadFile, RadioButton, FileItem } from "../../.."
import Files from "../../../../utilities/files"
import "./styles.scss"
import ClearIcon from "@mui/icons-material/Clear"
import { getProductNameKMU } from "../../../../utilities/helpers"

const units = [
  { label: "Метър", value: "м" },
  { label: "Брой", value: "бр" },
  { label: "Килограм", value: "кг" },
  { label: "Литър", value: "л" },
  { label: "Кубик", value: "м3" },
]

const KMUView = ({
  setMainUnit,
  onBasicInfoSet,
  onSetImages,
  loading,
  stopLoading,
  productNames,
  setProductNames,
  data,
  setClientDiagram,
  setProductionDiagram,
  typeName
}) => {
  const [unit, setUnit] = useState("")
  const [nomenclature, setNomenclature] = useState("")
  const [basicInfo, setBasicInfo] = useState([])
  const [clientDiagram, onSetClientDiagram] = useState([])
  const [productionDiagram, onSetProductionDiagram] = useState([])
  const [images, setImages] = useState([])
  const [nameKMU, setNameKMU] = useState('')

  useEffect(() => {
    if (data) {
      const main = units.find((el) => el.value === data.mainUnit)
      setUnit(main.value)
      setMainUnit(main.value)
      setNomenclature(data.nomenclature)
      setBasicInfo(data.basicInfo)
      onBasicInfoSet(data.basicInfo)
      setProductNames(data.friendlyNames)
      if (data.schematicsProduction) {
        setProductionDiagram(data.schematicsProduction)
        onSetProductionDiagram(data.schematicsProduction)
      }
      if (data.schematicsClient) {
        setClientDiagram(data.schematicsClient)
        onSetClientDiagram(data.schematicsClient)
      }
      if (data.images) {
        onSetImages(data.images)
        setImages(data.images)
      }
    }
  }, [data, setMainUnit])

  useEffect(() => {
    setNameKMU(getProductNameKMU(basicInfo))
  }, [basicInfo])

  return (
    <div className="kmu-view-container">
      <h3>Обща информация</h3>
      <div className="flex-container start">
        <div className="regular-container small">
          <p>Мерна единица</p>
          <Input.Dropdown
            placeholder=""
            value={unit}
            options={units}
            onChange={(value) => {
              setMainUnit(value.value)
              setUnit(value.value)
            }}
          />
        </div>

        <div className="regular-container small">
          <p>NS</p>
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "NS") &&
              basicInfo.find((el) => el.name === "NS").value
            }
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex((el) => el.name === "NS")
              const object = {
                type: "input",
                value: event.target.value,
                name: "NS",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
        </div>

        <div className="regular-container small">
          <p>SF</p>
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "SF") &&
              basicInfo.find((el) => el.name === "SF").value
            }
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex((el) => el.name === "SF")
              const object = {
                type: "input",
                value: event.target.value,
                name: "SF",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
        </div>

      </div>
    <div className="flex-container start">
    <div className="form-control">
          <p className="radio-bttn">Ориентация 1</p>
          <div>
          <div className="radio-group-horizontal">
            <RadioButton
              changed={() => {
                const newBasicInfo = basicInfo.slice(0)
                if (
                  newBasicInfo.findIndex(
                    (el) => el.value === "Хоризонтална"
                  ) === -1
                ) {
                  const index = newBasicInfo.findIndex(
                    (el) => el.name === "Ориентация 1"
                  )
                  if (index !== -1) {
                    newBasicInfo.splice(index, 1)
                  }
                  newBasicInfo.push({
                    type: "option",
                    value: "Хоризонтална",
                    name: "Ориентация 1",
                    units: "",
                  })
                  onBasicInfoSet(newBasicInfo)
                  setBasicInfo(newBasicInfo)
                }
              }}
              id={nanoid()}
              isSelected={
                basicInfo.findIndex((el) => el.value === "Хоризонтална") !== -1
              }
              label="Хоризонтална"
              value="Хоризонтална"
            />
            <RadioButton
              changed={() => {
                const newBasicInfo = basicInfo.slice(0)
                if (
                  newBasicInfo.findIndex((el) => el.value === "Вертикална") ===
                  -1
                ) {
                  const index = newBasicInfo.findIndex(
                    (el) => el.name === "Ориентация 1"
                  )
                  if (index !== -1) {
                    newBasicInfo.splice(index, 1)
                  }
                  newBasicInfo.push({
                    type: "option",
                    value: "Вертикална",
                    name: "Ориентация 1",
                    units: "",
                  })
                  onBasicInfoSet(newBasicInfo)
                  setBasicInfo(newBasicInfo)
                }
              }}
              id={nanoid()}
              isSelected={
                basicInfo.findIndex((el) => el.value === "Вертикална") !== -1
              }
              label="Вертикална"
              value="Вертикална"
            />
          </div>
          </div>
        </div>

      <div className="regular-container smallest">
          <p>DN тяло 1</p>
          {/* <p>Вариация</p> */}

          <div className="input-unit-wrapper small">
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "DN тяло 1") &&
              basicInfo.find((el) => el.name === "DN тяло 1").value
            }
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "DN тяло 1"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "DN тяло 1",
                units: `${basicInfo.find((el) => el.name === "Ориентация 1") ?
                (basicInfo.find((el) => el.name === "Ориентация 1").value === 'Хоризонтална' ? 'L' : 'H') : ''}`,
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
          <p className="input-unit">
            {basicInfo.find((el) => el.name === "Ориентация 1") ?
            (basicInfo.find((el) => el.name === "Ориентация 1").value === 'Хоризонтална' ? 'L' : 'H') : '--'}
          </p>
          </div>
        </div>

        <div className="form-control">
          <p className="radio-bttn">Ориентация 2</p>
        <div>
          <div className="radio-group-horizontal">
          <RadioButton
              changed={() => {
                const newBasicInfo = basicInfo.slice(0)
                if (
                  newBasicInfo.findIndex((el) => el.value === "Ориентация 2") ===
                  -1
                ) {
                  const index = newBasicInfo.findIndex(
                    (el) => el.name === "Ориентация 2"
                  )
                  if (index !== -1) {
                    newBasicInfo.splice(index, 1)
                  }
                  newBasicInfo.push({
                    type: "option",
                    value: "ID",
                    name: "Ориентация 2",
                    units: "",
                  })
                  onBasicInfoSet(newBasicInfo)
                  setBasicInfo(newBasicInfo)
                }
              }}
              id={nanoid()}
              isSelected={
                basicInfo.findIndex((el) => el.value === "ID") !== -1
              }
              label="ID"
              value="ID"
            />
            <RadioButton
              changed={() => {
                const newBasicInfo = basicInfo.slice(0)
                if (
                  newBasicInfo.findIndex((el) => el.value === "Ориентация 2") ===
                  -1
                ) {
                  const index = newBasicInfo.findIndex(
                    (el) => el.name === "Ориентация 2"
                  )
                  if (index !== -1) {
                    newBasicInfo.splice(index, 1)
                  }
                  newBasicInfo.push({
                    type: "option",
                    value: "OD",
                    name: "Ориентация 2",
                    units: "",
                  })
                  onBasicInfoSet(newBasicInfo)
                  setBasicInfo(newBasicInfo)
                }
              }}
              id={nanoid()}
              isSelected={
                basicInfo.findIndex((el) => el.value === "OD") !== -1
              }
              label="OD"
              value="OD"
            />
          </div>
        </div>
      </div>

        <div className="regular-container smallest">
          <p>DN тяло 2</p>
          {/* <p>Вариация</p> */}

          <div className="input-unit-wrapper small">
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "DN тяло 2") &&
              basicInfo.find((el) => el.name === "DN тяло 2").value
            }
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "DN тяло 2"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "DN тяло 2",
                units: `${basicInfo.find((el) => el.name === "Ориентация 2") ?
                (basicInfo.find((el) => el.name === "Ориентация 2").value === 'ID' ? 'ID' : 'OD') : ''}`,
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
          <p className="input-unit">
            {basicInfo.find((el) => el.name === "Ориентация 2") ?
            (basicInfo.find((el) => el.name === "Ориентация 2").value === 'ID' ? 'ID' : 'OD') : '--'}
          </p>
          </div>

        </div>
        
      </div>

      <div className="flex-container">
      <div className="regular-container flex-1">
          {/* <span>Тежест на единца</span> */}
          <span>Тежест на единца тръба</span>
          <div className="input-unit-wrapper">
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "Тежест на единца") &&
              basicInfo.find((el) => el.name === "Тежест на единца").value
            }
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Тежест на единца"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Тежест на единца",
                units: "кг",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
          <p>кг</p>
          </div>
        </div>
        <div className="regular-container flex-1">
          <span>ВХ/ИЗХ</span>
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "ВХ/ИЗХ") &&
              basicInfo.find((el) => el.name === "ВХ/ИЗХ").value
            }
            placeholder=""
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "ВХ/ИЗХ"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "ВХ/ИЗХ",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
        </div>
        <div className="regular-container flex-1">
          <span>Капак</span>
          <Input.Dropdown
          options={[ 'Капак А15', 'D400', 'Самонивелиращ се капак D400' ]}
          value={
            basicInfo.find((el) => el.name === "Капак") &&
            basicInfo.find((el) => el.name === "Капак").value
          }
          placeholder=''
          onChange={(event) => {
            const newBasicInfo = basicInfo.slice(0)
            const index = newBasicInfo.findIndex(
              (el) => el.name === "Капак"
            )
            const object = {
              type: "option",
              value: event.value,
              name: "Капак",
              units: "",
            }
            if (index === -1) {
              newBasicInfo.push(object)
            } else {
              newBasicInfo.splice(index, 1, object)
            }
            setBasicInfo(newBasicInfo)
            onBasicInfoSet(newBasicInfo)
          }}
          />
        </div>
      </div>
      <h3>Ревизионен отвор</h3>
      <div className="flex-container flex-start">
          <div className="regular-container">
            <p>ID/OD</p>
            <Input.Text
               value={
                basicInfo.find((el) => el.name === "ID/OD") &&
                basicInfo.find((el) => el.name === "ID/OD").value
              }
              placeholder=""
              onChange={(event) => {
                const newBasicInfo = basicInfo.slice(0)
                const index = newBasicInfo.findIndex(
                  (el) => el.name === "ID/OD"
                )
                const object = {
                  type: "input",
                  value: event.target.value,
                  name: "ID/OD",
                  units: "",
                }
                if (index === -1) {
                  newBasicInfo.push(object)
                } else {
                  newBasicInfo.splice(index, 1, object)
                }
                setBasicInfo(newBasicInfo)
                onBasicInfoSet(newBasicInfo)
              }}
             />
          </div>
          <div className="regular-container">
            <p>H</p>
            <Input.Text
           value={
            basicInfo.find((el) => el.name === "H") &&
            basicInfo.find((el) => el.name === "H").value
          }
          placeholder=""
          onChange={(event) => {
            const newBasicInfo = basicInfo.slice(0)
            const index = newBasicInfo.findIndex(
              (el) => el.name === "H"
            )
            const object = {
              type: "input",
              value: event.target.value,
              name: "H",
              units: "",
            }
            if (index === -1) {
              newBasicInfo.push(object)
            } else {
              newBasicInfo.splice(index, 1, object)
            }
            setBasicInfo(newBasicInfo)
            onBasicInfoSet(newBasicInfo)
          }}
             />
          </div>
      </div>
      {typeName && typeName !== 'КМУ' &&
      <>
     <h3>Наименование за клиент</h3>
      <div className="flex-container">
        {/* <div
          className="plus-button"
          onClick={() => {
            const newNames = productNames.slice(0)
            newNames.push("")
            setProductNames(newNames)
          }}
        /> */}

        <div className="flex-container no-margin">
          <div className="flex-container no-margin">
            <Input.Text
              value={productNames}
              onChange={(event) => {
                setProductNames(event.target.value)
              }}
            />
          </div>
        </div>
      </div>
      </>
      }
      {typeName && typeName === 'КМУ' &&
            <>
            <h3>Наименование за клиент</h3>
             <div className="flex-container">
               <div className="flex-container no-margin">
                 <div className="flex-container no-margin">
                   <Input.Text
                     className='large'
                     value={nameKMU}
                   />
                 </div>
               </div>
             </div>
             </> }
      <div className="form-control">
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за клиенти</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  onSetClientDiagram((prevState) => [...prevState, ...data])
                  setClientDiagram((prevState) => [...prevState, ...data])
                  stopLoading()
                })
              }}
            />
          </div>
          {(clientDiagram.length > 0 &&
            clientDiagram.map((el, index) => {
              return (
                <FileItem
                  {...el}
                  onRemove={() => {
                    let newDiagrams = clientDiagram.slice(0)
                    newDiagrams.splice(index, 1)
                    onSetClientDiagram([...newDiagrams])
                    setClientDiagram([...newDiagrams])
                  }}
                />
              )
            })) ||
            (clientDiagram.length > 0 &&
              clientDiagram.map((el, index) => (
                <FileItem
                  key={index}
                  {...el}
                  onRemove={() => {
                    onSetClientDiagram([])
                    setClientDiagram([])
                  }}
                />
              )))}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за производство</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  onSetProductionDiagram((prevState) => [...prevState, ...data])
                  setProductionDiagram((prevState) => [...prevState, ...data])
                  stopLoading()
                })
              }}
            />
          </div>
          {(productionDiagram.length > 0 &&
            productionDiagram.map((el, index) => {
              return (
                <FileItem
                  {...el}
                  onRemove={() => {
                    let newDiagrams = productionDiagram.slice(0)
                    newDiagrams.splice(index, 1)
                    onSetProductionDiagram([...newDiagrams])
                    setProductionDiagram([...newDiagrams])
                  }}
                />
              )
            })) ||
            (productionDiagram.length > 0 &&
              productionDiagram.map((diagram, index) => (
                <FileItem
                  key={index}
                  {...diagram}
                  onRemove={() => {
                    onSetProductionDiagram([])
                    setProductionDiagram([])
                  }}
                />
              )))}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Снимки</span>
            <UploadFile
              accept={"image/*"}
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                Files.uploadFiles(e.target.files).then((result) => {
                  setImages((prevState) => [
                    ...prevState,
                    ...result.map((el) => el.location),
                  ])
                  onSetImages((prevState) => [
                    ...prevState,
                    ...result.map((el) => el.location),
                  ])
                  stopLoading()
                })
              }}
            />
          </div>
          {images && (
            <div className="images-scroll">
              {images.map((img, idx) => {
                return (
                  <>
                    <img key={`Image-${idx}`} src={img} alt="item" />
                    <ClearIcon
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        let newImages = images.slice(0)
                        newImages.splice(idx, 1)
                        onSetImages(newImages)
                        setImages(newImages)
                      }}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(KMUView)
