import React, { useEffect, useState } from "react"
import moment from "moment"
import { TableHeaderInquiry } from "../TableHeaderInquiry"
import { getInquirySales } from "../../actions"
import _ from "lodash"
import InquirySaleElement from "../InquirySaleElement/InquirySaleElement"
import "./styles.scss"
import "../InquiriesClientsComponent/InquiriesHeader.scss"
import InquiriesHeader from "../InquiriesClientsComponent/InquiriesHeader"
import { useSelector, useDispatch } from "react-redux"

const headerElements = [
  {
    name: "№ на документ",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Дата",
    size: 1.3,
    fixedWidth: 160,
    filter: true,
    sort: false
  },
  {
    name: "Стока/артикул",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Партньор",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Потребител",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Мярка",
    size: 1.3,
    fixedWidth: 160,
    filter: true,
    sort: false
  },
  {
    name: "Количество",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Продажна цена",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Продажно ДДС",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Продажно ст-ст",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Доставна цена",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Доставно ДДС",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Доставна стойност",
    size: 2,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Ед. печалба",
    size: 1.3,
    fixedWidth: 160,
    filter: true,
    sort: false
  },
  {
    name: "Ст-ст печалба",
    size: 3.5,
    fixedWidth: 200,
    filter: true,
    sort: false
  }
]

const InquiriesSalesComponent = ({ getClients, pageNumber }) => {
  const [sort, setSort] = useState(true)
  const [selectedSortValue, setSelectedSortValue] = useState("")
  const [firstLoad, setFirstLoad] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchValue, setSearchValue] = useState("invoiceNumber")
  const [searchDateValue, setSearchDateValue] = useState("createdAt")
  const [startDate, setStartDate] = useState(moment().toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const salesData = useSelector((state) => state.inquiries.salesData)
  const [sortedSales, setSortedSales] = useState([])
  const dispatch = useDispatch()

  const filterOptions = {
    'all': 'Всички',
    'invoiceNumber': '№ на документ',
    'product': 'Стока/артикул',
    'client': 'Партньор',
    'createdBy': 'Потребител'
  }

  const dateFilterOptions = [
    { label: "Дата на създаване", value: "createdAt" },
    { label: "Последна активност", value: "updatedAt" },
  ]

  const selectedSortValues = [
    "№ на документ",
    "Дата",
    "Партньор",
    "Количество",
    "Продажна цена",
    "Продажно ДДС",
    "Доставна стойност",
    "Ед. печалба",
    "Ст-ст печалба",
  ]

  useEffect(() => {
    dispatch(getInquirySales())
  }, [])

  // useEffect(() => {
  //   handleSort();
  // }, [sort]);

  const handleSearch = () => {
    // if (sortedSales === []) setSortedSales(salesData)
    if (sortedSales?.length === 0) setSortedSales(salesData)
    setFirstLoad(false)

    if (searchQuery) {
      if (searchValue === "invoiceNumber") {
        setSortedSales(
          salesData.filter((invoice) =>
            invoice.invoiceNumber.includes(Number(searchQuery))))
      } else if (searchValue === "product") {
        setSortedSales(
          salesData.filter((invoice) =>
            invoice.product.toLowerCase().includes(searchQuery.toLowerCase())))
      } else if (searchValue === "client") {
        setSortedSales(
          salesData.filter((invoice) =>
            invoice?.client?.toLowerCase().includes(searchQuery.toLowerCase())))
      } else if (searchValue === "createdBy") {
        setSortedSales(
          salesData.filter((invoice) =>
            invoice?.createdBy?.toLowerCase().includes(searchQuery.toLowerCase())))
      } else if (searchValue === "all") {
        setSortedSales(salesData.filter(invoice => {
         return invoice.invoiceNumber.includes(Number(searchQuery)) ||
          invoice.product.toLowerCase().includes(searchQuery.toLowerCase()) ||
          invoice?.client?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          invoice?.createdBy?.toLowerCase().includes(searchQuery.toLowerCase())
        }))
      }
    }
  }

  const handleDatesFilter = () => {
    if (moment(endDate).isBefore(startDate)) {
      setStartDate(moment().toDate())
      setEndDate(moment().toDate())
      return
    } else {
      let start = moment(startDate).clone()
      let dates = []
      // if (sortedSales === []) setSortedSales(salesData)
      if (sortedSales?.length === 0) setSortedSales(salesData)
      setFirstLoad(false)
      setAreDatesSelected(true)

      while (start.isSameOrBefore(endDate)) {
        dates.push(start.format("DD.MM.YYYY"))
        start.add(1, "days")
      }

      setSortedSales(
        salesData.filter((invoice) =>
          dates.includes(moment(invoice.createdAt).format("DD.MM.YYYY"))
        )
      )

      return sortedSales
    }
  }

  const handleSort = (selectedSortValue) => {
    let sorted = searchQuery === "" ? salesData.slice(0) : sortedSales.slice(0)
    setFirstLoad(false)

    switch (selectedSortValue) {
      case "№ на документ":
        sorted.sort((a, b) => {
          let numA = Number(a.invoiceNumber)
          let numB = Number(b.invoiceNumber)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Дата":
        sorted.sort((a, b) => {     
          return sort
            ? moment(a.createdAt) - moment(b.createdAt)
            : moment(b.createdAt) - moment(a.createdAt)
        })
        break;

      case "Стока/артикул":
        sorted.sort((a, b) => {
          return sort
          ? a.product.localeCompare(b.product)
          : b.product.localeCompare(a.product)
        })
        break;

      case "Партньор":
        sorted.sort((a, b) => {
          return sort
            ? a.client.localeCompare(b.client)
            : b.client.localeCompare(a.client)
        })
        break;

      case "Потребител":
        sorted.sort((a, b) => {
          return sort
            ? a.createdBy.localeCompare(b.createdBy)
            : b.createdBy.localeCompare(a.createdBy)
        })
        break;

      case "Мярка":
        sorted.sort((a, b) => {
          let numA = Number(a.units)
          let numB = Number(b.units)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Количество":
        sorted.sort((a, b) => {
          let numA = Number(a.quantity)
          let numB = Number(b.quantity)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Продажна цена":
        sorted.sort((a, b) => {
          let numA = Number(a.sellPrice)
          let numB = Number(b.sellPrice)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Продажно ДДС":
        sorted.sort((a, b) => {
          let numA = Number(a.sellPriceVat)
          let numB = Number(b.sellPriceVat)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Продажно ст-ст":
        sorted.sort((a, b) => {
          let numA = Number(a.sellPriceWithVat)
          let numB = Number(b.sellPriceWithVat)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Доставна цена":
        sorted.sort((a, b) => {
          let numA = Number(a.deliveryPrice)
          let numB = Number(b.deliveryPrice)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Доставно ДДС":
        sorted.sort((a, b) => {
          let numA = Number(a.deliveryPriceVat)
          let numB = Number(b.deliveryPriceVat)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Доставна стойност":
        sorted.sort((a, b) => {
          let numA = Number(a.deliveryPriceWithVat)
          let numB = Number(b.deliveryPriceWithVat)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Ед. печалба":
        sorted.sort((a, b) => {
          let numA = Number(a.profitTotal)
          let numB = Number(b.profitTotal)
          return sort ? numA - numB : numB - numA
        })
        break;

      case "Ст-ст печалба":
        sorted.sort((a, b) => {
          let numA = Number(a.sellPriceWithVat)
          let numB = Number(b.sellPriceWithVat)
          return sort ? numA - numB : numB - numA
        })
        break;
    }
    setSortedSales(sorted)
  }

  return (
    <div className="inquiries-clients-component">
      <InquiriesHeader
        headerElements={headerElements}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={filterOptions}
        startDate={startDate}
        endDate={endDate}
        tab="sales"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        dateFilterOptions={dateFilterOptions}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        searchDateValue={searchDateValue}
        setSearchDateValue={setSearchDateValue}
        handleDatesFilter={handleDatesFilter}
        areDatesSelected={areDatesSelected}
        setAreDatesSelected={setAreDatesSelected}
        sortedSales={sortedSales}
        setSortedSales={setSortedSales}
        salesData={salesData}
      />

      <div className="sales-main-container">
        <TableHeaderInquiry
          elements={headerElements}
          justifyContent="flex-start"
          width="fit-content"
          handleSort={handleSort}
          sort={sort}
          setSort={setSort}
          tab="sales"
          selectedSortValue={selectedSortValue}
          setSelectedSortValue={setSelectedSortValue}
        />
        <div className="scroll-container">
          {firstLoad ||
          (searchQuery == "" && !areDatesSelected && selectedSortValue === "")
            ? salesData.map((invoice, i) => {
                return (
                  <InquirySaleElement data={invoice} key={invoice._id + i} />
                )
              })
            : sortedSales.map((invoice, i) => {
                return (
                  <InquirySaleElement data={invoice} key={invoice._id + i} />
                )
              })}

          {/* { sortedSales.map((invoice, i) => {
          return <InquirySaleElement data={invoice} key={invoice._id + i} />
        })} */}
        </div>
      </div>
    </div>
  )
}

export default InquiriesSalesComponent
