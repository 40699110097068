// export const API_URL = process.env.REACT_APP_API_URL || 'https://erp-hydropro-api-dev.herokuapp.com'

export const API_URL = process.env.REACT_APP_API_URL || "https://erp-hydropro-api.herokuapp.com"

// export const API_URL = "https://tests.mexus.org"

export const SUPPORT_URL =
  process.env.REACT_APP_SUPPORT_URL ||
  "https://customer-support-dev.herokuapp.com"
export const s3Credentials = {
  bucketName: "dev-fidweb",
  dirName: "hydropro",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5KYJIFZTM",
  secretAccessKey: "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",
}
// export const S3_CREDENTIALS_DEV = {
//   bucketName: "dev-fidweb",
//   region: "eu-central-1",
//   accessKeyId: "AKIA5L7PZ7W5KYJIFZTM",
//   secretAccessKey: "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",
// };

// DEV
export const oneSignalAppID = "295682b5-835e-4a42-b215-da6d5c980648"
export const oneSignalSafariID =
  "web.onesignal.auto.5f4f9ed9-fb2e-4d6a-935d-81aa46fccce0"

// PROD
export const GOOGLE_VISION_API_KEY = "AIzaSyC9behkdKVonnX-alJyCawSt1gV6yhIuII"
// export const oneSignalAppID = '8e41c711-304e-473a-b446-d6dc34ff481c'
// export const oneSignalSafariID =
//   'web.onesignal.auto.30826c80-9a56-405a-b53d-a917475ecc5b'
