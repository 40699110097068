import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { uniqBy } from "lodash"
import {
  StorageTypes,
  GeneralTypes,
  hideModal,
  searchRequestsSuccess,
  hideLoading,
} from "../actions"
import { API_URL } from "../config/settings"

export const getRequests = (action$) =>
  action$.pipe(
    ofType(StorageTypes.GET_REQUESTS),
    switchMap(() =>
      Api.get(`${API_URL}/requests`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_REQUESTS_SUCCESS,
              payload: response.requests,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const addProformInvoice = (action$) => {
  return action$.pipe(
    ofType(StorageTypes.CREATE_PROFORM),
    switchMap(({ payload }) => {
      console.log(payload)
      return Api.post(
        `${API_URL}/offers/proform-invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProformInvoiceStorage = (action$) => {
  return action$.pipe(
    ofType(StorageTypes.EDIT_PROFORM),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/proform-invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createActOfAcceptanceStorage = (action$) => {
  return action$.pipe(
    ofType(StorageTypes.CREATE_ACT_OF_ACCEPTANCE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/act-of-acceptance`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editActOfAcceptanceStorage = (action$) => {
  return action$.pipe(
    ofType(StorageTypes.EDIT_ACT_OF_ACCEPTANCE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/act-of-acceptance`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchRequestEpic = (action$) =>
  action$.pipe(
    ofType(StorageTypes.SEARCH),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/requests/search`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(searchRequestsSuccess(response.requests))
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const createRequest = (action$) =>
  action$.pipe(
    ofType(StorageTypes.CREATE_REQUEST),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/requests`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.CREATE_REQUEST_SUCCESS,
              payload: response.request,
            })
            obs.next(hideModal())
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const orderRequest = (action$) =>
  action$.pipe(
    ofType(StorageTypes.ORDER_REQUEST),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/requests/order`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_REQUESTS,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )

export const updateOrderStatus = (action$) =>
  action$.pipe(
    ofType(StorageTypes.UPDATE_ORDER_STATUS),
    switchMap(({ payload }) => {
      // console.log(`${API_URL}/requests/status`, JSON.stringify(payload))
      return Api.post(
        `${API_URL}/requests/status`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.UPDATE_ORDER_STATUS_SUCCESS,
              payload: response.request,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )

export const postsRequest = (action$) =>
  action$.pipe(
    ofType(StorageTypes.POSTS_REQUEST),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/requests/posts`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_REQUESTS,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const exportRequest = (action$) =>
  action$.pipe(
    ofType(StorageTypes.EXPORT_REQUEST),
    switchMap(({ payload }) =>
      Api.post(
        `${API_URL}/requests/exportations`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.EXPORT_REQUEST_SUCCESS,
              payload: response.requests,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const searchProvider = (action$) =>
  action$.pipe(
    ofType(StorageTypes.SEARCH_PROVIDER),
    switchMap(({ payload }) =>
      Api.post(
        `${API_URL}/requests/search/provider`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.SEARCH_PROVIDER_SUCCESS,
              payload: uniqBy(response.providers, "uic"),
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const getAvailableProducts = (action$) =>
  action$.pipe(
    ofType(StorageTypes.GET_AVAILABLE_PRODUCTS),
    switchMap(() =>
      Api.get(`${API_URL}/warehouse`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_AVAILABLE_PRODUCTS_SUCCESS,
              payload: response.products,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const searchStorageProducts = (action$) =>
  action$.pipe(
    ofType(StorageTypes.SEARCH_STORAGE_PRODUCTS),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/warehouse/search`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.SEARCH_STORAGE_PRODUCTS_SUCCESS,
              payload: response.products,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const createStorageProduct = (action$) =>
  action$.pipe(
    ofType(StorageTypes.CREATE_STORAGE_PRODUCT),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/warehouse`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.CREATE_STORAGE_PRODUCT_SUCCESS,
              payload: response.product,
            })
            obs.next(hideModal())
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const editStorageProduct = (action$) => {
  return action$.pipe(
    ofType(StorageTypes.EDIT_STORAGE_PRODUCT),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/warehouse`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.UPDATE_PRODUCTS,
              payload: response.product,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const exportProduct = (action$) =>
  action$.pipe(
    ofType(StorageTypes.EXPORT_PRODUCT),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/warehouse/export`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.UPDATE_PRODUCTS,
              payload: response.product,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const sampleProduct = (action$) =>
  action$.pipe(
    ofType(StorageTypes.SAMPLE_PRODUCT),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/warehouse/sample`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.UPDATE_PRODUCTS,
              payload: response.product,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const defectProduct = (action$) =>
  action$.pipe(
    ofType(StorageTypes.DEFECT_PRODUCT),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/warehouse/defective`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.UPDATE_PRODUCTS,
              payload: response.product,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const getHPRequests = (action$) =>
  action$.pipe(
    ofType(StorageTypes.GET_HP_REQUESTS),
    switchMap(() =>
      Api.get(`${API_URL}/requests/hp`).pipe(
        switchMap(({ response }) => {
          response.requests.reverse()
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS_SUCCESS,
              payload: response.requests,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const createHPRequest = (action$) =>
  action$.pipe(
    ofType(StorageTypes.CREATE_HP_REQUEST),
    switchMap(({ payload, onSuccess }) => {
      return Api.post(`${API_URL}/requests/hp`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            onSuccess(response)
            obs.complete()
          })}
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )

export const editHPRequest = (action$) =>
  action$.pipe(
    ofType(StorageTypes.EDIT_HP_REQUEST),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/requests/hp`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )

export const editHPInvoice = (action$) =>
  action$.pipe(
    ofType(StorageTypes.EDIT_HP_INVOICE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/requests/invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )

export const orderHPRequest = (action$) =>
  action$.pipe(
    ofType(StorageTypes.ORDER_HP_REQUEST),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/requests/hp/order`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const changeHPOrderStatus = (action$) =>
  action$.pipe(
    ofType(StorageTypes.CHANGE_HP_ORDER_STATUS),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/requests/hp/status`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const accrueHPOrder = (action$) =>
  action$.pipe(
    ofType(StorageTypes.ACCRUE_HP_ORDER),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/requests/hp/posts`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

export const sellHPOrder = (action$) =>
  action$.pipe(
    ofType(StorageTypes.SELL_HP_ORDER),
    switchMap(({ payload }) =>
      Api.post(`${API_URL}/requests/hp/sell`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: StorageTypes.GET_HP_REQUESTS,
            })
            obs.complete()
          })
        ),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    )
  )

  export const sellProduct = (action$) => {
    return action$.pipe(
      ofType(StorageTypes.SELL_PRODUCT_INVOICE),
      switchMap(({ payload }) => {
        return Api.post(
          `${API_URL}/warehouse/sell`,
          JSON.stringify(payload)
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: StorageTypes.SELL_PRODUCT_INVOICE_SUCCESS,
                payload: response,
              })
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error("Грешка", err.response.message)
            } else {
              Alerts.error("Грешка", "невалидни данни!")
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }

  export const sellProductHP = (action$) => {
    return action$.pipe(
      ofType(StorageTypes.SELL_PRODUCT_INVOICE_HP),
      switchMap(({ payload }) => {
        return Api.post(
          `${API_URL}/requests/hp/sell2`,
          JSON.stringify(payload)
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: StorageTypes.SELL_PRODUCT_INVOICE_HP_SUCCESS,
                payload: response,
              })
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error("Грешка", err.response.message)
            } else {
              Alerts.error("Грешка", "невалидни данни!")
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }

export const filterHPOrders = (action$) =>
  action$.pipe(
    ofType(StorageTypes.FILTER_HP_ORDERS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/requests/hp/search`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StorageTypes.FILTER_HP_ORDERS_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
