import React, { useEffect, useState, useCallback } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getFilteredUsers } from '../../../actions'
import './styles.scss'

const UserFilter = ({ getUsersAction, users, onSelectUsers }) => {
  const [value, setValue] = useState('')
  const [checkedUsers, setCheckedUsers] = useState([])

  const sendQuery = (query) => getUsersAction({ fullName: query })
  const delayedQuery = useCallback(
    _.debounce((q) => sendQuery(q), 500),
    []
  )

  useEffect(() => {
    getUsersAction({})
  }, [getUsersAction])

  const renderUser = (user) => {
    return (
      <div key={user._id} className="user-filter-user-item">
        <div
          className="user-item-avatar"
          style={{ backgroundImage: `url(${user.coverPhoto || global.config.defaultAvatar})` }}
        />
        <div className="user-item-full-name">{user.fullName}</div>
        <div className="user-item-check-box">
          <Button.Checkbox
            onChange={() => {
              const checkedArr = checkedUsers.slice(0)
              const userIndex = checkedArr.findIndex((el) => el === user._id)
              if (userIndex === -1) {
                checkedArr.push(user._id)
              } else {
                checkedArr.splice(userIndex, 1)
              }
              setCheckedUsers(checkedArr)
            }}
            checked={checkedUsers.includes(user._id)}
            noLabel
          />
        </div>
      </div>
    )
  }

  return (
    <div className="user-filter-container">
      <h3 className="user-filter-title">Членове на екипа</h3>
      <Input.Text
        value={value}
        placeholder="Търси по име"
        onChange={(event) => {
          setValue(event.target.value)
          delayedQuery(event.target.value)
        }}
      />
      <div className="user-filter-scroll-container">{users.map((user) => renderUser(user))}</div>
      <div className="user-filter-actions">
        <Button.Raised
          text="Виж"
          clear
          onClick={() => {
            if (onSelectUsers) {
              onSelectUsers.call(null, checkedUsers)
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  users: state.user.filteredUsers,
})

const mapDispatchToProps = (dispatch) => ({
  getUsersAction: (payload) => dispatch(getFilteredUsers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserFilter)
