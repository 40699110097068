import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { ActivityTypes, GeneralTypes, getActivitiesSuccess } from "../actions"
import { API_URL } from "../config/settings"

export const getActivitiesEpic = (action$) => {
  return action$.pipe(
    ofType(ActivityTypes.GET_ACTIVITIES),
    switchMap(({ payload }) => {
      return Api.get(
        `${API_URL}/activities/${payload.page}/${payload.limit}`
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(
              getActivitiesSuccess({
                activities: response.activities,
                page: payload.page,
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
