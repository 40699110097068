import React, { useEffect, useState } from "react"
import { Inputs } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import { validations, defaultValues } from "./contactValidations"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import "reactjs-popup/dist/index.css"
import { history } from "../../../config/stores"
import ImageCropComponent from "../../../components/ImageCropComponent/ImageCropComponent"
import { getRawImageData, getVision } from "../../../utilities/helpers"
import {
  deleteContact,
  editContact,
  getCurrentContact,
  getCurrentUser,
  hideLoading,
  showLoading,
} from "../../../actions"
import ContactForm from "../../../components/ContactComponents/ContactForm"
import "../styles.scss"
import moment from "moment"

var steps = [
  { label: "1.Имена", field: "name", completed: false, opened: true },
  { label: "2.Позиция", field: "position", completed: false, opened: false },
  {
    label: "3.Компания",
    field: "companyName",
    completed: false,
    opened: false,
  },
  { label: "4.Имейл", field: "email", completed: false, opened: false },
  { label: "5.Телефон", field: "phoneNumber", completed: false, opened: false },
]

const EditContactFromImage = () => {
  const isMobile = window.innerWidth < 950
  const dispatch = useDispatch()

  const contactId = window.location.href.split("/")[5]
  const croppedImage =
    useSelector(({ contacts }) => contacts?.croppedData) || null
  const currentUser = useSelector((state) => state.user.currentUser)
  const canEditDelete = currentUser?._id === "5fa2a91bf689c74baedd3a6b"

  const [cropScreen, setCropScreen] = useState({ open: true, step: 0 })

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validations),
    mode: "onSubmit",
    defaultValues: defaultValues,
  })
  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = methods

  useEffect(() => {
    dispatch(getCurrentUser())
    dispatch(
      getCurrentContact({
        _id: contactId,
        onSuccess: (res) => {
          setValue("name", res?.name)
          setValue("position", res?.position)
          setValue("companyName", res?.companyName)
          setValue("phoneNumber", res?.phoneNumber)
          setValue("email", res?.email)
          setValue("country", res?.country)
          setValue("contactFrom", res?.contactFrom)
          setValue("files", res?.files)
          setValue("mainComment", res?.mainComment)
          setValue(
            "forProducts",
            res?.forProducts?.map((prod) => ({ label: prod, value: prod }))
          )
          setValue("comments", res?.comments)
        },
      })
    )
  }, [])

  useEffect(() => {
    if (!croppedImage) setCropScreen({ open: false, step: null })
    else setCropScreen({ open: true, step: 0 })
  }, [croppedImage])

  const handleNextStep = async (res) => {
    if (res) {
      const result = await getVision(getRawImageData(res?.base64)).then(
        (res) => {
          const currentStep = steps[cropScreen.step]
          if (!watch("files").length)
            setValue("files", [
              {
                url: croppedImage?.url,
                name: croppedImage?.url?.split("/")?.[4],
                createdAt: moment().toISOString(),
                from: currentUser?.fullName,
              },
            ])
          if (res?.responses?.[0]?.fullTextAnnotation?.text) {
            setValue(
              `${currentStep.field}`,
              res?.responses?.[0]?.fullTextAnnotation?.text
            )
            if (cropScreen.step === 4)
              setCropScreen({ open: false, step: null })
            else {
              setCropScreen((prevState) => ({
                ...prevState,
                step: prevState.step + 1,
              }))
              steps[cropScreen.step].completed = true
              steps[cropScreen.step].opened = false

              steps[cropScreen.step + 1].opened = true
            }
          }
          dispatch(hideLoading())
        }
      )
    } else {
      if (cropScreen.step === 4) setCropScreen({ open: false, step: null })
      else {
        setCropScreen((prevState) => ({
          ...prevState,
          step: prevState.step + 1,
        }))
        steps[cropScreen.step].completed = true
        steps[cropScreen.step].opened = false

        steps[cropScreen.step + 1].opened = true
      }
      if (!watch("files").length)
        setValue("files", [
          {
            url: croppedImage?.url,
            name: croppedImage?.url?.split("/")?.[4],
            createdAt: moment().toISOString(),
            from: currentUser?.fullName,
          },
        ])
      dispatch(hideLoading())
    }
  }

  const handleEdit = (e) =>
    dispatch(
      editContact({
        ...e,
        ...(e?.forProducts && {
          forProducts: e?.forProducts?.map((prod) => prod?.value),
        }),
        _id: contactId,
        onSuccess: (res) => {
          steps = [
            { label: "1.Имена", field: "name", completed: false, opened: true },
            {
              label: "2.Позиция",
              field: "position",
              completed: false,
              opened: false,
            },
            {
              label: "3.Компания",
              field: "companyName",
              completed: false,
              opened: false,
            },
            {
              label: "4.Имейл",
              field: "email",
              completed: false,
              opened: false,
            },
            {
              label: "5.Телефон",
              field: "phoneNumber",
              completed: false,
              opened: false,
            },
          ]
          history.push("/contacts")
        },
      })
    )

  const handleDelete = (e) =>
    dispatch(
      deleteContact({
        _id: contactId,
        onSuccess: (res) => history.push("/contacts"),
      })
    )

  return (
    <div className="main-container" style={{ height: "93%" }}>
      <div className="inner-header-container">
        <div className="flex w-full justify-between items-center">
          <h3 className="inner-title" style={{ marginLeft: "0px" }}>
            Редактирай контакт
          </h3>
          <div
            className="flex items-center"
            style={{ width: "260px", marginRight: "10px", gap: "10px" }}
          >
            <Inputs.Button
              text="Изтрий"
              className="delete"
              disabled={cropScreen.open}
              style={{ width: "130px" }}
              onClick={() => handleDelete()}
            />
            <Inputs.Button
              text="Запази"
              selected
              disabled={cropScreen.open}
              style={{ width: "130px" }}
              onClick={handleSubmit(handleEdit)}
            />
          </div>
        </div>
      </div>
      {cropScreen.open ? (
        <div
          className="body-container"
          style={{ display: "flex", gap: "10px" }}
        >
          <div className="cropper-container">
            <ImageCropComponent
              image={croppedImage?.base64}
              outerStyles={{ background: true }}
              options={{ clear: true, open: false, skip: true }}
              onSuccess={async (res) => {
                dispatch(showLoading())
                handleNextStep(res)
              }}
            />
          </div>

          <div
            className="flex column full-height steps-container"
            style={{ gap: "10px", padding: "5px" }}
          >
            {steps.map((step) => (
              <>
                <div className="w-full">
                  <div
                    className="flex items-center w-full"
                    style={{ marginBottom: "5px" }}
                  >
                    <div
                      className={`step ${
                        step.completed ? "checked" : "unchecked"
                      }`}
                    />
                    <b className="step-text">{step.label}</b>
                  </div>
                  {step.opened && (
                    <span style={{ fontSize: "14px" }}>
                      Оградете посочената информация от визитката и потвърдете
                    </span>
                  )}
                </div>
                <div className="divider" />
              </>
            ))}
          </div>
        </div>
      ) : (
        <ContactForm
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
          currentUser={currentUser}
          canEdit
        />
      )}
    </div>
  )
}

export default EditContactFromImage
