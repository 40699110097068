export const VariationsTypes = {
  GET_VARIATIONS: 'variations/GET_VARIATIONS',
  GET_VARIATIONS_SUCCESS: 'variations/GET_VARIATIONS_SUCCESS',
}

export const getVariations = (payload) => ({
  type: VariationsTypes.GET_VARIATIONS,
  payload,
})

export const getVariationsSuccess = (payload) => ({
  type: VariationsTypes.GET_VARIATIONS_SUCCESS,
  payload,
})
