import React, { useState, useRef, useEffect } from "react"
import { Input, Button, Section } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import {
  QuantityDropdownInput,
  PaymentPopup,
  ClientAutocomplete,
} from "../../../components"
import {
  hideModal,
  orderHPRequest,
  showLoading,
  hideLoading,
} from "../../../actions"
import Files from "../../../utilities/files"
import moment from "moment"
import "./styles.scss"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

function ReadyProductOrderModal({
  hideModal,
  data,
  orderHPRequest,
  showLoading,
  hideLoading,
}) {
  const [products, setProducts] = useState([])
  const [supplier, setSupplier] = useState({
    uic: "",
    mol: "",
    firmName: "",
    address: "",
  })
  const [invoice, setInvoice] = useState(null)
  const [additionalFiles, setAdditionalFiles] = useState([])
  const [typeOfPayment, setTypeOfPayment] = useState("")
  const [payments, setPayments] = useState([
    {
      percent: "",
      date: "",
    },
    {
      percent: "",
      date: "",
    },
  ])
  const [comment, setComment] = useState("")
  let fileInput = useRef()
  let moreFiles = useRef()
  let paymentPopup = useRef()

  useEffect(() => {
    if (data) {
      setProducts(
        data.map((product) => {
          return {
            _id: product._id,
            product: product.product,
            quantity: product.quantity,
            units: product.units,
            deliveryDate: moment(product.deliveryDate).toDate(),
          }
        })
      )
    }
  }, [data])
  return (
    <div className="ready-product-order-container">
      <div className="top-container">
        <h3>Нова поръчка</h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      {products.map((product, index) => {
        return (
          <div className="flex-container space-between border-bottom">
            <div className="input-element width50 no-margin">
              <p>Продукт</p>
              <Input.Text disabled value={product.product} />
            </div>
            <div className="input-element">
              <p>Количество</p>
              <Input.Text
                disabled
                value={`${product.quantity}  ${product.units}`}
              />
              {/* <QuantityDropdownInput
                disabled
                quantityTypeValue={product.units}
                quantityValue={product.quantity}
              /> */}
            </div>
            <div className="input-element">
              <p>Срок за доставка</p>
              <Input.Datepicker
                placeholder="Дата"
                disabled
                pickDate={product.deliveryDate}
              />
            </div>
            <div className="input-element">
              <p>Цена за единица</p>
              <Input.Text
                value={product.price}
                onChange={(event) => {
                  const newProducts = products.slice(0)
                  newProducts[index].price = event.target.value
                  setProducts(newProducts)
                }}
              />
            </div>
          </div>
        )
      })}

      <h3>Данни за доставчик и плащане</h3>
      <div className="flex-container">
        <div className="input-element no-margin">
          <p>Име на фирмата</p>
          <ClientAutocomplete
            onChooseClient={(data) => {
              setSupplier({
                uic: data.uic,
                mol: data.mol,
                firmName: data.name,
                address: data.address,
              })
            }}
            onChange={(event) => setSupplier({ firmName: event.target.value })}
          />
        </div>
        <div className="input-element">
          <p>ЕИК (БУЛСТАТ):</p>
          <Input.Text
            value={supplier.uic}
            onChange={(event) => {
              const newSupplier = { ...supplier }
              newSupplier.uic = event.target.value
              setSupplier(newSupplier)
            }}
          />
        </div>
        <div className="input-element">
          <p>МОЛ</p>
          <Input.Text
            value={supplier.mol}
            onChange={(event) => {
              const newSupplier = { ...supplier }
              newSupplier.mol = event.target.value
              setSupplier(newSupplier)
            }}
          />
        </div>
      </div>
      <div className="input-element width50 no-margin">
        <p>Адрес</p>
        <Input.Text
          value={supplier.address}
          onChange={(event) => {
            const newSupplier = { ...supplier }
            newSupplier.address = event.target.value
            setSupplier(newSupplier)
          }}
        />
      </div>
      <div className="flex-container">
        <div className="input-element no-margin">
          <p>Начин на плащане</p>
          <Button.Tab
            buttons={["Цялата сума", "Разсрочено плащане"]}
            onClick={(name) => setTypeOfPayment(name)}
          />
        </div>
        {typeOfPayment === "Разсрочено плащане" && (
          <div className="flex-container">
            <p id="bold">Вноски: {payments.length}</p>
            <Section.Popup
              ref={(ref) => {
                paymentPopup.current = ref
              }}
              content={
                <PaymentPopup
                  onSetPayments={(data) => {
                    setPayments(data)
                    paymentPopup.current.hide()
                  }}
                />
              }
            >
              <div
                className="eye-icon"
                onClick={() => paymentPopup.current.show()}
              />
            </Section.Popup>
          </div>
        )}
        <div className="input-element">
          <p>Добави файл:</p>
          <div className="flex-container">
            {!invoice ? (
              <>
                <input
                  type="file"
                  ref={(ref) => {
                    fileInput = ref
                  }}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    showLoading()
                    event.persist()
                    const name = event.target.files[0].name
                    Files.uploadFile(event.target.files[0])
                      .then((data) => {
                        hideLoading()
                        setInvoice({ name: name, url: data.location })
                      })
                      .catch((err) => {
                        hideLoading()
                      })
                  }}
                />
                <div
                  className="flex-container border-right"
                  onClick={() => fileInput && fileInput.click()}
                >
                  <p id="file">Фактура</p>
                  <div className="attach-icon" />
                </div>
              </>
            ) : (
              <div className="flex-container">
                <p id="file" onClick={() => window.open(invoice.url)}>
                  {invoice.name}
                </p>
                <div className="remove-icon" onClick={() => setInvoice(null)} />
              </div>
            )}
            <div
              className="flex-container"
              onClick={() => moreFiles && moreFiles.click()}
            >
              <input
                type="file"
                ref={(ref) => {
                  moreFiles = ref
                }}
                multiple
                style={{ display: "none" }}
                onChange={(event) => {
                  showLoading()
                  event.persist()
                  const nameArray = Object.keys(event.target.files).map(
                    (key) => {
                      return event.target.files[key].name
                    }
                  )
                  Files.uploadFiles(event.target.files)
                    .then((data) => {
                      let files = additionalFiles.slice(0)
                      data.forEach((el, index) => {
                        files.push({ name: nameArray[index], url: el.location })
                      })
                      hideLoading()
                      setAdditionalFiles(files)
                    })
                    .catch((err) => {
                      hideLoading()
                    })
                }}
              />
              <p id="file">Друг файл</p>
              <div className="attach-icon" />
            </div>
            <div className="flex-container scroll">
              {additionalFiles.map((file, index) => {
                return (
                  <div className="flex-container">
                    <p id="file" onClick={() => window.open(file.url)}>
                      {file.name}
                    </p>
                    <div
                      className="remove-icon"
                      onClick={() => {
                        let newAdditionalFiles = additionalFiles.slice(0)
                        newAdditionalFiles.splice(index, 1)
                        setAdditionalFiles(newAdditionalFiles)
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="input-element no-margin width50">
        <p>Коментар</p>
        <Input.Text
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </div>
      <div className="flex-container flex-end">
        <Button.Raised
          text="Поръчай"
          onClick={() => {
            if (!typeOfPayment) {
              Alerts.error("Грешка!", "Моля изберете начин на плащане!")
            } else {
              if (
                !supplier.uic ||
                !supplier.mol ||
                !supplier.firmName ||
                !supplier.address
              ) {
                Alerts.error("Грешка!", "Моля попълнете полетата!")
              } else {
                if (
                  products.findIndex((product) => product.price === "") !== -1
                ) {
                  Alerts.error("Грешка!", "Моля въведете цена за единица!")
                } else {
                  const payload = products.map((product) => {
                    return {
                      _id: product._id,
                      deliveryDate: product.deliveryDate,
                      quantity: product.quantity,
                      price: product.price,
                      provider: {
                        uic: supplier.uic,
                        mol: supplier.mol,
                        firmName: supplier.firmName,
                        address: supplier.address,
                      },
                      payments:
                        typeOfPayment === "Цялата сума"
                          ? [
                              {
                                dueDate: new Date(),
                                percent: 100,
                              },
                            ]
                          : payments.map((payment) => {
                              return {
                                dueDate: payment.date,
                                percent: payment.percent,
                              }
                            }),
                      comment: comment,
                      invoice: invoice,
                      files: additionalFiles,
                    }
                  })
                  orderHPRequest(payload)
                }
              }
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  orderHPRequest: (payload) => dispatch(orderHPRequest(payload)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadyProductOrderModal)
