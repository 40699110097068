import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { hideModal } from "../../../actions"

import "./styles.scss"

const StorageTotal = ({ total, hideModal }) => {
  const [reverseSort, setReverseSort] = useState(false)
  const [query, setQuery] = useState("")
  const [sortBy, setSortBy] = useState("material")
  const customSort = () => {
    let sorted = total.filter((el) => el.material.toLowerCase().includes(query))
    switch (sortBy) {
      case "quantity":
      case "price":
        sorted.sort((a, b) => {
          let tempA = a[sortBy] || 0
          let tempB = b[sortBy] || 0
          return reverseSort
            ? parseInt(tempA) - parseInt(tempB)
            : parseInt(tempB) - parseInt(tempA)
        })
        break
      default:
        return sorted.sort((a, b) =>
          reverseSort ? a[sortBy] < b[sortBy] : a[sortBy] > b[sortBy]
        )
    }
    return sorted
  }
  return (
    <div className="storage-total-container">
      <div className="modal-heading">
        <h3>Общо на склад</h3>
        <div className="exit-btn">
          <Button.Icon
            name="plus"
            color="#152331"
            onClick={hideModal}
            size={24}
          />
        </div>
      </div>
      <div className="modal-content">
        <Input.Text
          placeholder="Търси по име"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="storage-total-list-header row">
          <div className="row big">
            <span>Суровина:</span>
            <Button.Icon
              isIconOnly
              name="angle-down"
              color="#152331"
              size={14}
              onClick={() => {
                setReverseSort(sortBy === "material" ? !reverseSort : false)
                setSortBy("material")
              }}
              className={reverseSort && sortBy === "material" && "rotate"}
            />
          </div>
          <div className="row medium">
            <span>Общо на склад:</span>
            <Button.Icon
              isIconOnly
              name="angle-down"
              color="#152331"
              size={14}
              onClick={() => {
                setReverseSort(sortBy === "quantity" ? !reverseSort : false)
                setSortBy("quantity")
              }}
              className={reverseSort && sortBy === "quantity" && "rotate"}
            />
          </div>
          <div className="row medium">
            <span>Обща стойност:</span>
            <Button.Icon
              isIconOnly
              name="angle-down"
              color="#152331"
              size={14}
              onClick={() => {
                setReverseSort(sortBy === "price" ? !reverseSort : false)
                setSortBy("price")
              }}
              className={reverseSort && sortBy === "price" && "rotate"}
            />
          </div>
          <div className="medium flex">
            <span>Критично к-во:</span>
            <Button.Icon
              isIconOnly
              name="angle-down"
              color="#152331"
              size={14}
              onClick={() => {
                setReverseSort(sortBy === "price" ? !reverseSort : false)
                setSortBy("price")
              }}
              className={reverseSort && sortBy === "price" && "rotate"}
            />
          </div>
        </div>
        <div className="storage-total-list-content">
          {customSort().map((item, i) => {
            return (
              <div
                key={`total-${i}`}
                className={`single-item row ${
                  item.quantity < item.criticalQuantity && "red-border"
                }`}
              >
                <div className="big">
                  <p>{item.material}</p>
                </div>
                <div className="medium">
                  <p>{item.quantity}</p>
                </div>
                <div className="medium">
                  <p>{(item.quantity * item.price).toFixed(2)} лв.</p>
                </div>
                <div className="medium flex">
                  <p>{item.criticalQuantity || "- -"}</p>
                  <div className="edit" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StorageTotal)
