import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Button, Input, Section } from "@makedonski/admin-ui"
import {
  hideModal,
  setModalWidth,
  searchStorageProducts,
  resetStorageResults,
  getInquiries,
  createStorageProduct,
  editStorageProduct,
  searchRequests,
} from "../../../actions"
import { ExpensePopup } from "./ExpensePopup"
import {
  QuantityDropdownInput,
  SingleRadioButton,
  UploadFile,
  Inputs,
} from "../../../components"
import _ from "lodash"
import Files from "../../../utilities/files"
import "./styles.scss"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

const units = [
  { label: "Метър", value: "м" },
  { label: "Брой", value: "бр" },
  { label: "Килограм", value: "кг" },
  { label: "Литър", value: "л" },
  { label: "Кубик", value: "м3" },
]

const onChange = (event, search) => {
  event.persist()
  onChangeDelayed(event, search)
}

const onChangeDelayed = _.debounce(
  (event, search) => search({ search: event.target.value }),
  300
)

const AddProjectActivityModal = ({
  hide,
  setModalWidth,
  storageProductResults,
  searchStorageProducts,
  resetStorageResults,
  inquiries,
  getInquiries,
  createStorageProduct,
  editStorageProduct,
  searchMaterials,
  storageMaterialResults,
}) => {
  const [pricing, setPricing] = useState("Фиксирана")
  const [quantityType, setQuantityType] = useState(units[0])
  const [quantityValue, setQuantityValue] = useState("")
  const [quantityPrice, setQuantityPrice] = useState("")
  const [storageProduct, setStorageProduct] = useState("")
  const [productVariation, setProductVariation] = useState("")
  const [criticalQuantity, setCriticalQuantity] = useState("")
  const [comment, setComment] = useState("")
  const [project, setProject] = useState("")
  const [files, setFiles] = useState([])
  const [material, setMaterial] = useState([])
  const [dropdownIndex, setDropdownIndex] = useState(-1)
  const [custom, setCustom] = useState([
    {
      type: "",
      elements: [
        {
          description: "",
          total: "",
        },
      ],
    },
  ])
  let expensePopup = useRef()
  const deleteIcon = require('../../../assets/icons/remove-btn.svg')

  useEffect(() => {
    getInquiries()
  }, [getInquiries])

  return (
    <div className="add-storage-product-modal-container">
      <div className="top-container">
        <h3>Добавяне на продукт</h3>
        <div className="close-btn" onClick={() => hide()} />
      </div>
      <div className="flex-container">
        <div
          className={`left-container ${pricing === "Себестойност" && "active"}`}
        >
          <div className="input-container small">
            <p>Вид продукт</p>
            <div className="input-wrapper">
              <Input.Text
                value={
                  typeof storageProduct === "string"
                    ? storageProduct
                    : storageProduct.type
                }
                onFocus={() => setDropdownIndex(-1)}
                onChange={(event) => {
                  onChange(event, searchStorageProducts)
                  setStorageProduct(event.target.value)
                }}
              />
              {dropdownIndex === -1 && storageProductResults.length > 0 && (
                <div className="client-results-dropdown">
                  {storageProductResults.map((product) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setStorageProduct(product)
                          setProductVariation(product.variation)
                          const unit = units.find(
                            (el) => el.value === product.units
                          )
                          setFiles(product.files)
                          setQuantityType(unit)
                          setQuantityPrice(product.pricing.singleUnitPrice)
                          setComment(product.comment)
                          setCriticalQuantity(product.criticalQuantity)
                          if (product.inquiry) {
                            setProject(product.inquiry)
                          } else {
                            setProject("")
                          }

                          resetStorageResults()
                        }}
                      >
                        {product.type} ({product.variation})
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <p>Вариация</p>
          <Input.Text
            value={productVariation}
            onChange={(event) => setProductVariation(event.target.value)}
          />
          <div className="flex-container space-between">
            <div className="input-element small">
              <div className="flex-container">
                <p>Количество </p>
                {typeof storageProduct !== "string" && (
                  <span>{`(Общо ${storageProduct.quantity})`}</span>
                )}
              </div>
              <QuantityDropdownInput
                quantityTypeValue={quantityType}
                quantityValue={quantityValue}
                onSelectType={(value) => setQuantityType(value)}
                onSetQuantity={(value) => setQuantityValue(value)}
                disableUnits
              />
            </div>
            <div className="input-element large">
              <p>Ценообразуване</p>
              <div className="flex-container space-between margin">
                <SingleRadioButton
                  label="Фиксирана"
                  selected={pricing === "Фиксирана"}
                  onClick={(value) => {
                    setModalWidth("600px")
                    setPricing(value)
                  }}
                />
                <SingleRadioButton
                  label="Себестойност"
                  selected={pricing === "Себестойност"}
                  onClick={(value) => {
                    setModalWidth("1300px")
                    setPricing(value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex-container">
            <div className="input-element small">
              <p>Цена</p>
              <QuantityDropdownInput
                noDropdown
                quantityValue={quantityPrice}
                quantityTypeValue={quantityType}
                onSetQuantity={(value) => setQuantityPrice(value)}
              />
            </div>
            <div className="critical-quantity-container">
              <span>Критично количество</span>
              <Inputs.UnitDropdown
                textValue={criticalQuantity}
                onTextChange={(criticalQuantity) =>
                  setCriticalQuantity(criticalQuantity)
                }
                unitsValue={quantityType.value}
                onUnitsChange={(units) => {}}
                unitsDisabled
              />
            </div>
          </div>
          <p>Проект</p>
          <Input.Dropdown
            placeholder="Проект"
            value={project ? `${project.object} (${project.client})` : ""}
            options={inquiries.map((el) => el.object)}
            onChange={(value) => {
              const project = inquiries.find((el) => el.object === value.value)
              setProject(project)
            }}
          />
          <div className="flex-container margin column">
            <div className="flex-container files">
            <p>Файлове</p>
            <UploadFile
              multiple
              onChange={(e) => {
                Files.uploadFiles(e.target.files).then((result) => {
                  const fileArray = result.map((file) => file.location)
                  setFiles(fileArray)
                })
              }}
            />

            </div>
            <div className="horizontal-scroll-container">
              {files.map((file) => (
                <div className="uploaded-file-wrapper">
                  <p id="small" onClick={() => window.open(file)}>
                    {file.slice(36)}
                  </p>
                  <img src={deleteIcon}
                  onClick={() => {
                    let newFiles = files.filter(el => el !== file)
                    setFiles(newFiles)
                  }} />

                  {/* <div className="full-name-wrapper">{file}</div> */}
                </div>
              ))}
            </div>
          </div>
          <p>Коментар</p>
          <Input.Text
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
          <Button.Raised
            text={typeof storageProduct === "string" ? "Добави" : "Редактирай"}
            className="button-green"
            onClick={() => {
              if (
                (pricing === "Фиксирана" && !quantityPrice) ||
                !storageProduct
              ) {
                Alerts.error("Грешка!", "Моля попълнете полетата")
              } else {
                if (pricing === "Фиксирана") {
                  if (typeof storageProduct === "string") {
                    const data = {
                      type: storageProduct,
                      variation: productVariation,
                      quantity: quantityValue,
                      pricing: {
                        type: "fixed",
                        singleUnitPrice: isNaN(quantityPrice)
                          ? parseFloat(quantityPrice.replace(",", "."))
                          : quantityPrice,
                      },
                      inquiry: project._id,
                      units: quantityType.value,
                      files: files,
                      criticalQuantity: criticalQuantity,
                      comment: comment,
                    }
                    createStorageProduct(data)
                  } else {
                    const data = {
                      _id: storageProduct._id,
                      type: storageProduct.type,
                      variation: productVariation,
                      quantity: quantityValue ? parseInt(quantityValue) : 0,
                      pricing: {
                        type: "fixed",
                        singleUnitPrice: isNaN(quantityPrice)
                          ? parseFloat(quantityPrice.replace(",", "."))
                          : quantityPrice,
                      },
                      inquiry: project && project._id,
                      units: quantityType.value,
                      criticalQuantity: criticalQuantity,
                      files: files,
                      comment: comment,
                    }
                    editStorageProduct(data)
                  }
                } else {
                  if (typeof storageProduct === "string") {
                    const materialData = material.map((el) => {
                      return {
                        name: el.name,
                        kind: el.kind,
                        ratio: null,
                        quantity: el.quantity,
                        total: el.total,
                      }
                    })
                    const data = {
                      type: storageProduct,
                      variation: productVariation,
                      quantity: quantityValue,
                      pricing: {
                        type: "selfcost",
                        // singleUnitPrice: quantityPrice,
                        data: {
                          material: materialData,
                          other: custom,
                        },
                      },
                      criticalQuantity: criticalQuantity,
                      inquiry: project._id,
                      units: quantityType.value,
                      files: files,
                      comment: comment,
                    }
                    createStorageProduct(data)
                  } else {
                    const materialData = material.map((el) => {
                      return {
                        name: el.name,
                        kind: el.kind,
                        ratio: null,
                        quantity: el.quantity,
                        total: el.total,
                      }
                    })
                    const data = {
                      _id: storageProduct._id,
                      type: storageProduct.type,
                      variation: productVariation,
                      quantity: quantityValue || 0,
                      pricing: {
                        type: "selfcost",
                        // singleUnitPrice: quantityPrice,
                        data: {
                          material: materialData,
                          other: custom,
                        },
                      },
                      inquiry: project._id,
                      criticalQuantity: criticalQuantity,
                      units: quantityType.value,
                      files: files,
                      comment: comment,
                    }
                    editStorageProduct(data)
                  }
                }
              }
            }}
          />
        </div>
        {pricing === "Себестойност" && (
          <div className="right-container">
            <h3>Разход за материали</h3>
            <div className="flex-container">
              <p>Добави разход за материал</p>
              <Section.Popup
                ref={(ref) => {
                  expensePopup = ref
                }}
                content={
                  <ExpensePopup
                    onlyQuantity
                    createExpense={(values) => {
                      let newMaterial = material.slice(0)
                      newMaterial.push({
                        ...values,
                        name: "",
                        kind: "",
                        total: 0,
                      })
                      setMaterial(newMaterial)
                      expensePopup.hide()
                    }}
                  />
                }
              >
                <Button.Icon
                  name="plus"
                  color="#26ACE2"
                  className="icon-button-add"
                  onClick={() => expensePopup.show()}
                />
              </Section.Popup>
            </div>
            {material.map((element, index) => {
              return (
                <div className="flex-container space-between">
                  <div className="input-container small">
                    <p>Вид</p>
                    <div className="input-wrapper">
                      <Input.Text
                        value={element.name}
                        onFocus={() => {
                          setDropdownIndex(index)
                        }}
                        onChange={(event) => {
                          let newMaterial = material.slice(0)
                          newMaterial[index].name = event.target.value
                          setMaterial(newMaterial)
                          if (element.storageType === "Суровини") {
                            onChange(event, searchMaterials)
                          } else {
                            onChange(event, searchStorageProducts)
                          }
                        }}
                      />
                      {dropdownIndex === index &&
                      element.storageType === "Суровини"
                        ? storageMaterialResults.length > 0 && (
                            <div className="client-results-dropdown">
                              {storageMaterialResults.map((product) => {
                                return (
                                  <div
                                    className="result"
                                    onClick={() => {
                                      let newMaterial = material.slice(0)
                                      newMaterial[index].name = product.material
                                      newMaterial[index].kind = product.material
                                      newMaterial[index].total =
                                        product.price *
                                        newMaterial[index].quantity
                                      newMaterial[index].singleUnitPrice =
                                        product.price
                                      setMaterial(newMaterial)
                                      resetStorageResults()
                                    }}
                                  >
                                    {product.material}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        : dropdownIndex === index &&
                          storageProductResults.length > 0 && (
                            <div className="client-results-dropdown">
                              {storageProductResults.map((product) => {
                                return (
                                  <div
                                    className="result"
                                    onClick={() => {
                                      let newMaterial = material.slice(0)
                                      newMaterial[index].name = product.type
                                      newMaterial[index].kind = product.type
                                      newMaterial[index].total =
                                        product.pricing.singleUnitPrice *
                                        newMaterial[index].quantity
                                      newMaterial[index].singleUnitPrice =
                                        product.pricing.singleUnitPrice
                                      setMaterial(newMaterial)
                                      resetStorageResults()
                                    }}
                                  >
                                    {product.type}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                    </div>
                  </div>
                  <div className="input-element small">
                    <p>
                      {element.quantity === null ? "Съотношение" : "Количество"}
                    </p>
                    <div className="quantity-input-wrapper">
                      <Input.Text
                        value={element.quantity}
                        onChange={(event) => {
                          if (!isNaN(parseInt(event.target.value))) {
                            let newMaterial = material.slice(0)
                            newMaterial[index].quantity = event.target.value
                            newMaterial[index].total =
                              parseInt(event.target.value) *
                              element.singleUnitPrice
                            setMaterial(newMaterial)
                          }
                        }}
                      />
                      <div className="quantity-type-container">
                        {element.quantity === null ? "%" : "бр"}
                      </div>
                    </div>
                  </div>
                  <div className="input-element coloured">
                    <p>Разход</p>
                    <div className="total-expense">{element.total}лв.</div>
                  </div>
                </div>
              )
            })}
            {custom.map((expense, index) => {
              return (
                <div className="custom-expense-container">
                  <div className="flex-container">
                    <p>Вид</p>
                    <div
                      className="delete-btn"
                      onClick={() => {
                        let newCustom = custom.slice(0)
                        newCustom.splice(index, 1)
                        setCustom(newCustom)
                      }}
                    />
                  </div>
                  <Input.Text
                    value={expense.type}
                    onChange={(event) => {
                      let newCustom = custom.slice(0)
                      newCustom[index].type = event.target.value
                      setCustom(newCustom)
                    }}
                  />
                  {expense.elements.map((element, elIndex) => {
                    return (
                      <div className="flex-container">
                        <div className="input-element big">
                          <p>Описание</p>
                          <Input.Text
                            value={element.description}
                            onChange={(event) => {
                              let newCustom = custom.slice(0)
                              newCustom[index].elements[elIndex].description =
                                event.target.value
                              setCustom(newCustom)
                            }}
                          />
                        </div>
                        <div className="input-element">
                          <p>Разход</p>
                          <Input.Text
                            value={element.total}
                            onChange={(event) => {
                              let newCustom = custom.slice(0)
                              newCustom[index].elements[elIndex].total =
                                event.target.value
                              setCustom(newCustom)
                            }}
                          />
                        </div>
                        <div
                          className="delete-btn"
                          onClick={() => {
                            let newCustom = custom.slice(0)
                            newCustom[index].elements.splice(elIndex, 1)
                            setCustom(newCustom)
                          }}
                        />
                      </div>
                    )
                  })}
                  <Button.Icon
                    name="plus"
                    color="#26ACE2"
                    onClick={() => {
                      let newCustom = custom.slice(0)
                      newCustom[index].elements.push({
                        description: "",
                        total: "",
                      })
                      setCustom(newCustom)
                    }}
                  />
                </div>
              )
            })}
            <div className="flex-container">
              <p>Добави нов вид разход</p>
              <Button.Icon
                name="plus"
                color="#26ACE2"
                onClick={() => {
                  let newCustom = custom.slice(0)
                  newCustom.push({
                    type: "",
                    elements: [
                      {
                        description: "",
                        total: "",
                      },
                    ],
                  })
                  setCustom(newCustom)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  storageProductResults: state.storage.storageProductResults,
  inquiries: state.inquiries.data,
  storageMaterialResults: state.storage.search,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  setModalWidth: (payload) => dispatch(setModalWidth(payload)),
  searchStorageProducts: (payload) => dispatch(searchStorageProducts(payload)),
  resetStorageResults: () => dispatch(resetStorageResults()),
  getInquiries: () => dispatch(getInquiries()),
  createStorageProduct: (payload) => dispatch(createStorageProduct(payload)),
  editStorageProduct: (payload) => dispatch(editStorageProduct(payload)),
  searchMaterials: (payload) => dispatch(searchRequests(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProjectActivityModal)
