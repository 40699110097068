import React, { useEffect, useState } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import { connect } from "react-redux";
import moment from "moment";
import InquiryOfferElement from "../InquiryOfferElement/InquiryOfferElement";
import { TableHeaderInquiry, InquiryClientElement } from "../../components"
import { getInquiryOffers, searchInquiryOffers, filterInquiryDatesOffers, showLoading } from "../../actions"
import _ from "lodash"
import "../InquiriesClientsComponent/styles.scss";
import "./InquiriesOffersComponent.scss";
import "../InquiryClientElement/styles.scss";
import "../InquiriesClientsComponent/InquiriesHeader.scss";
import InquiriesHeader from "../InquiriesClientsComponent/InquiriesHeader";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts";

const headerElements = [
  {
    name: "Оферта №",
    size: 1.3,
    fixedWidth: 120,
    filter: true,
    sort: false
  },
  {
    name: "Клиент",
    size: 2,
    fixedWidth: 150,
    filter: true,
    sort: false
  },
  {
    name: "Обект",
    size: 2,
    fixedWidth: 150,
    // filter: true
  },
  {
    name: "Продукти",
    size: 2,
    fixedWidth: 150
  },
  {
    name: "Обща стойност",
    size: 2,
    fixedWidth: 150,
    // filter: true
  },

  {
    name: "Създадена на",
    size: 2,
    fixedWidth: 150,
    filter: true,
    sort: false
  },
  {
    name: "Създадена от",
    size: 2,
    fixedWidth: 150,
    // filter: true,
    // sort: false
  },
  {
    name: "Последна редакция",
    size: 3.5,
    fixedWidth: 220,
    filter: true,
    sort: false
  },
]

const InquiriesOffersComponent = ({ pageNumber }) => {
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState(true);
  const [selectedSortValue, setSelectedSortValue] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("client");
  const [searchDateValue, setSearchDateValue] = useState("createdAt");
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const [offersTab, setOfferspartiesTab] = useState('offers');
  const offersInnerRef = useRef();

  const offersData = useSelector(state => state.inquiries.offersData);

  // const filterOptions = [{label: 'Име на клиент', value: 'client'}, {label: 'Обект', value: 'object'}];
  
  const filterOptions = {
    'client': 'Име на клиент',
    'object': 'Обект'
  }

  const dateFilterOptions = {
    "createdAt": "Дата на създаване",
    "updatedAt": "Последна редакция"
  } 

  // const dateFilterOptions = [
  //   { label: "Дата на създаване", value: "createdAt" },
  //   { label: "Последна редакция", value: "updatedAt" },
  // ]

  const selectedSortValues = {
    'Оферта №': 'refNumber',
    'Клиент': 'client',
    'Обект': 'object',
    'Обща стойност': 'total',
    'Създадена на': 'createdAt',
    'Създадена от': 'createdBy',
    'Последна редакция': 'updatedAt',
  }

  const dispatch = useDispatch();

  const handleScrolling = () => {
    if (offersInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = offersInnerRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && offersData.hasNextPage && scrollTop !== 0) {
        loadPageOnScroll()
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  const loadPageOnScroll = () => {
    let payloadObj = {pageNumber: offersData.nextPage}

    if (searchQuery) {
      payloadObj[searchValue] = searchQuery;
    }
    if (areDatesSelected) {
      payloadObj.dateField = searchDateValue;
      payloadObj.from = moment(startDate).startOf("day");
      payloadObj.to = moment(endDate).endOf("day");
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue];
      payloadObj.sortBy = { [sortType]: sort ? 1 : -1 };
    }

    dispatch(showLoading())
    dispatch(searchInquiryOffers(payloadObj)) 
  }

  const handleSearch = () => {
    offersInnerRef.current && offersInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    let searchPayload = { [searchValue]: searchQuery, pageNumber: 1, pageSize: 20 }

    if (areDatesSelected) {
      searchPayload.dateField = searchDateValue
      searchPayload.from = moment(startDate).startOf("day")
      searchPayload.to = moment(endDate).endOf("day")
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue]
      searchPayload.sortBy = { [sortType]: sort ? 1 : -1 }
    }

    dispatch(showLoading())
    dispatch(searchInquiryOffers(searchPayload));
  }

  const handleDatesFilter = () => {
    if (moment(startDate).startOf("day").isAfter(moment(endDate).startOf("day"))) {
      return Alerts.error("Зададената начална дата на търсене е след крайната дата!")
    }

    offersInnerRef.current && offersInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    let searchPayload = { dateField: searchDateValue, from: moment(startDate).startOf("day"), to: moment(endDate).endOf("day"), pageNumber: 1, pageSize: 20 }
    setAreDatesSelected(true);

    if (searchQuery) {
      searchPayload[searchValue] = searchQuery
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue]
      searchPayload.sortBy = { [sortType]: sort ? 1 : -1 }
    }

    dispatch(showLoading())
    dispatch(filterInquiryDatesOffers(searchPayload))
  }

  const handleSort = (type, sortValue) => {
    let sortType = selectedSortValues[type];
    let payloadSort = { 
      sortBy: { [sortType]: sortValue ? 1 : -1 },
      pageNumber: 1,
      pageSize: 20
    };

    if (searchQuery) {
      payloadSort[searchValue] = searchQuery;
    }
    if (areDatesSelected) {
      payloadSort.from = moment(startDate).startOf("day");
      payloadSort.to = moment(endDate).endOf("day");
      payloadSort.dateField = searchDateValue;
    }

    offersInnerRef.current && offersInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    setSort(sortValue)
    setSelectedSortValue(type)
    dispatch(showLoading())
    dispatch(getInquiryOffers(payloadSort))
  }

  useEffect(() => {
    dispatch(showLoading())
    dispatch(getInquiryOffers({ pageNumber: 1 }))
  }, []);

  return (
    <div className="inquiries-clients-component offers">
      <InquiriesHeader 
        headerElements={headerElements}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={filterOptions}
        startDate={startDate}
        endDate={endDate}
        tab={offersTab}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        dateFilterOptions={dateFilterOptions}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        searchDateValue={searchDateValue}
        setSearchDateValue={setSearchDateValue}
        handleDatesFilter={handleDatesFilter}
        areDatesSelected={areDatesSelected}
        setAreDatesSelected={setAreDatesSelected}
        selectedSortValue={selectedSortValue}
        setSelectedSortValue={setSelectedSortValue}
        sort={sort}
        setSort={setSort} />


      <TableHeaderInquiry
        elements={headerElements}
        justifyContent="flex-start"
        handleSort={handleSort}
        selectedSortValue={selectedSortValue}
      />
      <div
        className="scroll-container"
        ref={offersInnerRef} onScroll={delayedScrolling}
      >

        {offersData.docs?.map((offer, i) => {
          return <InquiryOfferElement key={offer.refNumber * i} data={offer} />
        })}

      </div> 
    </div>
  )
}

export default InquiriesOffersComponent;
