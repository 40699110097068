import React from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import {
  hideModal,
  showLoading,
  hideLoading,
  updateProductAction,
} from "../../../actions"

import { KMUView } from "./KMUView"
import { TubeView } from "./TubeView"
import { MineView } from "./MineView"
import { LinearDrainageView } from "./LinearDrainageView"
import { ReservoirView } from "./ReservoirView"
import { FittingsView } from "./FittingsView"
import { OthersView } from "./OthersView"
import "./styles.scss"

const handleType = (product, setProduct, loading, stopLoading) => {
  switch (product.productType.name) {
    case "КМУ":
      return (
        <KMUView
          product={product}
          setProduct={setProduct}
          loading={loading}
          stopLoading={stopLoading}
        />
      )
    case "Тръба":
      return (
        <TubeView
          product={product}
          setProduct={setProduct}
          loading={loading}
          stopLoading={stopLoading}
        />
      )
    case "Шахти":
      return (
        <MineView
          product={product}
          setProduct={setProduct}
          loading={loading}
          stopLoading={stopLoading}
        />
      )
    case "Линеен отводнител":
      return (
        <LinearDrainageView
          product={product}
          setProduct={setProduct}
          loading={loading}
          stopLoading={stopLoading}
        />
      )
    case "Резервоари":
      return (
        <ReservoirView
          product={product}
          setProduct={setProduct}
          loading={loading}
          stopLoading={stopLoading}
        />
      )
    case "Фитинги":
      return (
        <FittingsView
          product={product}
          setProduct={setProduct}
          loading={loading}
          stopLoading={stopLoading}
        />
      )
    case "Други":
      return (
        <OthersView
          product={product}
          setProduct={setProduct}
          loading={loading}
          stopLoading={stopLoading}
        />
      )
    default:
      return <div>Unknown Type</div>
  }
}

const EditProduct = ({ hide, data, loading, stopLoading, update }) => {
  const [product, setProduct] = React.useState(data)
  const updateProduct = (key, value) => {
    const prod = { ...product }
    prod[key] = value
    setProduct(prod)
  }

  return (
    <div className="add-product-type-modal-container">
      <div className="flex-container space-between">
        <h1>Редакция на продукт</h1>
        <div className="exit-btn" onClick={() => hide()} />
      </div>
      <div className="flex-wrapper">
        {handleType(product, updateProduct, loading, stopLoading)}
      </div>
      <div className="flex-wrapper">
        <Button.Raised
          text="Редактирай"
          onClick={() => {
            update(product)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  loading: (payload) => dispatch(showLoading(payload)),
  stopLoading: () => dispatch(hideLoading()),
  update: (payload) => dispatch(updateProductAction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)
