import React, { Component } from "react"
import { connect } from "react-redux"
import { Icon } from "@makedonski/admin-ui"
import {
  Empty,
  ProductView,
  ProductDetails,
  ProductHistoryElement,
} from "../../components"
import {
  setUserWorkingStatus,
  getSingleProduct,
  showModal,
  getProductTypes,
  getProviders,
} from "../../actions"
import { getProductData, getProductNameKMU } from "../../utilities/helpers"
import "./styles.scss"

class Product extends Component {
  componentDidMount() {
    this.props.getSingleProduct({ id: this.props.match.params.id })
    this.props.getProductTypes()
    this.props.getProviders()
  }

  getExpenseModalType(type) {
    if (type.name === "Тръба") {
      return "addTubeExpenses"
    }
    return "addOtherExpenses"
  }

  calculateTotalValue() {
    if (this.props.product.expenses) {
      let values = []
      values = values.concat(
        this.props.product.expenses.material.map((el) => el.total)
      )
      values = values.concat(
        this.props.product.expenses.staff.map((el) => el.total)
      )
      values = values.concat(
        this.props.product.expenses.other.map((el) => el.total)
      )
      values = values.concat(
        this.props.product.expenses.custom.map((el) => {
          return el.elements.map((e) => e.total).reduce((a, b) => a + b, 0)
        })
      )
      const sum = values.reduce((a, b) => a + b, 0)
      return sum / 100
    }
    return null
  }

  renderProduct() {
    this.calculateTotalValue()
    const width =
      this.props.product.productType.name === "Тръба" ? "800px" : "600px"
    const modalType =
      this.props.product.productType.name === "Тръба"
        ? "editTubeExpenses"
        : "editOtherExpenses"
    return (
      <>
        <div className="content-section with-shadow no-padding">
          <div className="container-header-wrapper" style={{ display: "flex", alignItems: "center" }}>
            <div
              className="header-with-option padding"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                className="back-button ripple"
                onClick={() => {
                  this.props.history.push("/products")
                }}
              >
                <Icon name="arrow-down-blue" size="14px" color="#005EA3" />
              </div>
            </div>
            <div className="name-and-variation">
              {this.props.product?.productType?.name === 'КМУ' ?
              <span>{getProductNameKMU(this.props.product?.basicInfo)}</span> :
              <span>{this.props.product.friendlyNames.join(", ") || getProductData(this.props.product?.basicInfo)}</span>
              }
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 20px",
                cursor: "pointer",
              }}
              className="icon-button"
              onClick={() => {
                this.props.showModal({
                  type: "editProduct",
                  data: this.props.product,
                  width,
                  overflow: "visible",
                })
              }}
            >
              <Icon name="pen" size="16px" color="#26ACE2" />
            </div>
            <div
              className="icon copy"
              onClick={() => {
                this.props.showModal({
                  type: "addProductType",
                  data: this.props.product,
                  width: "1200px",
                  overflow: "visible",
                })
              }}
            />
          </div>
          <ProductDetails product={this.props.product} />
        </div>
        <div className="content-section with-shadow section-middle no-padding">
          <h3 className="with-expenses padding">
            Разходни норми
            <div style={{ flex: "auto" }} />
            {this.calculateTotalValue() && (
              <div className="expense-total-value">
                Себестойност: &nbsp;<span>{this.calculateTotalValue()}</span>
                лв./{this.props.product?.mainUnit}
              </div>
            )}
            {this.props.product.expenses && (
              <div
                style={{ marginLeft: "10px" }}
                className="icon-button"
                onClick={() => {
                  this.props.showModal({
                    type: modalType,
                    data: this.props.product,
                    width: "700px",
                    overflow: "visible",
                    minHeight: "550px",
                  })
                }}
              >
                <Icon name="pen" size="16px" color="#26ACE2" />
              </div>
            )}
          </h3>
          {this.props.product.expenses && (
            <ProductView product={this.props.product} />
          )}
          {!this.props.product.expenses && (
            <Empty
              text="Трябва да добавите инфорамция за разходни норми на продукта"
              onClick={() => {
                this.props.showModal({
                  type: this.getExpenseModalType(
                    this.props.product.productType
                  ),
                  width: "700px",
                  minHeight: "550px",
                  overflow: "visible",
                  data: { product: this.props.product._id },
                })
              }}
            />
          )}
        </div>
        <div className="content-section with-shadow">
          <div className="flex-container space-between">
            <h3>История на производство:</h3>
            {/* <div className="expense-total-value">
              Средно време: &nbsp;<span>0ч 0мин</span>
            </div> */}
          </div>
          {this.props.product.productionRequests.length === 0 && (
            <Empty text="Първо изберете продукт, за да видите историята на производство" />
          )}
          {this.props.product.productionRequests.map((request) => {
            return (
              <ProductHistoryElement
                data={request}
                unit={this.props.product.mainUnit}
              />
            )
          })}
          {/* <div className="section-spacer" />
          <div className="content-section no-height with-shadow">
            <h3 className="with-expenses">
              Скорост за производство
              {this.props.product.production && (
                <div
                  style={{ marginLeft: "10px" }}
                  className="icon-button"
                  onClick={() => {
                    this.props.showModal({
                      type: "editProduction",
                      data: this.props.product,
                      width: "410px",
                    })
                  }}
                >
                  <Icon name="pen" size="16px" color="#26ACE2" />
                </div>
              )}
            </h3>
            {this.props.product.production && (
              <ProductProduction product={this.props.product} />
            )}
            {!this.props.product.production && (
              <Empty
                text="Въведете основна информация за производство на нужните компоненти"
                onClick={() => {
                  this.props.showModal({
                    type: "addProduction",
                    data: { product: this.props.product._id },
                    width: "410px",
                  })
                }}
              />
            )}
          </div> */}
        </div>
      </>
    )
  }

  render() {
    return (
      <div className="products main-container">
        {this.props.product && this.renderProduct()}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  profile: state.user.currentUser,
  product: state.products.current,
})
const mapDispatchToProps = (dispatch) => ({
  setUserWorkingStatus: (payload) => dispatch(setUserWorkingStatus(payload)),
  getSingleProduct: (payload) => dispatch(getSingleProduct(payload)),
  showModal: (payload) => dispatch(showModal(payload)),
  getProductTypes: (payload) => dispatch(getProductTypes(payload)),
  getProviders: (payload) => dispatch(getProviders(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Product)
