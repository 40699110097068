import React from "react"
import { Support } from '@fidweb/customer-support'
import { useSelector } from "react-redux"
import "./styles.scss"

const SupportScreen = ({ socket }) => {
  const { currentUser } = useSelector(({ user }) => user)

  return <div className="screen-customer-support-container row">
    {currentUser && socket && <Support
      {...socket}
      currentUser={currentUser}
      // logo={require('../../assets/images/logo-small.png')}
      colors={{
        '--main-color': '#26ace2',
        '--secondary-color': '#25E083',
        '--light-color': '#fdf9f5'
      }}
    />}
  </div>
}

export default SupportScreen