import React from 'react';
import './styles.scss';
import { useState } from 'react';
import { hideModal } from "../../../actions"
import { useDispatch } from 'react-redux';

export default function EditModal({ data }) {
    const dispatch = useDispatch()
    const [ query, setQuery ] = useState('')

  return (
    <div className='edit-modal-container'>
      <div className='modal-inner-container'>
        <div className='top-wrapper'>
            <h3>{data?.text || 'Редактирай име на колона'}</h3>
            <img className='close-btn' src={require('../../../assets/icons/closeIcon.svg')} onClick={() => dispatch(hideModal())} />
        </div>

        <div className='main-wrapper'>
            <div className='input-wrapper'>
              <label>Заглавие</label>
              <input type='text' value={query} placeholder={data?.title} onChange={(e) => setQuery(e.target.value)} />
            </div>
        </div>

        <div className='bottom-wrapper'>
            <button className='primary-btn' onClick={() => data.onEdit(query)}>Запази</button>
        </div>
      </div>
    </div>
  )
}
