import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import {
  Paper,
  InputBase,
  Divider,
  IconButton,
  Chip,
  Avatar,
  Badge,
} from "@mui/material"
import { Button, Input, Section } from "@makedonski/admin-ui"
import { Auth, Alerts } from "@makedonski/socourt-utilities"
import moment from "moment"
import {
  hideModal,
  addComment,
  updateComment,
  deleteComment,
} from "../../../actions"
import styles from "./ProjectCommentsModal.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import SendIcon from "@mui/icons-material/Send"
import { styled } from "@mui/material/styles"

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 8,
    top: 14,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    color: "#26ACE2",
    backgroundColor: "#FFFFFF",
  },
}))

const ProjectCommentsModal = ({
  hide,
  data,
  inquiry,
  comment,
  update,
  remove,
  currentUser,
}) => {
  const [currentMessage, setCurrentMessage] = useState("")
  const handleChange = (event) => {
    setCurrentMessage(event.target.value)
  }

  const checkMessage = (message) => {
    return message.split(' ').length - 1 === message.length ? false : true
  }

  const handleSubmit = (e) => {
    if (currentMessage.length > 0 && checkMessage(currentMessage)) {
      e.preventDefault()
      comment({ _id: inquiry._id, text: currentMessage })
      setCurrentMessage("")
    }
  }

  const image = React.useMemo(() => {
    if (Auth.getUser().coverPhoto) {
      if (Auth.getUser().coverPhoto.indexOf("sindi-trud-admin") !== -1)
        return global.config.defaultAvatar
      return Auth.getUser().coverPhoto
    }
    return global.config.defaultAvatar
    //eslint-disable-next-line
  }, [inquiry])

  const getImage = (photo) => {
    if (photo) {
      if (photo.indexOf("sindi-trud-admin") !== -1)
        return global.config.defaultAvatar
      return photo
    }
    return global.config.defaultAvatar
  }

  return (
    <div className={styles["comments-modal-container"]}>
      <div className={styles["comments-header-container"]}>
        <div className={styles["comments-heading"]}>
          <h3>Коментари</h3>
          <StyledBadge badgeContent={inquiry?.comments?.length} color="primary">
            <Button.Icon
              name="chat"
              size={25}
              color="#26ace2"
              className=""
              iconStyles=""
            />
          </StyledBadge>
        </div>
        <CloseIcon style={{ cursor: "pointer" }} onClick={hide} />
      </div>
      <div className={styles["comments-container"]}>
        {[]
          .concat(inquiry.comments)
          .reverse()
          .map((comment, index) => (
            <div
              className={`${styles.comment} ${
                comment?.owner.id === currentUser.id && styles.me
              }`}
              key={index}
            >
              {comment?.owner.id !== currentUser.id ? (
                <>
                  <div className={styles["comment-header"]}>
                    <div style={{ display: "flex" }}>
                      <Avatar />
                      <h5>{comment?.owner?.fullName}</h5>
                    </div>
                    <p className={styles.time}>
                      {moment(comment?.createdAt).format("HH:mm")}|
                      {moment(comment?.createdAt).format("DD.MM.YYYY")}
                    </p>
                  </div>
                  <div className={styles["message-body"]}>{comment?.text}</div>
                </>
              ) : (
                <>
                  <div className={`${styles["comment-header"]} ${styles.me}`}>
                    <p className={styles.time}>
                      {moment(comment?.createdAt).format("HH:mm")}|
                      {moment(comment?.createdAt).format("DD.MM.YYYY")}
                    </p>
                    <Avatar src={comment?.owner?.coverPhoto} />
                    <h5>{comment?.owner?.fullName}</h5>
                  </div>
                  <div className={styles["message-body"]}>{comment?.text}</div>
                </>
              )}
            </div>
          ))}
      </div>
      <div className={styles["form-container"]}>
        <Avatar src={currentUser.coverPhoto} style={{ marginRight: "10px" }} />
        <Paper
          onSubmit={handleSubmit}
          component={"form"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 1714,
          }}
        >
          <InputBase
            multiline
            sx={{ ml: 1, flex: 1 }}
            onChange={handleChange}
            value={currentMessage}
          />
          <img
            onClick={handleSubmit}
            src={"/send-icon.png"}
            style={{ color: "#01D088", cursor: "pointer" }}
          />
        </Paper>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiry: state.inquiries.current,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  comment: (payload) => dispatch(addComment(payload)),
  update: (payload) => dispatch(updateComment(payload)),
  remove: (payload) => dispatch(deleteComment(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCommentsModal)
