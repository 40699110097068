import React from 'react';
import { TableHeader } from '../TableHeader';
import InquirySingleInvoice from './InquirySingleInvoice';
import './styles.scss';

export default function InquiryClientInvoices({ invoices }) {
  const headerElements = [
    {
      name: "Обект",
      size: 2,
      fixedWidth: 300
    },
    {
      name: "Фактура",
      size: 1,
      fixedWidth: 120
    },
    {
      name: "Дата на добавяне",
      size: 1,
      fixedWidth: 120
      // filter: true,
    },
    {
      name: "Час на добавяне",
      size: 1,
      fixedWidth: 120
    },
    {
      name: "Добавена от",
      size: 4.2,
      fixedWidth: 500,
    }
]

return (
<div className='invoices-wrapper'>
  <TableHeader elements={headerElements} justifyContent='flex-start' />

  <div className='invoices-list-container'>
  {invoices.map((invoice) => {
      return <InquirySingleInvoice invoice={invoice} />
    })}
  </div>
</div>
)
}
