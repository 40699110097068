import React from 'react';
import './styles.scss';

export default function CalendarTask({ data, type, onClick }) {
  return (
    <div className={`single-task-wrapper ${data?.status === 'finished' ? 'finished' : ''} ${type === 'Дневен' ? 'daily' : 'weekly-monthly'}`}
    onClick={onClick}>
      <h3>{data?.title}</h3>
      <p>{data?.text}</p>
    </div>
  )
}
