import { ActivityTypes } from "../actions"

const initialState = {
  loading: false,
  hasMore: true,
  data: [],
}

const activities = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActivityTypes.GET_ACTIVITIES_SUCCESS:
      if (payload.page === 1) {
        return {
          ...state,
          loading: false,
          data: [...payload.activities],
          hasMore: payload.activities.length === 20,
        }
      }
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload.activities],
        hasMore: payload.activities.length === 20,
      }
    case ActivityTypes.NEW_ACTIVITY:
      return {
        ...state,
        data: [payload.activity, ...state.data],
      }
    default:
      return state
  }
}

export default activities
