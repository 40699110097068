import React, { useState } from "react"
import { connect } from "react-redux"
import { Input } from "@makedonski/admin-ui"
import { TableHeader } from "../../../components"
import { StorageProduct } from "./StorageProduct"
import { hideModal } from "../../../actions"
import "./styles.scss"

const availableProductsHeader = [
  { name: "Продукт", size: 2 },
  { name: "Общо на склад", size: 1 },
  { name: "Обща стойност", size: 1 },
  { name: "Критично к-во", size: 1.3 },
]

const StorageProductsModal = ({ hideModal, data }) => {
  const [productFilter, setProductFilter] = useState("")
  return (
    <div className="storage-products-modal-container">
      <div className="top-container">
        <h3>Общо на склад</h3>
        <div className="exit-btn" onClick={() => hideModal()} />
      </div>
      <Input.Text
        placeholder="Търсене по име на продукт"
        value={productFilter}
        onChange={(event) => setProductFilter(event.target.value)}
      />
      <TableHeader
        elements={availableProductsHeader}
        justifyContent="flex-start"
      />
      <div className="scroll-container">
        {data
          .filter((el) => {
            return el.type.toLowerCase().includes(productFilter.toLowerCase())
          })
          .map((product) => {
            return <StorageProduct product={product} />
          })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageProductsModal)
