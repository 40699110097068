import React, { useCallback, useRef } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  getContacts,
  hideModal,
  setCroppedData,
  showLoading,
  showModal,
} from "../../actions"
import { history } from "../../config/stores"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"
import "./styles.scss"
import { useEffect } from "react"
import { useState } from "react"
import ContactsGrid from "./ContactsGrid"
import { Inputs } from "../../components"
import MobileContactsGrid from "./MobileContactsGrid"
import ModalComponent from "../../components/ModalComponent/ModalComponent"
import CustomModals from "../../components/ModalComponent/Modals"
import Files from "../../utilities/files"

const Contacts = ({ contacts }) => {
  const isMobile = window.innerWidth < 950
  const dispatch = useDispatch()
  const uploadRef = useRef()

  const [curPage, setCurPage] = useState(1)
  const [optionsModal, setOptionsModal] = useState({
    open: false,
    options: null,
  })

  const handleUpload = async ({ target: { files } }) => {
    const file = files[0]
    const reader = new FileReader()
    reader.onload = () => {
      if (isMobile) {
        Files.uploadFile(file).then((res) => {
          dispatch(
            setCroppedData({
              url: res?.location,
              base64: reader.result,
            })
          )
          history.push("/add-contact/mobile-scanned")
        })
      } else
        dispatch(
          showModal({
            type: "addContactImageModal",
            data: { image: reader.result },
            hide: () => dispatch(hideModal()),
            width: "800px",
          })
        )
    }
    reader.readAsDataURL(file)
  }

  const fetch = useCallback(
    (payload) => {
      dispatch(getContacts(payload))
    },
    [dispatch]
  )

  useEffect(() => {
    fetch({
      pageNumber: 1,
      pageSizeLimit: 20,
      sortBy: { createdAt: -1 },
      onSuccess: (res) => setCurPage(2),
    })
  }, [])

  return (
    <div className="main-container" style={{ height: "93%" }}>
      <div
        className={`body-container ${!isMobile && "full-height"}`}
        style={{ padding: isMobile && "1.5vw", height: isMobile && "100%" }}
      >
        <div
          className={`flex items-center w-full items-center justify-between ${
            isMobile && "column items-start"
          }`}
        >
          <h3>Контакти</h3>
          {!isMobile ? (
            <Popup
              trigger={
                <div className={`button-container selected`}>
                  <b style={{ textTransform: "uppercase" }}>Добави контакт</b>
                </div>
              }
              closeOnDocumentClick
              className="create-contact-popUp anvil"
              position="bottom right"
              on={"click"}
            >
              {(close) => (
                <div className="flex column full-width">
                  <div
                    className="flex full-width justify-between items-center"
                    style={{ padding: "0px 7px" }}
                  >
                    <p style={{ fontSize: "14px" }}>Моля изберете</p>
                    <img
                      src={require("../../assets/icons/closeIcon.svg")}
                      className="close-popup-icon"
                      onClick={() => close()}
                    />
                  </div>
                  <p
                    className="popup-option"
                    onClick={() => history.push("/add-contact/manually")}
                  >
                    Ръчно добавяне
                  </p>
                  <p
                    className="popup-option"
                    // onClick={() => history.push("/add-contact/scan")}
                    onClick={() => {
                      uploadRef.current.click()
                    }}
                  >
                    Сканиране на визитка
                  </p>
                </div>
              )}
            </Popup>
          ) : (
            <Inputs.Button
              className={"dashed"}
              style={{ width: "100%" }}
              text={"Добави контакт"}
              onClick={() =>
                setOptionsModal({
                  open: true,
                  options: [
                    {
                      label: "Ръчно добавяне",
                      callback: () => history.push("/add-contact/manually"),
                    },
                    {
                      label: "Сканиране на визитка",
                      callback: () => uploadRef.current.click(),
                    },
                  ],
                })
              }
            />
          )}
        </div>
        {!isMobile ? (
          <div style={{ width: "100%", height: "91%", overflowX: "scroll" }}>
            <ContactsGrid
              docs={contacts}
              current={curPage}
              setCurrent={setCurPage}
              fetch={fetch}
            />
          </div>
        ) : (
          <MobileContactsGrid
            docs={contacts}
            current={curPage}
            setCurrent={setCurPage}
            fetch={fetch}
          />
        )}
      </div>
      <input
        ref={uploadRef}
        style={{ display: "none" }}
        type="file"
        accept={"image/*"}
        onChange={(e) => handleUpload(e)}
      />
      <ModalComponent
        open={optionsModal.open}
        children={
          <CustomModals.OptionsModal
            handleClose={() => setOptionsModal({ open: false, options: [] })}
            options={optionsModal.options}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts,
})

const mapDispatchToProps = (dispatch) => ({
  showLoading: () => dispatch(showLoading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
