import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { hideModal } from "../../../actions"

import "./styles.scss"

const EmailAddressModal = ({ hideModal, data }) => {
  const [copied, setCopied] = useState(false)
  return (
    <div className="email-address-container">
      <div className="top-container">
        <h2>Имейл адрес на проект</h2>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <div className="flex-container">
        <div className="email-container">
          <p>Имейл</p>
          <Input.Text disabled value={data} />
        </div>
        <Button.Raised
          text="Копирай"
          onClick={() => {
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 3000)
            navigator.clipboard.writeText(data)
          }}
        />
        {copied && <div className="copied">Копирано</div>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailAddressModal)
