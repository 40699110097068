import React from "react";
import moment from "moment";
import "./styles.scss";
import { getProductName } from "../../utilities/helpers";

const InquiryExpenseElement = ({ data, type }) => {
  const productName = getProductName(data?.basicInfo)

  return (
    <div className="inquiry-client-element expense">
      <div className="element medium">{moment(data?.start).format('DD.MM.YYYY')} - {moment(data?.end).format('DD.MM.YYYY')}</div>
      <div className="element small">{data?.performers?.join(', ') || data?.machineName}</div>
      {type === 'machines' ?
      <div className="element medium overflow">{data?.friendlyNames?.length ? data?.friendlyNames[0] : productName ? productName : '--'}</div> :
      <div className="element medium overflow">{data?.friendlyNames?.length ? data?.friendlyNames[0] : data?.productType ? data?.productType : '--'}</div>}
      <div className="element large last">{data?.machineCostPrice ? data?.machineCostPrice?.toFixed(2) : 0} лв.</div>
    </div>
  )
}

export default InquiryExpenseElement;
