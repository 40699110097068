import React from "react"
import { withRouter } from "react-router-dom"
import moment from "moment"
import "./styles.scss"

class NotificationsFeed extends React.Component {
  constructor(props) {
    super(props)
    this.linkDetails = {}
  }

  scrollEvent(event) {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight
    if (bottom && this.props.onBottom) {
      this.props.onBottom()
    }
  }

  render() {
    return (
      <div className="notifications-feed">
        <div className="header">
          <div className="row">
            <div className="col">
              <div className="pfp">
                <img
                  alt="profile"
                  src={
                    this.props.user.coverPhoto || global.config.defaultAvatar
                  }
                />
              </div>
            </div>
            <div className="col">
              <p>Здравей,</p>
              <h3>{this.props.user.fullName}</h3>
              <p>
                Последно влизане{" "}
                {moment(this.props.user.lastActivityAt)
                  .locale("bg")
                  .format("DD MMMM, HH:mm")}
              </p>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h3>Известия</h3>
          </div>
          <div className="notifications" onScroll={this.scrollEvent.bind(this)}>
            {this.props.data.map((activity) => {
              return (
                <div
                  key={activity._id}
                  className="notification"
                  onClick={() => {
                    if (this.props.onActivityClick) {
                      this.props.onActivityClick(activity)
                    }
                  }}
                >
                  <div className="notification-date">
                    <p>
                      {moment(activity.createdAt)
                        .locale("bg")
                        .format("DD MMMM, HH:mm")}
                    </p>
                  </div>
                  <div className="notification-content">
                    <div className="row">
                      <div className="col">
                        <div className="pfp">
                          <img
                            alt="pfp"
                            src={
                              activity.user.coverPhoto ||
                              global.config.defaultAvatar
                            }
                          />
                        </div>
                      </div>
                      <div className="col">{activity.text}</div>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* <div className="notification">
              <div className="notification-date">
                <p>{this.props.date}</p>
              </div>
              <div className="notification-content">
                <div className="row">
                  <div className="col">
                    <div className="pfp">
                      <img
                        alt="pfp"
                        src={this.props.user.coverPhoto || global.config.defaultAvatar}
                      />
                    </div>
                  </div>
                  <div className="col">
                    We try not to sting. It`s usually fatal for us. So you have to watch your
                    temper. Very carefully. You kick a wall, take ..
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(NotificationsFeed)
