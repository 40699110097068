export const ProjectTypes = {
  CREATE_PROJECT_OFFER: "project/CREATE_PROJECT_OFFER",
  CREATE_PROJECT_OFFER_SUCCESS: "project/CREATE_PROJECT_OFFER_SUCCESS",
  CREATE_PROJECT_OFFER_EN: "project/CREATE_PROJECT_OFFER_EN",
  CREATE_PROJECT_OFFER_EN_SUCCESS: "project/CREATE_PROJECT_OFFER_EN_SUCCESS",
  CREATE_PROJECT_INVOICE: "project/CREATE_PROJECT_INVOICE",
  CREATE_PROJECT_INVOICE_SUCCESS: "project/CREATE_PROJECT_INVOICE_SUCCESS",
  CREATE_PROJECT_INVOICE_EN: "project/CREATE_PROJECT_INVOICE_EN",
  CREATE_PROJECT_INVOICE_EN_SUCCESS:
    "project/CREATE_PROJECT_INVOICE_EN_SUCCESS",
  CREATE_ACT_OF_ACCEPTANCE: "project/CREATE_ACT_OF_ACCEPTANCE",
  CREATE_ACT_OF_ACCEPTANCE_SUCCESS: "project/CREATE_ACT_OF_ACCEPTANCE_SUCCESS",
  CREATE_ACT_OF_ACCEPTANCE_EN: "project/CREATE_ACT_OF_ACCEPTANCE_EN",
  CREATE_ACT_OF_ACCEPTANCE_EN_SUCCESS:
    "project/CREATE_ACT_OF_ACCEPTANCE_EN_SUCCESS",
  RESET_ACT_OF_ACCEPTANCE_CREATION: "project/RESET_ACT_OF_ACCEPTANCE_CREATION",

  EDIT_PROJECT_OFFER: "project/EDIT_PROJECT_OFFER",
  EDIT_PROJECT_OFFER_SUCCESS: "project/EDIT_PROJECT_OFFER_SUCCESS",
  EDIT_PROJECT_OFFER_EN: "project/EDIT_PROJECT_OFFER_EN",
  EDIT_PROJECT_OFFER_EN_SUCCESS: "project/EDIT_PROJECT_OFFER_EN_SUCCESS",
  EDIT_PROJECT_INVOICE: "project/EDIT_PROJECT_INVOICE",
  EDIT_PROJET_INVOICE_SUCCESS: "project/EDIT_PROJECT_INVOICE_SUCCESS",
  EDIT_PROJECT_INVOICE_EN: "project/EDIT_PROJECT_INVOICE_EN",
  EDIT_PROJET_INVOICE_EN_SUCCESS: "project/EDIT_PROJECT_INVOICE_EN_SUCCESS",
  EDIT_ACT_OF_ACCEPTANCE: "project/EDIT_ACT_OF_ACCEPTANCE",
  EDIT_ACT_OF_ACCEPTANCE_SUCCESS: "project/EDIT_ACT_OF_ACCEPTANCE_SUCCESS",
  EDIT_ACT_OF_ACCEPTANCE_EN: "project/EDIT_ACT_OF_ACCEPTANCE_EN",
  EDIT_ACT_OF_ACCEPTANCE_EN_SUCCESS:
    "project/EDIT_ACT_OF_ACCEPTANCE_EN_SUCCESS",

  DELETE_PROJECT_OFFER: "project/DELETE_PROJECT_OFFER",
  DELETE_PROJECT_INVOICE: "project/DELETE_PROJECT_INVOICE",
  DELETE_PROJECT_ACT: "project/DELETE_PROJECT_ACT",
  DELETE_PROJECT_PRODUCT: "project/DELETE_PROJECT_PRODUCT",
  DELETE_PROJECT_PRODUCT_SUCCESS: "project/DELETE_PROJECT_PRODUCT_SUCCESS",

  EDIT_INQUIRY_SUCCESS: "project/EDIT_INQUIRY_SUCCESS",
  EDIT_PROJECT_TEAM: "project/EDIT_PROJECT_TEAM",

  SEARCH_OFFER_CLIENTS: "project/SEARCH_OFFER_CLIENTS",
  SEARCH_OFFER_CLIENTS_SUCCESS: "project/SEARCH_OFFER_CLIENTS_SUCCESS",
  SEARCH_ACT_SUPPLIERS: "project/SEARCH_ACT_SUPPLIERS",
  SEARCH_ACT_SUPPLIERS_SUCCESS: "project/SEARCH_ACT_SUPPLIERS_SUCCESS",
  RESET_OFFER_CLIENT_RESULTS: "project/RESET_OFFER_CLIENT_RESULTS",

  SEARCH_INQUIRY_CATEGORIES: "project/SEARCH_INQUIRY_CATEGORIES",
  SEARCH_INQUIRY_CATEGORIES_SUCCESS:
    "project/SEARCH_INQUIRY_CATEGORIES_SUCCESS",

  SEARCH_INQUIRIES: "projects/SEARCH_INQUIRIES",
  SEARCH_INQUIRIES_SUCCESS: "projects/SEARCH_INQUIRIES_SUCCESS",
}

export const searchInquiries = (payload) => ({
  type: ProjectTypes.SEARCH_INQUIRIES,
  payload,
})

export const searchInquiryCategories = (payload) => ({
  type: ProjectTypes.SEARCH_INQUIRY_CATEGORIES,
  payload,
})

export const deleteProjectProduct = (payload) => ({
  type: ProjectTypes.DELETE_PROJECT_PRODUCT,
  payload,
})

export const searchActSuppliers = (payload) => ({
  type: ProjectTypes.SEARCH_ACT_SUPPLIERS,
  payload,
})

export const deleteAct = (payload) => ({
  type: ProjectTypes.DELETE_PROJECT_ACT,
  payload,
})

export const deleteInvoice = (payload) => ({
  type: ProjectTypes.DELETE_PROJECT_INVOICE,
  payload,
})

export const deleteOffer = (payload) => ({
  type: ProjectTypes.DELETE_PROJECT_OFFER,
  payload,
})

export const resetAct = () => ({
  type: ProjectTypes.RESET_ACT_OF_ACCEPTANCE_CREATION,
})

export const editAct = (payload) => ({
  type: ProjectTypes.EDIT_ACT_OF_ACCEPTANCE,
  payload,
})

export const editActEn = (payload) => ({
  type: ProjectTypes.EDIT_ACT_OF_ACCEPTANCE_EN,
  payload,
})

export const createAct = (payload) => ({
  type: ProjectTypes.CREATE_ACT_OF_ACCEPTANCE,
  payload,
})

export const createActEn = (payload) => ({
  type: ProjectTypes.CREATE_ACT_OF_ACCEPTANCE_EN,
  payload,
})

export const resetResults = () => ({
  type: ProjectTypes.RESET_OFFER_CLIENT_RESULTS,
})

export const searchOfferClients = (payload) => ({
  type: ProjectTypes.SEARCH_OFFER_CLIENTS,
  payload,
})

export const editInvoice = (payload) => ({
  type: ProjectTypes.EDIT_PROJECT_INVOICE,
  ...payload,
})

export const editInvoiceEn = (payload) => ({
  type: ProjectTypes.EDIT_PROJECT_INVOICE_EN,
  ...payload,
})

export const editOffer = (payload) => ({
  type: ProjectTypes.EDIT_PROJECT_OFFER,
  payload,
})

export const editOfferEn = (payload) => ({
  type: ProjectTypes.EDIT_PROJECT_OFFER_EN,
  payload,
})

export const createInvoice = (payload) => ({
  type: ProjectTypes.CREATE_PROJECT_INVOICE,
  ...payload,
})

export const createInvoiceEn = (payload) => ({
  type: ProjectTypes.CREATE_PROJECT_INVOICE_EN,
  ...payload,
})

// export const createInvoice = (payload) => ({
//   type: ProjectTypes.CREATE_PROJECT_INVOICE,
//   payload,
// })

export const createOffer = (payload) => ({
  type: ProjectTypes.CREATE_PROJECT_OFFER,
  payload,
})

export const createOfferEn = (payload) => ({
  type: ProjectTypes.CREATE_PROJECT_OFFER_EN,
  payload,
})
export const editProjectTeam = (payload) => ({
  type: ProjectTypes.EDIT_PROJECT_TEAM,
  payload,
})
