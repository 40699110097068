import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { VariationsTypes, GeneralTypes, getVariationsSuccess } from '../actions'
import { API_URL } from '../config/settings'

export const getVariationsEpic = (action$) => {
  return action$.pipe(
    ofType(VariationsTypes.GET_VARIATIONS),
    switchMap(() => {
      return Api.get(`${API_URL}/variations`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(getVariationsSuccess(response.variations))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
