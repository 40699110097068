import React, { Component } from "react"
import { Section, Button, Tasks } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import moment from "moment"
import "moment/locale/bg"
import { isMobile } from "react-device-detect"
import { NotificationsFeed } from "../../components"
import { UserFilter } from "./UserFilter"
import { getCurrentUser, getUserTasks, getActivities } from "../../actions"
import "./styles.scss"

const PopoverContent = ({ task }) => {
  return (
    <div className="calendar-popover-content">
      <div className="calendar-popover-status-line" />
      <div className="calendar-popover-times">
        <div className="calendar-popover-time-detail">
          Начало:{" "}
          <b>{moment(task.startTaskDate).format("DD.MM.YYYY | HH:mm")}</b>
        </div>
        <div className="calendar-popover-time-detail text-end">
          Краен срок:{" "}
          <b>{moment(task.deadline).format("DD.MM.YYYY | HH:mm")}</b>
        </div>
      </div>
      <div className="calendar-popover-users">
        <div className="calendar-popover-users-label">Изпълнител:</div>
        <div className="calendar-popover-users-list">
          {task.assignees.length > 2 && (
            <div
              className="calendar-popover-users-label"
              style={{ alignSelf: "flex-end" }}
            >
              <b> {task.assignees.length} Изпълнители</b>
            </div>
          )}
          {task.assignees.length < 3 &&
            task.assignees.map((user) => {
              return (
                <div className="user-filter-user-item">
                  <div
                    className="user-item-avatar"
                    style={{
                      backgroundImage: `url(${
                        user.coverPhoto || global.config.defaultAvatar
                      })`,
                    }}
                  />
                  <div className="user-item-full-name">{user.fullName}</div>
                </div>
              )
            })}
        </div>
      </div>
      {task.text && (
        <>
          <div className="calendar-popover-devider" />
          <div className="calendar-popover-description">{task.text}</div>
        </>
      )}
      <div className="calendar-popover-devider" />
      <div className="calendar-popover-details">
        <div className="calendar-popup-detail-item">
          Файлове ({task.files.length})
        </div>
        <div className="calendar-popup-detail-item">
          Коментари ({task.comments.length})
        </div>
      </div>
    </div>
  )
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: "Мои задачи",
      usersToFilter: [],
      currentPopupTask: null,
      disableHidePopup: false,
      page: 1,
    }
    this.calendarPopoverRef = null
    this.isMobileSafari = isMobile
  }

  componentDidMount() {
    this.props.getCurrentUser()
    this.props.getUserTasks()
    this.props.getActivities({ page: this.state.page, limit: 20 })
    if (window.location.search) {
      const taskID = window.location.search.replace("?open-task=", "")
      this.props.showTaskModal({
        type: "viewTask",
        data: taskID,
        width: "900px",
        overflow: "visible",
      })
    }
  }

  onMonthChange(date) {
    this.props.getUserTasks({ date: date.format("DD-MM-YYYY") })
  }

  setBackground(task) {
    let color = ""
    if (task.priority === "high") {
      color = "rgba(255, 0, 0, 0.32)"
    } else if (task.priority === "medium") {
      color = "rgb(255, 170, 0)"
    } else if (task.priority === "low") {
      color = "#9EE6A7"
    }
    if (task.finished) {
      color = "rgb(216, 216, 216)"
    }
    return color
  }

  handleScrollToBottom() {
    this.setState({ page: this.state.page + 1 }, () => {
      if (this.props.hasMore) {
        this.props.getActivities({ page: this.state.page, limit: 20 })
      }
    })
  }

  taskToEvents() {
    if (this.props.tasks.length && this.state.filter === "На екипа") {
      return this.props.tasks
        .filter((task) => {
          if (this.state.usersToFilter.length) {
            if (
              task.assignees.some((assignee) =>
                this.state.usersToFilter.includes(assignee._id)
              )
            ) {
              return task
            }
            return null
          }
          return task
        })
        .map((task) => {
          return {
            id: task._id,
            start: task.startTaskDate
              ? moment(task.startTaskDate)
              : moment(task.createdAt),
            end: moment(task.deadline),
            title: `${task.title}`,
            color: this.setBackground(task),
            isActive: task.userStatuses.find(
              (user) => user.status !== "completed"
            ),
          }
        })
    }
    if (this.props.tasks.length && this.state.filter === "Мои задачи") {
      return this.props.currentUserTasks.map((task) => {
        return {
          id: task._id,
          start: task.startTaskDate
            ? moment(task.startTaskDate)
            : moment(task.createdAt),
          end: moment(task.deadline),
          title: `${task.title}`,
          color: this.setBackground(task),
          isActive: task.userStatuses.find(
            (user) => user.status !== "completed"
          ),
        }
      })
    }
    return []
  }

  renderSelectUser() {
    return (
      <UserFilter
        onSelectUsers={(users) => {
          this.setState({ usersToFilter: users }, () => {
            this.usersPopup.hide()
          })
        }}
      />
    )
  }

  renderLeft() {
    return (
      <div className="calendar-navigation">
        <div className="calendar-events-type-selector">
          <Button.RoundedTab
            className=""
            buttons={["Мои задачи", "На екипа"]}
            onChange={(name) => this.setState({ filter: name })}
          />
        </div>
        {this.state.filter === "На екипа" && (
          <Section.Popup
            ref={(ref) => {
              this.usersPopup = ref
            }}
            content={this.renderSelectUser()}
          >
            <div className="other-actions">
              <span>По член от екипа</span>
              <Button.Icon
                className="icon-background"
                name="plus"
                size={14}
                color="#0077FF"
                onClick={() => {
                  if (this.usersPopup) this.usersPopup.show()
                }}
              />
            </div>
          </Section.Popup>
        )}
      </div>
    )
  }

  render() {
    return (
      <div
        className="main-container-home"
        onMouseOver={(e) => {
          const isPopup =
            e.target.classList.contains("calendar-popover-content") ||
            e.target.parentElement.classList.contains(
              "calendar-popover-content"
            )
          const isSegment =
            e.target.classList.contains("au-calendar-row-segment") ||
            e.target.parentElement.classList.contains("au-calendar-row-segment")
          if (this.calendarPopoverRef && !(isPopup || isSegment)) {
            this.calendarPopoverRef.style.top = `0px`
            this.calendarPopoverRef.style.left = `0px`
            this.calendarPopoverRef.classList.remove("active")
          }
          return false
        }}
      >
        <div className="row height">
          <div className="col">
            <div className="news-feed">
              <NotificationsFeed
                onBottom={this.handleScrollToBottom.bind(this)}
                user={this.props.profile}
                data={this.props.activities}
                onActivityClick={(activity) => {
                  if (activity.data.task) {
                    this.props.showTaskModal({
                      type: "viewTask",
                      data: activity.data.task,
                      width: "900px",
                      overflow: "visible",
                    })
                  }
                  if (activity.data.inquiry) {
                    this.props.history.push(
                      `/projects/${activity.data.inquiry}`
                    )
                  }
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="calendar-container">
              <div className="row">
                <h3>Календар</h3>
                <div className="add">
                  <Button.Create
                    medium
                    onClick={() =>
                      this.props.showTaskModal({
                        type: "newTask",
                        data: "",
                        width: "800px",
                        overflow: "visible",
                      })
                    }
                  />
                </div>
              </div>
              <Section.Calendar
                events={this.taskToEvents()}
                className="calendar"
                navigationBarLeft={this.renderLeft()}
                locale="bg"
                onMonthChange={this.onMonthChange.bind(this)}
                onMouseEnter={(e, segment) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!this.isMobileSafari) {
                    const currentPopupTask = this.props.tasks.find(
                      (el) => el._id === segment.id
                    )
                    const { x, y, width } = e.target.getBoundingClientRect()
                    this.setState({ currentPopupTask }, () => {
                      if (this.calendarPopoverRef) {
                        this.calendarPopoverRef.style.top = `${y - 50}px`
                        this.calendarPopoverRef.style.left = `${
                          x + width / 2
                        }px`
                        this.calendarPopoverRef.classList.add("active")
                      }
                    })
                  }
                  return false
                }}
                onMouseLeave={(e) => {
                  // const isPopup = e.target.classList.contains('calendar-popover-content')
                  // const isSegment = e.target.classList.contains('au-calendar-row-segment')
                  // if (this.calendarPopoverRef && !(isPopup || isSegment)) {
                  //   this.calendarPopoverRef.style.top = `0px`
                  //   this.calendarPopoverRef.style.left = `0px`
                  //   this.calendarPopoverRef.classList.remove('active')
                  // }
                  // return false
                }}
                onEventClick={(event) => {
                  this.props.showTaskModal({
                    type: "viewTask",
                    data: event.id,
                    width: "900px",
                    overflow: "visible",
                  })
                }}
              />
              {this.state.currentPopupTask && (
                <div
                  ref={(ref) => {
                    this.calendarPopoverRef = ref
                  }}
                  onMouseEnter={(e) => {}}
                  onMouseOver={(e) => {
                    // e.preventDefault()
                    // e.stopPropagation()
                    // const isTarget = e.target.classList.contains('calendar-popover-content')
                    // this.setState({ disableHidePopup: true })
                    // return false
                  }}
                  onMouseLeave={(e) => {
                    // e.preventDefault()
                    // e.stopPropagation()
                    // this.setState({ disableHidePopup: false })
                    // return false
                    // if (this.calendarPopoverRef) {
                    //   this.calendarPopoverRef.style.top = `0px`
                    //   this.calendarPopoverRef.style.left = `0px`
                    //   this.calendarPopoverRef.classList.remove('active')
                    //   this.setState({ disableHidePopup: false })
                    // }
                  }}
                  className="calendar-popover"
                >
                  <PopoverContent task={this.state.currentPopupTask} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.user.currentUser,
  tasks: state.user.activeTasks,
  currentUserTasks: state.user.currentUserTasks,
  activities: state.activities.data,
  hasMore: state.activities.hasMore,
})
const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: (payload) => dispatch(getCurrentUser(payload)),
  showTaskModal: (payload) => dispatch(Tasks.actions.showTaskModal(payload)),
  getUserTasks: (payload) => dispatch(getUserTasks(payload)),
  getActivities: (payload) => dispatch(getActivities(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Home)
