import React, { useState } from 'react'
import './styles.scss'
import { NavLink } from 'react-router-dom'

export default function RequestDropdown () {
  const closeIcon = require('../../assets/icons/closeIcon.svg')
  const [isDropdownOpen, setIsdropdownOpen] = useState(true)
  return (
    <>
      {isDropdownOpen &&
        <div className='request-btn-dropdown-wrapper'>
          <div className='request-btn-dropdown-top'>
            <h5>Заявка за</h5>
            <img src={closeIcon} onClick={() => setIsdropdownOpen(false)} />
          </div>
          <div className='navlink-wrapper'>
            <NavLink to='/production/newrequest'>Тръба / Краен продукт</NavLink>
            <NavLink to='/production/addrequest'>Друга дейност</NavLink>
          </div>
        </div>}
    </>
  )
}
