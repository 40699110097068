import React, { useState } from "react"
import { connect } from "react-redux"
import { filterHPOrders } from "../../actions"
import _ from "lodash"
import "./styles.scss"

const onSearchInit = (event, filterHPOrders) => {
  event.persist()
  onSearchDelayed(event, filterHPOrders)
}

const onSearch = (event, filterHPOrders) => {
  filterHPOrders({ search: event.target.value })
}

const onSearchDelayed = _.debounce(
  (event, filterHPOrders) => onSearch(event, filterHPOrders),
  300
)

const ClientAutocomplete = ({
  filterHPOrders,
  found,
  onChooseOrder,
  onChange,
}) => {
  const [filter, setFilter] = useState("")
  const [focus, setFocus] = useState(false)
  return (
    <div className="client-autocomplete-container">
      <input
        type="text"
        placeholder="Заявки"
        value={filter}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setTimeout(() => {
            setFocus(false)
          }, 150)
        }}
        onChange={(event) => {
          setFilter(event.target.value)
          onSearchInit(event, filterHPOrders)
          onChange(event)
        }}
      />
      {focus && found.length > 0 && (
        <div className="clients-dropdown">
          {found.map((product) => {
            return (
              <div
                className="client-option"
                onClick={() => {
                  setFilter(product.product)
                  onChooseOrder(product)
                }}
              >
                {product.product}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  found: state.storage.found,
})

const mapDispatchToProps = (dispatch) => ({
  filterHPOrders: (payload) => dispatch(filterHPOrders(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientAutocomplete)
