import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input } from "@makedonski/admin-ui"
import { nanoid } from "nanoid"
import { UploadFile, RadioButton, FileItem } from "../../.."
import Files from "../../../../utilities/files"
import "./styles.scss"
import ClearIcon from "@mui/icons-material/Clear"

const units = [
  { label: "Метър", value: "м" },
  { label: "Брой", value: "бр" },
  { label: "Килограм", value: "кг" },
  { label: "Литър", value: "л" },
  { label: "Кубик", value: "м3" },
]

const ReservoirView = ({
  setMainUnit,
  onBasicInfoSet,
  setClientDiagram,
  setProductionDiagram,
  onSetImages,
  loading,
  stopLoading,
  productNames,
  setProductNames,
  data,
}) => {
  const [unit, setUnit] = useState("")
  const [basicInfo, setBasicInfo] = useState([])
  const [clientDiagram, onSetClientDiagram] = useState([])
  const [productionDiagram, onSetProductionDiagram] = useState([])
  const [images, setImages] = useState([])
  useEffect(() => {
    if (data) {
      const main = units.find((el) => el.value === data.mainUnit)
      setUnit(main.value)
      setMainUnit(main.value)
      setBasicInfo(data.basicInfo)
      onBasicInfoSet(data.basicInfo)
      setProductNames(data.friendlyNames)
      if (data.schematicsProduction) {
        setProductionDiagram(data.schematicsProduction)
        onSetProductionDiagram(data.schematicsProduction)
      }
      if (data.schematicsClient) {
        setClientDiagram(data.schematicsClient)
        onSetClientDiagram(data.schematicsClient)
      }
      if (data.images) {
        onSetImages(data.images)
        setImages(data.images)
      }
    }
  }, [data, setMainUnit])
  return (
    <div className="mine-view-container">
      <h3>Обща информация</h3>
      <div className="flex-container space-between">
        <div
          className="regular-container "
          style={{ maxWidth: "35%", marginRight: "20px" }}
        >
          <p>Мерна единица</p>
          <Input.Dropdown
            placeholder=""
            value={unit}
            options={units}
            onChange={(value) => {
              setMainUnit(value.value)
              setUnit(value.value)
            }}
          />
        </div>
        <div className="form-control">
          <div className="form-control-label">Ориентация</div>
          <div className="radio-group-horizontal">
            <RadioButton
              changed={() => {
                const newBasicInfo = basicInfo.slice(0)
                if (
                  newBasicInfo.findIndex(
                    (el) => el.value === "Хоризонтална"
                  ) === -1
                ) {
                  const index = newBasicInfo.findIndex(
                    (el) => el.name === "Ориентация"
                  )
                  if (index !== -1) {
                    newBasicInfo.splice(index, 1)
                  }
                  newBasicInfo.push({
                    type: "option",
                    value: "Хоризонтална",
                    name: "Ориентация",
                    units: "",
                  })
                  onBasicInfoSet(newBasicInfo)
                  setBasicInfo(newBasicInfo)
                }
              }}
              id={nanoid()}
              isSelected={
                basicInfo.findIndex((el) => el.value === "Хоризонтална") !== -1
              }
              label="Хоризонтална"
              value="Хоризонтална"
            />
            <RadioButton
              changed={() => {
                const newBasicInfo = basicInfo.slice(0)
                if (
                  newBasicInfo.findIndex((el) => el.value === "Вертикална") ===
                  -1
                ) {
                  const index = newBasicInfo.findIndex(
                    (el) => el.name === "Ориентация"
                  )
                  if (index !== -1) {
                    newBasicInfo.splice(index, 1)
                  }
                  newBasicInfo.push({
                    type: "option",
                    value: "Вертикална",
                    name: "Ориентация",
                    units: "",
                  })
                  onBasicInfoSet(newBasicInfo)
                  setBasicInfo(newBasicInfo)
                }
              }}
              id={nanoid()}
              isSelected={
                basicInfo.findIndex((el) => el.value === "Вертикална") !== -1
              }
              label="Вертикална"
              value="Вертикална"
            />
          </div>
        </div>
        <div className="regular-container flex-1">
          <p>Обем</p>
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "Обем") &&
              basicInfo.find((el) => el.name === "Обем").value
            }
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex((el) => el.name === "Обем")
              const object = {
                type: "input",
                value: event.target.value,
                name: "Обем",
                units: unit,
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
        </div>
        <div className="regular-container flex-2">
          <p>Вариация</p>
          <Input.Text
            value={
              basicInfo.find((el) => el.name === "Вариация") &&
              basicInfo.find((el) => el.name === "Вариация").value
            }
            onChange={(event) => {
              const newBasicInfo = basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Вариация"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Вариация",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setBasicInfo(newBasicInfo)
              onBasicInfoSet(newBasicInfo)
            }}
          />
        </div>
      </div>
      <div className="regular-container flex-1" style={{ width: "30%" }}>
        <p>Тежест на единца</p>
        <Input.Text
          value={
            basicInfo.find((el) => el.name === "Тежест на единца") &&
            basicInfo.find((el) => el.name === "Тежест на единца").value
          }
          placeholder="кг"
          onChange={(event) => {
            const newBasicInfo = basicInfo.slice(0)
            const index = newBasicInfo.findIndex(
              (el) => el.name === "Тежест на единца"
            )
            const object = {
              type: "input",
              value: event.target.value,
              name: "Тежест на единца",
              units: "кг",
            }
            if (index === -1) {
              newBasicInfo.push(object)
            } else {
              newBasicInfo.splice(index, 1, object)
            }
            setBasicInfo(newBasicInfo)
            onBasicInfoSet(newBasicInfo)
          }}
        />
      </div>
      <h3>Наименование за клиент</h3>
      <div className="flex-container">
        {/* <div
          className="plus-button"
          onClick={() => {
            const newNames = productNames.slice(0)
            newNames.push("")
            setProductNames(newNames)
          }}
        /> */}

        <div className="flex-container no-margin">
          <div className="flex-container no-margin">
            <Input.Text
              value={productNames}
              onChange={(event) => {
                setProductNames(event.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-control">
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за клиенти</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  onSetClientDiagram((prevState) => [...prevState, ...data])
                  setClientDiagram((prevState) => [...prevState, ...data])
                  stopLoading()
                })
              }}
            />
          </div>
          {(clientDiagram.length > 0 &&
            clientDiagram.map((el, index) => {
              return (
                <FileItem
                  {...el}
                  onRemove={() => {
                    let newDiagrams = clientDiagram.slice(0)
                    newDiagrams.splice(index, 1)
                    onSetClientDiagram([...newDiagrams])
                    setClientDiagram([...newDiagrams])
                  }}
                />
              )
            })) ||
            (clientDiagram.length > 0 &&
              clientDiagram.map((el, index) => (
                <FileItem
                  key={index}
                  {...el}
                  onRemove={() => {
                    onSetClientDiagram([])
                    setClientDiagram([])
                  }}
                />
              )))}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за производство</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  onSetProductionDiagram((prevState) => [...prevState, ...data])
                  setProductionDiagram((prevState) => [...prevState, ...data])
                  stopLoading()
                })
              }}
            />
          </div>
          {(productionDiagram.length > 0 &&
            productionDiagram.map((el, index) => {
              return (
                <FileItem
                  {...el}
                  onRemove={() => {
                    let newDiagrams = productionDiagram.slice(0)
                    newDiagrams.splice(index, 1)
                    onSetProductionDiagram([...newDiagrams])
                    setProductionDiagram([...newDiagrams])
                  }}
                />
              )
            })) ||
            (productionDiagram.length > 0 &&
              productionDiagram.map((diagram, index) => (
                <FileItem
                  key={index}
                  {...diagram}
                  onRemove={() => {
                    onSetProductionDiagram([])
                    setProductionDiagram([])
                  }}
                />
              )))}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Снимки</span>
            <UploadFile
              accept={"image/*"}
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                Files.uploadFiles(e.target.files).then((result) => {
                  setImages((prevState) => [
                    ...prevState,
                    ...result.map((el) => el.location),
                  ])
                  onSetImages((prevState) => [
                    ...prevState,
                    ...result.map((el) => el.location),
                  ])
                  stopLoading()
                })
              }}
            />
          </div>
          {images && (
            <div className="images-scroll">
              {images.map((img, idx) => {
                return (
                  <>
                    <img key={`Image-${idx}`} src={img} alt="item" />
                    <ClearIcon
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        let newImages = images.slice(0)
                        newImages.splice(idx, 1)
                        onSetImages(newImages)
                        setImages(newImages)
                      }}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ReservoirView)
