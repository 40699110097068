import React, { useEffect, useRef, useState } from "react"
import { Inputs } from "../../components"
import { isArray, omit, uniqBy } from "lodash"
import "./styles.scss"
const FiltersPopup = ({
  close,
  filter: filterProp = {},
  filterOptions,
  handleSubmit,
}) => {
  const { type, key, options, fetch, transform } = filterOptions

  const [filter, setFilter] = useState(filterProp)

  const [dbOptions, setDbOptions] = useState([])
  const allOptions = uniqBy(
    [...(isArray(filter[key]) ? filter[key] : []), ...dbOptions],
    "value"
  )
  const firstRender = useRef(true)

  useEffect(() => {
    if (
      !firstRender.current ||
      type !== "dbDropdown" ||
      !["departments"].includes(key)
    )
      return
    firstRender.current = false
    fetch()
      .then(({ data: { payload } }) => setDbOptions(transform(payload)))
      .catch((error) => console.error(error))
  }, [dbOptions.length, fetch, key, transform, type])

  const timerRef = useRef(null)
  useEffect(() => {
    const timer = timerRef.current
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [])

  const renderContent = () => {
    switch (type) {
      case "checkboxes":
        return (
          <Inputs.CheckBoxes
            style={{ maxHeight: "500px", overflow: "hidden" }}
            column
            full
            special
            options={options}
            value={filter[key] || ""}
            onChange={({ value }) => {
              const current = filter[key] ?? []
              if (current.includes(value))
                setFilter({
                  ...filter,
                  [key]: current.filter((v) => v !== value),
                })
              else setFilter({ ...filter, [key]: [...current, value] })
            }}
          />
        )
      case "text":
        return (
          <Inputs.TextInput
            outerStyle={{ width: "95%", margin: "0% 2%", height: "70px" }}
            value={filter[key] || ""}
            onChange={(e) => setFilter({ ...filter, [key]: e.target.value })}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="filters-popup-container">
      <div
        className="flex w-full justify-between items-center"
        style={{ padding: "10px" }}
      >
        <h1 style={{ fontSize: "14px", marginLeft: "5px" }}>Филтрирай по</h1>
        <div className="close-btn w-4 h-4" onClick={close} />
      </div>
      <div className="filters-popup-content px-[10px] py-[10px]">
        {renderContent()}
      </div>
      {(type !== "dbDropdown" || filter[key] || allOptions.length > 0) && (
        <div
          className="filters-popup-footer flex w-full rounded-b-md w-full flex gap-[5px] px-[10px] py-[5px] shadow-[0px_-1px_6px_-4px_#515867]"
          style={{ padding: "5px 10px" }}
        >
          <Inputs.Button
            text="ИЗЧИСТИ"
            className={"border border-[#515867] w-1/2"}
            style={{ width: "50%" }}
            disabled={!filterProp[key]}
            onClick={() => {
              handleSubmit(omit(filter, [key]))
              close()
            }}
          />
          <Inputs.Button
            text="ЗАПАЗИ"
            className={"selected w-1/2"}
            style={{ width: "50%" }}
            disabled={!filter[key]}
            onClick={() => {
              handleSubmit(filter)
              close()
            }}
          />
        </div>
      )}
    </div>
  )
}

export default FiltersPopup
