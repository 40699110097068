import React from "react";
import "./styles.scss";
import CalendarTask from "./CalendarTask";
import { useState } from "react";
import { useEffect } from "react";
import { capitalizeFirstLetter } from '../../utilities/helpers'
import moment from "moment";
import { history } from "../../config/stores"

export default function CalendarSection({ type, date, setDate }) {
    const data = [
        { performer: 'Александър Иванов', tasks: [
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'finished', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'finished', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
        ]},
        { performer: 'Борислав Димитров', tasks: [
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'finished', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' }
        ]},
        { performer: 'Васил Петков', tasks: [
            { title: 'Изготвяне на офета за Община Бургас', status: 'finished', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'finished', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' }
        ]},
        { performer: 'Георги Красимиров', tasks: [
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'finished', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' }
        ]},
        { performer: 'Димитър Кирилов', tasks: [
            { title: 'Изготвяне на офета за Община Бургас', status: 'active', text: 'Нужна ни е калкуляция за линейно отводняване по проекта за АМ Тракия. Всички мерки и данни са добавени в проекта, моля прегледай и да обсъдим.' },
        ]},
    ]

    const [ weekdays, setWeekdays ] = useState([])
    const week = [];
    let weekday = moment(date).startOf('week')
    const monthDays = []
    let monthDay = moment(date).startOf('month')
    const monthDaysCount = moment(date).endOf('month').format('D')

    
  for (let i = 0; i < 7; i++) {
    if (i !== 0) {
      weekday = moment(weekday).add(1, 'day')
    }

    const currentDate = weekday.format('D')
    const currentMonth = weekday.format('MMMM')
    const currentDay = weekday.format('dddd')
    week.push({
      date: currentDate,
      month: currentMonth,
      weekday: currentDay,
      isVisible: true
    })
  }

  for (let i = 0; i < monthDaysCount; i++) {
    if (i !== 0) {
      monthDay = moment(monthDay).add(1, 'day')
    }

    const currentDate = monthDay.format('D')
    const currentMonth = monthDay.format('MMMM')
    const currentDay = monthDay.format('dddd')
    monthDays.push({
      date: currentDate,
      month: currentMonth,
      weekday: currentDay,
      requestData: []
    })
  }

  const linesCount = (type === 'Месечен' && monthDays.length) || (type === 'Седмичен' && weekdays.filter(day => day.isVisible).length)

    // request to get all tasks for the date based on type
    // call setDate func from props when changing arrows to call new request

    useEffect(() => {
      setDate(date)
      setWeekdays(week)
    }, [date])

  return (
    <div className="calendar-section-wrapper">
        {type === 'Дневен' &&
        <>
        <div className="schedule-daily-main">
        <div className="arrows-wrapper">
          <div className="arrows">
            <img
              src={require('../../assets/icons/arrowLeft.svg')}
            //   onClick={() => {
            //     const newDate = moment(date).subtract(1, "day")
            //     setDate(newDate)
            //     onSetDate(newDate)
            //     dispatch(
            //       getMachineSchedule({
            //         start: moment(newDate).startOf("day").startOf("hour"),
            //         end: moment(newDate).endOf("day").endOf("hour"),
            //         groupBy: "performerNew",
            //       })
            //     )
            //   }}
            />
            <img
              src={require('../../assets/icons/arrowRight.svg')}
            //   onClick={() => {
            //     const newDate = moment(date).add(1, "day")
            //     setDate(newDate)
            //     onSetDate(newDate)
            //     dispatch(
            //       getMachineSchedule({
            //         start: moment(newDate).startOf("day").startOf("hour"),
            //         end: moment(newDate).endOf("day").endOf("hour"),
            //         groupBy: "performerNew",
            //       })
            //     )
            //   }}
            />
          </div>
        </div>
      </div>
      <div className="daily-orders-wrapper horizontal-scroll">
        {data?.map((performerData, index) => {
          return (
            <div
              key={`task-performer-element-${index}`}
              className="daily-order-wrapper"
              style={{ width: '400px' }}
            >
              <h3
                className="order-main-title horizontal-scroll"
                style={{ width: '415px' }}
              >
                {performerData?.performer}
              </h3>
              <div className="render-container">
                {performerData?.tasks?.map((task, index) => {
                  return (
                    <CalendarTask
                      key={`performers-daily-column-${index}`}
                      onClick={() => history.push('/review-task')}
                      data={task}
                      type='Дневен'
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
        {data.length === 0 && (
          <div className="daily-order-wrapper">
            <h3
              className="order-main-title horizontal-scroll"
              style={{ width: "92.6%" }}
            />
            <div className="render-container" />
          </div>
        )}
      </div>
        </>}

        {(type === 'Седмичен' || type === 'Месечен') && 
         <div className='schedule-wrapper'>
            <div className='schedule-weekly-monthly-main'>
              <div className='arrows-wrapper'>
                <div className='arrows'>
                  <img
                    src={require('../../assets/icons/arrowLeft.svg')}
                    // onClick={() => {
                    //   dispatch(showLoading())
                    //   if (type === 'Седмичен') {
                    //     const newDate = moment(date).subtract(7, 'days').toDate()
                    //     setDate(newDate)
                    //     onSetDate(newDate)
                    //     if (requestType === 'Тръби - машини') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week')
                    //       }))
                    //     } else if (requestType === 'Тръби - изпълнители') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week'),
                    //         groupBy: 'performerNew'
                    //       }))
                    //     } else if (requestType === 'Краен продукт') {
                    //       dispatch(getMachineScheduleByPerformer({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week')
                    //       }))
                    //     } else if (requestType === 'Допълнителни') {
                    //       dispatch(getActionSchedule({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week')
                    //       }))
                    //     }
                    //   } else if (type === 'Месечен') {
                    //     const newDate = moment(date).subtract(1, 'months')
                    //     setDate(newDate)
                    //     onSetDate(newDate)
                    //     if (requestType === 'Тръби - машини') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month')
                    //       }))
                    //     } else if (requestType === 'Тръби - изпълнители') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month'),
                    //         groupBy: 'performerNew'
                    //       }))
                    //     } else if (requestType === 'Краен продукт') {
                    //       dispatch(getMachineScheduleByPerformer({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month')
                    //       }))
                    //     } else if (requestType === 'Допълнителни') {
                    //       dispatch(getActionSchedule({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month')
                    //       }))
                    //     }
                    //   }
                    // }}
                  />
                  <img
                    src={require('../../assets/icons/arrowRight.svg')}
                    // onClick={() => {
                    //   dispatch(showLoading())
                    //   if (type === 'Седмичен') {
                    //     const newDate = moment(date).add(7, 'days').toDate()
                    //     setDate(newDate)
                    //     onSetDate(newDate)
                    //     if (requestType === 'Тръби - машини') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week')
                    //       }))
                    //     } else if (requestType === 'Тръби - изпълнители') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week'),
                    //         groupBy: 'performerNew'
                    //       }))
                    //     } else if (requestType === 'Краен продукт') {
                    //       dispatch(getMachineScheduleByPerformer({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week')
                    //       }))
                    //     } else if (requestType === 'Допълнителни') {
                    //       dispatch(getActionSchedule({
                    //         start: moment(newDate).startOf('week'),
                    //         end: moment(newDate).endOf('week')
                    //       }))
                    //     }
                    //   } else if (type === 'Месечен') {
                    //     const newDate = moment(date).add(1, 'months')
                    //     setDate(newDate)
                    //     onSetDate(newDate)
                    //     if (requestType === 'Тръби - машини') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month')
                    //       }))
                    //     } else if (requestType === 'Тръби - изпълнители') {
                    //       dispatch(getMachineSchedule({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month'),
                    //         groupBy: 'performerNew'
                    //       }))
                    //     } else if (requestType === 'Краен продукт') {
                    //       dispatch(getMachineScheduleByPerformer({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month')
                    //       }))
                    //     } else if (requestType === 'Допълнителни') {
                    //       dispatch(getActionSchedule({
                    //         start: moment(newDate).startOf('month'),
                    //         end: moment(newDate).endOf('month')
                    //       }))
                    //     }
                    //   }
                    // }}
                  />
                </div>
              </div>
      
              {type === 'Седмичен' && (
                <ul className='schedule-weeks'>
                  {weekdays.map((day, index) => {
                    return (
                      day.isVisible &&
                      <li
                        key={`weekday-number-${index}`}
                        className={
                          day.date === moment().format('D') &&
                          day.month === moment().format('MMMM')? 'selected-day' : ''
                        }
                      >
                        <h2>{day.date}</h2>
                        <h3>{capitalizeFirstLetter(day.month)}</h3>
                        <h3 className='weekday'>
                          {capitalizeFirstLetter(day.weekday)}
                        </h3>
                      </li>
                    )
                  })}
                </ul>
              )}
      
              {type === 'Месечен' && (
                <ul className='schedule-weeks'>
                  {monthDays.map((day, index) => {
                    return (
                      <li
                        key={`monthday-number-${index}`}
                        className={
                          day.date === moment().format('D') &&
                          day.month === moment().format('MMMM')
                            ? 'selected-day'
                            : ''
                        }
                      >
                        <h2>{day.date}</h2>
                        <h3>{capitalizeFirstLetter(day.month)}</h3>
                        <h3 className='weekday'>
                          {capitalizeFirstLetter(day.weekday)}
                        </h3>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>

            <div className='weekly-monthly-orders-wrapper'>
            {data.length === 0 &&
            <>
              <div className='line-background-wrapper'>
                {Array(linesCount)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-${index}`} className='line-background' />
                  })}
              </div>
              <div className={`weekly-monthly-order-wrapper ${type === 'Месечен' ? 'month' : ''}`} style={{minHeight: type === 'Месечен' && monthDaysCount * 164}}>
                <h3 className='order-main-title' style={{ width: '100%' }}/>
              </div>
            </>}
            
            {data?.map((tasks, index) => {
              return (
                <div
                  className={`weekly-monthly-order-wrapper ${type === 'Месечен' ? 'month' : ''}`}
                  style={{ width: '400px',
                    minHeight: type === 'Месечен' && monthDaysCount * 164 }}
                  key={`executor-weekly-${index}`}
                >
                  <h3
                    className='order-main-title'
                    style={{ width: '400px' }}
                  >{tasks.performer}
                  </h3>
                  <div className='line-background-wrapper'>
                    {Array(linesCount)
                      .fill(0)
                      .map((el, index) => {
                        return <li key={`line-number-${index}`} className='line-background' />
                      })}
                  </div>
                  <div className='render-container'>
                    {tasks?.tasks?.map((task, i) => {
                    //   const dailyRequests = Object.values(task)[0]
                    const dailyRequests = JSON.parse(JSON.stringify(task))
                      if (type === 'Седмичен') {
                        if (weekdays.length > 0 && weekdays[i].isVisible === false) {
                          return null
                        }
                        else if (dailyRequests.length === 0) {
                          return <div key={`weekly-performers-col-${i}`} />
                        } 
                        else {
                          return (
                            <div className='weekday-requests-container' key={`weekday-requests-${i}`}>
                               <CalendarTask
                               key={`weekly-performers-col-key-${i}-`}
                               data={task}
                               onClick={() => history.push('/review-task')}
                               />
                               {/* <TaskPreviewPopup data={task} /> */}
                            </div>
                          )
                        }
                      } else if (type === 'Месечен') {
                        if (task.length === 0) {
                          return <div key={`monthly-performers-col-empty-${i}`} />
                        } 
                        else {
                        // else if (task === tasks.performer) {
                        // } else if (task.length > 0 &&
                        //     task === tasks.performer) {
                          return (
                            <div className='weekday-requests-container' key={`monthly-performers-col-${i}`}
                            onClick={() => history.push('/review-task')}>
                                <CalendarTask
                                  key={`monthly-performers-col-key-${i}--`}
                                  data={task}
                                  onClick={() => history.push('/review-task')}
                                  />
                            </div>
                          )
                        }
                      }
                    })}
                  </div>
                </div>
              )
            })}
          </div>


          </div>}
    </div>
  )
}
