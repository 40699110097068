import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { OfferProduct } from "../OfferCreationModal/OffectProduct"
import {
  hideModal,
  searchOfferClients,
  resetResults,
  createProformInvoice,
  createProformInvoiceEn,
  editProformInvoice,
  editProformInvoiceEn,
  deleteProformInvoice,
} from "../../../actions"
import { ClientAutocomplete } from "../../../components"
import moment from "moment"
import "./styles.scss"

const getProductValue = (product) => {
  if (product.singleUnitPrice) {
    return Number(product.singleUnitPrice) || 0
  } else if (product.expenses) {
    const materialCost = product.expenses.material.reduce((acc, val) => {
      return acc + Number(val.total) || 0
    }, 0)
    const staffCost =
      product.expenses.staff &&
      product.expenses.staff.reduce((acc, val) => {
        return acc + Number(val.total)
      }, 0)
    const otherCost =
      product.expenses.other &&
      product.expenses.other.reduce((acc, val) => {
        return acc + Number(val.total)
      }, 0)
    const customCost =
      product.expenses.custom &&
      product.expenses.custom.reduce((acc, val) => {
        return (
          acc +
          val.elements.reduce((acc, val) => {
            return acc + Number(val.total)
          }, 0)
        )
      }, 0)
    return (
      Number(materialCost) +
      Number(staffCost) +
      Number(otherCost) +
      Number(customCost)
    )
  } else {
    return 0
  }
}

function ProformInvoiceModal({
  hideModal,
  data,
  clientResults,
  productTypes,
  searchOfferClients,
  resetResults,
  createProformInvoice,
  createProformInvoiceEn,
  editProformInvoice,
  editProformInvoiceEn,
  deleteProformInvoice,
}) {
  const [client, setClient] = useState("")
  const [EIK, setEIK] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [reason, setReason] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [products, setProducts] = useState([])
  const [language, setLanguage] = useState("bg")
  const [currency, setCurrency] = useState("BGN")
  const [isCurrencyChanged, setIsCurrencyChanged] = useState(false)

  const currencyMap = { Лева: "BGN", Евро: "EUR" }
  const currencyDropdownMap = { BGN: "Лева", EUR: "Евро" }

  useEffect(() => {
    if (data.forEdit) {
      setBankAccount(data.proform.bankData.bankAccount)
      setClient(data.proform.client.name)
      setEIK(data.proform.client.uic)
      setPhone(data.proform.client.phone)
      setEmail(data.proform.client.email)
      setAddress(data.proform.client.address)
      setReason(data.proform.reason)
      setCurrency(data.proform.currency)

      const products = data.proform.products.map((product) => {
        const found = data.includedProducts.find(
          (el) => el._id === product.item._id
        )

        if (data?.proform?.currency === "EUR") {
          return {
            ...found,
            singleUnitPrice:
              (product.singleUnitPrice / 1.95583).toFixed(2) * 100,
            deliveryDate: product.deliveryDate,
            quantity: product.quantity,
          }
          // product.singleUnitPrice = (product.singleUnitPrice / 1.95583).toFixed(2) * 100
        }

        return {
          ...found,
          singleUnitPrice: product.singleUnitPrice,
          deliveryDate: product.deliveryDate,
          quantity: product.quantity,
        }
      })
      setProducts(products)
    }
  }, [data])

  useEffect(() => {
    if (!data.forEdit && data.includedProducts.length) {
      const readyProducts = data.includedProducts.map((prod) => {
        const quantity = prod.quantity ? prod.quantity : null
        const date = prod.deliveryDate ? prod.deliveryDate : moment().toDate()
        return {
          ...prod,
          quantity: quantity ? quantity : prod.orderQuantity,
          deliveryDate: date,
        }
      })
      setProducts(readyProducts)
    }
  }, [data])

  const productsTotal =
    products.length &&
    products.reduce((acc, val) => {
      return acc + getProductValue(val) / 100
    }, 0)

  const initialProductsWithoutQuantity =
    products.length &&
    data.includedProducts.reduce((acc, val) => {
      const chosenProduct = products.find((prod) => {
        if (prod.item) {
          return prod.item._id === val._id
        } else {
          return prod._id === val._id
        }
      })
      if (!chosenProduct) {
        return acc + 0
      } else {
        return acc + getProductValue(val) / 100
      }
    }, 0)

  let totalPrice =
    products.length &&
    products.reduce((acc, val) => {
      return acc + (getProductValue(val) * val.quantity) / 100
    }, 0)

  useEffect(() => {
    if (!products?.length) return
    console.log("currency change")
    const newChosenProducts = products.slice(0)
    newChosenProducts.map((product) => (product.singleUnitPrice = 0))
    setProducts(newChosenProducts)

    totalPrice = 0
  }, [isCurrencyChanged])

  console.log(products)

  return (
    <div className="proform-invoice-container">
      <div className="left-container">
        <h3>Създаване на проформа фактура</h3>

        <div className="flex-container space-between">
          <div className="input-element">
            <p>Клиент</p>
            <ClientAutocomplete
              onChooseClient={(data) => {
                setClient(data.name)
                if (data.uic) setEIK(data.uic)
                else setEIK("")

                if (data.phone) setPhone(data.phone)
                else setPhone("")

                if (data.email) setEmail(data.email)
                else setEmail("")

                if (data.address) setAddress(data.address)
                else setAddress("")
              }}
              onChange={(event) => setClient(event.target.value)}
            />
          </div>
          <div className="input-element">
            <p>ЕИК</p>
            <Input.Text
              value={EIK}
              onChange={(event) => setEIK(event.target.value)}
            />
          </div>
          <div className="input-element">
            <p>Телефон</p>
            <Input.Text
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container space-between">
          <div className="input-element">
            <p>Имейл</p>
            <Input.Text
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="input-element large">
            <p>Адрес</p>
            <Input.Text
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div className="input-element">
          <p>Основание</p>
          <Input.Text
            value={reason}
            onChange={(event) => setReason(event.target.value)}
          />
        </div>
        <div className="flex-container flex-start margin">
          <div className="input-element small">
            <p>Ваша банкова сметка</p>
            <Input.Dropdown
              placeholder="Моля, изберете"
              value={bankAccount}
              options={["ДСК", "УниКредит", "Райфайзен"]}
              onChange={(value) => setBankAccount(value.value)}
            />
          </div>
          <div className="input-element small">
            <p>Валута</p>
            <Input.Dropdown
              placeholder="Моля, изберете"
              value={currencyDropdownMap?.[currency]}
              options={["Лева", "Евро"]}
              onChange={(value) => {
                setCurrency(currencyMap?.[value.value])
                setIsCurrencyChanged(!isCurrencyChanged)
              }}
            />
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="top-container">
          <h3>Продукти към проформа фактура</h3>
          <div className="exit-btn" onClick={hideModal} />
        </div>
        <div className="scroll-container">
          {products.map((product, index) => {
            return (
              <OfferProduct
                forEdit
                noDropDown
                currency={currency}
                productTypes={productTypes}
                product={product}
                onSetProductDate={(date) => {
                  let newProducts = products.slice(0)
                  newProducts[index].deliveryDate = date
                  setProducts(newProducts)
                }}
                onSetQuantity={(value) => {
                  let newChosenProducts = products.slice(0)
                  newChosenProducts[index].quantity = value
                  setProducts(newChosenProducts)
                }}
                onRemoveProduct={() => {
                  let newChosenProducts = products.slice(0)
                  newChosenProducts.splice(index, 1)
                  setProducts(newChosenProducts)
                }}
                onSetProductPrice={(value) => {
                  let newChosenProducts = products.slice(0)
                  if (!newChosenProducts[index].singleUnitPrice) {
                    newChosenProducts[index] = {
                      ...newChosenProducts[index],
                      singleUnitPrice: Number(value) * 100,
                    }
                  } else {
                    newChosenProducts[index].singleUnitPrice =
                      Number(value) * 100
                  }
                  setProducts(newChosenProducts)
                }}
              />
            )
          })}
        </div>
        <div className="margin-value-container">
          <p id="margin">
            Марж: {productsTotal - initialProductsWithoutQuantity}
            лв. |{" "}
            {(
              ((productsTotal - initialProductsWithoutQuantity) /
                productsTotal) *
              100
            ).toFixed(0)}
            %
          </p>
        </div>
        <div className="flex-container space-between">
          <p>Стойност на продуктите</p>
          {currency === "EUR" ? (
            <div className="value-container">{totalPrice.toFixed(2)} €</div>
          ) : (
            <div className="value-container">{totalPrice.toFixed(2)} лв.</div>
          )}
        </div>
        <div className="flex-container">
          {data.forEdit && (
            <Button.Raised
              text="Изтрий документ"
              className="delete-btn"
              onClick={() => {
                deleteProformInvoice({
                  _id: data.proform._id,
                })
              }}
            />
          )}

          <Button.Raised
            text={`${data.forEdit ? "Редактирай" : "Създай"}`}
            onClick={() => {
              const chosenProducts = products.map((product) => {
                let cost = 0
                if (product.expenses) {
                  const materialCost = product.expenses.material.reduce(
                    (acc, val) => {
                      return acc + val.total || 0
                    },
                    0
                  )
                  const staffCost =
                    product.expenses.staff &&
                    product.expenses.staff.reduce((acc, val) => {
                      return acc + val.total
                    }, 0)
                  const otherCost =
                    product.expenses.other &&
                    product.expenses.other.reduce((acc, val) => {
                      return acc + val.total
                    }, 0)
                  const customCost =
                    product.expenses.custom &&
                    product.expenses.custom.reduce((acc, val) => {
                      return (
                        acc +
                        val.elements.reduce((acc, val) => {
                          return acc + val.total
                        }, 0)
                      )
                    }, 0)
                  cost =
                    (materialCost + staffCost + otherCost + customCost) / 100
                }

                if (currency === "EUR") cost = cost * 1.95583
                // product.singleUnitPrice = product.singleUnitPrice * 1.95583

                return {
                  item: product._id,
                  quantity: parseInt(product.quantity),
                  deliveryDate: product.deliveryDate,
                  singleUnitPrice: product.singleUnitPrice / 100 || cost,
                  total: cost * parseInt(product.quantity),
                  unit: product?.mainUnit || product?.unit,
                  //added unit
                }
              })
              if (data.forEdit) {
                editProformInvoice({
                  _id: data.proform._id,
                  inquiry: data._id,
                  client: {
                    name: client,
                    uic: EIK,
                    phone: phone,
                    email: email,
                    address: address,
                    attentionOf: client,
                  },
                  reason: reason,
                  bankData: {
                    bankAccount: bankAccount,
                    marginFacilities: isNaN(
                      (productsTotal - initialProductsWithoutQuantity) / 100
                    )
                      ? 0
                      : (productsTotal - initialProductsWithoutQuantity) / 100,
                    marginHydro: isNaN(
                      (
                        ((productsTotal - initialProductsWithoutQuantity) /
                          productsTotal) *
                        100
                      ).toFixed(0)
                    )
                      ? 0
                      : (
                          ((productsTotal - initialProductsWithoutQuantity) /
                            productsTotal) *
                          100
                        ).toFixed(0),
                  },
                  products: chosenProducts,
                  currency: currency,
                })
              } else {
                createProformInvoice({
                  request: data._id,
                  inquiry: data._id,
                  client: {
                    name: client,
                    uic: EIK,
                    phone: phone,
                    email: email,
                    address: address,
                    attentionOf: client,
                  },
                  reason: reason,
                  bankData: {
                    bankAccount: bankAccount,
                    marginFacilities: isNaN(
                      (productsTotal - initialProductsWithoutQuantity) / 100
                    )
                      ? 0
                      : (productsTotal - initialProductsWithoutQuantity) / 100,
                    marginHydro: isNaN(
                      (
                        ((productsTotal - initialProductsWithoutQuantity) /
                          productsTotal) *
                        100
                      ).toFixed(0)
                    )
                      ? 0
                      : (
                          ((productsTotal - initialProductsWithoutQuantity) /
                            productsTotal) *
                          100
                        ).toFixed(0),
                  },
                  products: chosenProducts,
                  currency: currency,
                })
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  productTypes: state.products.types,
  clientResults: state.inquiries.clientResults,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  searchOfferClients: (payload) => dispatch(searchOfferClients(payload)),
  resetResults: () => dispatch(resetResults()),
  createProformInvoice: (payload) => dispatch(createProformInvoice(payload)),
  createProformInvoiceEn: (payload) =>
    dispatch(createProformInvoiceEn(payload)),
  editProformInvoice: (payload) => dispatch(editProformInvoice(payload)),
  editProformInvoiceEn: (payload) => dispatch(editProformInvoiceEn(payload)),
  deleteProformInvoice: (payload) => dispatch(deleteProformInvoice(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProformInvoiceModal)
