import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ScheduleDailyOrderColumn from './ScheduleDailyOrderColumn'
import { scale } from '../../../utilities/helpers'
import './Daily.scss'
import '../WeeklyMonthly/WeeklyMonthly.scss'
import '../Production.scss'
import { history } from '../../../config/stores'
import { getMachineSchedule, getMachineScheduleByPerformer, getActionSchedule, showLoading } from '../../../actions'
import { useSelector, useDispatch } from 'react-redux'

const hoursColumn = [
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00'
]

const halfHours = [
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30'
]

export default function Daily ({ onSetDate, requestType }) {
  const arrowRightIcon = require('../../../assets/icons/arrowRight.svg')
  const arrowLeftIcon = require('../../../assets/icons/arrowLeft.svg')
  const timePointerIcon = require('../../../assets/icons/timePointer.svg')
  const blueCircleIcon = require('../../../assets/icons/circle-blue.svg')
  const [date, setDate] = useState(moment())
  const dispatch = useDispatch()
  const requestData = useSelector((state) => state.production.productSchedule)
  const actionData = useSelector((state) => state.production.actionSchedule)

  const getCurrentTime = () =>
    moment().startOf('hour').isBefore(moment().startOf('day').add(7, 'hours'))
      ? Math.abs(moment().hours() + 17) * 56 + scale(moment().minutes(), 0, 59, 0, 56) + 81
      : Math.abs(moment().hours() - 7) * 56 + scale(moment().minutes(), 0, 59, 0, 56) + 81
      

  const [currentTime, setCurrentTime] = useState(getCurrentTime())
  const currentTimeRef = useRef()

  let activityPerformers = [];
  actionData.map(el => {
    activityPerformers.push(...el.performers)});
    activityPerformers = [...new Set(activityPerformers)]

  const columnWidth = Object.keys(requestData).length > 3
    ? Object.keys(requestData).length * 194
    : 1758 / Object.keys(requestData).length - 8

  const checkSchedule = (currentColumn) => {
    const requests = halfHours.slice(0)
    currentColumn && currentColumn.forEach((requestData, index) => {
      const start = requestData.start ? requestData.start : requestData.startsAt
      const duration = requestData.durationMinutes ? requestData.durationMinutes / 60 : moment(requestData.end).diff(moment(requestData.start), 'hours')
      let counter = duration * 2 - 1
      // let counter = duration * 2

      currentColumn[index + 1]
      // && (currentColumn[index + 1].durationMinutes ? currentColumn[index + 1].durationMinutes / 60 : moment(currentColumn[index + 1].end).diff(moment(currentColumn[index + 1].start), 'hours')) % 2 === 0
      && (currentColumn[index + 1]?.start || currentColumn[index + 1]?.startsAt) === (requestData?.end || requestData?.endsAt)
      && counter++
      let isRequestRendering = false
      halfHours.map((halfHour, i) => {
        if (moment(start).format('HH:mm') === halfHour &&
          isRequestRendering === false && (requestData?.request || requestData?.activity)) {
          requests.splice(i, 1, requestData)
          isRequestRendering = true
        } else if (counter > 0 && isRequestRendering === true) {
          requests.splice(i, 1, 'empty')
          counter--
        } else if (counter === 0) {
          isRequestRendering = false
        }
      })
    })
    return requests
  }

  useEffect(() => {
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(getCurrentTime())
    }, 1000)
    return () => {
      clearInterval(currentTimeInterval)
    }
  }, [])

  useEffect(() => {
    if (currentTimeRef?.current) {
      currentTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }, [currentTimeRef.current])

  useEffect(() => {
    dispatch(showLoading())
    if (requestType === 'Тръби - машини') {
      dispatch(
        getMachineSchedule({
          start: moment(date).startOf('day').startOf('hour'),
          end: moment(date).endOf('day').endOf('hour')
        })
      )
    } else if (requestType === 'Тръби - изпълнители') {
      dispatch(
        getMachineSchedule({
          start: moment(date).startOf('day').startOf('hour'),
          end: moment(date).endOf('day').endOf('hour'),
          groupBy: 'performerNew'
        })
      )
    } else if (requestType === 'Краен продукт') {
      dispatch(
        getMachineScheduleByPerformer({
          start: moment(date).startOf('day').startOf('hour'),
          end: moment(date).endOf('day').endOf('hour')
        })
      )
    } else if (requestType === 'Допълнителни') {
      dispatch(
        getActionSchedule({
          start: moment(date).startOf('day').startOf('hour'),
          end: moment(date).endOf('day').endOf('hour')
        })
      )
    }
    onSetDate(date)
  }, [requestType])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (requestType === 'Тръби - машини') {
        dispatch(
          getMachineSchedule({
            start: moment(date).startOf('day').startOf('hour'),
            end: moment(date).endOf('day').endOf('hour')
          })
        )
      } else if (requestType === 'Тръби - изпълнители') {
        dispatch(
          getMachineSchedule({
            start: moment(date).startOf('day').startOf('hour'),
            end: moment(date).endOf('day').endOf('hour'),
            groupBy: 'performerNew'
          })
        )
      } else if (requestType === 'Краен продукт') {
        dispatch(
          getMachineScheduleByPerformer({
            start: moment(date).startOf('day').startOf('hour'),
            end: moment(date).endOf('day').endOf('hour')
          })
        )
      } else if (requestType === 'Допълнителни') {
        dispatch(
          getActionSchedule({
            start: moment(date).startOf('day').startOf('hour'),
            end: moment(date).endOf('day').endOf('hour')
          })
        )
      }
      onSetDate(date)
    }, 10000);
    return () => clearTimeout(timer);
  }, [requestData, actionData]);

  const renderData = () => {
    switch (requestType) {
      case 'Тръби - машини':
        return (
          <>
            <div className='schedule-daily-main'>
              <div className='arrows-wrapper'>
                <div className='arrows'>
                  <img
                    src={arrowLeftIcon}
                    onClick={() => {
                      const newDate = moment(date).subtract(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getMachineSchedule({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour')
                        })
                      )
                    }}
                  />
                  <img
                    src={arrowRightIcon}
                    onClick={() => {
                      const newDate = moment(date).add(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getMachineSchedule({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour')
                        })
                      )
                    }}
                  />
                </div>
              </div>

              <ul className='schedule-hours'>
                {hoursColumn.map((hour, index) => {
                  return (
                    <li
                      key={`hour-number-${index}`}
                      className={
                  hour === moment().startOf('hour').format('HH:mm')
                    ? 'selected-hour'
                    : ''
                }
                    >
                      {hour}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='daily-orders-wrapper'>
              <div className='line-background-wrapper'>
                {Array(24)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-${index}`} className='line-background' />
                  })}
              </div>
              <div className='daily-order-wrapper' >
                <h3 className='order-main-title machine'>Машина OD800</h3>
                <div className='render-container'>
                  {checkSchedule(requestData?.OD800)?.map((el, index) => {
                    if (el === 'empty') {
                      return null
                    } else if (typeof el === 'object') {
                      return (
                        <ScheduleDailyOrderColumn
                        key={`machine-one-daily-${index}-${el._id}`}
                          onClick={() => {
                            window.open(`/production/pipe/${el._id}`)
                            // history.push(`/production/pipe/${el._id}`)
                          }} ordersColumnData={el}
                        />
                      )
                    } else {
                      return <div key={`empty-element-daily-column-machine-one-${index}-${el._id}`}/>
                    }
                  })}
                </div>
              </div>
              <div className='daily-order-wrapper' >
                <h3 className='order-main-title machine'>Машина OD2000</h3>
                <div className='render-container'>
                  {checkSchedule(requestData?.OD2000)?.map((el, i) => {
                    if (el === 'empty') {
                      return null
                    } else if (typeof el === 'object') {
                      return (
                        <ScheduleDailyOrderColumn
                        key={`machine-two-daily-${i}-${el._id}`}
                          onClick={() => {
                            window.open(`/production/pipe/${el._id}`)
                            // history.push(`/production/pipe/${el._id}`)
                          }} ordersColumnData={el}
                        />
                      )
                    } else {
                      return <div key={`empty-element-daily-column-machine-two-${i}-${el._id}`}/>
                    }
                  })}
                </div>
              </div>
              <div className='daily-order-wrapper'>
                <h3 className='order-main-title machine'>Машина OD2400</h3>
                <div className='render-container last'>
                  {checkSchedule(requestData?.OD2400)?.map((el, index) => {
                    if (el === 'empty') {
                      return null
                    } else if (typeof el === 'object') {
                      return (
                        <ScheduleDailyOrderColumn
                        key={`element-daily-column-three-${index}-${el._id}`}
                          onClick={() => {
                            window.open(`/production/pipe/${el._id}`)
                            // history.push(`/production/pipe/${el._id}`)
                          }} ordersColumnData={el}
                        />
                      )
                    } else {
                      return <div key={`empty-element-daily-column-machine-three-${index}-${el._id}`} />
                    }
                  })}
                </div>
              </div>
            </div>
            <div
              className='schedule-current-time'
              ref={currentTimeRef}
              style={{
                top: Math.abs(currentTime)
              }}
            >
              <img src={timePointerIcon} />
            </div>
            <div className='night-shift-label'>
              <img src={blueCircleIcon}/>
            </div>
          </>
        )
      case 'Тръби - изпълнители':
        return (
          <>
            {/* <div className='headers-wrapper'>
                {Object.keys(requestData).map(el =>
                  {return el &&
                   <h3 className='order-main-title'
                   >{el || ''}</h3>})}
              </div> */}
            <div className='schedule-daily-main'>
              <div className='arrows-wrapper'>
                <div className='arrows'>
                  <img
                    src={arrowLeftIcon}
                    onClick={() => {
                      const newDate = moment(date).subtract(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getMachineSchedule({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour'),
                          groupBy: 'performerNew'
                        })
                      )
                    }}
                  />
                  <img
                    src={arrowRightIcon}
                    onClick={() => {
                      const newDate = moment(date).add(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getMachineSchedule({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour'),
                          groupBy: 'performerNew'
                        })
                      )
                    }}
                  />
                </div>

              </div>
              <ul className='schedule-hours'>
                {hoursColumn.map((hour, index) => {
                  return (
                    <li
                      key={`hour-number-performers-${index}`}
                      className={
                  hour === moment().startOf('hour').format('HH:mm')
                    ? 'selected-hour'
                    : ''
                }
                    >
                      {hour}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='daily-orders-wrapper horizontal-scroll'>
              <div className='line-background-wrapper'>
                {Array(24)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-${index}`} className='line-background' />
                  })}
            </div>
              {Object.keys(requestData).map((key, index) => {
                return (
                  <div
                    key={`request-element-${index}`} className='daily-order-wrapper'
                    style={{ width: columnWidth}}>
                    <h3
                      className='order-main-title horizontal-scroll'
                      style={{ width: columnWidth + 15}}
                    >{key}
                    </h3>
                    <div className='line-background-wrapper'>
                      {Array(24)
                          .fill(0)
                          .map((el, index) => {
                            return <li key={`line-number-${index}`} className='line-background' />
                          })}
                    </div>
                    <div className='render-container'>
                      {checkSchedule(requestData[key])?.map((el, index) => {
                        if (el === 'empty') {
                          return null
                        } else if (typeof el === 'object') {
                          return (
                            <ScheduleDailyOrderColumn
                            key={`performers-daily-column-${index}-${el._id}`}
                              onClick={() => {
                                window.open(`/production/pipe/${el._id}`)
                                // history.push(`/production/pipe/${el._id}`)
                              }}
                              ordersColumnData={el}
                            />
                          )
                        } else {
                          return <div key={`empty-element-performers-daily-column-${index}-${el._id}`}
                          className={index % 2 === 0 ? 'no-border' : 'border'} />
                        }
                      })}
                    </div>
                  </div>
                )
              })}
              {Object.keys(requestData).length === 0 && <div className='daily-order-wrapper'>
                <h3
                  className='order-main-title horizontal-scroll'
                  style={{ width: '92.6%' }}
                />
                <div className='render-container' />
              </div>}
            </div>
            <div
              className='schedule-current-time'
              ref={currentTimeRef}
              style={{
                top: currentTime
              }}
            >
              <img src={timePointerIcon} />
            </div>
            <div className='night-shift-label' style={{ marginTop: 1000 }}>
              <img src={blueCircleIcon}/>
            </div>
          </>
        )
      case 'Краен продукт':
        return (
          <>
            <div className='schedule-daily-main'>
              <div className='arrows-wrapper'>
                <div className='arrows'>
                  <img
                    src={arrowLeftIcon}
                    onClick={() => {
                      const newDate = moment(date).subtract(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getMachineScheduleByPerformer({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour')
                        })
                      )
                    }}
                  />
                  <img
                    src={arrowRightIcon}
                    onClick={() => {
                      const newDate = moment(date).add(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getMachineScheduleByPerformer({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour')
                        })
                      )
                    }}
                  />
                </div>
              </div>
              <ul className='schedule-hours'>
                {hoursColumn.map((hour, index) => {
                  return (
                    <li
                      key={`hour-number-end-product-${index}`}
                      className={
                  hour === moment().startOf('hour').format('HH:mm')
                    ? 'selected-hour'
                    : ''
                }
                    >
                      {hour}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='daily-orders-wrapper horizontal-scroll'>
              <div className='line-background-wrapper'>
                {Array(24)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-end-product-el-${index}`} className='line-background' />
                  })}
              </div>
              {Object.keys(requestData).map((key, index) => {
                return (
                  <div
                    key={`request-element-${index}`} className='daily-order-wrapper'
                    style={{ width: columnWidth }}
                  >
                    <h3
                      className='order-main-title horizontal-scroll'
                      style={{ width: columnWidth + 15 }}
                    >{key}
                    </h3>
                    <div className='line-background-wrapper'>
                {Array(24)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-additional-${index}`} className='line-background' />
                  })}
              </div>
                    <div className='render-container'>
                      {checkSchedule(requestData[key])?.map((el, i) => {
                        if (el === 'empty') {
                          return null
                        } else if (typeof el === 'object') {
                          return (
                            <ScheduleDailyOrderColumn
                            key={`end-product-daily-column-${i}-${index}-${el._id}`}
                              onClick={() => {
                                const finishingWorks = []
                                Object.values(requestData).forEach(finishingWorksArray => {
                                  finishingWorksArray.forEach(finishingWork => {
                                    if (finishingWork.request) {
                                      const index = finishingWorks.findIndex(el => el._id === finishingWork._id)
                                      if (index === -1 && finishingWork.request._id === el.request._id) {
                                        finishingWorks.push(finishingWork)
                                      }
                                    }
                                  })
                                })
                                // window.open(`/production/end-product/${el.request._id}`)
                                // history.push(`/production/end-product/${el.request._id}/${el._id}`, { data: { ...el.request, finishingWorks: finishingWorks } })
                                history.push(`/production/end-product/${el.request._id}`, { data: { ...el.request, finishingWorks: finishingWorks } })
                              }}
                              ordersColumnData={el}
                            />
                          )
                        } else {
                          return <div key={`empty-element-end-product-daily-column-key-${i}-${index}`} />
                        }
                      })}
                    </div>
                  </div>
                )
              })}

              {Object.keys(requestData).length === 0 && <div className='daily-order-wrapper'>
                <h3
                  className='order-main-title horizontal-scroll'
                  style={{ width: '92.6%' }}
                />
                <div className='render-container' /></div>}
            </div>
            <div
              className='schedule-current-time'
              ref={currentTimeRef}
              style={{
                top: currentTime
              }}
            >
              <img src={timePointerIcon} />
            </div>
            <div className='night-shift-label' style={{ marginTop: 1000 }}>
              <img src={blueCircleIcon}/>
            </div>
          </>
        )
        break
      case 'Допълнителни':
        return (
          <>
            <div className='schedule-daily-main'>
              <div className='arrows-wrapper'>
                <div className='arrows'>
                  <img
                    src={arrowLeftIcon}
                    onClick={() => {
                      const newDate = moment(date).subtract(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getActionSchedule({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour')
                        })
                      )
                    }}
                  />
                  <img
                    src={arrowRightIcon}
                    onClick={() => {
                      const newDate = moment(date).add(1, 'day')
                      setDate(newDate)
                      onSetDate(newDate)
                      dispatch(
                        getActionSchedule({
                          start: moment(newDate).startOf('day').startOf('hour'),
                          end: moment(newDate).endOf('day').endOf('hour')
                        })
                      )
                    }}
                  />
                </div>
              </div>
              <ul className='schedule-hours'>
                {hoursColumn.map((hour, index) => {
                  return (
                    <li
                      key={`hour-number-additional-${index}`}
                      className={
                  hour === moment().startOf('hour').format('HH:mm')
                    ? 'selected-hour'
                    : ''
                }
                    >
                      {hour}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='daily-orders-wrapper horizontal-scroll'>
              <div className='line-background-wrapper'>
                {Array(24)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-additional-${index}`} className='line-background' />
                  })}
              </div>
              {activityPerformers.map((key, index) => {
                if (index === actionData.length) {
                  return
                }
                return (
                  <div
                    key={`request-element-${index}`} className='daily-order-wrapper'
                    style={{ width: columnWidth }}
                  >
                    <h3
                      className='order-main-title horizontal-scroll'
                      style={{ width: columnWidth + 15 }}
                    >{key}
                    </h3>
                    <div className='line-background-wrapper'>
                {Array(24)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-additional-${index}`} className='line-background' />
                  })}
              </div>
                    <div className='render-container'>
                      {checkSchedule(actionData)?.map((el, i) => {
                        if (el === 'empty') {
                          return null
                        } else if (typeof el === 'object') {
                          return (
                            el?.performers?.includes(key) &&
                            <ScheduleDailyOrderColumn
                            key={`additional-daily-column-el-${key}-${i}-${el._id}`}
                              onClick={() => {
                                let data = actionData.find(request => request._id === el._id)
                                // history.push({
                                //   pathname: `/production/view-edit/${el._id}`,
                                //   state: { data: data }
                                // })
                                window.open(`/production/view-edit/${el._id}`)
                              }}
                              ordersColumnData={el}
                            />
                          )
                        } else {
                          return <div key={`empty-element-additional-daily-column-${index}-${i}${el._id}`} />
                        }
                      })}
                    </div>
                  </div>
                )
              })}

              {actionData.length === 0 && <div className='daily-order-wrapper'>
                <h3
                  className='order-main-title horizontal-scroll'
                  style={{ width: '92.6%' }}
                />
                <div className='render-container' /> </div>}
            </div>
            <div
              className='schedule-current-time'
              ref={currentTimeRef}
              style={{
                top: currentTime
              }}
            >
              <img src={timePointerIcon} />
            </div>
            <div className='night-shift-label' style={{ marginTop: 1000 }}>
              <img src={blueCircleIcon}/>
            </div>
          </>
        )
        break
      default:
        break
    }
  }

  return (
    <div className='schedule-wrapper-daily'>
      {renderData()}
    </div>
  )
}
