import React, { useRef } from "react"
import { connect } from "react-redux"
import { Section } from "@makedonski/admin-ui"
import { showModal, deleteOffer, showLoading, deleteAct } from "../../actions"
import { API_URL } from "../../config/settings"
import moment from "moment"
import "./styles.scss"

const OfferElement = ({
  offer,
  index,
  showModal,
  inquiry,
  invoice,
  act,
  proform,
  deleteOffer,
  deleteAct,
  showLoading,
  includedProducts,
}) => {
  let invoicePopup = useRef()
  return (
    <div
      className={`offer-element-container ${invoice && "invoice borders"} ${
        proform && "proform"
      }`} 
    >
      <div className="top-container">
        <div className="flex-container">
          {invoice && <div className="border-line" />}
          <p>{`${
            invoice
              ? "Фактура"
              : act
              ? "ППП"
              : proform
              ? "Проформа-Фактура"
              : "Оферта"
          }-${index + 1}`}</p>

        <div className="buttons-container">
          {invoice ? (
            <Section.Popup
              ref={(ref) => {
                invoicePopup = ref
              }}
              style={{left: 0}}
              content={
                <div className="invoice-popup-container">
                  <div
                    className="close-popup-btn"
                    onClick={() => invoicePopup.hide()}
                  />
                  <p
                    onClick={() => {
                      showModal({
                        type: "invoiceCreation",
                        width: "90%",
                        data: {
                          _id: inquiry._id,
                          forEdit: true,
                          invoice: invoice,
                          includedProducts: includedProducts,
                        },
                      })
                      invoicePopup.hide()
                    }}
                  >
                    Редакция документ
                  </p>
                  <p
                    onClick={() => {
                      showModal({
                        type: "invoiceStatusChange",
                        width: "35%",
                        overflow: "visible",
                        data: {
                          inquiry: inquiry._id,
                          invoice: invoice,
                        },
                      })
                      invoicePopup.hide()
                    }}
                  >
                    Редакция статус
                  </p>
                </div>
              }
            >
              <div className="pen-icon" onClick={() => invoicePopup.show()} />
            </Section.Popup>
          ) : (
            <div
              className="pen-icon"
              onClick={() => {
                if (act) {
                  showModal({
                    type: "actOfAcceptanceModal",
                    width: "90%",
                    data: { _id: inquiry._id, forEdit: true, act: act },
                  })
                } else if (offer) {
                  showModal({
                    type: "offerCreation",
                    width: "90%",
                    data: {
                      _id: inquiry._id,
                      forEdit: true,
                      offer: offer,
                      includedProducts: includedProducts,
                    },
                  })
                } else if (proform) {
                  showModal({
                    type: "proformInvoiceModal",
                    width: "90%",
                    data: {
                      _id: inquiry._id,
                      forEdit: true,
                      proform: proform,
                      includedProducts: includedProducts,
                    },
                  })
                }
              }}
            />
          )}

          <div
            className="printer-icon"
            onClick={() => {
              if (invoice) {
                window.open(`${API_URL}/files/invoice/${invoice._id}`)
              } else if (act) {
                window.open(`${API_URL}/files/act-of-acceptance/${act._id}`)
              } else if (proform) {
                window.open(`${API_URL}/files/proformInvoice/${proform._id}`)
              } else {
                window.open(`${API_URL}/files/offer/${offer._id}`)
              }
            }}
          />

          <div
            className="printer-icon-en"
            onClick={() => {
              if (invoice) {
                window.open(`${API_URL}/files/invoice/${invoice._id}/en`)
              } else if (act) {
                window.open(`${API_URL}/files/act-of-acceptance/${act._id}/en`)
              } else if (proform) {
                window.open(`${API_URL}/files/proformInvoice/${proform._id}/en`)
              } else {
                window.open(`${API_URL}/files/offer/${offer._id}/en`)
              }
            }}
          />
          {invoice || proform ? (
            <div
              className="history-icon"
              onClick={() => {
                if (invoice) {
                  showModal({
                    type: "historyModal",
                    width: "500px",
                    data: {
                      title: "Движение на фактура",
                      history: invoice.history,
                    },
                  })
                } else {
                  showModal({
                    type: "historyModal",
                    width: "500px",
                    data: {
                      title: "Движение на проформа-фактура",
                      history: proform.history,
                    },
                  })
                }
              }}
            />
          ) : (
            <div
              className="delete-icon"
              onClick={() => {
                showLoading()
                if (act) {
                  deleteAct({ _id: act._id })
                } else if (proform) {
                  //delete proform
                } else {
                  deleteOffer({
                    _id: offer._id,
                  })
                }
              }}
            />
          )}
        </div>
        </div>
        {/* <div className="buttons-container">
          {invoice ? (
            <Section.Popup
              ref={(ref) => {
                invoicePopup = ref
              }}
              style={{left: 0}}
              content={
                <div className="invoice-popup-container">
                  <div
                    className="close-popup-btn"
                    onClick={() => invoicePopup.hide()}
                  />
                  <p
                    onClick={() => {
                      showModal({
                        type: "invoiceCreation",
                        width: "90%",
                        data: {
                          _id: inquiry._id,
                          forEdit: true,
                          invoice: invoice,
                          includedProducts: includedProducts,
                        },
                      })
                      invoicePopup.hide()
                    }}
                  >
                    Редакция документ
                  </p>
                  <p
                    onClick={() => {
                      showModal({
                        type: "invoiceStatusChange",
                        width: "35%",
                        overflow: "visible",
                        data: {
                          inquiry: inquiry._id,
                          invoice: invoice,
                        },
                      })
                      invoicePopup.hide()
                    }}
                  >
                    Редакция статус
                  </p>
                </div>
              }
            >
              <div className="pen-icon" onClick={() => invoicePopup.show()} />
            </Section.Popup>
          ) : (
            <div
              className="pen-icon"
              onClick={() => {
                if (act) {
                  showModal({
                    type: "actOfAcceptanceModal",
                    width: "90%",
                    data: { _id: inquiry._id, forEdit: true, act: act },
                  })
                } else if (offer) {
                  showModal({
                    type: "offerCreation",
                    width: "90%",
                    data: {
                      _id: inquiry._id,
                      forEdit: true,
                      offer: offer,
                      includedProducts: includedProducts,
                    },
                  })
                } else if (proform) {
                  showModal({
                    type: "proformInvoiceModal",
                    width: "90%",
                    data: {
                      _id: inquiry._id,
                      forEdit: true,
                      proform: proform,
                      includedProducts: includedProducts,
                    },
                  })
                }
              }}
            />
          )}

          <div
            className="printer-icon"
            onClick={() => {
              if (invoice) {
                window.open(`${API_URL}/files/invoice/${invoice._id}`)
              } else if (act) {
                window.open(`${API_URL}/files/act-of-acceptance/${act._id}`)
              } else if (proform) {
                window.open(`${API_URL}/files/proformInvoice/${proform._id}`)
              } else {
                window.open(`${API_URL}/files/offer/${offer._id}`)
              }
            }}
          />
          {invoice || proform ? (
            <div
              className="history-icon"
              onClick={() => {
                if (invoice) {
                  showModal({
                    type: "historyModal",
                    width: "500px",
                    data: {
                      title: "Движение на фактура",
                      history: invoice.history,
                    },
                  })
                } else {
                  showModal({
                    type: "historyModal",
                    width: "500px",
                    data: {
                      title: "Движение на проформа-фактура",
                      history: proform.history,
                    },
                  })
                }
              }}
            />
          ) : (
            <div
              className="delete-icon"
              onClick={() => {
                showLoading()
                if (act) {
                  deleteAct({ _id: act._id })
                } else if (proform) {
                  //delete proform
                } else {
                  deleteOffer({
                    _id: offer._id,
                  })
                }
              }}
            />
          )}
        </div> */}
      </div>
      <div className={`flex-container ${invoice ? 'invoice' : ''}`}>
        {invoice && (
          <>
            <p
              id={`${
                invoice.status === "paid"
                  ? "green"
                  : invoice.status === "not-paid"
                  ? "red"
                  : "yellow"
              }`}
            >
              {invoice.status === "paid"
                ? "ПЛАТЕНА"
                : invoice.status === "not-paid"
                ? "НЕПЛАТЕНА"
                : "ЧАСТИЧНО"}
            </p>
          </>
        )}
        <span>
          {invoice && " | "}
          {moment(
            invoice
              ? invoice.createdAt
              : act
              ? act.createdAt
              : proform
              ? proform.createdAt
              : offer.dueDate
          ).format("DD/MM/YYYY, HH:mm")}{" "}
          |{" "}
          {invoice
            ? invoice.client.name
            : act
            ? act.client.name
            : proform
            ? proform.client.name
            : offer.client.name}
        </span>
      </div>
      {invoice && (
        <div className="flex-container invoice">
          <p id="blue">Кредитно</p>
          <div
            className="filled-plus-icon"
            onClick={() => {
              showModal({
                type: "invoiceNotification",
                width: "95%",
                overflow: "visible",
                data: {
                  type: "Credit",
                  products: invoice.products,
                  invoiceID: invoice._id,
                },
              })
            }}
          />
          <p id="blue">Дебитно</p>
          <div
            className="filled-plus-icon"
            onClick={() => {
              showModal({
                type: "invoiceNotification",
                width: "95%",
                overflow: "visible",
                data: {
                  type: "Debit",
                  products: invoice.products,
                  invoiceID: invoice._id,
                },
              })
            }}
          />
        </div>
      )}
      {invoice && (
        <>
          {invoice.creditNotices.map((notice, index) => {
            return (
              <div className="invoice-notification-outer-container last">
                <div className="invoice-line" />
                <div className="invoice-notification-inner-container">
                  <div className="flex-container invoice">
                    <p>{`Кредитно-${index + 1}`}</p>
                    <div className="flex-container invoice">
                      <div
                        className="pen-icon"
                        onClick={() => {
                          showModal({
                            type: "invoiceNotification",
                            width: "95%",
                            overflow: "visible",
                            data: {
                              forEdit: true,
                              type: "Credit",
                              products: invoice.products,
                              invoiceID: invoice._id,
                              notice: notice,
                            },
                          })
                        }}
                      />
                      <div className="printer-icon" />
                      <div
                        className="history-icon"
                        onClick={() => {
                          showModal({
                            type: "historyModal",
                            width: "500px",
                            data: {
                              title: "Движение на документ",
                              history: notice.history,
                            },
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-container">
                    <p id="red">НЕПЛАТЕНА</p>
                    <span>
                      {" | "}
                      {moment(invoice.createdAt).format(
                        "MMMM Do YYYY, HH:mm"
                      )}{" "}
                      | {invoice.client.name}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
          {invoice.debitNotices.map((notice, index) => {
            return (
              <div className="invoice-notification-outer-container last">
                <div className="invoice-line" />
                <div className="invoice-notification-inner-container">
                  <div className="flex-container invoice">
                    <p>{`Дебитно-${index + 1}`}</p>
                    <div className="flex-container invoice">
                      <div
                        className="pen-icon"
                        onClick={() => {
                          showModal({
                            type: "invoiceNotification",
                            width: "95%",
                            overflow: "visible",
                            data: {
                              forEdit: true,
                              type: "Debit",
                              products: invoice.products,
                              invoiceID: invoice._id,
                              notice: notice,
                            },
                          })
                        }}
                      />
                      <div className="printer-icon" />
                      <div
                        className="history-icon"
                        onClick={() => {
                          showModal({
                            type: "historyModal",
                            width: "500px",
                            data: {
                              title: "Движение на документ",
                              history: notice.history,
                            },
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-container">
                    <p id="red">НЕПЛАТЕНА</p>
                    <span>
                      {" | "}
                      {moment(invoice.createdAt).format(
                        "MMMM Do YYYY, HH:mm"
                      )}{" "}
                      | {invoice.client.name}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  deleteOffer: (payload) => dispatch(deleteOffer(payload)),
  deleteAct: (payload) => dispatch(deleteAct(payload)),
  showLoading: () => dispatch(showLoading()),
})

export default connect(null, mapDispatchToProps)(OfferElement)
