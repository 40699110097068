import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { Alerts } from "@makedonski/socourt-utilities"
import { createRequest, hideModal } from "../../../actions"
import { Inputs } from "../../../components"

import "./styles.scss"

const AddStorageRawMaterialsRequest = ({ createRequest, hideModal, data }) => {
  const [payload, setPayload] = useState({
    material: "",
    quantity: "",
    units: "",
    deliveryDate: "",
    criticalQuantity: "",
  })
  return (
    <div className="add-storage-raw-materials-request-container">
      <div className="modal-heading">
        <h3>Нова заявка</h3>
        <div className="exit-btn">
          <Button.Icon
            name="plus"
            color="#152331"
            onClick={hideModal}
            size={24}
          />
        </div>
      </div>
      <div className="modal-content">
        <div className="item">
          <span>Суровина</span>
          <Input.Text
            value={payload.material}
            onChange={(e) =>
              setPayload({ ...payload, material: e.target.value })
            }
          />
        </div>
        <div className="details">
          <div>
            <span>Количество</span>
            <Inputs.UnitDropdown
              textValue={payload.quantity}
              onTextChange={(quantity) => setPayload({ ...payload, quantity })}
              unitsValue={payload.units}
              onUnitsChange={(units) => setPayload({ ...payload, units })}
            />
          </div>
          <div>
            <span>Нужна до</span>
            <Input.Datepicker
              pickDate={payload.deliveryDate}
              onChange={(deliveryDate) =>
                setPayload({ ...payload, deliveryDate })
              }
              minDate={new Date()}
            />
          </div>
          <div>
            <span>Критично количество</span>
            <Inputs.UnitDropdown
              textValue={payload.criticalQuantity}
              onTextChange={(criticalQuantity) =>
                setPayload({ ...payload, criticalQuantity })
              }
              unitsValue={payload.units}
              onUnitsChange={(units) => {}}
              unitsDisabled
            />
          </div>
        </div>
        <Button.Raised
          text="Създай заявка"
          className="btn-create"
          onClick={() => {
            if (
              !payload.material ||
              !payload.quantity ||
              !payload.units ||
              !payload.deliveryDate ||
              !payload.criticalQuantity
            ) {
              Alerts.error("Моля попълнете всички данни!")
            } else {
              if (data?.type === "machineStorage") {
                //create machine storage element logic
              } else {
                createRequest(payload)
              }
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  createRequest: (payload) => dispatch(createRequest(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStorageRawMaterialsRequest)
