import React from 'react'
import moment from 'moment'
import { history } from '../../config/stores'
import './styles.scss'
import { scheduled } from 'rxjs'

const ProductHistoryElement = ({ data, unit }) => {
  const duration = React.useMemo(() => {
    if (data?.scheduled) {
      const start = moment(data.scheduled.start).add(30, 'minutes')
      const end = moment(data.scheduled.end)
      const dur = moment.duration(end.diff(start))
      const hours = dur.hours()
      const min = dur.minutes()
      if (hours > 0) {
        if (min === 0) return `${hours}ч`
        return `${hours}ч ${min}мин`
      }
      return `${min}мин`
    }
  }, [data.scheduled])

  return (
    <div className="product-history-element">
      <div className="blue-dot" />
      <p id="date">{moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}</p>
      <div className="flex-container start">
        <p>Проект:</p>
        <p id="bold">{data.inquiry.object}</p>
      </div>
      <div className="flex-container start">
        <p>Количество:</p>
        <p id="bold">
          {data.quantity} {unit}
        </p>
      </div>
      <div className="flex-container start">
        <p>Времетраене:</p>
        <p id="bold">{duration}</p>
      </div>
      <div className="flex-container start">
        <p>Себестойност:</p>
        <p id="bold">{data?.scheduled?.expense ? data?.scheduled?.expense.toFixed(2) : 0} лв/бр.</p>
      </div>
      <div
        className="flex-container cursor"
        onClick={() => {
          // history.push(`/production/product/${data.scheduled._id}`)
          if (data?.scheduled?.machineSettings) {
            history.push(window.open(`/production/pipe/${data.scheduled._id}`))
          } 
          else {
            history.push(window.open(`/production/end-product/${data.scheduled.request}`))
          }
        }}
      >
        {data?.scheduled && 
        <>
         <p id="blue">Детайли за производство</p>
         <div className="arrow-right-blue" />
        </>}
      </div>
    </div>
  )
}

export default ProductHistoryElement
