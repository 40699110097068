import React from "react";
import moment from "moment";
import "./styles.scss";
import "../InquiriesSalesComponent/styles.scss";

const InquirySaleElement = ({ data }) => {

  return (
    <div className="inquiry-client-element invoice">
      <div className="element medium">{data.invoiceNumber}</div>
      <div className="element small">{moment(data.createdAt).format('DD.MM.YY')}</div>
      <div className="element medium overflow">{data.product}</div>
      <div className="element medium overflow">{data.client}</div>
      <div className="element medium">{data.createdBy}</div>
      <div className="element small">{data.units}</div>
      <div className="element medium">{data.quantity}</div>
      <div className="element medium">{data.sellPrice.toFixed(2)} лв.</div>
      <div className="element medium">{data.sellPriceVat.toFixed(2)} лв.</div>
      <div className="element medium">{data.sellPriceWithVat.toFixed(2)} лв.</div>
      <div className="element medium">{data.deliveryPrice.toFixed(2)} лв.</div>
      <div className="element medium">{data.deliveryPriceVat.toFixed(2)} лв.</div>
      <div className="element medium">{data.deliveryPriceWithVat.toFixed(2)} лв.</div>
      <div className="element small">{data.profitTotal.toFixed(2)} лв.</div>
      <div className="element large last">{data.sellPriceWithVat.toFixed(2)} лв.</div>
    </div>
  )
}

export default InquirySaleElement;
