import React, { useEffect, useState, useRef } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import { TableHeaderInquiry, InquiryCounterpartyElement } from "../../components"
import { showModal, getSuppliers, searchInquirySuppliers, resetSuppliers, filterInquiryDatesSuppliers, showLoading } from "../../actions"
import _ from "lodash";
import moment from "moment";
import "./styles.scss";
import "../InquiriesClientsComponent/InquiriesHeader.scss";
import InquiriesHeader from "../InquiriesClientsComponent/InquiriesHeader";
import { useSelector, useDispatch } from "react-redux";
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"


const headerElements = [
  {
    name: "Име на клиент",
    size: 2,
    filter: true,
    sort: false
  },
  {
    name: "Контрагент от",
    size: 1,
    filter: true,
    sort: false
  },
  {
    name: "Дължими суми",
    size: 1
  },
  {
    name: "ЕИК",
    size: 1,
    filter: true,
    sort: false
  },
  {
    name: "МОЛ",
    size: 2,
    filter: true,
    sort: false
  },
  {
    name: "Телефон",
    size: 1,
    filter: true,
    sort: false
  },
  {
    name: "Имейл",
    size: 2,
    filter: true,
    sort: false
  },
  {
    name: "Адрес",
    size: 3,
    filter: true,
    sort: false
  },
]

const InquiriesCounterpartiesComponent = ({ showModal, getSuppliers, supplierPage, suppliers, nextPage}) => {

  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState(true);
  const [selectedSortValue, setSelectedSortValue] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("firmName");
  const [searchDateValue, setSearchDateValue] = useState("createdAt");
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const [counterpartiesTab, setCounterpartiesTab] = useState('counterparties');
  const dispatch = useDispatch();
  const suppliersInnerRef = useRef();
  const suppliersObj = useSelector(state => state.references);

  // const filterOptions = [{label: 'Име на клиент', value: 'firmName'}, {label: 'Телефон', value: 'phone'},
  // {label: 'Имейл', value: 'email'}, {label: 'Адрес', value: "address"}, {label: 'ЕИК', value: 'uic'}];
  
  const filterOptions = {
    'firmName': 'Име на клиент',
    'phone': 'Телефон',
    'email': 'Имейл',
    'address': 'Адрес',
    'uic': 'ЕИК'
  }
 
  const dateFilterOptions = [{label: 'Дата на създаване', value: 'createdAt'}, {label: 'Последна редакция', value: 'updatedAt'}];

  const selectedSortValues = {
    'Име на клиент': 'firmName',
    'МОЛ': 'mol',
    'Контрагент от': 'createdAt',
    'ЕИК': 'uic',
    'Телефон': 'phone',
    'Имейл': 'email',
    'Адрес': 'address'
  }

  // useEffect(() => {
  //   getSuppliers({ pageNumber: 1 })
  // }, [getSuppliers]);

  useEffect(() => {
    showLoading()
    setCounterpartiesTab('counterparties');
    getSuppliers({ pageNumber: 1, pageSize: 20 })
    // return setClientsTab('')
  }, []);

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  const handleScrolling = () => {
    if (suppliersInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = suppliersInnerRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && nextPage && scrollTop !== 0) {
        loadPageOnScroll()
      }
    }
  }

  const loadPageOnScroll = () => {
      let payloadObj = {pageNumber: nextPage, pageSize: 20}

      if (searchQuery) {
        payloadObj[searchValue] = searchQuery
      }
      if (areDatesSelected) {
        payloadObj.dateField = 'createdAt'
        // payloadObj.dateField = searchDateValue
        payloadObj.from = moment(startDate).startOf("day")
        payloadObj.to = moment(endDate).endOf("day")
      }
      if (selectedSortValues[selectedSortValue]) {
        let sortType = selectedSortValues[selectedSortValue]
        payloadObj.sortBy = { [sortType]: sort ? 1 : -1 }
      }

      showLoading()
      dispatch(searchInquirySuppliers(payloadObj))
  }

  const handleSearch = () => {
    suppliersInnerRef.current && suppliersInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    let searchPayload = { [searchValue]: searchQuery, pageNumber: 1, pageSize: 20 }

    if (areDatesSelected) {
      searchPayload.dateField = 'createdAt'
      // searchPayload.dateField = searchDateValue
      searchPayload.from = moment(startDate).startOf("day")
      searchPayload.to = moment(endDate).endOf("day")
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue]
      searchPayload.sortBy = { [sortType]: sort ? 1 : -1 }
    }

    showLoading()
    dispatch(searchInquirySuppliers(searchPayload));
  }

  const handleDatesFilter = () => {
    if (moment(startDate).startOf("day").isAfter(moment(endDate).startOf("day"))) {
      return Alerts.error("Зададената начална дата на търсене е след крайната дата!")
    }

    suppliersInnerRef.current && suppliersInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    let searchPayload = { dateField: 'createdAt', from: moment(startDate).startOf("day"), to: moment(endDate).endOf("day"), pageNumber: 1, pageSize: 20 }
    setAreDatesSelected(true); 

    if (searchQuery) {
      searchPayload[searchValue] = searchQuery
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue]
      searchPayload.sortBy = { [sortType]: sort ? 1 : -1 }
    }

    showLoading()
    dispatch(filterInquiryDatesSuppliers(searchPayload))
  }

  const handleSort = (type, sortValue) => {
    let sortType = selectedSortValues[type];
    let payloadSort = { 
      sortBy: { [sortType]: sortValue ? 1 : -1 },
      pageNumber: 1,
      pageSize: 20
    };

    if (searchQuery) {
      payloadSort[searchValue] = searchQuery;
    }
    if (areDatesSelected) {
      payloadSort.from = moment(startDate).startOf("day");
      payloadSort.to = moment(endDate).endOf("day");
      payloadSort.dateField = 'createdAt';
    }

    suppliersInnerRef.current && suppliersInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    setSort(sortValue)
    setSelectedSortValue(type)
    showLoading()
    dispatch(getSuppliers(payloadSort))
  }
  
  return (
    <div className="inquiries-counterparties-component" key='000822246354756'>
      
      <InquiriesHeader 
        headerElements={headerElements}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={filterOptions}
        startDate={startDate}
        endDate={endDate}
        tab={counterpartiesTab}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        dateFilterOptions={dateFilterOptions}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        searchDateValue={searchDateValue}
        setSearchDateValue={setSearchDateValue}
        handleDatesFilter={handleDatesFilter}
        areDatesSelected={areDatesSelected}
        setAreDatesSelected={setAreDatesSelected}
        selectedSortValue={selectedSortValue}
        setSelectedSortValue={setSelectedSortValue}
        sort={sort}
        setSort={setSort} />

      <TableHeaderInquiry
        elements={headerElements}
        handleSort={handleSort}
        justifyContent="flex-start"
        selectedSortValue={selectedSortValue}
      />
      <div
        className="scroll-container"
        ref={suppliersInnerRef}
        onScroll={delayedScrolling}
      >
        {suppliers.map((supplier) => {
          return (
            <InquiryCounterpartyElement
              data={supplier}
              key={supplier._id}
              onEdit={() =>
                showModal({
                  type: "addClient",
                  overflow: "visible",
                  width: "60%",
                  data: {
                    type: "counterparty",
                    forEdit: true,
                    counterparty: supplier,
                  },
                })
              }
            />
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  supplierPage: state.references.supplierPage,
  suppliers: state.references.suppliers,
  nextPage: state.references.nextPage
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  getSuppliers: (payload) => dispatch(getSuppliers(payload)),
  showLoading: () => dispatch(showLoading()),
  resetSuppliers: (payload) => dispatch(resetSuppliers(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InquiriesCounterpartiesComponent)
