export const ActivityTypes = {
  GET_ACTIVITIES: 'activities/GET_ACTIVITIES',
  GET_ACTIVITIES_SUCCESS: 'activities/GET_ACTIVITIES_SUCCESS',
  NEW_ACTIVITY: 'activities/NEW_ACTIVITY',
}

export const getActivities = (payload) => ({
  type: ActivityTypes.GET_ACTIVITIES,
  payload,
})

export const getActivitiesSuccess = (payload) => ({
  type: ActivityTypes.GET_ACTIVITIES_SUCCESS,
  payload,
})
