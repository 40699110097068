import React, { useEffect, useState } from "react"
import moment from "moment";
import { TableHeaderInquiry, InquiryDefectivesElement } from "../../components"
import { getInquiryDefectives, showLoading, getMachines } from "../../actions"
import _ from "lodash"
import InquiriesHeader from "../InquiriesClientsComponent/InquiriesHeader";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts";
import './styles.scss';

const headerElements = [
  {
    name: "Дата",
    size: 1.3,
    fixedWidth: 120,
    filter: true,
    sort: false
  },
  {
    name: "Час",
    size: 0.7,
    fixedWidth: 100,
    filter: true,
    sort: false
  },
  {
    name: "Машина",
    size: 1.3,
    fixedWidth: 120
  },
  {
    name: "Тегло",
    size: 1.3,
    fixedWidth: 120,
    filter: true,
    sort: false
  },
  {
    name: "Размер",
    size: 7,
    fixedWidth: 450,
    filter: true,
    sort: false
  }
]

const InquiriesDefectivesComponent = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState('Дата');
  const [selectedMachine, setSelectedMachine] = useState('');
  const [searchValue, setSearchValue] = useState("machines");
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const [tab, setTab] = useState('defectives');
  const defectivesInnerRef = useRef();

  const inquiriesData = useSelector(state => state.inquiries.defectives);
  const pageNumber = useSelector(state => state.inquiries.pageNumber);
  const hasNextPage = useSelector(state => state.inquiries.hasNextPage);
  const machines = useSelector(state => state.production.machines);
//   const offersData = useSelector(state => state.inquiries.offersData);
  
  const filterOptions = {
    'machines': 'Всички машини',
    'OD800': 'OD800',
    'OD2000': 'OD2000',
    'OD2400': 'OD2400'
  }

  const selectedSortValues = {
    'Дата': 'createdAt',
    'Час': 'createdAt',
    'Машина': 'machine',
    'Тегло': 'weight',
    'Размер': 'length'
  }

  const dispatch = useDispatch();

  const handleScrolling = () => {
    if (defectivesInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = defectivesInnerRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && hasNextPage && scrollTop !== 0) {
        loadPageOnScroll()
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  const loadPageOnScroll = () => {
    let payloadObj = { pageNumber: pageNumber + 1 }

    if (areDatesSelected) {
      payloadObj.start = moment(startDate).startOf("day");
      payloadObj.end = moment(endDate).endOf("day");
    }
    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue];
      payloadObj.sortBy = { [sortType]: sort ? 1 : -1 };
    }
    if (searchValue !== 'machines') {
      handleFilters(payloadObj)
    }

    dispatch(showLoading())
    dispatch(getInquiryDefectives(payloadObj)) 
  }

  const handleDatesFilter = () => {
    if (moment(startDate).startOf("day").isAfter(moment(endDate).startOf("day"))) {
      return Alerts.error("Зададената начална дата на търсене е след крайната дата!")
    }

    defectivesInnerRef.current && defectivesInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    let searchPayload = { start: moment(startDate).startOf("day"), end: moment(endDate).endOf("day"), pageNumber: 1, pageSize: 20 }
    setAreDatesSelected(true);

    if (selectedSortValues[selectedSortValue]) {
      let sortType = selectedSortValues[selectedSortValue]
      searchPayload.sortBy = { [sortType]: sort ? 1 : -1 }
    }

    if (searchValue !== 'machines') {
      handleFilters(searchPayload)
    }

    dispatch(showLoading())
    dispatch(getInquiryDefectives(searchPayload))
  }

  const handleFilters = (payloadObj) => {
    if (machines?.length) {
      let machineId = machines.find(machine => machine?.name === searchValue)?._id
      if (machineId) {
        if (payloadObj) return payloadObj.machine = machineId
        else {
          let payloadObj = { pageNumber: 1, machine: machineId }

          if (areDatesSelected) {
            payloadObj.start = moment(startDate).startOf("day");
            payloadObj.end = moment(endDate).endOf("day");
          }
          if (selectedSortValues[selectedSortValue]) {
            let sortType = selectedSortValues[selectedSortValue];
            payloadObj.sortBy = { [sortType]: sort ? 1 : -1 };
          }

          defectivesInnerRef.current && defectivesInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
          dispatch(getInquiryDefectives(payloadObj))
        }
      }
    }
  }

  const handleSort = (type, sortValue) => {
    let sortType = selectedSortValues[type];
    let payloadSort = { 
      sortBy: { [sortType]: sortValue ? 1 : -1 },
      pageNumber: 1,
      pageSize: 20
    };

    if (areDatesSelected) {
      payloadSort.start = moment(startDate).startOf("day");
      payloadSort.end = moment(endDate).endOf("day");
    }

    if (searchValue !== 'machines') {
      handleFilters(payloadSort)
    }

    defectivesInnerRef.current && defectivesInnerRef.current.scrollTo({top: 0, behavior: 'smooth'})
    setSort(sortValue)
    setSelectedSortValue(type)
    dispatch(showLoading())
    dispatch(getInquiryDefectives(payloadSort))
  }

  useEffect(() => {
    dispatch(showLoading())
    dispatch(getMachines())
    dispatch(getInquiryDefectives({ pageNumber: 1 }))
  }, []);

  useEffect(() => {
    setData(inquiriesData)
  }, [inquiriesData]);

  return (
    <div className="inquiries-clients-component expense">
      <InquiriesHeader 
        headerElements={headerElements}
        filterOptions={filterOptions}
        startDate={startDate}
        endDate={endDate}
        tab={tab}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleDatesFilter={handleDatesFilter}
        areDatesSelected={areDatesSelected}
        setAreDatesSelected={setAreDatesSelected}
        selectedSortValue={selectedSortValue}
        setSelectedSortValue={setSelectedSortValue}
        handleFilters={handleFilters}
        inquiriesRef={defectivesInnerRef}
        sort={sort}
        setSort={setSort} />


      <TableHeaderInquiry
        elements={headerElements}
        justifyContent="flex-start"
        handleSort={handleSort}
        selectedSortValue={selectedSortValue}
      />
      <div
        className={`scroll-container ${data?.length ? '': 'empty-state'}`}
        ref={defectivesInnerRef} onScroll={delayedScrolling}
      >

        {data?.length ? data?.map((el, i) => {
          return <InquiryDefectivesElement key={el?._id} data={el} />
        }) : 
        <p>Няма налични справки за този период</p>}

      </div> 
    </div>
  )
}

export default InquiriesDefectivesComponent;

