import React from 'react';
import './stylesHome.scss';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import "moment/locale/bg"
import { capitalizeFirstLetter } from '../../utilities/helpers'
import { useSelector, useDispatch } from 'react-redux'
import { Tasks, CalendarSection } from '../../components';
import { showModal, hideModal } from '../../actions';
import { FilterPopup } from '../../components';
import { emit } from '../../utilities/helpers';
import { socket } from '../../actions';

export default function Home() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.tasks.users)
  const filters = useSelector((state) => state.tasks.filters)

    const [selectedTab, setSelectedTab] = useState('Дневен');
    const [selectedSchedule, setSelectedSchedule] = useState('Задачи');
    const [date, setDate] = useState(moment().startOf('day'));
    const [ isFilterActive, setIsFilterActive ] = useState(false)
    const [ performersList, setPerformersList ] = useState([]);
    const [ filtersPayload, setFiltersPayload ] = useState({
      startDate: null,
      endDate: null,
      priority: [],
      assignedTo: []
    })
    

    const renderWeekDate = (date) => {
        let weekStart = moment(date).startOf('week').format('DD')
        let weekEnd = moment(date).endOf('week').format('DD')
        
        if (moment(date).startOf('week').format('MMMM') !== 
        moment(date).endOf('week').format('MMMM')) {
          return weekStart + ' - ' + weekEnd + ' ' + 
          (capitalizeFirstLetter(moment(date).clone().add(1, 'month').format('MMMM')))
        }
        return weekStart + ' - ' + weekEnd + ' ' + 
               (capitalizeFirstLetter(moment(date).format('MMMM')))
      }

    const checkFilters = () => {
      if (filtersPayload?.startDate || filtersPayload?.endDate || filtersPayload?.priority?.length || filtersPayload?.assignedTo?.length || Object.values(filters || {}).length) {
        return true
      }
      else {
        return false
      }
    }

    useEffect(() => {
      emit(socket, { action: 'task/getUsers' })
      if (Object.values(filters || {}).length) {
        setFiltersPayload(filters)
      }
      else {
        setFiltersPayload({
          startDate: null,
          endDate: null,
          priority: '',
          assignedTo: []
        })
      }
    }, [])

    // useEffect(() => {
    //   if (users.length) {
    //     let newUsers = JSON.parse(JSON.stringify(users))
    //     newUsers = newUsers.map(user => user.fullName)
    //     setPerformersList(newUsers)
    //   }
    // }, [users])

  return (
    <div className='home-page-wrapper'>
      <div className='home-top-container'>
        <div className={`home-top-inner-wrapper ${selectedSchedule === 'Задачи' ? 'tasks' : ''}`}>
            <div className='tab-selection-wrapper'>
               <h3>{selectedSchedule}</h3>

               <div className='flex'>
                  {/* <div className={`tab-icon ${selectedSchedule === 'Задачи' ? 'selected' : ''}`}>
                    <img className='img' onClick={() => setSelectedSchedule('Задачи')} src={require('../../assets/icons/tasks.svg')} alt='tasks' />
                  </div> */}
                  {/* <div className={`tab-icon ${selectedSchedule === 'Календар' ? 'selected' : ''}`}>
                    <img className='img' onClick={() => setSelectedSchedule('Календар')} src={require('../../assets/icons/calendar-icon.svg')} alt='calendar' />
                  </div> */}
               </div>
            </div>

          {selectedSchedule === 'Календар' &&
          <>
            <div className='current-date'>
              {selectedTab === 'Дневен' && `${capitalizeFirstLetter(moment(date).format('DD MMMM'))} - ${capitalizeFirstLetter(moment(date).format('dddd'))}`}
              {selectedTab === 'Седмичен' && renderWeekDate(date)}
              {selectedTab === 'Месечен' && (capitalizeFirstLetter(moment(date).format('MMMM')))}
            </div>
            <div className='date-type-wrapper'>
              <ul className='date-type-wrapper-li'>
                <li onClick={() => setSelectedTab('Дневен')} className={selectedTab === 'Дневен' ? 'selected-tab' : ''}>Дневен</li>
                <li onClick={() => setSelectedTab('Седмичен')} className={selectedTab === 'Седмичен' ? 'selected-tab' : ''}>Седмичен</li>
                <li onClick={() => setSelectedTab('Месечен')} className={selectedTab === 'Месечен' ? 'selected-tab' : ''}>Месечен</li>
              </ul>
            </div>
  
            {/* <div className='icon-button-wrapper open'>
              <img src={require('../../assets/icons/filter.svg')}
                  onClick={() => {
                    // setIsFilterActive(!isFilterActive)
                    dispatch(showModal({
                      type: "filterModal",
                      hide: () => dispatch(hideModal()),
                      width: "30%",
                      data: performers
                    }))
                  }} 
                  // onClick={() => {
                  //   setIsFilterActive(!isFilterActive)
                  // }}
                  />
            </div> */}
          </>}

          {selectedSchedule === 'Задачи' &&
          <div className='icon-button-wrapper'>
              {checkFilters() ?
              <img src={require('../../assets/icons/filter-icon-active.svg')} onClick={() => setIsFilterActive(!isFilterActive)} /> :
              <img src={require('../../assets/icons/filter-icon.svg')} onClick={() => setIsFilterActive(!isFilterActive)} />}
            </div>}
        </div>

        {isFilterActive && <FilterPopup data={users} type="tasks" hide={() => setIsFilterActive(false)} setFilters={(filters) => emit(socket, { action: 'task/getTasks', payload: { filters: {...filters, showNotCompleted: true } } })} filtersPayload={filtersPayload} setFiltersPayload={(value) => setFiltersPayload(value)} />}
      </div>

      <div className='home-main-container'>
        {selectedSchedule === 'Задачи' && <Tasks />} 
        {selectedSchedule === 'Календар' && <CalendarSection type={selectedTab} date={date} setDate={(value) => setDate(value)} />} 
      </div>
    </div>
  )
}
