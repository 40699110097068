import React from "react"
import { NavLink, withRouter } from "react-router-dom"
import { Section, Button } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import { compose } from "redux"
import { Auth } from "@makedonski/socourt-utilities"
import { ProfileEdit, ProfileStatus } from ".."
import { showModal, getColleagues, setUserWorkingStatus } from "../../actions"
import { history } from "../../config/stores"
import moment from "moment"
import "./styles.scss"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      profileEdit: false,
      showWorkingStatus: false,
      isTabletNavOpen: false,
    }
    this.linkDetails = {}
  }

  componentDidMount() {
    this.props.getColleagues({ name: "" })
    setTimeout(() => {
      this.detectRoute(this.props.location)
    }, 100)
    this.props.history.listen((location, action) => {
      this.detectRoute(location)
    })
  }

  detectRoute(location) {
    const currentLoc = this.linkDetails[location.pathname]
    if (currentLoc) {
      const left = currentLoc.offsetLeft
      const { width } = currentLoc.getBoundingClientRect()
      this.selector.setAttribute("style", `left: ${left}px; width: ${width}px;`)
      // if (this.selector.offsetHeight === 0 && this?.selector?.classList) {
      //   setTimeout(() => {
      //     this.selector.classList.add("selector-add-height")
      //   }, 300)
      // }
    }
  }

  // getStatusColor() {
  //   switch (this.props.detector.status) {
  //     case "Working":
  //       return { backgroundColor: "#2ecc71" }
  //     case "Offline":
  //       return { backgroundColor: "#bdc3c7" }
  //     case "Away":
  //       return { backgroundColor: "#e74c3c" }
  //     default:
  //       return { backgroundColor: "#2ecc71" }
  //   }
  // }

  render() {
    const isMobile = window.innerWidth < 950
    const arrowBackIcon = require("../../assets/icons/arrow-left-back-blue.svg")

    return (
      <div className="main-header">
        {(this.props.location.pathname.includes("/machine-tablet-view") ||
          this.props.location.pathname.includes("/machine-activity-view") ||
          this.props.location.pathname.includes("/machine-requests-view")) && (
          <img
            className="tablet-menu"
            src={require("../../assets/icons/tablet-menu.svg")}
            onClick={() =>
              this.setState({ isTabletNavOpen: !this.state.isTabletNavOpen })
            }
          />
        )}

        {this.state.isTabletNavOpen && (
          <div className="tablet-opened-menu-wrapper">
            <div className="tablet-nav-wrapper">
              <div className="top-wrapper">
                <img
                  src={require("../../assets/icons/close-nav.svg")}
                  onClick={() =>
                    this.setState({
                      isTabletNavOpen: !this.state.isTabletNavOpen,
                    })
                  }
                />
              </div>

              <div className="nav-content-wrapper">
                <NavLink
                  to="/"
                  className="nav-element"
                  onClick={() =>
                    this.setState({
                      isTabletNavOpen: !this.state.isTabletNavOpen,
                    })
                  }
                >
                  Задачи
                </NavLink>
                <NavLink
                  to="/products"
                  className="nav-element"
                  onClick={() =>
                    this.setState({
                      isTabletNavOpen: !this.state.isTabletNavOpen,
                    })
                  }
                >
                  Продукти
                </NavLink>
                <NavLink
                  to="/projects"
                  className="nav-element"
                  onClick={() =>
                    this.setState({
                      isTabletNavOpen: !this.state.isTabletNavOpen,
                    })
                  }
                >
                  Проекти
                </NavLink>
                <NavLink
                  to="/storage"
                  className="nav-element"
                  onClick={() =>
                    this.setState({
                      isTabletNavOpen: !this.state.isTabletNavOpen,
                    })
                  }
                >
                  Склад
                </NavLink>
                <NavLink
                  to="/production"
                  className="nav-element"
                  onClick={() =>
                    this.setState({
                      isTabletNavOpen: !this.state.isTabletNavOpen,
                    })
                  }
                >
                  Производство
                </NavLink>
                <NavLink
                  to="/inquiries"
                  className="nav-element border"
                  onClick={() =>
                    this.setState({
                      isTabletNavOpen: !this.state.isTabletNavOpen,
                    })
                  }
                >
                  Справки
                </NavLink>
                <li
                  className="nav-element margin"
                  onClick={() => {
                    this.props.showModal({
                      type: "profileEdit",
                      data: this.props.currentUser,
                      width: "800px",
                    })
                    this.setState({ isTabletNavOpen: false })
                  }}
                >
                  Профил
                </li>
                <li
                  to="/products"
                  className="nav-element"
                  onClick={() => {
                    this.props.showModal({
                      type: "colleaguesModal",
                      data: this.props.colleagues,
                      width: "1300px",
                    })
                    this.setState({ isTabletNavOpen: false })
                  }}
                >
                  Колеги
                </li>
                <li
                  to="/products"
                  className="nav-element"
                  onClick={() => {
                    this.setState({ isTabletNavOpen: false })
                    history.push("/machine-settings")
                  }}
                >
                  Настройки производство
                </li>
                <li
                  to="/products"
                  className="nav-element"
                  onClick={() => {
                    this.setState({ isTabletNavOpen: false })
                    history.push("/support")
                  }}
                >
                  Помощен център
                </li>
                <li
                  to="/products"
                  className="nav-element"
                  onClick={() => {
                    this.setState({ isTabletNavOpen: false })
                    history.push("/machine-requests-view")
                  }}
                >
                  Работа по дейност
                </li>
                <li
                  to="/products"
                  className="nav-element"
                  onClick={() => {
                    Auth.signout().then(() => {
                      window.location.href = "/login"
                    })
                  }}
                >
                  Изход
                </li>
              </div>
            </div>

            <div className="background-wrapper" />
          </div>
        )}

        {this.props.location.pathname === "/production/newrequest" ||
        this.props.location.pathname.includes("/production/edit/") ||
        this.props.location.pathname.includes("/production/newrequest/") ||
        this.props.location.pathname.includes("/production/addrequest") ||
        this.props.location.pathname.includes("/production/view-edit") ||
        this.props.location.pathname.includes("/production/end-product") ||
        this.props.location.pathname.includes("/production/view/") ||
        this.props.location.pathname.includes("/production/pipe/") ||
        this.props.location.pathname.includes("/inquiries/edit/") ||
        this.props.location.pathname.includes("/inquiries/edit-offer/") ||
        this.props.location.pathname.includes("/task/") ||
        this.props.location.pathname.includes("/inquiries/add") ? (
          // <div className="main-header-go-back">
          //   <img src={arrowBackIcon} alt="<"></img>
          //   {this.props.location.pathname.includes('/production') && <div onClick={() => {this.props.history.push('/production')}}>Към график</div>}
          //   {this.props.location.pathname.includes('/inquiries/add') && <div onClick={() => {this.props.history.push('/inquiries')}}>Към Справки</div>}
          //   {this.props.location.pathname.includes('/inquiries/edit/') && <div onClick={() => {this.props.history.push('/inquiries')}}>Към Справки</div>}
          //   {this.props.location.pathname.includes('/inquiries/edit-offer/') && <div onClick={() => {this.props.history.push('/inquiries')}}>Към Справки</div>}
          //   {this.props.location.pathname.includes('/task/') && <div onClick={() => {this.props.history.push('/')}}>Към Задачи</div>}
          // </div>

          <div
            className="main-header-go-back"
            onClick={() => {
              if (this.props.location.pathname.includes("/production")) {
                this.props.history.go(-1)
                localStorage.setItem("tab", "Производство - изпълнители")
              } else if (
                this.props.location.pathname.includes("/inquiries/add") ||
                this.props.location.pathname.includes("/inquiries/edit/") ||
                this.props.location.pathname.includes("/inquiries/edit-offer/")
              ) {
                this.props.history.push("/inquiries")
              } else if (this.props.location.pathname.includes("/task/")) {
                // this.props.history.push('/')
                window.close()
              }
            }}
          >
            <img src={arrowBackIcon} alt="<"></img>
            {this.props.location.pathname.includes("/production") && (
              <div>Към График</div>
            )}
            {this.props.location.pathname.includes("/inquiries/add") && (
              <div>Към Справки</div>
            )}
            {this.props.location.pathname.includes("/inquiries/edit/") && (
              <div>Към Справки</div>
            )}
            {this.props.location.pathname.includes(
              "/inquiries/edit-offer/"
            ) && <div>Към Справки</div>}
            {this.props.location.pathname.includes("/task/") && (
              <div>Към Задачи</div>
            )}
          </div>
        ) : (
          <div
            className="main-header-logo"
            onClick={() => {
              if (isMobile) this.props.history.push("/contacts")
              else this.props.history.push("/")
            }}
          >
            <img alt="Logo" src={require("../../assets/images/logo.png")} />
          </div>
        )}

        {!this.props.location.pathname.includes("/machine-tablet-view") &&
          !this.props.location.pathname.includes("/machine-activity-view") &&
          !this.props.location.pathname.includes("/machine-requests-view") && (
            <div className="main-header-content">
              {!isMobile && (
                <div className="main-header-elements">
                  <NavLink
                    ref={(ref) => {
                      this.linkDetails["/"] = ref
                    }}
                    style={{ fontWeight: this.props.location.pathname === "/" ? "bold" : "200" }}
                    to="/"
                    className=""
                  >
                    Начало
                  </NavLink>
                  <NavLink
                    ref={(ref) => {
                      this.linkDetails["/products"] = ref
                    }}
                    to="/products"
                    className=""
                  >
                    Продукти
                  </NavLink>
                  <NavLink
                    ref={(ref) => {
                      this.linkDetails["/projects"] = ref
                    }}
                    to="/projects"
                    className=""
                  >
                    Проекти
                  </NavLink>
                  <NavLink
                    ref={(ref) => {
                      this.linkDetails["/storage"] = ref
                    }}
                    to="/storage"
                    className=""
                  >
                    Склад
                  </NavLink>
                  <NavLink
                    ref={(ref) => {
                      this.linkDetails[this.props.productionQuery] = ref
                    }}
                    to={`/production/Тръби/Дневен/${moment().format(
                      "DD"
                    )}-${moment().format("DD")}-${moment().format(
                      "MM"
                    )}-${moment().format("YYYY")}`}
                    className=""
                  >
                    Производство
                  </NavLink>
                  <NavLink
                    ref={(ref) => {
                      this.linkDetails["/inquiries"] = ref
                    }}
                    to="/inquiries"
                    className=""
                  >
                    Справки
                  </NavLink>
                  <div
                    ref={(ref) => {
                      this.selector = ref
                    }}
                    className="active-route-selector"
                  />
                </div>
              )}
            </div>
          )}
        {!this.props.location.pathname.includes("/machine-tablet-view") &&
          !this.props.location.pathname.includes("/machine-activity-view") &&
          !this.props.location.pathname.includes("/machine-requests-view") && (
            <>
              {!isMobile ? (
                <div className="main-header-right">
                  <div
                    className="icon icon-support-chat"
                    onClick={() => history.push("/support")}
                  >
                    {this.props.socket?.state?.tickets?.reduce(
                      (acc, { _unreadCustomer }) => acc + _unreadCustomer,
                      0
                    ) > 0 && <div className="support-status" />}
                  </div>
                  <Section.Popup
                    ref={(ref) => {
                      this.showProfileStatus = ref
                    }}
                    className="profile-status"
                  >
                    <div className="img">
                      <img
                        alt="profile"
                        src={
                          this.props.currentUser.coverPhoto ||
                          global.config.defaultAvatar
                        }
                      />
                    </div>
                  </Section.Popup>

                  <div
                    className="main-header-menu"
                    onClick={() => {
                      this.setState({ showMenu: !this.state.showMenu })
                    }}
                  />
                  {this.state.showMenu ? (
                    <div className="settings-menu">
                      <div
                        className="flex items-center w-full"
                        style={{ gap: "10px" }}
                        onClick={() => {
                          this.props.showModal({
                            type: "profileEdit",
                            data: this.props.currentUser,
                            width: "800px",
                          })
                          this.setState({ showMenu: false })
                        }}
                      >
                        <img
                          src={require("../../assets/icons/profile-icon.svg")}
                          className="settings-dropdown-icon"
                        />
                        <span>Профил</span>
                      </div>

                      <div
                        className="flex items-center w-full"
                        style={{ gap: "10px" }}
                        onClick={() => {
                          this.props.showModal({
                            type: "colleaguesModal",
                            data: this.props.colleagues,
                            width: "1300px",
                          })
                          this.setState({ showMenu: false })
                        }}
                      >
                        <img
                          src={require("../../assets/icons/colleagues-icon.svg")}
                          className="settings-dropdown-icon"
                        />
                        <span>Колеги</span>
                      </div>
                      <div
                        className="flex items-center w-full"
                        style={{ gap: "10px" }}
                        onClick={() => {
                          history.push("/machine-settings")
                          this.setState({ showMenu: false })
                        }}
                      >
                        <img
                          src={require("../../assets/icons/settings-icon.svg")}
                          className="settings-dropdown-icon"
                        />
                        <span>Настройки на производство</span>
                      </div>
                      <div
                        className="flex items-center w-full"
                        style={{ gap: "10px" }}
                        onClick={() => {
                          history.push("/machine-requests-view")
                          this.setState({ showMenu: false })
                        }}
                      >
                        <img
                          src={require("../../assets/icons/briefcase-icon.svg")}
                          className="settings-dropdown-icon"
                        />
                        <span>Работа по дейност</span>
                      </div>
                      <div
                        className="flex items-center w-full"
                        style={{ gap: "10px" }}
                        onClick={() => {
                          history.push("/contacts")
                          this.setState({ showMenu: false })
                        }}
                      >
                        <img
                          src={require("../../assets/icons/contacts-icon.svg")}
                          className="settings-dropdown-icon"
                        />
                        <span>Контакти</span>
                      </div>

                      <div
                        className="flex items-center w-full cursor-pointer"
                        style={{ gap: "10px" }}
                        onClick={() => {
                          Auth.signout().then(() => {
                            window.location.href = "/login"
                          })
                        }}
                      >
                        <img
                          src={require("../../assets/icons/logOut-icon.svg")}
                          className="settings-dropdown-icon"
                        />
                        <span>Изход</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.profileEdit ? (
                    <div className="profile-edit-container">
                      <ProfileEdit
                        closeModal={() => {
                          this.setState({
                            profileEdit: !this.state.profileEdit,
                          })
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div
                  className="main-header-right"
                  style={{ marginRight: "30px" }}
                >
                  <img
                    src={require("../../assets/icons/logOut-icon.svg")}
                    style={{ width: "30px", height: "30px", cursor: "pointer" }}
                    onClick={() => {
                      Auth.signout().then(() => {
                        window.location.href = "/login"
                      })
                    }}
                  />
                </div>
              )}
            </>
          )}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  colleagues: state.user.data,
  currentUser: state.user.currentUser,
  // detector: state.StatusDetection.currentUser,
  // hasUnseen: state.chat.unseenMessages,
  productionQuery: state.production.productionQuery,
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  getColleagues: (payload) => dispatch(getColleagues(payload)),
  setUserWorkingStatus: (payload) => dispatch(setUserWorkingStatus(payload)),
  // toggleChat: () => dispatch(Chat.actions.toggleChat()),
})

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
