import React, { useState, useEffect, useRef } from 'react'
import { Input, Button, Section } from '@makedonski/admin-ui'
import { useParams, Link, useLocation } from 'react-router-dom'
import './styles.scss'
import { CommentsActivityDashboard } from '../../components'
import { Alerts } from '@makedonski/socourt-utilities/lib/Alerts'
import { CheckListPopup } from '../../components/CheckListPopup'
import SchedulePopup from '../../components/SchedulePopup/SchedulePopup'
import { useSelector, useDispatch } from 'react-redux'
import {
  putProductionRequest,
  getSingleSchedule,
  searchStorageProducts,
  searchRequests,
  getPerformers,
  addPerformer,
  getMachines,
  getMachineSettings,
  finishRequest,
  getCurrentUser,
  showLoading,
  hideLoading,
  addCommentMachine,
  deleteScheduleRequest,
  saveMachineActivity,
  getMachineActivity
} from '../../actions'
import { getProductName, validateObjectValues } from '../../utilities/helpers'
import { ProductionHistoryTab } from '../../components'
import moment from 'moment'
import machineSettings from '../../reducers/machineSettings'
import { pick } from 'lodash'
import { ConstructionOutlined, TodayTwoTone } from '@mui/icons-material'
import { check } from 'prettier'
import { history } from '../../config/stores'

export default function PipeScheduleInsertion () {
  const { id } = useParams()
  const { state } = useLocation()
  const dispatch = useDispatch()
  let executorsTab = useRef(null)
  let scheduleTab = useRef(null)

  const plusIcon = require('../../assets/icons/light-plus-icon.svg')
  const xIcon = require('../../assets/icons/remove-btn.svg')
  const activityIcon = require('../../assets/icons/dashboard-apps.svg')
  const penIcon = require('../../assets/icons/pen-icon.svg')
  const calendarIcon = require('../../assets/icons/calendar-icon.svg')

  const user = useSelector(state => state.user.currentUser)
  const storageProductResults = useSelector((state) => state.storage.storageProductResults)
  const results = useSelector((state) => state.storage.search)
  const performers = useSelector((state) => state.production.performers)
  const data = useSelector(state => state.production.currentSchedule)
  const searchedSettings = useSelector(state => state.machineSettings.machineSettings)
  const commentsDashboard = useSelector(state => state.production.currentSchedule?.comments)
  const machines = useSelector((state) => state.production.machines)
  const savedActivity = useSelector((state) => state.production.savedMachineActivity)
  const pendingData = state?.data
  // let status = (data?.work[0]?.end && 'finished') || data?.request?.status || pendingData?.status
  let status = data?.request?.status || pendingData?.status

  const profileArray = Array.from(Array(10).keys()).slice(1)
  const [isDateValid, setIsDateValid] = useState(true)
  const [isTimeValid, setIsTimeValid] = useState(true)
  const [selectedBtn, setSelectedBtn] = useState('')
  const [focusedElement, setFocusedElement] = useState(null)
  const [firstLoad, setFirstLoad] = useState(true)
  const [isEditActive, setIsEditActive] = useState(false)
  const [isDashboardActive, setIsDashboardActive] = useState(false)
  const [isHistoryTabActive, setIsHistoryTabActive] = useState(false)
  const [isMaterialChanged, setIsMaterialChanged] = useState(false)
  const [isEditComplete, setIsEditComplete] = useState(false)
  const [dashboardSection, setDashboardSection] = useState('comments')
  const [newPerformers, setNewPerformers] = useState([])

  const [payload, setPayload] = useState({
    _id: data?.request._id || pendingData?.id || id,
    product: '',
    inquiry: '',
    expense: 0,
    quantity: 0,
    // quantity: status === 'pending' ? pendingData?.quantity : data?.quantity,
    deadline: '',
    machine: '',
    start: null,
    end: '',
    duration: '',
    mixture: '',
    comment: '',
    performers: [],
    machineSettings: {
      machine: '',
      filledBy: '',
      isSearchable: false,
      materials: [{ name: '', ratio: '' }],
      geometry: {
        innerDiameter: '',
        caliber: '',
        f1f2: '',
        stiffnes: '',
        vacuum: '',
        weight: ''
      },
      extruderSJ90: {
        motor: {
          current: ''
        },
        profiles: ['', '', '', '', '', '', '', '', '']
      },
      extruderSJ65: {
        motor: {
          current: '',
          revolutions: ''
        },
        profiles: ['', '', '', '', '', '', '', '', '']
      },
      windingSystem: '',
      injector: '',
      buzzSaw: {
        min: '',
        move: ''
      },
      name: '',
      note: ''
    },
    activity: '',
    finishingWorks: []
  })

  const [materialsQueryArray, setMaterialsQueryArray] = useState([''])
  const [materials, setMaterials] = useState([{ name: '', ratio: '' }])
  const [ tableData, setTableData ] = useState([]);

  const totalProducts = [...results, ...storageProductResults].map((el) => {
    return el.material
      ? Object.assign(el, { name: el.material })
      : Object.assign(el, { name: el.type })
  })

  let tableHeaderElements = [
    {name: 'Брой', size: 'medium', sort: false}, 
    {name: 'Тегло', size: 'small', sort: false}, 
    {name: 'Дължина', size: 'small', sort: false}, 
    {name: 'Дата', size: 'medium', sort: false}, 
    {name: 'Час', size: 'small', sort: false},
];

  const durationDropdown = [
    { label: '30 мин', value: '30' },
    { label: '1 час', value: '60' },
    { label: '1ч. 30м.', value: '90' },
    { label: '2 часа', value: '120' },
    { label: '2ч. 30м.', value: '150' },
    { label: '3 часа', value: '180' },
    { label: '3ч. 30м.', value: '210' },
    { label: '4 часа', value: '240' },
    { label: '4ч. 30м.', value: '270' },
    { label: '5 часа', value: '300' },
    { label: '5ч. 30м.', value: '330' },
    { label: '6 часа', value: '360' },
    { label: '6ч. 30м.', value: '390' },
    { label: '7 часа', value: '420' },
    { label: '7ч. 30м.', value: '450' },
    { label: '8 часа', value: '480' }
  ]
  let disabledInput = (status === 'in-production' || status === 'finished') && !isEditActive

  let singleUnitPrice = 0
  if (payload.machineSettings?.materials) {
    payload.machineSettings.materials.forEach((mat) => {
      const material = totalProducts.find((product) => product.name === mat.name)
      const matPrice = material?.pricing?.singleUnitPrice || material?.price
      let multiplier = 0
      if (status === 'in-production') {
        multiplier = (parseInt(mat.ratio) / 100) * data?.quantity
      } else if (status === 'pending') {
        multiplier = (parseInt(mat.ratio) / 100) * pendingData?.quantity
      }
      const price = matPrice * multiplier
      if (price) {
        singleUnitPrice = singleUnitPrice + price
      }
    })
  }

  const checkMaterialStorage = (currentMaterial) => {
    if (currentMaterial) {
      const currentProduct = totalProducts.find((product) => {
        return product.name === currentMaterial
      })
      return (
        <span>
          &nbsp;{currentProduct?.quantity}&nbsp;{currentProduct?.units}
        </span>
      )
    }
  }

  const checkMaterialPrice = (material) => {
    if (material) {
      const currentProduct = totalProducts.find((product) => {
        return product.name === material
      })

      const singleUnitPrice = currentProduct?.pricing?.singleUnitPrice || currentProduct?.price

      return (
        <span>
          &nbsp;{singleUnitPrice && singleUnitPrice?.toFixed(2)} лв./{currentProduct?.units}
        </span>
      )
    }
  }

  const checkInputs = () => {
    if ((payload.quantity && payload.start && payload.end &&
        payload.duration && payload.machine && payload.activity && payload.performers) ||
        status === 'in-production') {
      return true
    }
    return false
  }

  const handleCheckList = (list) => {
    const newPayload = { ...payload }
    newPayload.performers = list
    setPayload(newPayload)
  }

  useEffect(() => {
    dispatch(showLoading())
    dispatch(getSingleSchedule({ _id: id }))
    dispatch(getPerformers())
    dispatch(getMachines())
    dispatch(searchStorageProducts({ search: '' }))
    dispatch(searchRequests({ search: '' }))
    dispatch(getMachineSettings())
    dispatch(getCurrentUser())
    dispatch(getMachineActivity({ request: id, pageNumber: 1, pageSize: 20 }))
  }, [])

  useEffect(() => {
    status = data?.request?.status || pendingData?.status
    disabledInput = (status === 'in-production' || status === 'finished') && !isEditActive

    if ((status === 'in-production' || status === 'finished') && !isEditComplete) {
      const newPayload = JSON.parse(JSON.stringify(payload))
      const newData = JSON.parse(JSON.stringify(data))
      // const newPayload = { ...payload }
      newPayload.activity = newData?.activity
      newPayload.comment = newData?.comments.map(el => el.text).join('')
      newPayload.machine = newData?.machine?._id
      newPayload.deadline = newData?.request?.deadline
      newPayload.duration = moment(newData.end).diff(moment(newData.start), 'minutes')
      newPayload.start = moment(newData?.start).toDate()
      newPayload.inquiry = newData?.request?.inquiry?.id
      newPayload.end = moment(newData?.end).toDate()
      newPayload.product = newData?.product._id
      newPayload.expense = singleUnitPrice
      newPayload.machineSettings = newData?.machineSettings
      // newPayload.machineSettings.note = newData?.machineSettings.note
      delete newPayload?.machineSettings?.__v
      // delete newPayload?.machineSettings?.note

      // newPayload.machineSettings.materials = newData.machineSettings.materials
      // newPayload.machineSettings.buzzSaw = newData.machineSettings.buzzSaw
      // newPayload.machineSettings.extruderSJ65 = newData.machineSettings.extruderSJ65
      // newPayload.machineSettings.extruderSJ90 = newData.machineSettings.extruderSJ90
      // newPayload.machineSettings.filledBy = newData.machineSettings.filledBy
      // newPayload.machineSettings.geometry = newData.machineSettings.geometry
      // newPayload.machineSettings.injector = newData.machineSettings.injector
      // newPayload.machineSettings.machine = newData.machineSettings.machine
      // newPayload.machineSettings.isSearchable = newData.machineSettings.isSearchable
      // newPayload.machineSettings.name = newData.machineSettings.name
      // newPayload.machineSettings.windingSystem = newData.machineSettings.windingSystem
      // newPayload.machineSettings.note = newData.machineSettings.note
      newPayload.performers = newData?.performers
      newPayload.quantity = newData?.quantity
      setSelectedBtn(data.machine.name)
      setPayload(newPayload)
      const newMaterialsQueryArray = []
      newPayload.machineSettings && newPayload.machineSettings.materials.map(materialData =>
        newMaterialsQueryArray.push(materialData.name)
      )
      setMaterialsQueryArray(newMaterialsQueryArray)
      setMaterials(newPayload?.machineSettings?.materials)

      if (data?.work?.length) setTableData(data?.work[0]?.produce?.reverse())

    } else if (status === 'pending') {
      const newPayload = { ...payload }
      const materialsData = pendingData?.product?.expenses?.material
      const newMaterialsArray = Array(materialsData?.length || 1).fill({ ratio: '', name: '' })
      if (newPayload.machineSettings) {
        newPayload.machineSettings.materials = newMaterialsArray
      }

      const newMaterialsQueryArray = Array(materialsData?.length || 1).fill('')
      setMaterialsQueryArray(newMaterialsQueryArray)

      setMaterials(pendingData?.product?.expenses?.material.map(el => {
        return { name: el.kind, ratio: el.ratio }
      }) || [])

      newPayload.machineSettings.materials = pendingData?.product?.expenses?.material.map(el => {
        return { name: el.kind, ratio: el.ratio }
      }) || []

      setPayload(newPayload)

      setPayload({
        ...payload,
        _id: id,
        inquiry: pendingData?.inquiry.id,
        deadline: pendingData?.deadline,
        product: pendingData?.product._id,
        expense: singleUnitPrice,
        quantity: pendingData?.quantity
      })
    }

    (pendingData || data) && dispatch(hideLoading())
  }, [pendingData, isEditActive, data])

  // console.log(pendingData)
  // console.log(data)

  return (
    <div className='production-schedule-insertion-wrapper'>
      <div className='schedule-insertion-container'>
        <div className='schedule-insertion-top'>
          <div className='headings-wrapper'>
            {disabledInput
              ? <div className='heading-icon-wrapper'>
                <h2 className='overflow'>{data?.request?.inquiry?.object || data?.inquiry?.object}</h2>
                <div className='img-wrapper'>
                  {status !== 'finished' &&
                   <img
                    src={penIcon} onClick={() => {
                      setIsEditActive(true)
                      setIsEditComplete(false)
                    }}
                  />}
                  <img
                    src={activityIcon} onClick={() => {
                      setIsDashboardActive(!isDashboardActive)
                      setIsEditActive(false)
                    }}
                  />
                </div>
              </div>
              : 
              <div className='heading-icon-wrapper'>
               <h2>Към производство</h2>

               {isEditActive && 
                  <img className='icon-pointer' src={require('../../assets/icons/bin.svg')}
                  onClick={() => {
                   Alerts.confirm({
                     title: "Сигурни ли сте, че искате да изтриете дейността?",
                     confirm: "Да",
                     cancel: "Не",
                   },
                   (answer) => {
                     if (answer) {
                       dispatch(deleteScheduleRequest({ _id: id }))
                       history.push('/production')
                     }
                   })
                 }} />}
              </div>}

            <div className='subtitle-wrapper'>
              {status === 'pending'
                ? <>
                  <h3>{getProductName(pendingData?.product.basicInfo)}</h3>
                  <h3 className='subtitle'>{moment(pendingData?.deadline).format('DD.MM.YYYY')}</h3>
                  {pendingData?.inquiry?.checklist?.length > 0 &&               
                   <div className='file-wrapper subtitle'>
                    <div className='file-name' onClick={() => window.open(pendingData?.inquiry?.checklist[pendingData?.inquiry?.checklist?.length - 1]?.url)}>
                     Чеклист от клиент
                    </div>
                    <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
                  </div>}
                  {(pendingData?.drawing && Object.values(pendingData?.drawing)) &&               
                   <div className='file-wrapper subtitle'>
                    <div className='file-name' onClick={() => window.open(pendingData?.drawing?.url)}>
                     Чертеж
                    </div>
                    <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
                  </div>}
                  {pendingData?.requestedFrom?.fullName &&
                  <h3> | &nbsp; {pendingData?.requestedFrom?.fullName}</h3>}
                </>
                : <>
                  <h3>{getProductName(data?.product.basicInfo)}</h3>
                  <h3 className='subtitle'>{moment(data?.end).format('DD.MM.YYYY')}</h3>
                  {data?.request?.inquiry?.checklist?.length > 0 && 
                  data?.request?.inquiry?.checklist[data?.request?.inquiry?.checklist?.length - 1]?.action === 'received' &&              
                   <div className='file-wrapper subtitle'>
                    <div className='file-name' onClick={() => window.open(data?.request?.inquiry?.checklist[data?.request?.inquiry?.checklist?.length - 1]?.url)}>
                     Чеклист от клиент
                    </div>
                    <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
                  </div>}
                  {(data?.request?.drawing && Object.values(data?.request?.drawing)) &&              
                   <div className='file-wrapper subtitle'>
                    <div className='file-name' onClick={() => window.open(data?.request?.drawing?.url)}>
                     Чертеж
                    </div>
                    <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
                  </div>}
                  {data?.requestedFrom?.fullName &&
                  <h3> | &nbsp; {data?.requestedFrom?.fullName}</h3>}
                </>}

            </div>
          </div>

          {status !== 'finished'
            ? <div className='buttons-wrapper'>
              {disabledInput
                ? <>
                  <button
                    className='blue'
                    onClick={() => {
                      dispatch(showLoading())
                      dispatch(finishRequest({ _id: data?.request._id }))
                      history.goBack()
                    }}
                  >Завърши дейност
                  </button>
                  {moment(data.end).diff(moment(data.start), 'hours') > 24 &&
                    <button className='white'>Приключи за деня</button>}

                  </>
                : <>
                  <button onClick={() => {
                    payload.machineSettings?.note === '' && setIsMaterialChanged(false)
                    status === 'pending' && history.goBack()
                    status === 'in-production' && setIsEditActive(false) && setIsEditComplete(false)
                  }}
                  >Откажи
                  </button>

                  <button
                    // className={checkInputs() && isDateValid && isTimeValid ? 'btn' : 'disabled-btn'}
                    className='btn'
                    onClick={() => {
                      let totalRatio = 0
                      payload.machineSettings.materials.forEach(material => {
                        totalRatio += parseInt(material.ratio)
                      })
                      if (totalRatio < 99 || totalRatio > 100) {
                        return Alerts.error('Невалидно съотношение на материалите!')
                      }
                      const payloadForCheck = JSON.parse(JSON.stringify(payload))
                      // const payloadForCheck = { ...payload }
                      delete payloadForCheck.machineSettings.isSearchable
                      delete payloadForCheck.finishingWorks
                      delete payloadForCheck.mixture
                      delete payloadForCheck.machineSettings.filledBy
                      delete payloadForCheck.machineSettings.machine
                      delete payloadForCheck.machineSettings.geometry
                      delete payloadForCheck.machineSettings.extruderSJ90
                      delete payloadForCheck.machineSettings.extruderSJ65
                      delete payloadForCheck.machineSettings.windingSystem
                      delete payloadForCheck.machineSettings.injector
                      delete payloadForCheck.machineSettings.buzzSaw
                      delete payloadForCheck.machineSettings.name
                      delete payloadForCheck.machineSettings.note
                      delete payloadForCheck.machineSettings.changeMaterialReason
                      delete payloadForCheck.machineSettings.note
                      delete payloadForCheck.comment
                      payloadForCheck.machineSettings.materials = payloadForCheck.machineSettings.materials.map(el => {
                        return { name: el.name, ratio: el.ratio }
                      })
                      const errors = validateObjectValues({ ...payloadForCheck, expense: singleUnitPrice })
                      if (errors.error) {
                        Alerts.error(`${errors.errorsText} не са въведени!`)
                      } else {
                      // searchedSettings[selectedBtn].push(payload.machineSettings);
                      // let updatedSettings = searchedSettings[selectedBtn].slice(0);
                      // updatedSettings.map((option, index) => {
                      //   if (option.name === payload.machineSettings.name) {
                      //     searchedSettings[selectedBtn].slice(index, 1, payload.machineSettings)
                      //   }
                      // })

                        // searchedSettings[selectedBtn].push(payload.machineSettings)

                        payload.machineSettings.isSearchable &&
                      !searchedSettings[selectedBtn].map(option => option.name === payload.machineSettings.name) &&
                      searchedSettings[selectedBtn].push(payload.machineSettings)

                        const newPayload = { ...payload }
                        newPayload.machineSettings.materials = newPayload.machineSettings.materials.map(el => {
                          return { name: el.name, ratio: el.ratio }
                        })
                        newPayload._id = data?.request._id || id
                        newPayload.machineSettings.machine = payload.machine

                        const filtered = searchedSettings[selectedBtn].filter(option =>
                          option.name === newPayload.machineSettings.name)

                        if (newPayload.machineSettings.isSearchable && filtered.length > 0) {
                        // delete newPayload.machineSettings.name
                          newPayload.machineSettings.isSearchable = false
                        }

                        if (newPayload.machineSettings.name === selectedBtn) {
                          newPayload.machineSettings.name = newPayload._id
                        }

                        const activityPayload = {
                          // request: newPayload._id,
                          request: id,
                          title: status === 'pending' ? 'activity' : 'промени дейност',
                          metadata: newPayload
                        }

                        setPayload(newPayload)
                        // dispatch(editMachineSettings({...payload.machineSettings}));
                        setIsEditComplete(true)
                        setIsEditActive(false)
                        dispatch(saveMachineActivity(activityPayload))
                        dispatch(putProductionRequest({ ...newPayload, expense: singleUnitPrice }))
                        dispatch(showLoading())
                      }
                    }}
                  >
                    {isEditActive ? 'Запази промени' : 'Добави в графика'}
                  </button>
                  </>}
              </div>
            : <div className='finished-date-wrapper'>
              <p>Завършена на: <span>{moment(data?.request.updatedAt).format('DD.MM.YYYY')}</span></p>
              </div>}
        </div>

        {isDashboardActive &&
          <CommentsActivityDashboard
          id={id}
          activityHistory={true}
          // tableData={data?.work?.length > 0 ? data?.work[0]?.produce : []}
          tableData={savedActivity?.length > 0 ? savedActivity : []}
          quantity={data?.quantity}
          comments={commentsDashboard}
          dashboardSection={dashboardSection}
          setDashboardSection={(value) => setDashboardSection(value)}
          onClose={() => setIsDashboardActive(false)}
          onSend={(comment, machineScheduleWork) => {
            dispatch(addCommentMachine({ commentType: 'text', text: comment, machineScheduleWork: machineScheduleWork, machineSchedule: data._id }))
            dispatch(getSingleSchedule({ _id: id }))
          }
        }
           />}

        {isHistoryTabActive &&
          <ProductionHistoryTab
          tableHeaderElements={tableHeaderElements}
          // tableData={data?.work?.length > 0 ? data?.work[0]?.produce : []}
          tableData={tableData}
          data={data}
          onClose={() => setIsHistoryTabActive(false)}
          />}

        <div className='schedule-insertion-main-info'>
          <h2>Основна информация</h2>

          <div className='main-info-inputs-wrapper'>
            <div className='quantity-section-wrapper'>
              <label htmlFor='quantity-input-pipe'>К-во</label>

              <div className='input-wrapper'>
                <Input.Text
                  id='quantity-input'
                  value={payload?.quantity}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  disabled={disabledInput}
                  onChange={(e) => {
                    setPayload({ ...payload, quantity: e.currentTarget.value })
                  }}
                />

                <p>{data?.product?.mainUnit || pendingData?.product?.mainUnit}</p>
              </div>
            </div>

            <div className='dates-section-wrapper'>
              <label
                htmlFor='datepicker-pipe'
                className={isDateValid ? 'label' : 'red'}
              >
                Ден
              </label>
              {disabledInput
                ? <Input.Text
                    value={payload?.start ? moment(payload?.start).format('DD.MM.YYYY') || '' : moment(data?.start).format('DD.MM.YYYY') || ''}
                    className={disabledInput && 'disabled-input' || 'date-input'}
                    disabled={disabledInput}
                  />

                : <Input.Datepicker
                    id='datepicker-pipe'
                    placeholder=''
                    minDate={status === 'pending' ? moment().toDate() : ''}
                    pickDate={payload?.start}
                    onChange={(pickDate) => {
                      moment(pickDate).isAfter(data?.request?.deadline || pendingData?.deadline) ? setIsDateValid(false) : setIsDateValid(true)

                      if (status === 'pending') {
                        setPayload({ ...payload, start: moment(pickDate).toDate() })
                      }
                      else if (status === 'in-production') {
                        setPayload({ ...payload, start: moment(pickDate).toDate(), isDelayed: true })
                      }
  
                    }}
                  />}
            </div>

            <div className='hour-section-wrapper'>
              <label htmlFor='hour-pipe' className={isTimeValid ? 'label' : 'red'}>Час</label>
              <div className='input-hour-icon-wrapper'>
                {disabledInput
                  ? <Input.Text
                      className='disabled-input'
                      value={moment(payload?.start).format('HH:mm') || null}
                    />

                  : <Input.TimePicker
                      timeIntervals={30}
                      id='hour-pipe'
                      placeholder=''
                      minDate={status === 'pending' ? moment().startOf('minute') : ''}
                      pickDate={status === 'pending' ? (moment().startOf('minute').isBefore(payload?.start) && payload?.start) : payload?.start}
                      disabled={!payload?.start}
                      className={payload?.start ? 'disabled-input' : 'input'}
                      style={{ fontWeight: 'bold' }}
                      onChange={(e) => {
                        setFirstLoad(false)
                        const newDate = moment(payload?.start).set('hour', moment(e).hour()).set('minute', moment(e).minute())
                        newDate.isAfter(payload?.deadline || payload?.request?.deadline) ? setIsDateValid(false) : setIsDateValid(true)

                        if (status === 'pending') {
                          setPayload({ ...payload, start: newDate.toDate(), end: moment(newDate).add(payload?.duration, 'minutes').toDate() })
                        }
                        else if (status === 'in-production') {
                          setPayload({ ...payload, start: newDate.toDate(), end: moment(newDate).add(payload?.duration, 'minutes').toDate(), isDelayed: true })
                        }
              
                      }}
                    />}

                {(status === 'pending' || isEditActive) &&
                  <>
                    <Section.Popup
                      ref={(ref) => {
                        scheduleTab = ref
                      }}
                      content={
                        <SchedulePopup
                          startDate={payload?.start}
                          endDate={payload?.end}
                          machine={selectedBtn}
                          payload={payload}
                          setIsTimeValid={setIsTimeValid}
                          onClose={() => scheduleTab.hide()}
                        />
}
                      className='daily-machine-schedule'
                    >

                      <img src={calendarIcon} className='calendar' onClick={() => scheduleTab.show()} />

                    </Section.Popup>
                  </>}
              </div>
            </div>

            <div className='duration-section-wrapper'>
              <label htmlFor='duration'>Времетраене</label>
              {disabledInput
                ? <Input.Text
                    className='disabled-input'
                    disabled
                    placeholder=''
                    value={`${Math.floor(payload.duration / 60)}ч ${payload.duration % 60}мин` || ''}
                  />
                : <Input.Dropdown
                    id='duration'
                    style={{ fontWeight: 'bold' }}
                    options={durationDropdown}
                    value={`${Math.floor(payload.duration / 60)}ч ${payload.duration % 60}мин` || ''}
                    placeholder=''
                    disabled={!payload?.start}
                    className={payload?.start ? 'disabled-input' : 'input'}
                    onChange={(e) => {
                      const newDate = moment(payload?.start).add(e.value, 'minutes')
                      if (status === 'pending') {
                        setPayload({
                          ...payload,
                          duration: e.value,
                          end: newDate.toDate()
                        })
                      }
                      else if (status === 'in-production') {
                        setPayload({
                          ...payload,
                          duration: e.value,
                          end: newDate.toDate(),
                          isDelayed: true
                        })
                      }
                      newDate.isAfter(payload?.deadline || payload?.request?.deadline) ? setIsTimeValid(false) : setIsTimeValid(true)
                    }}
                  />}
            </div>

            {disabledInput
              ? <div className='planned-deadline-section-wrapper'>
                <label>Планиран край</label>
                <div className='deadline-date-wrapper'>
                  <p>{moment(payload?.end).format('DD.MM.YYYY | HH:mm')}</p>
                </div>
                </div>
              : <div className='activity-section-wrapper'>
                <label htmlFor='activity'>Дейност</label>
                <Input.Text
                  id='activity'
                  value={payload.activity}
                  style={{ fontWeight: 'bold' }}
                  onChange={(e) => {
                    setPayload({ ...payload, activity: e.currentTarget.value })
                  }}
                />
                </div>}

            {disabledInput
              ? <div className='contractor-section-wrapper-in-production'>
                <label htmlFor='contractor'>Изпълнители</label>
                <div className='contractor-wrapper'>
                  {payload?.performers?.map(p => {
                    return <p>{p}</p>
                  })}
                </div>
                </div>
              : <div className='contractor-section-wrapper'>
                <label htmlFor='contractor'>Изпълнители</label>
                <div className='contractors-main'>
                  {payload.performers &&
                    <div className='selected-performers-wrapper'>

                      <Section.Popup
                        ref={(ref) => {
                          executorsTab = ref
                        }}
                        className='checklist-popup'
                        content={
                          <CheckListPopup
                              data={performers}
                              startDate={payload?.start}
                              endDate={payload?.end}
                              handleCheckList={handleCheckList}
                              payloadPerformers={payload.performers}
                              onClose={() => executorsTab.hide()}
                            />
                    }
                      >
                        <img
                          className='plus-icon'
                          src={plusIcon}
                          alt='+'
                          onClick={() => executorsTab.show()}
                        />
                      </Section.Popup>
                      {payload.performers.map(performer => {
                        return (
                          <>
                              <img
                                src={xIcon} onClick={() => {
                                const newPayload = { ...payload }
                                newPayload.performers = newPayload.performers.filter(el => el !== performer)
                                setPayload(newPayload)
                              }}
                              />
                              <p>{performer}</p>
                            </>
                        )
                      })}
                    </div>}
                </div>
                </div>}
          </div>

          {!isDateValid &&
            <p className='warning-msg'>Моля обърнете внимание, че датата, която сте посочили е след зададения срок за доставка.</p>}
          {!isTimeValid &&
            <p className='warning-msg'>{`Зададеният час и времетраене не съответсват с графика за деня на машина ${selectedBtn}. Моля сменете часа.`}</p>}
        </div>

        {(status === 'in-production' || status === 'finished') &&
        <div className='schedule-insertion-main-info'>
          <h2>Реални срокове</h2>

          <div className='main-info-inputs-wrapper'>
            <div className='quantity-section-wrapper'>
              <label>К-во</label>

              <div className='input-wrapper'>
                {data?.work?.length > 0 ?
                <Input.Text
                  value={data?.work[0]?.produce?.length > 0 ? data?.work[0]?.produce?.length : ''}
                  placeholder='0'
                  className='disabled-input'
                  disabled
                /> :
                <Input.Text
                value='0'
                className='disabled-input'
                disabled
              />
                }
                {/* <p>{data?.product?.mainUnit || pendingData?.product?.mainUnit}</p> */}
                <p>бр</p>
              </div>
            </div>
            <div className='dates-section-wrapper'>
              <label>Ден</label>
              {data?.work?.length > 0 ?
              <Input.Text
              value={data?.work[0]?.start ? moment(data?.work[0]?.start).format("DD.MM.YYYY") : ''}
              placeholder='— —'
              disabled
              className='disabled-input'
               /> :
               <Input.Text
               value='— —'
               disabled
               className='disabled-input'
                />}
            </div>
            <div className='hour-section-wrapper'>
              <label>Час</label>
              <div className='input-hour-icon-wrapper'>
                  {data?.work?.length > 0 ?
                  <Input.Text
                      className='disabled-input'
                      placeholder='— —'
                      value={data?.work[0]?.start ? moment(data?.work[0]?.start).format('HH:mm') : ''}
                    /> :
                    <Input.Text
                    className='disabled-input'
                    value='— —'
                  />}
              </div>
            </div>
            <div className='duration-section-wrapper'>
              <label>Времетраене</label>
              {data?.work?.length > 0 ? 
              <Input.Text
                    className='disabled-input'
                    disabled
                    placeholder='00ч 00мин'
                    value={` ${(moment(data?.work[0]?.end)).diff(moment(data?.work[0]?.start), 'hours')}ч ${moment(data?.work[0]?.end).diff(moment(data?.work[0]?.start), 'minutes') % 60} мин`}
                    // value={`${Math.floor(payload.duration / 60)}ч ${payload.duration % 60}мин` || ''}
                  /> :
                  <Input.Text
                  className='disabled-input'
                  disabled
                  value='00ч 00мин'
                />}
            </div>
            
            <div className='icon-section-wrapper'>
              <div className='img-wrapper'>
                <img src={require('../../assets/icons/history-icon.svg')} onClick={() => setIsHistoryTabActive(!isHistoryTabActive)} />
              </div>
            </div>
          </div>
        </div>}

        <div className='schedule-insertion-settings'>
          <h2>Настройки на машината</h2>

          <div className='settings-inputs-wrapper'>
            <div className='settings-top-section-wrapper'>
              {disabledInput
                ? <>
                  <div className='buttons-wrapper'>
                    <button className='selected'>{data?.machine.name}</button>
                  </div>
                </>
                : <>
                  <div className='buttons-wrapper'>
                    {machines?.sort()?.map((machineData, i) => {
                      return (
                        <>
                          <button
                            className={(machineData.name === selectedBtn) ? 'selected' : 'btn'}
                            onClick={(e) => {
                              setSelectedBtn(machineData.name)
                              setPayload({
                                ...payload,
                                machine: machineData.id,
                                machineSettings: { ...payload.machineSettings, name: machineData.name }
                              })
                            }}
                          >
                            {machineData.name}
                          </button>
                        </>
                      )
                    })}
                  </div>

                  <div className='saved-settings-input-wrapper'>
                    <label htmlFor='settings-saved'>Запазни настройки</label>
                    <Input.Dropdown
                      id='settings-saved'
                      style={{ fontWeight: 'bold' }}
                      disabled={!selectedBtn}
                      options={searchedSettings[selectedBtn]?.map(el => el.name || '') || []}
                      placeholder=''
                      value={!payload.machineSettings?.isSearchable ? selectedBtn : payload.machineSettings?.name}
                      onChange={(e) => {
                        const newPayload = { ...payload }
                        const arr = searchedSettings[selectedBtn].slice(0)
                        const option = arr.filter(el => el.name === e.value)[0]

                        delete option.__v
                        delete option?.extruderSJ65?.material
                        delete option?.extruderSJ90?.material
                        newPayload.machineSettings = option
                        newPayload._id = data?.request?._id || id
                        setPayload(newPayload)
                        const newMaterialsQueryArray = []
                        newPayload.machineSettings.materials.map(materialData =>
                          newMaterialsQueryArray.push(materialData.name)
                        )
                        setMaterialsQueryArray(newMaterialsQueryArray)
                        setMaterials(newPayload.machineSettings.materials)
                      }}
                    />
                  </div>
                </>}
            </div>
          </div>

          <div className='materials-section-wrapper'>
            <div className='materials-top-wrapper'>
              <h3>Материали</h3>
              {(status === 'pending' || isEditActive) && <Input.Dropdown
                options={profileArray}
                // value={pendingData?.product?.expenses?.material.length.toString()}
                value={payload.machineSettings?.materials?.length.toString()}
                style={{ fontWeight: 'bold' }}
                onChange={(e) => {
                  const newPayload = { ...payload }
                  const newMaterials = []
                  Array(e.value).fill('').forEach((el, i) => {
                    if (newPayload.machineSettings.materials[i]) {
                      newMaterials.push(newPayload.machineSettings.materials[i])
                    } else {
                      newMaterials.push({ name: '', ratio: '' })
                    }
                  })
                  newPayload.machineSettings.materials = newMaterials
                  setMaterialsQueryArray(Array(e.value).fill(''))
                  setPayload(newPayload)
                  setMaterials(newPayload.machineSettings.materials)
                  setIsMaterialChanged(true)

                  const totalRatio = newPayload.machineSettings.materials.reduce((acc, curr) => acc.ratio + curr.ratio)
                  // newPayload.machineSettings.materials.map(el => el.ratio = 100 / payload.machineSettings.materials.length)
                }}
                                                         />}
            </div>

            <div className='material-details-container'>
              {payload?.machineSettings?.materials.map((el, i) => {
                return (
                  <div className='single-material-wrapper'>
                    <div className='material-details-top'>
                      <div className='material-input-wrapper'>
                        <div className='label-wrapper'>
                          <label>Материал {i + 1}</label>
                          <img
                            src={xIcon} onClick={() => {
                              setIsMaterialChanged(true)
                              const newPayload = { ...payload }
                              newPayload.machineSettings.materials = newPayload.machineSettings.materials.filter(el => el.name !== materials[i].name)
                              // newPayload.machineSettings.materials.map(el => el.ratio = 100 / newPayload.machineSettings.materials.length)

                              setPayload(newPayload)
                            }}
                          />
                        </div>
                        <Input.Text
                          onChange={(e) => {
                            const newQueryArray = materialsQueryArray.slice(0)
                            newQueryArray[i] = e.target.value
                            setMaterialsQueryArray(newQueryArray)
                            setMaterials(payload.machineSettings.materials)
                          }}
                          onFocus={() => { setFocusedElement(i) }}
                          onBlur={() => {
                            setTimeout(() => {
                              setFocusedElement(null)
                            }, 300)
                          }}
                          disabled={!(((isEditActive) || status === 'pending'))}
                          className={(isEditActive || status === 'pending') ? 'input' : 'disabled-input'}
                          value={materialsQueryArray[i] || ''}
                          // value={payload.machineSettings.materials[i].name || (materialsQueryArray[i] || '')}
                        />

                        {i === focusedElement && (
                          <div className='suggestions-container'>
                            {totalProducts
                              ?.filter((product) => {
                                return product?.name
                                  ?.toLowerCase()
                                  ?.includes(
                                    materialsQueryArray[i].toLowerCase()
                                  )
                              })
                              ?.filter(el => {
                                const materialsArray = materials.slice(0).map(material => material.name)
                                return !materialsArray.includes(el.name) && el.name
                              })
                              ?.map((product, index) => {
                                return (
                                  <div
                                    className='suggestion-wrapper'
                                    onClick={() => {
                                      const newPayload = { ...payload }
                                      const newMaterialValue = { ...newPayload.machineSettings.materials[i] }

                                      newMaterialValue.name = product.name
                                      newPayload.machineSettings.materials[i] = newMaterialValue
                                      setPayload(newPayload)
                                      const newQueryArray = materialsQueryArray.slice(0)
                                      newQueryArray[i] = product.name
                                      setMaterialsQueryArray(newQueryArray)
                                      setFocusedElement(null)
                                    }}
                                  >
                                    {product.name}
                                  </div>
                                )
                              })}
                          </div>
                        )}
                      </div>

                      <div className='percentage-input-wrapper'>
                        <label>Съотношение</label>
                        <div className='percentage-input'>
                          {disabledInput
                            ? <Input.Text
                                disabled={!isEditActive}
                                className={!isEditActive ? 'disabled-input' : 'input'}
                                placeholder={data?.machineSettings?.materials[i]?.ratio || ''}
                              />
                            : <Input.Text
                                value={payload.machineSettings.materials[i].ratio || ''}
                                onChange={(e) => {
                                  const newPayload = { ...payload }
                                  const newMaterialValue = { ...newPayload.machineSettings.materials[i] }
                                  newMaterialValue.ratio = e.target.value

                                  // let filtered = newPayload.machineSettings.materials.filter(el => el.name !== newMaterialValue.name)
                                  // const newSum = 100 - newMaterialValue.ratio
                                  // filtered.map(el => el.ratio = newSum / (newPayload.machineSettings.materials.length - 1))
                                  // filtered = [...filtered, newMaterialValue]
                                  newPayload.machineSettings.materials[i].ratio = e.target.value
                                  setPayload(newPayload)
                                  setMaterials(newPayload.machineSettings.materials)
                                }}
                              />}
                          <p>%</p>
                        </div>
                      </div>
                    </div>

                    <div className='material-details-bottom'>
                      <div className='storage-info'>
                        На склад:

                        {/* {payload.machineSettings.name === '' ?
                        <span>{payload.machineSettings.materials[i].quantity}</span> :
                         checkMaterialStorage(materialsQueryArray[i]) ||
                         (status === 'in-production' && <span>{data.quantity} {data.product.mainUnit}</span>)
                         } */}

                        {/* {status === 'in-production' ? <span>&nbsp;{data.quantity} {data.product.mainUnit}</span> :
                         checkMaterialStorage(materialsQueryArray[i])
                         } */}

                        {checkMaterialStorage(materialsQueryArray[i] || el.name)}

                      </div>
                      <div className='price-info'>
                        Ед. Цена:
                        {checkMaterialPrice(el.name)}

                        {/* {payload.machineSettings.name === '' ?
                        <span>{payload.machineSettings.materials[i].total}</span> : checkMaterialPrice(el.name) ||
                        (status === 'in-production' && <span>{data.expense}</span>)} */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            {(isMaterialChanged || payload?.machineSettings?.note) &&
              <div className='changed-materials-section-wrapper'>
                <h3>Смяна на материали</h3>

                <div className='changed-materials-label-input-wrapper'>
                  <label>Причина за смяна на материали</label>
                  <Input.Text
                    style={{ fontWeight: 'bold' }}
                    value={payload?.machineSettings?.note || ''}
                    disabled={disabledInput}
                    className={disabledInput ? 'disabled-input' : 'input'}
                    onChange={(e) => {
                      searchedSettings[selectedBtn] && searchedSettings[selectedBtn].map(el => el?.name === e.target.value || '')
                      setPayload({
                        ...payload,
                        machineSettings:
                    { ...payload.machineSettings, note: e.target.value }
                      })
                    }}
                  />
                </div>
              </div>}
          </div>

          <div className='size-solidity-weight-wrapper'>
            <h3>Геометрични размери, тегло и коравина</h3>

            <div className='size-solidity-weight-wrapper-inputs-wrapper'>
              <div className='size-solidity-weight-wrapper-input-wrapper'>
                <label>Вътрешен диаметър мм</label>

                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  value={payload.machineSettings?.geometry?.innerDiameter || ''}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      machineSettings: {
                        ...payload.machineSettings,
                        geometry: { ...payload.machineSettings?.geometry, innerDiameter: e.currentTarget.value }
                      }
                    })
                  }}
                />
              </div>

              <div className='caliber-input-wrapper'>
                <label>Калибър мм</label>
                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  value={payload.machineSettings?.geometry?.caliber}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      machineSettings: {
                        ...payload.machineSettings,
                        geometry: { ...payload.machineSettings.geometry, caliber: e.currentTarget.value }
                      }
                    })
                  }}
                />

              </div>

              <div className='label-input-wrapper'>
                <label>Тегло кг/м</label>
                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  value={payload.machineSettings?.geometry?.weight}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      machineSettings: {
                        ...payload.machineSettings,
                        geometry: { ...payload.machineSettings.geometry, weight: e.currentTarget.value }
                      }
                    })
                  }}
                />
              </div>

              {/* <div className='f-input-wrapper'>
                <label>ф 1 | ф2</label>
                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  value={payload.machineSettings?.geometry?.f1f2}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      machineSettings: {
                        ...payload.machineSettings,
                        geometry: { ...payload.machineSettings.geometry, f1f2: e.currentTarget.value }
                      }
                    })
                  }}
                />

              </div> */}

              <div className='solidity-input-wrapper'>
                <label>Коравина SN</label>

                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  value={payload.machineSettings?.geometry?.stiffnes}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      machineSettings: {
                        ...payload.machineSettings,
                        geometry: { ...payload.machineSettings.geometry, stiffnes: e.currentTarget.value }
                      }
                    })
                  }}
                />

              </div>

              {/* <div className='weight-input-wrapper'>
                <label>Тегло кг/м</label>

                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  value={payload.machineSettings?.geometry?.weight}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      machineSettings: {
                        ...payload.machineSettings,
                        geometry: { ...payload.machineSettings.geometry, weight: e.currentTarget.value }
                      }
                    })
                  }}
                />

              </div> */}
            </div>
          </div>

          <div className='extruder-section-wrapper'>
            <div className='extruder-top-wrapper'>
              <h3>Екструдер за профил SJ90, t</h3>
              {(status === 'pending' || isEditActive) &&
                <Input.Dropdown
                  options={profileArray}
                  value={(payload.machineSettings?.extruderSJ90?.profiles.length || 9).toString()}
                  style={{ fontWeight: 'bold' }}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    const newProfiles = []
                    Array(e.value).fill('').forEach((el, i) => {
                      if (newPayload.machineSettings.extruderSJ90.profiles[i]) {
                        newProfiles.push(newPayload.machineSettings.extruderSJ90.profiles[i])
                      } else {
                        newProfiles.push('')
                      }
                    })
                    newPayload.machineSettings.extruderSJ90.profiles = newProfiles
                    setPayload(newPayload)
                  }}
                />}
            </div>

            <div className='extruder-details-container'>
              {payload.machineSettings?.extruderSJ90?.profiles.map((el, i) => {
                return (
                  <div className='single-profile-wrapper'>
                    <div className='profile-top-wrapper'>
                      <label>{i + 1}</label>
                      <Input.Text
                        value={payload.machineSettings?.extruderSJ90?.profiles[i]}
                        disabled={disabledInput}
                        className={disabledInput ? 'disabled-input' : 'input'}
                        onChange={(e) => {
                          const newPayload = { ...payload }
                          newPayload.machineSettings.extruderSJ90.profiles[i] = e.currentTarget.value
                          setPayload(newPayload)
                        }}
                      />
                    </div>
                  </div>
                )
              })}

            </div>

            <div className='engine-info-wrapper'>
              <div className='label-input-wrapper'>
                <label>Двигател ток А</label>

                <Input.Text
                  value={payload?.machineSettings?.extruderSJ90?.motor?.current || ''}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.extruderSJ90.motor.current = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />

              </div>

              <div className='label-input-wrapper'>
                <label>Двигател оɗ/min</label>
                <Input.Text
                  value={payload.machineSettings?.extruderSJ90?.motor?.revolutions}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.extruderSJ90.motor.revolutions = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Вакуум</label>
                <Input.Text
                  value={payload.machineSettings?.geometry?.vacuum}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.geometry.vacuum = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>

              <div className='label-input-wrapper'>
                <label>ф 1 | ф2</label>
                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  value={payload.machineSettings?.geometry?.f1f2}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      machineSettings: {
                        ...payload.machineSettings,
                        geometry: { ...payload.machineSettings.geometry, f1f2: e.currentTarget.value }
                      }
                    })
                  }}
                />

              </div>

            </div>
          </div>

          <div className='extruder-section-wrapper'>
            <div className='extruder-top-wrapper'>
              <h3>Екструдер за заварка SJ65, t</h3>
              {(status === 'pending' || isEditActive) && <Input.Dropdown
                options={profileArray}
                value={(payload.machineSettings?.extruderSJ65?.profiles.length || 9).toString()}
                style={{ fontWeight: 'bold' }}
                onChange={(e) => {
                  const newPayload = { ...payload }
                  const newProfiles = []
                  Array(e.value).fill('').forEach((el, i) => {
                    if (newPayload.machineSettings?.extruderSJ65.profiles[i]) {
                      newProfiles.push(newPayload.machineSettings?.extruderSJ65.profiles[i])
                    } else {
                      newProfiles.push('')
                    }
                  })
                  newPayload.machineSettings.extruderSJ65.profiles = newProfiles
                  setPayload(newPayload)
                }}
                                                         />}
            </div>

            <div className='extruder-details-container'>
              {payload.machineSettings?.extruderSJ65?.profiles.map((el, i) => {
                return (
                  <div className='single-profile-wrapper'>
                    <div className='profile-top-wrapper'>
                      <label>{i + 1}</label>
                      <Input.Text
                        value={payload.machineSettings?.extruderSJ65.profiles[i]}
                        disabled={disabledInput}
                        className={disabledInput ? 'disabled-input' : 'input'}
                        onChange={(e) => {
                          const newPayload = { ...payload }
                          newPayload.machineSettings.extruderSJ65.profiles[i] = e.currentTarget.value
                          setPayload(newPayload)
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='engine-info-wrapper'>
              <div className='label-input-wrapper'>
                <label>Двигател ток А</label>
                <Input.Text
                  value={payload?.machineSettings?.extruderSJ65?.motor?.current || ''}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.extruderSJ65.motor.current = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Двигател оɗ/min</label>
                <Input.Text
                  value={payload.machineSettings?.extruderSJ65?.motor?.revolutions}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.extruderSJ65.motor.revolutions = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>

              {/* <div className='label-input-wrapper'>
                <label>Вакуум</label>
                <Input.Text
                  value={payload.machineSettings?.geometry?.vacuum}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.geometry.vacuum = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div> */}

              <div className='label-input-wrapper'>
                <label>Дюза</label>
                <Input.Text
                  value={payload.machineSettings?.injector}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.injector = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Навиваща система честота Hz</label>
                <Input.Text
                  value={payload.machineSettings?.windingSystem}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.windingSystem = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Циркуляр за рязане - мин</label>
                <Input.Text
                  value={payload.machineSettings?.buzzSaw?.min}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.buzzSaw.min = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Циркуляр за рязане - ход</label>
                <Input.Text
                  value={payload.machineSettings?.buzzSaw?.move}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    const newPayload = { ...payload }
                    newPayload.machineSettings.buzzSaw.move = e.currentTarget.value
                    setPayload(newPayload)
                  }}
                />
              </div>
            </div>

            <div className='comment-section-wrapper'>
              <div className='comment-label-input-wrapper'>
                <label>Коментар</label>
                <Input.Text
                  placeholder={payload?.comment || ''}
                  disabled={disabledInput}
                  className={disabledInput ? 'disabled-input' : 'input'}
                  onChange={(e) => {
                    setPayload({ ...payload, comment: e.currentTarget.value })
                  }}
                />
              </div>

              {(status === 'pending' || isEditActive) &&
                <div className='switch-btn-wrapper'>
                  <div className='label-btn-wrapper'>
                    <label>Запази настройките</label>
                    <Button.Switch
                      isOn={(payload?.machineSettings === null) ? false : payload?.machineSettings?.isSearchable}
                      style={{ backgroundColor: '#25E083' }}
                      onChange={() => {
                        const newPayload = { ...payload }
                        // searchedSettings[selectedBtn].push(payload.machineSettings);
                        newPayload.machineSettings.isSearchable = !newPayload.machineSettings.isSearchable
                        setPayload(newPayload)
                      }}
                    />
                  </div>

                  {payload?.machineSettings?.isSearchable &&
                    <Input.Text
                      placeholder={selectedBtn}
                      value={payload.machineSettings?.name || ''}
                      onChange={(e) => {
                        const newPayload = { ...payload }
                        newPayload.machineSettings.name = e.currentTarget.value
                        // searchedSettings[selectedBtn].push(newPayload.machineSettings)
                        setPayload(newPayload)
                      }}
                    />}
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
