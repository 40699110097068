export const GeneralTypes = {
  SHOW_LOADING: 'general/SHOW_LOADING',
  HIDE_LOADING: 'general/HIDE_LOADING',
  SHOW_SUCCESS: 'general/SHOW_SUCCESS',
  HIDE_SUCCESS: 'general/HIDE_SUCCESS',
  STOR_WORK: 'general/STOR_WORK',
  ERROR: 'general/ERROR',
}

export const showLoading = (payload) => ({
  type: GeneralTypes.SHOW_LOADING,
  payload,
})

export const hideLoading = (payload) => ({
  type: GeneralTypes.HIDE_LOADING,
  payload,
})

export const showSuccess = (paylaod) => ({
  type: GeneralTypes.SHOW_SUCCESS,
  paylaod,
})

export const hideSuccess = (payload) => ({
  type: GeneralTypes.HIDE_SUCCESS,
  payload,
})

export const stopWork = (payload) => ({
  type: GeneralTypes.STOR_WORK,
  payload,
})
