import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import _ from "lodash"
import { OfferProduct } from "../OfferCreationModal/OffectProduct"
import {
  hideModal,
  searchOfferClients,
  resetResults,
  addInvoiceCredit,
  addInvoiceDebit,
  editInvoiceCredit,
  editInvoiceDebit,
  deleteInvoiceCredit,
  deleteInvoiceDebit,
} from "../../../actions"

import "./styles.scss"

const onChange = (event, searchOfferClients) => {
  event.persist()
  onChangeDelayed(event, searchOfferClients)
}

const onChangeDelayed = _.debounce(
  (event, searchOfferClients) =>
    searchOfferClients({
      search: event.target.value,
    }),
  500
)

const InvoiceNotificationModal = ({
  hideModal,
  data,
  searchOfferClients,
  resetResults,
  addInvoiceCredit,
  addInvoiceDebit,
  productTypes,
  clientResults,
  editInvoiceCredit,
  editInvoiceDebit,
  deleteInvoiceCredit,
  deleteInvoiceDebit,
}) => {
  const [client, setClient] = useState("")
  const [EIK, setEIK] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [reason, setReason] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [products, setProducts] = useState([])

  useEffect(() => {
    if (data.forEdit) {
      setClient(data.notice.client.name)
      setEIK(data.notice.client.uic)
      setPhone(data.notice.client.phone)
      setEmail(data.notice.client.email)
      setAddress(data.notice.client.address)
      setReason(data.notice.reason)
      setBankAccount(data.notice.bankData.bankAccount)
      const foundProducts = data.products.filter((el) => {
        const found = data.notice.products.find(
          (product) => product.item === el.item._id
        )
        if (found) {
          return true
        } else {
          return false
        }
      })
      setProducts(foundProducts)
    }
  }, [data])

  useEffect(() => {
    if (data.products && !data.forEdit) {
      setProducts(data.products.slice(0))
    }
    //eslint-disable-next-line
  }, [data.products])

  const total = products.reduce(
    (acc, val) => acc + val.singleUnitPrice * val.quantity,
    0
  )
  return (
    <div className="invoice-notification-modal-container">
      <div className="left-container">
        <h3>
          Създаване на {data.type === "Credit" ? "кредитно" : "дебитно"}{" "}
          известие
        </h3>
        <div className="flex-container space-between">
          <div className="input-element">
            <p>Клиент</p>
            <div className="input-wrapper">
              <Input.Text
                value={client}
                onChange={(event) => {
                  onChange(event, searchOfferClients)
                  setClient(event.target.value)
                }}
              />
              {clientResults.length > 0 && (
                <div className="client-results-dropdown">
                  {clientResults.map((client) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setAddress(client.address)
                          setEmail(client.email)
                          setClient(client.name)
                          setPhone(client.phone)
                          setEIK(client.uic)
                          resetResults()
                        }}
                      >
                        {client.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="input-element">
            <p>ЕИК</p>
            <Input.Text
              value={EIK}
              onChange={(event) => setEIK(event.target.value)}
            />
          </div>
          <div className="input-element">
            <p>Телефон</p>
            <Input.Text
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container space-between">
          <div className="input-element">
            <p>Имейл</p>
            <Input.Text
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="input-element large">
            <p>Адрес</p>
            <Input.Text
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div className="input-element">
          <p>Основание</p>
          <Input.Text
            value={reason}
            onChange={(event) => setReason(event.target.value)}
          />
        </div>
        <div className="input-element small">
          <p>Ваша банкова сметка</p>
          <Input.Dropdown
            placeholder="Моля изберете"
            value={bankAccount}
            options={["ДСК", "УниКредит", "Райфайзен"]}
            onChange={(value) => setBankAccount(value.value)}
          />
        </div>
      </div>
      <div className="right-container">
        <div className="top-container">
          <h3>Продукти към фактура</h3>
          <div className="exit-btn" onClick={hideModal} />
        </div>
        <div className="scroll-container">
          {products.map((product, index) => {
            return (
              <OfferProduct
                forEdit
                noDropDown
                productTypes={productTypes}
                product={product}
                onSetProductDate={(date) => {
                  let newProducts = products.slice(0)
                  newProducts[index].deliveryDate = date
                  setProducts(newProducts)
                }}
                onSetQuantity={(value) => {
                  let newChosenProducts = products.slice(0)
                  newChosenProducts[index].quantity = value
                  setProducts(newChosenProducts)
                }}
                onRemoveProduct={() => {
                  let newChosenProducts = products.slice(0)
                  newChosenProducts.splice(index, 1)
                  setProducts(newChosenProducts)
                }}
                onSetProductPrice={(value) => {
                  let newChosenProducts = products.slice(0)
                  if (!newChosenProducts[index].singleUnitPrice) {
                    newChosenProducts[index] = {
                      ...newChosenProducts[index],
                      singleUnitPrice: value,
                    }
                  } else {
                    newChosenProducts[index].singleUnitPrice = value
                  }
                  setProducts(newChosenProducts)
                }}
              />
            )
          })}
        </div>
        <div className="flex-container ">
          <p>Обща сума</p>
          <div className="total-container">
            <p>{total / 100}</p>
            <p id="border">лв.</p>
          </div>
        </div>
        <div className="flex-container btn">
          {data.forEdit && (
            <Button.Raised
              text="Изтрий документ"
              className="delete-btn"
              onClick={() => {
                if (data.type === "Credit") {
                  deleteInvoiceCredit({
                    _id: data.notice._id,
                  })
                } else if (data.type === "Debit") {
                  deleteInvoiceDebit({
                    _id: data.notice._id,
                  })
                }
              }}
            />
          )}
          <Button.Raised
            text={`${data.forEdit ? "Редактирай" : "Създай"}`}
            onClick={() => {
              if (data.type === "Credit") {
                if (data.forEdit) {
                  editInvoiceCredit({
                    _id: data.notice._id,
                    invoice: data.invoiceID,
                    client: {
                      name: client,
                      uic: EIK,
                      phone: phone,
                      email: email,
                      address: address,
                      attentionOf: client,
                    },
                    reason: reason,
                    bankData: {
                      bankAccount: bankAccount,
                      marginFacilities: 20,
                      marginHydro: 20,
                    },
                    products: products,
                    total: total / 100,
                  })
                } else {
                  addInvoiceCredit({
                    invoice: data.invoiceID,
                    client: {
                      name: client,
                      uic: EIK,
                      phone: phone,
                      email: email,
                      address: address,
                      attentionOf: client,
                    },
                    reason: reason,
                    bankData: {
                      bankAccount: bankAccount,
                      marginFacilities: 20,
                      marginHydro: 20,
                    },
                    products: products,
                    total: total / 100,
                  })
                }
              } else {
                if (data.forEdit) {
                  editInvoiceDebit({
                    _id: data.notice._id,
                    invoice: data.invoiceID,
                    client: {
                      name: client,
                      uic: EIK,
                      phone: phone,
                      email: email,
                      address: address,
                      attentionOf: client,
                    },
                    reason: reason,
                    bankData: {
                      bankAccount: bankAccount,
                      marginFacilities: 20,
                      marginHydro: 20,
                    },
                    products: products,
                    total: total / 100,
                  })
                } else {
                  addInvoiceDebit({
                    invoice: data.invoiceID,
                    client: {
                      name: client,
                      uic: EIK,
                      phone: phone,
                      email: email,
                      address: address,
                      attentionOf: client,
                    },
                    reason: reason,
                    bankData: {
                      bankAccount: bankAccount,
                      marginFacilities: 20,
                      marginHydro: 20,
                    },
                    products: products,
                    total: total / 100,
                  })
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  productTypes: state.products.types,
  clientResults: state.inquiries.clientResults,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  searchOfferClients: (payload) => dispatch(searchOfferClients(payload)),
  resetResults: () => dispatch(resetResults()),
  addInvoiceCredit: (payload) => dispatch(addInvoiceCredit(payload)),
  addInvoiceDebit: (payload) => dispatch(addInvoiceDebit(payload)),
  editInvoiceDebit: (payload) => dispatch(editInvoiceDebit(payload)),
  editInvoiceCredit: (payload) => dispatch(editInvoiceCredit(payload)),
  deleteInvoiceDebit: (payload) => dispatch(deleteInvoiceDebit(payload)),
  deleteInvoiceCredit: (payload) => dispatch(deleteInvoiceCredit(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceNotificationModal)
