import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { ProjectTypes, GeneralTypes, showModal, hideLoading, getInquirySuccess } from "../actions"
import { API_URL } from "../config/settings"

export const createProjectOffer = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.CREATE_PROJECT_OFFER),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/offers`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.CREATE_PROJECT_OFFER_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно създадена оферта",
                width: "500px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createProjectOfferEn = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.CREATE_PROJECT_OFFER_EN),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/offers/en`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.CREATE_PROJECT_OFFER_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно създадена оферта",
                width: "500px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createProjectInvoice = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.CREATE_PROJECT_INVOICE),
    switchMap(({ payload, onSuccess }) => {
      return Api.post(
        `${API_URL}/offers/invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.CREATE_PROJECT_INVOICE_SUCCESS,
              payload: response,
            })
            onSuccess && onSuccess()
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно създадена фактура",
                width: "500px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createProjectInvoiceEn = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.CREATE_PROJECT_INVOICE_EN),
    switchMap(({ payload, onSuccess }) => {
      return Api.post(
        `${API_URL}/offers/invoice/en`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.CREATE_PROJECT_INVOICE_SUCCESS,
              payload: response,
            })
            onSuccess(response)
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно създадена фактура",
                width: "500px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProjectOffer = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.EDIT_PROJECT_OFFER),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/offers`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_PROJECT_OFFER_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно редактирахте оферта",
                width: "600px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProjectOfferEn = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.EDIT_PROJECT_OFFER_EN),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/offers/en`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_PROJECT_OFFER_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно редактирахте оферта",
                width: "600px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProjectInvoice = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.EDIT_PROJECT_INVOICE),
    switchMap(({ payload, onSuccess }) => {
      return Api.put(`${API_URL}/offers/invoice`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_PROJET_INVOICE_SUCCESS,
              payload: response,
            })
            onSuccess(response)
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно редактирахте фактура",
                width: "600px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProjectInvoiceEn = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.EDIT_PROJECT_INVOICE_EN),
    switchMap(({ payload, onSuccess }) => {
      return Api.put(
        `${API_URL}/offers/invoice/en`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_PROJET_INVOICE_SUCCESS,
              payload: response,
            })
            onSuccess(response)
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно редактирахте фактура",
                width: "600px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchOfferClients = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.SEARCH_OFFER_CLIENTS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/search/clients`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.SEARCH_OFFER_CLIENTS_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createActOfAcceptance = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.CREATE_ACT_OF_ACCEPTANCE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/act-of-acceptance`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.CREATE_ACT_OF_ACCEPTANCE_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно създаден ППП",
                width: "500px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createActOfAcceptanceEn = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.CREATE_ACT_OF_ACCEPTANCE_EN),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/act-of-acceptance/en`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.CREATE_ACT_OF_ACCEPTANCE_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно създаден ППП",
                width: "500px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editActOfAcceptance = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.EDIT_ACT_OF_ACCEPTANCE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/act-of-acceptance`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_ACT_OF_ACCEPTANCE_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно редактирахте ППП",
                width: "600px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editActOfAcceptanceEn = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.EDIT_ACT_OF_ACCEPTANCE_EN),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/act-of-acceptance/en`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_ACT_OF_ACCEPTANCE_SUCCESS,
              payload: response,
            })
            obs.next(
              showModal({
                type: "successModal",
                data: "Успешно редактирахте ППП",
                width: "600px",
              })
            )
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteProjectOffer = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.DELETE_PROJECT_OFFER),
    switchMap(({ payload }) => {
      return Api.delete(`${API_URL}/offers`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_INQUIRY_SUCCESS,
              payload: response,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteProjectInvoice = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.DELETE_PROJECT_INVOICE),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/offers/invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_INQUIRY_SUCCESS,
              payload: response,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteProjectAct = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.DELETE_PROJECT_ACT),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/offers/act-of-acceptance`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_INQUIRY_SUCCESS,
              payload: response,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchActSuppliers = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.SEARCH_ACT_SUPPLIERS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/search/delivery-services`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.SEARCH_ACT_SUPPLIERS_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteProjectProduct = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.DELETE_PROJECT_PRODUCT),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/inquiries/product`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.EDIT_INQUIRY_SUCCESS,
              payload: response,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchInquiryCategories = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.SEARCH_INQUIRY_CATEGORIES),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/inquiries/actions/categories`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProjectTypes.SEARCH_INQUIRY_CATEGORIES_SUCCESS,
              payload: response.categories,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
export const editProjectTeamEpic = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.EDIT_PROJECT_TEAM),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/inquiries`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            console.log(response)
            obs.next(getInquirySuccess(response.inquiry))
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
