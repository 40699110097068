import React, { useState, useEffect } from "react"
import { Button } from "@makedonski/admin-ui"
import { CustomRadio } from "../../components"
import "./styles.scss"

const StorageRawMaterialsOrderStatus = ({
  statusTypes,
  currentStatus,
  onClick,
  onClose,
}) => {
  const [selected, setSelected] = useState(currentStatus)
  useEffect(() => {
    if (currentStatus) {
      setSelected(currentStatus)
    }
  }, [currentStatus])
  return (
    <div className="storage-order-status-container">
      <div className="storage-order-status-header">
        <span>Статус</span>
        <Button.Icon name="plus" onClick={onClose} size={16} />
      </div>
      <div className="storage-order-status-content">
        {Object.keys(statusTypes)
          .filter((status) => {
            if (currentStatus === "accrued") {
              if (status === "accrued" || status === "canceled") {
                return status
              } else {
                return null
              }
            } else if (
              currentStatus === "ordered" ||
              currentStatus === "delivered"
            ) {
              if (
                status === "delivered" ||
                status === "canceled" ||
                status === "ordered"
              ) {
                return status
              } else {
                return null
              }
            } else if (currentStatus === "exported") {
              if (status === "exported" || status === "canceled") {
                return status
              } else {
                return null
              }
            } else {
              return status
            }
          })
          .map((s, i) => (
            <div key={`single-status-${i}`} className="single-status">
              <div
                className="status-bar"
                style={{ backgroundColor: statusTypes[s].color }}
              />
              <p className={`${s === selected && "selected"}`}>
                {statusTypes[s].text}
              </p>
              <CustomRadio
                onClick={() => setSelected(s)}
                selected={s === selected}
              />
            </div>
          ))}
      </div>
      <Button.Raised
        className="btn-change"
        text="Промени"
        onClick={() => onClick(selected)}
      />
    </div>
  )
}

export default StorageRawMaterialsOrderStatus
