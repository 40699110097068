import React, { useState, useEffect, useRef } from 'react'
import './styles.scss'
import { Alerts } from '@makedonski/socourt-utilities/lib/Alerts'
import { CommentsActivityDashboard } from '../../components'
import { Input, Section } from '@makedonski/admin-ui'
import { useParams, useLocation } from 'react-router-dom'
import { getPerformers, editActionItem, getCurrentAction, getMachineActivity, saveMachineActivity } from '../../actions'
import { useSelector, useDispatch } from 'react-redux'
import { CheckListPopup } from "../../components/CheckListPopup"
import { history } from '../../config/stores'
import moment from 'moment'

export default function ProductionViewOtherRequest() {
  const { id } = useParams()
  const dispatch = useDispatch();

  const [ isEditActive, setIsEditActive ] = useState(false);
  const [ isEditComplete, setIsEditComplete ] = useState(false);
  const [ isDashboardActive, setIsDashboardActive ] = useState(false);
  const [ dashboardSection, setDashboardSection ] = useState('comments');

  const penIcon = require("../../assets/icons/pen-icon.svg");
  const plusIcon = require("../../assets/icons/light-plus-icon.svg");
  const xIcon = require('../../assets/icons/remove-btn.svg');
  const activityIcon = require('../../assets/icons/dashboard-apps.svg');

  let executorsTab = useRef(null);
  const performers = useSelector((state) => state.production.performers)
  const data = useSelector((state) => state.production.currentAction)
  const savedActivity = useSelector((state) => state.production.savedMachineActivity)

  const [ payload, setPayload ] = useState(
    {
    activity: '',
    description: '',
    comment: '',
    start: null,
    end: null,
    performers: []
  })

  const checkStatus = () => {
    if (data?.request?.status === 'in-production' ||
    data?.status === 'in-production') {
      return 'В производство'
    } else if (data?.request?.status === 'finished' ||
    data?.status === 'finished') {
      return 'Произведена'
    } else {
      return 'В изчакване'
    }
  }

  useEffect(() => {
    if (!isEditComplete) {
      // data = state.data
      if (data) {
        setPayload({...data})
      }
      dispatch(getPerformers())
    }
  }, [isEditActive, data]);

  useEffect(() => {
    dispatch(getCurrentAction(id))
    dispatch(getMachineActivity({ request: id, pageNumber: 1, pageSize: 20 }))
  }, [])

  return (
    <div className='production-view-other-request-wrapper'>
      <div className={isEditActive ? 'view-request-other-container edit' : 'view-request-other-container' }>
        <div className='view-other-request-top'>

        {isEditActive ? 
        <>
        <div className='flex'>
          <h2 className='edit-heading'>Редакция на дейност</h2>
          <img className='icon-pointer' src={require('../../assets/icons/bin.svg')}
                  onClick={() => {
                   Alerts.confirm({
                     title: "Сигурни ли сте, че искате да изтриете дейността?",
                     confirm: "Да",
                     cancel: "Не",
                   },
                   (answer) => {
                     if (answer) {
                      dispatch(editActionItem({ ...payload, deletedAt: moment() }))
                       history.push('/production')
                     }
                   })
                 }} />
        </div>

         <div className='edit-buttons-wrapper'>
         <button className='cancel-edit-btn'
            onClick={() => {
              setIsEditActive(false)
              }}>Откажи</button>
         <button className='save-edit-btn'
         onClick={() => {
          const newPayload = { ...payload }

          const activityPayload = {
            request: id,
            title: 'промени дейност',
            metadata: newPayload
          }

          dispatch(saveMachineActivity(activityPayload))
          dispatch(editActionItem({...payload}))
          setIsEditActive(false)
          setIsEditComplete(true)
          }}>Запази</button>
         </div>
        </>
        :
        <>
        <div className="heading-icon-wrapper">
          <div className='headings-wrapper'>
            <h2>Преглед дейност</h2>
            <h3>
              Статус: <span>{checkStatus()}</span>
            </h3>
          </div>

               <div className="img-wrapper">
                 {data?.status !== 'finished' && <img src={penIcon}
                 onClick={() => {
                  setIsEditActive(!isEditActive)
                  setIsDashboardActive(false)
                  }} />}
                 <img src={activityIcon} 
                      onClick={() => {
                      setIsDashboardActive(!isDashboardActive)
                      setIsEditActive(false)
                  }}
                 />
               </div>
          </div>

          {data?.status !== 'finished' ? 
                     <button
                     onClick={() => dispatch(editActionItem({_id: data?._id, status: 'finished'}))}>Приключи
                     </button> :
                     <div className='finished-msg-wrapper'>
                      Завършена на: <span>{moment(payload?.updatedAt).format('DD.MM.YYYY')}</span>
                      </div>}
        </>}
        </div>

        <div className='view-request-main'>
          <div className='view-request-main-wrapper'>

            <div className='row'>
               {!isEditActive &&
               <div className="activity-section-wrapper">
                 <label htmlFor="activity-input">Дейност</label>
                 <Input.Text
                 disabled={!isEditActive}
                 value={payload.activity}
                 onChange={(e) => setPayload({...payload, activity: e.target.value})} />
               </div>}

               {isEditActive &&
               <div className="activity-section-wrapper">
                 <label htmlFor="activity-input">Дейност</label>
                 <Input.Text
                 value={payload?.activity}
                 onChange={(e) => setPayload({...payload, activity: e.target.value})} />
               </div>}
               
               {/* {isEditActive &&
               <div className='activity-section-wrapper'>
                 <label htmlFor="activity-input">Дейност</label>
                 <div className="dropdowns-wrapper">
                    <Input.Dropdown
                        className='activity-dropdown'
                        options={Object.keys(activityCategoryList)}
                        value={activityQuery}
                        placeholder=''
                        onChange={(e) => {
                          setActivityCategory(activityCategoryList[e.value])
                          setActivityQuery(e.value)
                          setPayload({...payload, activity: ''})
                        }}
                        />
   
                      <Input.Dropdown
                        disabled={!activityQuery}
                        className='activity-dropdown'
                        options={activitiesList[activityCategory]}
                        value={payload.activity}
                        placeholder=''
                        onChange={(e) => {
                         setPayload({...payload, activity: e.value})
                        }}
                        />
                  </div>
               </div>} */}
   
               <div className="description-section-wrapper">
                 <label htmlFor="description-input">Описание</label>
                 <Input.Text 
                 disabled={!isEditActive}
                 value={payload.description} id="description-input"
                 onChange={(e) => setPayload({...payload, description: e.target.value})} />
               </div>
   
               <div className="comment-section-wrapper">
                 <label htmlFor="comment-input">Коментар</label>
                 <Input.Text 
                 disabled={!isEditActive}
                 value={payload.comment} id="comment-input"
                 onChange={(e) => setPayload({...payload, comment: e.target.value})} />
               </div>
            </div>
   
            <div className="start-date-section-wrapper">
                <label>Начало</label>
                {isEditActive ? 
                <Input.Datepicker
                placeholder={moment(payload.start).format('DD.MM.YYYY')}
                // pickDate={payload.start || null}
                onChange={(pickDate) => {
                  setPayload({...payload, start: pickDate, isDelayed: true })
                }}
                 /> :
                <Input.Text disabled value={moment(payload?.start).format('DD.MM.YYYY')} />}
            </div>

            <div className="start-hour-section-wrapper">
                <label>Начален час</label>
                {isEditActive ?
                <Input.TimePicker
                 timeIntervals={30}
                 placeholder={moment(payload.start).format('HH:mm')}
                 // pickDate={moment(data.start)}
                 onChange={pickDate => {
                  let newPayload = {...payload}
                  let hour = moment(pickDate).hour()
                  let minute = moment(pickDate).minute()
                  newPayload.start = moment(newPayload.start).set({'hour': hour, 'minute': minute})
                  newPayload.isDelayed = true
                  setPayload(newPayload)
                }}
              
              /> :
              <Input.Text disabled value={moment(payload.start || data?.start).format('HH:mm')} />}
                
            </div>
            
            <div className="end-date-section-wrapper">
                <label>Крайна дата</label>
                {isEditActive ?
                <Input.Datepicker 
                placeholder={moment(payload.end).format('DD.MM.YYYY')}
                 // pickDate={moment(data.end)}
                 onChange={pickDate => {
                  setPayload({...payload, end: pickDate, isDelayed: true })
                }} />
                 :
                <Input.Text disabled value={moment(payload?.end).format('DD.MM.YYYY')} />}
            </div>

            <div className="end-hour-section-wrapper">
                <label>Краен час</label>
                {isEditActive ?
                <Input.TimePicker
                timeIntervals={30}
                placeholder={moment(payload.end).format('HH:mm')}
                // pickDate={moment(data.end)}
                onChange={pickDate => {
                  let newPayload = {...payload}
                  let hour = moment(pickDate).hour()
                  let minute = moment(pickDate).minute()
                  newPayload.end = moment(newPayload.end).set({'hour': hour, 'minute': minute})
                  newPayload.isDelayed = true
                  setPayload(newPayload)
               }}
                /> :
                <Input.Text disabled value={moment(payload?.end).format('HH:mm')} />}
            </div>

          {!isEditActive && <div className="contractors-section-wrapper">
            <label>Изпълнители</label>
            <div className='contractors-names-wrapper'>
              {payload?.performers.map(performer => {return <p>{performer}</p>})}
            </div>
          </div>}

          {(!isEditActive && data?.inquiry?.checklist?.length > 0 &&
            data?.inquiry?.checklist[data?.inquiry?.checklist?.length - 1]?.action === 'received') &&
            <div className='checklist-section-wrapper'>
              <div className='label'>Чеклист от клиент</div>

              <div className='file-wrapper'>
                  <div className='file-name' onClick={() => window.open(data?.inquiry?.checklist[data?.inquiry?.checklist?.length - 1]?.url)}>
                   {data?.inquiry?.checklist[data?.inquiry?.checklist?.length - 1]?.name}
                  </div>
                  <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
              </div>
            </div>}

            {(!isEditActive && data?.drawing && Object.values(data?.drawing)) &&
            <div className='checklist-section-wrapper'>
              <div className='label'>Чертеж</div>

              <div className='file-wrapper'>
                  <div className='file-name' onClick={() => window.open(data?.drawing?.url)}>
                   {data?.drawing?.name}
                  </div>
                  <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
              </div>
            </div>}
          
          </div>

          {isEditActive && <div className="contractors-section-wrapper">
            <label htmlFor="contractor">Изпълнители</label>

            <div className='contractors-img-selected-wrapper'>
            <Section.Popup
              ref={(ref) => {
                executorsTab = ref
              }}
              content={
                <CheckListPopup
                  data={performers}
                  handleCheckList={(list) => setPayload({...payload, performers: list})}
                  onClose={() => executorsTab.hide()}
                />
              }
            >
              <img
                src={plusIcon}
                style={{width: 24, height: 24}}
                alt="+"
                onClick={() => executorsTab.show()}
              ></img>
            </Section.Popup>

            <div className='contractors-names-wrapper'>
              {payload.performers?.map(performer => {
                return <div className='single-contractor-img-wrapper'>
                  <p>{performer}</p>
                  <img src={xIcon} alt='x'
                  onClick={() => {
                  let newPayload = {...payload}
                  newPayload.performers = newPayload.performers.filter(el => el !== performer)
                  setPayload(newPayload)
                  }}></img>
                </div>
                })}
            </div>
            </div>
          </div>}
      
        </div>
      </div>

      {isDashboardActive &&
          <CommentsActivityDashboard
          id={id}
          type='other-request'
          activityHistory={true}
          // tableData={data?.work?.length > 0 ? data?.work[0]?.produce : []}
          tableData={savedActivity?.length > 0 ? savedActivity : []}
          quantity={data?.quantity}
          // comments={commentsDashboard}
          dashboardSection={dashboardSection}
          setDashboardSection={(value) => setDashboardSection(value)}
          onClose={() => setIsDashboardActive(false)}
        //   onSend={(comment, machineScheduleWork) => {
        //     dispatch(addCommentMachine({ commentType: 'text', text: comment, machineScheduleWork: machineScheduleWork, machineSchedule: data._id }))
        //     dispatch(getSingleSchedule({ _id: id }))
        //   }
        // }
           />}

      {/* {isDashboardActive &&
          <div className='dashboard-tab-section-wrapper'>
            <div className='dashboard-top-wrapper'>
              <div className='headers-wrapper'>
                <h3
                  className={dashboardSection === '1' ? 'selected' : 'comment'}
                  onClick={() => setDashboardSection('1')}
                >Коментари
                </h3>
                <h3
                  className={dashboardSection === '2' ? 'selected' : 'activity'}
                  onClick={() => setDashboardSection('2')}
                >Активност
                </h3>
              </div>
              <img src={closeIcon}
              onClick={() => setIsDashboardActive(false)}
               />
            </div>

            <div className='dashboard-main-wrapper'>
              <div className='comments-tab-wrapper' />
              <div className='activity-tab-wrapper' />
            </div>

          </div>} */}
    </div>
  )
}
