import { Auth } from "@makedonski/socourt-utilities"
import { UserTypes, AuthTypes } from "../actions"

const _ = require("lodash")

const initialState = {
  data: [],
  filteredUsers: [],
  activeTasks: {},
  completedTasks: [],
  currentUser: Auth.getUser(),
  currentUserRole: [],
  currentUserTasks: [],
  isAuthenticated: Auth.isAuthenticated,
}
const setUserWorkingStatus = (state, payload) => {
  const newData = state.data.slice(0)
  const index = newData.findIndex((user) => user._id === payload._id)
  newData[index] = payload
  return newData
}

const updateUser = (state, payload) => {
  localStorage.setItem('userId', payload?._id || '')
  return payload
}

const setTasks = (payload) => {
  const taskArray = []
  Object.values(payload.tasks).forEach((value) => {
    value.forEach((task) => taskArray.push(task))
  })
  const uniqueTaskArray = _.uniqBy(taskArray, (task) => task._id)
  return uniqueTaskArray
}

const addTask = (state, payload) => {
  const newTasks = state.activeTasks.slice(0)
  newTasks.push(payload)
  return newTasks
}

const addUserTask = (state, payload) => {
  const newTasks = state.currentUserTasks.slice(0)
  if (
    payload.assignees.findIndex(
      (assignee) => assignee._id === state.currentUser._id
    ) !== -1
  ) {
    newTasks.push(payload)
  }
  return newTasks
}

const updateTask = (state, payload) => {
  let newActiveTasks = state.activeTasks.slice(0)
  let taskIndex = newActiveTasks.findIndex((task) => task._id === payload._id)
  newActiveTasks[taskIndex] = payload
  return newActiveTasks
}

const updateUserTask = (state, payload) => {
  let newUserTasks = state.currentUserTasks.slice(0)
  let taskIndex = newUserTasks.findIndex((task) => task._id === payload._id)
  newUserTasks[taskIndex] = payload
  return newUserTasks
}

const setUserRole = (payload) => {
  localStorage.setItem('currentUserRole', payload?.role?.access?.split(',') || [])
  return payload?.role?.access?.split(',') || []
}

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case UserTypes.CHANGE_WORKING_STATUS_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        data: setUserWorkingStatus(state, payload),
      }
    case UserTypes.CHANGE_STATUS_SUCCESS:
      return { ...state, currentUser: payload }
    case UserTypes.GET_COLLEAGUES_SUCCESS:
      return { ...state, data: payload }
    case UserTypes.GET_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: updateUser(state, payload) }
    case UserTypes.GET_CURRENT_USER_ROLE_SUCCESS:
      return { ...state, currentUserRole: setUserRole(payload) }
    case AuthTypes.SIGN_IN_SUCCESS:
      return { ...state, isAuthenticated: true }
    case AuthTypes.UPDATE_PROFILE_SUCCESS:
      return { ...state, currentUser: payload }
    case UserTypes.GET_FILTERED_USERS_SUCCESS:
      return { ...state, filteredUsers: payload }
    case UserTypes.GET_USER_TASKS_SUCCESS:
      return {
        ...state,
        activeTasks: setTasks(payload),
        completedTasks: payload.completed,
        currentUserTasks: payload.tasks[Auth.getUser()._id] || [],
      }
    case "modal/CREATE_TASK_SUCCESS":
      return {
        ...state,
        activeTasks: addTask(state, payload),
        currentUserTasks: addUserTask(state, payload),
      }
    case "modal/UPDATE_TASK_SUCCESS":
      return {
        ...state,
        activeTasks: updateTask(state, payload),
        currentUserTasks: updateUserTask(state, payload),
      }
    case "modal/GET_TASKS_SUCCESS":
      return {
        ...state,
        activeTasks: setTasks(payload),
        completedTasks: payload.completed,
        currentUserTasks: payload.tasks[state.currentUser._id] || [],
      }
    default:
      return state
  }
}

export default user
