import React from "react"
import { history } from "../../config/stores"
import "./styles.scss"

function ScheduleDailyElement({ flex, completed, data, type }) {
  let name = null
  if (data.request?.product?.basicInfo) {
    name = type
  }
  const productName =
    (data.product && data.product.basicInfo.map((el) => el.value).join("_")) ||
    ""
  return (
    <div
      className={`schedule-daily-element ${completed && "completed"}`}
      style={{ flex: flex }}
      onClick={() => {
        if (data.request?.scheduled) {
          history.push(`/production/${data.request.scheduled._id}`)
        } else {
          history.push(`/production/${data._id}`)
        }
      }}
    >
      <div className="coloured-bar" />
      <div className="schedule-data">
        <h4>{name ? name : data.activity}</h4>
        {name && <p id="bold">Дейност: {data.activity}</p>}
        {data.description && (
          <div className="flex-container">
            <p>Описание: </p>
            <p id="bold">{data.description}</p>
          </div>
        )}
        {data.performers && (
          <div className="flex-container">
            <p>Изпълнители: </p>
            <p id="bold">{data.performers.join(", ")}</p>
          </div>
        )}
        {data.request && (
          <div className="flex-container">
            <p>Проект: </p>
            <p id="bold">{data.request.inquiry.object}</p>
          </div>
        )}
        {productName && (
          <div className="flex-container">
            <p>Продукт: </p>
            <p id="bold">{productName}</p>
          </div>
        )}
        {data.materials &&
          data.materials.map((material, index) => {
            return (
              <>
                <div className="flex-container">
                  <p id="bold">Материал: </p>
                  <p> {material.name}</p>
                </div>
                <div className="flex-container">
                  <p id="bold">Количество: </p>
                  <p> {material.quantity}</p>
                </div>
              </>
            )
          })}
      </div>
    </div>
  )
}

export default ScheduleDailyElement
