import React from "react"
import { connect } from "react-redux"
import { updateProfile } from "../../actions"
import { Button, Input } from "@makedonski/admin-ui"
import "./styles.scss"

class ProfileEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
    }
    this.linkDetails = {}
    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.closeModal()
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () =>
      console.log(this.state.firstName)
    )
  }
  render() {
    return (
      <div className="profile-edit" ref={this.wrapperRef}>
        <div className="row">
          <h2>Профил</h2>
          <Button.Image
            src={require("../../assets/images/mario.jpg")}
            className="pfp"
            imageClassName=""
            nameClassName=""
            onClick={() => console.log()}
          />
        </div>
        <div className="profile-form">
          <div className="text-input-container">
            <input
              className="text-input"
              placeholder={this.props.profile.firstName}
              name="firstName"
              value={this.state.firstName}
              onChange={this.onChange.bind(this)}
            />
          </div>
          <div className="text-input-container">
            <input
              className="text-input"
              placeholder={this.props.profile.lastName}
              name="lastName"
              value={this.state.lastName}
              onChange={this.onChange.bind(this)}
            />
          </div>
          <Input.Text placeholder="Info" />
          <Input.Textarea placeholder="Description" />
          <h3>Данни за Вход</h3>
          <Input.Text placeholder="Email" />
          <Input.Text placeholder="password" />
          <Button.Raised
            text="Запази"
            onClick={() =>
              this.props.updateProfile({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
              })
            }
          />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  profile: state.user.currentUser,
})
const mapDispatchToProps = (dispatch) => ({
  updateProfile: (payload) => dispatch(updateProfile(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit)
