import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Input } from "@makedonski/admin-ui"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { ScheduleDailyElement, ScheduleTabs } from "../../components"
import {
  getMachineSchedule,
  getMachineScheduleByPerformer,
  getProductTypes,
  getActionSchedule,
  setProductionQuery,
} from "../../actions"
import moment from "moment"
import "./styles.scss"
import { history } from "../../config/stores"
import { isEmpty } from "lodash"

const hoursArray = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
]

const nightHoursArray = [
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
]

const dayMap = {
  0: "Понеделник",
  1: "Вторник",
  2: "Сряда",
  3: "Четвъртък",
  4: "Петък",
  5: "Събота",
  6: "Неделя",
}

const monthMap = {
  1: "Януари",
  2: "Февруари",
  3: "Март",
  4: "Април",
  5: "Май",
  6: "Юни",
  7: "Юли",
  8: "Август",
  9: "Септември",
  10: "Октомври",
  11: "Ноември",
  12: "Декември",
}

const getWeek = (startOfWeek, endOfWeek) => {
  const week = []
  let day = startOfWeek

  while (day <= endOfWeek) {
    week.push(day.toDate())
    day = day.clone().add(1, "d")
  }
  return week
}

const ScheduleComponent = ({
  schedule,
  getMachineSchedule,
  getMachineScheduleByPerformer,
  productSchedule,
  types,
  getProductTypes,
  getActionSchedule,
  actionSchedule,
  setProductionQuery,
}) => {
  const [week, setWeek] = useState({
    startOfWeek: moment().startOf("isoWeek"),
    endOfWeek: moment().endOf("isoWeek"),
  })
  const [weekDay, setWeekDay] = useState(moment())
  const [viewType, setViewType] = useState("")
  const [periodType, setPeriodType] = useState("")
  const [shift, setShift] = useState("Дневна смяна")

  const daysArray = getWeek(week.startOfWeek, week.endOfWeek)
  const month = moment(daysArray[daysArray.length - 1]).format("M")
  let actionSchedulePerformers = []
  actionSchedule.length &&
    actionSchedule.map((el) => {
      el.performers.forEach((performer) => {
        const performerIndex = actionSchedulePerformers.findIndex(
          (perf) => performer === perf
        )
        if (performerIndex === -1) {
          actionSchedulePerformers.push(performer)
        }
      })
      return null
    })
  let newActionSchedule = {}
  actionSchedulePerformers.forEach((performer) => {
    let dataArray = []
    actionSchedule.forEach((el) => {
      if (el.performers.findIndex((perf) => perf === performer) !== -1) {
        dataArray.push(el)
      }
    })
    newActionSchedule[performer] = dataArray
  })
  const setOutcomeData = (date, request) => {
    const startDate = request.date
      ? request.date
      : request.start
      ? request.start
      : request.startsAt
    const endDate = request.end
      ? request.end
      : moment(startDate).add(request.durationMinutes, "minutes")
    if (
      moment(startDate).isAfter(moment(date).startOf("day")) &&
      moment(endDate).isBefore(moment(date.endOf("day")))
    ) {
      return "startsTodayEndsToday"
    } else if (
      moment(startDate).isAfter(moment(date).startOf("day")) &&
      moment(endDate).isAfter(moment(date).endOf("day"))
    ) {
      return "startsTodayEndsAfter"
    } else if (
      moment(startDate).isBefore(moment(date).startOf("day")) &&
      moment(endDate).isBefore(moment(date).endOf("day"))
    ) {
      return "startsBeforeEndsToday"
    } else if (
      moment(startDate).isBefore(moment(date).startOf("day")) &&
      moment(endDate).isAfter(moment(date).endOf("day"))
    ) {
      return "startsBeforeEndsAfter"
    } else {
      return "error"
    }
  }

  const renderHeader = (
    viewType,
    periodType,
    schedule,
    productSchedule,
    activitySchedule
  ) => {
    const query = `${viewType}-${periodType}`
    switch (query) {
      case "Тръби-Седмичен":
        return (
          <>
            <div className="calendar-header-element">OD800</div>
            <div className="calendar-header-element">OD2400</div>
            <div className="calendar-header-element">OD2000</div>
          </>
        )
      case "Тръби-Дневен":
        return (
          <>
            {productSchedule &&
              Object.keys(productSchedule)
                .sort((a, b) => a.length - b.length)
                .map((key) => {
                  return <div className="calendar-header-element">{key}</div>
                })}
          </>
        )
      case "Краен продукт-Дневен":
        return (
          <>
            {productSchedule &&
              Object.keys(productSchedule)
                .sort((a, b) => a.length - b.length)
                .map((key) => {
                  return (
                    <div
                      className="calendar-header-element"
                      style={{ minWidth: "169px" }}
                    >
                      {(key === "" && "Няма име") || key}
                    </div>
                  )
                })}
          </>
        )
      case "Краен продукт-Седмичен":
        return (
          <>
            {productSchedule &&
              Object.keys(productSchedule).map((key) => {
                return (
                  <div className="calendar-header-element">
                    {(key === "" && "Няма име") || key}
                  </div>
                )
              })}
          </>
        )
      case "Допълнителни-Дневен":
        return (
          <>
            {activitySchedule &&
              Object.keys(activitySchedule)
                .sort((a, b) => a.length - b.length)
                .map((key) => {
                  return (
                    <div className="calendar-header-element">
                      {(key === "" && "Няма име") || key}
                    </div>
                  )
                })}
          </>
        )
      default:
        break
    }
  }

  const setProductionData = (date, requestsArray) => {
    let outcomeArray = Array(13).fill("dummy")
    requestsArray.forEach((request) => {
      const startDate = request.date
        ? request.date
        : request.start
        ? request.start
        : request.startsAt
      const endDate = request.end
        ? request.end
        : moment(startDate).add(request.durationMinutes, "minutes")
      let outcome = setOutcomeData(date, request)
      switch (outcome) {
        case "startsTodayEndsToday":
          hours.forEach((hour, index) => {
            // if (
            //   moment(startDate)
            //     .startOf("hour")
            //     .isBefore(moment(weekDay).set("hour", 7)) &&
            //   shift === "Дневна смяна"
            // ) {
            //   outcomeArray.forEach((el, index) => {
            //     if (index === 0) {
            //       outcomeArray[index] = request
            //     } else {
            //       outcomeArray[index] = null
            //     }
            //   })
            // } else {
            if (moment(startDate).startOf("hour").format("HH:mm") === hour) {
              outcomeArray[index] = request
              let steps = request.durationMinutes / 60
              if (isNaN(steps)) {
                steps =
                  Math.abs(moment(startDate).diff(moment(endDate), "minutes")) /
                  60
              }
              if (steps < 1) {
                steps = 1
              }
              Array(Math.floor(steps))
                .fill(null)
                .forEach((x, i) => {
                  outcomeArray[index + i + 1] = x
                })
            }
            // }
          })
          break
        case "startsTodayEndsAfter":
          hours.forEach((hour, index) => {
            if (
              moment(startDate)
                .startOf("hour")
                .isBefore(moment(weekDay).set("hour", 7))
            ) {
              outcomeArray.forEach((el, index) => {
                if (index === 0) {
                  outcomeArray[index] = request
                } else {
                  outcomeArray[index] = null
                }
              })
            } else {
              if (moment(startDate).startOf("hour").format("HH:mm") === hour) {
                outcomeArray[index] = request
                outcomeArray.forEach((el, innerIndex) => {
                  if (innerIndex > index) {
                    outcomeArray[innerIndex] = null
                  }
                })
              }
            }
          })
          break
        case "startsBeforeEndsToday":
          hours.forEach((hour, index) => {
            if (
              (shift === "Дневна смяна" && hour === "07:00") ||
              (shift === "Нощна смяна" && hour === "20:00")
            ) {
              outcomeArray[index] = request
              let steps =
                moment(endDate).diff(
                  moment(endDate).set("hour", 7).set("minute", 0),
                  "minutes"
                ) / 60
              if (isNaN(steps)) {
                steps =
                  Math.abs(moment(startDate).diff(moment(endDate), "minutes")) /
                  60
              }
              if (steps < 1) {
                steps = 1
              } else if (steps > 13) {
                steps = 13
              }
              Array(Math.floor(steps))
                .fill(null)
                .forEach((x, i) => {
                  outcomeArray[index + i + 1] = x
                })
            }
          })
          break
        case "startsBeforeEndsAfter":
          outcomeArray[0] = request
          outcomeArray.forEach((el, index) => {
            if (index !== 0) {
              outcomeArray[index] = null
            }
          })
          break
        default:
          console.log("error")
          break
      }
    })
    return outcomeArray
  }

  const getScheduleItem = (machine, date, isTubes) => {
    const machineFiltered =
      viewType === "Тръби" || viewType === "Краен продукт"
        ? productSchedule[machine]
        : machine

    // viewType === "Тръби"
    //   ? schedule[machine]
    //   : viewType === "Краен продукт"
    //   ? productSchedule[machine]
    //   : machine
    if (!machineFiltered) return []
    const dayFiltered = machineFiltered.filter((item) => {
      if (viewType === "Тръби") {
        let bool = false
        if (
          moment(date, "DD.MM.YYYY").format("DD.MM.YYYY") ===
          moment(item.start).format("DD.MM.YYYY")
        ) {
          bool = true
        } else if (
          moment(date, "DD.MM.YYYY").isBetween(
            moment(item.start),
            moment(item.end)
          )
        ) {
          bool = true
        }
        return bool
      } else if (viewType === "Краен продукт") {
        let bool = false
        if (
          moment(date, "DD.MM.YYYY").format("DD.MM.YYYY") ===
          moment(item.date).format("DD.MM.YYYY")
        ) {
          bool = true
        } else if (
          moment(date, "DD.MM.YYYY").isBetween(
            moment(item.date),
            moment(item.date).add(item.durationMinutes, "minutes")
          )
        ) {
          bool = true
        }
        return bool
      } else {
        let bool = false
        if (
          moment(date, "DD.MM.YYYY").format("DD.MM.YYYY") ===
          moment(item.start).format("DD.MM.YYYY")
        ) {
          bool = true
        } else if (
          moment(date, "DD.MM.YYYY").isBetween(
            moment(item.start),
            moment(item.end)
          )
        ) {
          bool = true
        }
        return bool
      }
    })
    return dayFiltered.filter((item) => {
      return true
    })
  }
  const params = useParams()

  useEffect(() => {
    if (!isEmpty(params)) {
      var date
      const dateArray = params.date.split("-")
      if (params.period === "Дневен") {
        date = moment()
          .set("year", parseInt(dateArray[3]))
          .set("month", parseInt(dateArray[2]) - 1)
          .set("date", parseInt(dateArray[0]))
      } else {
        date = moment()
          .set("year", parseInt(dateArray[3]))
          .set("month", parseInt(dateArray[2]) - 1)
          .set("date", parseInt(dateArray[0]))
      }

      if (
        params.type !== viewType.replace(" ", "-") ||
        params.period !== periodType ||
        params.date !== date.format("DD-DD-MM-YYYY")
      ) {
        if (params.type === "Краен-продукт") {
          setViewType("Краен продукт")
        } else if (params.type === "Тръби") {
          setViewType("Тръби")
        } else if (params.type === "Допълнителни") {
          setViewType("Допълнителни")
        }
        setPeriodType(params.period)
        if (params.period === "Дневен") {
          setWeekDay(date)
        } else {
          setWeek({
            startOfWeek: moment(date).startOf("isoWeek"),
            endOfWeek: moment(date).endOf("isoWeek"),
          })
        }
      }
    }
    //eslint-disable-next-line
  }, [params])

  useEffect(() => {
    getProductTypes()
  }, [getProductTypes])

  useEffect(() => {
    if (periodType === "Дневен") {
      if (viewType === "Тръби") {
        getMachineSchedule({
          start: moment(weekDay).startOf("day"),
          end: moment(weekDay).endOf("day"),
        })
      } else if (viewType === "Краен продукт") {
        getMachineScheduleByPerformer({
          groupBy: "performer",
          start: moment(weekDay).startOf("day"),
          end: moment(weekDay).endOf("day"),
        })
      } else {
        getActionSchedule({
          start: moment(weekDay).startOf("day"),
          end: moment(weekDay).endOf("day"),
        })
      }
    } else {
      if (viewType === "Тръби") {
        getMachineSchedule({
          start: moment(week.startOfWeek).startOf("week"),
          end: moment(week.endOfWeek).endOf("week"),
        })
      } else if (viewType === "Краен продукт") {
        getMachineScheduleByPerformer({
          groupBy: "performer",
          start: moment(week.startOfWeek).startOf("week"),
          end: moment(week.endOfWeek).endOf("week"),
        })
      } else {
        getActionSchedule({
          start: moment(week.startOfWeek).startOf("day"),
          end: moment(week.endOfWeek).endOf("day"),
        })
      }
    }
    //eslint-disable-next-line
  }, [periodType, weekDay, viewType, week])
  let hours = shift === "Дневна смяна" ? hoursArray : nightHoursArray
  return (
    <div className="schedule-container">
      <div className="flex-container space-between">
        <h1>График</h1>
        <div className="input-element">
          <p>{periodType === "Дневен" ? "Ден" : "Седмица"}</p>
          <div className="flex-container fixed-width">
            <div
              className="arrow left"
              onClick={() => {
                if (periodType === "Седмичен") {
                  const newWeek = { ...week }
                  newWeek.startOfWeek.subtract(1, "weeks").startOf("isoWeek")
                  newWeek.endOfWeek.subtract(1, "weeks").endOf("isoWeek")
                  setWeek(newWeek)
                  history.replace({
                    pathname: `/production/${params.type}/${
                      params.period
                    }/${moment(newWeek.startOfWeek).format("DD")}-${moment(
                      newWeek.startOfWeek
                    ).format("DD")}-${moment(newWeek.startOfWeek).format(
                      "MM"
                    )}-${moment(newWeek.startOfWeek).format("YYYY")}`,
                  })
                } else {
                  let newDay = weekDay.clone()
                  newDay.subtract(1, "days")
                  setWeekDay(newDay)
                  setProductionQuery(
                    `/production/${params.type}/${params.period}/${moment(
                      newDay
                    ).format("DD")}-${moment(newDay).format("DD")}-${moment(
                      newDay
                    ).format("MM")}-${moment(newDay).format("YYYY")}`
                  )
                  history.replace({
                    pathname: `/production/${params.type}/${
                      params.period
                    }/${moment(newDay).format("DD")}-${moment(newDay).format(
                      "DD"
                    )}-${moment(newDay).format("MM")}-${moment(newDay).format(
                      "YYYY"
                    )}`,
                  })
                }
              }}
            />
            <span>
              {periodType === "Седмичен" ? (
                <>
                  {moment(daysArray[0]).format("DD")} -{" "}
                  {moment(daysArray[daysArray.length - 1]).format("DD")}{" "}
                  {monthMap[month]}
                </>
              ) : (
                <div className="flex-container">
                  <Input.Datepicker
                    pickDate={moment(weekDay).toDate()}
                    onChange={(date) => {
                      setWeekDay(moment(date))
                      setProductionQuery(
                        `/production/${params.type}/${params.period}/${moment(
                          date
                        ).format("DD")}-${moment(date).format("DD")}-${moment(
                          date
                        ).format("MM")}-${moment(date).format("YYYY")}`
                      )
                      history.replace({
                        pathname: `/production/${params.type}/${
                          params.period
                        }/${moment(date).format("DD")}-${moment(date).format(
                          "DD"
                        )}-${moment(date).format("MM")}-${moment(date).format(
                          "YYYY"
                        )}`,
                      })
                    }}
                  />
                  {moment(weekDay).format("LL")}
                </div>
              )}
            </span>
            <div
              className="arrow right"
              onClick={() => {
                if (periodType === "Седмичен") {
                  const newWeek = { ...week }
                  newWeek.startOfWeek.add(1, "weeks").startOf("isoWeek")
                  newWeek.endOfWeek.add(1, "weeks").endOf("isoWeek")
                  setWeek(newWeek)
                  setProductionQuery(
                    `/production/${params.type}/${params.period}/${moment(
                      newWeek.startOfWeek
                    ).format("DD")}-${moment(newWeek.startOfWeek).format(
                      "DD"
                    )}-${moment(newWeek.startOfWeek).format("MM")}-${moment(
                      newWeek.startOfWeek
                    ).format("YYYY")}`
                  )
                  history.replace({
                    pathname: `/production/${params.type}/${
                      params.period
                    }/${moment(newWeek.startOfWeek).format("DD")}-${moment(
                      newWeek.startOfWeek
                    ).format("DD")}-${moment(newWeek.startOfWeek).format(
                      "MM"
                    )}-${moment(newWeek.startOfWeek).format("YYYY")}`,
                  })
                } else {
                  let newDay = weekDay.clone()
                  newDay.add(1, "days")
                  setWeekDay(newDay)
                  setProductionQuery(
                    `/production/${params.type}/${params.period}/${moment(
                      newDay
                    ).format("DD")}-${moment(newDay).format("DD")}-${moment(
                      newDay
                    ).format("MM")}-${moment(newDay).format("YYYY")}`
                  )
                  history.replace({
                    pathname: `/production/${params.type}/${
                      params.period
                    }/${moment(newDay).format("DD")}-${moment(newDay).format(
                      "DD"
                    )}-${moment(newDay).format("MM")}-${moment(newDay).format(
                      "YYYY"
                    )}`,
                  })
                }
              }}
            />
          </div>
        </div>
        <div className="placeholder" />
      </div>
      <ScheduleTabs
        selected={viewType}
        buttons={["Тръби", "Краен продукт", "Допълнителни"]}
        onChange={(name) => {
          setProductionQuery(
            `/production/${name === "Краен продукт" ? "Краен-продукт" : name}/${
              name === "Краен продукт" ? "Дневен" : params.period
            }/${params.date}`
          )
          history.replace({
            pathname: `/production/${
              name === "Краен продукт" ? "Краен-продукт" : name
            }/${name === "Краен продукт" ? "Дневен" : params.period}/${
              params.date
            }`,
          })
          setViewType(name)
        }}
      />
      <div className="flex-container space-between">
        <ScheduleTabs
          selected={periodType}
          buttons={["Дневен", "Седмичен"]}
          onChange={(name) => {
            setProductionQuery(
              `/production/${params.type}/${name}/${params.date}`
            )
            history.replace({
              pathname: `/production/${params.type}/${name}/${params.date}`,
            })
            setPeriodType(name)
          }}
        />
        {periodType === "Дневен" && (
          <p id="bold">{moment(weekDay).format("DD.MM.YYYY")}</p>
        )}
        {periodType === "Дневен" && (
          <Button.UnderlinedTab
            buttons={["Дневна смяна", "Нощна смяна"]}
            onChange={(name) => setShift(name)}
          />
        )}
      </div>
      <div className="calendar-container">
        <div className="calendar-header">
          <div className="day-container no-border" />
          {renderHeader(
            viewType,
            periodType,
            schedule,
            productSchedule,
            newActionSchedule
          )}
        </div>
        {periodType === "Дневен" ? (
          <div className="flex-container height">
            <div className="flex-container column height border-right">
              {hours.map((hour) => {
                return <div className="hour">{hour}</div>
              })}
            </div>
            <div className="flex-container height width">
              {viewType === "Допълнителни"
                ? Object.keys(newActionSchedule)
                    .sort((a, b) => a.length - b.length)
                    .map((key) => {
                      return (
                        <div className="flex-container column flex1">
                          {setProductionData(
                            weekDay,
                            newActionSchedule[key]
                          ).map((section) => {
                            if (section === "dummy") {
                              return <div className="dummy-flex-element" />
                            } else if (section === null) {
                              return null
                            } else {
                              const startDate = section.date
                                ? section.date
                                : section.start
                                ? section.start
                                : section.startsAt
                              const endDate = section.end
                                ? section.end
                                : moment(startDate).add(
                                    section.durationMinutes,
                                    "minutes"
                                  )
                              let steps
                              if (
                                moment(startDate).isBefore(
                                  moment(weekDay).startOf("day")
                                )
                              ) {
                                steps =
                                  moment(endDate).diff(
                                    moment(endDate)
                                      .set("hour", 7)
                                      .set("minute", 0),
                                    "minutes"
                                  ) / 60
                              } else {
                                steps = section.durationMinutes / 60
                              }
                              if (isNaN(steps)) {
                                steps =
                                  Math.abs(
                                    moment(startDate).diff(
                                      moment(endDate),
                                      "minutes"
                                    )
                                  ) / 60
                              }
                              if (steps > 13) {
                                if (
                                  moment(startDate).isBefore(
                                    moment().startOf("day")
                                  )
                                ) {
                                  const diffFromToday = moment(startDate).diff(
                                    moment(startDate).set("hour", 19),
                                    "hours"
                                  )
                                  steps = 13 - Math.abs(diffFromToday) - 1
                                } else {
                                  steps = 13
                                }
                              }
                              if (steps < 1) {
                                steps = 1
                              }

                              const finished =
                                section.status === "finished" ? true : false
                              return (
                                <ScheduleDailyElement
                                  type={null}
                                  completed={finished}
                                  data={section}
                                  flex={steps}
                                />
                              )
                            }
                          })}
                        </div>
                      )
                    })
                : Object.keys(productSchedule)
                    .sort((a, b) => a.length - b.length)
                    .map((key) => {
                      return (
                        <div className="flex-container column flex1">
                          {setProductionData(weekDay, productSchedule[key]).map(
                            (section) => {
                              if (section === "dummy") {
                                return <div className="dummy-flex-element" />
                              } else if (section === null) {
                                return null
                              } else {
                                const startDate = section.date
                                  ? section.date
                                  : section.start
                                  ? section.start
                                  : section.startsAt
                                const endDate = section.end
                                  ? section.end
                                  : moment(startDate).add(
                                      section.durationMinutes,
                                      "minutes"
                                    )
                                let steps
                                if (
                                  moment(startDate).isBefore(
                                    moment(weekDay).startOf("day")
                                  )
                                ) {
                                  steps =
                                    moment(endDate).diff(
                                      moment(endDate)
                                        .set("hour", 7)
                                        .set("minute", 0),
                                      "minutes"
                                    ) / 60
                                } else {
                                  steps = section.durationMinutes / 60
                                }
                                if (isNaN(steps)) {
                                  steps =
                                    Math.abs(
                                      moment(startDate).diff(
                                        moment(endDate),
                                        "minutes"
                                      )
                                    ) / 60
                                }

                                if (steps > 13) {
                                  if (
                                    moment(startDate).isBefore(
                                      moment(startDate)
                                        .startOf("day")
                                        .set("hour", 7)
                                    )
                                  ) {
                                    const diffFromToday = moment(
                                      startDate
                                    ).diff(
                                      moment(startDate).set("hour", 19),
                                      "hours"
                                    )
                                    steps = 13 - Math.abs(diffFromToday) - 1
                                  } else {
                                    const diffFromToday = moment(
                                      startDate
                                    ).diff(
                                      moment(startDate).set("hour", 7),
                                      "hours"
                                    )
                                    steps = 13 - Math.abs(diffFromToday) - 1
                                  }
                                }
                                if (steps < 1) {
                                  steps = 1
                                }
                                const type = types.find((type) => {
                                  if (section.product) {
                                    return (
                                      type._id ===
                                      section.product.productType._id
                                    )
                                  } else {
                                    return (
                                      type._id ===
                                      section.request.product.productType._id
                                    )
                                  }
                                })
                                const finished = section.state
                                  ? section.state === "finished"
                                  : section.request.status
                                  ? section.request.status === "finished"
                                  : false
                                return (
                                  <ScheduleDailyElement
                                    type={type && type.name}
                                    completed={finished}
                                    data={section}
                                    flex={steps}
                                  />
                                )
                              }
                            }
                          )}
                        </div>
                      )
                    })}
            </div>
          </div>
        ) : (
          daysArray.map((day, index) => {
            return (
              <div className="day-row">
                <div className="day-container">
                  <p>{moment(day).format("DD.MM.YYYY")}</p>
                  <span>{dayMap[index]}</span>
                </div>
                {viewType === "Тръби" ? (
                  <>
                    <div className="row-element">
                      {getScheduleItem(
                        "OD800",
                        moment(day).format("DD.MM.YYYY"),
                        viewType === "Тръби"
                      ).map((item) => {
                        return (
                          <div className="row-info-element red">
                            <Link to={`/production/${item._id}`}>
                              {item.activity}
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                    <div className="row-element">
                      {getScheduleItem(
                        "OD2400",
                        moment(day).format("DD.MM.YYYY"),
                        viewType === "Тръби"
                      ).map((item) => {
                        return (
                          <div className="row-info-element red">
                            <Link to={`/production/${item._id}`}>
                              {item.activity}
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                    <div className="row-element">
                      {getScheduleItem(
                        "OD2000",
                        moment(day).format("DD.MM.YYYY"),
                        viewType === "Тръби"
                      ).map((item) => {
                        return (
                          <div className="row-info-element red">
                            <Link to={`/production/${item._id}`}>
                              {item.activity}
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  </>
                ) : viewType === "Краен продукт" ? (
                  <>
                    {Object.keys(productSchedule).map((key) => {
                      return (
                        <div className="row-element">
                          {getScheduleItem(
                            key,
                            moment(day).format("DD.MM.YYYY"),
                            viewType === "Краен продукт"
                          ).map((item) => {
                            return (
                              <div className="row-info-element red">
                                <Link
                                  to={`/production/${
                                    item.request.scheduled &&
                                    item.request.scheduled._id
                                  }`}
                                >
                                  {item.activity ? item.activity : "Няма име"}
                                </Link>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <div className="row-element">
                    {getScheduleItem(
                      actionSchedule,
                      moment(day).format("DD.MM.YYYY"),
                      viewType === "Краен продукт"
                    ).map((item) => {
                      return (
                        <div className="row-info-element red">
                          <Link to={`/production/${item._id}`}>
                            {item.activity ? item.activity : "Няма име"}
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  productSchedule: state.production.productSchedule,
  types: state.products.types,
  actionSchedule: state.production.actionSchedule,
})

const mapDispatchToProps = (dispatch) => ({
  getMachineSchedule: (payload) => dispatch(getMachineSchedule(payload)),
  getMachineScheduleByPerformer: (payload) =>
    dispatch(getMachineScheduleByPerformer(payload)),
  getProductTypes: (payload) => dispatch(getProductTypes(payload)),
  getActionSchedule: (payload) => dispatch(getActionSchedule(payload)),
  setProductionQuery: (payload) => dispatch(setProductionQuery(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleComponent)
