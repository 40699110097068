import React from "react"
import "./styles.scss"
import { history } from "../../config/stores"
import { useState, useEffect, useRef } from "react"
import { Input, Button, Section } from "@makedonski/admin-ui"
import { getPerformers, getCurrentUser } from "../../actions"
import { CKEditor } from "ckeditor4-react";
import { editorConfig } from "../../config/constants";
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { CheckListPopup } from "../../components/CheckListPopup"
import { createTask, getUsers } from "../../actions"
import "./styles.scss"
import moment from "moment"
import { RadioButton } from "../../components"
import { emit } from "../../utilities/helpers"
import { socket, showModal, hideModal } from "../../actions"
import { nanoid } from 'nanoid'

export default function AddEditTask() {
  let executorsTab = useRef(null)
  let usersVisibilityTab = useRef(null)
  const checkListRef = useRef()
  const checkListVisibilityRef = useRef()
  const dispatch = useDispatch()
  const { _id, categoryId } = useParams()
  const performers = useSelector((state) => state.production.performers)
  const currentUser = useSelector((state) => state.user.currentUser)
  const users = useSelector((state) => state.tasks.users)
  const task = useSelector((state) => state.tasks.task)

  const [ payload, setPayload ] = useState({
    title: '',
    categoryId: '',
    // startDate: null,
    startDate: moment().toDate(),
    endDate: null,
    priority: 'high',
    assignedTo: [],
    description: '',
    visibility: 'public',
    visibilityUsers: []
  })

  const [ isSelected, setIsSelected ] = useState('high')
  const [ isViewTypeSelected, setIsViewTypeSelected ] = useState('public')
  const [ isComponentVisible, setIsComponentVisible ] = useState(true);
  const [ isVisibilityComponentVisible, setIsVisibilityComponentVisible ] = useState(true);
  const [ usersList, setUsersList ] = useState([])
  const [ visibilityUsersList, setVisibilityUsersList ] = useState([])
  const [ edit, setEdit ] = useState(false)
  const [ isCreated, setIsCreated ] = useState(false)

  let isFinished = task?.status === 'completed'

  const filterUsers = () => {

    if (users?.length && currentUser?._id) {
      let newUsers = users.slice()
      let user = users?.find(user => user?._id === currentUser?._id)
      let userIndex = users?.findIndex(user => user?._id === currentUser?._id)
      newUsers.splice(userIndex, 1)
      newUsers.unshift(user)
      newUsers = newUsers?.map(user => user?.fullName)

      if (newUsers.length) return newUsers
    }

    else {
      return []
    }
  }
  
  const handleDeleteTask = (el) => {
    dispatch(showModal({
      type: "deleteModal",
      data: {
        marginTop: "15%",
        onDelete: () => {
        emit(socket, { action: 'task/deleteTask', payload: { taskId: _id } })
        // emit(socket, { action: 'task/getTasks', payload: {} })
        dispatch(hideModal())
        // history.push('/')
        window.close()
      }},
      // hide: () => dispatch(hideModal()),,
      width: "45%"
    }))
  }

  const handleClickOutside = (event) => {
    if (checkListRef.current && !checkListRef.current.contains(event.target)) {
        setIsComponentVisible(false);
    }
    if (checkListVisibilityRef.current && !checkListVisibilityRef.current.contains(event.target)) {
        setIsVisibilityComponentVisible(false);
    }
  };
  
  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  useEffect(() => {
    dispatch(getPerformers())
    // dispatch(getUsers())

    if (socket) {
      emit(socket, { action: 'task/getUsers' })
      if (_id) {
        emit(socket, { action: 'task/getTask', payload: { taskId: _id } })
      }
      else if (categoryId) {
        emit(socket, { action: 'task/getTasks', payload: {} })
      }
    }

    dispatch(getCurrentUser()) 
  }, [socket]);

  useEffect(() => {
    if (Object.values(task || {}).length) setEdit(true)
    else if (!Object.values(task || {}).length) setEdit(false)
  }, [task]);

  // new
  // useEffect(() => {
  //   if (Object.values(task).length && edit) {
  //     let newPayload = {
  //       taskId: task?._id,
  //       title: task?.title,
  //       startDate: moment(task?.startDate),
  //       endDate: moment(task?.endDate),
  //       priority: task?.priority,
  //       assignedTo: task?.assignedTo,
  //       description: task?.description
  //     }

  //     setPayload(newPayload)
  //     setIsSelected(task?.priority)

  //     if (users) {
  //       let newUsersList = usersList.slice();
  //       newUsersList = newPayload.assignedTo.map(el => el.fullName)
  //       setUsersList(newUsersList)
  //     }
  //   }
  // }, [task, edit]);

  // // new
  // useEffect(() => {
  //   if (_id && !edit) {
  //     setPayload({...payload, categoryId: _id})
  //   }
  //   else if (Object.values(payload).length && edit) {
  //     if (users) {
  //       let newUsersList = usersList.slice();
  //       newUsersList = payload.assignedTo.map(el => el.fullName)
  //       setUsersList(newUsersList)
  //     }
  //   }

  //   if (users && Object.values(currentUser)) {
  //     filterUsers()
  //   }
  // }, [_id, edit, users, currentUser]);

  // old
  useEffect(() => {
    if (categoryId && !edit) {
      setPayload({...payload, categoryId: categoryId})
    }
    else if (Object.values(task || {}).length && edit) {
      let newPayload = {
        taskId: task?._id,
        title: task?.title,
        startDate: moment(task?.startDate),
        endDate: moment(task?.endDate),
        priority: task?.priority,
        assignedTo: task?.assignedTo,
        description: task?.description,
        visibility: task?.visibility,
        visibilityUsers: task?.visibilityUsers
      }

      setPayload(newPayload)
      setIsSelected(task?.priority)

      if (users) {
        let newUsersList = usersList.slice();
        newUsersList = newPayload?.assignedTo?.map(el => el.fullName)
        setUsersList(newUsersList)

        let newVisibilityUsersList = visibilityUsersList?.slice();
        newVisibilityUsersList = newPayload.visibilityUsers?.map(el => el.fullName)
        setVisibilityUsersList(newVisibilityUsersList)

        // if (newPayload.visibilityUsers?.length) setIsViewTypeSelected 
      }

      if (newPayload.visibility === 'private') setIsViewTypeSelected('private')
    }

    if (users && Object.values(currentUser)) {
      filterUsers()
    }

    // if (isFinished) {
    //   const newPayload = {...payload}
    //   newPayload.startDate = moment().toDate()
    //   newPayload.endDate = null
    //   setPayload(newPayload)
    // }

    // else if (!id) {
    //   edit = false
    // }

    // dispatch(getPerformers())
  }, [_id, edit, users, currentUser]);

  return (
    <div className={`task-screen-wrapper ${_id ? 'edit' : 'add'}`}>
      <div className="task-screen-inner-wrapper">
        <div className="task-wrapper-top">
          {edit ? <h2>Редакция на задача</h2> : isFinished ? <h2>Отвори задача</h2> : <h2>Нова задача</h2>}
          <div className="buttons-wrapper">
            {edit && <img src={require('../../assets/icons/delete.svg')} className='icon' onClick={handleDeleteTask} />}
            <button className="secondary-bttn" onClick={() => history.goBack()}>Откажи</button>
            {edit ? 
            <button
              disabled={
                !payload?.startDate ||
                !payload?.endDate ||
                !payload?.title ||
                !payload?.assignedTo
              }
              onClick={() => {
                // dispatch(editTask(payload))
                let newPayload = JSON.parse(JSON.stringify(payload))
                if(payload?.visibility === 'private' && !payload?.visibilityUsers?.length) newPayload.visibility = 'public'
                emit(socket, { action: 'task/updateTask', payload: newPayload })
                // history.push('/')
                history.goBack()
                // window.close()
              }}
            >
              Запази
            </button> :
              <button
              className="primary-bttn"
              disabled={
                (!payload?.startDate ||
                !payload?.endDate ||
                !payload?.title ||
                !payload?.assignedTo) || isCreated
              }
              onClick={() => {
                // dispatch(createTask(payload))
                let newPayload = JSON.parse(JSON.stringify(payload))
                if(payload?.visibility === 'private' && !payload?.visibilityUsers?.length) newPayload.visibility = 'public'
                emit(socket, { action: 'task/createTask', payload: newPayload })
                setIsCreated(true)
                // history.push('/')
                window.close()
              }}
            >
              Добави
            </button>}
          </div>
        </div>

        <div className="task-wrapper-main">
          <h3>Основна информация</h3>
          <div className="task-wrapper-main-top">
            <div className="row wide">
              <div className="input-wrapper wide">
                <label htmlFor="activity-input">Заглавие</label>
                <Input.Text
                  value={payload?.title}
                  id="activity-input"
                  onChange={(e) => {
                    setPayload({ ...payload, title: e.target.value })
                  }}
                />
              </div>

              <div className="input-wrapper small">
                <label>Начална дата</label>
                {(edit && Object.keys(payload)?.length) ?
                <Input.Datepicker
                  minDate={moment().toDate()}
                  placeholder={moment(payload?.startDate).format('DD.MM.YYYY')}
                  // pickDate={payload?.startDate}
                  onChange={(e) => {
                    setPayload({ ...payload, startDate: e, endDate: moment(e).startOf("minute").isSame(moment(payload?.endDate).startOf("minute")) ||   moment(e).isAfter(moment(payload?.endDate)) ? null : payload?.endDate})}}
                /> :
                <Input.Datepicker
                  placeholder=""
                  minDate={moment().toDate()}
                  pickDate={payload?.startDate}
                  onChange={(e) => {
                    setPayload({ ...payload, startDate: e, endDate: moment(e).startOf("minute").isSame(moment(payload?.endDate).startOf("minute")) ||   moment(e).isAfter(moment(payload?.endDate)) ? null : payload?.endDate})}}
                />}
              </div>

              
              <div className="input-wrapper small">
                <label>Крайна дата</label>
                {(edit && Object.keys(payload)?.length) ?
                <Input.Datepicker
                disabled={!payload?.startDate}
                minDate={payload?.startDate || moment().toDate()}
                placeholder={moment(payload?.endDate).format('DD.MM.YYYY')}
                onChange={(e) => {
                  setPayload({ ...payload, endDate: moment(e).endOf('day').endOf('hour').toDate() })
                }}
              /> :
              <Input.Datepicker
              placeholder=""
              disabled={!payload?.startDate}
              minDate={payload?.startDate}
              pickDate={payload?.startDate ? payload?.endDate : null}
              onChange={(e) => {
                setPayload({ ...payload, endDate: moment(e).endOf('day').endOf('hour').toDate() })
              }}
              />}
              </div>
            </div>

            <div className="row">

              {/* <div className="input-wrapper">
                <label>Начален час</label>

                {edit ?
                <Input.TimePicker
                placeholder={moment(payload?.startDate).format('HH:mm')}
                disabled={!payload?.startDate}
                minDate={moment().startOf("minute")}
                timeIntervals={30}
                onChange={(e) => {
                  setPayload({ ...payload, startDate: e })
                }}
              /> :
              <Input.TimePicker
              pickDate={payload?.startDate}
              disabled={!payload?.startDate}
              minDate={moment().startOf("minute")}
              placeholder=""
              timeIntervals={30}
              onChange={(e) => {
                setPayload({ ...payload, startDate: e })
              }}
              />}
              </div> */}

              {/* <div className="input-wrapper">
                <label>Краен час</label>
                {edit ?
                <Input.TimePicker
                placeholder={moment(payload?.endDate).format('HH:mm')}
                disabled={!payload?.endDate}
                timeIntervals={30}
                onChange={(e) => {
                  setPayload({ ...payload, endDate: e })
                  // setPayload({...payload, end: moment(payload.end).startOf('day').add(moment(e).minutes(), 'minutes').toDate() })
                }}
              /> :
              <Input.TimePicker
              pickDate={
                payload?.startDate &&
                moment(payload?.endDate)
                  .startOf("minute")
                  .isAfter(moment(payload?.startDate).startOf("minute"))
                  ? payload?.endDate
                  : null
              }
              disabled={!payload?.endDate}
              placeholder=""
              timeIntervals={30}
              onChange={(e) => {
                setPayload({ ...payload, endDate: e })
                // setPayload({...payload, end: moment(payload.end).startOf('day').add(moment(e).minutes(), 'minutes').toDate() })
              }}
            />
                }
              </div> */}

            <div className="contractors-section-wrapper input-wrapper" ref={checkListRef}>
                <label htmlFor="contractor">Изпълнители</label>

                <div className="flex">
                {isComponentVisible ?
                <Section.Popup
                  style={{ width: 25 }}
                  // ref={(ref) => {executorsTab = ref}}
                  ref={(ref) =>  {
                    executorsTab = ref
                    setIsComponentVisible(true)
                  }}
                  content={
                    <CheckListPopup
                      // data={performers}
                      // data={users?.map(user => user?.fullName)}
                      data={filterUsers() || []}
                      handleCheckList={(list) => {
                        let newList = list.slice()
                        newList = newList.map((user, index) => users.find(el => el.fullName === user)._id)
                        setUsersList(list)
                        setPayload({ ...payload, assignedTo: newList })
                      }}
                      onClose={() => executorsTab.hide()}
                      addButtonDisabled={true}
                      usersList={usersList}
                    />
                  }
                >
                  <img
                    src={require("../../assets/icons/light-plus-icon.svg")}
                    alt="+"
                    onClick={() => {
                      executorsTab.show()
                      setIsComponentVisible(true)
                    }}
                  ></img>
                </Section.Popup> :
                <img
                src={require("../../assets/icons/light-plus-icon.svg")}
                alt="+"
                onClick={() => {
                  setIsComponentVisible(true)
                  // executorsTab.show()
                }}
                ></img>}


                {payload?.assignedTo && (
                  <div className="flex scroll no-margin fit">
                    {usersList?.map((performer, index) => {
                       return <div className='single-contractor-img-wrapper' key={`performer-${index}-${performer}`}>
                       <p>{performer}</p>
                       <img src={require('../../assets/icons/remove-btn.svg')} alt='x'
                       onClick={() => {
                      //  let newPayload = JSON.parse(JSON.stringify(payload))
                      if (typeof payload.assignedTo[0] === "string") {
                        let newPayload = {...payload}
                        let userInfo = users.find(user => user.fullName === performer)
                        newPayload.assignedTo = newPayload.assignedTo.filter(el => el !== userInfo._id)
 
                        let newUsersList = usersList.slice();
                        newUsersList = newPayload.assignedTo.map(el => users.find(user => user._id === el).fullName)
                       //  newPayload.startDate = moment(newPayload.startDate).toDate()
                        setPayload(newPayload)
                        setUsersList(newUsersList)
                      }

                      else if (typeof payload.assignedTo[0] === 'object') {
                        let newPayload = {...payload}
                        let userInfo = users.find(user => user.fullName === performer)
                        newPayload.assignedTo = newPayload.assignedTo.filter(el => el._id !== userInfo._id)
 
                        let newUsersList = usersList.slice();
                        newUsersList = newPayload.assignedTo.map(el => users.find(user => user._id === el._id).fullName)
                       //  newPayload.startDate = moment(newPayload.startDate).toDate()
                        setPayload(newPayload)
                        setUsersList(newUsersList)
                      }


                      //  let newPayload = {...payload}
                      //  newPayload.assignedTo = newPayload.assignedTo.filter(el => el !== performer)
                      //  setPayload(newPayload)
                       }}></img>
                     </div>
                    })}
                  </div>
                )}
                </div>

                {/* <Section.Popup
                  style={{ width: 25 }}
                  // ref={(ref) => {executorsTab = ref}}
                  ref={(ref) =>  {
                    executorsTab = ref
                  }}
                  content={
                    <CheckListPopup
                      // data={performers}
                      // data={users?.map(user => user?.fullName)}
                      data={filterUsers() || []}
                      handleCheckList={(list) => {
                        let newList = list.slice()
                        newList = newList.map((user, index) => users.find(el => el.fullName === user)._id)
                        setUsersList(list)
                        setPayload({ ...payload, assignedTo: newList })
                      }}
                      onClose={() => executorsTab.hide()}
                      addButtonDisabled={true}
                      usersList={usersList}
                    />
                  }
                >
                  {!isComponentVisible && 
                  <img
                    src={require("../../assets/icons/light-plus-icon.svg")}
                    alt="+"
                    onClick={() => {
                      executorsTab.show()
                      setIsComponentVisible(true)
                    }}
                  ></img>}
                </Section.Popup>  */}
                                
              </div>

              <div className="input-wrapper radio">
                <label>Приоритет</label>

                <div className="flex last">
                <RadioButton
                     onClick={() => {
                       setIsSelected('low')
                       setPayload({ ...payload, priority: 'low' })
                     }}
                     key='low-priority'
                    //  id='low-priority'
                     id={nanoid()}
                     isSelected={isSelected === 'low'}
                     label='Нисък'
                     value='low-priority'
                     color='green'
                   />
                  <RadioButton
                     onClick={() => {
                      setIsSelected('medium')
                      setPayload({ ...payload, priority: 'medium' })
                    }}
                    key='medium-priority'
                    // id='medium-priority'
                    id={nanoid()}
                    isSelected={isSelected === 'medium'}
                    label='Среден'
                    value='medium-priority'
                    color='yellow'
                   />
                  <RadioButton
                     onClick={() => {
                      setIsSelected('high')
                      setPayload({ ...payload, priority: 'high' })
                    }}
                    key='high-priority'
                    // id='high-priority'
                    id={nanoid()}
                    isSelected={isSelected === 'high'}
                    label='Висок'
                    value='high-priority'
                    color='red'
                   />
                </div>
              </div>

              {/* <div className="input-wrapper radio">
                <label>Видимост</label>

                <div className="flex">
                <RadioButton
                     onClick={() => {
                       setIsViewTypeSelected('public')
                       setPayload({ ...payload, visibility: 'public' })
                     }}
                     key='public'
                    //  id='low-priority'
                     id={nanoid()}
                     isSelected={isViewTypeSelected === 'public'}
                     label='Всички'
                     value='public'
                   />
                  <RadioButton
                     onClick={() => {
                      setIsViewTypeSelected('private')
                      setPayload({ ...payload, visibility: 'private', visibilityUsers: [] })
                    }}
                    key='private'
                    // id='high-priority'
                    id={nanoid()}
                    isSelected={isViewTypeSelected === 'private'}
                    label='Избор'
                    value='private'
                   />
                </div>
              </div> */}

              {/* <div className="contractors-section-wrapper" ref={checkListRef}> */}
                <div className="input-wrapper radio fit last" ref={checkListVisibilityRef}>
                  <label htmlFor="contractor">Видимост</label>
  
                  <div className="flex small">
                    <RadioButton
                         onClick={() => {
                           setIsViewTypeSelected('public')
                           setPayload({ ...payload, visibility: 'public', visibilityUsers: [] })
                           setVisibilityUsersList([])
                         }}
                         key='public'
                         id={nanoid()}
                         isSelected={isViewTypeSelected === 'public'}
                         label='Всички'
                         value='public'
                       />
                    <RadioButton
                       onClick={() => {
                        setIsViewTypeSelected('private')
                        setPayload({ ...payload, visibility: 'private' })
                      }}
                      key='private'
                      id={nanoid()}
                      isSelected={isViewTypeSelected === 'private'}
                      label='Избор'
                      value='private'
                     />

              {(isVisibilityComponentVisible && isViewTypeSelected === 'private') ?
                <Section.Popup
                  style={{ width: 25 }}
                  // ref={(ref) => {executorsTab = ref}}
                  ref={(ref) =>  {
                    usersVisibilityTab = ref
                    setIsVisibilityComponentVisible(true)
                  }}
                  content={
                    <CheckListPopup
                      data={filterUsers() || []}
                      handleCheckList={(list) => {
                        let newList = list.slice()
                        newList = newList?.map((user, index) => users.find(el => el.fullName === user)._id)
                        setVisibilityUsersList(list)
                        setPayload({ ...payload, visibilityUsers: newList })
                      }}
                      onClose={() => usersVisibilityTab.hide()}
                      addButtonDisabled={true}
                      usersList={visibilityUsersList}
                      type='visibility'
                    />
                  }
                >
                  <img
                    className="margin-top"
                    src={require("../../assets/icons/light-plus-icon.svg")}
                    alt="+"
                    onClick={() => {
                      usersVisibilityTab.show()
                      setIsVisibilityComponentVisible(true)
                    }}
                  ></img>
                </Section.Popup> :
                isViewTypeSelected === 'private' ?
                <img
                className="margin-top"
                src={require("../../assets/icons/light-plus-icon.svg")}
                alt="+"
                onClick={() => {
                  setIsVisibilityComponentVisible(true)
                  // usersVisibilityTab.show()
                }}
                ></img> : <></>}

                {(payload?.visibilityUsers && isViewTypeSelected) && (
                  <div className="flex scroll no-margin fit">
                    {visibilityUsersList?.map((performer, index) => {
                       return <div className='single-contractor-img-wrapper no-margin' key={`performer-${index}-${performer}`}>
                       <p>{performer}</p>
                       <img src={require('../../assets/icons/remove-btn.svg')} alt='x'
                       onClick={() => {
                      if (typeof payload.visibilityUsers[0] === "string") {
                        let newPayload = {...payload}
                        let userInfo = users.find(user => user.fullName === performer)
                        newPayload.visibilityUsers = newPayload.visibilityUsers.filter(el => el !== userInfo._id)
 
                        let newUsersList = visibilityUsersList.slice();
                        newUsersList = newPayload.visibilityUsers?.map(el => users.find(user => user._id === el).fullName)
                        setPayload(newPayload)
                        setVisibilityUsersList(newUsersList)
                      }

                      else if (typeof payload.visibilityUsers[0] === 'object') {
                        let newPayload = {...payload}
                        let userInfo = users.find(user => user.fullName === performer)
                        newPayload.visibilityUsers = newPayload.visibilityUsers.filter(el => el._id !== userInfo._id)
 
                        let newUsersList = visibilityUsersList.slice();
                        newUsersList = newPayload.visibilityUsers?.map(el => users.find(user => user._id === el._id).fullName)
                        setPayload(newPayload)
                        setVisibilityUsersList(newUsersList)
                      }
                       }}></img>
                     </div>
                    })}
                  </div>
                )}
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>

          <h3>Кратко описание</h3>
          <div className="task-wrapper-main-bottom">
            {(payload?.description && edit) && <CKEditor
            style={{ boxShadow: `0 0 2px #909090`, borderColor: 'transparent', width: '100%', height: '37vh', display: !edit ? 'none' : '' } }
            initData={payload?.description || ''}
            config={editorConfig}
            id='textarea_bg'
            onChange={(e) => {
              setPayload((payload) => ({ ...payload, description: e.editor.getData() }))
              }} />}

            {!edit && <CKEditor
            style={{ boxShadow: `0 0 2px #909090`, borderColor: 'transparent', width: '100%', height: '37vh', display: edit ? 'none' : '' } }
            initData={payload?.description || ''}
            config={editorConfig}
            id='textarea_bg'
            onChange={(e) => {
              setPayload((payload) => ({ ...payload, description: e.editor.getData() }))
              }} />}
            </div>
        </div>
      </div>
    </div>
  )
}
