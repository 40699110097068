import { machineSettingTypes } from "../actions"

const initialState = {
  machineSettings: {},
  selectedMachine: localStorage.getItem('machine'),
  // selectedMachine: "OD800",
  specificSettings: [],
}

const deleteMachineSetting = (state, payload) => {
  const newMachineSettings = { ...state.machineSettings }
  const index = newMachineSettings[state.selectedMachine].findIndex(
    (el) => el._id === payload._id
  )
  if (index !== -1) {
    newMachineSettings[state.selectedMachine].splice(index, 1)
  }
  return newMachineSettings
}

const editMachineSettings = (state, payload) => {
  const newMachineSettings = { ...state.machineSettings }
  const index = newMachineSettings[state.selectedMachine].findIndex(
    (el) => el._id === payload._id
  )
  newMachineSettings[state.selectedMachine][index] = payload
  return newMachineSettings
}

const machineSettings = (state = initialState, { type, payload }) => {
  switch (type) {
    case machineSettingTypes.GET_MACHINE_SETTINGS_SUCCESS:
      return { ...state, machineSettings: payload }
    case machineSettingTypes.DELETE_MACHINE_SETTINGS_SUCCESS:
      return {
        ...state,
        machineSettings: deleteMachineSetting(state, payload),
      }
    case machineSettingTypes.SET_SELECTED_MACHINE:
      return { ...state, selectedMachine: payload }
    case machineSettingTypes.EDIT_MACHINE_SETTINGS:
      return { ...state, machineSettings: editMachineSettings(state, payload) }
    case machineSettingTypes.GET_SPECIFIC_SETTINGS_SUCCESS:
      return { ...state, specificSettings: payload }
    default:
      return state
  }
}

export default machineSettings
