import React from "react"
import { connect } from "react-redux"
import { hideModal } from "../../../actions"
import "./styles.scss"

function ProfileInfo({ hideModal, data }) {
  return (
    <div className="notification-modal-container">
      <div className="notification-modal-top-container">
        <h2>Subject: {data.subject}</h2>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <div className="client-info">
        <div className="client-info-email">
          <p>{data.clientName}</p>
          <span>
            {"<"}
            {data.clientEmail}
            {">"}
          </span>
        </div>
        <span>{data.createdAt}</span>
      </div>
      <p>{data.subjectText}</p>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo)
