import React from "react"
import { connect } from "react-redux"
import { hideModal } from "../../../actions"
import moment from "moment"
import "./styles.scss"

function historyModal({ hide, data }) {
  return (
    <div className="history-modal-container">
      <div className="top-container">
        <h3>{data.title}</h3>
        <div className="close-btn" onClick={hide} />
      </div>
      <div className="scroll-container">
        {data.history.map((el) => {
          return (
            <div className="history-element">
              <div className="dot-container">
                <div className="dot" />
                <p>{moment(el.createdAt).format("MMMM Do YYYY, HH:mm")}</p>
              </div>
              <div className="inner-history-element">
                <div className="flex-container">
                  <p>Събитие: </p>
                  <p id="bold">{el.title}</p>
                </div>
                {el.data && (
                  <div className="flex-container">
                    <p>{Object.keys(el.data)[1]}: </p>
                    <p id="bold">{Object.values(el.data)[1]}</p>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(historyModal)
