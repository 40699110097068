import React, { useEffect, useMemo, useState } from "react"
import { Button } from "@makedonski/admin-ui"
import "./styles.scss"
import { TextField, Button as MuiButton, Radio, Checkbox } from "@mui/material"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { useSelector, useDispatch } from "react-redux"
import { getInquiryStatuses } from "../../actions"

const mapStatuses = {
  projection: "Проектиране",
  "choosing-provider": "Избор на доставчик",
  auction: "Търг за изпълнители",
  lost: "Загубен",
  finished: "Приключил",
  other: "Друго",
}

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

function ProjectStatusPopup({
  onClose,
  onUpdate,
  status,
  onCreate,
  additionalStatuses,
}) {
  const [extraStatuses, setExtraStatuses] = useState([])
  const [globalStatuses, setGlobalStatuses] = useState([])
  const dispatch = useDispatch()
  const statuses = useSelector(state => state.inquiries.statuses)

  useEffect(() => {
    if (status !== "") {
      if (mapStatuses[status]) {
        status = mapStatuses[status]
      }
      let isInitialStatus = [].concat(status, additionalStatuses)
      setExtraStatuses(isInitialStatus)
    } else {
      setExtraStatuses(additionalStatuses)
    }
  }, [additionalStatuses, status])

  useEffect(() => {
    if (statuses.length) {
      let newStatuses = statuses.slice();
      if (statuses[0]?.name) {
        newStatuses = newStatuses.map(status => status.name)
        setGlobalStatuses(newStatuses)
      }
    }
  }, [statuses])

  useEffect(() => {
    dispatch(getInquiryStatuses())
  }, [])

  const [newStatus, setNewStatus] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault()
    onCreate(newStatus)
    setAddNewTrigger(false)
    setNewStatus("")
  }
  const handleOnChange = (e) => {
    let occurence = extraStatuses.find((stat) => stat === e.target.value)
    if (occurence) {
      setExtraStatuses((prevStatuses) => {
        return prevStatuses.filter((stat) => stat !== e.target.value)
      })
    } else {
      setExtraStatuses((prevStatuses) => {
        return [...prevStatuses, e.target.value]
      })
    }
  }
  const [currentColour, setCurrentColour] = useState("")
  const [addNewTrigger, setAddNewTrigger] = useState(false)

  return (
    <div className="project-status-popup-container">
      <div className="top-project-status-container">
        <h4>Избор на статус</h4>
        <div className="exit-btn" onClick={onClose} />
      </div>
      <div className="project-status-main-container">
        {/* {Object.keys(mapStatuses).map((mappedStatus) => ( */}
        {globalStatuses?.map((mappedStatus) => (
          <div
            className="colour-container"
            onClick={() => setCurrentColour("lost")}
          >
            <div className="colour-bar" style={{ backgroundColor: "#26ACE2" }}>
              <p>{mapStatuses[mappedStatus] || mappedStatus}</p>
            </div>
            <Checkbox
              sx={radioStyles}
              // checked={currentStatus === mappedStatus}
              value={mappedStatus}
              onChange={handleOnChange}
              checked={extraStatuses.includes(mappedStatus) ? true : false}
            />
          </div>
        ))}
        {extraStatuses.map((stat, index) => (
          <>
            {/* {!Object.keys(mapStatuses).includes(stat) && ( */}
            {!globalStatuses.includes(stat) && (
              <div
                key={index}
                className="colour-container"
                onClick={() => setCurrentColour("lost")}
              >
                <div
                  className="colour-bar"
                  style={{ backgroundColor: "#26ACE2" }}
                >
                  <p>{stat}</p>
                </div>
                <Checkbox
                  sx={radioStyles}
                  // checked={currentStatus === status}
                  value={stat}
                  onChange={handleOnChange}
                  defaultChecked
                />
              </div>
            )}
          </>
        ))}
      </div>
      <div className="bottom-project-status-container">
        {addNewTrigger === false ? (
          <>
            <Button.Raised
              text="Добави нов"
              onClick={() => {
                setAddNewTrigger(true)
              }}
            />
            <MuiButton
              variant="contained"
              onClick={() => {
                if (extraStatuses.length > 0) {
                  const preFlightCurrStatus = extraStatuses[0]
                  const preFlightAdditionalStatuses = extraStatuses.slice(1)
                  onUpdate(preFlightCurrStatus, preFlightAdditionalStatuses)
                } else {
                  onUpdate("", [])
                }
              }}
              style={{ textTransform: "none" }}
            >
              Запази промените
            </MuiButton>
          </>
        ) : (
          <>
            <TextField
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              size="small"
              variant="outlined"
              placeholder="Заглавие на статус"
              style={{ padding: "10px 0" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <CheckCircleRoundedIcon
                onClick={(e) => {
                  handleSubmit(e)
                }}
                style={{ color: "#25E083", cursor: "pointer" }}
              />
              <CloseRoundedIcon
                style={{ color: "#FF7590", cursor: "pointer" }}
                onClick={() => setAddNewTrigger(false)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ProjectStatusPopup
