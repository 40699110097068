export const handleUpdate = (values, docs, currentPage, setCurrentPage, innerLoading, setInnerLoading, fetch, payload) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.998 && docs.totalPages >= currentPage && !innerLoading) {
      setInnerLoading(true);
      fetch(payload);
      setCurrentPage(currentPage + 1);
    }
  };
  