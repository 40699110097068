import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { ProvidersTypes, GeneralTypes, getProvidersSuccess } from "../actions"
import { API_URL } from "../config/settings"

export const getProvidersEpic = (action$) => {
  return action$.pipe(
    ofType(ProvidersTypes.GET_PROVIDERS),
    switchMap(() => {
      return Api.get(`${API_URL}/providers`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(getProvidersSuccess(response.providers))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
