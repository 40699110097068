import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { CommentTypes, GeneralTypes } from "../actions"
import { API_URL } from "../config/settings"

export const addCommentEpic = (action$) => {
  return action$.pipe(
    ofType(CommentTypes.ADD_GENERAL_COMMENT),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/comments`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: CommentTypes.ADD_GENERAL_COMMENT_SUCCESS,
              payload: response.comment,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editCommentEpic = (action$) => {
  return action$.pipe(
    ofType(CommentTypes.EDIT_GENERAL_COMMENT),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/comments`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: CommentTypes.EDIT_GENERAL_COMMENT_SUCCESS,
              payload: response.comment,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteCommentEpic = (action$) => {
  return action$.pipe(
    ofType(CommentTypes.DELETE_GENERAL_COMMENT),
    switchMap(({ payload }) => {
      return Api.delete(`${API_URL}/comments`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: CommentTypes.DELETE_GENERAL_COMMENT_SUCCESS,
              payload: response.comment,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
