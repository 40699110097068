import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { Icon } from "@makedonski/admin-ui";
import Files from "../../../utilities/files"
// import moment from 'moment'
import { QuantityDropdownInput, SingleRadioButton } from "../../../components"
import {
  hideModal,
  getInquiries,
  postProductionRequest,
  getPerformers,
  addOtherAction,
} from "../../../actions"
import moment from "moment"
import "./styles.scss"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

const ProductionRequestModal = ({
  hide,
  data,
  getList,
  inquiries,
  postProductionRequestAction,
  getPerformers,
  addOtherAction,
  performers,
}) => {
  const [focusIndex, setFocusIndex] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [query, setQuery] = useState({
    product: "",
    inquiry: "",
    quantity: "",
    deadline: "",
    comment: "",
    drawing: {}
  })
  const [onFocus, setOnFocus] = useState(false)
  const [ file, setFile ] = useState({ name: '', url: '' })
  const [filter, setFilter] = useState("")
  const [typeOfQuery, setTypeOfQuery] = useState(
    "Продукт (Тръба / Краен продукт)"
  )

  const [otherActionPayload, setOtherActionPayload] = useState({
    activity: "",
    description: "",
    start: moment().add(1, "h").startOf("hour").toDate(),
    end: moment().add(1, "day").add(1, "h").startOf("hour").toDate(),
    performers: [""],
    drawing: {}
  })

  const updateQuery = (key, value) => {
    const newQ = { ...query }
    newQ[key] = value
    setQuery(newQ)
  }

  const [chosenProject, setChosenProject] = useState({ object: "" })
  const [quantity, setQuantity] = useState(0)
  useEffect(() => {
    getList()
  }, [getList])

  useEffect(() => {
    if (data) {
      setChosenProject(data.project)
    }
  }, [data])

  useEffect(() => {
    if (data && chosenProject) {
      updateQuery("inquiry", data.project._id)
    }
    //eslint-disable-next-line
  }, [chosenProject])

  useEffect(() => {
    if (data && chosenProject && query.inquiry) {
      updateQuery("product", data.product._id)
    }
    //eslint-disable-next-line
  }, [chosenProject])

  useEffect(() => {
    getPerformers({ search: "" })
  }, [getPerformers])

  const availableProducts = React.useMemo(() => {
    if (chosenProject.object === "") return []
    return chosenProject.orders
      .map((el) => el.product)
      .filter((el) => el !== null)
  }, [chosenProject])

  const productVariation = (item) => {
    return item.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")
  }
  const product = availableProducts.find((el) => el._id === query.product)

  return (
    <div className="production-request-modal">
      <div className="top-container">
        <h1>Нова заявка</h1>
        <div className="exit-btn" onClick={hide} />
      </div>
      <div className="upper-elements">
        <p>Заявка от</p>
        <div className="flex-container">
          <SingleRadioButton
            selected={typeOfQuery === "Продукт (Тръба / Краен продукт)"}
            label="Продукт (Тръба / Краен продукт)"
            onClick={(value) => setTypeOfQuery(value)}
          />
          <SingleRadioButton
            selected={typeOfQuery === "Друга дейност"}
            label="Друга дейност"
            onClick={(value) => setTypeOfQuery(value)}
          />
        </div>
        {typeOfQuery === "Друга дейност" ? (
          <>
            <p>Дейност</p>
            <Input.Text
              value={otherActionPayload.activity}
              onChange={(event) => {
                const newActionPayload = { ...otherActionPayload }
                newActionPayload.activity = event.target.value
                setOtherActionPayload(newActionPayload)
              }}
            />
            <p>Описание</p>
            <Input.Text
              value={otherActionPayload.description}
              onChange={(event) => {
                const newActionPayload = { ...otherActionPayload }
                newActionPayload.description = event.target.value
                setOtherActionPayload(newActionPayload)
              }}
            />

          <p>Чертеж</p>
            {file?.name ?
            <div className='file-wrapper'>
               <div className='file-name'>{file?.name || ''}</div>
               <div className="delete-icon" onClick={() => setFile({})} />
            </div> :
              <div className="input-file-control">
              Добави файл
              <Icon name="chat---add-file" size="14px" color="#7E7E7E" />
              <input
                type="file"
                // ref={drawing}
                accept="*"
                onChange={(e) => {
                  const { name } = e.target.files[0]
                  Files.uploadFile(e.target.files[0]).then((result) => {
                    setFile({ ...file, name, url: result.location })
                    setOtherActionPayload({...otherActionPayload, drawing: { ...file, name, url: result.location }})
                  })
                }}
              />
            </div>}

            <div className="flex-container">
              <div className="input-element">
                <p>Начало</p>
                <Input.Datepicker
                  placeholder="Дата"
                  pickDate={otherActionPayload.start}
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.start = date
                    newActionPayload.end = date
                    setOtherActionPayload(newActionPayload)
                  }}
                />
              </div>
              <div className="input-element">
                <p>Начален час</p>
                <Input.Datepicker
                  placeholder="Час"
                  pickDate={otherActionPayload.start}
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.start = date
                    newActionPayload.end = date
                    setOtherActionPayload(newActionPayload)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Час"
                  dateFormat="HH:mm"
                />
              </div>
            </div>
            <div className="flex-container">
              <div className="input-element">
                <p>Крайна дата</p>
                <Input.Datepicker
                  minDate={otherActionPayload.start}
                  placeholder="Дата"
                  pickDate={otherActionPayload.end}
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.end = date
                    setOtherActionPayload(newActionPayload)
                  }}
                />
              </div>
              <div className="input-element">
                <p>Краен час</p>
                <Input.Datepicker
                  placeholder="Час"
                  pickDate={otherActionPayload.end}
                  onChange={(date) => {
                    const newActionPayload = { ...otherActionPayload }
                    newActionPayload.end = date
                    setOtherActionPayload(newActionPayload)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Час"
                  dateFormat="HH:mm"
                />
              </div>
            </div>
            <p>Изпълнители</p>
            <div className="flex-container">
              <Button.Icon
                name="plus"
                size={14}
                color="blue"
                onClick={() => {
                  const newActionPayload = { ...otherActionPayload }
                  newActionPayload.performers.push("")
                  setOtherActionPayload(newActionPayload)
                }}
              />
              <div className="horizontal-scroll">
                {otherActionPayload.performers.map((performer, index) => {
                  return (
                    <div className="performers-wrapper">
                      <Input.Text
                        onFocus={() => setFocusIndex(index)}
                        onBlur={() => {
                          setTimeout(() => {
                            setFocusIndex(null)
                          }, 200)
                        }}
                        value={performer}
                        onChange={(event) => {
                          const newActionPayload = { ...otherActionPayload }
                          newActionPayload.performers[index] =
                            event.target.value
                          setOtherActionPayload(newActionPayload)
                        }}
                      />
                      {focusIndex === index && (
                        <div className="perfomers-container">
                          {performers
                            .filter((el) =>
                              el
                                .toLowerCase()
                                .includes(
                                  otherActionPayload.performers[
                                    index
                                  ].toLowerCase()
                                )
                            )
                            .map((performer) => {
                              return (
                                <div
                                  className="performer"
                                  onClick={() => {
                                    const newActionPayload = {
                                      ...otherActionPayload,
                                    }
                                    newActionPayload.performers[
                                      index
                                    ] = performer
                                    setOtherActionPayload(newActionPayload)
                                    setFocusIndex(null)
                                  }}
                                >
                                  {performer}
                                </div>
                              )
                            })}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <p>Проект</p>
            <div className="autocomplete-wrapper">
              <input
                type="text"
                value={filter ? filter : chosenProject.object}
                onChange={(event) => {
                  setChosenProject({ object: "" })
                  setFilter(event.target.value)
                }}
                onFocus={() => setOnFocus(true)}
                onBlur={() => {
                  setTimeout(() => setOnFocus(false), 200)
                }}
              />
              <div className={`projects-dropdown ${onFocus && "visible"}`}>
                {inquiries
                  .filter((inquiry) =>
                    inquiry.object.toLowerCase().includes(filter.toLowerCase())
                  )
                  .map((inquiry) => {
                    return (
                      <div
                        className="project-option"
                        onClick={() => {
                          setFilter("")
                          updateQuery("inquiry", inquiry._id)
                          setChosenProject(inquiry)
                        }}
                      >
                        {inquiry.object}
                      </div>
                    )
                  })}
              </div>
            </div>
            <p>Продукт</p>
            <Input.Dropdown
              disabled={query.inquiry === ""}
              value={query.product}
              options={availableProducts
                // .filter((el) => el.expenses)
                .map((product) => {
                  if (product.productType.name === "Тръба") {
                    const type = product.basicInfo.find(
                      (el) => el.name === "Произвежда се по"
                    )
                    const SN = product.basicInfo.find(
                      (el) => el.name === "Коравина"
                    )
                    let name
                    if (type.value === "Вътрешен диаметър") {
                      const ID = product.basicInfo.find(
                        (el) => el.name === "Вътрешен диаметър"
                      )
                      name = `SN${SN?.value}ID${ID?.value}`
                    } else {
                      const OD = product.basicInfo.find(
                        (el) => el.name === "Външен диаметър"
                      )
                      name = `SN${SN?.value}OD${OD?.value}`
                    }
                    return {
                      label: name,
                      value: product._id,
                    }
                  } else {
                    return {
                      label: `${product.productType.name}_${productVariation(
                        product
                      )}`,
                      value: product._id,
                    }
                  }
                })}
              onChange={({ value }) => {
                updateQuery("product", value)
              }}
            />
            <span>
              *продуктът не се показва, ако няма зададена себестойност.
            </span>

          <p>Чертеж</p>
            {file?.name ?
            <div className='file-wrapper'>
               <div className='file-name'>{file?.name || ''}</div>
               <div className="delete-icon" onClick={() => setFile({})} />
            </div> :
              <div className="input-file-control">
              Добави файл
              <Icon name="chat---add-file" size="14px" color="#7E7E7E" />
              <input
                type="file"
                // ref={drawing}
                accept="*"
                onChange={(e) => {
                  const { name } = e.target.files[0]
                  Files.uploadFile(e.target.files[0]).then((result) => {
                    setFile({ ...file, name, url: result.location })
                    updateQuery("drawing", { ...file, name, url: result.location })
                  })
                }}
              />
            </div>}

            <div className="flex-container border">
              <div className="input-element">
                <p>К-во</p>
                <QuantityDropdownInput
                  quantityTypeValue={{
                    value: product ? product.mainUnit : "бр",
                  }}
                  noDropdown
                  quantityValue={quantity}
                  onSetQuantity={(value) => {
                    setQuantity(value)
                    updateQuery("quantity", value)
                  }}
                />
              </div>
              <div className="input-element">
                <p>Срок за производство</p>
                <Input.Datepicker
                  placeholder="-/-/-"
                  pickDate={dueDate}
                  onChange={(date) => {
                    setDueDate(date)
                    updateQuery("deadline", date)
                  }}
                />
              </div>
            </div>
            <p>Коментар</p>
            <Input.Textarea
              onChange={(e) => {
                updateQuery("comment", e.target.value)
              }}
            />
          </>
        )}
      </div>
      <Button.Raised
        onClick={() => {
          if (typeOfQuery === "Друга дейност") {
            const valueCheck = Object.values(otherActionPayload).filter(value => value !== 'drawing').map(
              (value) => {
                if (!value) {
                  return false
                } else {
                  return true
                }
              }
            )
            if (
              !otherActionPayload.performers[0] ||
              valueCheck.findIndex((el) => !el) !== -1
            ) {
              Alerts.error("Грешка", "Моля попълнете полетата!")
            } else {
              addOtherAction(otherActionPayload)
            }
          } else {
            postProductionRequestAction(query)
          }
        }}
        text={
          typeOfQuery === "Друга дейност" ? "Добави в график" : "Добави заявка"
        }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  inquiries: state.inquiries.data,
  performers: state.production.performers,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  getList: () => dispatch(getInquiries()),
  postProductionRequestAction: (payload) =>
    dispatch(postProductionRequest(payload)),
  getPerformers: (payload) => dispatch(getPerformers(payload)),
  addOtherAction: (payload) => dispatch(addOtherAction(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionRequestModal)
