import React, { useRef } from 'react'
import './Daily.scss'
import moment from 'moment'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getProductName, getProductData } from '../../../utilities/helpers'
import '../WeeklyMonthly/WeeklyMonthly.scss'

export default function ScheduleDailyOrderColumn ({ ordersColumnData, onClick, schedulePopup }) {
  const taskContainer = useRef()
  const requestData = useSelector((state) => state.production.productSchedule)

  const start = ordersColumnData.start ? ordersColumnData.start : ordersColumnData.startsAt
  const end = ordersColumnData.end ? ordersColumnData.end : moment(start).add(ordersColumnData.durationMinutes, 'minutes')
  const basicInfo = ordersColumnData?.product?.basicInfo ? ordersColumnData?.product.basicInfo : ordersColumnData.request?.product.basicInfo
  const quantity = ordersColumnData?.quantity ? ordersColumnData.quantity : ordersColumnData.request?.quantity
  const mainUnit = ordersColumnData?.product?.mainUnit ? ordersColumnData?.product?.mainUnit : ordersColumnData?.request?.product?.mainUnit
  const name = ordersColumnData?.product?.productType.name ? ordersColumnData.product.productType.name : ordersColumnData.request?.product?.productType.name
  const project = ordersColumnData?.inquiry?.object ? ordersColumnData.inquiry.object : ordersColumnData.request?.inquiry.object

  let heightValue =
  ((moment(end).clone().hours() + moment(end).clone().minutes() / 60) -
  (moment(start).clone().hours() + moment(start).clone().minutes() / 60)) * 56

  let heightPopupValue =
  ((moment(end).clone().hours() + moment(end).clone().minutes() / 60) -
  (moment(start).clone().hours() + moment(start).clone().minutes() / 60)) * 43.5  //21.6

  let height = schedulePopup ? heightPopupValue : heightValue

  return (
    <div
      className={`single-daily-order-wrapper ${(ordersColumnData?.request?.status === 'finished' || ordersColumnData?.state === 'finished' || ordersColumnData?.status === 'finished') ? 'finished' : ''}`}
      key={`daily-schedule-key-container-${ordersColumnData._id}`} ref={taskContainer}
      style={{ height: height,
               padding: (schedulePopup ? heightValue < 56 : heightPopupValue < 43.5) ? '0 1em' : '1.5em 1em',
               borderLeft: (ordersColumnData?.isDelayed && ordersColumnData?.state !== 'finished') ? '10px solid #FF7590' : '10px solid #25E083' }}
      onClick={() => onClick && onClick()}
    >
      {(ordersColumnData?.isDelayed || ordersColumnData?.comment) && 
      <div className='icons-wrapper'>
         {ordersColumnData?.isDelayed &&
         !(ordersColumnData?.request?.status === 'finished' || ordersColumnData?.state === 'finished' || ordersColumnData?.status === 'finished') &&
         <img src={require('../../../assets/icons/swap.svg')} className='icon revert' />}
         {ordersColumnData?.comment && 
         <>
         {(ordersColumnData?.request?.status === 'finished' || ordersColumnData?.state === 'finished' || ordersColumnData?.status === 'finished') ?
          <img src={require('../../../assets/icons/comment-white.svg')} className='icon comment' /> :
          <img src={require('../../../assets/icons/comment-black.svg')} className='icon comment' />}
          
          <p className={`${(ordersColumnData?.request?.status === 'finished' || ordersColumnData?.state === 'finished' || ordersColumnData?.status === 'finished') ? 'white' : ''}`}>1</p>
         </>}
      </div>}
     
      {!ordersColumnData?.product?.basicInfo && !project || ordersColumnData?.state ?
        <>
            {quantity ?
            <h3><span>{ordersColumnData?.basicInfo ? (basicInfo[0]?.value || '') : ordersColumnData?.activity}</span><span>{` - ${quantity} ${mainUnit}`}</span></h3>:
            <h3><span>{ordersColumnData?.basicInfo ? (basicInfo[0]?.value || '') : ordersColumnData?.activity}</span></h3>}
            <h3>{ordersColumnData?.description}</h3>
           {/* {ordersColumnData?.produce && <h3>{`Изработени: ${ordersColumnData?.produce} ${mainUnit}`}</h3>} */}
       </> :
        <>
      {quantity ?
      <h3>{`${getProductName(basicInfo) || ''} - ${quantity} ${mainUnit}`}</h3> :
      <h3>{`${getProductName(basicInfo) || ''}`}</h3>}

      {(schedulePopup ? heightValue > 56 : heightPopupValue > 43.5) && <h3>Проект: <span>{project}</span></h3>}
      {(schedulePopup ? heightValue > 112 : heightPopupValue > 87) && <h3>Продукт: <span>{name}</span></h3>}
        </>
      }
    </div>
  )
}
