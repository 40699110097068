import React from "react"
import { Input, Icon } from "@makedonski/admin-ui"

const IconButton = ({
  name,
  background = "#26ace2",
  onClick,
  size = "16px",
}) => {
  return (
    <div
      className="icon-cicle-button riple"
      style={{ backgroundColor: background }}
      onClick={onClick}
    >
      <Icon name={name} size={size} color="white" />
    </div>
  )
}

const ProductListItem = ({ item, index, onUpdate, onRemove }) => {
  const linkRef = React.useRef(null)
  return (
    <div className="product-element">
      <a
        ref={linkRef}
        href={`/products/${item._id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        a
      </a>
      <div className="left-side">
        <span>
          {item.name}{" "}
          <IconButton
            onClick={() => {
              linkRef.current.click()
            }}
            name="angle-right"
          />{" "}
          <IconButton
            onClick={() => {
              onRemove && onRemove(index)
            }}
            size="12px"
            name="trash-empty"
            background="#e74c3c"
          />
        </span>
        <p>{item.variation}</p>
      </div>
      <div className="mid-side">
        <span>К-во</span>
        <Input.Text
          value={item.amount}
          onChange={(e) => {
            if (onUpdate) {
              onUpdate("amount", e.target.value, index)
            }
          }}
        />
      </div>
      <div className="right-side">
        <div className="deadline-container">
          <span>Срок за доставка</span>
          <Input.Datepicker
            placeholder="Дата"
            pickDate={item.deliveryDate}
            onChange={(date) => {
              if (onUpdate) {
                onUpdate("deliveryDate", date, index)
              }
            }}
          />
        </div>
      </div>
      {/* <div
        className="delete-btn"
        onClick={() => {
          if (onRemove) onRemove(index)
        }}
      /> */}
    </div>
  )
}

export default ProductListItem
