import React from "react"
import moment from "moment"
import "./styles.scss"

const StorageAvailableProduct = ({
  checked,
  onCheck,
  product,
  onViewHistory,
}) => {
  const totalQuantity =
    product.quantity + product.defectives + product.exported + product.samples
  return (
    <div
      className={`storage-available-product-container ${checked && "checked"} ${
        product.quantity < product.criticalQuantity && "red-border"
      }`}
    >
      <div className="element type">
        <div className="flex-container">
          <p id="bold">{product.type}</p>
          {/* <div className="arrow-right" /> */}
        </div>
        <p>{product.variation}</p>
      </div>
      <div className="element quantity">
        {product.quantity}
        {product.units} ({totalQuantity}
        {product.units})
      </div>
      <div className="element storage">
        {moment(product.createdAt).format("DD.MM.YYYY")}г.
      </div>
      <div className="element small">
        {product.pricing.singleUnitPrice &&
          product.pricing.singleUnitPrice.toFixed(2)}
        лв./
        {product.units}
      </div>
      <div className="element small">
        {product.pricing && product.pricing.total.toFixed(2)}лв.
      </div>
      <div className="element medium">{product.from.fullName}</div>
      <div className="element large">---</div>
      <div className="element large">---</div>
      <div className="element large">---</div>
      <div className="element medium no-border even">
        <div className="history-btn" onClick={onViewHistory} />
        <div
          className={`light-checkbox ${checked && "checked"}`}
          onClick={onCheck}
        >
          {checked && <div className="checkmark" />}
        </div>
      </div>
    </div>
  )
}

export default StorageAvailableProduct
