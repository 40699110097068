import React, { useEffect, useState } from "react"
import { Inputs } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import { validations, defaultValues } from "./contactValidations"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import "reactjs-popup/dist/index.css"
import { history } from "../../../config/stores"
import ContactForm from "../../../components/ContactComponents/ContactForm"
import {
  deleteContact,
  editContact,
  getCurrentContact,
  getCurrentUser,
} from "../../../actions"

const EditContact = () => {
  const isMobile = window.innerWidth < 950
  const dispatch = useDispatch()

  const contactId = window.location.href.split("/")[4]
  const currentUser = useSelector((state) => state.user.currentUser)
  const canEditDelete = currentUser?._id === "5fa2a91bf689c74baedd3a6b"

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validations),
    mode: "onSubmit",
    defaultValues: defaultValues,
  })
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    dispatch(getCurrentUser())
    dispatch(
      getCurrentContact({
        _id: contactId,
        onSuccess: (res) => {
          setValue("name", res?.name)
          setValue("position", res?.position)
          setValue("companyName", res?.companyName)
          setValue("phoneNumber", res?.phoneNumber)
          setValue("email", res?.email)
          setValue("country", res?.country)
          setValue("contactFrom", res?.contactFrom)
          setValue("files", res?.files)
          setValue(
            "forProducts",
            res?.forProducts?.map((prod) => ({ label: prod, value: prod }))
          )
          setValue("mainComment", res?.mainComment)
          setValue("comments", res?.comments)
        },
      })
    )
  }, [])

  const handleEdit = (e) => {
    console.log(e)
    dispatch(
      editContact({
        ...e,
        ...(e?.forProducts && {
          forProducts: e?.forProducts?.map((prod) => prod?.value),
        }),
        _id: contactId,
        onSuccess: (res) => history.push("/contacts"),
      })
    )
  }

  const handleDelete = (e) =>
    dispatch(
      deleteContact({
        _id: contactId,
        onSuccess: (res) => history.push("/contacts"),
      })
    )

  return (
    <div className="main-container" style={{ height: "93%" }}>
      <div className="inner-header-container">
        <div
          className={`flex w-full ${
            !isMobile && "justify-between"
          } items-center`}
        >
          {isMobile && (
            <img
              src={require("../../../assets/icons/closeIcon.svg")}
              style={{
                width: "15px",
                height: "15px",
                cursor: "pointer",
                margin: "2px 15px 0px 5px",
              }}
              onClick={() => history.push("/contacts")}
            />
          )}
          <h3 className="inner-title" style={{ marginLeft: "0px" }}>
            Редактирай контакт
          </h3>
          {!isMobile && canEditDelete ? (
            <div
              className="flex items-center"
              style={{ width: "260px", marginRight: "10px", gap: "10px" }}
            >
              <Inputs.Button
                text="Изтрий"
                className="delete"
                style={{ width: "130px" }}
                onClick={() => handleDelete()}
              />
              <Inputs.Button
                text="Запази"
                selected
                style={{ width: "130px" }}
                onClick={handleSubmit(handleEdit)}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="body-container"
        style={{ height: isMobile && "unset", padding: isMobile && "1.5vw" }}
      >
        <ContactForm
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
          currentUser={currentUser}
          edit={true}
          canEdit={canEditDelete}
        />
        {isMobile && (
          <>
            <Inputs.Button
              text={"Запази промени"}
              selected
              style={{ width: "100%", margin: "20px 0px" }}
              onClick={handleSubmit(handleEdit)}
            />
            <Inputs.Button
              text="Изтрий контакт"
              className="delete"
              style={{ width: "100%", margin: "20px 0px" }}
              onClick={() => handleDelete()}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default EditContact
