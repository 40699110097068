import { combineEpics } from "redux-observable"
import * as starter from "./starter"
import * as authEpics from "./auth"
import * as userEpic from "./user"
import * as productsEpic from "./products"
import * as variationsEpic from "./variations"
import * as providersEpic from "./providers"
import * as inquiriesEpic from "./inquiries"
import * as tasksEpic from "./tasks"
import * as storage from "./storage"
import * as activities from "./activities"
import * as projects from "./projects"
import * as production from "./production"
import * as comments from "./comments"
import * as references from "./references"
import * as settings from "./machineSettings"
import * as socket from "./socket"
import * as contacts from "./contacts"

// const taskEpics = Tasks.epics.taskEpicInit({ URL: API_URL })
// const statusDetectionEpics = StatusDetection.epics.statusDetectionEpicsInit({
//   URL: API_URL
// })
// const chat = Chat.epics.chatEpicInit({
//   URL: API_URL
// })

export const rootEpic = combineEpics(
  starter.loadingEpic,
  authEpics.signInEpic,
  authEpics.updateProfileEpic,
  authEpics.updateUserEpic,
  activities.getActivitiesEpic,
  userEpic.changeWorkingStatusEpic,
  userEpic.changeStatusEpic,
  userEpic.getcurrentUser,
  userEpic.getColleagues,
  userEpic.getFilteredUsers,
  userEpic.getTasks,
  userEpic.setPushTokenEpic,
  userEpic.getCurrentUserRoleEpic,
  productsEpic.getProductTypesEpic,
  productsEpic.getProductEpic,
  productsEpic.getSingleProductEpic,
  productsEpic.createProductEpic,
  productsEpic.updateProductExpenseEpic,
  productsEpic.updateProductProductionEpic,
  productsEpic.searchProductEpic,
  productsEpic.updateProductEpic,
  productsEpic.editProductExpenseEpic,
  productsEpic.editProductProductionEpic,
  productsEpic.getAllProducts,
  variationsEpic.getVariationsEpic,
  providersEpic.getProvidersEpic,
  inquiriesEpic.getInquiriesEpic,
  inquiriesEpic.createInquiriesEpic,
  inquiriesEpic.getInquiryEpic,
  inquiriesEpic.addProductsToInquiryEpic,
  inquiriesEpic.addDocumentsToInquiryEpic,
  inquiriesEpic.changeWorkingOnEpic,
  inquiriesEpic.addCommentEpic,
  inquiriesEpic.updateCommentEpic,
  inquiriesEpic.removeCommentEpic,
  inquiriesEpic.addActionsEpic,
  inquiriesEpic.updateInquiryEpic,
  inquiriesEpic.removeInquiriesEpic,
  inquiriesEpic.addZoneEpic,
  inquiriesEpic.getZonesEpic,
  inquiriesEpic.getInquiryOffers,
  inquiriesEpic.searchInquiryOffers,
  inquiriesEpic.filterInquiryDatesOffers,
  inquiriesEpic.getInquirySales,
  inquiriesEpic.searchInquiries,
  inquiriesEpic.createInquiryChecklistEpic,
  inquiriesEpic.createInquiryStatusEpic,
  inquiriesEpic.getInquiryStatusesEpic,
  inquiriesEpic.getProductsProductEpic,
  tasksEpic.getTasksEpic,
  tasksEpic.getTaskEpic,
  tasksEpic.createTaskEpic,
  tasksEpic.updateTaskEpic,
  tasksEpic.deleteTaskEpic,
  tasksEpic.reorderTasksEpic,
  // taskEpics,
  // statusDetectionEpics,
  // chat,
  storage.getRequests,
  storage.createRequest,
  storage.orderRequest,
  storage.updateOrderStatus,
  storage.postsRequest,
  storage.exportRequest,
  storage.searchRequestEpic,
  storage.searchProvider,
  storage.editHPInvoice,
  projects.editProjectTeamEpic,
  projects.createProjectOffer,
  projects.createProjectOfferEn,
  projects.createProjectInvoice,
  projects.createProjectInvoiceEn,
  projects.editProjectOffer,
  projects.editProjectOfferEn,
  projects.searchOfferClients,
  projects.editProjectInvoice,
  projects.editProjectInvoiceEn,
  projects.createActOfAcceptance,
  projects.createActOfAcceptanceEn,
  projects.editActOfAcceptance,
  projects.editActOfAcceptanceEn,
  projects.deleteProjectOffer,
  projects.deleteProjectInvoice,
  projects.deleteProjectAct,
  projects.searchActSuppliers,
  storage.getAvailableProducts,
  storage.searchStorageProducts,
  storage.createStorageProduct,
  storage.editStorageProduct,
  storage.exportProduct,
  storage.sampleProduct,
  storage.defectProduct,
  storage.editHPRequest,
  storage.addProformInvoice,
  storage.sellProduct,
  storage.sellProductHP,
  storage.createActOfAcceptanceStorage,
  storage.editActOfAcceptanceStorage,
  storage.editProformInvoiceStorage,
  projects.deleteProjectProduct,
  production.createProductionRequest,
  production.getProductionRequests,
  production.getProductionRequest,
  production.getMachinesRequests,
  production.updateProductionRequest,
  production.getScheduleRequest,
  production.getSingleScheduleEpic,
  production.searchMachineSettingsEpic,
  production.checkMachineScheduleEpic,
  production.updateScheduleEpic,
  production.finishRequestEpic,
  production.getPerformers,
  production.addPerformer,
  projects.searchInquiryCategories,
  production.getMachineSchedule,
  production.startWorkMachineSchedule,
  comments.addCommentEpic,
  comments.editCommentEpic,
  comments.deleteCommentEpic,
  production.getMachineScheduleByPerformer,
  production.getCurrentEndProduct,
  inquiriesEpic.addInvoiceCredit,
  inquiriesEpic.addInvoiceDebit,
  inquiriesEpic.changeInvoiceStatus,
  inquiriesEpic.editInvoiceCredit,
  inquiriesEpic.editInvoiceDebit,
  inquiriesEpic.deleteInvoiceCredit,
  inquiriesEpic.deleteInvoiceDebit,
  inquiriesEpic.addProformInvoice,
  inquiriesEpic.addProformInvoiceEn,
  inquiriesEpic.editProformInvoice,
  inquiriesEpic.editProformInvoiceEn,
  inquiriesEpic.deleteProformInvoice,
  inquiriesEpic.searchInquiryProduction,
  inquiriesEpic.getInquiriesExpenseMachine,
  inquiriesEpic.getInquiriesExpenseEndProduct,
  inquiriesEpic.getInquiriesDefectives,
  production.finishFinishingWorks,
  references.searchReferences,
  references.createClient,
  references.getClients,
  references.searchInquiryClients,
  references.editClient,
  references.getSuppliers,
  references.createSupplier,
  references.editSupplier,
  references.searchSuppliers,
  references.searchInquirySuppliers,
  references.filterInquiryDatesClients,
  references.filterInquiryDatesSuppliers,
  storage.getHPRequests,
  storage.createHPRequest,
  storage.orderHPRequest,
  storage.changeHPOrderStatus,
  storage.accrueHPOrder,
  storage.sellHPOrder,
  storage.filterHPOrders,
  settings.getMachineSettings,
  settings.deleteMachineSettings,
  settings.editMachineSettings,
  settings.getSpecificSettings,
  production.addOtherAction,
  production.getCurrentAction,
  production.getActionSchedule,
  production.editActionItem,
  production.deleteRequest,
  production.produceMachineSchedule,
  production.deleteScheduleRequest,
  production.endWorkMachineSchedule,
  production.checkUnfinishedWork,
  production.checkUnfinishedWorks,
  production.getUnfinishedWorks,
  production.addCommentMachine,
  production.saveMachineActivityEpic,
  production.getMachineActivityEpic,
  production.markMachineActivityAsDefectiveEpic,
  production.deleteSingleFinishingWorkEpic,
  production.deleteEndProductScheduleEpic,
  contacts.addContactEpic,
  contacts.getContactsEpic,
  contacts.getCurrentContactEpic,
  contacts.editContactEpic,
  contacts.deleteContactEpic,
  socket.connectSocket,
  socket.disconnectSocket
)
