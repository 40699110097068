import { ModalTypes, CommentTypes } from "../actions"

const initialState = {
  visible: false,
  type: "",
  data: "",
  width: null,
  height: null,
  overflow: "",
  minHeight: "",
  classNames: [],
}

const addComment = (state, payload) => {
  if (state.width) {
    const newData = { ...state.data }
    newData.comments.unshift(payload)
    return newData
  } else {
    return state.data
  }
}

const deleteComment = (state, payload) => {
  if (state.width) {
    const newData = { ...state.data }
    const commentIndex = newData.comments.findIndex(
      (comment) => comment._id === payload._id
    )
    newData.comments.splice(commentIndex, 1)
    return newData
  } else {
    return state.data
  }
}

const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case ModalTypes.SHOW_MODAL:
      return {
        ...state,
        visible: true,
        type: payload.type,
        data: payload.data,
        width: payload.width,
        height: payload.height,
        minHeight: payload.minHeight,
        overflow: payload.overflow,
        classNames: payload.classNames || [],
      }

    case ModalTypes.HIDE_MODAL:
      return { ...state, visible: false }

    case ModalTypes.SET_MODAL_WIDTH:
      return { ...state, width: payload }
    case CommentTypes.ADD_GENERAL_COMMENT_SUCCESS:
      return { ...state, data: addComment(state, payload) }
    case CommentTypes.DELETE_GENERAL_COMMENT_SUCCESS:
      return { ...state, data: deleteComment(state, payload) }
    default:
      return state
  }
}

export default modal
