import React from "react"
import "./styles.scss"
import { Button } from "@mui/material"
import { Input } from "@makedonski/admin-ui"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { useState, useEffect, useRef } from "react"
import { Link, useParams, useLocation } from "react-router-dom"
import moment from "moment"
import { history } from "../../config/stores"
import { getProductName } from "../../utilities/helpers"
import { getSingleSchedule, getAllProducts, getInquiries,
  deleteProductionRequest, putProductionRequest, searchInquiries } from "../../actions"
import { useDispatch, useSelector } from "react-redux"
import { pick } from "lodash"
import _, { add } from "lodash"
import { Lottie } from "@crello/react-lottie";
import * as animationData from "../../assets/animations/loading.json";

export default function ProductionEditRequest() {
  const [startDate, setStartDate] = useState(moment().toDate())
  const [chosenProject, setChosenProject] = useState(null)
  const [chosenProduct, setChosenProduct] = useState(null)
  const [query, setQuery] = useState('')
  const [focusedElement, setFocusedElement] = useState(null)
  const [loading, setLoading] = useState(false)
  const scrollRef = useRef();
  const { id } = useParams()
  const { state } = useLocation();
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.production.currentSchedule); 
  const data = state.data;

  const [ payload, setPayload ] = useState({
    _id: id,
    product: data.product,
    quantity: Number(data.quantity),
    deadline: data.deadline,
    // start: data.inquiry.startDate,
    comment: data?.comments[0]?.text || '',
    inquiry: data.inquiry,
    status: data.status,
    requestedFrom: data.requestedFrom._id,
    finishingWorks: []
  })

  const projects = useSelector(state => state.inquiries.inquiries).map(project => {
    return { ...project, productsInvolved: project.orders.filter(order => order.product).map(order => order.product._id) }
  })
  const inquiriesPage = useSelector(state => state.inquiries.inquiriesPage)

  const storageProductResults =
  useSelector((state) => state.products.totalProducts)
    .map(product => {
      const name = product.friendlyNames?.length ? product.friendlyNames[0] : product.productType?.name ? product.productType.name : 'No name'
      return { _id: product._id, name: name }
    })
    .filter(product => {
      return chosenProject && chosenProject?.productsInvolved?.includes(product._id)
    })

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
    };


    const onReachEnd = () => {
      if (inquiriesPage) {
        let payload = {pageSize: 20, pageNumber: inquiriesPage, sortBy: {object: 1}}
        setLoading(true)
        dispatch(searchInquiries(payload))
      }
    }

    const handleScrolling = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight } = scrollRef.current
        const innerHeight = scrollRef.current.getBoundingClientRect().height
        if (scrollTop + innerHeight >= scrollHeight - 10) {
          onReachEnd()
        }
      }
    }

    const delayedScrolling = _.debounce(() => {
      handleScrolling() 
    }, 300)

  useEffect(() => {
    dispatch(getAllProducts())
    dispatch(searchInquiries({pageSize: 20, pageNumber: inquiriesPage, sortBy: {object: 1}}))
    setChosenProject(payload.inquiry)
    setChosenProduct(payload?.product?.productType)
  }, [])

  return (
    <div className="production-edit-request-wrapper">
      <div className="request-edit-container">
        <div className="request-edit-top">
          <h2>Редакция заявка</h2>

          <div className="buttons-wrapper">
            <button
            onClick={() => {
              Alerts.confirm({
                title: "Сигурни ли сте, че искате да изтриете заявката?",
                confirm: "Да",
                cancel: "Не",
              },
              (answer) => {
                if (answer) {
                  dispatch(deleteProductionRequest({_id: data._id}))
                  history.push('/production')
                }
              })
            }}>
              Изтрий
            </button>
            <button
            onClick={() => {
              let newPayload = {...payload}
              newPayload.product = newPayload.product._id
              newPayload.inquiry = newPayload.inquiry._id

              dispatch(putProductionRequest(newPayload))
            }}>
                Запази
            </button>
          </div>
        </div>

        <div className="request-edit-main">
          <div className="request-edit-main-dropdown-inputs">
            <div className="project-section-wrapper">
              <label htmlFor="project-input">Проект</label>

            <Input.Text
              value={chosenProject?.object}
              onFocus={() => {
                setFocusedElement(true)
              }}
              onBlur={() => {
                setTimeout(() => {
                  setFocusedElement(null)
                  setLoading(false)
                  // dispatch(searchInquiries({pageSize: 20, pageNumber: 1, sortBy: {object: 1}}))
                }, 300)
              }}
              id='project-input'
              placeholder='Избор на проект'
              onChange={(e) => e.target.value = ''}
              />
             {focusedElement &&
             <div 
              ref={scrollRef}
              onScroll={delayedScrolling}
              className='project-suggestions-dropdown-wrapper'>
                {projects?.map(project => 
                  <div
                  className='single-project-suggestion' 
                  onClick={() => {
                    setChosenProject(project)
                    setChosenProduct(null)
                    setPayload({...payload, inquiry: project})
                    }}>{project?.object}</div>)}

                <div className='loader-wrapper'>
                  {loading && <Lottie config={defaultOptions} width={70} height={70} />}
                </div>
              </div>}

            </div>

            <div className="product-section-wrapper">
              <label htmlFor="product-input">Продукт</label>
              <Input.Dropdown id="product-input" 
                options={storageProductResults.map(product => product.name)}
                value={chosenProduct?.name || ''}
                onChange={product => {
                  const found = storageProductResults.find(el => el.name === product.value)
                  setChosenProduct(found)
                  let newPayload = {...payload}
                  newPayload.product = found
                  setPayload(newPayload)
                }}
               />
            </div>

            <div className="quantity-section-wrapper">
              <label htmlFor="quantity-input">К-во</label>
              <div className="input-wrapper">
                <Input.Text id="quantity-input" value={payload?.quantity}
                onChange={(e) => {
                  setPayload({...payload, quantity: Number(e.target.value)})
                }}
                 />
                <p>{payload?.product?.mainUnit || 'бр'}</p>
              </div>
            </div>

            <div className="dates-section-wrapper">
              <label htmlFor="datepicker">Срок за производство</label>
              <Input.Datepicker 
              id="datepicker" 
              placeholder={moment(payload.deadline).format('DD.MM.YYYY')}
              onChange={(pickDate) => {
                setPayload({...payload, deadline: pickDate})
              }}
               />
            </div>
          </div>

          <div className="notes-section-wrapper">
            <label htmlFor="notes-input">Бележки</label>
            <Input.Text id="notes-input"  
            // value={payload?.comment?.map(el => el.text).join(', ')}
            value={payload?.comment}
            onChange={(e) => {
              setPayload({...payload, comment: e.target.value})
            }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
