import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import { hideModal, addProductsToInquiry, clearResults } from "../../../actions"
import { FilterProducts, ProductListItem } from "../.."
import "./styles.scss"

const nameMap = {
  Тръба: "Материал",
  КМУ: "Ориентация",
  Резервоар: "Ориентация",
}

const ProductsList = ({ onClose, products = [], onAdd, search }) => {
  const [ searchValue, setSearchValue ] = useState('');
  const [selected, setSelected] = React.useState("")
  const getProductVariation = (item) => {
    // let { name } = item.productType
    return item.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")
  }

  const checkSearchedProducts = (product, text) => {
    // if (product.productType.name !== "Тръба") {
    //  return getProductVariation(product)?.toLowerCase()?.includes(searchValue)
    // }
    // else {
    //   return text?.toLowerCase()?.includes(searchValue) || (product?.friendlyNames?.join(", ")?.toLowerCase()?.includes(searchValue))
    // }
    return text?.toLowerCase()?.includes(searchValue) || (product?.friendlyNames?.join(", ")?.toLowerCase()?.includes(searchValue))
  }

  return (
    <div className="products-list-container">
      <div className="products-list-container-title">
        <h4>Изберете продукт</h4>
        <div className="close-button">
          <Button.Icon
            onClick={onClose}
            name="plus"
            size={20}
            color="#7E7E7E"
          />
        </div>
      </div>

      {/* {search &&  */}
      <div className='products-search-wrapper'>
         <div className='input-wrapper'>
           <img src={require("../../../assets/icons/searchIcon.svg")} />
           <input type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value.toLocaleLowerCase())} />
         </div>
      </div>
      {/* } */}

      <div className="products-list-container-scroll">
        {products?.map((product) => {
          let text = product?.productType?.name
          if (product?.productType?.name === "Тръба") {
            const typeOfDiameter = product.basicInfo.find(
              (el) => el.name === "Произвежда се по"
            )
            const SN = product.basicInfo.find((el) => el.name === "Коравина")
            if (typeOfDiameter?.value === "Вътрешен диаметър") {
              const diameter = product.basicInfo.find(
                (el) => el.name === "Вътрешен диаметър"
              )
              text = `SN${SN?.value}_ID${diameter?.value}`
            } else {
              const diameter = product.basicInfo.find(
                (el) => el.name === "Външен диаметър"
              )
              text = `SN${SN?.value}_OD${diameter?.value}`
            }
          }
          return (
            <div
              className="product-list-item-wrap"
              // style={{ display: checkSearchedProducts(product, text) ? 'visible' : 'none' }}
              onClick={() => {
                setSelected(product._id)
              }}
            >
              {checkSearchedProducts(product, text)
              && <div className="product-list-item-title">
                {product?.friendlyNames?.join(", ") || text}
              </div>}
              {/* <div className="product-list-item-title">
                {product?.friendlyNames?.join(", ") || text}
              </div> */}
              
              {(product.productType.name !== "Тръба" && checkSearchedProducts(product, text))
              && (
                <div className="product-list-item-variation">
                  {getProductVariation(product)}
                </div>
              )}
              {checkSearchedProducts(product, text) 
              && <div
                className={`radio-circle ${
                  selected === product._id ? "active" : ""
                }`}
              >
                <div className="radio-circle-inner" />
              </div>}
            </div>
          )
        })}
      </div>
      <div className="products-list-container-footer">
        <Button.Raised
          disabled={selected === ""}
          onClick={() => {
            onAdd(selected)
          }}
          text="Добави"
          className="button-green"
        />
      </div>
    </div>
  )
}

const AddProductModal = ({ hide, data, addProducts, more, clear }) => {
  const [products, setProducts] = useState([])
  const popupRefItem = React.useRef(null)
  const productsSearchRef = React.useRef(null)
  const getProductName = (item) => {
    let { name } = item.productType
    if (nameMap[name]) {
      const basicInfoDetail = item.basicInfo.find(
        (b) => b.name === nameMap[name]
      )
      if (basicInfoDetail) {
        name += ` | ${basicInfoDetail?.value}`
      }
    }
    return name
  }
  React.useEffect(() => {
    const { width } = productsSearchRef.current.getBoundingClientRect()
    popupRefItem.current.style.width = "290px"
    popupRefItem.current.style.height = "410px"
    popupRefItem.current.style.bottom = "-20px"
    popupRefItem.current.style.display = "none"
    popupRefItem.current.style.left = `${width - 10}px`
  }, [])

  React.useEffect(() => {
    if (more.length) {
      popupRefItem.current.style.display = "block"
    }
  }, [more])

  const onClosePopup = () => {
    popupRefItem.current.style.display = "none"
  }

  const getProductVariation = (item) => {
    // let { name } = item.productType
    return item?.basicInfo
      ?.filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      ?.map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")
  }

  const updateProduct = (key, value, index) => {
    const _products = products.slice(0)
    const found = _products[index]
    found[key] = value
    setProducts(_products)
  }

  const removeProduct = (index) => {
    const _products = products.slice(0)
    _products.splice(index, 1)
    setProducts(_products)
  }

  // let filteredRequests = data.requests
  // .filter(request => request?.status === "accrued")
  // .map(request => {
  //   return {
  //   name: request.product,
  //   id: request._id,
  //   createdAt: request.createdAt,
  //   updatedAt: request.updatedAt,
  //   __v: 0,
  //   _id: request._id
  // }})

  let filteredRequests = data.requests
  .filter(request => request?.status === "accrued")
  .map(request => {
    return {
      _id: request._id,
      name: request.name,
      amount: request.quantity,
      deliveryDate: new Date(),
      variation: 0,
    }})

  return (
    <div className="add-product-modal-new-container">
      <div className="left-container" ref={productsSearchRef}>
        <h3>Добавяне на нов продукт към запитване</h3>
        <FilterProducts
          onSelect={(product) => {
            const newProducts = products.slice(0)
            newProducts.push({
              _id: product._id,
              name: getProductName(product),
              amount: 0,
              deliveryDate: new Date(),
              variation: getProductVariation(product),
            })
            setProducts(newProducts)
          }}
          // requests={filteredRequests}
        />
      </div>
      <div className="right-container">
        <div className="top-right-container">
          <h3>Продукти към запитването:</h3>
          <div
            className="exit-btn"
            onClick={() => {
              clear()
              hide()
            }}
          />
        </div>
        <div className="scroll-container">
          {products.map((p, index) => {
            return (
              <ProductListItem
                item={p}
                key={`${p._id}_${index}`}
                index={index}
                onUpdate={updateProduct}
                onRemove={removeProduct}
              />
            )
          })}
        </div>
        <Button.Raised
          text="Добави продукт"
          onClick={() => {
            addProducts({ products, _id: data.inquiry })
            hide()
          }}
        />
      </div>
      <div ref={popupRefItem} className="more-products-popup">
        <ProductsList
          // products={[...more, ...filteredRequests]}
          products={more}
          onClose={() => {
            onClosePopup()
          }}
          onAdd={(productID) => {
            const newProducts = products.slice(0)
            const found = more.find((el) => el._id === productID)
            newProducts.push({
              _id: found._id,
              name: getProductName(found),
              amount: 0,
              deliveryDate: new Date(),
              variation: getProductVariation(found),
            })
            setProducts(newProducts)
            onClosePopup()
            // this.props.history.push(`/products/${productID}`)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  more: state.products.more,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  addProducts: (payload) => dispatch(addProductsToInquiry(payload)),
  clear: () => dispatch(clearResults()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProductModal)
