import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './WeeklyMonthly.scss'
import '../Daily/Daily.scss'
import '../Production.scss'
import { getMachineSchedule, getMachineScheduleByPerformer, getActionSchedule, showLoading } from '../../../actions'
import ScheduleOrderColumn from './ScheduleOrderColumn'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../../config/stores'
import { capitalizeFirstLetter } from '../../../utilities/helpers'

export default function WeeklyMonthly ({ selectedTab, requestType, onSetDate }) {
  const [date, setDate] = useState(moment().startOf('week'))
  const arrowRightIcon = require('../../../assets/icons/arrowRight.svg')
  const arrowLeftIcon = require('../../../assets/icons/arrowLeft.svg')
  const arrowDownIcon = require('../../../assets/icons/arrowDown.svg')
  const requestData = useSelector((state) => state.production.productSchedule)
  const actionData = useSelector((state) => state.production.actionSchedule)
  const dispatch = useDispatch()
  

  let activityPerformers = [];
  actionData.map(el => {
    activityPerformers.push(...el.performers)});
    activityPerformers = [...new Set(activityPerformers)]

  const columnWidth = Object.keys(requestData).length <= 3
    ? 1735 / Object.keys(requestData).length - 10
    : 427
    
  // const columnWidth = Object.keys(requestData).length <= 3
  //   ? Object.keys(requestData).length * 425
  //   : 400

  const columnWidthAction = activityPerformers.length <= 3
  ? 1735 / Object.keys(activityPerformers).length - 10
  : 430

  const [ weekdays, setWeekdays ] = useState([])
  const week = [];
  let weekday = moment(date).startOf('week')
  let renderDays = [{ понеделник: [] }, { вторник: [] }, { сряда: [] },
     { четвъртък: [] }, { петък: [] }, {събота: []}, {неделя: []}]

  const monthDays = []
  let monthDay = moment(date).startOf('month')
  const monthDaysCount = moment(date).endOf('month').format('D')

  for (let i = 0; i < 7; i++) {
    if (i !== 0) {
      weekday = moment(weekday).add(1, 'day')
    }

    const currentDate = weekday.format('D')
    const currentMonth = weekday.format('MMMM')
    const currentDay = weekday.format('dddd')
    week.push({
      date: currentDate,
      month: currentMonth,
      weekday: currentDay,
      isVisible: true
    })
  }

  for (let i = 0; i < monthDaysCount; i++) {
    if (i !== 0) {
      monthDay = moment(monthDay).add(1, 'day')
    }

    const currentDate = monthDay.format('D')
    const currentMonth = monthDay.format('MMMM')
    const currentDay = monthDay.format('dddd')
    monthDays.push({
      date: currentDate,
      month: currentMonth,
      weekday: currentDay,
      requestData: []
    })
  }

  const linesCount = (selectedTab === 'Месечен' && monthDays.length) || (selectedTab === 'Седмичен' && weekdays.filter(day => day.isVisible).length)

  const checkSchedule = (currentColumn) => {
    renderDays = [{ понеделник: [] }, { вторник: [] }, { сряда: [] },
     { четвъртък: [] }, { петък: [] }, {събота: []}, {неделя: []}]

    if (selectedTab === 'Седмичен') {
      let requests = renderDays.slice(0)
      currentColumn && currentColumn.map(requestData => {
        const start = requestData.start ? requestData.start : requestData.startsAt
        
        requests.map(day => {
          if (Object.keys(day)[0] === moment(start).format('dddd')) {
           return Object.values(day)[0].push(requestData)
          }
          })
      })
      return requests
    } else if (selectedTab === 'Месечен') {
      let requests = JSON.parse(JSON.stringify(monthDays))
      currentColumn && currentColumn.forEach(requestData => {
        const start = requestData.start ? requestData.start : requestData.startsAt
        requests.map(day => {
          if (day.date === moment(start).format('D')) {
            if (`${day.date} ${day.month}` === moment(start).format('DD MMMM') ||
                `0${day.date} ${day.month}` === moment(start).format('DD MMMM')) {
              return day.requestData.push(requestData)
            }
          }
       })
      })
      return requests
    }
  }

  useEffect(() => {
    dispatch(showLoading())
    if (requestType === 'Тръби - машини') {
      selectedTab === 'Седмичен' &&
      dispatch(getMachineSchedule({
        start: moment(date).startOf('week'),
        end: moment(date).endOf('week')
      }))

      selectedTab === 'Месечен' &&
      dispatch(getMachineSchedule({
        start: moment(date).startOf('month'),
        end: moment(date).endOf('month')
      }))
    } else if (requestType === 'Тръби - изпълнители') {
      selectedTab === 'Седмичен' &&
      dispatch(getMachineSchedule({
        start: moment(date).startOf('week'),
        end: moment(date).endOf('week'),
        groupBy: 'performerNew'
      }))

      selectedTab === 'Месечен' &&
      dispatch(getMachineSchedule({
        start: moment(date).startOf('month'),
        end: moment(date).endOf('month'),
        groupBy: 'performerNew'
      }))
    } else if (requestType === 'Краен продукт') {
      selectedTab === 'Седмичен' &&
      dispatch(getMachineScheduleByPerformer({
        start: moment(date).startOf('week'),
        end: moment(date).endOf('week')
      }))
      selectedTab === 'Месечен' &&
      dispatch(getMachineScheduleByPerformer({
        start: moment(date).startOf('month'),
        end: moment(date).endOf('month')
      }))
    } else if (requestType === 'Допълнителни') {
      selectedTab === 'Седмичен' &&
      dispatch(getActionSchedule({
        start: moment(date).startOf('week'),
        end: moment(date).endOf('week')
      }))
      selectedTab === 'Месечен' &&
      dispatch(getActionSchedule({
        start: moment(date).startOf('month'),
        end: moment(date).endOf('month')
      }))
    }
    onSetDate(date)
    setWeekdays(week)
  }, [requestType, date, selectedTab])


  useEffect(() => {
    const timer = setTimeout(() => {
      if (requestType === 'Тръби - машини') {
        selectedTab === 'Седмичен' &&
        dispatch(getMachineSchedule({
          start: moment(date).startOf('week'),
          end: moment(date).endOf('week')
        }))
  
        selectedTab === 'Месечен' &&
        dispatch(getMachineSchedule({
          start: moment(date).startOf('month'),
          end: moment(date).endOf('month')
        }))
      } else if (requestType === 'Тръби - изпълнители') {
        selectedTab === 'Седмичен' &&
        dispatch(getMachineSchedule({
          start: moment(date).startOf('week'),
          end: moment(date).endOf('week'),
          groupBy: 'performerNew'
        }))
  
        selectedTab === 'Месечен' &&
        dispatch(getMachineSchedule({
          start: moment(date).startOf('month'),
          end: moment(date).endOf('month'),
          groupBy: 'performerNew'
        }))
      } else if (requestType === 'Краен продукт') {
        selectedTab === 'Седмичен' &&
        dispatch(getMachineScheduleByPerformer({
          start: moment(date).startOf('week'),
          end: moment(date).endOf('week')
        }))
        selectedTab === 'Месечен' &&
        dispatch(getMachineScheduleByPerformer({
          start: moment(date).startOf('month'),
          end: moment(date).endOf('month')
        }))
      } else if (requestType === 'Допълнителни') {
        selectedTab === 'Седмичен' &&
        dispatch(getActionSchedule({
          start: moment(date).startOf('week'),
          end: moment(date).endOf('week')
        }))
        selectedTab === 'Месечен' &&
        dispatch(getActionSchedule({
          start: moment(date).startOf('month'),
          end: moment(date).endOf('month')
        }))
      }
      onSetDate(date)
      setWeekdays(week)
    }, 10000)
    return () => clearTimeout(timer);
  }, [requestData, actionData])

  const renderData = () => {
    switch (requestType) {
      case 'Тръби - машини':
        return (
          <div className='weekly-monthly-orders-wrapper'>
            <div className='weekly-monthly-order-wrapper machine'
            style={{minHeight: selectedTab === 'Месечен' ? monthDaysCount * 164 : 7 * 164}}>
              <h3 className='order-main-title machine'>Машина OD800</h3>
              <div className='line-background-wrapper'>
                    {Array(linesCount)
                      .fill(0)
                      .map((el, index) => {
                        return <li key={`line-number-${index}`} className='line-background' />
                      })}
                  </div>
              <div className='render-container machine'>
                {requestData?.OD800 && checkSchedule(requestData.OD800).map((el, i) => {
                  if (selectedTab === 'Седмичен') {
                    if (weekdays.length > 0 && weekdays[i].isVisible === false) {
                      return null
                    }
                    else if (Object.values(el)[0].length === 0) {
                      return <div key={`weekly-pipes-machine-one-${i}`} />
                    }
                     else {
                      return (
                        <div className='weekday-requests-container' key={`weekday-requests-one-${i}-${Object.values(el)[0].length + i * 7}`}>
                          {Object.values(el)[0].map((data, index) => {
                            return (
                              <ScheduleOrderColumn
                                key={`weekly-pipes-machine-one-key-${index}-${data._id}`}
                                onClick={() => {
                                  window.open(`/production/pipe/${data._id}`)
                                  // history.push(`/production/pipe/${data[index]._id}`)
                                }}
                                orderData={data}
                              />
                            )
                          })}
                        </div>
                      )
                    }
                  } else if (selectedTab === 'Месечен') {
                    if (el.requestData?.length === 0) {
                      return <div key={`monthly-pipes-machine-one-${i}`} />
                    } else if (el.requestData.length > 0) {
                      return (
                        <div className='weekday-requests-container' key={`weekday-requests-one-month-${i * 17 + i}`}>
                          {el.requestData.map((data, index) => {
                            return (
                              <ScheduleOrderColumn
                                key={`monthly-pipes-machine-one-key-${index}-${data._id}`}
                                onClick={() => {
                                  window.open(`/production/pipe/${data._id}`)
                                  // history.push(`/production/pipe/${data._id}`)
                                }}
                                orderData={data}
                              />
                            )
                          })}

                        </div>
                      )
                    }
                  }
                })}
                {/* {requestData?.OD800 === undefined &&
                  (selectedTab === 'Седмичен' &&
                  Array(weekdays.filter(day => day.isVisible).length).fill(0).map((el, index) => { return (<div key={`random-ass-element-${index}`} />) })) ||
                  (selectedTab === 'Месечен' && Array(Number(monthDaysCount)).fill(0).map((el, index) => { return (<div key={`random-ass-element-2-${index}`} />) }))} */}
              </div>
            </div>
            <div className='weekly-monthly-order-wrapper machine'
            style={{minHeight: selectedTab === 'Месечен' ? monthDaysCount * 164 : 7 * 164 }}>
              <h3 className='order-main-title machine'>Машина OD2000</h3>
              <div className='line-background-wrapper'>
                    {Array(linesCount)
                      .fill(0)
                      .map((el, index) => {
                        return <li key={`line-number-${index}`} className='line-background' />
                      })}
                  </div>
              <div className='render-container machine'>
                {requestData?.OD2000 && checkSchedule(requestData?.OD2000)?.map((el, i) => {
                  if (selectedTab === 'Седмичен') {
                    if (weekdays.length > 0 && weekdays[i].isVisible === false) {
                      return null
                    }
                    else if (Object.values(el)[0].length === 0) {
                      return <div key={`weekly-pipes-machine-two-${i}`} />
                    } 
                    else {
                      return (
                        <div className='weekday-requests-container' key={`weekday-requests-two-${i}-${Object.values(el)[0].length + i}`}>
                          {Object.values(el)[0].map((data, index) => {
                            return (
                              <ScheduleOrderColumn
                                key={`monthly-pipes-machine-two-key-${index}-${data._id}`}
                                onClick={() => {
                                  window.open(`/production/pipe/${data._id}`)
                                  // history.push(`/production/pipe/${data[index]._id}`)
                                }}
                                orderData={data}
                              />
                            )
                          })}
                        </div>
                      )
                    }
                  } else if (selectedTab === 'Месечен') {
                    if (el.requestData?.length === 0) {
                      return <div key={`monthly-pipes-machine-two-${i}`} />
                    } else if (el.requestData.length > 0) {
                      return (
                        <div className='weekday-requests-container' key={`weekday-requests-one-month-${i}--${Object.values(el)[0].length * i}`}>
                          {el.requestData.map((data, index) => {
                            return (
                              <ScheduleOrderColumn
                              key={`monthly-pipes-machine-two-key-${index}-${data._id}`}
                                onClick={() => {
                                  window.open(`/production/pipe/${data._id}`)
                                  // history.push(`/production/pipe/${data._id}`)
                                }}
                                orderData={data}
                              />
                            )
                          })}

                        </div>
                      )
                    }
                  }
                })}
                {/* {requestData?.OD2000 === undefined &&
                  (((selectedTab === 'Седмичен' && 
                  Array(weekdays.filter(day => day.isVisible).length).fill(0).map((el, index) => { return (<div key={`random-ass-element-3-${index}`} />) })) ||
                  (selectedTab === 'Месечен' && Array(Number(monthDaysCount)).fill(0).map((el, index) => { return (<div key={`random-ass-element-4-${index}`} />) }))))} */}
              </div>
            </div>
            <div className='weekly-monthly-order-wrapper machine'
            style={{minHeight: selectedTab === 'Месечен' ? monthDaysCount * 164 : 7 * 164}}>
              <h3 className='order-main-title machine'>Машина OD2400</h3>
              <div className='line-background-wrapper'>
                    {Array(linesCount)
                      .fill(0)
                      .map((el, index) => {
                        return <li key={`line-number-${index}`} className='line-background' />
                      })}
                  </div>
              <div className='render-container machine'>
                {requestData?.OD2400 && checkSchedule(requestData?.OD2400)?.map((el, i) => {
                  if (selectedTab === 'Седмичен') { 
                    if (weekdays.length > 0 && weekdays[i].isVisible === false) {
                      return null
                    }
                    else if (Object.values(el)[0].length === 0) {
                      return <div key={`weekly-pipes-machine-three-${i}`} />
                    }
                    else {
                      return (
                        <div className='weekday-requests-container' key={`weekday-requests-three-${i}--${Object.values(el)[0].length + i}`}>
                          {Object.values(el)[0].map((data, index) => {
                            return (
                              <ScheduleOrderColumn
                              key={`weekly-pipes-machine-three-key-${index}-${data._id}`}
                                onClick={() => {
                                  window.open(`/production/pipe/${data._id}`)
                                  // history.push(`/production/pipe/${data[index]._id}`)
                                }}
                                orderData={data}
                              />
                            )
                          })}
                        </div>
                      )
                    }
                  } else if (selectedTab === 'Месечен') {
                    if (el.requestData?.length === 0) {
                      return <div key={`monthly-pipes-machine-three-${i}`} />
                    } else if (el.requestData.length > 0) {
                      return (
                        <div className='weekday-requests-container' key={`weekday-requests-three-month-${i}--${Object.values(el)[0].length * i}`}>
                          {el.requestData.map((data, index) => {
                            return (
                              <ScheduleOrderColumn
                                key={`monthly-pipes-machine-three-key-${index}-${data._id}`}
                                onClick={() => {
                                  window.open(`/production/pipe/${data._id}`)
                                  // history.push(`/production/pipe/${data._id}`)
                                }}
                                orderData={data}
                              />
                            )
                          })}

                        </div>
                      )
                    }
                  }
                })}
                {/* {requestData?.OD2400 === undefined &&
                    ((selectedTab === 'Седмичен' && Array(5).fill(0).map((el, index) => { return (<div key={`random-ass-element-5-${index}`} />) })) ||
                    (selectedTab === 'Месечен' && Array(Number(monthDaysCount)).fill(0).map((el, index) => { return (<div key={`random-ass-element-6-${index}`} />) })))} */}
              </div>
            </div>
          </div>
        )
      case 'Тръби - изпълнители':
        return (
          <div className='weekly-monthly-orders-wrapper'>
            {Object.keys(requestData).length === 0 &&
            <>
              <div className='line-background-wrapper'>
                {Array(linesCount)
                  .fill(0)
                  .map((el, index) => {
                    return <li key={`line-number-${index}`} className='line-background' />
                  })}
              </div>
              <div className={`weekly-monthly-order-wrapper ${selectedTab === 'Месечен' ? 'month' : ''}`} style={{minHeight: selectedTab === 'Месечен' && monthDaysCount * 164}}>
                <h3 className='order-main-title' style={{ width: '100%' }}/>
              </div>
            </>}
            
            {Object.keys(requestData).map((key, index) => {
              return (
                <div
                  className={`weekly-monthly-order-wrapper ${selectedTab === 'Месечен' ? 'month' : ''}`}
                  style={{ width: columnWidth,
                    minHeight: selectedTab === 'Месечен' && monthDaysCount * 164 }}
                  key={`executor-weekly-${index}`}
                >
                  <h3
                    className='order-main-title'
                    style={{ width: columnWidth }}
                  >{key}
                  </h3>
                  <div className='line-background-wrapper'>
                    {Array(linesCount)
                      .fill(0)
                      .map((el, index) => {
                        return <li key={`line-number-${index}`} className='line-background' />
                      })}
                  </div>
                  <div className='render-container'>
                    {checkSchedule(requestData[key])?.map((el, i) => {
                      const dailyRequests = Object.values(el)[0]
                      if (selectedTab === 'Седмичен') {
                        if (weekdays.length > 0 && weekdays[i].isVisible === false) {
                          return null
                        }
                        else if (dailyRequests.length === 0) {
                          return <div key={`weekly-performers-col-${i}`} />
                        } 
                        else if (dailyRequests.length > 0 &&
                          dailyRequests[0]?.performer === key) {
                          return (
                            <div className='weekday-requests-container' key={`weekday-requests-${i}`}>
                              {Object.values(el).map((data, i) => {
                                return (
                                  <ScheduleOrderColumn
                                  key={`weekly-performers-col-key-${i}-${data[i]._id}`}
                                    onClick={() => {
                                      window.open(`/production/pipe/${data[i]._id}`)
                                      // history.push(`/production/pipe/${data[i]._id}`)
                                    }}
                                    orderData={data[i]}
                                  />
                                )
                              })}
                            </div>
                          )
                        }
                      } else if (selectedTab === 'Месечен') {
                        if (el.requestData.length === 0) {
                          return <div key={`monthly-performers-col-empty-${i}`} />
                        } else if (el.requestData.length > 0 &&
                            el.requestData[0]?.performer === key) {
                          return (
                            <div className='weekday-requests-container'
                                 key={`monthly-performers-col-${i}`}>
                              {el.requestData.map(data => {
                                return data.performer === key &&
                                  <ScheduleOrderColumn
                                  key={`monthly-performers-col-key-${i}-${data._id}`}
                                    onClick={() => {
                                      window.open(`/production/pipe/${data._id}`)
                                      // history.push(`/production/pipe/${data._id}`)
                                    }} orderData={data}
                                  />
                              })}

                            </div>
                          )
                        }
                      }
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        )
      case 'Краен продукт':
        return (
          <div className='weekly-monthly-orders-wrapper'>
            {Object.keys(requestData).length === 0 &&
              <>
                <div className='line-background-wrapper'>
                  {Array(linesCount)
                    .fill(0)
                    .map((el, index) => {
                      return <li key={`line-number-${index}`} className='line-background' />
                    })}
                </div>
                <div className={`weekly-monthly-order-wrapper ${selectedTab === 'Месечен' ? 'month' : ''}`} 
                style={{minHeight: selectedTab === 'Месечен' && monthDaysCount * 164}} >
                  <h3 className='order-main-title' style={{ width: '100%' }}/>
                </div>
              </>}
            {Object.keys(requestData).map((key, index) => {
              return (
                <div
                className={`weekly-monthly-order-wrapper ${selectedTab === 'Месечен' ? 'month' : ''}`}
                style={{ width: columnWidth,
                  minHeight: selectedTab === 'Месечен' && monthDaysCount * 164 }}
                  key={`executor-weekly-${index}`}
                >
                  <h3
                    className='order-main-title'
                    style={{ width: columnWidth }}
                  >{key}
                  </h3>
                  <div className='line-background-wrapper'>
                    {Array(linesCount)
                      .fill(0)
                      .map((el, index) => {
                        return <li key={`line-number-${index}`} className='line-background' />
                      })}
                  </div>
                  <div className='render-container'>
                    {checkSchedule(requestData[key])?.map((el, i) => {
                      const dailyRequests = Object.values(el)[0]
                      if (selectedTab === 'Седмичен') {
                        if (weekdays.length > 0 && weekdays[i].isVisible === false) {
                          return null
                        }
                        else if (dailyRequests.length === 0) {
                          return <div key={`weekly-end-product-col-${i}`} />
                        }
                         else if (dailyRequests.length > 0) {
                          return (
                            <div className='weekday-requests-container'>
                              {dailyRequests.map((data, i) => {
                                return data.performers?.includes(key) &&
                                <ScheduleOrderColumn
                                key={`weekly-end-product-col-key-${i}-${data.request?._id || data._id}`}
                                  onClick={() => {
                                    const finishingWorks = []
                                    Object.values(requestData).forEach(finishingWorksArray => {
                                      finishingWorksArray.forEach(finishingWork => {
                                        if (finishingWork.request) {
                                          const index = finishingWorks.findIndex(wtf => wtf._id === finishingWork._id)
                                          if (index === -1 && finishingWork.request._id === data.request?._id) {
                                            finishingWorks.push(finishingWork)
                                          }
                                        }
                                      })
                                    })
                                    // window.open(`/production/end-product/${data.request?._id || data._id}`)
                                    // history.push(`/production/end-product/${data.request?._id || data._id}/${data._id}`, { data: { ...data.request, finishingWorks: finishingWorks } })
                                    history.push(`/production/end-product/${data.request?._id || data._id}`, { data: { ...data.request, finishingWorks: finishingWorks } })
                                  }}
                                  orderData={data} />
                              })}
                            </div>
                          )
                        }
                      } else if (selectedTab === 'Месечен') {
                        if (el.requestData.length === 0) {
                          return <div key={`monthly-performers-col-${i}`} />
                        } else if (el.requestData.length > 0) {
                          return (
                            <div className='weekday-requests-container'
                                 key={`monthly-performers-col-${i}`}>
                              {el.requestData.map((data, index) => {
                                return (
                                  <ScheduleOrderColumn
                                  key={`monthly-performers-col-key-${index}-${data.request?._id || data._id}`}
                                    onClick={() => {
                                      const finishingWorks = []
                                      Object.values(requestData).forEach(finishingWorksArray => {
                                        finishingWorksArray.forEach(finishingWork => {
                                          if (finishingWork.request) {
                                            const index = finishingWorks.findIndex(wtf => wtf._id === finishingWork._id)
                                            if (index === -1 && finishingWork.request._id === data.request?._id) {
                                              finishingWorks.push(finishingWork)
                                            }
                                          }
                                        })
                                      })
                                      // window.open(`/production/end-product/${data.request?._id || data._id}`)
                                      history.push(`/production/end-product/${data.request?._id || data._id}`, { data: { ...data.request, finishingWorks: finishingWorks } })
                                    }}
                                    orderData={data}
                                  />
                                )
                              })}
                            </div>
                          )
                        }
                      }
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        )
      case 'Допълнителни':
        return (
          <div className='weekly-monthly-orders-wrapper'>
            {actionData.length === 0 &&
              <>
                <div className='line-background-wrapper'>
                  {Array(linesCount)
                    .fill(0)
                    ?.map((el, index) => {
                      return <li key={`line-number-${index}`} className='line-background' />
                    })}
                </div>
                <div className={`weekly-monthly-order-wrapper ${selectedTab === 'Месечен' ? 'month' : ''}`}
                style={{minHeight: selectedTab === 'Месечен' && monthDaysCount * 164}}>
                  <h3 className='order-main-title' style={{ width: '100%' }}/>
                </div>
              </>}

            {activityPerformers.map((key, index) => {
              return (
                <div
                className={`weekly-monthly-order-wrapper ${selectedTab === 'Месечен' ? 'month' : ''}`}
                style={{ width: columnWidth,
                  minHeight: selectedTab === 'Месечен' && monthDaysCount * 164 }}
                  key={`executor-weekly-${key}-${index}`}
                >
                  <h3
                    className='order-main-title'
                    style={{ width: columnWidthAction }}
                    key={`executor-main-title-weekly-${index}`}
                  >{key}
                  </h3>
                  <div className='line-background-wrapper'>
                    {Array(linesCount)
                      .fill(0)
                      .map((el, index) => {
                        return <li key={`line-number-${index}`} className='line-background' />
                      })}
                  </div>
                  <div className='render-container'>
                    {checkSchedule(actionData)?.map((el, i) => {
                      const dailyRequests = Object.values(el)[0]
                      if (selectedTab === 'Седмичен') {
                        if (weekdays.length > 0 && weekdays[i].isVisible === false) {
                          return null
                        }
                        else if (dailyRequests.length === 0 
                          && (!dailyRequests[i]?.performers
                            || !dailyRequests[i]?.performers.includes(key))) {
                          return <div key={`weekly-additional-col-${i}-${key}`} />
                        } 
                        else if (dailyRequests.length > 0) {
                          return (
                            <div className='weekday-requests-container'>
                              {dailyRequests.map((data, index) => {
                                return data.performers?.includes(key) &&
                                  <ScheduleOrderColumn
                                  key={`weekly-additional-col-key-${i}-${index}-${data._id}`}
                                  onClick={() => {
                                    // let requestData = actionData.find(request => request._id === data._id)
                                    // history.push({
                                    //   pathname: `/production/view-edit/${data._id}`,
                                    //   state: { data: data }
                                    // })
                                    window.open(`/production/view-edit/${data._id}`)
                                  }}
                                    orderData={data}
                                  />
                              })}
                            </div>
                          )
                        }
                      } else if (selectedTab === 'Месечен') {
                        if (el.requestData?.length === 0) {
                          return <div key={`monthly-additional-col-${i}-${index}`} />
                        } else {
                          return (
                            <div className='weekday-requests-container'>
                              {el.requestData.map((data, index) => {
                                return (
                                  data.performers?.includes(key) &&
                                  <ScheduleOrderColumn
                                    key={`monthly-additional-col-key-${i}-${index}-${data._id}`}
                                    onClick={() => {
                                      // let requestData = actionData.find(request => request._id === data._id)
                                      // history.push({
                                      //   pathname: `/production/view-edit/${data._id}`,
                                      //   state: { data: data }
                                      // })
                                      window.open(`/production/view-edit/${data._id}`)
                                    }}
                                    orderData={data}
                                  />
                                )
                              })}

                            </div>
                          )
                        }
                      }
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        )
    }
  }

  return (
    <>
    <div className='schedule-wrapper'>
      <div className='schedule-weekly-monthly-main'>
        <div className='arrows-wrapper'>
          <div className='arrows'>
            <img
              src={arrowLeftIcon}
              onClick={() => {
                dispatch(showLoading())
                if (selectedTab === 'Седмичен') {
                  const newDate = moment(date).subtract(7, 'days').toDate()
                  setDate(newDate)
                  onSetDate(newDate)
                  if (requestType === 'Тръби - машини') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week')
                    }))
                  } else if (requestType === 'Тръби - изпълнители') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week'),
                      groupBy: 'performerNew'
                    }))
                  } else if (requestType === 'Краен продукт') {
                    dispatch(getMachineScheduleByPerformer({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week')
                    }))
                  } else if (requestType === 'Допълнителни') {
                    dispatch(getActionSchedule({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week')
                    }))
                  }
                } else if (selectedTab === 'Месечен') {
                  const newDate = moment(date).subtract(1, 'months')
                  setDate(newDate)
                  onSetDate(newDate)
                  if (requestType === 'Тръби - машини') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month')
                    }))
                  } else if (requestType === 'Тръби - изпълнители') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month'),
                      groupBy: 'performerNew'
                    }))
                  } else if (requestType === 'Краен продукт') {
                    dispatch(getMachineScheduleByPerformer({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month')
                    }))
                  } else if (requestType === 'Допълнителни') {
                    dispatch(getActionSchedule({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month')
                    }))
                  }
                }
              }}
            />
            <img
              src={arrowRightIcon}
              onClick={() => {
                dispatch(showLoading())
                if (selectedTab === 'Седмичен') {
                  const newDate = moment(date).add(7, 'days').toDate()
                  setDate(newDate)
                  onSetDate(newDate)
                  if (requestType === 'Тръби - машини') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week')
                    }))
                  } else if (requestType === 'Тръби - изпълнители') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week'),
                      groupBy: 'performerNew'
                    }))
                  } else if (requestType === 'Краен продукт') {
                    dispatch(getMachineScheduleByPerformer({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week')
                    }))
                  } else if (requestType === 'Допълнителни') {
                    dispatch(getActionSchedule({
                      start: moment(newDate).startOf('week'),
                      end: moment(newDate).endOf('week')
                    }))
                  }
                } else if (selectedTab === 'Месечен') {
                  const newDate = moment(date).add(1, 'months')
                  setDate(newDate)
                  onSetDate(newDate)
                  if (requestType === 'Тръби - машини') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month')
                    }))
                  } else if (requestType === 'Тръби - изпълнители') {
                    dispatch(getMachineSchedule({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month'),
                      groupBy: 'performerNew'
                    }))
                  } else if (requestType === 'Краен продукт') {
                    dispatch(getMachineScheduleByPerformer({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month')
                    }))
                  } else if (requestType === 'Допълнителни') {
                    dispatch(getActionSchedule({
                      start: moment(newDate).startOf('month'),
                      end: moment(newDate).endOf('month')
                    }))
                  }
                }
              }}
            />
          </div>
        </div>

        {selectedTab === 'Седмичен' && (
          <ul className='schedule-weeks'>
            {weekdays.map((day, index) => {
              return (
                day.isVisible &&
                <li
                  key={`weekday-number-${index}`}
                  className={
                    day.date === moment().format('D') &&
                    day.month === moment().format('MMMM')? 'selected-day' : ''
                  }
                >
                  <h2>{day.date}</h2>
                  <h3>{capitalizeFirstLetter(day.month)}</h3>
                  <h3 className='weekday'>
                    {capitalizeFirstLetter(day.weekday)}
                  </h3>
                </li>
              )
            })}
          </ul>
        )}

        {selectedTab === 'Месечен' && (
          <ul className='schedule-weeks'>
            {monthDays.map((day, index) => {
              return (
                <li
                  key={`monthday-number-${index}`}
                  className={
                    day.date === moment().format('D') &&
                    day.month === moment().format('MMMM')
                      ? 'selected-day'
                      : ''
                  }
                >
                  <h2>{day.date}</h2>
                  <h3>{capitalizeFirstLetter(day.month)}</h3>
                  <h3 className='weekday'>
                    {capitalizeFirstLetter(day.weekday)}
                  </h3>
                </li>
              )
            })}
          </ul>
        )}
      </div>

      {renderData()}

      {/* {selectedTab === 'Седмичен' && (
        <div className='weekend-label'>
          <h4>Почивни дни</h4>
          <img src={arrowDownIcon}
          onClick={() => {
            let updatedWeeks = weekdays.slice(0)
            updatedWeeks[5].isVisible = !updatedWeeks[5].isVisible
            updatedWeeks[6].isVisible = !updatedWeeks[6].isVisible
            setWeekdays(updatedWeeks)
            }} />
        </div>
      )} */}
    </div>

      {/* {selectedTab === 'Седмичен' && (
        <div className='weekend-label fixed'>
          <h4>Почивни дни</h4>
          <img src={arrowDownIcon}
          className={weekdays[5]?.isVisible ? 'arrow-up' : 'arrow-down'}
          onClick={() => {
            let updatedWeeks = weekdays.slice(0)
            updatedWeeks[5].isVisible = !updatedWeeks[5].isVisible
            updatedWeeks[6].isVisible = !updatedWeeks[6].isVisible
            setWeekdays(updatedWeeks)
            }} />
        </div>
)} */}
    </>
  )
}
