import React from 'react'

export default function InquirySingleInvoice({ invoice }) {
    const downloadIcon = require('../../assets/icons/download-icon.svg');

  return (
    <div className='single-invoice-wrapper' key={invoice._id}>
      <div className="element large">
          {invoice.object || 'Object missing'}
      </div>
      <div className="element medium">
        <p>{invoice.invoiceNumber}.pdf</p>
        <img src={downloadIcon}/>
      </div>
      <div className="element medium">{invoice.reason || '--'}</div>
      <div className="element medium">12:45</div>
      <div className="element large last">Вера Иванова</div>
    </div>
  )
}
