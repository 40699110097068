import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
  SingleRadioButton,
  TableHeader,
  StorageAvailableProduct,
} from "../../components"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CloseIcon from "@mui/icons-material/Close"
import {
  Button as MuiButton,
  Paper,
  Divider,
  InputBase,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  TextField,
  Box,
  IconButton,
} from "@mui/material"
import { connect } from "react-redux"
import { Button, Input } from "@makedonski/admin-ui"
import { showModal, getAvailableProducts } from "../../actions"
import moment from "moment"
import "./styles.scss"
const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

const disabledTextfieldStyles = {
  "& input.Mui-disabled": {
    "-webkit-text-fill-color": "rgba(0, 0, 0, 1)",
  },
}

const availableProductsHeader = [
  { name: "Вид продукт", size: 4 },
  { name: "К-во", size: 1.7 },
  { name: "На склад от", size: 1.5 },
  { name: "Ед. цена", size: 1 },
  { name: "Цена", size: 1 },
  { name: "Добавен от", size: 2 },
  { name: "Посл. изписани", size: 2.5 },
  { name: "Посл. бракувани", size: 2.5 },
  { name: "Посл. мостри", size: 2.5 },
  { name: "", size: 2 },
]

const StorageAvailableProducts = ({
  showModal,
  getAvailableProducts,
  availableProducts,
}) => {
  const [period, setPeriod] = useState(false)
  const [checkedProduct, setCheckedProduct] = useState({ _id: "" })
  const [search, setSearch] = useState("")
  const [startDate, setStartDate] = useState(moment().toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const totalValue = availableProducts.reduce((acc, val) => {
    return acc + val.pricing.total
  }, 0)
  const searchQuery = useParams().search

  useEffect(() => {
    if (searchQuery) {
      setSearch(searchQuery)
    } else {
      setSearch("")
    }
  }, [searchQuery])

  useEffect(() => {
    getAvailableProducts()
  }, [getAvailableProducts])

  const [actionsAnchor, setActionsAnchor] = useState(null)
  const actionsAnchorOpen = Boolean(actionsAnchor)
  const handleActionsOpen = (event) => {
    setActionsAnchor(event.currentTarget)
  }
  const handleActionsClose = () => {
    setActionsAnchor(null)
  }

  return (
    <div className="storage-available-products-container">
      <div className={"projects-header"}>
        <div className={"search-container"}>
          <Paper
            component={"form"}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation()
                    e.preventDefault()
                  }
                },
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Търси"
            />
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <MuiButton
              style={{ textTransform: "none", color: "black" }}
              disableElevation
              disableRipple
              disableFocusRipple
              className={"filter-button"}
              endIcon={<KeyboardArrowDownIcon style={{ color: "#26ACE2" }} />}
              onClick={handleOpen}
            >
              {searchQuery}
            </MuiButton>
            <Menu
              PaperProps={{ style: { padding: "15px" } }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div className={"filters-options"}>
                <h5>Търсене по</h5>
                <CloseIcon
                  fontSize="small"
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <FormControl>
                <RadioGroup
                  value={radioBtnValue}
                  onChange={handleRadioBtnChange}
                >
                  <FormControlLabel
                    value="product"
                    control={<Radio sx={radioStyles} />}
                    label="Вид продукт"
                  />
                  <FormControlLabel
                    value="code"
                    control={<Radio sx={radioStyles} />}
                    label="Код"
                  />
                </RadioGroup>
                <MuiButton
                  sx={{
                    background: "#26ACE2",
                    "&:hover": {
                      background: "#26ACE2",
                    },
                  }}
                  onClick={handleClose}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  variant="contained"
                  fullWidth
                >
                  Задай
                </MuiButton>
              </FormControl>
            </Menu> */}
          </Paper>
          {/* <MuiButton
            // onClick={handleFilter}
            variant="contained"
            sx={{
              padding: "6px 25px",
              backgroundColor: "#E6EEF4",
              "&:hover": {
                background: "#E6EEF4",
              },
              color: "black",
            }}
          >
            Търси
          </MuiButton> */}
        </div>
        <div className={"datepicker-container"}>
          <Input.Datepicker
            className={`datepicker`}
            placeholder="Дата"
            pickDate={startDate}
            onChange={(date) => {
              setStartDate(date)
            }}
          />
          <Box sx={{ m: 1 }}>-</Box>
          <Input.Datepicker
            className={`datepicker`}
            placeholder="Дата"
            pickDate={endDate}
            onChange={(date) => {
              setEndDate(date)
            }}
          />
          {/* <TextField
            value={periodSearchQuery}
            size="small"
            disabled
            style={{
              width: "220px",
              margin: "0px 10px",
            }}
            sx={disabledTextfieldStyles}
            InputProps={{
              style: { fontSize: "14px" },
              endAdornment: (
                <InputAdornment position="end">
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    onClick={handlePeriodSearchOpen}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Menu
            PaperProps={{ style: { padding: "15px" } }}
            anchorEl={periodSearchAnchor}
            open={periodSearchOpen}
            onClose={handlePeriodSearchClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div className={"filters-options"}>
              <h5>Търси по</h5>
              <CloseIcon
                fontSize="small"
                onClick={handlePeriodSearchClose}
                style={{ cursor: "pointer" }}
              />
            </div>
            <FormControl>
              <RadioGroup
                value={radioBtnPeriod}
                onChange={handleRadioBtnPeriodChange}
              >
                <FormControlLabel
                  value="createdAt"
                  control={<Radio sx={radioStyles} />}
                  label="Дата на създаване"
                />
                <FormControlLabel
                  value="updatedAt"
                  control={<Radio sx={radioStyles} />}
                  label="Последна активност"
                />
              </RadioGroup>
            </FormControl>
          </Menu> */}
          <MuiButton
            onClick={() => {
              setPeriod(true)
            }}
            variant="contained"
            sx={{
              padding: "6px 25px",
              backgroundColor: "#E6EEF4",
              "&:hover": {
                background: "#E6EEF4",
              },
              color: "black",
            }}
          >
            Търси
          </MuiButton>
        </div>
        <div>
          <MuiButton
            onClick={() => {
              setPeriod(false)
              setSearch("")
              setStartDate(moment().toDate())
              setEndDate(moment().toDate())
            }}
            variant="contained"
            sx={{
              backgroundColor: "#26ACE2",
              "&:hover": {
                background: "#26ACE2",
              },
              color: "white",
            }}
          >
            Reset Filters
          </MuiButton>
          <MuiButton
            onClick={handleActionsOpen}
            variant="contained"
            sx={{
              backgroundColor: "#25E083",
              "&:hover": {
                background: "#25E083",
              },
              color: "white",
            }}
          >
            Действия
          </MuiButton>
          <Menu
            PaperProps={{ style: { padding: "15px" } }}
            anchorEl={actionsAnchor}
            open={actionsAnchorOpen}
            onClose={handleActionsClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseIcon
                fontSize="small"
                onClick={handleActionsClose}
                style={{ cursor: "pointer" }}
              />
            </div>
            <Button.Raised
              text="Добави продукция"
              className="button-green"
              onClick={() => {
                showModal({
                  type: "addStorageProduct",
                  overflow: "visible",
                  width: "600px",
                })
                handleActionsClose()
              }}
            />
            <Button.Raised
              text="Изпиши"
              className={`clear blue ${!checkedProduct._id && "disabled"}`}
              onClick={() => {
                if (checkedProduct._id !== "") {
                  showModal({
                    type: "storageProductAction",
                    overflow: "visible",
                    width: "800px",
                    data: { type: "Изписване", product: checkedProduct },
                  })
                  setCheckedProduct({ _id: "" })
                  handleActionsClose()
                }
              }}
            />
            <Button.Raised
              text="За брак"
              className={`clear red ${!checkedProduct._id && "disabled"}`}
              onClick={() => {
                if (checkedProduct._id !== "") {
                  showModal({
                    type: "storageProductAction",
                    overflow: "visible",
                    width: "800px",
                    data: { type: "Бракуване", product: checkedProduct },
                  })
                  setCheckedProduct({ _id: "" })
                  handleActionsClose()
                }
              }}
            />
            <Button.Raised
              text="За мостра"
              className={`clear purple ${!checkedProduct._id && "disabled"}`}
              onClick={() => {
                if (checkedProduct._id !== "") {
                  showModal({
                    type: "storageProductAction",
                    overflow: "visible",
                    width: "800px",
                    data: { type: "Мостра", product: checkedProduct },
                  })
                  setCheckedProduct({ _id: "" })
                  handleActionsClose()
                }
              }}
            />
          </Menu>
        </div>
      </div>
      {/* <div className="flex-container space-between padding">
        <div className="flex-container space-between big">
          <Button.Raised
            text="Добави продукция"
            className="button-green"
            onClick={() => {
              showModal({
                type: "addStorageProduct",
                overflow: "visible",
                width: "600px",
              })
            }}
          />
          <Input.Text
            placeholder="Свободно търсене"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        <div className="flex-container space-between bigger">
          <SingleRadioButton
            label="За период от:"
            selected={period}
            onClick={() => {
              setPeriod((prev) => !prev)
            }}
          />
          <Input.Datepicker
            placeholder="Дата"
            pickDate={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <p id="margin-left">До:</p>
          <Input.Datepicker
            placeholder="Дата"
            pickDate={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
        <div className="flex-container space-between medium">
          <Button.Raised
            text="Изпиши"
            className={`clear blue ${!checkedProduct._id && "disabled"}`}
            onClick={() => {
              if (checkedProduct._id !== "") {
                showModal({
                  type: "storageProductAction",
                  overflow: "visible",
                  width: "800px",
                  data: { type: "Изписване", product: checkedProduct },
                })
                setCheckedProduct({ _id: "" })
              }
            }}
          />
          <Button.Raised
            text="За брак"
            className={`clear red ${!checkedProduct._id && "disabled"}`}
            onClick={() => {
              if (checkedProduct._id !== "") {
                showModal({
                  type: "storageProductAction",
                  overflow: "visible",
                  width: "800px",
                  data: { type: "Бракуване", product: checkedProduct },
                })
                setCheckedProduct({ _id: "" })
              }
            }}
          />
          <Button.Raised
            text="За мостра"
            className={`clear purple ${!checkedProduct._id && "disabled"}`}
            onClick={() => {
              if (checkedProduct._id !== "") {
                showModal({
                  type: "storageProductAction",
                  overflow: "visible",
                  width: "800px",
                  data: { type: "Мостра", product: checkedProduct },
                })
                setCheckedProduct({ _id: "" })
              }
            }}
          />
        </div>
      </div> */}
      <div className="flex-container padding">
        <TableHeader
          elements={availableProductsHeader}
          justifyContent="flex-start"
        />
      </div>
      <div className="scroll-container">
        {availableProducts
          .filter((product) => {
            if (search) {
              if (period === "createdAt") {
                return (
                  product.type.toLowerCase().includes(search.toLowerCase()) &&
                  moment(product.createdAt).isBetween(
                    moment(startDate),
                    moment(endDate)
                  )
                )
              } else {
                return product.type.toLowerCase().includes(search.toLowerCase())
              }
            } else {
              if (period) {
                return moment(product.createdAt).isBetween(
                  moment(startDate).startOf("day"),
                  moment(endDate).endOf("day")
                )
              } else {
                return product
              }
            }
          })
          .sort((a, b) => {
            return moment(a.createdAt).isBefore(b.createdAt)
          })
          .map((el) => {
            return (
              <StorageAvailableProduct
                product={el}
                checked={checkedProduct._id === el._id}
                onCheck={() => {
                  if (checkedProduct._id === el._id) {
                    setCheckedProduct({ _id: "" })
                  } else {
                    setCheckedProduct(el)
                  }
                }}
                onViewHistory={() => {
                  showModal({
                    type: "productionHistory",
                    overflow: "visible",
                    width: "600px",
                    data: el,
                  })
                }}
              />
            )
          })}
      </div>
      <div className="scroll-footer">
        <div className="flex-container">
          <h3>Обща стойност на склада:</h3>
          <p id="bold">
            {totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}лв.
          </p>
          <Button.Icon
            name="info"
            size={26}
            color="#a2bfd5"
            onClick={() => {
              showModal({
                type: "storageProducts",
                overflow: "visible",
                width: "700px",
                data: availableProducts,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  availableProducts: state.storage.availableProducts,
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  getAvailableProducts: () => dispatch(getAvailableProducts()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageAvailableProducts)
