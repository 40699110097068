import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
  StorageRawMaterials,
  StorageAvailableProducts,
  StorageReadyProducts,
  MachineStorage,
} from "../../components"
import "./styles.scss"

const tabs = [
  {
    name: "Суровини и елементи",
    component: <StorageRawMaterials />,
  },
  { name: "Готова продукция", component: <StorageAvailableProducts /> },
  { name: "ХидроПро", component: <StorageReadyProducts /> },
  // { name: "Машинен Склад", component: <MachineStorage /> },
]

const Storage = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const tab = useParams().tab
  useEffect(() => {
    if (tab) {
      if (tab === "material") {
        setSelectedTab({
          name: "Суровини и елементи",
          component: <StorageRawMaterials />,
        })
      } else if (tab === "product") {
        setSelectedTab({
          name: "Готова продукция",
          component: <StorageAvailableProducts />,
        })
      } else {
        setSelectedTab({
          name: "Суровини и елементи",
          component: <StorageRawMaterials />,
        })
      }
    }
  }, [tab])
  return (
    <div className="storage-container">
      <div className="top-bar-container">
        {tabs.map((tab, i) => (
          <div
            key={`storage-tab-${i}`}
            className={`option ${tab.name === selectedTab.name && "active"}`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      {selectedTab.component}
    </div>
  )
}

export default Storage
