import React from "react"
import { connect } from "react-redux"
import { showModal } from "../../actions"
import moment from "moment"
import "./styles.scss"

function PlannedActivity({ dotColour, date, activities }) {
  return (
    <div className="activity-container">
      <div className="activity-top">
        <div className="activity-dot" style={{ backgroundColor: dotColour }} />
        <div className="date">до {moment(date).format("DD.MM.YYYY")}</div>
      </div>
      <div className="activity-inner-container">
        <div
          className="activity-content"
          style={{ borderLeft: `solid 2px ${dotColour}` }}
        >
          {activities.map((activity, i) => {
            return (
              <div
                className={`activity-element ${
                  activities.length - 1 === i ? "padding-bottom" : null
                }`}
              >
                <span>{activity.type}</span>
                <p>{activity.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
})

export default connect(null, mapDispatchToProps)(PlannedActivity)
