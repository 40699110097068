import React, { useEffect, useState } from "react"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { Input } from "@makedonski/admin-ui"
import CloseIcon from "@mui/icons-material/Close"
import { DatePicker, TabContext, TabList } from "@mui/lab"
import Tab from "@mui/material/Tab"
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  TextField,
  withStyles,
} from "@mui/material"
import styles from "./PipesMachines.module.scss"
import Daily from "../Calendar/Daily/Daily"
import Grid from "../Calendar/Monthly/Monthly"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import moment from "moment"
import { connect } from "react-redux"
import { createTheme } from "@mui/material"
import {
  getMachineSchedule,
  getMachineScheduleByPerformer,
  getProductTypes,
  getActionSchedule,
  setProductionQuery,
  getProductionRequests,
} from "../../actions"
import AllRequestsMenu from "../AllRequestsMenu/AllRequestsMenu"
import { useHistory } from "react-router-dom"
import Monthly from "../Calendar/Monthly/Monthly"
import Weekly from "../Calendar/Weekly/Weekly"
import products from "../../reducers/products"

const monthMap = {
  1: "Януари",
  2: "Февруари",
  3: "Март",
  4: "Април",
  5: "Май",
  6: "Юни",
  7: "Юли",
  8: "Август",
  9: "Септември",
  10: "Октомври",
  11: "Ноември",
  12: "Декември",
}

function PipesMachines({
  contractor = false,
  schedule,
  getMachineSchedule,
  getMachineScheduleByPerformer,
  productSchedule,
  types,
  getProductTypes,
  getActionSchedule,
  actionSchedule,
  setProductionQuery,
  getRequests,
  data,
}) {
  useEffect(() => {
    getRequests()
  }, [])
  const history = useHistory()
  const [requestsModalOpen, setRequestsModalOpen] = useState(false)
  const handleOpen = () => setRequestsModalOpen(true)
  const handleClose = () => setRequestsModalOpen(false)

  const [newRequestMenuAnchor, setNewRequestMenuAnchor] = useState(null)
  const open = Boolean(newRequestMenuAnchor)
  const handleClickNewRequest = (event) => {
    setNewRequestMenuAnchor(event.currentTarget)
  }
  const handleCloseNewRequest = () => {
    setNewRequestMenuAnchor(null)
  }
  const [month, setMonth] = useState(moment())
  const [weekDay, setWeekDay] = useState(moment())
  const [week, setWeek] = useState({
    startOfWeek: moment().startOf("isoWeek"),
    endOfWeek: moment().endOf("isoWeek"),
  })
  const [date, setDate] = useState(moment())
  const [value, setValue] = useState("day")
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (contractor === false) {
      if (value === "week") {
        getMachineSchedule({
          start: moment(week.startOfWeek).startOf("week"),
          end: moment(week.endOfWeek).endOf("week"),
        })
      }
      if (value === "day") {
        getMachineSchedule({
          start: moment(weekDay).startOf("day"),
          end: moment(weekDay).endOf("day"),
        })
      }
      if (value === "month") {
        getMachineSchedule({
          start: moment(month).startOf("month"),
          end: moment(month).endOf("month"),
        })
      }
    }
    if (contractor === true && value === "day") {
      getMachineScheduleByPerformer({
        groupBy: "performer",
        start: moment(weekDay).startOf("day"),
        end: moment(weekDay).endOf("day"),
      })
      // getActionSchedule({
      //   start: moment(weekDay).startOf("day"),
      //   end: moment(weekDay).endOf("day"),
      // })
    }
    if (contractor === true && value === "week") {
      getMachineScheduleByPerformer({
        groupBy: "performer",
        start: moment(week.startOfWeek).startOf("week"),
        end: moment(week.endOfWeek).endOf("week"),
      })
    }
    if (contractor === true && value === "month") {
      getMachineScheduleByPerformer({
        groupBy: "performer",
        start: moment(month).startOf("month"),
        end: moment(month).endOf("month"),
      })
    }
    //   if (viewType === "Тръби") {
    //     getMachineSchedule({
    //       start: moment(weekDay).startOf("day"),
    //       end: moment(weekDay).endOf("day"),
    //     })
    //   } else if (viewType === "Краен продукт") {
    //     getMachineScheduleByPerformer({
    //       groupBy: "performer",
    //       start: moment(weekDay).startOf("day"),
    //       end: moment(weekDay).endOf("day"),
    //     })
    //   } else {
    //     getActionSchedule({
    //       start: moment(weekDay).startOf("day"),
    //       end: moment(weekDay).endOf("day"),
    //     })
    //   }
    // } else {
    //   if (viewType === "Тръби") {
    //     getMachineSchedule({
    //       start: moment(week.startOfWeek).startOf("week"),
    //       end: moment(week.endOfWeek).endOf("week"),
    //     })
    //   } else if (viewType === "Краен продукт") {
    //     getMachineScheduleByPerformer({
    //       groupBy: "performer",
    //       start: moment(week.startOfWeek).startOf("week"),
    //       end: moment(week.endOfWeek).endOf("week"),
    //     })
    //   } else {
    //     getActionSchedule({
    //       start: moment(week.startOfWeek).startOf("day"),
    //       end: moment(week.endOfWeek).endOf("day"),
    //     })
    //   }
    // }
    //eslint-disable-next-line
  }, [value, week, weekDay, month, contractor])

  return (
    <div className={styles["pipes-machines-container"]}>
      <div className={styles.options}>
        <div className={styles["time-container"]}>
          <DatePicker
            components={{
              OpenPickerIcon: () => (
                <img style={{ width: 24, height: 24 }} src="/calendar.svg" />
              ),
            }}
            value={
              (value === "day" && weekDay) ||
              (value === "week" && week.startOfWeek) ||
              (value === "month" && month)
            }
            onChange={(newValue) => {
              if (value === "day") {
                setWeekDay(moment(newValue))
              } else if (value === "week") {
                setWeek({
                  startOfWeek: moment(newValue).startOf("isoWeek"),
                  endOfWeek: moment(newValue).endOf("isoWeek"),
                })
              } else if (value === "month") {
                setMonth(moment(newValue))
              }
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box ref={inputRef} {...inputProps}>
                {InputProps?.endAdornment}
              </Box>
            )}
          />
          {value === "week" && (
            <>
              <h4>{moment(week.startOfWeek).format("DD")}</h4>
              <h4> - </h4>
              <h4>
                {moment(week.endOfWeek).format("DD")}{" "}
                {moment(week.endOfWeek).format("MMMM")}
              </h4>
            </>
          )}
          {value === "day" && (
            <h4>
              {moment(weekDay).format("DD MMMM")} -{" "}
              {moment(weekDay).format("dddd")}
            </h4>
          )}
          {value === "month" && <h4>{moment(month).format("MMMM")}</h4>}
        </div>
        <div className={styles["dates-tabs"]}>
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              TabIndicatorProps={{
                sx: {
                  height: 5,
                },
              }}
            >
              <Tab
                style={{
                  borderBottom: "5px solid #e6eef4",
                }}
                sx={{
                  "&.Mui-selected": {
                    color: "black",
                    fontWeight: "800",
                  },
                }}
                label="Дневен"
                value="day"
              />
              <Tab
                style={{
                  borderBottom: "5px solid #e6eef4",
                }}
                sx={{
                  "&.Mui-selected": {
                    color: "black",
                    fontWeight: "800",
                  },
                }}
                label="Седмичен"
                value="week"
              />
              <Tab
                style={{
                  borderBottom: "5px solid #e6eef4",
                }}
                sx={{
                  "&.Mui-selected": {
                    color: "black",
                    fontWeight: "800",
                  },
                }}
                label="Месечен"
                value="month"
              />
            </TabList>
          </TabContext>
        </div>
        <div className={styles["requests-container"]}>
          <img src="/download-file.png" alt="download" />
          <Button
            sx={{
              fontFamily: "inherit",
              color: "black",
            }}
            onClick={handleOpen}
            variant="outlined"
          >
            Всички заявки
          </Button>
          <Button
            sx={{
              background: "#26ACE2",
              "&:hover": {
                backgroundColor: "#26ACE2",
              },
            }}
            variant="contained"
            onClick={handleClickNewRequest}
          >
            Нова Заявка
          </Button>
        </div>
      </div>
      {value === "day" && (
        <Daily
          contractor={contractor}
          setWeekDay={setWeekDay}
          weekDay={weekDay}
          schedule={productSchedule}
        />
      )}
      {value === "month" && (
        <Monthly
          contractor={contractor}
          setDate={setMonth}
          month={month}
          schedule={productSchedule}
          initialDate={month}
        />
      )}
      {value === "week" && (
        <Weekly
          contractor={contractor}
          setDate={setWeek}
          week={week}
          initialDate={date}
          schedule={productSchedule}
        />
      )}
      <Menu
        open={open}
        onClose={handleCloseNewRequest}
        anchorEl={newRequestMenuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            width: "300px",
          },
        }}
      >
        {/* <img src="/menu-open.png" style={{ position: "absolute" }} /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <h4>Заявка за</h4>
          <CloseIcon onClick={handleCloseNewRequest} />
        </div>
        <MenuItem
          style={{ padding: "10px" }}
          onClick={() => history.push(`/productionv2/request`)}
        >
          Тръба / Краен продукт
        </MenuItem>
        <MenuItem style={{ padding: "10px" }}>Друга дейност</MenuItem>
      </Menu>
      <AllRequestsMenu
        open={requestsModalOpen}
        onClose={handleClose}
        data={data}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.production.data,
  productSchedule: state.production.productSchedule,
  types: state.products.types,
  actionSchedule: state.production.actionSchedule,
})

const mapDispatchToProps = (dispatch) => ({
  getRequests: () => dispatch(getProductionRequests()),
  getMachineSchedule: (payload) => dispatch(getMachineSchedule(payload)),
  getMachineScheduleByPerformer: (payload) =>
    dispatch(getMachineScheduleByPerformer(payload)),
  getProductTypes: (payload) => dispatch(getProductTypes(payload)),
  getActionSchedule: (payload) => dispatch(getActionSchedule(payload)),
  setProductionQuery: (payload) => dispatch(setProductionQuery(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PipesMachines)
