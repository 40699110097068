import React, { useState } from "react"
import { Button } from "@makedonski/admin-ui"
import "./styles.scss"
import { TextField, Button as MuiButton, Checkbox } from "@mui/material"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"

function ProjectZonesPopup({
  onClose,
  onUpdate,
  status,
  onCreate,
  zones = [],
  inquiryZones = [],
}) {
  const [newZone, setNewZone] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault()
    onCreate(newZone)
    setAddNewTrigger(false)
  }

  const initialState = zones?.map((zone) => ({
    ...zone,
    checked: inquiryZones
      ? Boolean(inquiryZones.find((a) => a?.name === zone?.name))
      : false,
  }))
  const [currentZones, setCurrentZones] = useState(initialState)
  const handleOnChange = (e) => {
    const { name, checked } = e.target
    let newZones = [...currentZones]
    const updatedCheckedState = newZones.map((zone) =>
      zone.name === name ? { ...zone, checked: checked } : zone
    )
    setCurrentZones(updatedCheckedState)
  }
  const [addNewTrigger, setAddNewTrigger] = useState(false)
  return (
    <div className="project-status-popup-container">
      <div className="top-project-status-container">
        <h4>Избор на зона</h4>
        <div className="exit-btn" onClick={onClose} />
      </div>
      <div className="project-status-main-container">
        {zones.map((zone, index) => (
          <div key={zone._id} className="colour-container">
            <div className="colour-bar" style={{ backgroundColor: "#6041ED" }}>
              <p>{zone.name}</p>
            </div>
            <Checkbox
              name={zone.name}
              onChange={handleOnChange}
              defaultChecked={Boolean(
                inquiryZones.find(
                  (inquiryZone) => inquiryZone?.name === zone?.name
                )
              )}
            />
          </div>
        ))}
      </div>
      <div className="bottom-project-status-container">
        {addNewTrigger === false ? (
          <>
            <Button.Raised
              text="Добави нов"
              onClick={() => {
                setAddNewTrigger(true)
              }}
            />
            <MuiButton
              variant="contained"
              onClick={() => {
                const newZones = currentZones.filter((a) => a.checked)
                onUpdate(newZones)
              }}
              style={{ textTransform: "none" }}
            >
              Запази промените
            </MuiButton>
          </>
        ) : (
          <>
            <TextField
              value={newZone}
              onChange={(e) => setNewZone(e.target.value)}
              size="small"
              variant="outlined"
              placeholder="Заглавие на зона"
              style={{ padding: "10px 0" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <CheckCircleRoundedIcon
                onClick={(e) => {
                  handleSubmit(e)
                }}
                style={{ color: "#25E083", cursor: "pointer" }}
              />
              <CloseRoundedIcon
                style={{ color: "#FF7590", cursor: "pointer" }}
                onClick={() => setAddNewTrigger(false)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ProjectZonesPopup
