import io from "socket.io-client"
import { store } from "../config/stores"
import { hideLoading } from "./general"
import { emit } from "../utilities/helpers"
import { API_URL as URL } from "../config/settings"

export const TaskTypes = {
    GET_TASKS: 'task/getTasks',
    GET_TASKS_SUCCESS: 'task/getTasksSuccess',
    GET_TASK: 'task/getTask',
    GET_TASK_SUCCESS: 'task/getTaskSuccess',
    CREATE_TASK: 'task/createTask',
    CREATE_TASK_SUCCESS: 'task/createTaskSuccessGlobal',
    UPDATE_TASK: 'task/updateTask',
    UPDATE_TASK_SUCCESS: 'task/updateTaskSuccessGlobal',
    DELETE_TASK: 'task/DELETE_TASK',
    DELETE_TASK_SUCCESS: 'tasks/deleteTaskSuccessGlobal',
    FINISH_TASK: 'task/toggleTaskCompleted',
    FINISH_TASK_SUCCESS: 'task/toggleTaskCompletedSuccessGlobal',
    REORDER_TASKS: 'task/reorderTasks',
    REORDER_TASKS_SUCCESS: 'task/reorderTasksSuccessGlobal',
    CHANGE_TASK_CATEGORY: 'task/changeTaskCategory',
    CHANGE_TASK_CATEGORY_SUCCESS: 'task/changeTaskCategorySuccessGlobal',

    // GET_CATEGORY: 'task/getCategory',
    // GET_CATEGORY_SUCCESS: 'task/getCategorySuccessGlobal',
    GET_CATEGORIES: 'task/getCategories',
    GET_CATEGORIES_SUCCESS: 'task/getCategoriesSuccessGlobal',
    CREATE_CATEGORY: 'task/createCategory',
    CREATE_CATEGORY_SUCCESS: 'task/createCategorySuccessGlobal',
    UPDATE_CATEGORY: 'task/updateCategory',
    UPDATE_CATEGORY_SUCCESS: 'task/updateCategorySuccessGlobal',
    DELETE_CATEGORY: 'task/deleteCategory',
    DELETE_CATEGORY_SUCCESS: 'task/deleteCategorySuccessGlobal',
    REORDER_CATEGORY: 'task/reorderCategories',
    REORDER_CATEGORY_SUCCESS: 'task/reorderCategoriesSuccessGlobal',

    CREATE_STEP: 'task/createStep',
    CREATE_STEP_SUCCESS: 'task/createStepSuccessGlobal',
    TOGGLE_STEP: 'task/toggleStepCompleted',
    TOGGLE_STEP_SUCCESS: 'task/toggleStepCompletedSuccessGlobal',
    DELETE_STEP: 'task/deleteStep',
    DELETE_STEP_SUCCESS: 'task/deleteStepCompletedSuccessGlobal',
    UPDATE_STEP: 'task/updateStep',
    UPDATE_STEP_SUCCESS: 'task/updateStepSuccessGlobal',
    UPDATE_STEPS: 'task/updateSteps',
    UPDATE_STEPS_SUCCESS: 'task/updateStepsSuccessGlobal',

    CREATE_COMMENT: 'task/createComment',
    CREATE_COMMENT_SUCCESS: 'task/createCommentSuccessGlobal',

    GET_USERS: 'task/getUsers',
    GET_USERS_SUCCESS: 'task/getUsersSuccessGlobal',

    UPLOAD_FILE: 'task/uploadFile',
    UPLOAD_FILE_SUCCESS: 'task/uploadFileSuccessGlobal',
    DELETE_FILE: 'task/deleteFile',
    DELETE_FILE_SUCCESS: 'task/deleteFileSuccessGlobal',

    DISPLAY_ACTIVITY_SUCCESS: 'task/newActivitySuccessGlobal',
    MARK_SEEN_ACTIVITY_SUCCESS: 'task/markActivityAsSeenSuccess',

    SET_HORIZONTAL_SCROLL: 'task/setHorizontalScroll',
    SET_LAST_OPENED_TASK: 'task/setLastOpenedTask',
  }

  export const socket = io(URL, { query: `token=${localStorage.getItem('token')}` })

  if (socket) {
    socket.on('connect', () => {          
      const controller = {
      'task/getTasksSuccess': (payload) => {
          store.dispatch(getTasksSuccess(payload))
      },
      'task/getTaskSuccess': (payload) => store.dispatch(getTaskSuccess(payload)),
      'task/createTaskSuccessGlobal': (payload) => {
        store.dispatch(createTaskSuccess(payload))
      },
      'task/updateTaskSuccessGlobal': (payload) => {
        store.dispatch(createTaskSuccess(payload))
      },
      'task/deleteTaskSuccessGlobal': (payload) => {
        store.dispatch(deleteTaskSuccess(payload))
      },
      'task/updateTaskSuccessGlobal': (payload) => {
        store.dispatch(updateTaskSuccess(payload))
      },
      'task/toggleTaskCompletedSuccessGlobal': (payload) => {
        store.dispatch(finishTaskSuccess(payload))
      },
      'task/reorderTasksSuccessGlobal': (payload) => {
        store.dispatch(reorderTasksSuccess(payload))
      },
      'task/changeTaskCategorySuccessGlobal': (payload) => {
        store.dispatch(changeTaskCategorySuccess(payload))
      },
      'task/getCategoriesSuccessGlobal': (payload) => {
        store.dispatch(getCategoriesSuccess(payload))
      },
      'task/createCategorySuccessGlobal': (payload) => {
        store.dispatch(createCategorySuccess(payload))
      },
      'task/updateCategorySuccessGlobal': (payload) => {
        store.dispatch(updateCategorySuccess(payload.category))
      },
      'task/deleteCategorySuccessGlobal': (payload) => {
        store.dispatch(deleteCategorySuccess(payload))
      },
      'task/reorderCategoriesSuccessGlobal': (payload) => {
        store.dispatch(reorderCategorySuccess(payload))
      },
      'task/createStepSuccessGlobal': (payload) => {
        store.dispatch(createStepSuccess(payload))
      },
      'task/createCommentSuccessGlobal': (payload) => {
        store.dispatch(createCommentSuccess(payload))
      },
      'task/toggleStepCompletedSuccessGlobal': (payload) => {
        store.dispatch(toggleStepSuccess(payload))
      },
      'task/deleteStepSuccessGlobal': (payload) => {
        store.dispatch(deleteStepSuccess(payload))
      },
      'task/updateStepSuccessGlobal': (payload) => {
        store.dispatch(updateStepSuccess(payload))
      },
      'task/updateStepsSuccessGlobal': (payload) => {
        store.dispatch(updateStepsSuccess(payload))
      },
      'task/getUsersSuccess': (payload) => {
        store.dispatch(getUsersSuccess(payload))
      },
      'task/uploadFileSuccessGlobal': (payload) => {
        store.dispatch(uploadFileSuccess(payload))
      },
      'task/deleteFileSuccessGlobal': (payload) => {
        store.dispatch(deleteFileSuccess(payload))
      },
      'task/newActivitySuccessGlobal': (payload) => {
        if (window.location.pathname === '/') 
        // emit(socket, { action: 'task/getTasks', payload: {} })
        store.dispatch(displayActivitySuccess(payload))
      },
      'task/markActivityAsSeenSuccess': (payload) => {
        let filters = store.getState().tasks.filters
        if (window.location.pathname === '/') 
        // emit(socket, { action: 'task/getTasks', payload: {} })
        emit(socket, { action: 'task/getTasks', payload: Object.values(filters || {})?.length ? { filters: {...filters, showNotCompleted: true} } : {} })
        store.dispatch(markSeenActivitySuccess(payload))
      },
      'task/setHorizontalScroll': (payload) => {
        store.dispatch(setHorizontalScroll(payload))
      },
      'task/setLastOpenedTask': (payload) => {
        store.dispatch(setLastOpenedTask(payload))
      }
  }

  socket.on('socket-message-v2', ({ action, payload }) => {
      console.log('Socket in:', { action, payload });
      if (controller[action]) {controller[action](payload)}
      store.dispatch(hideLoading())
  })
  })
  }
        // else {
      //   socket.off()
      // }
  
  export const getTasks = (payload) => ({
    type: TaskTypes.GET_TASKS,
    payload,
  })
  
  export const getTasksSuccess = (payload) => ({
    type: TaskTypes.GET_TASKS_SUCCESS,
    payload,
  })
  

  export const getTask = (payload) => ({
    type: TaskTypes.GET_TASK,
    payload,
  })
  
  export const getTaskSuccess = (payload) => ({
    type: TaskTypes.GET_TASK_SUCCESS,
    payload,
  })
  

  export const createTask = (payload) => ({
    type: TaskTypes.CREATE_TASK,
    payload,
  })
  
  export const createTaskSuccess = (payload) => ({
    type: TaskTypes.CREATE_TASK_SUCCESS,
    payload,
  })
  

  export const updateTask = (payload) => ({
    type: TaskTypes.UPDATE_TASK,
    payload,
  })
  
  export const updateTaskSuccess = (payload) => ({
    type: TaskTypes.UPDATE_TASK_SUCCESS,
    payload,
  })


  export const deleteTask = (payload) => ({
    type: TaskTypes.DELETE_TASK,
    payload,
  })
  
  export const deleteTaskSuccess = (payload) => ({
    type: TaskTypes.DELETE_TASK_SUCCESS,
    payload,
  })


  export const finishTask = (payload) => ({
    type: TaskTypes.FINISH_TASK,
    payload,
  })
  
  export const finishTaskSuccess = (payload) => ({
    type: TaskTypes.FINISH_TASK_SUCCESS,
    payload,
  })


  export const reorderTasks = (payload) => ({
    type: TaskTypes.REORDER_TASKS,
    payload,
  })
  
  export const reorderTasksSuccess = (payload) => ({
    type: TaskTypes.REORDER_TASKS_SUCCESS,
    payload,
  })


  export const changeTaskCategory = (payload) => ({
    type: TaskTypes.CHANGE_TASK_CATEGORY,
    payload,
  })
  
  export const changeTaskCategorySuccess = (payload) => ({
    type: TaskTypes.CHANGE_TASK_CATEGORY_SUCCESS,
    payload,
  })


  export const getCategories = (payload) => ({
    type: TaskTypes.GET_CATEGORIES,
    payload,
  })
  
  export const getCategoriesSuccess = (payload) => ({
    type: TaskTypes.GET_CATEGORIES_SUCCESS,
    payload,
  })

  export const updateCategory = (payload) => ({
    type: TaskTypes.UPDATE_CATEGORY,
    payload,
  })
  
  export const updateCategorySuccess = (payload) => ({
    type: TaskTypes.UPDATE_CATEGORY_SUCCESS,
    payload,
  })


  // export const getCategory = (payload) => ({
  //   type: TaskTypes.GET_CATEGORY,
  //   payload,
  // })
  
  // export const getCategorySuccess = (payload) => ({
  //   type: TaskTypes.GET_CATEGORY_SUCCESS,
  //   payload,
  // })


  export const createCategory = (payload) => ({
    type: TaskTypes.CREATE_CATEGORY,
    payload,
  })
  
  export const createCategorySuccess = (payload) => ({
    type: TaskTypes.CREATE_CATEGORY_SUCCESS,
    payload,
  })


  export const deleteCategory = (payload) => ({
    type: TaskTypes.DELETE_CATEGORY,
    payload,
  })
  
  export const deleteCategorySuccess = (payload) => ({
    type: TaskTypes.DELETE_CATEGORY_SUCCESS,
    payload,
  })


  export const reorderCategory = (payload) => ({
    type: TaskTypes.REORDER_CATEGORY,
    payload,
  })
  
  export const reorderCategorySuccess = (payload) => ({
    type: TaskTypes.REORDER_CATEGORY_SUCCESS,
    payload,
  })


  export const createStep = (payload) => ({
    type: TaskTypes.CREATE_STEP,
    payload,
  })
  
  export const createStepSuccess = (payload) => ({
    type: TaskTypes.CREATE_STEP_SUCCESS,
    payload,
  })


  export const toggleStep = (payload) => ({
    type: TaskTypes.TOGGLE_STEP,
    payload,
  })
  
  export const toggleStepSuccess = (payload) => ({
    type: TaskTypes.TOGGLE_STEP_SUCCESS,
    payload,
  })


  export const deleteStep = (payload) => ({
    type: TaskTypes.DELETE_STEP,
    payload,
  })
  
  export const deleteStepSuccess = (payload) => ({
    type: TaskTypes.DELETE_STEP_SUCCESS,
    payload,
  })


  export const updateStep = (payload) => ({
    type: TaskTypes.UPDATE_STEP,
    payload,
  })
  
  export const updateStepSuccess = (payload) => ({
    type: TaskTypes.UPDATE_STEP_SUCCESS,
    payload,
  })


  export const updateSteps = (payload) => ({
    type: TaskTypes.UPDATE_STEPS,
    payload,
  })
  
  export const updateStepsSuccess = (payload) => ({
    type: TaskTypes.UPDATE_STEPS_SUCCESS,
    payload,
  })


  export const createComment = (payload) => ({
    type: TaskTypes.CREATE_COMMENT,
    payload,
  })
  
  export const createCommentSuccess = (payload) => ({
    type: TaskTypes.CREATE_COMMENT_SUCCESS,
    payload,
  })


  export const getUsers = () => ( emit(socket, { action: TaskTypes.GET_USERS }))
  
  export const getUsersSuccess = (payload) => ({
    type: TaskTypes.GET_USERS_SUCCESS,
    payload,
  })

  
  export const uploadFile = (payload) => ({
    type: TaskTypes.UPLOAD_FILE,
    payload,
  })
  
  export const uploadFileSuccess = (payload) => ({
    type: TaskTypes.UPLOAD_FILE_SUCCESS,
    payload,
  })

  
  export const deleteFile = (payload) => ({
    type: TaskTypes.DELETE_FILE,
    payload,
  })
  
  export const deleteFileSuccess = (payload) => ({
    type: TaskTypes.DELETE_FILE_SUCCESS,
    payload,
  })

  export const displayActivitySuccess = (payload) => ({
    type: TaskTypes.DISPLAY_ACTIVITY_SUCCESS,
    payload,
  })

  export const markSeenActivitySuccess = (payload) => ({
    type: TaskTypes.MARK_SEEN_ACTIVITY_SUCCESS,
    payload,
  })

  export const setHorizontalScroll = (payload) => ({
    type: TaskTypes.SET_HORIZONTAL_SCROLL,
    payload,
  })

  export const setLastOpenedTask = (payload) => ({
    type: TaskTypes.SET_LAST_OPENED_TASK,
    payload,
  })