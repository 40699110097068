import React, { useState, useEffect } from 'react'
import './styles.scss'
import { Input } from '@makedonski/admin-ui'
import { useParams, Link, useLocation } from 'react-router-dom'
import { getSingleSchedule, getSchedule, getProductionRequests,
         getMachineSchedule, showLoading, hideLoading } from '../../actions'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../config/stores'
import moment from 'moment'
import { SubscriptionsOutlined } from '@mui/icons-material'

const nameMap = {
  'Вътрешен диаметър': 'ID',
  'Външен диаметър': 'OD',
  Коравина: 'SN'
}

const getProductData = (basicInfo) => {
  return basicInfo.map(el => {
    const name = nameMap[el.name] ? nameMap[el.name] : el.name
    return `${name}-${el.value}`
  }).join(' _ ')
}

export default function ProductionViewRequest () {
  const { id } = useParams()
  const { state } = useLocation()
  const penIcon = require('../../assets/icons/pen-icon.svg')
  const arrowIcon = require('../../assets/icons/blueArrowCircle.svg')
  const circleIcon = require('../../assets/icons/blueCircle.svg')
  // const [data, setData] = useState(state.data)

  //status !== 'pending' && product === 'pipe' -> use state.data

  const dispatch = useDispatch()
  const machineRequestData = useSelector((state) => state.production.currentSchedule) // getSingleSchedule
  const scheduleData = useSelector(state => state.schedule) // getSchedule
  const requests = useSelector(state => state.production.data)
  const stateData = state.data

  const [data, setData] = useState(requests.find(request => request._id === id))
  let machineId = (data && data?.status !== stateData?.status ? data?.scheduled?.id : stateData?.scheduled?.id) || id

  const productInfo = data?.product ? getProductData(data?.product?.basicInfo) : ''

  const checkStatus = (data) => {
    if (data?.request?.status === 'in-production' ||
    data?.status === 'in-production') {
      return 'В производство'
    } else if (data?.request?.status === 'finished' ||
    data?.status === 'finished') {
      return 'Произведена'
    } else {
      return 'В изчакване'
    }
  }

  useEffect(() => {
    // dispatch(getSingleSchedule({ _id: id }))
    // dispatch(getSchedule({_id: id}));
    dispatch(getProductionRequests({_id: id}))
    dispatch(showLoading())
  }, [])

  useEffect(() => {
    requests && setData(requests.find(request => request._id === id))
    checkStatus(requests.find(request => request._id === id))
    machineId = (data && data?.status !== stateData?.status ? data?.scheduled?.id : stateData?.scheduled?.id) || id
    // data && dispatch(hideLoading())
  }, [requests])

  return (
    <div className='production-view-request-wrapper'>
      <div className='request-view-container'>
        <div className='request-view-top'>
          <div className='headings-wrapper'>
            <h2>Преглед заявка</h2>
            <h3>
              Статус: <span>{checkStatus(data)}</span>
            </h3>
          </div>

          <div className='buttons-wrapper'>
            {(data?.request?.status === 'pending' ||
            data?.status === 'pending') ? (
              <>
                <Link to={{ pathname: `/production/edit/${id}`, state: { data } }}>
                  <img
                    src={penIcon} alt='edit'
                  />
                </Link>

                <button>
                  {data?.product?.productType?.name === 'Тръба' ? 
                  <Link
                    to={{ pathname: `/production/pipe/${id}`, state: { data: data } }}
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    Към производство
                  </Link> :

                  <Link
                  to={{ pathname: `/production/end-product/${id}`, state: { data: data } }}
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  Към производство
                </Link>
                  }

                  {/* Към производство */}
                </button>
              </>
                ) : (
                  <button>

                    {data?.product?.productType?.name === 'Тръба' ? 
                    <Link
                      to={{ pathname: `/production/pipe/${machineId}`, state: { stateData } }}
                      // to={{ pathname: `/production/pipe/${state?.data?.scheduled?.id}`, state: { stateData } }}
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Производствена карта
                    </Link> :

                    <Link
                      to={{ pathname: `/production/end-product/${id}`, state: { data } }}
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      Производствена карта
                    </Link>
                    }
                  </button>
                )}
          </div>
        </div>

        <div className='request-view-main'>
          <div className='view-request-main-dropdown-inputs-top'>
            <div className='project-section-wrapper'>
              <label htmlFor='project-input-view'>Проект</label>
              <Input.Text
                id='project-input-view' placeholder='' disabled
                value={data?.inquiry?.object}
              />
            </div>

            <div className='product-section-wrapper'>
              <label htmlFor='product-input-view'>Продукт</label>
              <div className='input-icon-wrapper'>
                <Input.Text
                  id='product-input-view'
                  placeholder={productInfo}
                  disabled
                />
                <img src={arrowIcon} onClick={() => history.push(`/products/${data.product._id}`)} />
              </div>
            </div>
          </div>

          <div className='view-request-main-dropdown-inputs-bottom'>
            <div className='quantity-section-wrapper'>
              <label htmlFor='quantity-input-view'>К-во</label>

              <div className='input-wrapper'>
                <Input.Text id='quantity-input' value={data?.quantity} />
                <p>{data?.product?.mainUnit || 'бр'}</p>
              </div>
            </div>

            <div className='dates-section-wrapper'>
              <label htmlFor='datepicker-view'>Срок за производство</label>
              <Input.Text
                id='datepicker-view'
                placeholder={moment(data?.deadline).format('DD/MM/YYYY')}
                style={{ fontWeight: 'bold' }}
                disabled
              />
            </div>

            {(data?.inquiry?.checklist?.length > 0 &&
            data?.inquiry?.checklist[data?.inquiry?.checklist?.length - 1]?.action === 'received') &&
            <div className='checklist-section-wrapper'>
              <div className='label'>Чеклист от клиент</div>

              <div className='file-wrapper'>
                  <div className='file-name' onClick={() => window.open(data?.inquiry?.checklist[data?.inquiry?.checklist?.length - 1]?.url)}>
                   {data?.inquiry?.checklist[data?.inquiry?.checklist?.length - 1]?.name}
                  </div>
                  <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
              </div>
            </div>}

            {(data?.drawing && Object.values(data?.drawing)) &&
            <div className='checklist-section-wrapper'>
              <div className='label'>Чертеж</div>

              <div className='file-wrapper'>
                  <div className='file-name' onClick={() => window.open(data?.drawing?.url)}>
                   {data?.drawing?.name}
                  </div>
                  <img src={require('../../assets/icons/download-icon.svg')} className="download-icon" />
              </div>
            </div>}
          </div>

          <div className='notes-section-wrapper'>
            <label htmlFor='notes-input'>Бележки</label>
            <Input.Textarea
              id='notes-input'
              value={data?.comments[0]?.text || ''}
              disabled
            />
          </div>
        </div>
      </div>

      {/* <div className='request-activity-container'>
        <div className='request-activity-heading'>
          <h2>Активност</h2>
        </div>

        <div className='request-activity-main-wrapper'>
          <div className='single-activity-wrapper'>
            <div className='icon-date-wrapper'>
              <img src={circleIcon} />
              <div className='activity-date'>12:45 | 12.12.2020</div>
            </div>

            <div className='activity-description'>
              <span>Гергана Красимирова</span> добави заявката към производство
            </div>
          </div>

          <div className='line-wrapper' />
        </div>
      </div> */}
    </div>
  )
}
