/* eslint-disable */
import React from "react"
import "./styles.scss"
import { forwardRef } from "react"

const TextInput = forwardRef(
  (
    {
      outerStyle,
      outerClassName,
      inputClassName,
      inputStyle,
      label,
      compulsory,
      disabled,
      center,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${outerClassName} input-container`} style={outerStyle}>
        {label && (
          <label className="input-header">
            {label}{" "}
            {compulsory && (
              <span style={{ padding: "0px 8px", color: "#E80111" }}>*</span>
            )}
          </label>
        )}
        <div
          className={`prefix-input-container ${inputClassName}`}
          style={inputStyle}
        >
          <input
            className={`inner-input`}
            type={"text"}
            disabled={disabled}
            {...props}
            ref={ref}
          />
        </div>
      </div>
    )
  }
)

export default TextInput
