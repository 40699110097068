import React, { useState, useEffect, useMemo, useRef } from "react"
import { connect } from "react-redux"
import { Button, Section } from "@makedonski/admin-ui"
import { Alerts } from "@makedonski/socourt-utilities"
import moment from "moment"
import {
  ProjectZonesPopup,
  ProjectStatusPopup,
  FileOffer,
  ActionItem,
  OfferElement,
  ProjectProductionElement,
  UserFilter,
} from "../../components"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import { Menu } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import {
  showModal,
  getInquiry,
  addDocumentsToInquiry,
  showLoading,
  updateInquiry,
  deleteProjectProduct,
  clearResults,
  searchInquiryCategories,
  resetAct,
  addZone,
  getZones,
  getHPRequests,
  editHPRequest,
  createInquiryChecklist,
  createInquiryStatus,
  getInquiryStatuses,
  hideModal,
  editProjectTeam,
} from "../../actions"
import Files from "../../utilities/files"
import "./styles.scss"
import { styled } from "@mui/material/styles"
import { Paper, TextField } from "@mui/material"

const mapStatuses = {
  projection: "Проектиране",
  "choosing-provider": "Избор на доставчик",
  auction: "Търг за изпълнители",
  lost: "Загубен",
  finished: "Приключил",
  other: "Друго",
}

const nameMap = {
  Тръба: "Материал",
  КМУ: "Ориентация",
  Резервоар: "Ориентация",
}

const InquiryListItem = ({ order, deleteProduct, showLoading }) => {
  const item = order.product
  const productName = React.useMemo(() => {
    let { name } = item.productType
    if (name === "Тръба") {
      const SN = item.basicInfo.find((el) => el.name === "Коравина")?.value
      const type = item.basicInfo.find((el) => el.name === "Произвежда се по")
        ?.value
      if (type === "Вътрешен диаметър") {
        const ID = item.basicInfo.find((el) => el.name === "Вътрешен диаметър")
          ?.value
        name = `SN${SN}ID${ID}`
      } else {
        const OD = item.basicInfo.find((el) => el.name === "Външен диаметър")
          ?.value
        name = `SN${SN}OD${OD}`
      }
    } else {
      if (nameMap[name]) {
        const basicInfoDetail = item.basicInfo.find(
          (b) => b.name === nameMap[name]
        )
        if (basicInfoDetail) {
          name += ` | ${basicInfoDetail.value}`
        }
      }
    }

    return name
    //eslint-disable-next-line
  }, [order])

  const productVariation = React.useMemo(() => {
    // let { name } = item.productType
    return item.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")
    //eslint-disable-next-line
  }, [order])

  return (
    <div className="material-item">
      <div className="flex-container space-between flex1">
        <div className="material-element left border-right">
          <p>{productName}</p>
          <span>{productVariation}</span>
        </div>
        <div
          className="arrow-right-coloured"
          onClick={() => {
            window.open(`/products/${item._id}`)
          }}
        />
      </div>
      <div className="material-element mid border-right">
        <span>Количество</span>
        <p>
          {order.amount}
          {order?.product?.mainUnit}.
        </p>
      </div>
      <div className="material-element right">
        <span>Срок за доставка:</span>
        <p>{moment(order.deliveryDate).format("DD.MM.YYYY[г.]")}</p>
      </div>
      <div
        className="delete-btn"
        onClick={() => {
          deleteProduct({ _id: order._id })
          showLoading()
        }}
      />
    </div>
  )
}

const SingleProject = (props) => {
  const [statusesAnchorEl, setStatusesAnchorEl] = useState(null)
  let showUsers = useRef()
  const statusesOpen = Boolean(statusesAnchorEl)
  const statusesHandleClick = (event) => {
    setStatusesAnchorEl(event.currentTarget)
  }
  const statusesHandleClose = () => {
    setStatusesAnchorEl(null)
  }
  const [zonesAnchorEl, setZonesAnchorEl] = useState(null)
  const open = Boolean(zonesAnchorEl)
  const handleClick = (event) => {
    setZonesAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setZonesAnchorEl(null)
  }
  let showProjectStatus = React.useRef()
  let showProjectZones = React.useRef()
  const fileInputRef = React.useRef()
  const [currentInputFileType, setCurrentInputFileType] = useState("")
  const [tabButton, setTabButton] = useState("Активност")
  const _id = props.match.params.id
  const { inquiry, createdActOfAcceptance } = props

  useEffect(() => {
    props.get({ _id })
    props.getZones()
    //eslint-disable-next-line
  }, [_id])

  useEffect(() => {
    props.searchInquiryCategories({ search: "" })
    //eslint-disable-next-line
  }, [searchInquiryCategories])

  useEffect(() => {
    if (inquiry?.zones && !inquiry?.zones[0]?.name) {
      inquiry.zones = inquiry.zones.map((zoneId) =>
        props.zones.find((zoneObj) => zoneObj._id === zoneId)
      )
    }
  }, [inquiry?.zones])

  useEffect(() => {
    props.getHPRequests()
  }, [])

  const openFileInput = (type) => {
    setCurrentInputFileType(type)
    fileInputRef.current.click()
  }

  let productsArray = []

  inquiry &&
    inquiry.orders.forEach((order) => {
      if (order.product) {
        if (
          productsArray.findIndex(
            (el) => el.product._id === order.product._id
          ) === -1
        ) {
          productsArray.push(order)
        }
      }
    })

  // 'offer', 'proform', 'invoice', 'contract', 'annex', 'actOfAcceptance', 'other'
  const handleFileUpload = (e) => {
    props.loading({ text: "Качване на файлове..." })
    const promises = []
    Array.from(e.target.files).forEach((f) => {
      const fileObject = {
        name: f.name,
        type: currentInputFileType,
      }
      const result = new Promise((resolve) => {
        Files.uploadFile(f).then((res) => {
          fileObject.url = res.location
          resolve(fileObject)
        })
      })
      promises.push(result)
    })
    Promise.all(promises).then((data) => {
      props.addDocuments({ documents: data, _id: inquiry._id })
    })
  }

  const firstComment = React.useMemo(() => {
    if (!inquiry) return null
    if (inquiry.comments.length) {
      return inquiry.comments[0]
    }
    return null
  }, [inquiry])

  const getDocumentsByType = (type) => {
    return inquiry.documents.filter((doc) => doc.type === type)
  }

  const worker = useMemo(() => {
    if (!inquiry) return null
    if (inquiry.working_on.length) {
      return inquiry.working_on[0].user
    }
    return null
  }, [inquiry])

  const workerPhoto = useMemo(() => {
    if (worker) {
      if (!worker.coverPhoto) return global.config.defaultAvatar
      if (worker.coverPhoto.indexOf("sindi-trud-admin.s3") !== -1)
        return global.config.defaultAvatar
      return worker.coverPhoto || global.config.defaultAvatar
    }
    return global.config.defaultAvatar
  }, [worker])

  if (!props.inquiry)
    return (
      <div className="single-project-main-container">
        <Section.Loader loading text="Зареждане на данни" />
      </div>
    )

  const uniqueProductOrdersIDS = new Set()
  const uniqueProductOrders = []
  props.inquiry.orders.forEach((order) => {
    if (order.product) {
      uniqueProductOrdersIDS.add(order.product._id)
    }
  })
  uniqueProductOrdersIDS.forEach((id) => {
    const order = props.inquiry.orders.find(
      (order) => order.product && order.product._id === id
    )
    if (order) {
      uniqueProductOrders.push({
        orderQuantity: order.amount,
        ...order.product,
      })
    }
  })
  let statusColor
  switch (mapStatuses[inquiry.status] || inquiry.status) {
    case "Търг за изпълнители":
      statusColor = "#2523E7"
      break
    case "Избор на доставчик":
      statusColor = "red"
      break
    case "Проектиране":
      statusColor = "green"
      break
    case "Загубен":
      statusColor = "purple"
      break
    case "Приключил":
      statusColor = "blue"
      break
    case "Друго":
      statusColor = "cyan"
      break
    default:
      statusColor = "black"
      break
  }

  console.log(inquiry)
  return (
    <div className="single-project-main-container">
      <div className="project-column flex-column">
        <div className="project-row blocks top">
          <div className="inner-project-row first">
            <div className="left-row">
              <h3 className="header-inline-button">{inquiry.object}</h3>
            </div>
            <div className="heading-options">
              <Button.Icon
                name="chat"
                size={18}
                color="#26ace2"
                className=""
                iconStyles=""
                onClick={() =>
                  props.showModal({
                    type: "projectCommentsModal",
                    width: "800px",
                    data: { inquiry: inquiry._id },
                  })
                }
              />
              <Button.Icon
                name="pen"
                size={14}
                color="#26ace2"
                className=""
                iconStyles=""
                onClick={() =>
                  props.showModal({
                    type: "editProductModal",
                    width: "600px",
                    overflow: "visible",
                  })
                }
              />
            </div>
          </div>
          <div className="inner-project-col border-bottom">
            <div className="status-container">
              <div className="status-heading">
                <p>Статус</p>
                <Section.Popup
                  ref={(ref) => {
                    showProjectStatus = ref
                  }}
                  content={
                    <ProjectStatusPopup
                      onClose={() => showProjectStatus.hide()}
                      status={inquiry.status}
                      additionalStatuses={inquiry.additionalStatuses}
                      onUpdate={(status, additionalStatuses) => {
                        props.updateInquiry({
                          _id: inquiry._id,
                          status,
                          additionalStatuses,
                        })
                        showProjectStatus.hide()
                      }}
                      onCreate={(newStatus) => {
                        props.updateInquiry({
                          _id: inquiry._id,
                          additionalStatuses: [
                            ...inquiry.additionalStatuses,
                            newStatus,
                          ],
                        })

                        props.createInquiryStatus({ name: newStatus })
                        props.getInquiryStatuses()
                      }}
                    />
                  }
                >
                  <Button.Icon
                    name="pen"
                    size={14}
                    color="#26ace2"
                    className=""
                    iconStyles=""
                    onClick={() => showProjectStatus.show()}
                  />
                </Section.Popup>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="status-state-container"
                  style={{ backgroundColor: statusColor }}
                >
                  <p>
                    {mapStatuses[inquiry.status] !== undefined
                      ? mapStatuses[inquiry.status]
                      : inquiry.status}
                  </p>
                </div>
                {inquiry.additionalStatuses.length > 0 && (
                  <p
                    style={{
                      marginLeft: "5px",
                      padding: "10px",
                      background: "#E6EEF4",
                      cursor: "pointer",
                    }}
                    onClick={statusesHandleClick}
                  >
                    + {inquiry.additionalStatuses.length}
                  </p>
                )}
                <Menu
                  open={statusesOpen}
                  anchorEl={statusesAnchorEl}
                  onClose={statusesHandleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 5px",
                    }}
                  >
                    <p>Статуси към проект</p>
                    <CloseIcon
                      onClick={statusesHandleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {inquiry.additionalStatuses.map((stat, index) => (
                    <div
                      key={index}
                      className="menu-zone"
                      style={{ backgroundColor: statusColor }}
                    >
                      <p className="zone-text">{mapStatuses[stat] || stat}</p>
                    </div>
                  ))}
                </Menu>
              </div>
            </div>
            <div className="zones-container">
              <div className="zone-heading">
                <p>Зона</p>
                <Section.Popup
                  ref={(ref) => {
                    showProjectZones = ref
                  }}
                  content={
                    <ProjectZonesPopup
                      zones={props.zones}
                      inquiryZones={inquiry.zones}
                      zone={true}
                      onClose={() => showProjectZones.hide()}
                      onUpdate={(zones) => {
                        const preFlightParse = zones.map(({ _id }) => _id)
                        props.updateInquiry({
                          _id: inquiry._id,
                          zones: [...preFlightParse],
                        })
                        showProjectZones.hide()
                      }}
                      onCreate={(zone) => {
                        props.addZone({ name: zone })
                      }}
                    />
                  }
                >
                  <Button.Icon
                    name="pen"
                    size={14}
                    color="#26ace2"
                    className=""
                    iconStyles=""
                    onClick={() => showProjectZones.show()}
                  />
                </Section.Popup>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {inquiry.zones.map((zone, index) => (
                  <>
                    {index < 2 && (
                      <div
                        key={zone?._id}
                        className="zone"
                        style={{ backgroundColor: statusColor }}
                      >
                        <p className="zone-text">
                          {zone?.name ||
                            props.zones.find((zoneObj) => zoneObj._id === zone)
                              ?.name}
                          {/* {zone.name} */}
                        </p>
                      </div>
                    )}
                  </>
                ))}
                {inquiry.zones.length > 2 && (
                  <p
                    style={{
                      padding: "10px",
                      background: "#E6EEF4",
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    + {inquiry.zones.length - 2}
                  </p>
                )}
                <Menu
                  open={open}
                  anchorEl={zonesAnchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 5px",
                    }}
                  >
                    <p>Зони към проект</p>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {inquiry.zones.map((zone, index) => (
                    <>
                      {index > 1 && (
                        <div
                          key={zone._id}
                          className="menu-zone"
                          style={{ backgroundColor: statusColor }}
                        >
                          <p className="zone-text">
                            {zone?.name ||
                              props.zones.find(
                                (zoneObj) => zoneObj._id === zone
                              ).name}
                          </p>
                        </div>
                      )}
                    </>
                  ))}
                </Menu>
              </div>
            </div>
          </div>
          <div className="inner-project-row border-bottom">
            <div className="small-container">
              <span>Клиент:</span>
              <p>{inquiry.client}</p>
            </div>
            <div className="small-container">
              <span>Бюджет</span>
              {inquiry?.budget ? (
                <p>{Number(inquiry?.budget?.toFixed(2))} лв.</p>
              ) : (
                <p>--</p>
              )}
            </div>
          </div>
          <div className="lower-card-container">
            <div className="small-container">
              <span>Получено на:</span>
              <p>{moment(inquiry.createdAt).format("DD.MM.YYYY[г.]")}</p>
            </div>
            <div className="small-container">
              <div className="upper-medium-container">
                <span>Поета от:</span>
                <Button.Icon
                  name="timer-clock"
                  size={16}
                  color="#26ace2"
                  className=""
                  iconStyles=""
                  onClick={() =>
                    props.showModal({
                      type: "workFlowModal",
                      width: "600px",
                      overflow: "visible",
                      data: { inquiry: inquiry._id },
                    })
                  }
                />
              </div>
              <Button.Image
                src={workerPhoto}
                name={worker?.fullName}
              />
            </div>
          </div>
          <div className="lower-card-container">
            <div className="small-container">
              <div className="upper-medium-container">
                <span>Екип:</span>

                <Section.Popup
                  ref={(ref) => (showUsers = ref)}
                  content={
                    <UserFilter
                      noReason
                      multipleSelect
                      excludedUsers={[inquiry?.working_on?.[0]?.user?._id]}
                      selectedUsers={inquiry.team.map((user) => user._id)}
                      onSelectUsers={(users) => {
                        props.editProjectTeam({
                          _id: _id,
                          team: users,
                        })
                        showUsers.hide()
                      }}
                    />
                  }
                >
                  <Button.Icon
                    name="pen"
                    size={16}
                    color="#26ace2"
                    className=""
                    iconStyles=""
                    onClick={() => showUsers.show()}
                  />
                </Section.Popup>
              </div>
              <div className="team-container">
                {!inquiry?.team?.length ? (
                  <p style={{ fontStyle: "italic", fontSize: "16px" }}>
                    Няма добавени участници
                  </p>
                ) : (
                  inquiry?.team?.map((user) => (
                    <Button.Image
                      src={user?.coverPhoto}
                      name={user?.fullName}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="project-row blocks flex-start bottom">
          <div className="project-row-title">
            <h3>Запитване за:</h3>
            <Button.Raised
              text="Добави"
              onClick={() => {
                props.clearResults()
                props.showModal({
                  type: "addProductModal",
                  width: "1100px",
                  overflow: "visible",
                  data: {
                    inquiry: inquiry._id,
                    requests: props.HPRequests,
                  },
                })
              }}
            />
          </div>
          <div className="scroll-container">
            {inquiry.orders
              .filter((order) => order.product !== null)
              .map((order) => {
                return (
                  <InquiryListItem
                    deleteProduct={props.deleteProjectProduct}
                    order={order}
                    key={order._id}
                    showLoading={props.loading}
                  />
                )
              })}
          </div>
        </div>
      </div>
      <div className="project-column">
        <div className="project-column-inner blocks">
          <div className="middle-top-container">
            <h3>Карта на дейността</h3>
            <div className="icons-container">
              <div
                className="email-icon"
                onClick={() => {
                  props.showModal({
                    type: "emailAddressModal",
                    width: "50%",
                    data: inquiry.emailTo,
                  })
                }}
              />
              <Button.Icon
                name="plus"
                size={20}
                color="#26ace2"
                onClick={() =>
                  props.showModal({
                    type: "addProjectActivityModal",
                    width: "600px",
                    overflow: "visible",
                    data: { project: props.inquiry },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="project-column-inner-2">
          <div className="tab-buttons-middle-column">
            <div
              className={`button-middle-column ${
                tabButton === "Активност" ? "active" : null
              }`}
              onClick={() => setTabButton("Активност")}
            >
              Активност
            </div>
            <div
              className={`button-middle-column ${
                tabButton === "Производство" ? "active" : null
              }`}
              onClick={() => setTabButton("Производство")}
            >
              Производство
            </div>
          </div>
          <div className="scroll-container">
            {tabButton === "Активност" ? (
              <>
                {inquiry.actions.map((action) => {
                  return (
                    <ActionItem
                      dotColour="#26ace2"
                      item={action}
                      categories={props.categories}
                    />
                  )
                })}
              </>
            ) : (
              <>
                {productsArray.map((el) => {
                  if (el.product) {
                    return (
                      <ProjectProductionElement
                        product={el}
                        inquiry={inquiry}
                        data={el}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="project-column blocks flex-column-files">
        <h3>Документи</h3>
        <input
          ref={fileInputRef}
          type="file"
          accepts="*"
          className="file-input-hidden"
          onChange={handleFileUpload}
          multiple
        />
        <div className="scroll-container max">
          <div className="top-documents-container">
            <div className="offer-btn-container">
              <p>Оферта</p>
            </div>
            <Button.Raised
              text="Създай"
              className="blue"
              onClick={() => {
                if (uniqueProductOrders.length < 1) {
                  Alerts.error(
                    "Грешка",
                    "Моля добавете продукти към запитване преди да създадете оферта"
                  )
                } else {
                  props.showModal({
                    type: "offerCreation",
                    width: "90%",
                    data: {
                      _id: props.inquiry._id,
                      includedProducts: uniqueProductOrders,
                    },
                  })
                }
              }}
            />
          </div>
          {inquiry.offers &&
            inquiry.offers.map((offer, index) => {
              return (
                <OfferElement
                  inquiry={inquiry}
                  index={index}
                  offer={offer}
                  includedProducts={uniqueProductOrders}
                />
              )
            })}
          <div className="regular-flex-border-bottom-container">
            <p>Проформа фактура</p>
            <Button.Raised
              text="Създай"
              className="blue"
              onClick={() => {
                if (uniqueProductOrders.length < 1) {
                  Alerts.error(
                    "Грешка",
                    "Моля добавете продукти към запитване преди да създадете проформа-фактура"
                  )
                } else {
                  props.showModal({
                    type: "proformInvoiceModal",
                    width: "90%",
                    overflow: "visible",
                    data: {
                      _id: props.inquiry._id,
                      includedProducts: uniqueProductOrders,
                    },
                  })
                }
              }}
            />
            {/* <Button.Raised
              text="Добави"
              onClick={() => {
                openFileInput("proform")
              }}
            /> */}
          </div>
          {inquiry.proform_invoices.map((proform, index) => {
            return (
              <OfferElement
                inquiry={inquiry}
                index={index}
                proform={proform}
                includedProducts={uniqueProductOrders}
              />
            )
          })}
          {getDocumentsByType("proform").map((doc) => (
            <FileOffer key={doc._id} doc={doc} />
          ))}
          <div className="regular-flex-border-bottom-container">
            <div className="dummy-wrapper">
              <p>Фактура</p>
              <div className="dummy-btn" />
            </div>
            <Button.Raised
              text="Създай"
              className="blue"
              onClick={() => {
                if (uniqueProductOrders.length < 1) {
                  Alerts.error(
                    "Грешка",
                    "Моля добавете продукти към запитване преди да създадете фактура"
                  )
                } else {
                  props.showModal({
                    type: "invoiceCreation",
                    width: "90%",
                    data: {
                      _id: props.inquiry._id,
                      includedProducts: uniqueProductOrders,
                    },
                  })
                }
              }}
            />
          </div>
          {inquiry.invoices &&
            inquiry.invoices.map((invoice, index) => {
              return (
                <OfferElement
                  inquiry={inquiry}
                  index={index}
                  invoice={invoice}
                  includedProducts={uniqueProductOrders}
                />
              )
            })}
          {getDocumentsByType("invoice").map((doc) => (
            <FileOffer key={doc._id} doc={doc} />
          ))}
          <div className="regular-flex-border-bottom-container">
            <div className="dummy-wrapper">
              <p>ППП</p>
              <div className="dummy-btn big" />
            </div>
            <Button.Raised
              text="Създай"
              className="blue"
              onClick={() => {
                props.showModal({
                  type: "actOfAcceptanceModal",
                  width: "90%",
                  data: {
                    _id: props.inquiry._id,
                    includedProducts: uniqueProductOrders,
                  },
                })
              }}
            />
          </div>
          {inquiry.actsofacceptance &&
            inquiry.actsofacceptance.map((act, index) => {
              return <OfferElement inquiry={inquiry} index={index} act={act} />
            })}
          {createdActOfAcceptance && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Paper
                variant="outlined"
                elevation={1}
                sx={{
                  borderColor: "#FF7590",
                  height: "45px",
                  padding: "0 10px;",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    margin: "0px",
                  }}
                >
                  Моля принтирайте инструкции за монтаж и товаро-разтоварване!
                </p>
              </Paper>
              <CheckCircleRoundedIcon
                style={{ color: "#25E083", cursor: "pointer" }}
                onClick={() => props.resetAct()}
              />
            </div>
          )}

          <div
            className="regular-flex-border-bottom-container"
            style={{ display: "none" }}
          >
            <div className="dummy-wrapper">
              <p>ППП</p>
              <div className="dummy-btn big" />
            </div>
            <Button.Raised
              text="Създай"
              className="blue"
              onClick={() => {
                props.showModal({
                  type: "actOfAcceptanceModal",
                  width: "90%",
                  data: {
                    _id: props.inquiry._id,
                    includedProducts: uniqueProductOrders,
                  },
                })
              }}
            />
            {/* <Button.Raised
              text="Добави"
              onClick={() => {
                openFileInput("actOfAcceptance")
              }}
            /> */}
          </div>

          <div className="regular-flex-border-bottom-container">
            <div className="dummy-wrapper flex-col">
              <p>Чеклист</p>
              <div className="file-wrapper">
                <div
                  className="file-name"
                  onClick={() =>
                    window.open(
                      "https://dev-fidweb.s3.amazonaws.com/tasks/CHECK LIST.xlsx"
                    )
                  }
                >
                  Checklist-template.xlsx
                </div>
                <img
                  src={require("../../assets/icons/download-icon.svg")}
                  className="download-icon"
                />
              </div>
            </div>
            {(inquiry?.checklist?.length < 2 || !inquiry?.checklist) && (
              <Button.Raised
                text={inquiry?.checklist?.length === 1 ? "Получен" : "Изпратен"}
                className="green solid"
                onClick={() => {
                  props.showModal({
                    type: "addChecklistModal",
                    width: "40%",
                    data: {
                      inquiryId: inquiry?._id || "",
                      action:
                        inquiry?.checklist?.length == 1 ? "received" : "sent",
                      onSave: (payload) => {
                        props.loading({ text: "Качване на файлове..." })
                        props.createInquiryChecklist(payload)
                        props.hideModal()
                      },
                      marginTop: "15%",
                    },
                  })
                }}
              />
            )}
            {/* <Button.Raised
              text="Добави"
              onClick={() => {
                openFileInput("actOfAcceptance")
              }}
            /> */}
          </div>

          {
            <div className="checklists-wrapper">
              {inquiry?.checklist?.length > 0 &&
                inquiry?.checklist?.map((file, index) => {
                  return (
                    <div className="file-container">
                      <div className="file-wrapper">
                        <div
                          className="file-name"
                          onClick={() => window.open(file?.url)}
                        >
                          {file?.name}
                        </div>
                        <img
                          src={require("../../assets/icons/download-icon.svg")}
                          className="download-icon"
                        />
                      </div>

                      <div className="details-info">
                        <span className="green">{`${
                          file?.action === "received"
                            ? "Получен на "
                            : "Изпратен на "
                        }`}</span>
                        <span>
                          {moment(file?.createdAt).format("DD.MM.YYYY, HH:mm")}
                        </span>
                        <span>
                          {" "}
                          | <span>{file?.userName}</span>
                        </span>
                      </div>
                    </div>
                  )
                })}
            </div>
          }

          <div className="bottom-documents-container">
            <div className="bottom-documents-top-container">
              <p>Други документи и материали</p>
              <Button.Raised
                text="Добави"
                onClick={() => {
                  openFileInput("other")
                }}
              />
            </div>
          </div>
          {getDocumentsByType("other").map((doc) => (
            <FileOffer key={doc._id} doc={doc} />
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  zones: state.inquiries.zones,
  inquiry: state.inquiries.current,
  createdActOfAcceptance: state.inquiries.createdActOfAcceptance,
  categories: state.inquiries.categories,
  HPRequests: state.storage.HPRequests,
})

const mapDispatchToProps = (dispatch) => ({
  addZone: (payload) => dispatch(addZone(payload)),
  getZones: () => dispatch(getZones()),
  showModal: (payload) => dispatch(showModal(payload)),
  hideModal: (payload) => dispatch(hideModal(payload)),
  get: (payload) => dispatch(getInquiry(payload)),
  addDocuments: (payload) => dispatch(addDocumentsToInquiry(payload)),
  loading: (payload) => dispatch(showLoading(payload)),
  updateInquiry: (payload) => dispatch(updateInquiry(payload)),
  deleteProjectProduct: (payload) => dispatch(deleteProjectProduct(payload)),
  clearResults: () => dispatch(clearResults()),
  searchInquiryCategories: (payload) =>
    dispatch(searchInquiryCategories(payload)),
  resetAct: () => dispatch(resetAct()),
  getHPRequests: () => dispatch(getHPRequests()),
  editHPRequest: (payload) => dispatch(editHPRequest(payload)),
  createInquiryChecklist: (payload) =>
    dispatch(createInquiryChecklist(payload)),
  createInquiryStatus: (payload) => dispatch(createInquiryStatus(payload)),
  getInquiryStatuses: (payload) => dispatch(getInquiryStatuses()),
  editProjectTeam: (payload) => dispatch(editProjectTeam(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleProject)
