import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Auth, Alerts } from '@makedonski/socourt-utilities'
import { Input, Button, Section } from '@makedonski/admin-ui'
import moment from 'moment'
import {
  hideModal,
  addGeneralComment,
  editGeneralComment,
  deleteGeneralComment
} from '../../../actions'

import './styles.scss'

const handleKeyPress = (event, comment, setComment, data, addComment) => {
  if (event.keyCode === 13) {
    if (comment !== '') {
      const newComment = {
        text: comment,
        ref: data.id,
        refType: data.refType
      }
      addComment(newComment)
      setComment('')
    } else {
      Alerts.error('Error', 'Comment is empty!')
    }
  }
}

const CommentsModal = ({
  hideModal,
  data,
  addComment,
  editComment,
  deleteComment
}) => {
  const [comment, setComment] = useState('')
  return (
    <div className='comments-modal-container'>
      <div className='top-container'>
        <h3>Коментари</h3>
        <div className='exit-btn' onClick={() => hideModal()} />
      </div>
      <div className='scroll-container'>
        {data.comments.map((comment) => {
          return (
            <Section.Comment
              // readOnly={Auth.getUser()._id !== comment.owner._id}
              readOnly
              text={comment.text}
              date={moment(comment.createdAt).format('HH:mm | DD.MM.YYYY')}
              name={comment.owner.fullName}
              src={comment.owner.coverPhoto || global.config.defaultAvatar}
              onEdit={(text) =>
                editComment({
                  _id: comment._id,
                  text: text,
                  ref: data.id,
                  refType: data.refType
                })}
              onDelete={() =>
                deleteComment({
                  _id: comment._id,
                  ref: data.id,
                  refType: data.refType
                })}
            />
          )
        })}
      </div>
      <div className='input-container'>
        <Button.Image
          src={Auth.getUser().coverPhoto || global.config.defaultAvatar}
        />
        <Input.Text
          value={comment}
          placeholder='Коментар'
          onChange={(event) => setComment(event.target.value)}
          onKeyDown={(event) =>
            handleKeyPress(event, comment, setComment, data, addComment)}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  addComment: (payload) => dispatch(addGeneralComment(payload)),
  editComment: (payload) => dispatch(editGeneralComment(payload)),
  deleteComment: (payload) => dispatch(deleteGeneralComment(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentsModal)
