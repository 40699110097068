import { combineReducers } from "redux"
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from "react-router-redux"
// import { Tasks, StatusDetection, Chat } from "@makedonski/admin-ui"

import starter from "./starter"
import user from "./user"
import general from "./general"
import modal from "./modal"
import products from "./products"
import variations from "./variations"
import providers from "./providers"
import inquiries from "./inquiries"
import storage from "./storage"
import activities from "./activities"
import production from "./production"
import references from "./references"
import machineSettings from "./machineSettings"
import tasks from "./tasks"
import contacts from "./contacts"

// const taskReducer = Tasks.reducer

export default combineReducers({
  starter,
  routerReducer,
  user,
  general,
  modal,
  // taskReducer,
  products,
  variations,
  providers,
  inquiries,
  activities,
  // StatusDetection: StatusDetection.reducer,
  // chat: Chat.reducer,
  storage,
  production,
  references,
  machineSettings,
  tasks,
  contacts,
})
