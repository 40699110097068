import React, { useEffect, useState } from "react"
import { Inputs } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { validations, defaultValues } from "./contactValidations"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import "reactjs-popup/dist/index.css"
import { history } from "../../../config/stores"
import ContactForm from "../../../components/ContactComponents/ContactForm"
import { addContact, getCurrentUser } from "../../../actions"
import moment from "moment"

const AddContactManually = () => {
  const isMobile = window.innerWidth < 950
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.user.currentUser)

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validations),
    mode: "onSubmit",
    defaultValues: defaultValues,
  })
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    dispatch(getCurrentUser())
  })

  const handleCreate = (e) => {
    dispatch(
      addContact({
        ...e,
        ...(e?.forProducts && {
          forProducts: e?.forProducts?.map((prod) => prod?.value),
        }),
        ...(e?.mainComment &&
          e?.mainComment?.length && { mainComment: e?.mainComment }),
        ...(e?.mainComment &&
          e?.mainComment?.length && {
            comments: [
              {
                from: currentUser?.fullName,
                createdAt: moment().toISOString(),
                text: e?.mainComment,
              },
            ],
          }),

        onSuccess: (res) => history.push("/contacts"),
      })
    )
  }

  console.log(watch())

  return (
    <div className="main-container" style={{ height: "93%" }}>
      <div className="inner-header-container">
        <div
          className={`flex w-full ${
            !isMobile && "justify-between"
          } items-center`}
        >
          {isMobile && (
            <img
              src={require("../../../assets/icons/closeIcon.svg")}
              style={{
                width: "15px",
                height: "15px",
                cursor: "pointer",
                margin: "2px 15px 0px 5px",
              }}
              onClick={() => history.push("/contacts")}
            />
          )}
          <h3
            className="inner-title"
            style={{
              marginLeft: "0px",
              marginTop: isMobile && "0px",
              marginBottom: isMobile && "0px",
            }}
          >
            Нов контакт
          </h3>
          {!isMobile && (
            <div
              className="flex items-center"
              style={{ width: "260px", marginRight: "10px", gap: "10px" }}
            >
              <Inputs.Button
                text="Откажи"
                style={{ width: "130px" }}
                onClick={() => history.push("/contacts")}
              />
              <Inputs.Button
                text="Добави"
                selected
                style={{ width: "130px" }}
                onClick={handleSubmit(handleCreate)}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="body-container"
        style={{ height: isMobile && "unset", padding: isMobile && "1.5vw" }}
      >
        <ContactForm
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
          currentUser={currentUser}
          canEdit
        />
        {isMobile && (
          <Inputs.Button
            text={"Добави контакт"}
            selected
            style={{ width: "100%", margin: "20px 0px" }}
            onClick={handleSubmit(handleCreate)}
          />
        )}
      </div>
    </div>
  )
}

export default AddContactManually
