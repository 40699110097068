import React from 'react'
import './styles.scss'

export default ({ tabs, onChange, selected, style }) => {
  const onHandleChange = (id) => {
    if (onChange) {
      onChange(id)
    }
  }
  return (
    <div className="component-tabs">
      {tabs.map((tab) => {
        return (
          <div
            style={style}
            onClick={() => onHandleChange(tab.id)}
            className={`component-tab-element ${selected === tab.id ? 'selected' : ''}`}
            key={tab.id}
          >
            {tab.name}
          </div>
        )
      })}
    </div>
  )
}
