import { GeneralTypes } from '../actions'

const initialState = {
  loading: false,
  loadingText: '',
  success: false,
  stopWork: false,
}

const general = (state = initialState, { type, payload }) => {
  switch (type) {
    case GeneralTypes.SHOW_LOADING:
      return { ...state, loading: true, loadingText: payload ? payload.text || '' : '' }
    case GeneralTypes.HIDE_LOADING:
      return { ...state, loading: false, loadingText: '' }
    case GeneralTypes.SHOW_SUCCESS:
      return { ...state, success: true }
    case GeneralTypes.HIDE_SUCCESS:
      return { ...state, success: false }
    case GeneralTypes.ERROR:
      return { ...state, loading: false, loadingText: '' }
    case GeneralTypes.STOR_WORK:
      return { ...state, stopWork: payload }

    default:
      return state
  }
}

export default general
