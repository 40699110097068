import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import {
  ProductTypes,
  GeneralTypes,
  getProductSuccess,
  getSingleProductSuccess,
  createProductSuccess,
  updateProductExpenseSuccess,
  updateProductProductionSuccess,
  searchProductSuccess,
  searchProductMoreSuccess,
  updateProductSuccess,
  editExpensesSuccess,
  editProdictionSuccess,
  hideModal
} from '../actions'
import { API_URL } from '../config/settings'
import { history } from '../config/stores'

export const getProductTypesEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_PRODUCT_TYPES),
    switchMap(() => {
      return Api.get(`${API_URL}/products/types`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductTypes.GET_PRODUCT_TYPES_SUCCESS,
              payload: response.types
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getAllProducts = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_ALL_PRODUCTS),
    switchMap(({ payload }) => {
      return Api.get(
        `${API_URL}/products`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ProductTypes.GET_ALL_PRODUCTS_SUCCESS,
              payload: response.products
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'Невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getProductEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_PRODUCT),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/products/search`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (response.product && window.location.pathname !== '/storage') {
              obs.next(getProductSuccess(response.product))
              history.push(`/products/${response.product._id}`)
            }
            if (response.products) {
              obs.next(searchProductMoreSuccess(response.products))
            }
            obs.complete()
            if (!response.product && !response.products) {
              Alerts.error(
                'Не е намерен продукт',
                'Моля въведете други данни за търсенето!'
              )
            }
            // obs.next(getProductSuccess(response.product))
            // obs.complete()
            // if (response.product) {
            //   history.push(`/products/${response.product._id}`)
            // } else {
            //   Alerts.error('Не е намерен продукт', 'Моля въведете други данни за търсенето!')
            // }
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'Невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchProductEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.SEARCH_PRODUCT),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/products/search`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (response.product) {
              obs.next(searchProductSuccess(response.product))
            }
            if (response.products) {
              obs.next(searchProductMoreSuccess(response.products))
            }
            obs.complete()
            if (!response.product && !response.products) {
              Alerts.error(
                'Не е намерен продукт',
                'Моля въведете други данни за търсенето!'
              )
            }
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'Невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getSingleProductEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.GET_SINGLE_PRODUCT),
    switchMap(({ payload }) => {
      return Api.get(`${API_URL}/products/${payload.id}`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(getSingleProductSuccess(response.product))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createProductEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.CREATE_PRODUCT),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/products`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(createProductSuccess(response.product))
            obs.next(hideModal())
            history.push(`/products/${response.product._id}`)
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateProductEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.UPDATE_PRODUCT),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/products`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(updateProductSuccess(response.product))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateProductExpenseEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.UPDATE_EXPENSE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/products/expense`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(updateProductExpenseSuccess(response.product))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'Невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProductProductionEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.EDIT_PRODUCTION),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/products/production`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(editProdictionSuccess(response.product))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'Невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProductExpenseEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.EDIT_EXPENSE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/products/expense`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(editExpensesSuccess(response.product))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'Невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateProductProductionEpic = (action$) => {
  return action$.pipe(
    ofType(ProductTypes.UPDATE_PRODUCTION),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/products/production`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(updateProductProductionSuccess(response.product))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error('Грешка', err.response.message)
          } else {
            Alerts.error('Грешка', 'Невалидни данни!')
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
