import { DatePicker } from "@mui/lab"
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  InputBase,
  Paper,
  Select,
  TextField,
} from "@mui/material"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import styles from "./RequestForm.module.scss"
import moment from "moment"
import {
  hideModal,
  getInquiries,
  postProductionRequest,
  getPerformers,
  addOtherAction,
} from "../../actions"
import { history } from "../../config/stores"

function RequestForm({
  hide,
  data,
  getList,
  inquiries,
  postProductionRequestAction,
  getPerformers,
  addOtherAction,
  performers,
}) {
  useEffect(() => {
    getList()
  }, [])
  const [project, setProject] = useState({})
  const [chosenProjectId, setChosenProjectId] = useState(null)
  useEffect(() => {
    if (project) {
      setChosenProjectId(project._id)
    }
  }, [project])
  const [dateValue, setDateValue] = useState(null)
  const [product, setProduct] = useState({})
  const [quantity, setQuantity] = useState(0)
  const [comment, setComments] = useState("")
  const availableProducts = React.useMemo(() => {
    if (project?.object === "" && project) return []
    return project?.orders?.map((el) => el.product)?.filter((el) => el !== null)
  }, [project])
  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      inquiry: chosenProjectId,
      product,
      quantity,
      comment,
      deadline: moment(dateValue),
    }
    postProductionRequestAction(payload)
    history.push("/productionv2")
  }
  
  return (
    <div className={styles["request-form-container"]}>
      <Paper className={styles["request-form"]}>
        <Paper elevation={3} className={styles["form-heading"]}>
          <h4>Нова заявка за Тръба / Краен продукт</h4>
          <Button
            disableElevation
            disableRipple
            disableFocusRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#01D088",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#01D088",
              },
            }}
            onClick={handleSubmit}
            disabled={
              // project !== {} && product !== {} && quantity !== 0 ? false : true
              Object.keys(project)?.length !== 0 && Object.keys(product)?.length !== 0 && quantity !== 0 ? false : true
            }
          >
            Добави заявка
          </Button>
        </Paper>
        <div className={styles["form-container"]}>
          <div className={styles["form-upperline"]}>
            <FormControl style={{ width: "300px" }} size="small">
              <label>Проект</label>
              <Autocomplete
                options={inquiries}
                getOptionLabel={(option) => option.object}
                onChange={(e, newValue) => setProject(newValue)}
                renderOption={(props, option) => (
                  <Box component={"li"} {...props}>
                    <p>{option.object}</p>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Избор на проект" />
                )}
              />
            </FormControl>
            <FormControl style={{ width: "300px" }} size="small">
              <label>Продукт</label>
              <Autocomplete
                options={availableProducts}
                getOptionLabel={(option) =>
                  option?.friendlyNames?.join(", ") || option?.productType.name
                }
                onChange={(e, newValue) => setProduct(newValue)}
                renderOption={(props, option) => (
                  <Box component={"li"} {...props}>
                    <p>
                      {option?.friendlyNames?.join(", ") ||
                        option?.productType.name}
                    </p>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Избор на продукт" />
                )}
              />
            </FormControl>
            <FormControl size="small">
              <label>К-во</label>
              <Paper
                component={"form"}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "80px",
                  height: "36px",
                }}
              >
                <InputBase
                  placeholder="-"
                  sx={{ ml: 1, flex: 1 }}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                <p>{product?.mainUnit}</p>
              </Paper>
            </FormControl>
            <FormControl>
              <label>Срок за производство</label>
              <DatePicker
                components={{
                  OpenPickerIcon: () => <img src="/calendar-icon.png" />,
                }}
                label={"-/-/-"}
                value={dateValue}
                onChange={(newValue) => {
                  setDateValue(newValue)
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </FormControl>
          </div>
          <FormControl>
            <label>Бележки</label>
            <TextField
              fullWidth
              size="small"
              onChange={(e) => setComments(e.target.value)}
            />
          </FormControl>
        </div>
      </Paper>
    </div>
  )
}
const mapStateToProps = (state) => ({
  data: state.modal.data,
  inquiries: state.inquiries.data,
  performers: state.production.performers,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  getList: () => dispatch(getInquiries()),
  postProductionRequestAction: (payload) =>
    dispatch(postProductionRequest(payload)),
  getPerformers: (payload) => dispatch(getPerformers(payload)),
  addOtherAction: (payload) => dispatch(addOtherAction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm)
