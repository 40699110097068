import { ContactsTypes } from "../actions"

const initialState = {
  croppedData: {},
  contacts: {},
  contact: null,
}

const handleNewDocs = (state, action) => {
  var newState = { ...state }
  if (action?.payload?.page === 1) newState.contacts = action?.payload
  else
    newState.contacts = {
      ...action?.payload,
      docs: [...newState?.contacts?.docs, ...action?.payload?.docs],
    }
  return newState
}

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case ContactsTypes.SET_CROPPED_DATA:
      return { ...state, croppedData: action.payload }
    case ContactsTypes.GET_CONTACTS_SUCCESS:
      return handleNewDocs(state, action)
    case ContactsTypes.GET_SINGLE_CONTACT_SUCCESS:
      return { ...state, contact: action.payload }
    default:
      return state
  }
}

export default contacts
