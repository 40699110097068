import React from "react";
import moment from "moment";
import "./styles.scss";
import { useState, useEffect } from "react";

const InquiryDefectivesElement = ({ data }) => {

  return (
    <div className="inquiry-client-element expense" key={data?._id}>
      <div className="element small">{moment(data?.createdAt).format('DD.MM.YYYY') || '--'}</div>
      <div className="element smallest">{moment(data?.createdAt).format('HH:mm') || '--'}</div>
      <div className="element small">{data?.machine?.name || '--'}</div>
      <div className="element small">{data?.weight ? `${data?.weight} кг` : '--'}</div>
      <div className="element large last">{data?.length ? `${data?.length} м` : '--'}</div>
    </div>
  )
}

export default InquiryDefectivesElement;

