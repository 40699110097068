import React from "react";
import "./styles.scss";
import moment from "moment";
import { emit } from "../../../utilities/helpers";
import { socket } from "../../../actions";

export default function SingleTask({ task, index }) {

  const taskPriority = {
    low: "#25E083",
    medium: "#F7E05D",
    high: "#FF7590",
    completed: "#4D5D6E",
  };

  let overdue = task?.status === 'completed' ?
   Math.floor(moment(task?.completedAt).diff(moment(task?.endDate), "days")) :
   moment().isBefore(moment(task?.endDate)) ?
   -1 :
   Math.floor(moment(task?.endDate).diff(moment(), "days"))
  let isFinished = task?.status === 'completed'

  return (
    <div
    key={task?._id}
    className="draggable-tasks-wrapper"
    onClick={() => {
      emit(socket, { action: 'task/markActivityAsSeen', payload: { taskId: task?._id } })
      window.open(`/review-task/${task?._id}`)
      }}>
    <ul
      className="single-task-wrapper"
      style={{ borderColor: isFinished ? taskPriority[task?.status] : taskPriority[task?.priority] }}>
      <li className="flex">
        <h4>{task?.title}</h4>

        {task?._hasNewActivity ?
        <div className="icon">
          <img src={require('../../../assets/icons/new-activity.svg')} />
        </div> : <></>}
      </li>

      <li className="due-date">
        <span>Срок:&nbsp;<span className="task-data">{moment(task?.endDate).format('DD.MM.YYYY')}</span></span>
        {!isFinished && <span className="red">&nbsp;{overdue > 0 && <h5>(просрочен с {overdue} дни)</h5>}</span>}
      </li>

      {isFinished &&
      <li className="due-date">
        <span>
          Завършен на:&nbsp;
          <span className="task-data finished">
            {moment(task?.completedAt).format('DD.MM.YYYY')}
            {/* {moment(task?.completedAt).format('DD.MM.YYYY | HH:mm')} */}
          </span>
        </span>

        <span className="red">{(overdue > 0 && overdue > 1) && <h5>&nbsp;(просрочен с {overdue} дни)</h5>}</span>
        <span className="red">{(overdue > 0 && overdue === 1) && <h5>&nbsp;(просрочен с {overdue} ден)</h5>}</span>
      </li>}

        <li className="employees-wrapper">
          <h5>{task?.assignedTo?.map(el => el?.fullName)?.slice(0, 2)?.join(', ')}</h5>

          {task?.assignedTo && task?.assignedTo?.length > 2 &&
          <div className="names-count-wrapper">
            <div className="names-count">
              {`+${task?.assignedTo.length - 2}`}
            </div>

            <div className="more-names">
              {task?.assignedTo.slice(2).map((employee, index) => {
                return (
                  <h5 className="task-data" key={index * 100}>
                    {employee?.fullName}
                  </h5>
                );
              })}
            </div>
          </div>}
        </li>
    </ul>
  </div>
  );
}
