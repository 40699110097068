import * as Yup from "yup"

export const contactValidations = Yup.object().shape({
  name: Yup.string(),
  position: Yup.string(),
  companyName: Yup.string(),
  email: Yup.string(),
  phoneNumber: Yup.string("Моля въведете телефонен номер!"),
  contactFrom: Yup.string(),
})

export const validations = contactValidations

export const defaultValues = {
  name: "",
  position: "",
  companyName: "",
  email: "",
  phoneNumber: "",
  contactFrom: "",
  files: [],
  mainComment: null,
}
// export const defaultValues = {
//   name: `${nanoid()}`,
//   position: `${nanoid()}`,
//   companyName: `${nanoid()}`,
//   email: `${nanoid()}`,
//   phoneNumber: `${nanoid()}`,
//   contactFrom: `${nanoid()}`,
//   files: [],
//   mainComment: `${nanoid()}`,
// }
