import React, { useEffect, useState, useRef } from "react"
import moment from "moment"
import { TableHeaderInquiry } from "../TableHeaderInquiry"
import {
  searchInquiryProduction,
  showLoading
} from "../../actions"
import _ from "lodash"
import InquiryProductionElement from "../InquiryProductionElement/InquiryProductionElement"
import "./styles.scss"
import "../InquiriesClientsComponent/InquiriesHeader.scss"
import InquiriesHeader from "../InquiriesClientsComponent/InquiriesHeader"
import { useSelector, useDispatch } from "react-redux"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

const headerElements = [
  {
    name: "Служител",
    size: 1.3,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Задача",
    size: 1.3,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "Дата",
    size: 0.9,
    fixedWidth: 100,
    filter: true,
    sort: false
  },
  {
    name: "Работил с",
    size: 1.25,
    fixedWidth: 180,
    filter: true,
    sort: false
  },
  {
    name: "К-во",
    size: 0.9,
    fixedWidth: 100,
    filter: true,
    sort: false
  },
  {
    name: "Изработени",
    size: 2.4,
    // fixedWidth: 180,
    filter: true,
    sort: false
  }
]

export default function InquiriesProductionComponent () {
  const [ requestData, setRequestData ] = useState({});
  const [sort, setSort] = useState(false)
  const [selectedSortValue, setSelectedSortValue] = useState("Дата")
  const [searchQuery, setSearchQuery] = useState("")
  const [searchValue, setSearchValue] = useState('performerName')
  const [startDate, setStartDate] = useState(moment().toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const productionData = useSelector((state) => state.inquiries.searchedProductionData)
  const dispatch = useDispatch()
  const productionRef = useRef()

  const filterOptions = { 'performerName': 'Име на служител' }

  useEffect(() => {
    dispatch(showLoading());
    dispatch(searchInquiryProduction({ name: '', from: moment().subtract(7, 'days'), to: moment() }));
    setStartDate(moment().subtract(7, 'days').toDate())
    setEndDate(moment().toDate())
    setAreDatesSelected(true)
    setSelectedSortValue('Дата')
    handleHeaderElements('Дата')
  }, [])

  useEffect(() => {
    let newData = JSON.parse(JSON.stringify(productionData))
    if (selectedSortValue) {
      newData = handleSort(selectedSortValue)
    }

    setRequestData(newData)
    productionRef.current.scrollTo({ top: 0, behavior: 'smooth' })
  }, [productionData]);

  const handleSearch = () => {
    let searchPayload = { name: searchQuery }

    if (areDatesSelected) {
      searchPayload.from = moment(startDate).startOf("day")
      searchPayload.to = moment(endDate).endOf("day")
    }

    dispatch(showLoading())
    dispatch(searchInquiryProduction(searchPayload));
  }

  const handleDatesFilter = () => {
    if (moment(startDate).startOf("day").isAfter(moment(endDate).startOf("day"))) {
      return Alerts.error("Зададената начална дата на търсене е след крайната дата!")
    }

    let searchPayload = { from: moment(startDate).startOf("day"), to: moment(endDate).endOf("day") }
    setAreDatesSelected(true);

    if (searchQuery) {
      searchPayload.name = searchQuery
    }
    dispatch(showLoading())
    dispatch(searchInquiryProduction(searchPayload))
  }

  const handleSort = (selectedSortValue) => {
    let sorted = JSON.parse(JSON.stringify(productionData))

    switch (selectedSortValue) {
      case "Служител":
        sorted.sort((a, b) => {
          return sort
          ? a.worker.localeCompare(b.worker)
          : b.worker.localeCompare(a.worker)
        })
        break;

      case "Задача":
        sorted.sort((a, b) => {
          return sort
          ? a.activity.localeCompare(b.activity)
          : b.activity.localeCompare(a.activity)
        })
        break;

      case "Работил с":
        sorted.sort((a, b) => {
          return sort
          ? a.coworkers.localeCompare(b.coworkers)
          : b.coworkers.localeCompare(a.coworkers)
        })
        break;

      case "Дата":
        sorted.sort((a, b) => {     
          return sort
            ? moment(a?.startsAt || a?.start) - moment(b?.startsAt || b?.start)
            : moment(b?.startsAt || b?.start) - moment(a?.startsAt || a?.start)
        })
        break;

      case "К-во":
        sorted.sort((a, b) => {
          return sort
            ? (a?.quantity || a?.request?.quantity) - (b?.quantity || b?.request?.quantity)
            : (b?.quantity || b?.request?.quantity) - (a?.quantity || a?.request?.quantity)
        })
        break;

      case "Изработени":
        sorted.sort((a, b) => {
          return sort
            ? a?.produce - b?.produce
            : b?.produce - a?.produce
        })
        break;
    }

    setRequestData(sorted)
    return sorted
  }

  const handleHeaderElements = (value) => {
    headerElements.find(el => el.name === value).sort = true
  }

  return (
    <div className="inquiries-clients-component production">
      <InquiriesHeader
        headerElements={headerElements}
        handleSort={handleSort}
        handleHeaderElements={handleHeaderElements}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={filterOptions}
        startDate={startDate}
        endDate={endDate}
        tab="production"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleDatesFilter={handleDatesFilter}
        areDatesSelected={areDatesSelected}
        setAreDatesSelected={setAreDatesSelected}
        selectedSortValue={selectedSortValue}
        setSelectedSortValue={setSelectedSortValue}
        productionData={requestData}
        sort={sort}
        setSort={setSort}
      />

      <div className="sales-main-container">
        <TableHeaderInquiry
          elements={headerElements}
          justifyContent="flex-start"
          width="100 %"
          handleSort={handleSort}
          sort={sort}
          setSort={setSort}
          tab="production"
          selectedSortValue={selectedSortValue}
          setSelectedSortValue={setSelectedSortValue}
        />
        <div className="scroll-container" ref={productionRef}>
              {requestData.length > 0 && requestData?.map((el, i) => {
                return <InquiryProductionElement key={i + 1} data={el} />
              })}
        </div>
      </div>
    </div>
  )
}

