import React from 'react'
import './styles.scss'

const ComponentItem = ({ name, value, units, inStock }) => {
  return (
    <div className="component-list-item">
      <div className="component-list-item-full">
        <span className="component-list-item-title left-align">Елемент:</span>
        <span className="component-list-item-value left-align">{name}</span>
      </div>
      <div className="component-list-item-right border">
        <span className="component-list-item-title">К-во</span>
        <span className="component-list-item-value">{value}</span>
      </div>
      <div className="component-list-item-right">
        <span className="component-list-item-title">На слад</span>
        <span className="component-list-item-value">{inStock}</span>
      </div>
    </div>
  )
}

export default ComponentItem
