export const ModalTypes = {
  SHOW_MODAL: 'modal/SHOW_MODAL',
  HIDE_MODAL: 'modal/HIDE_MODAL',
  SET_MODAL_WIDTH: 'modal/MODAL_WIDTH',
}

export const setModalWidth = (payload) => ({
  type: ModalTypes.SET_MODAL_WIDTH,
  payload,
})

export const showModal = (payload) => ({
  type: ModalTypes.SHOW_MODAL,
  payload,
})

export const hideModal = (payload) => ({
  type: ModalTypes.HIDE_MODAL,
  payload,
})
