import React, { useState, useEffect } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { Inputs } from "../Inputs"
import { QuantityDropdownInput } from ".."
import q from "./emptyState"
import "./styles.scss"
import moment from "moment"
import { connect } from "react-redux"
import _ from "lodash"
import {
  seachMachineSettings,
  resetMachineSettings,
  checkTimeSlot,
  searchRequests,
  searchStorageProducts,
  getPerformers,
} from "../../actions"
import { history } from "../../config/stores"

const DURATION_OPTIONS = [
  { label: "30 мин", value: "30" },
  { label: "1 час", value: "60" },
  { label: "1ч. 30м.", value: "90" },
  { label: "2 часа", value: "120" },
  { label: "2ч. 30м.", value: "150" },
  { label: "3 часа", value: "180" },
  { label: "3ч. 30м.", value: "210" },
  { label: "4 часа", value: "240" },
  { label: "4ч. 30м.", value: "270" },
  { label: "5 часа", value: "300" },
  { label: "5ч. 30м.", value: "330" },
  { label: "6 часа", value: "360" },
  { label: "6ч. 30м.", value: "390" },
  { label: "7 часа", value: "420" },
  { label: "7ч. 30м.", value: "450" },
  { label: "8 часа", value: "480" },
  { label: "Още", value: "more" },
]

const EditSchedule = ({
  onCancel,
  scheduleElement,
  machines,
  settings,
  onSave,
  seachMachineSettingsAction,
  resetMachineSettingsAction,
  checkTimeSlotAction,
  machineSchedules,
  searchRequests,
  searchStorageProducts,
  results,
  storageProductResults,
  getPerformers,
  performers,
  specificSettings,
}) => {
  const [focusedElement, setFocusedElement] = useState(null)
  const [performerField, setPerformerField] = useState(null)
  const [activityField, setActivityField] = useState(null)
  const [machineSetingsName, setMachineSettingsName] = useState("")
  const [isTimeSlotAvailable, setIsTimeSlotAvailable] = useState(true)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const prepareScheduled = (scheduled) => {
    if (!scheduled) return {}
    const { _id, id, request, product, ...rest } = scheduled
    return rest
  }
  const [query, setQuery] = useState({
    ...q,
    ...prepareScheduled(scheduleElement.scheduled),
    _id: scheduleElement._id,
  })
  const [startDate, setStartDate] = useState(
    moment().add(1, "h").startOf("hour").toDate()
  )
  const [endDate, setEndDate] = useState(
    moment().add(1, "day").add(1, "h").startOf("hour").toDate()
  )

  const updateQuery = (key, value) => {
    const dup = { ...query }
    dup[key] = value
    setQuery(dup)
  }

  useEffect(() => {
    if (
      scheduleElement.product.productType.name === "Тръба" &&
      specificSettings.length
    ) {
      let settingsToUpdate = specificSettings[0]
      settingsToUpdate.isSearchable = false
      updateQuery("machineSettings", settingsToUpdate)
    }
    //eslint-disable-next-line
  }, [specificSettings, scheduleElement.product.productType.name])

  const checkQuery = (query, element) => {
    if (element.product.productType.name !== "Тръба") {
      const finishingWorks = query.finishingWorks.map((el) => {
        const performers = el.performers.map((performer) => {
          if (performer === "") {
            return false
          }
          return true
        })
        const performersCheck = performers.find(
          (performer) => performer === false
        )
        if (!el.activity || performersCheck === false) {
          return false
        }
        return true
      })
      const finishingWorksCheck = finishingWorks.find((work) => work === false)
      if (query.activity === "" || finishingWorksCheck === false) {
        return false
      }
      return true
    }
    const machineMaterials = query.machineSettings.materials
      ? query.machineSettings.materials.map((mat) => {
          if (mat.name === "" || mat.quantity === 0) {
            return false
          }
          return true
        })
      : []
    const machineProfiles = query.machineSettings.extruderSJ65.profiles.map(
      (profile) => {
        if (profile === "") {
          return false
        }
        return true
      }
    )
    const machineProfiles2 = query.machineSettings.extruderSJ90.profiles.map(
      (profile) => {
        if (profile === "") {
          return false
        }
        return true
      }
    )
    const machineMaterialsCheck = machineMaterials.find((el) => el === false)
    const machineProfilesCheck = machineProfiles.find(
      (profile) => profile === false
    )
    const machineProfilesCheck2 = machineProfiles2.find(
      (profile) => profile === false
    )

    // const keys = Object.keys(query.machineSettings)
    //   .map((key) => {
    //     return { [key]: query.machineSettings[key] !== "" }
    //   })
    //   .filter((el) => {
    //     const fkey = Object.keys(el)[0]
    //     return !el[fkey]
    //   })

    //const error = `${Object.keys(keys[0])[0]} is missing!`

    if (
      query.activity === "" ||
      machineMaterialsCheck === false ||
      query.machineSettings.buzzSaw.min === "" ||
      query.machineSettings.buzzSaw.move === "" ||
      query.machineSettings.extruderSJ65.motor.current === "" ||
      query.machineSettings.extruderSJ65.motor.revolutions === "" ||
      machineProfilesCheck === false ||
      query.machineSettings.extruderSJ90.motor.current === "" ||
      query.machineSettings.extruderSJ90.motor.revolutions === "" ||
      machineProfilesCheck2 === false ||
      query.machineSettings.injector === "" ||
      query.machineSettings.geometry.caliber === "" ||
      query.machineSettings.geometry.f1f2 === "" ||
      query.machineSettings.geometry.innerDiameter === "" ||
      query.machineSettings.geometry.stiffnes === "" ||
      query.machineSettings.geometry.vacuum === "" ||
      query.machineSettings.geometry.weight === "" ||
      // query.machineSettings.name === '' ||
      query.machineSettings.windingSystem === ""
    ) {
      return false
    }
    return true
  }

  const updateTotal = (
    scheduleElement,
    ratio,
    quantity,
    name,
    totalProducts
  ) => {
    const product = totalProducts.find((el) => {
      if (el.material) {
        return el.material === name
      }
      return el.type === name
    })
    let cost
    if (ratio) {
      const weight = scheduleElement.product.basicInfo.find(
        (el) => el.name === "Тежест на единца тръба"
      ).value
      const weightAfterRatio = (parseFloat(weight) / 100) * parseFloat(ratio)
      if (product.pricing) {
        cost = weightAfterRatio * product.pricing.singleUnitPrice
      } else {
        cost = weightAfterRatio * product.price
      }
    } else if (product.pricing) {
      cost = parseFloat(quantity) * product.pricing.singleUnitPrice
    } else {
      cost = parseFloat(quantity) * product.price
    }
    return cost
  }

  // Search machine settings
  const onChangeDelayed = _.debounce(
    (event) =>
      seachMachineSettingsAction({
        search: event.target.value,
        machine: query.machine,
      }),
    300
  )

  const onSettingsChange = (event, search) => {
    event.persist()
    setMachineSettingsName(event.target.value)
    updateMachineSettings("name", event.target.value)
    onChangeDelayed(event, search)
  }

  const [activitiesNumber, setActivitiesNumber] = useState(1)
  const [activities, setActivities] = useState([
    {
      request: scheduleElement._id,
      activity: "",
      date: moment().startOf("hour").toDate(),
      startsAt: moment().startOf("hour").toDate(),
      performers: [""],
      rate: "12",
      durationMinutes: "30",
      comment: "",
      materials: [{ name: "", quantity: 0, ratio: null, total: 0 }],
    },
  ])

  useEffect(() => {
    setQuery({ ...q, _id: scheduleElement._id })
    setActivities([
      {
        request: scheduleElement._id,
        activity: "",
        date: moment().startOf("hour").toDate(),
        startsAt: moment().startOf("hour").toDate(),
        performers: [""],
        rate: "12",
        durationMinutes: "30",
        comment: "",
        materials: [{ name: "", quantity: 0, ratio: null, total: 0 }],
      },
    ])
  }, [scheduleElement])

  useEffect(() => {
    getPerformers({ search: "" })
  }, [getPerformers])

  const updateMachineSettings = (key, value) => {
    const update = { ...query }
    update.machineSettings[key] = value
    setQuery(update)
  }

  useEffect(() => {
    searchRequests({ search: "" })
    searchStorageProducts({ search: "" })
  }, [searchRequests, searchStorageProducts])

  useEffect(() => {
    updateQuery("finishingWorks", activities)
    // eslint-disable-next-line
  }, [activities])

  const getProductVariation = (product) => {
    // let { name } = item.productType
    if (!product) return "Не-е-дефиниран"
    return `${product.basicInfo
      .filter((b) => {
        if (b.name === "Материал") return false
        if (b.name === "Ориентация") return false
        return true
      })
      .map((b) => {
        if (typeof b.value === "string") {
          const words = b.value.split(" ")
          if (words.length > 1) {
            return words
              .map((w) => {
                return `${w.slice(0, 1).toUpperCase()}.`
              })
              .join(" ")
          }
        }
        return b.value
      })
      .join("_")}`
  }

  useEffect(() => {
    const { start } = query
    if (start !== "") {
      checkTimeSlotAction({
        start: moment(start),
        end: moment(start).add(query.duration, "minutes"),
        groupBy: "_id",
      })
    }
    // eslint-disable-next-line
  }, [query.machine, query.start, query.duration])
  useEffect(() => {
    setIsTimeSlotAvailable(!machineSchedules[query.machine])
    setShowErrorPopup(!!machineSchedules[query.machine])
    // eslint-disable-next-line
  }, [machineSchedules, query.machine])

  useEffect(() => {
    updateQuery("machine", machines[0]._id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      scheduleElement &&
      scheduleElement.product.productType.name === "Тръба"
    ) {
      const newQuery = { ...query }
      newQuery.machineSettings.materials =
        scheduleElement.product.expenses &&
        scheduleElement.product.expenses.material.map((mat) => {
          return {
            name: mat.kind,
            ratio: mat.ratio,
            quantity: mat.quantity,
            total: mat.total,
          }
        })
      setQuery(newQuery)
    }
    // eslint-disable-next-line
  }, [scheduleElement])

  const totalProducts = [...results, ...storageProductResults]
  let altName = null
  if (scheduleElement.product.productType.name === "Тръба") {
    const type = scheduleElement.product.basicInfo.find(
      (el) => el.name === "Произвежда се по"
    )?.value
    const SN = scheduleElement.product.basicInfo.find(
      (el) => el.name === "Коравина"
    )?.value
    if (type === "Вътрешен диаметър") {
      const ID = scheduleElement.product.basicInfo.find(
        (el) => el.name === "Вътрешен диаметър"
      )?.value
      altName = `SN${SN}ID${ID}`
    } else {
      const OD = scheduleElement.product.basicInfo.find(
        (el) => el.name === "Външен диаметър"
      )?.value
      altName = `SN${SN}OD${OD}`
    }
  }
  const duration =
    query.duration === "more"
      ? Math.abs(moment(startDate).diff(moment(endDate), "minutes"))
      : query.duration
  return (
    <div className="edit-schedule-container">
      <div className="edit-schedule-section small">
        <div className="left">
          <h1>Към производство</h1>
          <div className="flex-container">
            <p
              id="bold-link"
              onClick={() =>
                history.push(`/products/${scheduleElement.product._id}`)
              }
            >
              {altName ||
                (scheduleElement.product &&
                  scheduleElement.product.productType.name)}
            </p>
            {!altName && (
              <span>{getProductVariation(scheduleElement.product)}</span>
            )}
            <p id="bold">
              {scheduleElement.quantity}
              {scheduleElement.product.mainUnit}
            </p>
            <p id="bold">
              {moment(scheduleElement.deadline).format("DD.MM.YYYY")}
            </p>
          </div>
        </div>
        <div className="flex-container buttons">
          <Button.Raised clear text="Откажи" onClick={onCancel} />
          <Button.Raised
            text="Добави в графика"
            disabled={!isTimeSlotAvailable}
            onClick={() => {
              if (onSave) {
                if (checkQuery(query, scheduleElement)) {
                  const materialExpenses = query.machineSettings.materials
                    ? query.machineSettings.materials.reduce((acc, val) => {
                        return acc + val.total
                      }, 0)
                    : 0
                  query.expense = materialExpenses / 100
                  if (!materialExpenses || isNaN(materialExpenses)) {
                    let expense = 0
                    if (scheduleElement.product.productType.name !== "Тръба") {
                      query.finishingWorks.forEach((activity) => {
                        activity.materials.forEach((mat) => {
                          const product = totalProducts.find((product) => {
                            if (product.type) {
                              return product.type === mat.name
                            }
                            return product.material === mat.name
                          })
                          if (product && product.price) {
                            expense += parseInt(mat.quantity) * product.price
                          } else if (product && product.pricing) {
                            expense +=
                              parseInt(mat.quantity) *
                              product.pricing.singleUnitPrice
                          } else {
                            expense = 0
                          }
                        })
                      })
                    } else {
                      query.finishingWorks = []
                    }
                    query.expense = expense
                  }
                  if (query.duration === "more") {
                    query.end = endDate
                    delete query.duration
                  }
                  query.startDate = startDate
                  query.start = startDate
                  if (
                    scheduleElement.product.productType.name === "Тръба" &&
                    !query.machine
                  ) {
                    Alerts.error("Грешка", "Моля изберете машина!")
                  } else {
                    if (
                      Number(scheduleElement.quantity) > Number(query.quantity)
                    ) {
                      const updatedInitialElement = {
                        ...scheduleElement,
                        quantity:
                          Number(scheduleElement.quantity) -
                          Number(query.quantity),
                      }
                      onSave(query, updatedInitialElement)
                    } else {
                      onSave(query, null)
                    }
                  }
                } else {
                  Alerts.error("Грешка!", "Моля попълнете полетата")
                }
              }
            }}
          />
        </div>
      </div>
      <div className="edit-schedule-section auto-height">
        <h3>Основна информация</h3>
        <div className="flex-container">
          <div className="input-elements bigger">
            <div className="flex-container">
              <div className="input-elements third">
                <p>К-во</p>
                <QuantityDropdownInput
                  quantityValue={query.quantity}
                  noCurrency
                  onSetQuantity={(val) => {
                    updateQuery("quantity", val)
                  }}
                  quantityTypeValue={{
                    value: scheduleElement.product.mainUnit,
                  }}
                  noDropdown
                />
              </div>
              <div className="input-elements third">
                <p>Ден</p>
                <Input.Datepicker
                  placeholder="Дата"
                  maxDate={query.duration === "more" ? endDate : null}
                  pickDate={startDate}
                  onChange={(date) => {
                    const newActivities = activities.slice(0)
                    newActivities.map((activity, index) => {
                      activity.date =
                        index === 0
                          ? moment(date).startOf("hour").toDate()
                          : moment(date)
                              .add(
                                (duration / activities.length) * index,
                                "minutes"
                              )
                              .toDate()
                      activity.durationMinutes =
                        query.duration === "more"
                          ? Math.abs(
                              moment(date).diff(moment(endDate), "minutes")
                            ) / newActivities.length
                          : parseInt(query.duration) / newActivities.length

                      return activity
                    })
                    updateQuery("start", date)
                    setActivities(newActivities)
                    setStartDate(date)
                  }}
                />
              </div>
              <div className="input-elements quarter">
                <p>Час</p>
                <div className="flex-container">
                  <Input.Datepicker
                    className={isTimeSlotAvailable ? "" : "error-time-slot"}
                    placeholder="Час"
                    pickDate={startDate}
                    onChange={(date) => {
                      const newActivities = activities.slice(0)
                      newActivities.map((activity, index) => {
                        activity.date =
                          index === 0
                            ? moment(date).startOf("hour").toDate()
                            : moment(date)
                                .add(
                                  (duration / activities.length) * index,
                                  "minutes"
                                )
                                .toDate()
                        activity.durationMinutes =
                          query.duration === "more"
                            ? Math.abs(
                                moment(date).diff(moment(endDate), "minutes")
                              ) / newActivities.length
                            : parseInt(query.duration) / newActivities.length

                        return activity
                      })
                      setActivities(newActivities)
                      setStartDate(date)
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Час"
                    dateFormat="HH:mm"
                  />
                  {showErrorPopup && (
                    <div className="error-time-slot-container">
                      <h2>Дублиране на час</h2>
                      <p className="error-helper-text">
                        Зададеният час и времетраене не съответсват с графика за
                        деня на машина OD800. Моля сменете часа
                      </p>
                      <div
                        className="close-btn"
                        onClick={() => {
                          setShowErrorPopup(false)
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="input-elements quarter">
                <p>Времетраене</p>
                <Input.Dropdown
                  options={DURATION_OPTIONS}
                  value={query.duration}
                  onChange={(value) => {
                    updateQuery("duration", value.value)
                    const newActivities = activities.slice(0)
                    newActivities.map((activity, index) => {
                      activity.date =
                        index === 0
                          ? moment(startDate).startOf("hour").toDate()
                          : moment(startDate)
                              .add(
                                (parseInt(value.value) / newActivities.length) *
                                  index,
                                "minutes"
                              )
                              .toDate()
                      activity.durationMinutes =
                        value.value === "more"
                          ? 1440 / newActivities.length
                          : parseInt(value.value) / newActivities.length
                      return activity
                    })
                    setActivities(newActivities)
                  }}
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="input-elements third">
            <p>Дейност</p>
            <Input.Text
              value={query.activity}
              onChange={(e) => {
                updateQuery("activity", e.target.value)
              }}
            />
          </div>
        </div>
        {query.duration === "more" && (
          <div
            className="flex-container"
            style={{
              justifyContent: "space-evenly",
              maxWidth: "28%",
              margin: "0 auto",
            }}
          >
            <div className="input-element quarter">
              <p>Крайна дата</p>
              <Input.Datepicker
                placeholder="Дата"
                pickDate={endDate}
                minDate={moment(startDate).add(1, "day").toDate()}
                onChange={(date) => {
                  setEndDate(date)
                  const durationSpecial =
                    query.duration === "more"
                      ? Math.abs(
                          moment(startDate).diff(moment(date), "minutes")
                        )
                      : query.duration
                  const newActivities = activities.slice(0)
                  newActivities.map((activity, index) => {
                    activity.date =
                      index === 0
                        ? moment(startDate).startOf("hour").toDate()
                        : moment(startDate)
                            .add(
                              (durationSpecial / activities.length) * index,
                              "minutes"
                            )
                            .toDate()

                    activity.durationMinutes =
                      Math.abs(
                        moment(startDate).diff(moment(date), "minutes")
                      ) / newActivities.length
                    return activity
                  })
                  setActivities(newActivities)
                }}
              />
            </div>
            <div className="input-element quarter">
              <p>Краен час</p>
              <Input.Datepicker
                placeholder="Час"
                pickDate={endDate}
                onChange={(date) => {
                  const durationSpecial =
                    query.duration === "more"
                      ? Math.abs(
                          moment(startDate).diff(moment(date), "minutes")
                        )
                      : query.duration
                  setEndDate(date)
                  const newActivities = activities.slice(0)
                  newActivities.map((activity, index) => {
                    activity.date =
                      index === 0
                        ? moment(startDate).startOf("hour").toDate()
                        : moment(startDate)
                            .add(
                              (durationSpecial / activities.length) * index,
                              "minutes"
                            )
                            .toDate()
                    activity.durationMinutes =
                      Math.abs(
                        moment(startDate).diff(moment(date), "minutes")
                      ) / newActivities.length
                    return activity
                  })
                  setActivities(newActivities)
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Час"
                dateFormat="HH:mm"
              />
            </div>
          </div>
        )}
        {moment(scheduleElement.deadline).add(1, "day").isBefore(startDate) && (
          <p id="red">
            *Моля обърнете внимание, че датата, която сте посочили е след
            зададения срок за доставка.
          </p>
        )}
        <span id="grey">
          *Времетраенето е базирано на последната настройка с тези параметри.
        </span>
      </div>
      {scheduleElement.product.productType.name === "Тръба" && (
        <div className="edit-schedule-section auto-height">
          <h3>Настройки на машината</h3>
          <div className="flex-container">
            <div className="input-elements">
              <p>Машина</p>
              <Button.Tab
                buttons={machines.map((el) => el.name)}
                onClick={(name) => {
                  const machine = machines.find((el) => {
                    return el.name === name
                  })
                  updateQuery("machine", machine._id)
                  // checkMachineAvailability()
                }}
              />
            </div>
            <div className="input-elements">
              <p>Запазени настройки</p>
              <div className="input-wrapper">
                <Input.Text
                  disabled={query.machine === ""}
                  value={machineSetingsName}
                  onFocus={(e) => {
                    onSettingsChange(e)
                  }}
                  onChange={(e) => {
                    onSettingsChange(e)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      resetMachineSettingsAction()
                    }, 300)
                  }}
                />
                {settings.length > 0 && (
                  <div className="client-results-dropdown">
                    {settings.map((setting) => {
                      return (
                        <div
                          className="result"
                          onClick={(e) => {
                            const newSetting = setting
                            newSetting.materials =
                              query.machineSettings.materials
                            newSetting.isSearchable = false
                            setMachineSettingsName(newSetting.name)
                            updateQuery("machineSettings", { ...newSetting })
                            resetMachineSettingsAction()
                          }}
                        >
                          {setting.name === "" ? "(Няма име)" : setting.name}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <h4>Материали</h4>
          <div className="flex-container">
            <div className="flex-container flex-start flex-wrap">
              {query.machineSettings.materials &&
                query.machineSettings.materials.map((mat, index) => {
                  const product = totalProducts.find((product) => {
                    if (product.material) {
                      return mat.name === product.material
                    }
                    return mat.name === product.type
                  })
                  return (
                    <div className="material-expense-outer-container">
                      <div className="material-expense-container">
                        <div className="elements-flex-2">
                          <p>Материал {index + 1}</p>
                          <div className="autocomplete-wrapper">
                            <Input.Text
                              value={mat.name}
                              onFocus={() => setFocusedElement(index)}
                              onChange={(e) => {
                                const mats = query.machineSettings.materials.slice(
                                  0
                                )
                                mats[index].name = e.target.value
                                updateMachineSettings("materials", mats)
                              }}
                            />
                            {index === focusedElement && (
                              <div className="suggestions-container">
                                {totalProducts
                                  .filter((product) => {
                                    if (product.material) {
                                      return product.material
                                        .toLowerCase()
                                        .includes(mat.name.toLowerCase())
                                    }
                                    return product.type
                                      .toLowerCase()
                                      .includes(mat.name.toLowerCase())
                                  })
                                  .map((product) => {
                                    return (
                                      <p
                                        onClick={() => {
                                          const mats = query.machineSettings.materials.slice(
                                            0
                                          )

                                          mats[index].name = product.material
                                            ? product.material
                                            : product.type
                                          mats[index].kind = product.material
                                            ? "request"
                                            : "product"
                                          mats[index].ref = product._id
                                          mats[index].total =
                                            updateTotal(
                                              scheduleElement,
                                              mats[index].ratio,
                                              mats[index].quantity,
                                              product.material
                                                ? product.material
                                                : product.type,
                                              totalProducts
                                            ) * 100
                                          updateMachineSettings(
                                            "materials",
                                            mats
                                          )
                                          setFocusedElement(null)
                                        }}
                                      >
                                        {product.material
                                          ? product.material
                                          : product.type}{" "}
                                        {product.variation &&
                                          `(${product.variation})`}
                                      </p>
                                    )
                                  })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="elements-flex-1">
                          <p>
                            {mat.ratio !== null ? "Съотношение" : "Количество"}
                          </p>
                          <QuantityDropdownInput
                            noCurrency
                            quantityValue={
                              mat.ratio !== null ? mat.ratio : mat.quantity
                            }
                            onSetQuantity={(val) => {
                              const mats = query.machineSettings.materials.slice(
                                0
                              )
                              if (mats[index].ratio !== null) {
                                mats[index].ratio = val
                              } else {
                                mats[index].quantity = val
                              }
                              mats[index].total =
                                updateTotal(
                                  scheduleElement,
                                  mats[index].ratio,
                                  mats[index].quantity,
                                  mats[index].name,
                                  totalProducts
                                ) * 100
                              updateMachineSettings("materials", mats)
                            }}
                            quantityTypeValue={
                              mat.ratio !== null
                                ? { value: "%" }
                                : { value: "бр" }
                            }
                            noDropdown
                          />
                        </div>
                        {query.machineSettings.materials.length !==
                          index + 1 && <div className="spacer" />}
                      </div>
                      <div className="material-expense-details">
                        <div className="flex-container">
                          <p>На склад:</p>
                          <p id="bold">
                            {product && product.quantity}{" "}
                            {product && product.units}
                          </p>
                        </div>
                        <div className="blue-line" />
                        <div className="flex-container">
                          <p>Ед. цена:</p>
                          <p id="bold">
                            {product && product?.price
                              ? product.price?.toFixed(2)
                              : product?.pricing?.singleUnitPrice?.toFixed(2)}
                             лв/{product && product.units}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="material-reason-change-container">
            <h4>Смяна на материали</h4>
            <p>Причина за смяна на материали</p>
            <Input.Text />
          </div>
          <h4 id="margin-top">Геометрични размери, коравина и тегло</h4>
          <div className="inputs-container">
            <div className="inputs-container-fields">
              <p>ID мм</p>
              <Input.Text
                value={query.machineSettings.geometry.innerDiameter}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.geometry }
                  gm.innerDiameter = e.target.value
                  updateMachineSettings("geometry", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>Калибър мм</p>
              <Input.Text
                value={query.machineSettings.geometry.caliber}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.geometry }
                  gm.caliber = e.target.value
                  updateMachineSettings("geometry", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>ф 1 | ф2</p>
              <Input.Text
                value={query.machineSettings.geometry.f1f2}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.geometry }
                  gm.f1f2 = e.target.value
                  updateMachineSettings("geometry", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>SN</p>
              <Input.Text
                value={query.machineSettings.geometry.stiffnes}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.geometry }
                  gm.stiffnes = e.target.value
                  updateMachineSettings("geometry", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>Тегло кг/м</p>
              <Input.Text
                value={query.machineSettings.geometry.weight}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.geometry }
                  gm.weight = e.target.value
                  updateMachineSettings("geometry", gm)
                }}
              />
            </div>
          </div>
          <div className="flex-container flex-start">
            <h4>Екструдер за профил SJ90, t</h4>&nbsp;&nbsp;&nbsp;
            <Input.Dropdown
              placeholder=""
              value={`${query.machineSettings.extruderSJ90.profiles.length}`}
              options={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
              onChange={(value) => {
                const val = parseInt(value.value, 10)
                const arr = Array.from(Array(val), () => "")
                const update = { ...query.machineSettings.extruderSJ90 }
                update.profiles = arr
                updateMachineSettings("extruderSJ90", update)
              }}
            />
          </div>

          <div className="inputs-container">
            {query.machineSettings.extruderSJ90.profiles.map((el, index) => {
              return (
                <div className="inputs-container-width">
                  <p>{index + 1}</p>
                  <Input.Text
                    value={el}
                    onChange={(e) => {
                      const update = { ...query.machineSettings.extruderSJ90 }
                      update.profiles[index] = e.target.value
                      updateMachineSettings("extruderSJ90", update)
                    }}
                  />
                </div>
              )
            })}
          </div>
          <div className="inputs-container">
            <div className="elements-flex-1 row-flex">
              <div className="inputs-container-fields">
                <p>Двигател ток А</p>
                <Input.Text
                  value={query.machineSettings.extruderSJ90.motor.current}
                  onChange={(e) => {
                    const update = { ...query.machineSettings.extruderSJ90 }
                    update.motor.current = e.target.value
                    updateMachineSettings("extruderSJ90", update)
                  }}
                />
              </div>
              <div className="inputs-container-fields">
                <p>Двигател оɗ/min</p>
                <Input.Text
                  value={query.machineSettings.extruderSJ90.motor.revolutions}
                  onChange={(e) => {
                    const update = { ...query.machineSettings.extruderSJ90 }
                    update.motor.revolutions = e.target.value
                    updateMachineSettings("extruderSJ90", update)
                  }}
                />
              </div>
            </div>
            <div className="elements-flex-1" />
          </div>
          <div className="flex-container flex-start">
            <h4>Екструдер за профил SJ65, t</h4>&nbsp;&nbsp;&nbsp;
            <Input.Dropdown
              placeholder=""
              value={`${query.machineSettings.extruderSJ65.profiles.length}`}
              options={[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
              ]}
              onChange={(value) => {
                const val = parseInt(value.value, 10)
                const arr = Array.from(Array(val), () => "")
                const update = { ...query.machineSettings.extruderSJ65 }
                update.profiles = arr
                updateMachineSettings("extruderSJ65", update)
              }}
            />
          </div>
          <div className="inputs-container">
            {query.machineSettings.extruderSJ65.profiles.map((el, index) => {
              return (
                <div className="inputs-container-width">
                  <p>{index + 1}</p>
                  <Input.Text
                    value={el}
                    onChange={(e) => {
                      const update = { ...query.machineSettings.extruderSJ65 }
                      update.profiles[index] = e.target.value
                      updateMachineSettings("extruderSJ65", update)
                    }}
                  />
                </div>
              )
            })}
          </div>
          <div className="inputs-container">
            <div className="elements-flex-1 row-flex">
              <div className="inputs-container-fields">
                <p>Двигател ток А</p>
                <Input.Text
                  value={query.machineSettings.extruderSJ65.motor.current}
                  onChange={(e) => {
                    const update = { ...query.machineSettings.extruderSJ65 }
                    update.motor.current = e.target.value
                    updateMachineSettings("extruderSJ65", update)
                  }}
                />
              </div>
              <div className="inputs-container-fields">
                <p>Двигател оɗ/min</p>
                <Input.Text
                  value={query.machineSettings.extruderSJ65.motor.revolutions}
                  onChange={(e) => {
                    const update = { ...query.machineSettings.extruderSJ65 }
                    update.motor.revolutions = e.target.value
                    updateMachineSettings("extruderSJ65", update)
                  }}
                />
              </div>
            </div>
            <div className="elements-flex-1" />
          </div>

          <div className="inputs-container">
            <div className="inputs-container-fields">
              <p>Вакуум</p>
              <Input.Text
                value={query.machineSettings.geometry.vacuum}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.geometry }
                  gm.vacuum = e.target.value
                  updateMachineSettings("geometry", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>Дюза</p>
              <Input.Text
                value={query.machineSettings.injector}
                onChange={(e) => {
                  let gm = query.machineSettings.injector
                  gm = e.target.value
                  updateMachineSettings("injector", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>Нав. сис. честота Hz</p>
              <Input.Text
                value={query.machineSettings.windingSystem}
                onChange={(e) => {
                  let gm = query.machineSettings.windingSystem
                  gm = e.target.value
                  updateMachineSettings("windingSystem", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>Циркуляр за рязане - мин</p>
              <Input.Text
                value={query.machineSettings.buzzSaw.min}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.buzzSaw }
                  gm.min = e.target.value
                  updateMachineSettings("buzzSaw", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <p>Циркуляр за рязане - ход</p>
              <Input.Text
                value={query.machineSettings.buzzSaw.move}
                onChange={(e) => {
                  const gm = { ...query.machineSettings.buzzSaw }
                  gm.move = e.target.value
                  updateMachineSettings("buzzSaw", gm)
                }}
              />
            </div>
          </div>

          <div className="inputs-container">
            <div className="inputs-container-fields">
              <p>Коментар </p>
              <Input.Text
                value={query.machineSettings.note}
                onChange={(e) => {
                  let gm = query.machineSettings.note
                  gm = e.target.value
                  updateMachineSettings("note", gm)
                }}
              />
            </div>
            <div className="inputs-container-fields">
              <Button.Switch
                label="Запази настройките"
                isOn={query.machineSettings.isSearchable}
                onChange={() => {
                  updateMachineSettings(
                    "isSearchable",
                    !query.machineSettings.isSearchable
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      {scheduleElement.product.productType.name !== "Тръба" && (
        <div className="edit-schedule-section">
          <div className="flex-container flex-start">
            <h3>Довършителни дейности</h3>
            <Input.Dropdown
              placeholder="брой"
              value={activitiesNumber.toString()}
              options={[1, 2, 3, 4, 5, 6, 7]}
              onChange={(value) => {
                Alerts.confirm(
                  {
                    title: "Сигурни ли сте че искате да продължите?",
                    text:
                      "Това действие ще изтрие нововъведените данни от полетата.",
                    confirm: "Да",
                    cancel: "Не",
                  },
                  (result) => {
                    if (result) {
                      const newActivities = Array.from(
                        Array(value.value).keys()
                      ).map((el, index) => {
                        return {
                          request: scheduleElement._id,
                          activity: "",
                          date:
                            index === 0
                              ? moment(startDate).startOf("hour").toDate()
                              : moment(startDate)
                                  .add(
                                    (duration / parseInt(value.value)) * index,
                                    "minutes"
                                  )
                                  .toDate(),
                          startsAt: moment(startDate).startOf("hour").toDate(),
                          performers: [""],
                          rate: "12",
                          durationMinutes: duration / parseInt(value.value),
                          comment: "",
                          materials: [
                            { name: "", quantity: 1, ratio: null, total: 0 },
                          ],
                        }
                      })
                      setActivities(newActivities)
                      setActivitiesNumber(value.value)
                    }
                  }
                )
              }}
            />
          </div>
          {activities.map((activity, index) => {
            return (
              <>
                <div key={`${index}-activity`} className="flex-container">
                  <div className="input-elements">
                    <p>Дейност {index + 1}</p>
                    <Input.Text
                      value={activity.activity}
                      onChange={(event) => {
                        const newActivities = activities.slice(0)
                        newActivities[index].activity = event.target.value
                        setActivities(newActivities)
                      }}
                    />
                  </div>
                  <div className="input-elements">
                    <div className="flex-container flex-start">
                      <div className="input-elements smaller">
                        <p>Ден</p>
                        <Input.Datepicker
                          disabled
                          minDate={startDate}
                          placeholder="Дата"
                          pickDate={activity.date}
                          onChange={(date) => {
                            const newActivities = activities.slice(0)
                            newActivities[index].date = date
                            setActivities(newActivities)
                          }}
                        />
                      </div>
                      <div className="input-elements smaller">
                        <p>Час</p>
                        <Input.Datepicker
                          disabled
                          minDate={startDate}
                          placeholder="Час"
                          pickDate={activity.date}
                          onChange={(date) => {
                            const newActivities = activities.slice(0)
                            newActivities[index].date = date
                            setActivities(newActivities)
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Час"
                          dateFormat="HH:mm"
                        />
                      </div>
                      <div className="input-elements smaller">
                        <p>Времетраене</p>
                        <Input.Dropdown
                          options={DURATION_OPTIONS.filter(
                            (el) => el.value !== "more"
                          ).filter((el) => parseInt(el.value) <= duration)}
                          value={activity.durationMinutes.toString()}
                          onChange={({ value }) => {
                            const newActivities = activities.map(
                              (activity, innerIndex) => {
                                if (innerIndex === 0) {
                                  activity.date = moment(startDate)
                                    .startOf("hour")
                                    .toDate()
                                } else if (innerIndex === index + 1) {
                                  const newDuration =
                                    (parseInt(duration) - parseInt(value)) /
                                    (activities.length - 1)
                                  activity.date = moment(startDate)
                                    .add(
                                      parseInt(value) +
                                        newDuration * (innerIndex - 1),
                                      "minutes"
                                    )
                                    .toDate()
                                } else if (innerIndex > index + 1) {
                                  const newDuration =
                                    (parseInt(duration) - parseInt(value)) /
                                    (activities.length - 1)
                                  activity.date = moment(startDate)
                                    .add(
                                      parseInt(value) +
                                        parseInt(newDuration) *
                                          (innerIndex - 1),
                                      "minutes"
                                    )
                                    .toDate()
                                } else {
                                  const newDuration =
                                    (duration - value) / (activities.length - 1)
                                  activity.date = moment(startDate)
                                    .add(
                                      parseInt(newDuration) * innerIndex,
                                      "minutes"
                                    )
                                    .toDate()
                                }
                                activity.durationMinutes =
                                  (duration - value) / (activities.length - 1)
                                return activity
                              }
                            )
                            newActivities[index].durationMinutes = value
                            setActivities(newActivities)
                          }}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex-container margin-bottom ${
                    1 + index === activitiesNumber && "no-border"
                  }`}
                >
                  <div className="input-elements">
                    <p>Коментар</p>
                    <Input.Text
                      value={activity.comment}
                      onChange={(event) => {
                        const newActivities = activities.slice(0)
                        newActivities[index].comment = event.target.value
                        setActivities(newActivities)
                      }}
                    />
                  </div>
                  <div className="input-elements">
                    <div className="input-elements flex-wrap">
                      {activity.performers.map((performer, performerIndex) => {
                        return (
                          <div className="contractor-container">
                            <div className="flex-container">
                              <p>{`Изпълнител ${1 + performerIndex}`}</p>
                              {activity.performers.length > 1 && (
                                <div
                                  className="remove-btn"
                                  onClick={() => {
                                    const newActivities = activities.slice(0)
                                    newActivities[index].performers.splice(
                                      performerIndex,
                                      1
                                    )
                                    setActivities(newActivities)
                                  }}
                                />
                              )}
                            </div>
                            <div className="performer-wrapper">
                              <Input.Text
                                value={performer}
                                onFocus={() => {
                                  setPerformerField(performerIndex)
                                  setActivityField(index)
                                }}
                                onChange={(event) => {
                                  const newActivities = activities.slice(0)
                                  newActivities[index].performers[
                                    performerIndex
                                  ] = event.target.value
                                  setActivities(newActivities)
                                }}
                              />
                              {performerField === performerIndex &&
                                activityField === index && (
                                  <div className="performers-container">
                                    {performers
                                      .filter((el) =>
                                        el
                                          .toLowerCase()
                                          .includes(performer.toLowerCase())
                                      )
                                      .map((performer) => {
                                        return (
                                          <p
                                            onClick={() => {
                                              const newActivities = activities.slice(
                                                0
                                              )
                                              newActivities[index].performers[
                                                performerIndex
                                              ] = performer
                                              setPerformerField(null)
                                              setActivityField(null)
                                              setActivities(newActivities)
                                            }}
                                          >
                                            {performer}
                                          </p>
                                        )
                                      })}
                                  </div>
                                )}
                            </div>
                          </div>
                        )
                      })}
                      <Button.Icon
                        name="plus"
                        size={14}
                        color="blue"
                        className=""
                        iconStyles=""
                        onClick={() => {
                          const newActivities = activities.slice(0)
                          newActivities[index].performers.push("")
                          setActivities(newActivities)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="materials-container">
                  <div className="flex-container flex-start">
                    <h4>Материали</h4>
                    <Input.Dropdown
                      placeholder=""
                      value={activity.materials.length.toString()}
                      options={[1, 2, 3, 4, 5]}
                      onChange={(value) => {
                        const newMaterials = Array.from(
                          Array(value.value).keys()
                        ).map(() => {
                          return {
                            name: "",
                            quantity: 0,
                            ratio: null,
                            total: 0,
                          }
                        })
                        const newActivities = activities.slice(0)
                        newActivities[index].materials = newMaterials
                        setActivities(newActivities)
                      }}
                    />
                  </div>
                  <div className="flex-container wrap">
                    {activity.materials.map((el, materialIndex) => {
                      return (
                        <div className="flex-container flex-start materials">
                          <div className="input-elements small">
                            <div className="flex-container margin">
                              <p>Материал {1 + materialIndex}</p>
                              {activity.materials.length > 1 && (
                                <div
                                  className="delete-btn"
                                  onClick={() => {
                                    const newActivities = activities.slice(0)
                                    newActivities[index].materials.splice(
                                      materialIndex,
                                      1
                                    )
                                    setActivities(newActivities)
                                  }}
                                />
                              )}
                            </div>
                            <Inputs.AutoSuggest
                              placeholder=""
                              ref={React.createRef()}
                              options={totalProducts
                                .filter((product) => {
                                  if (product.material) {
                                    return product.material
                                      .toLowerCase()
                                      .includes(el.name.toLowerCase())
                                  }
                                  return product.type
                                    .toLowerCase()
                                    .includes(el.name.toLowerCase())
                                })
                                .map((el) => {
                                  if (el.material) {
                                    return { title: el.material, ...el }
                                  }
                                  return { title: el.type, ...el }
                                })}
                              updateOptions={(payload) => {}}
                              value={el.name}
                              setValue={(newValue) => {
                                const newActivities = activities.slice(0)
                                newActivities[index].materials[
                                  materialIndex
                                ].name = newValue
                                setActivities(newActivities)
                              }}
                              onSuggestionClick={(result) => {
                                const newActivities = activities.slice(0)
                                const singlePrice = result.pricing
                                  ? result.pricing.singleUnitPrice
                                  : result.price
                                newActivities[index].materials[
                                  materialIndex
                                ].material = result.material
                                  ? result.material
                                  : result.type
                                newActivities[index].materials[
                                  materialIndex
                                ].total = parseInt(el.quantity) * singlePrice
                                newActivities[index].materials[
                                  materialIndex
                                ].kind = result.material ? "request" : "product"
                                newActivities[index].materials[
                                  materialIndex
                                ].ref = result._id
                                setActivities(newActivities)
                              }}
                              handleFocus={() => {}}
                            />
                          </div>
                          <div className="input-elements smaller">
                            <div className="flex-container margin">
                              <p>Количество</p>
                            </div>
                            <Input.Text
                              value={el.quantity}
                              onChange={(event) => {
                                const newActivities = activities.slice(0)
                                const product = totalProducts.find(
                                  (product) => {
                                    if (product.material) {
                                      return product.material === el.name
                                    }
                                    return product.type === el.name
                                  }
                                )
                                const singlePrice = product.pricing
                                  ? product.pricing.singleUnitPrice
                                  : product.price
                                newActivities[index].materials[
                                  materialIndex
                                ].quantity = event.target.value
                                newActivities[index].materials[
                                  materialIndex
                                ].total =
                                  parseInt(event.target.value) * singlePrice
                                setActivities(newActivities)
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </>
            )
          })}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  settings: state.production.searchedSettings,
  machineSchedules: state.production.machineSchedules,
  results: state.storage.search,
  storageProductResults: state.storage.storageProductResults,
  performers: state.production.performers,
  specificSettings: state.machineSettings.specificSettings,
})
const mapDispatchToProps = (dispatch) => ({
  seachMachineSettingsAction: (payload) =>
    dispatch(seachMachineSettings(payload)),
  resetMachineSettingsAction: () => dispatch(resetMachineSettings()),
  checkTimeSlotAction: (payload) => dispatch(checkTimeSlot(payload)),
  searchRequests: (payload) => dispatch(searchRequests(payload)),
  searchStorageProducts: (payload) => dispatch(searchStorageProducts(payload)),
  getPerformers: (payload) => dispatch(getPerformers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule)
