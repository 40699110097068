import React from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import { Alerts } from "@makedonski/socourt-utilities"
import { showModal } from "../../actions"
import moment from "moment"
import "./styles.scss"

const ProjectProductionElement = ({ showModal, data, inquiry, product }) => {
  let name
  if (data.product.productType.name === "Тръба") {
    const SN = data.product.basicInfo.find((info) => info.name === "Коравина")
    data.product.basicInfo.forEach((el) => {
      if (el.name === "Произвежда се по") {
        if (el.value === "Вътрешен диаметър") {
          const ID = data.product.basicInfo.find(
            (info) => info.name === "Вътрешен диаметър"
          )
          name = `SN${SN?.value}ID${ID?.value}`
        } else {
          const OD = data.product.basicInfo.find(
            (info) => info.name === "Външен диаметър"
          )
          name = `SN${SN?.value}OD${OD?.value}`
        }
      }
    })
  } else {
    name = data.product.basicInfo.map((el) => el.value).join("-")
  }
  return (
    <div className="project-production-element">
      <div className="flex-container">
        <p id="bold">{name}</p>
        <Button.Raised
          text="Заявка"
          onClick={() => {
            if (!product.product.schematicsClient) {
              Alerts.error("Грешка!", "Няма наличен чертеж")
            } else {
              showModal({
                type: "productionRequestModal",
                width: "600px",
                overflow: "visible",
                data: { project: inquiry, product: product.product },
              })
            }
          }}
        />
      </div>
      <div className="production-table-header">
        <div className="large">Заявка от</div>
        <div className="small">К-во</div>
        <div className="medium">До дата</div>
        <div className="small">К-во</div>
        <div className="large">На дата</div>
        <div className="medium">Завършен на</div>
      </div>
      {inquiry &&
        inquiry.productionRequests &&
        inquiry.productionRequests
          .filter((request) => request.product === data.product._id)
          .map((request) => {
            return (
              <div className="production-table-row">
                <div className="large">
                  {moment(request.createdAt).format("lll")}
                </div>
                <div className="small">{request.quantity}бр.</div>
                <div className="medium">
                  {moment(request.deadline).format("DD.MM.YYYY")}
                </div>
                <div className="small coloured">
                  {request.scheduled
                    ? request?.scheduled?.quantity + "бр."
                    : (request?.quantity || "--")}
                </div>
                <div className="large coloured space-between">
                  {request?.scheduled ?
                    <p>{moment(request?.scheduled?.createdAt).format("DD.MM.YYYY")}</p>
                   :
                   request?.startDate ? 
                    <p>{moment(request?.startDate).format("DD.MM.YYYY")}</p>
                   : 
                   request?.createdAt ? 
                    <p>{moment(request?.createdAt).format("DD.MM.YYYY")}</p>
                   : 
                    <p>--</p>
                  }
                  <div
                    className="chat-icon"
                    onClick={() => {
                      showModal({
                        type: "commentsModal",
                        width: "60%",
                        data: {
                          id: request._id,
                          refType: "ProductionRequests",
                          comments:
                            request.comments && request.comments.length > 0
                              ? request.comments
                              : [],
                        },
                      })
                    }}
                  />
                </div>
                <div className="medium coloured">
                {request?.endDate ? 
                    <p>
                      {moment(request?.endDate).format("DD.MM.YYYY")}
                    </p> :
                    <p>--</p>
                  }
                </div>
              </div>
            )
          })}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectProductionElement)
