import React, { useState, useEffect } from 'react'
import './styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import TableHeader from '../../components/TableHeader/TableHeader'
import Request from './Request/Request'
import moment from 'moment'

export default function ProductionRequest ({
  data,
  isRequestTabActive,
  setIsRequestTabActive
}) {
  const closeIcon = require('../../assets/icons/closeIcon.svg')
  const searchIcon = require('../../assets/icons/searchIcon.svg')
  const dispatch = useDispatch()

  const [ searchValue, setSearchValue ] = useState('');
  const [ sort, setSort ] = useState(false)
  const [ sorted, setSorted ] = useState([])
  const [ firstLoad, setFirstLoad ] = useState(true)

  const headerRequestElements = [
    { name: 'Продукт', size: 2.4, fixedWidth: 130 },
    // { name: 'К-во', size: 0.8, fixedWidth: 25 },
    // { name: 'Срок', size: 1.2, fixedWidth: 32 },
    { name: 'Проекти', size: 2, fixedWidth: 100 },
    { name: 'Дейности', size: 2, fixedWidth: 100 },
    { name: 'Клиент', size: 2, fixedWidth: 100 },
  ]

  let finished = data
    .filter((el) => el.status === 'finished')
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })

  let inProduction = data
    .filter((el) => el.status === 'in-production')
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })

  let pending = data
    .filter((el) => {
      return (
        el.status === 'pending' &&
        moment(el.createdAt).isAfter(
          moment('14.10.2021', 'DD.MM.YYYY').endOf('day')
        )
      )
    })
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt))
    })

  let sortedData = [...pending, ...inProduction, ...finished]

  const handleSort = (sortType) => {
    firstLoad && setFirstLoad(false)

    if (sortType === 'product') {
      finished = data
      .filter((el) => el.status === 'finished')
      .sort((a, b) => {
        let firstProduct = a.product?.productType?.name?.toLowerCase()
        let secondProduct = b.product?.productType?.name?.toLowerCase()
        return sort 
          ? firstProduct?.localeCompare(secondProduct)
          : secondProduct?.localeCompare(firstProduct)
      })

      inProduction = data
      .filter((el) => el.status === 'in-production')
      .sort((a, b) => {
        let firstProduct = a.product?.productType?.name?.toLowerCase()
        let secondProduct = b.product?.productType?.name?.toLowerCase()
        return sort 
        ? firstProduct?.localeCompare(secondProduct)
        : secondProduct?.localeCompare(firstProduct)
      })

      pending = data
      .filter((el) => {
        return (
          el.status === 'pending' &&
          moment(el.createdAt).isAfter(moment('14.10.2021', 'DD.MM.YYYY').endOf('day')))})
      .sort((a, b) => {
        let firstProduct = a.product?.productType?.name?.toLowerCase()
        let secondProduct = b.product?.productType?.name?.toLowerCase()
        return sort 
        ? firstProduct?.localeCompare(secondProduct)
        : secondProduct?.localeCompare(firstProduct)
      })
      
      // sortedData.sort((a, b) => {
      //   let firstProduct = a.product?.productType?.name?.toLowerCase()
      //   let secondProduct = b.product?.productType?.name?.toLowerCase()
      //   return sort 
      //     ? firstProduct?.localeCompare(secondProduct)
      //     : secondProduct?.localeCompare(firstProduct)
      // })
    }
    else if (sortType === 'quantity') {
      finished = data
      .filter((el) => el.status === 'finished')
      .sort((a, b) => {
        let numA = Number(a.quantity)
        let numB = Number(b.quantity)
        return sort ? numA - numB : numB - numA
      })

      inProduction = data
      .filter((el) => el.status === 'in-production')
      .sort((a, b) => {
        let numA = Number(a.quantity)
        let numB = Number(b.quantity)
        return sort ? numA - numB : numB - numA
      })

      pending = data
      .filter((el) => {
        return (
          el.status === 'pending' &&
          moment(el.createdAt).isAfter(moment('14.10.2021', 'DD.MM.YYYY').endOf('day')))})
      .sort((a, b) => {
        let numA = Number(a.quantity)
        let numB = Number(b.quantity)
        return sort ? numA - numB : numB - numA
      })
    }
    else if (sortType === 'deadline') {
      finished = data
      .filter((el) => el.status === 'finished')
      .sort((a, b) => {
        let numA = a?.deadline || a?.end
        let numB = b?.deadline || b?.end
        return sort
            ? moment(numA) - moment(numB)
            : moment(numB) - moment(numA)
      })

      inProduction = data
      .filter((el) => el.status === 'in-production')
      .sort((a, b) => {
        let numA = a?.deadline || a?.end
        let numB = b?.deadline || b?.end
        return sort
            ? moment(numA) - moment(numB)
            : moment(numB) - moment(numA)
      })

      pending = data
      .filter((el) => {
        return (
          el.status === 'pending' &&
          moment(el.createdAt).isAfter(moment('14.10.2021', 'DD.MM.YYYY').endOf('day')))})
      .sort((a, b) => {
        let numA = a?.deadline || a?.end
        let numB = b?.deadline || b?.end
        return sort
            ? moment(numA) - moment(numB)
            : moment(numB) - moment(numA)
      })
    }
    else if (sortType === 'activities') {
      finished = data
      .filter((el) => el.status === 'finished')
      .sort((a, b) => {
        let firstValue = a?.activity || a?.finishingWorks[0]?.activity
        let secondValue = b?.activity || b?.finishingWorks[0]?.activity

        return sort
            ? firstValue?.localeCompare(secondValue) || -1
            : secondValue?.localeCompare(firstValue) || -1
      })

      inProduction = data
      .filter((el) => el.status === 'in-production')
      .sort((a, b) => {
        let firstValue = a?.activity || a?.finishingWorks[0]?.activity
        let secondValue = b?.activity || b?.finishingWorks[0]?.activity

        return sort
            ? firstValue?.localeCompare(secondValue) || -1
            : secondValue?.localeCompare(firstValue) || -1
      })

      pending = data
      .filter((el) => {
        return (
          el.status === 'pending' &&
          moment(el.createdAt).isAfter(moment('14.10.2021', 'DD.MM.YYYY').endOf('day')))})
          .sort((a, b) => {
            let firstValue = a?.activity || a?.finishingWorks[0]?.activity
            let secondValue = b?.activity || b?.finishingWorks[0]?.activity
    
            return sort
                ? firstValue?.localeCompare(secondValue) || -1
                : secondValue?.localeCompare(firstValue) || -1
          })
    }
    else if (sortType === 'projects') {
      finished = data
      .filter((el) => el.status === 'finished')
      .sort((a, b) => {
        return sort
            ? a?.inquiry?.object.localeCompare(b?.inquiry?.object)
            : b?.inquiry?.object.localeCompare(a?.inquiry?.object)
      })

      inProduction = data
      .filter((el) => el.status === 'in-production')
      .sort((a, b) => {
        return sort
            ? a?.inquiry?.object.localeCompare(b?.inquiry?.object)
            : b?.inquiry?.object.localeCompare(a?.inquiry?.object)
      })

      pending = data
      .filter((el) => {
        return (
          el.status === 'pending' &&
          moment(el.createdAt).isAfter(moment('14.10.2021', 'DD.MM.YYYY').endOf('day')))})
      .sort((a, b) => {
        return sort
            ? a?.inquiry?.object.localeCompare(b?.inquiry?.object)
            : b?.inquiry?.object.localeCompare(a?.inquiry?.object)
      })
    }
    else if (sortType === 'client') {
      finished = data
      .filter((el) => el.status === 'finished')
      .sort((a, b) => {
        return sort
            ? a?.inquiry?.client.localeCompare(b?.inquiry?.client) || -1
            : b?.inquiry?.client.localeCompare(a?.inquiry?.client) || -1
      })

      inProduction = data
      .filter((el) => el.status === 'in-production')
      .sort((a, b) => {
        return sort
            ? a?.inquiry?.client.localeCompare(b?.inquiry?.client) || -1
            : b?.inquiry?.client.localeCompare(a?.inquiry?.client) || -1
      })

      pending = data
      .filter((el) => {
        return (
          el.status === 'pending' &&
          moment(el.createdAt).isAfter(moment('14.10.2021', 'DD.MM.YYYY').endOf('day')))})
      .sort((a, b) => {
        return sort
            ? a?.inquiry?.client.localeCompare(b?.inquiry?.client) || -1
            : b?.inquiry?.client.localeCompare(a?.inquiry?.client) || -1
      })
    }
    sortedData = [...pending, ...inProduction, ...finished]
    setSorted(sortedData)
  }

  useEffect(() => {
    setSorted(sortedData)
  }, [])

  return (
    <div className='requests-tab-container'>
      <div className='requests-top-wrapper'>
        <h2>Заявки</h2>
        <img
          src={closeIcon}
          alt='x'
          onClick={() => setIsRequestTabActive(!isRequestTabActive)}
        />
      </div>
      <div className='requests-search-wrapper'>
        <div className='input-wrapper'>
          <img src={searchIcon} />
          <input type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
        </div>
      </div>
      <div className='requests-wrapper'>
        <TableHeader
          elements={headerRequestElements}
          onSetSort={handleSort}
          setSort={setSort}
          sort={sort}
          justifyContent='flex-start'
          cursor={true}
        />
        <div className='requests-list-wrapper'>
          {(firstLoad ? sortedData : sorted)
          .filter(request => request?.product?.productType?.name?.toLowerCase()?.includes(searchValue.toLowerCase()) || 
          request?.inquiry?.object?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
          request?.activity?.toLowerCase()?.includes(searchValue.toLowerCase()))
          .map((request, index) => {
            return request?.quantity && 
            <Request key={`request-number-${index}`} data={request} />
          })}
        </div>
      </div>
    </div>
  )
}
