import React from "react"
import moment from "moment"
import "./styles.scss"
import { history } from '../../config/stores'

const InquiryCounterpartyElement = ({ data, onEdit }) => {

  return (
    <div className="inquiry-counterparty-element">
      <div className="element medium overflow">{data.firmName || '--'}</div>
      <div className="element small overflow">
        {moment(data.supplierSince).format("DD.MM.YYYY") || '--'}г.
      </div>
      <div className="element small overflow">
        {`${data.turnover.toFixed(2)} лв.` || '--'}
        </div>
      <div className="element small overflow">{data.uic || '--'}</div>
      <div className="element medium overflow">{data.mol || '--'}</div>
      <div className="element small overflow">{data.phone || '--'}</div>
      <div className="element medium overflow">{data.email || '--'}</div>
      <div className="element large flex">
        <p className="overflow">{data.address || '--'}</p>
        <div className="icon pen" 
        // onClick={onEdit}
        onClick={() => {history.push('/inquiries/add', { data: data })}}/>
      </div>
    </div>
  )
}

export default InquiryCounterpartyElement
