import React, { useEffect, useRef, useState } from "react"
import { Inputs } from "../../../components"
import { connect, useDispatch, useSelector } from "react-redux"
import "reactjs-popup/dist/index.css"
import { history } from "../../../config/stores"
import {
  editContact,
  getCurrentContact,
  getCurrentUser,
  hideLoading,
  setCroppedData,
  showLoading,
} from "../../../actions"
import Files from "../../../utilities/files"
import "../styles.scss"
import moment from "moment"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { fileToBase64, getFileFromUrl } from "../../../utilities/helpers"

const tabs = [
  { label: "За контакта", value: "info" },
  { label: "Файлове", value: "files" },
  { label: "Коментари", value: "comments" },
]

const fields = [
  { label: "Име", value: "name" },
  { label: "Позиция", value: "position" },
  { label: "Компания", value: "companyName" },
  { label: "Държава", value: "country" },
  { label: "Имейл", value: "email" },
  { label: "Телефон", value: "phoneNumber" },
  { label: "За продукт", value: "forProducts" },
  { label: "Контакт от", value: "contactFrom" },
]

const CurrentContactMobile = () => {
  const dispatch = useDispatch()
  const uploadRef = useRef()
  const contactId = window.location.href.split("/")[4]

  const current = useSelector(({ contacts }) => contacts?.contact) || {}
  const currentUser = useSelector((state) => state.user.currentUser)

  const canEditDelete = currentUser?._id === "5fa2a91bf689c74baedd3a6b"

  const [selTab, setSelTab] = useState("info")
  const [comment, setComment] = useState(null)

  useEffect(() => {
    dispatch(getCurrentUser())
    dispatch(
      getCurrentContact({
        _id: contactId,
      })
    )
  }, [])

  const handleUpload = async ({ target: { files } }) => {
    dispatch(showLoading())
    Files.uploadFiles(files).then((result) => {
      handleUpdateFiles(result)
      dispatch(hideLoading())
    })
  }

  const handleUpdateFiles = (files) => {
    if (!files || !files?.length) {
      Alerts.error("Грешка", "не сте избрали файлове!")
    } else
      dispatch(
        editContact({
          _id: current?._id,
          files: [
            ...current?.files,
            ...files?.map((file) => ({
              url: file?.location,
              name: file?.location?.split("/")?.[4],
              createdAt: moment().toISOString(),
              from: currentUser?.fullName,
            })),
          ],
          onSuccess: (res) => {
            dispatch(
              getCurrentContact({
                _id: current?._id,
              })
            )
          },
        })
      )
  }

  const handleSendComment = () => {
    if (!comment || !comment?.length) {
      Alerts.error("Грешка", "не сте въвели коментар!")
    } else
      dispatch(
        editContact({
          _id: current?._id,
          comments: [
            ...current?.comments,
            {
              createdAt: moment().toISOString(),
              from: currentUser?.fullName,
              text: comment,
            },
          ],
          onSuccess: (res) => {
            setComment(null)
            dispatch(
              getCurrentContact({
                _id: current?._id,
              })
            )
          },
        })
      )
  }

  return (
    <div className="main-container" style={{ height: "93%" }}>
      <div className="inner-header-container">
        <div className={`flex w-full justify-between items-center`}>
          <div className="flex items-center">
            <img
              src={require("../../../assets/icons/closeIcon.svg")}
              style={{
                width: "15px",
                height: "15px",
                cursor: "pointer",
                margin: "2px 15px 0px 5px",
              }}
              onClick={() => history.push("/contacts")}
            />
            <h3
              className="inner-title"
              style={{ marginLeft: "0px", fontSize: "1rem" }}
            >
              {current?.name}
            </h3>
          </div>
          {canEditDelete && (
            <div
              className="flex items-center justify-center"
              style={{
                width: "26px",
                height: "26px",
                borderRadius: "100%",
                background: "#E5EEF4",
                marginRight: "10px",
              }}
            >
              <img
                src={require("../../../assets/icons/pen-icon.svg")}
                style={{ width: "16px", height: "16px", cursor: "pointer" }}
                onClick={() => history.push(`/edit-contact/${current?._id}`)}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="body-container"
        style={{ height: "91%", padding: "1.5vw" }}
      >
        <div className="tab-buttons-container">
          {tabs.map(({ label, value }) => (
            <Inputs.Button
              text={label}
              selected={value === selTab}
              style={{ minWidth: "150px", height: "40px" }}
              onClick={() => setSelTab(value)}
            />
          ))}
        </div>
        {selTab === "info" ? (
          fields?.map((field) => (
            <div
              style={{
                maxHeight: "100px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <label className="input-header" style={{ marginBottom: "10px" }}>
                {field.label}
              </label>
              <div className="mobile-contact-info-container">
                {Array.isArray(current?.[field?.value])
                  ? current?.[field?.value]?.map(
                      (prod, i) =>
                        `${prod}${
                          current?.forProducts?.length - 1 > i ? ", " : ""
                        }`
                    )
                  : current?.[field?.value]}
              </div>
            </div>
          ))
        ) : selTab === "files" ? (
          <>
            <div style={{ height: "83%", overflowY: "auto" }}>
              {current?.files?.map((file, i) => (
                <>
                  <div
                    className="flex items-center w-full"
                    style={{ height: "40px", marginTop: "20px" }}
                  >
                    <div
                      className="mobile-file-container flex justify-between"
                      style={{ width: "85%", wordBreak: "break-all" }}
                      onClick={() => window.open(file?.url)}
                    >
                      {file?.name}
                      <img
                        src={require("../../../assets/icons/download-icon-green.svg")}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                        className="close-popup-icon"
                      />
                    </div>
                    <img
                      src={require("../../../assets/icons/scan.svg")}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "10px",
                      }}
                      onClick={async () => {
                        const filePromises = [getFileFromUrl(file?.url)]
                        const newFile = await Promise.all(filePromises)
                        const convertedFile = await fileToBase64(
                          newFile?.[0]
                        ).then((res) => {
                          dispatch(
                            setCroppedData({
                              url: file?.url,
                              base64: res,
                            })
                          )
                          history.push(
                            `/edit-contact/mobile-image/${contactId}`
                          )
                        })
                      }}
                    />
                    <img
                      src={require("../../../assets/icons/closeIcon-red.svg")}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                      className="close-popup-icon"
                      onClick={() => {
                        const newFiles = current?.files?.slice(0)
                        newFiles.splice(i, 1)
                        dispatch(
                          editContact({
                            _id: current?._id,
                            files: [...newFiles],
                            onSuccess: (res) => {
                              dispatch(
                                getCurrentContact({
                                  _id: current?._id,
                                })
                              )
                            },
                          })
                        )
                      }}
                    />
                  </div>
                  <p
                    className="file-data"
                    style={{ fontSize: "13px", marginLeft: "5px" }}
                  >
                    {moment(file?.createdAt).format("HH:mm | DD.MM.YYYY")} |{" "}
                    {file?.from}
                  </p>
                </>
              ))}
            </div>
            <Inputs.Button
              text={"Добави файл"}
              className={"dashed"}
              onClick={() => uploadRef.current.click()}
              style={{ width: "100%", marginBottom: "10px" }}
            />
          </>
        ) : (
          <>
            <div style={{ height: "83%", overflowY: "auto" }}>
              {current?.comments?.map((comment, i) => (
                <>
                  <div className="flex items-center justify-between w-full">
                    <b style={{ fontSize: "14px" }}>{comment?.from}</b>
                    <p style={{ fontSize: "14px" }}>
                      {moment(comment?.createdAt).format("DD.MM.YYYY | HH:mm")}
                    </p>
                  </div>
                  <div className="mobile-comment-text-container">
                    {comment?.text}
                  </div>
                </>
              ))}
            </div>
            <Inputs.TextAreaInput
              placeholder="Коментар"
              outerStyle={{ width: "100%", height: "60px" }}
              inputStyle={{ height: "100%" }}
              value={comment || ""}
              onChange={(e) => setComment(e.target.value)}
              suffix={
                <img
                  src={require("../../../assets/icons/sendComment.svg")}
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                  onClick={handleSendComment}
                />
              }
            />
          </>
        )}
      </div>
      <input
        ref={uploadRef}
        style={{ display: "none" }}
        type="file"
        multiple
        onChange={(e) => handleUpload(e)}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts,
})

const mapDispatchToProps = (dispatch) => ({
  showLoading: () => dispatch(showLoading()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentContactMobile)
