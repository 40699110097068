import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Activity from "../../components/Activity/Activity"
import EditRequest from "../../components/EditRequest/EditRequest"
import styles from "./EditProduct.module.scss"
import { connect } from "react-redux"
import {
  getMachineSchedule,
  getMachineScheduleByPerformer,
  getProductTypes,
  getActionSchedule,
  setProductionQuery,
  getProductionRequests,
} from "../../actions"
function EditProduct({ getRequests, data }) {
  useEffect(() => {
    getRequests()
  }, [])
  const { slug } = useParams()
  const [enableEdit, setEnableEdit] = useState(false)
  const [product, setProduct] = useState({})
  useEffect(() => {
    setProduct(data.find((item) => item._id === slug))
  }, [data])

  return (
    <div
      className={`${styles["edit-product-page-container"]} ${
        enableEdit === false && styles.edit
      }`}
    >
      <EditRequest
        data={product}
        setEnableEdit={setEnableEdit}
        enableEdit={enableEdit}
      />
      {enableEdit === false && <Activity />}
    </div>
  )
}
const mapStateToProps = (state) => ({
  data: state.production.data,
  productSchedule: state.production.productSchedule,
  actionSchedule: state.production.actionSchedule,
})

const mapDispatchToProps = (dispatch) => ({
  getRequests: () => dispatch(getProductionRequests()),
  getMachineSchedule: (payload) => dispatch(getMachineSchedule(payload)),
  getMachineScheduleByPerformer: (payload) =>
    dispatch(getMachineScheduleByPerformer(payload)),
  getProductTypes: (payload) => dispatch(getProductTypes(payload)),
  getActionSchedule: (payload) => dispatch(getActionSchedule(payload)),
  setProductionQuery: (payload) => dispatch(setProductionQuery(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)
