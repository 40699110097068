import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import {
  hideModal,
  createClient,
  editClient,
  createSupplier,
  editSupplier,
} from "../../../actions"
import "./styles.scss"

function AddClientModal({
  hide,
  data,
  createClient,
  editClient,
  createSupplier,
  editSupplier,
}) {
  const [name, setName] = useState("")
  const [uic, setUIC] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [mol, setMOL] = useState("")
  useEffect(() => {
    if (data.forEdit) {
      if (data.type === "client") {
        setName(data.client.name)
        setUIC(data.client.uic)
        setPhone(data.client.phone)
        setEmail(data.client.email)
        setAddress(data.client.address)
      } else {
        setName(data.counterparty.firmName)
        setUIC(data.counterparty.uic)
        setPhone(data.counterparty.phone)
        setEmail(data.counterparty.email)
        setAddress(data.counterparty.address)
        setMOL(data.counterparty.mol)
      }
    }
  }, [data])
  return (
    <div className="add-client-modal">
      <div className="top-container">
        <h1>
          {data.forEdit ? "Редактиране" : "Добавяне"} на{" "}
          {data.type === "client" ? "клиент" : "контрагент"}
        </h1>
        <div className="exit-btn" onClick={hide} />
      </div>
      <div className="flex-container space-between">
        <div className="input-element">
          <p>Име на фирма</p>
          <Input.Text
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="input-element">
          <p>ЕИК</p>
          <Input.Text
            value={uic}
            onChange={(event) => setUIC(event.target.value)}
          />
        </div>
        <div className="input-element">
          <p>Телефон</p>
          <Input.Text
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </div>
      </div>
      <div className="flex-container space-between">
        <div className="input-element">
          <p>Имейл</p>
          <Input.Text
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        {data.type !== "client" && (
          <div className="input-element">
            <p>МОЛ</p>
            <Input.Text
              value={mol}
              onChange={(event) => setMOL(event.target.value)}
            />
          </div>
        )}
        <div className="dummy" />
      </div>
      <p>Адрес</p>
      <Input.Text
        value={address}
        onChange={(event) => setAddress(event.target.value)}
      />
      <Button.Raised
        disabled={!name || !uic || !phone || !email || !address}
        className={
          (!name || !uic || !phone || !email || !address) && "disabled"
        }
        text={`${data.forEdit ? "Редактирай" : "Добави"} ${
          data.type === "client" ? "клиент" : "контрагент"
        }`}
        onClick={() => {
          if (data.type === "client") {
            if (data.forEdit) {
              editClient({
                _id: data.client._id,
                name: name,
                uic: uic,
                phone: phone,
                email: email,
                address: address,
              })
            } else {
              createClient({
                name: name,
                uic: uic,
                phone: phone,
                email: email,
                address: address,
              })
            }
          } else {
            if (data.forEdit) {
              editSupplier({
                _id: data.counterparty._id,
                firmName: name,
                mol: mol,
                uic: uic,
                phone: phone,
                email: email,
                address: address,
              })
            } else {
              createSupplier({
                firmName: name,
                mol: mol,
                uic: uic,
                phone: phone,
                email: email,
                address: address,
              })
            }
          }
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  createClient: (payload) => dispatch(createClient(payload)),
  editClient: (payload) => dispatch(editClient(payload)),
  createSupplier: (payload) => dispatch(createSupplier(payload)),
  editSupplier: (payload) => dispatch(editSupplier(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddClientModal)
