import React from "react"
import moment from "moment"
import "./styles.scss"

function FileOffer({ doc }) {
  return (
    <div className="file-offer-container">
      <div className="file-offer-inner-container">
        <a
          href={doc.url}
          target="_blank"
          rel="noopener noreferrer"
          className="file-text"
        >
          {doc.name}
        </a>
        <div className="delete-icon" />
      </div>
      <span>
        {moment(doc.createdAt).format("DD.MM.YYYY, HH:mm")} |{" "}
        {doc.user.fullName}
      </span>
    </div>
  )
}

export default FileOffer
