import React from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import { hideModal } from "../../../actions"

import "./styles.scss"

const SuccessModal = ({ hideModal, data }) => {
  return (
    <div className="success-container">
      <div className="success-icon" />
      <h1>{data}</h1>
      <Button.Raised text="Към проект" onClick={() => hideModal()} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal)
