import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { ReferenceTypes, GeneralTypes, hideModal, hideLoading } from "../actions"
import { API_URL } from "../config/settings"

export const searchReferences = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.GET_REFERENCES),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/client/search`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ReferenceTypes.GET_REFERENCES_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createClient = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.CREATE_CLIENT),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/client`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: ReferenceTypes.CREATE_CLIENT_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getClients = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.GET_CLIENTS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/client/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ReferenceTypes.GET_CLIENTS_SUCCESS,
              payload: response,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editClient = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.EDIT_CLIENT),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/client`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ReferenceTypes.EDIT_CLIENT_SUCCESS,
              payload: response,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getSuppliers = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.GET_SUPPLIERS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/supplier/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ReferenceTypes.GET_SUPPLIERS_SUCCESS,
              payload: response,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createSupplier = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.CREATE_SUPPLIER),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/supplier`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(hideModal())
            obs.next({
              type: ReferenceTypes.GET_SUPPLIERS,
              payload: { pageNumber: 1 },
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editSupplier = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.EDIT_SUPPLIER),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/supplier`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ReferenceTypes.EDIT_SUPPLIER_SUCCESS,
              payload: response,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchSuppliers = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.SEARCH_SUPPLIERS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/supplier/list`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ReferenceTypes.SEARCH_SUPPLIERS_SUCCESS,
              payload: response,
            })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const filterInquiryDatesClients = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.FILTER_CLIENTS_DATES),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/client/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: ReferenceTypes.FILTER_CLIENTS_DATES_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchInquiryClients = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.SEARCH_CLIENTS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/client/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: ReferenceTypes.SEARCH_CLIENTS_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchInquirySuppliers = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.SEARCH_INQUIRY_SUPPLIERS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/supplier/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: ReferenceTypes.SEARCH_INQUIRY_SUPPLIERS_SUCCESS, payload: response})
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.next(hideLoading())
            obs.complete()
          })
        })
      )
    })
  )
}

export const filterInquiryDatesSuppliers = (action$) => {
  return action$.pipe(
    ofType(ReferenceTypes.FILTER_INQUIRY_SUPPLIERS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/supplier/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: ReferenceTypes.FILTER_INQUIRY_SUPPLIERS_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
