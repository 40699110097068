import React from 'react';
import './styles.scss';
import moment from 'moment';
import { history } from '../../config/stores';

export default function InquirySingleProduct({ product }) {
    const arrowIcon = require('../../assets/icons/blueArrowCircle.svg');

  return (
    <div className='single-offer-product-wrapper' key={product.item}>
      <div className="element medium">
          {product.productType}
          <img src={arrowIcon} onClick={() => history.push(`/products/${product.item}`)}
           className="arrow-icon-blue"></img>
      </div>
      <div className="element small">{product.quantity} бр.</div>
      <div className="element small">{moment(product.deliveryDate).format('DD/MM/YY')}</div>
      <div className="element large last">{product.singleUnitPrice} лв.</div>
    </div>
  )
}
