import React, { useRef } from "react"
import { connect } from "react-redux"
import { Input, Button, Section } from "@makedonski/admin-ui"
// import moment from 'moment'
import {
  hideModal,
  editExpensesAction,
  searchRequests,
  searchStorageProducts,
} from "../../../actions"
import { Inputs } from "../.."
import { ExpensePopup } from "../EditOtherExpensesModal/ExpensePopup"
import "./styles.scss"
import { isBudgetValid } from "../../../utilities/helpers"

const EditTubeExpensesModal = ({
  hide,
  data,
  update,
  search,
  results,
  product,
  storageProductResults,
  searchStorageProducts,
}) => {
  let expensePopup = useRef()
  const prepareExpenses = () => {
    const exp = { ...data.expenses }
    exp.staff = exp.staff.map((el) => {
      return {
        ...el,
        pricePerHour: el.pricePerHour / 100,
        total: el.total / 100,
      }
    })
    exp.material = exp.material.map((el) => {
      return {
        ...el,
        total: el.total / 100,
      }
    })
    exp.other = exp.other.map((el) => {
      return {
        ...el,
        singleUnitPrice: el.singleUnitPrice / 100,
        total: el.total / 100,
      }
    })
    exp.custom = exp.custom.map((el) => {
      return {
        ...el,
        elements: el.elements.map((l) => {
          return {
            ...l,
            total: l.total / 100,
          }
        }),
      }
    })
    return exp
  }

  const [filter, setFilter] = React.useState("")
  const [expense, setExpense] = React.useState(prepareExpenses())
  const [elRefs, setElRefs] = React.useState([])

  React.useEffect(() => {
    search({ search: "" })
    searchStorageProducts({ search: "" })
    //eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    // add or remove refs
    setElRefs((els) =>
      Array(expense.material.length)
        .fill()
        .map((_, i) => els[i] || React.createRef())
    )
  }, [expense.material])

  const updateExpense = (key, value) => {
    const newExpense = { ...expense, [key]: value }
    setExpense(newExpense)
  }

  const updateMaterial = (key, value, index) => {
    const materials = expense.material.slice(0)
    materials[index][key] = value
    updateExpense("material", materials)
  }

  const calculateOthers = (index) => {
    const item = [...expense.other]
    if (item[index].quantity && item[index].singleUnitPrice) {
      item[index].total = item[index].quantity * item[index].singleUnitPrice
    } else {
      item[index].total = ""
    }
    updateExpense("other", item)
  }

  const addCustomExpense = () => {
    const custom = expense.custom.slice(0)
    custom.push({
      type: "",
      elements: [
        {
          description: "",
          total: "",
        },
      ],
    })
    updateExpense("custom", custom)
  }

  const addCustomExpenseElement = (index) => {
    const custom = expense.custom.slice(0)
    custom[index].elements.push({
      description: "",
      total: "",
    })
    updateExpense("custom", custom)
  }

  const updateCustomExpenseType = (index, value) => {
    const custom = expense.custom.slice(0)
    custom[index].type = value
    updateExpense("custom", custom)
  }

  const updateCustomExpenseElement = (customIndex, index, key, value) => {
    const custom = expense.custom.slice(0)
    custom[customIndex].elements[index][key] = value
    updateExpense("custom", custom)
  }

  const checkMaterialTotal = (index) => {
    const mat = expense.material[index]
    if (mat.kind && (mat.ratio || mat.quantity)) {
      let found = results.find((el) => el.material === mat.kind)
      if (!found) {
        found = storageProductResults.find((el) => el.type === mat.kind)
      }
      const weightForOne = product.basicInfo.find(
        (el) => el.name === "Тежест на единца тръба"
      )
      const weight = weightForOne ? weightForOne.value : 1
      if (mat.ratio !== null) {
        var ratio = mat.ratio / 100
      } else {
        var { quantity } = mat
      }

      const pricePerUnit = found.price
        ? found.price
        : found.pricing.singleUnitPrice

      var total = 0
      if (mat.ratio !== null) {
        total = Math.round(weight * ratio * pricePerUnit)
      } else {
        total = Math.round(quantity * pricePerUnit)
      }
      updateMaterial("total", total, index)
    }
  }

  return (
    <div className="add-tube-expenses-modal-container">
      <div className="modal-heading">
        <h3>Разходни норми</h3>
        <div className="exit-btn">
          <Button.Icon name="plus" color="#152331" onClick={hide} />
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-section big">
          <h4>Разход за материал</h4>
          <div className="form-control">
            {expense.material.map((el, idx) => {
              const found = results.find((product) => product._id === el._id)
              return (
                <div className="form-control inline">
                  <div className="form-control-inner">
                    <div className="form-control-label">
                      {found ? "Вид" : "Продукт"}
                    </div>
                    <Inputs.AutoSuggest
                      placeholder=""
                      ref={elRefs[idx]}
                      options={
                        el.type === "Суровини"
                          ? results
                              .filter((el) =>
                                el.material
                                  .toLowerCase()
                                  .includes(filter.toLowerCase())
                              )
                              .map((el) => {
                                return { ...el, title: el.material }
                              })
                          : storageProductResults
                              .filter((el) =>
                                el.type
                                  .toLowerCase()
                                  .includes(filter.toLowerCase())
                              )
                              .map((el) => {
                                return { ...el, title: el.type }
                              })
                      }
                      updateOptions={(payload) => {
                        setFilter(payload)
                      }}
                      value={expense.material[idx].kind}
                      setValue={(newValue) => {
                        updateMaterial("kind", newValue, idx)
                      }}
                      onSuggestionClick={(result) => {
                        if (el.type === "Суровини") {
                          updateMaterial("kind", result.material, idx)
                        } else {
                          updateMaterial("kind", result.type, idx)
                        }
                        checkMaterialTotal(idx)
                      }}
                      handleFocus={() => {
                        elRefs.forEach((el, i) => {
                          if (i !== idx) {
                            elRefs[i].current.closeSuggest()
                          }
                        })
                      }}
                    />
                  </div>
                  <div className="form-control-inner" />
                  <div className="form-control-inner small-input-margin">
                    <div className="form-control-label">
                      {el.ratio !== null ? "Съотношение" : "Количество"}
                    </div>
                    <Input.Text
                      value={
                        expense.material[idx].ratio !== null
                          ? expense.material[idx].ratio
                          : expense.material[idx].quantity
                      }
                      onChange={(e) => {
                        if (el.ratio !== null) {
                          updateMaterial("ratio", e.target.value, idx)
                        } else {
                          updateMaterial("quantity", e.target.value, idx)
                        }
                        checkMaterialTotal(idx)
                      }}
                    />
                  </div>
                  <div className="form-control-inner small-input-margin">
                    <div className="form-control-label">Разход</div>
                    <Input.Text disabled value={expense.material[idx].total} />
                  </div>
                  {expense.material.length > 1 && (
                    <div
                      className="remove-icon"
                      onClick={() => {
                        const newMaterial = expense.material.slice(0)
                        newMaterial.splice(idx, 1)
                        updateExpense("material", newMaterial)
                      }}
                    />
                  )}
                </div>
              )
            })}
            <div className="form-control">
              <div className="form-control-label inline small-text">
                <span>Добави разход за материал</span>
                <Section.Popup
                  ref={(ref) => {
                    expensePopup = ref
                  }}
                  className='add-material-expenses-popup'
                  content={
                    <ExpensePopup
                      createExpense={(values) => {
                        const newMaterial = expense.material.slice(0)
                        newMaterial.push({
                          ...values,
                          name: "",
                          total: 0,
                          type: values.type,
                        })
                        updateExpense("material", newMaterial)
                        expensePopup.hide()
                      }}
                    />
                  }
                >
                  <Button.Icon
                size="12px"
                name="plus"
                color="#26ACE2"
                className="icon-button-add"
                    onClick={() => expensePopup.show()}
                  />
                </Section.Popup>
              </div>
            </div>
          </div>
          {/* WORKERS */}
          {/* <h4 className="child-header">Персонал</h4> */}
          {/* {expense.staff.map((worker, index) => {
            return (
              <div className="form-control">
                <h5>{worker.type}</h5>
                <div className="form-control inline">
                  <div className="form-control-inner small-input">
                    <div className="form-control-label">Брой</div>
                    <Input.Text
                      placeholder="лв."
                      value={worker.count}
                      onChange={(e) => {
                        const staff = [...expense.staff]
                        staff[index].count = e.target.value
                        updateExpense("staff", staff)
                        calculateExpense(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner small-input">
                    <div className="form-control-label">Работни часа</div>
                    <Input.Text
                      placeholder="часове"
                      value={worker.workHours}
                      onChange={(e) => {
                        const staff = [...expense.staff]
                        staff[index].workHours = e.target.value
                        updateExpense("staff", staff)
                        calculateExpense(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner small-input">
                    <div className="form-control-label">Надница</div>
                    <Input.Text
                      placeholder="лв."
                      value={worker.pricePerHour}
                      onChange={(e) => {
                        const staff = [...expense.staff]
                        staff[index].pricePerHour = e.target.value
                        updateExpense("staff", staff)
                        calculateExpense(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner small-input">
                    <div className="form-control-label">Разход</div>
                    <Input.Text
                      placeholder="лв."
                      disabled
                      value={worker.total}
                    />
                  </div>
                </div>
              </div>
            )
          })} */}
        </div>
        <div className="column-devider" />
        <div className="modal-section">
          <h4>Други разходи</h4>
          {expense.other.map((exp, index) => {
            return (
              <div className="form-control">
                <h5>{exp.type}</h5>
                <div className="form-control inline">
                  <div className="form-control-inner small-input">
                    <div className="form-control-label">Количество</div>
                    <Input.Text
                      value={exp.quantity}
                      onChange={(e) => {
                        const item = [...expense.other]
                        item[index].quantity = e.target.value
                        updateExpense("other", item)
                        calculateOthers(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner small-input">
                    <div className="form-control-label">Ед. Цена</div>
                    <Input.Text
                      value={exp.singleUnitPrice}
                      onChange={(e) => {
                        const item = [...expense.other]
                        item[index].singleUnitPrice = e.target.value
                        updateExpense("other", item)
                        calculateOthers(index)
                      }}
                    />
                  </div>
                  <div className="form-control-inner small-input">
                    <div className="form-control-label">Разход</div>
                    <Input.Text placeholder="лв." disabled value={exp.total} />
                  </div>
                </div>
              </div>
            )
          })}
          {expense.custom.map((custom, idx) => {
            return (
              <>
                <div className="form-control">
                  <div className="form-control inline">
                    <div className="form-control-inner">
                      <div className="flex-container">
                        <div className="form-control-label">Вид</div>
                        <div
                          className="delete-btn"
                          onClick={() => {
                            let newCustomExpenses = expense.custom.slice(0)
                            newCustomExpenses.splice(idx, 1)
                            updateExpense("custom", newCustomExpenses)
                          }}
                        />
                      </div>
                      <Input.Text
                        value={custom.type}
                        onChange={(e) => {
                          updateCustomExpenseType(idx, e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  {custom.elements.map((element, index) => {
                    return (
                      <div className="form-control inline">
                        <div className="form-control-inner">
                          <div className="form-control-label">Описание</div>
                          <Input.Text
                            value={element.description}
                            onChange={(e) => {
                              updateCustomExpenseElement(
                                idx,
                                index,
                                "description",
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className="form-control-inner small-input-margin">
                          <div className="form-control-label">Разход</div>
                          <Input.Text
                            value={element.total}
                            onChange={(e) => {
                              if (isBudgetValid(e.target.value)) {
                                updateCustomExpenseElement(
                                  idx,
                                  index,
                                  "total",
                                  e.target.value
                                )
                              }
                            }}
                          />
                        </div>
                        <div
                          className="delete-btn"
                          onClick={() => {
                            let newCustomExpenses = expense.custom.slice(0)
                            newCustomExpenses[idx].elements.splice(index, 1)
                            updateExpense("custom", newCustomExpenses)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className="form-control">
                  <div className="form-control-label inline small-text">
                    <span>Добави разход</span>
                    <Button.Icon
                      onClick={() => {
                        addCustomExpenseElement(idx)
                      }}
                      size="12px"
                      name="plus"
                      color="#26ACE2"
                      className="icon-button-add"
                    />
                  </div>
                </div>
              </>
            )
          })}
          {/*  */}
          <div className="form-control">
            <div className="form-control-label inline small-text">
              <span>
                <b>Добави нов вид разход</b>
              </span>
              <Button.Icon
                onClick={() => {
                  addCustomExpense()
                }}
                size="12px"
                name="plus"
                color="#26ACE2"
                className="icon-button-add"
              />
            </div>
          </div>
          <div className="button-container">
            <Button.Raised
              onClick={() => {
                update({ ...expense })
              }}
              text="Редактирай"
              className="button-green"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  product: state.products.current,
  results: state.storage.search,
  storageProductResults: state.storage.storageProductResults,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  update: (payload) => dispatch(editExpensesAction(payload)),
  search: (payload) => dispatch(searchRequests(payload)),
  searchStorageProducts: (payload) => dispatch(searchStorageProducts(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTubeExpensesModal)
