import React from 'react';
import './styles.scss';
import { Input, Button } from "@makedonski/admin-ui";
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isBudgetValid } from '../../../utilities/helpers';
import { hideModal, addMachineActivity, getSingleSchedule } from '../../../actions';
import { DataSaverOff } from '@mui/icons-material';

export default function AddMachineActivityModal() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.modal.data);
  const [ payload, setPayload ] = useState({ quantity: 0, size: 0 });
  let isDisabled = !payload.quantity || !payload.size;

  const setValue = (value, property) => {
    setPayload({...payload, [property]: value})
    isDisabled = !payload.quantity || !payload.size;
  }

  return (
    <div className='add-machine-activity-modal-wrapper'>
      <div className='headings-wrapper'>
        <h1>Въвеждане на тегло и дължина</h1>
        <h2>Въведете тегло и дължина на изработения продукт</h2>
      </div>

      <div className='main-data-wrapper'>
        <div className='input-wrapper'>
          <div>Тегло</div>
          <div className='text-wrapper'>
            <Input.Text onChange={(e) => isBudgetValid(e.target.value) && setValue(e.target.value, 'quantity')} />
            <div className='input-quantity'>кг</div>
          </div>

        </div>
        <div className='input-wrapper'>
          <div>Дължина</div>
          <div className='text-wrapper'>
            <Input.Text onChange={(e) => isBudgetValid(e.target.value) && setValue(e.target.value, 'size')} />
            <div className='input-quantity'>м</div>
          </div>
        </div>
      </div>

      <div className='buttons-wrapper'>
        <Button.Raised text="Въведи" onClick={() => {
          dispatch(addMachineActivity({ 
          machineSchedule: data.machineSchedule,
          machineScheduleWork: data.machineScheduleWork,
          weight: Number(payload.quantity),
          length: Number(payload.size)
          }))
          // data.renderTable()
          // dispatch(getSingleSchedule({ _id: data.machineSchedule }))
        }} disabled={isDisabled}></Button.Raised>
        <Button.Raised text="Откажи" className="white" onClick={() => dispatch(hideModal())}></Button.Raised>
      </div>

    </div>
  )
}
