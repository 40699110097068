import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { showModal } from "../../actions"
import { API_URL } from "../../config/settings"
import "./styles.scss"

const mapKind = {
  "phone-call": "проведе телефонно обаждане: ",
  email: "изпрати email: ",
  meeting: "направи среща: ",
  letter: "изпрати писмо: ",
  offer: "изпрати оферта: ",
  proform: "изпрати проформа: ",
  contract: "изготви договор: ",
  annex: "изготви анекс: ",
  actOfAcceptance: "подготви приемо предавателен протокол: ",
  other: "",
}

const ActionItem = ({ dotColour, item, categories }) => {
  const userImage = React.useMemo(() => {
    if (!item) return global.config.defaultAvatar
    if (item.user && item.user.coverPhoto) {
      if (item.user.coverPhoto.indexOf("sindi-trud-admin.s3") !== -1)
        return global.config.defaultAvatar
      return item.user.coverPhoto
    }
    return global.config.defaultAvatar
  }, [item])
  if (!item) return null
  const category = categories.find((cat) => cat._id === item.category)
  return (
    <div className="action-item-container">
      <div className="action-item-top">
        <div
          className="action-item-dot"
          style={{ backgroundColor: dotColour }}
        />
        <div className="date">
          {moment(item.onDate).format("HH:mm [|] DD.MM.YYYY")}
        </div>
      </div>
      <div className="action-item-inner-container">
        <div
          className="action-item-content"
          style={{ borderLeft: `solid 2px ${dotColour}` }}
        >
          {category && <p id="bold">{category.name}</p>}
          <div className="flex-container">
            <div
              className="img-photo"
              style={{ backgroundImage: `url(${userImage})` }}
              alt="user"
            />
            <div
              className="action-item-description"
              onClick={() => {
                if (item.isEmail) {
                  window.open(`${API_URL}/emails/${item.data.email}`, "_blank")
                }
              }}
            >
              <span className="action-item-bold-text">
                {item.user && item.user.fullName}{" "}
              </span>
              <span>
                {item.isEmail ? null : mapKind[item.kind]} {item.text}
              </span>
            </div>
          </div>
          {item.data &&
            item.data.files &&
            item.data.files.map((file) => {
              return (
                <div className="flex-container">
                  <div className="attach-icon" />
                  <p id="file" onClick={() => window.open(file.url)}>
                    {file.name}
                  </p>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  show: (payload) => dispatch(showModal(payload)),
})

export default connect(null, mapDispatchToProps)(ActionItem)
