import React from "react"
import { Input } from "@makedonski/admin-ui"
import { nanoid } from "nanoid"
import { UploadFile, RadioButton, FileItem } from "../../.."
import Files from "../../../../utilities/files"
import ClearIcon from "@mui/icons-material/Clear"

const units = [
  { label: "Метър", value: "м" },
  { label: "Брой", value: "бр" },
  { label: "Килограм", value: "кг" },
  { label: "Литър", value: "л" },
  { label: "Кубик", value: "м3" },
]

const TubeView = ({ product, setProduct, loading, stopLoading }) => {
  const thoughness = React.useMemo(() => {
    const val = product.basicInfo.find((el) => el.name === "Коравина")
    if (val) return val.value
    return ""
  }, [product.basicInfo])

  const innerD = React.useMemo(() => {
    const val = product.basicInfo.find((el) => el.name === "Вътрешен диаметър")
    if (val) return val.value
    return ""
  }, [product.basicInfo])

  const outerD = React.useMemo(() => {
    const val = product.basicInfo.find((el) => el.name === "Външен диаметър")
    if (val) return val.value
    return ""
  }, [product.basicInfo])

  const weight = React.useMemo(() => {
    const val = product.basicInfo.find(
      (el) => el.name === "Тежест на единца тръба"
    )
    if (val) return val.value
    return ""
  }, [product.basicInfo])

  return (
    <div className="pipe-view-container" style={{ flex: 1 }}>
      <h3>Обща информация</h3>
      <div className="flex-container space-between">
        <div className="regular-container">
          <span>Мерна единица</span>
          <Input.Dropdown
            placeholder=""
            value={product.mainUnit}
            options={units}
            onChange={(value) => {
              setProduct("mainUnit", value.value)
            }}
          />
        </div>
        <div className="regular-container">
          <div className="form-control">
            <div className="form-control-label">Материал</div>
            <div className="radio-group-horizontal">
              <RadioButton
                changed={() => {
                  const newBasicInfo = product.basicInfo.slice(0)
                  if (
                    newBasicInfo.findIndex(
                      (el) => el.value === "Полиетилен"
                    ) === -1
                  ) {
                    const index = newBasicInfo.findIndex(
                      (el) => el.name === "Материал"
                    )
                    if (index !== -1) {
                      newBasicInfo.splice(index, 1)
                    }
                    newBasicInfo.push({
                      type: "option",
                      value: "Полиетилен",
                      name: "Материал",
                      units: "",
                    })
                  }
                  setProduct("basicInfo", newBasicInfo)
                }}
                id={nanoid()}
                isSelected={
                  product.basicInfo.findIndex(
                    (el) => el.value === "Полиетилен"
                  ) !== -1
                }
                label="Полиетилен"
                value="Полиетилен"
              />
              <RadioButton
                changed={() => {
                  const newBasicInfo = product.basicInfo.slice(0)
                  if (
                    newBasicInfo.findIndex(
                      (el) => el.value === "Полипропилен"
                    ) === -1
                  ) {
                    const index = newBasicInfo.findIndex(
                      (el) => el.name === "Материал"
                    )
                    if (index !== -1) {
                      newBasicInfo.splice(index, 1)
                    }
                    newBasicInfo.push({
                      type: "option",
                      value: "Полипропилен",
                      name: "Материал",
                      units: "",
                    })
                  }
                  setProduct("basicInfo", newBasicInfo)
                }}
                id={nanoid()}
                isSelected={
                  product.basicInfo.findIndex(
                    (el) => el.value === "Полипропилен"
                  ) !== -1
                }
                label="Полипропилен"
                value="Полипропилен"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-container space-between">
        <div className="regular-container">
          <span>SN</span>
          <Input.Text
            value={thoughness}
            onChange={(event) => {
              const newBasicInfo = product.basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Коравина"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Коравина",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setProduct("basicInfo", newBasicInfo)
            }}
          />
        </div>
        <div className="regular-container">
          <span>ID</span>
          <Input.Text
            value={innerD}
            onChange={(event) => {
              const newBasicInfo = product.basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Вътрешен диаметър"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Вътрешен диаметър",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setProduct("basicInfo", newBasicInfo)
            }}
          />
        </div>
        <div className="regular-container">
          <span>OD</span>
          <Input.Text
            value={outerD}
            onChange={(event) => {
              const newBasicInfo = product.basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Външен диаметър"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Външен диаметър",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setProduct("basicInfo", newBasicInfo)
            }}
          />
        </div>
      </div>

      <div className="flex-container space-between">
        <div className="regular-container">
          <span>Тежест на единца тръба</span>
          <Input.Text
            placeholder="кг"
            value={weight}
            onChange={(event) => {
              const newBasicInfo = product.basicInfo.slice(0)
              const index = newBasicInfo.findIndex(
                (el) => el.name === "Тежест на единца тръба"
              )
              const object = {
                type: "input",
                value: event.target.value,
                name: "Тежест на единца тръба",
                units: "",
              }
              if (index === -1) {
                newBasicInfo.push(object)
              } else {
                newBasicInfo.splice(index, 1, object)
              }
              setProduct("basicInfo", newBasicInfo)
            }}
          />
        </div>
        <div className="regular-container">
          <div className="form-control">
            <div className="form-control-label">Произвежда се по</div>
            <div className="radio-group-horizontal">
              <RadioButton
                changed={() => {
                  const newBasicInfo = product.basicInfo.slice(0)
                  if (
                    newBasicInfo.findIndex(
                      (el) => el.value === "Вътрешен диаметър"
                    ) === -1
                  ) {
                    const index = newBasicInfo.findIndex(
                      (el) => el.name === "Произвежда се по"
                    )
                    if (index !== -1) {
                      newBasicInfo.splice(index, 1)
                    }
                    newBasicInfo.push({
                      type: "option",
                      value: "Вътрешен диаметър",
                      name: "Произвежда се по",
                      units: "",
                    })
                  }
                  setProduct("basicInfo", newBasicInfo)
                }}
                id={nanoid()}
                isSelected={
                  product.basicInfo.findIndex(
                    (el) => el.value === "Вътрешен диаметър"
                  ) !== -1
                }
                label="Вътрешен диаметър"
                value="Вътрешен диаметър"
              />
              <RadioButton
                changed={() => {
                  const newBasicInfo = product.basicInfo.slice(0)
                  if (
                    newBasicInfo.findIndex(
                      (el) => el.value === "Външен диаметър"
                    ) === -1
                  ) {
                    const index = newBasicInfo.findIndex(
                      (el) => el.name === "Произвежда се по"
                    )
                    if (index !== -1) {
                      newBasicInfo.splice(index, 1)
                    }
                    newBasicInfo.push({
                      type: "option",
                      value: "Външен диаметър",
                      name: "Произвежда се по",
                      units: "",
                    })
                  }
                  setProduct("basicInfo", newBasicInfo)
                }}
                id={nanoid()}
                isSelected={
                  product.basicInfo.findIndex(
                    (el) => el.value === "Външен диаметър"
                  ) !== -1
                }
                label="Външен диаметър"
                value="Външен диаметър"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form-control">
        <div className="form-control-label">Муфа</div>
        <div className="radio-group-horizontal">
          <RadioButton
            changed={() => {
              const newBasicInfo = product.basicInfo.slice(0)
              if (
                newBasicInfo.findIndex(
                  (el) => el.value === "Гумено уплътнение"
                ) === -1
              ) {
                const index = newBasicInfo.findIndex((el) => el.name === "Муфа")
                if (index !== -1) {
                  newBasicInfo.splice(index, 1)
                }
                newBasicInfo.push({
                  type: "option",
                  value: "Гумено уплътнение",
                  name: "Муфа",
                  units: "",
                })
              }
              setProduct("basicInfo", newBasicInfo)
            }}
            id={nanoid()}
            isSelected={
              product.basicInfo.findIndex(
                (el) => el.value === "Гумено уплътнение"
              ) !== -1
            }
            label="Гумено уплътнение"
            value="Гумено уплътнение"
          />
          <RadioButton
            changed={() => {
              const newBasicInfo = product.basicInfo.slice(0)
              if (
                newBasicInfo.findIndex((el) => el.value === "Ел. муфа") === -1
              ) {
                const index = newBasicInfo.findIndex((el) => el.name === "Муфа")
                if (index !== -1) {
                  newBasicInfo.splice(index, 1)
                }
                newBasicInfo.push({
                  type: "option",
                  value: "Ел. муфа",
                  name: "Муфа",
                  units: "",
                })
              }
              setProduct("basicInfo", newBasicInfo)
            }}
            id={nanoid()}
            isSelected={
              product.basicInfo.findIndex((el) => el.value === "Ел. муфа") !==
              -1
            }
            label="Ел. муфа"
            value="Ел. муфа"
          />
          <RadioButton
            changed={() => {
              const newBasicInfo = product.basicInfo.slice(0)
              if (newBasicInfo.findIndex((el) => el.value === "Резба") === -1) {
                const index = newBasicInfo.findIndex((el) => el.name === "Муфа")
                if (index !== -1) {
                  newBasicInfo.splice(index, 1)
                }
                newBasicInfo.push({
                  type: "option",
                  value: "Резба",
                  name: "Муфа",
                  units: "",
                })
              }
              setProduct("basicInfo", newBasicInfo)
            }}
            id={nanoid()}
            isSelected={
              product.basicInfo.findIndex((el) => el.value === "Резба") !== -1
            }
            label="Резба"
            value="Резба"
          />
        </div>
      </div>
      <h3>Наименование за клиент</h3>
      <div className="flex-container">
        <div className="flex-container no-margin">
          <div className="flex-container no-margin">
            <Input.Text
              value={product.friendlyNames}
              onChange={(event) => {
                setProduct("friendlyNames", event.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-control">
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за клиенти</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  if (Array.isArray(product.schematicsClient)) {
                    product.schematicsClient.forEach((el) => data.push(el))
                  }
                  setProduct("schematicsClient", data)
                  stopLoading()
                })
              }}
            />
          </div>
          {product.schematicsClient && Array.isArray(product.schematicsClient)
            ? product.schematicsClient.map((el, index) => {
                return (
                  <FileItem
                    {...el}
                    onRemove={() => {
                      let newDiagrams = product.schematicsClient.slice(0)
                      newDiagrams.splice(index, 1)
                      setProduct("schematicsClient", newDiagrams)
                    }}
                  />
                )
              })
            : product.schematicsClient && (
                <FileItem
                  {...product.schematicsClient}
                  onRemove={() => {
                    setProduct("schematicsClient", null)
                  }}
                />
              )}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Чертежи за производство</span>
            <UploadFile
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                const nameArray = Object.keys(e.target.files).map((key) => {
                  return e.target.files[key].name
                })
                Files.uploadFiles(e.target.files).then((result) => {
                  let data = result.map((el, index) => {
                    return { name: nameArray[index], url: el.location }
                  })
                  if (Array.isArray(product.schematicsProduction)) {
                    product.schematicsProduction.forEach((el) => data.push(el))
                  }
                  setProduct("schematicsProduction", data)
                  stopLoading()
                })
              }}
            />
          </div>
          {product.schematicsProduction &&
          Array.isArray(product.schematicsProduction)
            ? product.schematicsProduction.map((el, index) => {
                return (
                  <FileItem
                    {...el}
                    onRemove={() => {
                      let newDiagrams = product.schematicsProduction.slice(0)
                      newDiagrams.splice(index, 1)
                      setProduct("schematicsProduction", newDiagrams)
                    }}
                  />
                )
              })
            : product.schematicsProduction && (
                <FileItem
                  {...product.schematicsProduction}
                  onRemove={() => {
                    setProduct("schematicsProduction", null)
                  }}
                />
              )}
        </div>
        <div className="images-control-wrap">
          <div className="images-control">
            <span>Снимки</span>
            <UploadFile
              accept={"image/*"}
              multiple
              onChange={(e) => {
                loading({ text: "Качване на файлове..." })
                Files.uploadFiles(e.target.files).then((result) => {
                  const imgs = product.images.slice(0)
                  const newImgs = result.map((el) => el.location)
                  const all = [...imgs, ...newImgs]
                  setProduct("images", all)
                  stopLoading()
                })
              }}
            />
          </div>
          {product.images && (
            <div className="images-scroll">
              {product.images.map((img, idx) => {
                return (
                  <>
                    <img key={`Image-${idx}`} src={img} alt="item" />
                    <ClearIcon
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        let newImages = product.images.slice(0)
                        newImages.splice(idx, 1)
                        setProduct("images", newImages)
                      }}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TubeView
