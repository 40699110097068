import React, { useState, useEffect } from "react"
import { Button } from "@makedonski/admin-ui"
import "./styles.scss"

const userStatusColor = {
  Working: "#17F607",
  Away: "#FFFF00",
  Offline: "#FF0000",
}

const userStatusText = {
  Working: "На линия",
  Away: "Неактивен",
  Offline: "Извън линия",
}

function ProfileStatus({ workingType, userStatus, onChangeStatus }) {
  const [status, setStatus] = useState("")
  useEffect(() => {
    setStatus(workingType)
  }, [workingType])
  return (
    <div className="profile-status-popup-container">
      <div className="top-status-container">
        <span>{userStatusText[userStatus]}</span>
        <div
          className="status-dot"
          style={{ backgroundColor: userStatusColor[userStatus] }}
        />
      </div>
      <h3>Статус на работа</h3>
      <div className="main-status-container">
        <div className="status-element">
          <div className="status-inner-element">
            <div
              className="status-image"
              style={{
                backgroundImage: `url(${require("../../assets/icons/icon-work.png")})`,
              }}
            />
            <span>Работя</span>
          </div>
          <input
            className="radio"
            type="radio"
            name="inOffice"
            value="inOffice"
            checked={status === "inOffice"}
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          />
        </div>
        <div className="status-element">
          <div className="status-inner-element">
            <div
              className="status-image"
              style={{
                backgroundImage: `url(${require("../../assets/icons/icon-calendar.png")})`,
              }}
            />
            <span>В среща</span>
          </div>
          <input
            className="radio"
            type="radio"
            name="inMeeting"
            value="inMeeting"
            checked={status === "inMeeting"}
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          />
        </div>
        <div className="status-element">
          <div className="status-inner-element">
            <div
              className="status-image"
              style={{
                backgroundImage: `url(${require("../../assets/icons/icon-build.png")})`,
              }}
            />
            <span>На обект</span>
          </div>
          <input
            className="radio"
            type="radio"
            name="onSite"
            value="onSite"
            checked={status === "onSite"}
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          />
        </div>
        <div className="status-element">
          <div className="status-inner-element">
            <div
              className="status-image"
              style={{
                backgroundImage: `url(${require("../../assets/icons/icon-home.png")})`,
              }}
            />
            <span>От вкъщи</span>
          </div>
          <input
            className="radio"
            type="radio"
            name="fromHome"
            value="fromHome"
            checked={status === "fromHome"}
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          />
        </div>
        <div className="status-element">
          <div className="status-inner-element">
            <div
              className="status-image"
              style={{
                backgroundImage: `url(${require("../../assets/icons/icon-pill.png")})`,
              }}
            />
            <span>Болничен</span>
          </div>
          <input
            className="radio"
            type="radio"
            name="feelingSick"
            value="feelingSick"
            checked={status === "feelingSick"}
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          />
        </div>
        <div className="status-element">
          <div className="status-inner-element">
            <div
              className="status-image"
              style={{
                backgroundImage: `url(${require("../../assets/icons/icon-plane.png")})`,
              }}
            />
            <span>Отпуск</span>
          </div>
          <input
            className="radio"
            type="radio"
            name="vacation"
            value="vacation"
            checked={status === "vacation"}
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          />
        </div>
      </div>
      <div className="status-button-container">
        <Button.Raised text="Обнови" onClick={() => onChangeStatus(status)} />
      </div>
    </div>
  )
}

export default ProfileStatus
