import React from 'react'
import { useEffect, useMemo, useState } from "react"
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getProductName, isBudgetValid } from '../../utilities/helpers'
import { getInquiries, editOffer, deleteOffer, showLoading } from '../../actions'
import './styles.scss'
import moment from 'moment'
import { Button, Input } from '@makedonski/admin-ui'
import { history } from '../../config/stores'
import { Alerts } from '@makedonski/socourt-utilities/lib/Alerts'

export default function EditOffer() {
    const { state } = useLocation()
    const dispatch = useDispatch()
    const data = state.data
    const offerData = useSelector(state => state.inquiries.data).filter(inquiry => 
        inquiry.refNumbers.find(num => num === state.data.refNumber))[0]?.offers.filter(offer =>
            offer.refNumber === state.data.refNumber)[0]

    const [ chosenProducts, setChosenProducts ] = useState([])
    const [ totalExpenses, setTotalExpenses ] = useState(0)
    const [ payload, setPayload ] = useState({
        _id: '',
        inquiry: '',
        client: {
          name: '',
          uic: '',
          phone: '',
          email: '',
          address: '',
          attentionOf: ''
        },
        dueDate: moment().toDate(),
        deliveryConditions: '',
        paymentConditions: '',
        otherDetails: '',
        bankData: {
          bankAccount: '',
          marginFacilities: 0,
          marginHydro: 0
        },
        products: [
            {
            deliveryDate: moment().toDate(),
            item: {
                basicInfo: [{name: '', type: '', units: '', value: '', _id: '' }],
                components: [],
                createdAt: moment().toDate(),
                diagram: [{name: '', url: ''}],
                friendlyNames: [''],
                images: [''],
                mainUnit: '',
                productType: '',
                provider: '',
                updatedAt: moment().toDate(),
                warehouseInfo: [],
                __v: 0,
                _id: ''
                 },
            quantity: 0,
            singleUnitPrice: 0,
            total: 0,
            _id: ''
            }
        ],
        refNumber: '',
        refNumberHelper: 0,
        updatedAt: moment().toDate(),
        __v: 0,
        _id: ''
      })

    const deleteIcon = require('../../assets/icons/remove-btn.svg');
    const arrowIcon = require('../../assets/icons/blueArrowCircle.svg');

    const calcProductsWithoutQuantity = (products) => {
        const expenses = products?.map(product => {
            return Number(product.singleUnitPrice)
        }).reduce((acc, curr) => {return acc + curr}, 0)
        
        return expenses
    }

    const calcTotalExpenses = (products) => {
        const expenses = products?.map(product => {
            return product.total
        }).reduce((acc, curr) => {return acc + curr}, 0)
        
        return expenses
    }

    useEffect(() => {
        dispatch(showLoading())
        dispatch(getInquiries())
        if (data?.total) setTotalExpenses(Number(data?.total))
    }, [])

    useEffect(() => {
      offerData && setPayload(offerData)
    }, [ offerData ])

  return (
    <div className='edit-offer-container'>
      <div className='edit-offer-wrapper'>
        <div className='edit-offer-top'>
            <h2>Редакция на оферта</h2>
            <div className='buttons-wrapper'>
                <Button.Raised 
                className='secondary-btn delete'
                text='Изтрий'
                onClick={() => {
                    Alerts.confirm({
                      title: "Сигурни ли сте, че искате да изтриете офертата?",
                      confirm: "Да",
                      cancel: "Не",
                    },
                    (answer) => {
                      if (answer) {
                        dispatch(deleteOffer({_id: payload._id}))
                        history.goBack()
                      }
                    })
                  }}
                />
                <Button.Raised 
                text='Запази'
                onClick={() => {
                    dispatch(editOffer(payload))
                    history.goBack()
                }}
                />
            </div>
        </div>

        <div className='edit-offer-main'>
            <div className='edit-offer-main-top shadow'>
                <div className='input-wrapper wrap'>
                    <div className='input-container medium'>
                        <label>Клиент</label>
                        <Input.Text
                        value={payload.client.name || ''}
                        onChange={(e) => {
                            setPayload({...payload, client: {...payload.client, name: e.target.value}})
                        }}
                         />
                    </div>
                    <div className='input-container medium'>
                        <label>ЕИК</label>
                        <Input.Text
                         value={payload.client.uic || ''}
                         onChange={(e) => {
                             setPayload({...payload, client: {...payload.client, uic: e.target.value}})
                         }}
                         />
                    </div>
                    <div className='input-container medium'>
                        <label>Телефон</label>
                        <Input.Text
                         value={payload.client.phone || ''}
                         onChange={(e) => {
                             setPayload({...payload, client: {...payload.client, phone: e.target.value}})
                         }}
                         />
                    </div>
                    <div className='input-container medium'>
                        <label>Имейл</label>
                        <Input.Text
                         value={payload.client.email || ''}
                         onChange={(e) => {
                             setPayload({...payload, client: {...payload.client, email: e.target.value}})
                         }}
                         />
                    </div>
                    <div className='input-container large'>
                        <label>Адрес</label>
                        <Input.Text
                         value={payload.client.address || ''}
                         onChange={(e) => {
                             setPayload({...payload, client: {...payload.client, address: e.target.value}})
                         }}
                         />
                    </div>
                </div>

                <div className='input-wrapper second-row wrap'>
                    <div className='input-container medium-large'>
                        <label>На вниманието на</label>
                        <Input.Text
                         value={payload.client.attentionOf || ''}
                         onChange={(e) => {
                             setPayload({...payload, client: {...payload.client, attentionOf: e.target.value}})
                         }}
                         />
                    </div>
                    <div className='input-container smaller'>
                        <label>Срок на оферта</label>
                        <Input.Datepicker
                        pickDate={moment(payload?.dueDate).toDate() || null}
                        onChange={(date) => {
                            setPayload({...payload, dueDate: date})
                        }}
                         />
                    </div>
                    <div className='input-container large'>
                        <label>Условия на доставка</label>
                        <Input.Text
                         value={payload.deliveryConditions || ''}
                         onChange={(e) => {
                             setPayload({...payload, deliveryConditions: e.target.value})
                         }}
                         />
                    </div>
                </div>

                <div className='input-wrapper'>
                    <div className='input-container large'>
                        <label>Условия на плащане</label>
                        <Input.Text
                         value={payload.paymentConditions || ''}
                         onChange={(e) => {
                             setPayload({...payload, paymentConditions: e.target.value})
                         }}
                         />
                    </div>
                    <div className='input-container large'>
                        <label>Допълнително</label>
                        <Input.Text
                         value={payload.otherDetails || ''}
                         onChange={(e) => {
                             setPayload({...payload, otherDetails: e.target.value})
                         }}
                         />
                    </div>
                </div>

                <div className='input-wrapper'>
                    <div className='input-container dropdown-small'>
                        <label>Ваша банкова сметка</label>
                        <Input.Dropdown
                             value={payload?.bankData?.bankAccount || ''}
                             options={['ДСК', 'УниКредит', 'Райфайзен']}
                            //  onChange={(value) => setBankAccount(value.value)}
                            onChange={(e) => {
                                setPayload({...payload, bankData: {...payload.bankData, bankAccount: e.value}})
                            }}
                         />
                    </div>
                </div>
            </div>

            <div className='edit-offer-main-bottom'>
                <h4>Продукти към оферта</h4>
                {payload?.products && payload?.products?.map((product, i) => {
                return <div className='single-product-wrapper' key={product?.item?._id}>
                        <div className='input-wrapper product-section'>
                            <div className='input-container border'>
                                <label>Наименование</label>
                                <Input.Text 
                                disabled
                                value={getProductName(product?.item?.basicInfo) || product?.item?.friendlyNames[0]?.split(',')[0]} />
                            </div>
                            <div className='input-container border'>
                                <label>К-во</label>
                                <div className='input-unit-wrapper'>
                                  <Input.Text value={product?.quantity} />
                                  <p>бр.</p>
                                </div>
                                
                            </div>
                            <div className='input-container border'>
                                <label>Срок за доставка</label>
                                <Input.Datepicker 
                                pickDate={moment(product?.deliveryDate).toDate() || null}
                                onChange={(date) => {
                                    let newPayload = JSON.parse(JSON.stringify(payload))
                                    newPayload.products[i].deliveryDate = date
                                    setPayload(newPayload)
                                }} />
                            </div>
                            <div className='input-container border'>
                                <label>Ед.стойност</label>
                                <div className='input-unit-wrapper'>
                                  <Input.Text 
                                  value={product.singleUnitPrice}
                                  onChange={(e) => {
                                    if (isBudgetValid(e.target.value)) {
                                        let newPayload = JSON.parse(JSON.stringify(payload))
                                        newPayload.products[i].singleUnitPrice = e.target.value
                                        newPayload.products[i].total = Number(e.target.value) * newPayload.products[i].quantity
                                        setPayload(newPayload)
                                    }
                                  }}
                                   />
                                  <p>лв.</p>
                                </div>
                            </div>
                            <div className='input-container'>
                                <label>Общо</label>
                                <div className='input-icon-wrapper'>
                                    <div className='input-unit-wrapper'>
                                    <Input.Text disabled value={product.total} />
                                    <p>лв.</p>
                                    </div>
                                  <img src={deleteIcon} alt='X'
                                  onClick={() => {
                                    let newPayload = JSON.parse(JSON.stringify(payload))
                                    newPayload.products = newPayload.products.filter(payloadProduct => payloadProduct?._id !== product?._id)
                                    setPayload(newPayload)
                                  }}/>
                                </div>
                                
                            </div>
                        </div>

                        <div className='product-img-wrapper'>
                            <p>{getProductName(product?.item?.basicInfo) || ''}</p>
                            <img src={arrowIcon} onClick={() => history.push(`/products/${product._id}`)} />
                        </div>
                    </div>
                })}

                <div className='profit-wrapper'>
                 <p>
                    Марж:
                    <span>&nbsp;
                    {(calcTotalExpenses(payload.products) - calcProductsWithoutQuantity(payload.products)).toFixed(2)} лв. | &nbsp;
                    {(((calcTotalExpenses(payload.products) - calcProductsWithoutQuantity(payload.products)) / calcTotalExpenses(payload.products)) * 100).toFixed(0)} %</span>
                 </p>
                </div>
                
                <div className='total-value-wrapper'>
                    <h4>Стойност на продуктите</h4>
                    <div className='input-unit-wrapper'>
                      <Input.Text value={calcTotalExpenses(payload.products)} />
                      <p>лв.</p>
                    </div>
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
