import React, { useRef, useState } from "react"
import { SingleRadioButton } from "../../components"
import { Section, Button } from "@makedonski/admin-ui"
import "./styles.scss"

const sortingMap = {
  "Вид продукт": "product",
  "Дейности": "activities",
  Доставчик: "provider",
  "Заявено на": "createdAt",
  "Доставка до": "deliveryDate",
  "Доставена на": "deliveredAt",
  "Заявено к-во": "quantity",
  Цена: "price",
  Плащане: "payments",
  Статус: "status",
  "Продукт": "product",
  "К-во": "quantity",
  "Срок": "deadline",
  "Проекти": "projects",
  "Клиент": "client",
  "Код": "productCode",
  "Зона": "quantity2"
}

const requestSortingMap = {
  "Продукт": "product",
  "К-во": "quantity",
  "Срок": "deadline",
  "Проекти": "projects"
}

function SearchResultsHeader({
  elements,
  justifyContent,
  width,
  onSearch,
  onClearFilter,
  onSetSort,
  setSort,
  cursor,
  sort
}) {
  let openFilter = useRef()
  const [filter, setFilter] = useState("")
  return (
    <div className="table-header-container" style={{width: width}}>
      {elements.map((el) => {
        return (
          <>
            {el.filter ? (
              <Section.Popup
                ref={(ref) => {
                  openFilter = ref
                }}
                content={
                  <div className="filter-popup-container">
                    <div className="flex-container space-between">
                      <h4>Пореди по</h4>
                      <div className="close-btn" />
                    </div>
                    <SingleRadioButton
                      label="Възходящи"
                      selected={filter === "Възходящи"}
                      onClick={() => setFilter("Възходящи")}
                    />
                    <SingleRadioButton
                      label="Низходящи"
                      selected={filter === "Низходящи"}
                      onClick={() => setFilter("Низходящи")}
                    />
                    <div className="flex-container space-between">
                      <Button.Raised
                        text="Изчисти"
                        clear
                        onClick={() => {
                          onClearFilter()
                          setFilter("")
                          openFilter.hide()
                        }}
                      />
                      <Button.Raised
                        text="Задай"
                        onClick={() => {
                          filter && onSearch(filter)
                          openFilter.hide()
                        }}
                      />
                    </div>
                  </div>
                }
              >
                <div
                  className="table-header-element cursor bold"
                  style={{
                    flex: el.size,
                    width: el?.fixedWidth,
                    whiteSpace: el?.whiteSpace,
                    alignItems: justifyContent,
                    justifyContent: justifyContent || "center"
                  }}
                  onClick={() => openFilter.show()}
                >
                  <p 
                  style={{
                    whiteSpace: el?.whiteSpace,
                    // fontSize: el?.fontSize
                  }}
                    >{el.name}</p>
                  {el.name.length > 0 &&
                   <div className="arrow-icon"
                        style={{transform: sort?.filter === sortingMap[el.name] && sort?.reverse ? "rotate(180deg)" : ""}} />}
                </div>
              </Section.Popup>
            ) : (
              <div
                onClick={() => {
                  onSetSort && onSetSort(sortingMap[el.name])
                  setSort && setSort(!sort)
                }}
                className="table-header-element"
                style={{
                  flex: el.size,
                  width: el?.fixedWidth,
                  whiteSpace: el?.whiteSpace,
                  justifyContent: justifyContent || "center",
                  cursor: cursor ? "pointer" : "default",
                }}
              >
                <p 
                  style={{
                    whiteSpace: el?.whiteSpace,
                    // fontSize: el?.fontSize
                  }}
                    >{el.name}</p>
                {el.name.length > 0 && 
                <div className="arrow-icon"
                     style={{transform: sort?.filter === sortingMap[el.name] && sort?.reverse ? "rotate(180deg)" : ""}}
                 />}
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

export default SearchResultsHeader
