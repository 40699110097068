export const ProductTypes = {
  GET_PRODUCT_TYPES: 'products/GET_PRODUCT_TYPES',
  GET_PRODUCT_TYPES_SUCCESS: 'products/GET_PRODUCT_TYPES_SUCCESS',
  GET_PRODUCT: 'products/GET_PRODUCT',
  GET_PRODUCT_SUCCESS: 'products/GET_PRODUCT_SUCCESS',
  GET_SINGLE_PRODUCT: 'products/GET_SINGLE_PRODUCT',
  GET_SINGLE_PRODUCT_SUCCESS: 'products/GET_SINGLE_PRODUCT_SUCCESS',
  CREATE_PRODUCT: 'products/CREATE_PRODUCT',
  CREATE_PRODUCT_SUCCESS: 'products/CREATE_PRODUCT_SUCCESS',
  UPDATE_EXPENSE: 'products/UPDATE_EXPENSE',
  UPDATE_EXPENSE_SUCCESS: 'products/UPDATE_EXPENSE_SUCCESS',
  UPDATE_PRODUCTION: 'products/UPDATE_PRODUCTION',
  UPDATE_PRODUCTION_SUCCESS: 'products/UPDATE_PRODUCTION_SUCCESS',
  SEARCH_PRODUCT: 'products/SEARCH_PRODUCT',
  SEARCH_PRODUCT_SUCCESS: 'products/SEARCH_PRODUCT_SUCCESS',
  SEARCH_PRODUCT_MORE_THAN_ONE_SUCCESS:
    'products/SEARCH_PRODUCT_MORE_THAN_ONE_SUCCESS',
  CLEAR_FOUND: 'products/CLEAR_FOUND',
  UPDATE_PRODUCT: 'products/UPDATE_PRODUCT',
  UPDATE_PRODUCT_SUCCESS: 'products/UPDATE_PRODUCT_SUCCESS',
  EDIT_EXPENSE: 'products/EDIT_EXPENSE',
  EDIT_EXPENSE_SUCCESS: 'products/EDIT_EXPENSE_SUCCESS',
  EDIT_PRODUCTION: 'products/EDIT_PRODUCTION',
  EDIT_PRODUCTION_SUCCESS: 'products/EDIT_PRODUCTION_SUCCESS',
  CLEAR_RESULTS: 'products/CLEAR_RESULTS',

  GET_ALL_PRODUCTS: 'products/GET_ALL_PRODUCTS',
  GET_ALL_PRODUCTS_SUCCESS: 'products/GET_ALL_PRODUCTS_SUCCESS'
}

export const clearResults = () => ({
  type: ProductTypes.CLEAR_RESULTS
})

export const getAllProducts = () => ({
  type: ProductTypes.GET_ALL_PRODUCTS
})

export const editProdictionAction = (payload) => ({
  type: ProductTypes.EDIT_PRODUCTION,
  payload
})

export const editProdictionSuccess = (payload) => ({
  type: ProductTypes.EDIT_PRODUCTION_SUCCESS,
  payload
})

export const editExpensesAction = (payload) => ({
  type: ProductTypes.EDIT_EXPENSE,
  payload
})

export const editExpensesSuccess = (payload) => ({
  type: ProductTypes.EDIT_EXPENSE_SUCCESS,
  payload
})

export const updateProductAction = (payload) => ({
  type: ProductTypes.UPDATE_PRODUCT,
  payload
})

export const updateProductSuccess = (payload) => ({
  type: ProductTypes.UPDATE_PRODUCT_SUCCESS,
  payload
})

export const updateProductProduction = (payload) => ({
  type: ProductTypes.UPDATE_PRODUCTION,
  payload
})

export const updateProductProductionSuccess = (payload) => ({
  type: ProductTypes.UPDATE_PRODUCTION_SUCCESS,
  payload
})

export const updateProductExpense = (payload) => ({
  type: ProductTypes.UPDATE_EXPENSE,
  payload
})

export const updateProductExpenseSuccess = (payload) => ({
  type: ProductTypes.UPDATE_EXPENSE_SUCCESS,
  payload
})

export const createProduct = (payload) => ({
  type: ProductTypes.CREATE_PRODUCT,
  payload
})

export const createProductSuccess = (payload) => ({
  type: ProductTypes.CREATE_PRODUCT_SUCCESS,
  payload
})

export const getProductTypes = (payload) => ({
  type: ProductTypes.GET_PRODUCT_TYPES,
  payload
})

export const getProduct = (payload) => ({
  type: ProductTypes.GET_PRODUCT,
  payload
})

export const getProductSuccess = (payload) => ({
  type: ProductTypes.GET_PRODUCT_SUCCESS,
  payload
})

export const getSingleProduct = (payload) => ({
  type: ProductTypes.GET_SINGLE_PRODUCT,
  payload
})

export const getSingleProductSuccess = (payload) => ({
  type: ProductTypes.GET_SINGLE_PRODUCT_SUCCESS,
  payload
})

export const searchProduct = (payload) => ({
  type: ProductTypes.SEARCH_PRODUCT,
  payload
})

export const searchProductSuccess = (payload) => ({
  type: ProductTypes.SEARCH_PRODUCT_SUCCESS,
  payload
})

export const searchProductMoreSuccess = (payload) => ({
  type: ProductTypes.SEARCH_PRODUCT_MORE_THAN_ONE_SUCCESS,
  payload
})

export const clearFound = () => ({
  type: ProductTypes.CLEAR_FOUND
})
