import React, { useState } from "react"
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Menu,
  Radio,
  RadioGroup
} from "@mui/material"
import { searchInquiries } from "../../actions"
import { useSelector, useDispatch } from "react-redux"
import styles from "./ProjectFilters.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import { Button as ButtonMui } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

const mapStatuses = {
  projection: "Проектиране",
  "choosing-provider": "Избор на доставчик",
  auction: "Търг за изпълнители",
  lost: "Загубен",
  finished: "Приключил",
  other: "Друго",
}

export default function ProjectFilters({
  sortZones,
  setSortZones,
  sortParams,
  setSortParams,
  sortStatuses,
  setSortStatuses,
  chosenSortStatuses,
  handleStatusChange,
  handleFilterStatuses,
  handleFilterZones,
  setReverseSort,
  reverseSort,
  selectedSortValue,
  // setValue,
  headerElements,
  sort,
  setFilters
  // setSort
}) {
  const handleChangeZones = (event) => {
    const { name, checked } = event.target
    let newSortZones = [...sortZones]
    const updatedZonesState = newSortZones.map((zone) =>
      zone.zone === name ? { ...zone, checked: checked } : zone
    )

    setSortZones(updatedZonesState)
  }
  const [zonesAnchor, setZonesAnchor] = useState(null)
  const [radioBtnValue, setRadioBtnValue] = useState("")
  const statusesList = useSelector(state => state.inquiries.statuses)
  const openZones = Boolean(zonesAnchor)
  const handleClickZones = (e) => {
    setZonesAnchor(e.currentTarget)
  }
  const handleCloseZones = () => {
    setZonesAnchor(null)
  }

  const handleChange = (event) => {
    const { name, checked } = event.target
    let newSortStatuses = [...sortStatuses]
    const updatedStatusesState = newSortStatuses.map((status) =>
      status.status === name ? { ...status, checked: checked } : status
    )

    setSortStatuses(updatedStatusesState)
  }

  const [statusesAnchor, setStatusesAnchor] = useState(null)
  const openStatuses = Boolean(statusesAnchor)
  const handleClickStatuses = (e) => {
    setStatusesAnchor(e.currentTarget)
  }
  const handleClose = () => {
    setStatusesAnchor(null)
  }

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getInquiryStatuses())
  // }, [])

  return (
    <div className='filters-container'>
      {headerElements.map((element, index) => {
        if (element.name === 'Статус') {
          return (
            <div
            className={`single-filter-element ${element.class}`}
            style={{cursor: element?.filter ? 'pointer' : 'default'}}
            key={`${element?.type || element?.name}-${index}`}>
              <ButtonMui
              style={{ textTransform: "none", color: "black", marginRight: "10px", fontWeight: "600", opacity: "0.9" }}
              disableElevation
              disableRipple
              disableFocusRipple
              className={styles["filter-button"]}
              endIcon={<KeyboardArrowDownIcon style={{ color: "#26ACE2" }} />}
              onClick={handleClickStatuses}
              >
              {element?.name}
              </ButtonMui>

              <Menu
                PaperProps={{ style: { padding: "15px" } }}
                anchorEl={statusesAnchor}
                open={openStatuses}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div className={styles["filters-options"]} style={{ display: "flex" }}>
                  <h5>Филтрация по</h5>
                  <CloseIcon
                    fontSize="small"
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <FormControl>
                  {/* <RadioGroup
                    value={radioBtnValue}
                    onChange={handleChange}
                  >
                    {sortStatuses?.map(status => {
                      return (
                        <FormControlLabel
                        value={status?.name}
                        control={<Radio sx={radioStyles} />}
                        label={status?.name}
                      />
                      )
                    })}
                  </RadioGroup> */}

                  <div className={styles["scroll-container"]}
                  style={{ display: "flex", flexDirection: "column", height: "300px", overflowY: "auto", margin: "15px 0" }}>
                    {sortStatuses?.map(status => {
                      const checked = chosenSortStatuses.findIndex(el => el === status?.name || el === mapStatuses[status?.name]) !== -1;
                      return (
                        <FormControlLabel
                        // value={mappedStatus}
                        value={mapStatuses[status?.name] || status?.name}
                        control={
                          <Checkbox
                            sx={radioStyles}
                            checked={checked}
                            onChange={handleStatusChange}
                            name={mapStatuses[status?.name] || status?.name}
                          />
                        }
                        label={mapStatuses[status?.name] || status?.name}
                      />
                      )
                    })}
                  </div>
                  <ButtonMui
                    sx={{
                      background: "#26ACE2",
                      "&:hover": {
                        background: "#26ACE2",
                      },
                    }}
                    onClick={() => {
                      handleFilterStatuses()
                      handleClose()
                    }}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    fullWidth
                  >
                    Задай
                  </ButtonMui>
                </FormControl>
                
              </Menu>
            </div>
          )
        }

        else {
          return (
            <div
            className={`single-filter-element ${element.class}`}
            style={{cursor: element?.filter ? 'pointer' : 'default'}}
            key={`${element?.type || element?.name}-${index}`}
            onClick={(e) => {
              if (element.name === 'Статус') {
                handleClickStatuses(e)
              }
              else {
                setFilters(sort, element?.type)
              }
              // setValue(element.type)
              // sort === 1 ? setSort(-1) : setSort(1)
              }}>
             <p>{element.name}</p>
             {element?.filter && 
             <img src={require('../../assets/images/arrow-down.svg')}
                  style={ selectedSortValue === element?.type && sort === 1
                     ? { transform: "rotate(180deg)" } : {} }
             />}
            </div>
          )
        }
      })}

      <Menu
        // anchorEl={statusesAnchor}
        // open={openStatuses}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          style: { padding: "15px", display: "flex", flexDirection: "column" },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>Филтрирай по статус</p>
          <CloseIcon onClick={handleClose} />
        </div>
        <FormControl className={styles["form-control"]}>
          <FormGroup>
            {Object.keys(sortStatuses).map((stats) => {
              // const status = sortStatuses[stats].status
              const status = stats
              return (
                <FormControlLabel
                  // value={mappedStatus}
                  control={
                    <Checkbox
                      sx={radioStyles}
                      checked={sortStatuses[stats].checked}
                      onChange={handleChange}
                      name={sortStatuses[stats].status}
                    />
                  }
                  label={mapStatuses[status] || status}
                />
              )
            })}
          </FormGroup>
        </FormControl>
        <Button
          variant="contained"
          onClick={(e) => {
            handleFilterStatuses(e)
            handleClose()
          }}
        >
          Филтрирай
        </Button>
      </Menu>
      {/* <Button
        onClick={handleClickZones}
        disableElevation
        disableRipple
        disableFocusRipple
        endIcon={
          sortParams === "zone" ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
        className={styles["filter-button"]}
      >
        Зона
      </Button> */}
      {/* <Menu
        anchorEl={zonesAnchor}
        open={openZones}
        onClose={handleCloseZones}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          style: { padding: "15px", display: "flex", flexDirection: "column" },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>Филтрирай по зона</p>
          <CloseIcon onClick={handleCloseZones} />
        </div>
        <FormControl className={styles["form-control"]}>
          <FormGroup>
            {Object.keys(sortZones).map((sortedZone) => {
              const zone = sortZones[sortedZone].zone
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={radioStyles}
                      checked={sortZones[sortedZone].checked}
                      onChange={handleChangeZones}
                      name={zone}
                    />
                  }
                  label={zone}
                />
              )
            })}
          </FormGroup>
        </FormControl>
        <Button
          variant="contained"
          onClick={(e) => {
            handleFilterZones(e)
            handleClose()
          }}
        >
          Филтрирай
        </Button>
      </Menu> */}
    </div>
  )
}
