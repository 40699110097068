import React from 'react';
import './styles.scss';
import { history } from '../../config/stores';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMachineSchedule, getUnfinishedWorks, showLoading } from '../../actions';
import { getProductName } from '../../utilities/helpers';
import RadioButton from '../../components/RadioButton/RadioButton';
import { Button } from "@makedonski/admin-ui";
import moment from 'moment';
import _ from 'lodash';

export default function MachineRequestsView() {
  const dispatch = useDispatch(); 
  const requestsRef = useRef();

  const [ selectedRequest, setSelectedRequest ] = useState('')
  const [searchQuery, setSearchQuery] = useState('');
  const [ data, setData ] = useState([])

  const machineData = useSelector(state => state.production.productSchedule);
  const scheduleRequestsTablet = useSelector(state => state.production.scheduleRequestsTablet);
  const hasNextPage = useSelector(state => state.production.hasNextPage);
  const nextPage = useSelector(state => state.production.nextPage);

  let selectedMachine = localStorage.getItem('machine');

  const checkSelectedRequest = (id) => selectedRequest === id ? true : false 

  const handleSearch = () => {
    if (searchQuery) {
      requestsRef.current && requestsRef.current.scrollTo({top: 0, behavior: 'smooth'})

      dispatch(showLoading())
      dispatch(getUnfinishedWorks({ activity: searchQuery, machine: selectedMachine, pageNumber: 1, pageSize: 20 }))
    }
  }

  const handleScrolling = () => {
    if (requestsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = requestsRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) <= 1 && hasNextPage && scrollTop !== 0) {
        let payloadObj = { machine: selectedMachine, pageNumber: nextPage, pageSize: 20 }
      
        if (searchQuery) {
          payloadObj.activity = searchQuery;
        }
    
        dispatch(showLoading())
        dispatch(getUnfinishedWorks(payloadObj)) 
      }
    }
    else {
      dispatch(showLoading())
      dispatch(getUnfinishedWorks({ machine: selectedMachine }))
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  useEffect(() => {
    localStorage.setItem('tabletRequest', '')
    dispatch(getMachineSchedule({ start: moment().subtract(20, 'days').startOf('day').startOf('hour'),  end: moment().endOf('day').endOf('hour')}))
    dispatch(getUnfinishedWorks({ machine: selectedMachine }))
    // setSelectedRequest(selectedMachine)
  }, []);

  useEffect(() => {
    dispatch(showLoading())
    dispatch(getUnfinishedWorks({ machine: selectedMachine }))
  }, [selectedMachine]);

  useEffect(() => {
    setData(scheduleRequestsTablet)
  }, [scheduleRequestsTablet]);

  return (
    <div className='tablet-view-wrapper requests'>
        <div className='top-heading-wrapper'>
            <img src={require('../../assets/icons/go-back.svg')} onClick={() => history.push('/')} />
            <h4>Избор на дейност</h4>
        </div>

        <div className='section-wrapper search'>
          <div className='flex search'>
            <input type='text' placeholder='Търсене на дейност' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}></input>
            <img className='icon' src={require('../../assets/icons/search-icon.svg')} onClick={handleSearch} />
          </div>
        </div>

        <div className='section-wrapper requests' ref={requestsRef} onScroll={delayedScrolling}>
          {data?.map((request, index) => {
            return (
              <>
              {(index === 0 || !(moment(data[index - 1]?.start).isSame(moment(request?.start), 'day'))) && 
              <div className='flex date'>
                <p>{moment(request?.start).format('DD.MM.YYYY')}</p>
                <div className='line' />
              </div>}

              <div className={`section-wrapper margin ${checkSelectedRequest(request?._id) ? 'selected' : ''}`}>
                <div className='radio-button-wrapper'>
                   <RadioButton
                     changed={() => {
                       setSelectedRequest(request?._id)
                     }}
                     id={`button-${index}`}
                     isSelected={checkSelectedRequest(request?._id)}
                     label=""
                     value={request?._id}
                   />
                </div>

                <div className='data-element bold'>{request?.activity}</div>
                <div className='data-element bold'>{getProductName(request?.product?.basicInfo)}</div>
                <div className='data-element'>Количество: <span>{`${request?.quantity}  ${request?.product?.mainUnit}`}</span></div>
                <div className='data-element'>Ден: <span>{moment(request?.start).format('DD.MM.YYYY')}</span></div>
                <div className='data-element'>Начален час: <span>{moment(request?.start).format('HH:mm')}</span></div>
                <div className='data-element'>Краен час: <span>{moment(request?.end).format('HH:mm')}</span></div>
                <div className='data-element'>Времетраене: 
                {request?.end && <span>{` ${(moment(request?.end)).diff(moment(request?.start), 'hours')}ч ${moment(request?.end).diff(moment(request?.start), 'minutes') % 60} мин`}</span>}
                </div>
              </div>
              </>
            )
          })}
        </div>

        <div className='button-wrapper'>
         <Button.Raised
           text="Избери"
           disabled={!selectedRequest} 
           onClick={() => {
             localStorage.setItem('tabletRequest', selectedRequest)
            //  history.push('/machine-tablet-view')
             history.push(`/machine-tablet-view/${selectedRequest}`)
             }}></Button.Raised>
        </div>
    </div>
  )
}
