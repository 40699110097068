export const StorageTypes = {
  GET_REQUESTS: "requests/GET_REQUESTS",
  GET_REQUESTS_SUCCESS: "requests/GET_REQUESTS_SUCCESS",
  CREATE_REQUEST: "requests/CREATE_REQUEST",
  CREATE_REQUEST_SUCCESS: "requests/CREATE_REQUEST_SUCCESS",
  ORDER_REQUEST: "requests/ORDER_REQUEST",
  ORDER_REQUEST_SUCCESS: "requests/ORDER_REQUEST_SUCCESS",
  UPDATE_ORDER_STATUS: "requests/UPDATE_ORDER_STATUS",
  UPDATE_ORDER_STATUS_SUCCESS: "requests/UPDATE_ORDER_STATUS_SUCCESS",
  POSTS_REQUEST: "requests/POSTS_REQUEST",
  POSTS_REQUEST_SUCCESS: "requests/POSTS_REQUEST_SUCCESS",
  EXPORT_REQUEST: "requests/EXPORT_REQUEST",
  EXPORT_REQUEST_SUCCESS: "requests/EXPORT_REQUEST_SUCCESS",
  SEARCH_PROVIDER: "requests/SEARCH_PROVIDER",
  SEARCH_PROVIDER_SUCCESS: "requests/SEARCH_PROVIDER_SUCCESS",
  SEARCH: "requests/SEARCH",
  SEARCH_SUCCESS: "requests/SEARCH_SUCCESS",

  CREATE_PROFORM: "requests/CREATE_PROFORM",
  CREATE_PROFORM_SUCCESS: "requests/CREATE_PROFORM_SUCCESS",
  CREATE_ACT_OF_ACCEPTANCE: "requests/CREATE_ACT_OF_ACCEPTANCE",
  CREATE_ACT_OF_ACCEPTANCE_SUCCESS: "requests/CREATE_ACT_OF_ACCEPTANCE_SUCCESS",

  EDIT_PROFORM: "requests/EDIT_PROFORM",
  EDIT_PROFORM_SUCCESS: "requests/EDIT_PROFORM_SUCCESS",
  EDIT_ACT_OF_ACCEPTANCE: "requests/EDIT_ACT_OF_ACCEPTANCE",
  EDIT_ACT_OF_ACCEPTANCE_SUCCESS: "requests/EDIT_ACT_OF_ACCEPTANCE_SUCCESS",

  GET_AVAILABLE_PRODUCTS: "storage/GET_AVAILABLE_PRODUCTS",
  GET_AVAILABLE_PRODUCTS_SUCCESS: "storage/GET_AVAILABLE_PRODUCTS_SUCCESS",
  SEARCH_STORAGE_PRODUCTS: "storage/SEARCH_STORAGE_PRODUCTS",
  SEARCH_STORAGE_PRODUCTS_SUCCESS: "storage/SEARCH_STORAGE_PRODUCTS_SUCCESS",
  RESET_STORAGE_RESULTS: "storage/RESET_STORAGE_RESULTS",
  CREATE_STORAGE_PRODUCT: "storage/CREATE_STORAGE_PRODUCT",
  CREATE_STORAGE_PRODUCT_SUCCESS: "storage/CREATE_STORAGE_PRODUCT_SUCCESS",
  EDIT_STORAGE_PRODUCT: "storage/EDIT_STORAGE_PRODUCT",
  EDIT_STORAGE_PRODUCT_SUCCESS: "storage/EDIT_STORAGE_PRODUCT_SUCCESS",

  EXPORT_PRODUCT: "storage/EXPORT_PRODUCT",
  SAMPLE_PRODUCT: "storage/SAMPLE_PRODUCT",
  DEFECT_PRODUCT: "storage/DEFECT_PRODUCT",
  UPDATE_MATERIAL: "storage/UPDATE_MATERIAL",

  UPDATE_PRODUCTS: "storage/UPDATE_PRODUCTS",

  GET_HP_REQUESTS: "storage/GET_HP_REQUESTS",
  GET_HP_REQUESTS_SUCCESS: "storage/GET_HP_REQUESTS_SUCCESS",

  CREATE_HP_REQUEST: "storage/CREATE_HP_REQUEST",
  CREATE_HP_REQUEST_SUCCESS: "storage/CREATE_HP_REQUEST_SUCCESS",

  EDIT_HP_REQUEST: "storage/EDIT_HP_REQUEST",
  EDIT_HP_REQUEST_SUCCESS: "storage/EDIT_HP_REQUEST_SUCCESS",

  EDIT_HP_INVOICE: "storage/EDIT_HP_INVOICE",

  ORDER_HP_REQUEST: "storage/ORDER_HP_REQUEST",
  ORDER_HP_REQUEST_SUCCESS: "storage/ORDER_HP_REQUEST_SUCCESS",

  CHANGE_HP_ORDER_STATUS: "storage/CHANGE_HP_ORDER_STATUS",
  CHANGE_HP_ORDER_STATUS_SUCCESS: "storage/CHANGE_HP_ORDER_STATUS_SUCCESS",

  ACCRUE_HP_ORDER: "storage/ACCRUE_HP_ORDER",
  ACCRUE_HP_ORDER_SUCCESS: "storage/ACCRUE_HP_ORDER_SUCCESS",

  SELL_HP_ORDER: "storage/SELL_HP_ORDER",
  SELL_HP_ORDER_SUCCESS: "storage/SELL_HP_ORDER_SUCCESS",

  FILTER_HP_ORDERS: "storage/FILTER_HP_ORDERS",
  FILTER_HP_ORDERS_SUCCESS: "storage/FILTER_HP_ORDERS_SUCCESS",

  SELL_PRODUCT_INVOICE: "storage/SELL_PRODUCT_INVOICE",
  SELL_PRODUCT_INVOICE_SUCCESS: "storage/SELL_PRODUCT_INVOICE_SUCCESS",

  SELL_PRODUCT_INVOICE_HP: "storage/SELL_PRODUCT_INVOICE_HP",
  SELL_PRODUCT_INVOICE_HP_SUCCESS: "storage/SELL_PRODUCT_INVOICE_HP_SUCCESS",
}

export const filterHPOrders = (payload) => ({
  type: StorageTypes.FILTER_HP_ORDERS,
  payload,
})

export const sellHPOrder = (payload) => ({
  type: StorageTypes.SELL_HP_ORDER,
  payload,
})

export const accrueHPOrder = (payload) => ({
  type: StorageTypes.ACCRUE_HP_ORDER,
  payload,
})

export const createProformStorage = (payload) => ({
  type: StorageTypes.CREATE_PROFORM,
  payload,
})

export const createActOfAcceptanceStorage = (payload) => ({
  type: StorageTypes.CREATE_ACT_OF_ACCEPTANCE,
  payload,
})

export const editProformStorage = (payload) => ({
  type: StorageTypes.EDIT_PROFORM,
  payload,
})

export const editActOfAcceptanceStorage = (payload) => ({
  type: StorageTypes.EDIT_ACT_OF_ACCEPTANCE,
  payload,
})

export const changeHPOrderStatus = (payload) => ({
  type: StorageTypes.CHANGE_HP_ORDER_STATUS,
  payload,
})

export const orderHPRequest = (payload) => ({
  type: StorageTypes.ORDER_HP_REQUEST,
  payload,
})

export const createHPRequest = (payload) => ({
  type: StorageTypes.CREATE_HP_REQUEST,
  ...payload,
})

export const editHPRequest = (payload) => ({
  type: StorageTypes.EDIT_HP_REQUEST,
  payload,
})

export const editHPInvoice = (payload) => ({
  type: StorageTypes.EDIT_HP_INVOICE,
  payload,
})

export const getHPRequests = (payload) => ({
  type: StorageTypes.GET_HP_REQUESTS,
  payload,
})

export const updateMaterial = (payload) => ({
  type: StorageTypes.UPDATE_MATERIAL,
  payload,
})

export const defectProduct = (payload) => ({
  type: StorageTypes.DEFECT_PRODUCT,
  payload,
})

export const sampleProduct = (payload) => ({
  type: StorageTypes.SAMPLE_PRODUCT,
  payload,
})

export const exportProduct = (payload) => ({
  type: StorageTypes.EXPORT_PRODUCT,
  payload,
})

export const editStorageProduct = (payload) => ({
  type: StorageTypes.EDIT_STORAGE_PRODUCT,
  payload,
})

export const createStorageProduct = (payload) => ({
  type: StorageTypes.CREATE_STORAGE_PRODUCT,
  payload,
})

export const resetStorageResults = () => ({
  type: StorageTypes.RESET_STORAGE_RESULTS,
})

export const searchStorageProducts = (payload) => ({
  type: StorageTypes.SEARCH_STORAGE_PRODUCTS,
  payload,
})

export const getAvailableProducts = () => ({
  type: StorageTypes.GET_AVAILABLE_PRODUCTS,
})

export const searchRequests = (payload) => ({
  type: StorageTypes.SEARCH,
  payload,
})

export const searchRequestsSuccess = (payload) => ({
  type: StorageTypes.SEARCH_SUCCESS,
  payload,
})

export const getRequests = () => ({
  type: StorageTypes.GET_REQUESTS,
})

export const createRequest = (payload) => ({
  type: StorageTypes.CREATE_REQUEST,
  payload,
})

export const orderRequest = (payload) => ({
  type: StorageTypes.ORDER_REQUEST,
  payload,
})

export const updateOrderStatus = (payload) => ({
  type: StorageTypes.UPDATE_ORDER_STATUS,
  payload,
})

export const postsRequest = (payload) => ({
  type: StorageTypes.POSTS_REQUEST,
  payload,
})

export const exportRequest = (payload) => ({
  type: StorageTypes.EXPORT_REQUEST,
  payload,
})

export const searchProvider = (payload) => ({
  type: StorageTypes.SEARCH_PROVIDER,
  payload,
})

export const sellProduct = (payload) => ({
  type: StorageTypes.SELL_PRODUCT_INVOICE,
  payload,
})

export const sellProductHP = (payload) => ({
  type: StorageTypes.SELL_PRODUCT_INVOICE_HP,
  payload,
})
