import S3FileUpload from "react-s3"
import { nanoid } from "nanoid"
import { s3Credentials } from "../config/settings"

const Files = {
  uploadFiles(files) {
    const promises = []
    Array.from(files).forEach((f) => {
      const file = new File([f], nanoid(), { type: f.type })
      promises.push(S3FileUpload.uploadFile(file, s3Credentials))
    })
    return Promise.all(promises)
  },
  uploadFile(file, name) {
    const uploadFile = new File([file], name || `${nanoid()}_${file.name}`, {
      type: file.type,
      lastModified: file.lastModified,
    })
    return S3FileUpload.uploadFile(uploadFile, s3Credentials)
  },
}

export default Files
