import React from "react"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getMachineSchedule } from "../../actions"
import moment from "moment"
import "./styles.scss"
import ScheduleDailyOrderColumn from "../../screens/Production/Daily/ScheduleDailyOrderColumn"

export default function SchedulePopup({ startDate, endDate, machine, payload, setIsTimeValid, onClose }) {
    const dispatch = useDispatch()
    const data = useSelector((state) => state.production.productSchedule)
    const [ shift, setShift ] = useState('day')
    let startHour = moment().startOf('day').add(7, 'hours')
    let endHour = moment().startOf('day').add(19, 'hours')

    const halfHoursDay = [
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00'
    ]

    const halfHoursNight = [
      '20:00',
      '20:30',
      '21:00',
      '21:30',
      '22:00',
      '22:30',
      '23:00',
      '23:30',
      '00:00',
      '00:30',
      '01:00',
      '01:30',
      '02:00',
      '02:30',
      '03:00',
      '03:30',
      '04:00',
      '04:30',
      '05:00',
      '05:30',
      '06:00',
      '06:30',
      '07:00',
      '07:30'
    ]

    const daySchedule = Array(12).fill(0).map(el => {
      startHour.add(1, 'hour')
      return el = startHour.format('HH:mm')
    })

    const nightSchedule = Array(12).fill(0).map(el => {
      endHour.add(1, 'hour')
      return el = endHour.format('HH:mm')
    })

    const checkSchedule = (machine) => {
      const halfHours = shift === 'day' ? halfHoursDay.slice(0) : halfHoursNight.slice(0)
      const requests = halfHours.slice(0)
      machine && machine.forEach((data, index) => {
        const start = data.start ? data.start : data.startsAt
        const duration = data.durationMinutes ? data.durationMinutes / 60 : moment(data.end).diff(moment(data.start), 'hours')
        let counter = duration * 2 - 1
        machine[index + 1]
        && (machine[index + 1]?.start || machine[index + 1]?.startsAt) === (data?.end || data?.endsAt)
        && counter++
        let isRequestRendering = false
        halfHours.map((halfHour, i) => {
          if (moment(start).format('HH:mm') === halfHour &&
            isRequestRendering === false && (data?.request || data?.activity)) {
            requests.splice(i, 1, data)
            isRequestRendering = true
          } else if (counter > 0 && isRequestRendering === true) {
            requests.splice(i, 1, 'empty')
            counter--
          } else if (counter === 0) {
            isRequestRendering = false
          }
        })
      })
      return requests
    }

    const checkTimeOverlap = (data, startDate, endDate) => {
      const newStartDate = moment(startDate).startOf('minute')
      const newEndDate = moment(endDate).startOf('minute')
      let isValid = true

      data && data.map(request => {
        if (request._id === payload._id || request?.request?.inquiry?._id === payload.inquiry) {
          isValid = true
        } 
        else {
          let start = moment(request?.start).startOf('minute')
          let end = moment(request?.end).startOf('minute')
  
          if (newStartDate.isBetween(start, end) || newStartDate.isSame(start)
          || newEndDate.isBetween(start, end)
          // || newEndDate.isSame(end)
          || (newStartDate.isBefore(start) && newEndDate.isBetween(start, end))
          || (newStartDate.isBefore(start) && newEndDate.isAfter(end))) {
            isValid = false
          }  
        }
      })

      return setIsTimeValid(isValid)
    }

    useEffect(() => {
        startDate && dispatch(getMachineSchedule({
            start: moment(startDate).startOf("day").startOf("hour"),
            end: moment(startDate).endOf("day").endOf("hour")}))

        checkTimeOverlap(data[machine], startDate, endDate)
        
      }, [startDate, endDate, machine, payload])

  return (
    <div className="schedule-popup-container">
      <div className="schedule-top-wrapper">
        <p>График на машина {machine || "OD800"}</p>
        <img src={require("../../assets/icons/closeIcon.svg")} alt='x' onClick={onClose} />
      </div>

      <div className="day-night-shift-wrapper">
        <div className={shift === 'day' ? 'selected shift' : 'shift'}
        onClick={() => setShift('day')}>Дневна смяна</div>
        <div className={shift === 'night' ? 'selected shift' : 'shift'}
        onClick={() => setShift('night')}>Нощна смяна</div>
      </div>

      <div className="schedule-main-wrapper">
       {shift === 'day' ?
       <div className="schedule-hours-wrapper">
          {daySchedule.map((hour, i) => {
            return <div key={`hour-daily-popup-${i}`} className="hour">{hour}</div>
          })}
        </div> :
        <div className="schedule-hours-wrapper">
          {nightSchedule.map((hour, i) => {
            return <div key={`hour-nightly-popup-${i}`} className="hour">{hour}</div>
          })}
        </div>}
 
        <div className="schedule-requests-wrapper">
          <div className="render-container">
          {checkSchedule(data[machine]).map((el, index) => {
                    if (el === 'empty') {
                      return null
                    } else if (typeof el === 'object') {
                      return (
                        <ScheduleDailyOrderColumn
                        schedulePopup={true}
                        key={`machine-popup-daily-${index}`} ordersColumnData={el}
                        />)
                    } else {
                      return <div key={`empty-element-daily-machine-popup-${index}`}/>
                    }
                  })}
          </div>
          <div className="line-background-wrapper">
            {Array(12).fill(0).map((line, i) => {
              return <div className="line" key={`line-${i}`}></div>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

