import React, { useEffect, useState } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { connect } from 'react-redux'
import { nanoid } from 'nanoid'
import { RadioButton } from '..'
import { searchMap } from '../../utilities/searchMap'
import {
  getProductTypes,
  getVariations,
  searchProduct,
  clearFound
} from '../../actions'

const FilterProducts = ({
  onSelect,
  getProductNames,
  types,
  find,
  found,
  clear,
  onChangeType,
  requests
}) => {
  const [type, setType] = React.useState('')
  const [typeName, setTypeName] = React.useState('')
  const [search, setSearch] = React.useState({})
  const [ newTypes, setNewTypes ] = React.useState([])

  const getTypeRenderMap = React.useMemo(() => {
    if (searchMap[typeName]) return searchMap[typeName]
    return []
  }, [typeName])

  const updateSearch = (key, value) => {
    const n = { ...search }
    n[key] = value
    setSearch(n)
  }

  const isSelectedOption = (key, value) => {
    if (!search[key]) return false
    if (search[key] === value) return true
    return false
  }

  const renderOptionType = (input) => {
    return (
      <div key={`${input.name}-key`}>
        <span>{input.name}</span>
        <div className='radio-group-horizontal'>
          {input.options.map((opt) => {
            return (
              <RadioButton
                changed={() => {
                  updateSearch(input.name, opt)
                }}
                id={nanoid()}
                isSelected={isSelectedOption(input.name, opt)}
                label={opt}
                value={opt}
                key={`${input.name}-key`}
              />
            )
          })}
        </div>
      </div>
    )
  }
  const renderInputType = (input) => {
    return (
      <div key={`${input.name}-key`}>
        <span>{input.name}</span>
        <Input.Text
          placeholder={input.name}
          value={search[input.name]}
          onChange={(e) => {
            updateSearch(input.name, e.target.value)
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    getProductNames()
    if (requests && types) {
      setNewTypes([...types, ...requests])
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSearch({})
    if (requests && types) {
      setNewTypes([...types, ...requests])
    }
  }, [type])

  useEffect(() => {
    if (found) {
      if (onSelect) onSelect({ ...found })
      clear()
    }
    // eslint-disable-next-line
  }, [found])

  return (
    <>
      <span>Вид продукт</span>
      <Input.Dropdown
        placeholder='Изберете продукт'
        value={type}
        options={types.map((t) => ({ label: t.name, value: t._id }))}
        // options={newTypes.length === 0 ? types.map((t) => ({ label: t.name, value: t._id })) :
        //          newTypes.map((t) => ({ label: t.name, value: t._id }))}
        onChange={({ label, value }) => {
          setType(value)
          setTypeName(label)
          onChangeType && onChangeType()
        }}
      />
      {getTypeRenderMap.map((el) => {
        if (el.type === 'option') return renderOptionType(el)
        return renderInputType(el)
      })}
      <div className='flex-container'>
        <div />
        <div
          className='add-btn-container'
          onClick={() => {
            if (!type) {
              Alerts.error('Грешка!', 'Моля изберете вид продукт.')
            } else {
              const basicInfo = Object.keys(search).map((k) => {
                return {
                  name: k,
                  value: search[k]
                }
              })
              find({ basicInfo, productType: type })
            }
          }}
        >
          <span>Добави към запитване</span>
          <Button.Icon
            name='angle-right'
            size={30}
            color='lime'
            className=''
            iconStyles=''
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  types: state.products.types,
  variations: state.variations.data,
  found: state.products.found
})

const mapDispatchToProps = (dispatch) => ({
  getProductNames: (payload) => dispatch(getProductTypes(payload)),
  getProductVariations: (payload) => dispatch(getVariations(payload)),
  find: (payload) => dispatch(searchProduct(payload)),
  clear: () => dispatch(clearFound())
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterProducts)
