import { Text } from "./Text"
import { UnitDropdown } from "./UnitDropdown"
import { AutoSuggest } from "./AutoSuggest"
import Button from "./Button"
import TextInput from "./TextInput"
import TextAreaInput from "./TextAreaInput/TextAreaInput"
import SingleSelect from "./SingleSelect/SingleSelect"
import CheckBoxes from "./Checkboxes"
const Inputs = {
  Text,
  UnitDropdown,
  AutoSuggest,
  Button,
  TextInput,
  TextAreaInput,
  SingleSelect,
  CheckBoxes,
}

export { Inputs }
