import React from 'react';
import './styles.scss';
import moment from 'moment';
import { Button, Input, Section } from '@makedonski/admin-ui';
import { getSingleSchedule, searchStorageProducts, searchRequests, addCommentMachine, checkUnfinishedWork } from '../../actions';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../config/stores';
import { TimerController } from '../../components';
import useRecorder from '../../utilities/useRecorder';

export default function MachineActivityView() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const state = useSelector(state => state.production.currentSchedule);
    const results = useSelector((state) => state.storage.search);
    const storageProductResults = useSelector((state) => state.storage.storageProductResults);
    const machineScheduleWork = useSelector(state => state.production.machineScheduleWork);
    let selectedMachine = localStorage.getItem('machine');

    const totalProducts = [...results, ...storageProductResults].map((el) => {
        return el.material
          ? Object.assign(el, { name: el.material })
          : Object.assign(el, { name: el.type })
      })

    const [audioState, setAudioState] = useState("");
    const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
    const [audios, setAudios] = useState([]);
    const [record, setRecord] = useState(false);
    const [play, setPlay] = useState(true);
    const [timeRecorded, setTimeRecorded] = useState([]);
    let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();

    const checkMaterialStorage = (currentMaterial) => {
        if (currentMaterial) {
          const currentProduct = totalProducts.find((product) => {
            return product.name === currentMaterial
          })
          return (
            <span>
              &nbsp;{Math.abs(currentProduct?.quantity)}&nbsp;{currentProduct?.units}
            </span>
          )
        }
      }
    
      const checkMaterialPrice = (material) => {
        if (material) {
          const currentProduct = totalProducts.find((product) => {
            return product.name === material
          })
    
          const singleUnitPrice = currentProduct?.pricing?.singleUnitPrice || currentProduct?.price
    
          return (
            <span>
              &nbsp;{singleUnitPrice && singleUnitPrice?.toFixed(2)} лв./{currentProduct?.units}
            </span>
          )
        }
      }

      function Buttonstart() {
        setAudioState("red");
        setRecord(true);
        startRecording();
      }

      async function Buttonstop() {
        if (isRecording === true) {
          await stopRecording();
          setAudioState("#4695da");
          setRecord(false);
          setPlay(false);
        } 
        // else {
        //   alert("Give permission audio to record");
        // }

        // else {
        //   checkPermissions()
        // }
      }

      function Deletedata(id) {
        const data = audios.filter((e, index) => {
          return index !== id;
        });
        setAudios(data);
      }

      function stateaudio(e, index) {
        if (e.target.classList.contains("fa-play")) {
          e.target.classList.remove("fa-play");
          e.target.classList.add("fa-pause");
        } else if (e.target.classList.contains("fa-pause")) {
          e.target.classList.remove("fa-pause");
          e.target.classList.add("fa-play");
        }
    
        var myAudio = document.getElementById(`audioId${index}`);
        return myAudio.paused ? myAudio.play() : myAudio.pause();
    }

    useEffect(() => {
        dispatch(getSingleSchedule({ _id: id }));
        dispatch(searchStorageProducts({ search: '' }));
        dispatch(searchRequests({ search: '' }));
        dispatch(checkUnfinishedWork({ machine: selectedMachine }))

        setPlay(true);
    }, []);

    useEffect(() => {
        if (audios.length <= 0) {
          setAudios([audioURL]);
        } else {
          setAudios([...audios, audioURL]);
        }
      }, [audioURL]);

  return (
   <div className="production-schedule-insertion-wrapper tablet-view">
        <div className='top-heading-wrapper'>
            <img src={require('../../assets/icons/go-back.svg')} onClick={() => history.goBack()} />
            <h4>Преглед дейност</h4>
        </div>
    <div className='schedule-insertion-container'>
      <div className='schedule-insertion-settings'>
          <h2>Настройки на машината</h2>

          <div className='settings-inputs-wrapper'>
            <div className='settings-top-section-wrapper'>
                  <div className='buttons-wrapper'>
                    <button className='selected'>{state?.machine.name}</button>
                  </div>
            </div>
          </div>

          <div className='materials-section-wrapper'>
            <div className='materials-top-wrapper'>
              <h3>Материали</h3>
              <Input.Text
                value={state?.machineSettings?.materials?.length.toString()}
                style={{ fontWeight: 'bold' }}
                className='disabled-input small'
                disabled
               />
            </div>

            <div className='material-details-container'>
              {state?.machineSettings?.materials.map((el, i) => {
                return (
                  <div className='single-material-wrapper'>
                    <div className='material-details-top'>
                      <div className='material-input-wrapper'>
                        <div className='label-wrapper'>
                          <label>Материал {i + 1}</label>
                          <img src={require('../../assets/icons/remove-btn.svg')} />
                        </div>
                        <Input.Text
                          disabled
                          className='disabled-input'
                          value={el.name}
                        />
                      </div>

                      <div className='percentage-input-wrapper'>
                        <label>Съотношение</label>
                        <div className='percentage-input'>
                            <Input.Text
                                disabled
                                className='disabled-input'
                                placeholder={state?.machineSettings?.materials[i]?.ratio || ''}
                              />
                          <p>%</p>
                        </div>
                      </div>
                    </div>

                    <div className='material-details-bottom'>
                      <div className='storage-info'>
                        На склад:

                        {checkMaterialStorage(el.name)}

                      </div>
                      <div className='price-info'>
                        Ед. Цена:
                        {checkMaterialPrice(el.name)}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            {(state?.isMaterialChanged || state?.machineSettings?.note) &&
              <div className='changed-materials-section-wrapper'>
                <h3>Смяна на материали</h3>

                <div className='changed-materials-label-input-wrapper'>
                  <label>Причина за смяна на материали</label>
                  <Input.Text
                    style={{ fontWeight: 'bold' }}
                    value={state?.machineSettings?.note || ''}
                    disabled
                    className='disabled-input'
                  />
                </div>
              </div>}
          </div>

          <div className='size-solidity-weight-wrapper'>
            <h3>Геометрични размери, тегло и коравина</h3>

            <div className='size-solidity-weight-wrapper-inputs-wrapper'>
              <div className='size-solidity-weight-wrapper-input-wrapper'>
                <label>Вътрешен диаметър мм</label>

                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled
                  className='disabled-input'
                  value={state?.machineSettings?.geometry?.innerDiameter || ''}
                />
              </div>

              <div className='caliber-input-wrapper'>
                <label>Калибър мм</label>
                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled
                  className='disabled-input'
                  value={state?.machineSettings?.geometry?.caliber}
                />

              </div>

              <div className='label-input-wrapper'>
                <label>Тегло кг/м</label>
                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled
                  className='disabled-input'
                  value={state?.machineSettings?.geometry?.weight}
                />
              </div>

              <div className='solidity-input-wrapper'>
                <label>Коравина SN</label>

                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled
                  className='disabled-input'
                  value={state?.machineSettings?.geometry?.stiffnes}
                />
              </div>
            </div>
          </div>

          <div className='extruder-section-wrapper'>
            <div className='extruder-top-wrapper'>
              <h3>Екструдер за профил SJ90, t</h3>
                <Input.Text
                  value={(state?.machineSettings?.extruderSJ90?.profiles.length || 9).toString()}
                  style={{ fontWeight: 'bold' }}
                  className='disabled-input small'
                />
            </div>

            <div className='extruder-details-container'>
              {state?.machineSettings?.extruderSJ90?.profiles.map((el, i) => {
                return (
                  <div className='single-profile-wrapper'>
                    <div className='profile-top-wrapper'>
                      <label>{i + 1}</label>
                      <Input.Text
                        value={state?.machineSettings?.extruderSJ90?.profiles[i]}
                        disabled
                        className='disabled-input'
                      />
                    </div>
                  </div>
                )
              })}

            </div>

            <div className='engine-info-wrapper'>
              <div className='label-input-wrapper'>
                <label>Двигател ток А</label>
                <Input.Text
                  value={state?.machineSettings?.extruderSJ90?.motor?.current || ''}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Двигател оɗ/min</label>
                <Input.Text
                  value={state?.machineSettings?.extruderSJ90?.motor?.revolutions}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Вакуум</label>
                <Input.Text
                  value={state?.machineSettings?.geometry?.vacuum}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>ф 1 | ф2</label>
                <Input.Text
                  style={{ fontWeight: 'bold' }}
                  disabled
                  className='disabled-input'
                  value={state?.machineSettings?.geometry?.f1f2}
                />
              </div>

            </div>
          </div>

          <div className='extruder-section-wrapper'>
            <div className='extruder-top-wrapper'>
              <h3>Екструдер за заварка SJ65, t</h3>
               <Input.Text
                value={(state?.machineSettings?.extruderSJ65?.profiles.length || 9).toString()}
                style={{ fontWeight: 'bold' }}
                className='disabled-input small'
                />
            </div>

            <div className='extruder-details-container'>
              {state?.machineSettings?.extruderSJ65?.profiles.map((el, i) => {
                return (
                  <div className='single-profile-wrapper'>
                    <div className='profile-top-wrapper'>
                      <label>{i + 1}</label>
                      <Input.Text
                        value={state?.machineSettings?.extruderSJ65.profiles[i]}
                        disabled
                        className='disabled-input'
                      />
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='engine-info-wrapper'>
              <div className='label-input-wrapper'>
                <label>Двигател ток А</label>
                <Input.Text
                  value={state?.machineSettings?.extruderSJ65?.motor?.current || ''}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Двигател оɗ/min</label>
                <Input.Text
                  value={state?.machineSettings?.extruderSJ65?.motor?.revolutions}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Дюза</label>
                <Input.Text
                  value={state?.machineSettings?.injector}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Навиваща система честота Hz</label>
                <Input.Text
                  value={state?.machineSettings?.windingSystem}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Циркуляр за рязане - мин</label>
                <Input.Text
                  value={state?.machineSettings?.buzzSaw?.min}
                  disabled
                  className='disabled-input'
                />
              </div>

              <div className='label-input-wrapper'>
                <label>Циркуляр за рязане - ход</label>
                <Input.Text
                  value={state?.machineSettings?.buzzSaw?.move}
                  disabled
                  className='disabled-input'
                />
              </div>
            </div>

            <div className='comment-section-wrapper'>
              <div className='comment-label-input-wrapper'>
                <label>Коментар</label>
                {state?.comments?.filter(el => el.commentType === "audio")?.map((comment, index) => {
                  return (
                  <div className='recorded-wrapper'> 
                  <audio
                    id={`audioId${index}`}
                    onEnded={() => {
                      if (document.getElementById(`playAudio${index}`)?.classList.contains("fa-pause")) {
                        document.getElementById(`playAudio${index}`).classList.add("fa-play");
                        document.getElementById(`playAudio${index}`).classList.remove("fa-pause");
                      }
                    }}
                    src={comment.url}
                    controls
                    controlsList="nodownload"
                    type="audio/webm">
                    </audio>

                    {play && <div className='time-recorded-wrapper'>{moment(comment?.updatedAt).format('DD.MM.YYYY, HH:mm')}</div>}
               </div>)
                })}

                {audios.map((res, index) =>
                 index !== 0 ? res && 
                 <div className='recorded-wrapper'> 
                    <audio
                      id={`audioId${index}`}
                      onEnded={() => {
                        if (document.getElementById(`playAudio${index}`)?.classList.contains("fa-pause")) {
                          document.getElementById(`playAudio${index}`).classList.add("fa-play");
                          document.getElementById(`playAudio${index}`).classList.remove("fa-pause");
                        }
                      }}
                      src={res.data}
                      controls
                      controlsList="nodownload"
                      type="audio/webm">
                      </audio>

                      {play && <div className='time-recorded-wrapper'>{timeRecorded[index]}</div>}
                 </div> : null)}

        {record === true && <TimerController record={record} />}

      <div>
            {play ? 
            <button 
            className='audio-recorder initial'
            onTouchStart={() => {
                setTimeRecorded([...timeRecorded, moment().format('DD.MM.YYYY, HH:mm')]);
                setTimeout(() => Buttonstart(), 500);
            }}
            onMouseDown={() => {
                setTimeRecorded([...timeRecorded, moment().format('DD.MM.YYYY, HH:mm')]);
                setTimeout(() => Buttonstart(), 500);
            }}
            onBlur={() => setTimeout(() => Buttonstop(), 500)}
            onMouseUp={() => setTimeout(() => Buttonstop(), 500)}
            >
            {record === true ? 'Край' : 
            (play ? 'Гласово съобщение' : null)}
            </button> :

             <div className='buttons-wrapper-audio'>
             <button className='audio-bttn red'
                     onClick={() => {Deletedata(audios.length - 1); setPlay(true)}}>Изтрий</button>
             <button
             className='audio-bttn blue'
             onClick={() => {
              setPlay(true)
              dispatch(addCommentMachine({ commentType: 'audio', url: audios[audios.length - 1]?.data, machineScheduleWork: machineScheduleWork._id, machineSchedule: state._id }))
              }}>Изпрати</button>
           </div>}

      </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
