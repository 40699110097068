import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, groupBy } from "lodash"
import { Button, Input } from "@makedonski/admin-ui"
import { getRequests, showModal } from "../../actions"
import moment from "moment"
import CloseIcon from "@mui/icons-material/Close"
import { Button as MuiButton, Paper, InputBase, Menu, Box } from "@mui/material"
import { CustomRadio, StorageRawMaterialsList } from "../../components"
import "./styles.scss"

const StorageRawMaterials = ({ requests, showModal, getRequests }) => {
  const [selectedRequests, setSelectedRequests] = useState([])
  const [filter, setFilter] = useState({
    query: "",
    sortBy: "createdAt",
    period: false,
    startDate: moment().toDate(),
    endDate: moment().toDate(),
  })

  const searchQuery = useParams().search
  useEffect(() => {
    if (searchQuery) {
      setFilter({
        query: searchQuery,
        sortBy: "createdAt",
        period: false,
        startDate: moment().toDate(),
        endDate: moment().toDate(),
      })
    }
  }, [searchQuery])

  useEffect(() => {
    getRequests()
  }, [getRequests])

  const groups = groupBy(
    requests.filter((r) => r.status === "accrued"),
    (item) => item.material.trim()
  )
  const totalStorage = Object.keys(groups).map((group) => ({
    material: group,
    quantity: groups[group].reduce((a, c) => {
      const quantity = parseInt(c.quantity) || 0
      return a + quantity
    }, 0),
    price: groups[group].reduce((a, c) => {
      const price = parseFloat(c.price) || 0
      return a + price
    }, 0),
    criticalQuantity: groups[group].reduce((a, c) => a + c.criticalQuantity, 0),
  }))

  const [actionsAnchor, setActionsAnchor] = useState(null)
  const actionsAnchorOpen = Boolean(actionsAnchor)
  const handleActionsOpen = (event) => {
    setActionsAnchor(event.currentTarget)
  }
  const handleActionsClose = () => {
    setActionsAnchor(null)
  }

  return (
    <div className="raw-materials-container">
      <div className={"projects-header"}>
        <div className={"search-container"}>
          <Paper
            component={"form"}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation()
                    e.preventDefault()
                  }
                },
              }}
              value={filter.query}
              onChange={(e) => setFilter({ ...filter, query: e.target.value })}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Търси"
            />
          </Paper>
        </div>
        <div className={"datepicker-container"}>
          <Input.Datepicker
            className={`datepicker`}
            placeholder="Дата"
            pickDate={filter.startDate}
            onChange={(startDate) => setFilter({ ...filter, startDate })}
          />
          <Box sx={{ m: 1 }}>-</Box>
          <Input.Datepicker
            className={`datepicker`}
            placeholder="Дата"
            pickDate={filter.endDate}
            onChange={(endDate) => setFilter({ ...filter, endDate })}
          />
          <MuiButton
            onClick={() => setFilter({ ...filter, period: true })}
            variant="contained"
            sx={{
              padding: "6px 25px",
              backgroundColor: "#E6EEF4",
              "&:hover": {
                background: "#E6EEF4",
              },
              color: "black",
            }}
          >
            Търси
          </MuiButton>
        </div>
        <div style={{ display: "flex",alignItems:"center",height:"55px" }}>
          <Button.Raised
            className="btn-new"
            text="Нова заявка"
            style={{ marginRight: "10px" }}
            onClick={() => {
              showModal({
                type: "AddStorageRawMaterialsRequest",
                overflow: "visible",
                width: "800px",
              })
            }}
          />
          <MuiButton
            onClick={() => {
              setFilter({
                query: "",
                sortBy: "createdAt",
                period: false,
                startDate: moment().toDate(),
                endDate: moment().toDate(),
              })
            }}
            variant="contained"
            sx={{
              backgroundColor: "#26ACE2",
              height:"40px",
              "&:hover": {
                background: "#26ACE2",
              },
              color: "white",
            }}
          >
            Reset Filters
          </MuiButton>
          <MuiButton
            onClick={handleActionsOpen}
            variant="contained"
            sx={{
              backgroundColor: "#25E083",
               height:"40px",
              "&:hover": {
                background: "#25E083",
              },
              color: "white",
            }}
          >
            Действия
          </MuiButton>
          <Menu
            PaperProps={{ style: { padding: "15px" } }}
            anchorEl={actionsAnchor}
            open={actionsAnchorOpen}
            onClose={handleActionsClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseIcon
                fontSize="small"
                onClick={handleActionsClose}
                style={{ cursor: "pointer" }}
              />
            </div>
            <Button.Raised
              disabled={
                selectedRequests.length !== 1 ||
                selectedRequests.some((r) => r.status !== "new")
              }
              text="Поръчай"
              onClick={() => {
                showModal({
                  type: "AddStorageRawMaterialsOrder",
                  overflow: "visible",
                  width: "1100px",
                  data: selectedRequests,
                })
                setSelectedRequests([])
                handleActionsClose()
              }}
            />
            <Button.Raised
              disabled={
                selectedRequests.length < 1 ||
                selectedRequests.some((r) => r.status !== "delivered")
              }
              text="Заприходи"
              onClick={() => {
                showModal({
                  type: "StorageRawMaterialsFinishOrder",
                  overflow: "visible",
                  width: "800px",
                  data: selectedRequests,
                  // search: true
                })
                setSelectedRequests([])
                handleActionsClose()
              }}
            />
            <Button.Raised
              disabled={
                selectedRequests.length < 1 ||
                selectedRequests.some((r) => r.status !== "accrued")
              }
              text="Изпиши"
              onClick={() => {
                showModal({
                  type: "StorageRawMaterialsFinishOrder",
                  overflow: "visible",
                  width: "800px",
                  data: selectedRequests,
                })
                setSelectedRequests([])
                handleActionsClose()
              }}
            />
          </Menu>
        </div>
      </div>

      {/* <div className="raw-materials-header">
        <Button.Raised
          className="btn-new"
          text="Нова заявка"
          onClick={() => {
            showModal({
              type: "AddStorageRawMaterialsRequest",
              overflow: "visible",
              width: "800px",
            })
          }}
        />
        <Input.Text
          placeholder="Свободно търсене"
          value={filter.query}
          onChange={(e) => setFilter({ ...filter, query: e.target.value })}
        />
        <div className="period">
          <CustomRadio
            onClick={() => setFilter({ ...filter, period: !filter.period })}
            selected={filter.period}
          />
          <span>За период от:</span>
          <Input.Datepicker
            pickDate={filter.startDate}
            onChange={(startDate) => setFilter({ ...filter, startDate })}
          />
          <span>До:</span>
          <Input.Datepicker
            pickDate={filter.endDate}
            onChange={(endDate) => setFilter({ ...filter, endDate })}
          />
        </div>
        <div className="buttons">
          <Button.Raised
            disabled={
              selectedRequests.length !== 1 ||
              selectedRequests.some((r) => r.status !== "new")
            }
            clear
            className="btn-clear"
            text="Поръчай"
            onClick={() => {
              showModal({
                type: "AddStorageRawMaterialsOrder",
                overflow: "visible",
                width: "1100px",
                data: selectedRequests,
              })
              setSelectedRequests([])
            }}
          />
          <Button.Raised
            disabled={
              selectedRequests.length < 1 ||
              selectedRequests.some((r) => r.status !== "delivered")
            }
            clear
            className="btn-clear"
            text="Заприходи"
            onClick={() => {
              showModal({
                type: "StorageRawMaterialsFinishOrder",
                overflow: "visible",
                width: "800px",
                data: selectedRequests,
              })
              setSelectedRequests([])
            }}
          />
          <Button.Raised
            disabled={
              selectedRequests.length < 1 ||
              selectedRequests.some((r) => r.status !== "accrued")
            }
            clear
            className="btn-clear"
            text="Изпиши"
            onClick={() => {
              showModal({
                type: "StorageRawMaterialsFinishOrder",
                overflow: "visible",
                width: "800px",
                data: selectedRequests,
              })
              setSelectedRequests([])
            }}
          />
        </div>
      </div> */}
      {isEmpty(requests) ? (
        <div className="raw-materials-empty">
          <span>
            Все още няма създадени заявки и поръчки. Започнете като натиснете
            бутона “Нова заяка”
          </span>
        </div>
      ) : (
        <StorageRawMaterialsList
          selectedRequests={selectedRequests}
          setSelectedRequests={(newValue) => setSelectedRequests(newValue)}
          filter={filter}
          setSortBy={(value) => setFilter({ ...filter, sortBy: value })}
        />
      )}
      <div className="raw-materials-total">
        <span>Обща стойност на склада:</span>
        <span>
          {totalStorage
            .reduce((a, c) => {
              return a + c.price * c.quantity
            }, 0)
            .toLocaleString()}
          лв.
        </span>
        <Button.Icon
          name="info"
          size={26}
          color="#a2bfd5"
          onClick={() =>
            showModal({
              type: "StorageTotal",
              overflow: "visible",
              width: "800px",
              data: totalStorage,
            })
          }
        />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  requests: state.storage.requests,
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  getRequests: (payload) => dispatch(getRequests(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StorageRawMaterials)
