import React, { useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import {
  hideModal,
  accrueHPOrder,
  showLoading,
  hideLoading,
} from "../../../actions"
import {
  QuantityDropdownInput,
  SingleRadioButton,
  ReadyProductsAutocomplete,
} from "../../../components"
import Files from "../../../utilities/files"
import "./styles.scss"
import moment from "moment"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

function ReadyProductAccrualModal({
  hideModal,
  accrueHPOrder,
  data,
  showLoading,
  hideLoading,
}) {
  const [products, setProducts] = useState([])
  const [invoice, setInvoice] = useState(null)
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [issueDate, setIssueDate] = useState(moment().toDate())
  const [additionalFiles, setAdditionalFiles] = useState([])
  const [toExisting, setToExisting] = useState([])
  const [comment, setComment] = useState("")
  let fileInput = useRef()
  let moreFiles = useRef()

  useEffect(() => {
    if (data.length) {
      setProducts(
        data.map((el) => {
          return { ...el, name: el.product }
        })
      )
      setToExisting(data.map(() => false))
    }
  }, [data])
  return (
    <div className="ready-product-accrual-container">
      <div className="top-container">
        <h3>Заприхождаване</h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      {products.map((el, index) => {
        return (
          <div className="flex-container space-between border-bottom">
            <div className="input-element no-margin">
              <p>Заприхождаване към</p>
              <div className="flex-container">
                <SingleRadioButton
                  label="Като нова позиция"
                  selected={!toExisting[index]}
                  onClick={() => {
                    const newToExisting = toExisting.slice(0)
                    const newProducts = products.slice(0)
                    newToExisting[index] = false
                    newProducts[index].name = data[index].product
                    setToExisting(newToExisting)
                    setProducts(newProducts)
                  }}
                />
                <SingleRadioButton
                  label="Към съществуваща"
                  selected={toExisting[index]}
                  onClick={() => {
                    const newToExisting = toExisting.slice(0)
                    const newProducts = products.slice(0)
                    newToExisting[index] = true
                    newProducts[index].name = ""
                    setToExisting(newToExisting)
                    setProducts(newProducts)
                  }}
                />
              </div>
            </div>
            {toExisting[index] ? (
              <div className="input-element">
                <p>Продукт</p>
                <ReadyProductsAutocomplete
                  onChooseOrder={(order) => {
                    const newProducts = products.slice(0)
                    newProducts[index].to = order._id
                    newProducts[index].name = order.product
                    setProducts(newProducts)
                  }}
                />
              </div>
            ) : (
              <div className="input-element">
                <p>Продукт</p>
                <Input.Text disabled value={el.name} />
              </div>
            )}

            <div className="input-element">
              <p>Количество</p>
              <Input.Text disabled value={`${el.quantity}  ${el.units}`} />
            </div>
            <div className="input-element">
              <p>Доставено на</p>
              <Input.Datepicker
                disabled
                placeholder="Дата"
                pickDate={moment(el.deliveredAt).toDate()}
                // onChange={(date) => {
                //   console.log(date)
                // }}
              />
            </div>
          </div>
        )
      })}

      <div className="flex-container">
        <div className="input-element no-margin">
          <p>Фактура Номер</p>
          <Input.Text
            onChange={(event) => setInvoiceNumber(event.target.value)}
          />
        </div>
        <div className="input-element no-margin">
          <p>Фактура Издаване</p>
          <Input.Datepicker
            placeholder="Дата"
            pickDate={issueDate}
            onChange={(date) => {
              setIssueDate(date)
            }}
          />
        </div>
        <div className="input-element">
          <p>Добави файл:</p>
          <div className="flex-container">
            {!invoice ? (
              <>
                <input
                  type="file"
                  ref={(ref) => {
                    fileInput = ref
                  }}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    showLoading()
                    event.persist()
                    const name = event.target.files[0].name
                    Files.uploadFile(event.target.files[0])
                      .then((data) => {
                        setInvoice({ name: name, url: data.location })
                        hideLoading()
                      })
                      .catch((err) => {
                        hideLoading()
                      })
                  }}
                />
                <div
                  className="flex-container border-right"
                  onClick={() => fileInput && fileInput.click()}
                >
                  <p id="file">Фактура</p>
                  <div className="attach-icon" />
                </div>
              </>
            ) : (
              <div className="flex-container">
                <p id="file" onClick={() => window.open(invoice.url)}>
                  {invoice.name}
                </p>
                <div className="remove-icon" onClick={() => setInvoice(null)} />
              </div>
            )}
            <div
              className="flex-container"
              onClick={() => moreFiles && moreFiles.click()}
            >
              <input
                type="file"
                ref={(ref) => {
                  moreFiles = ref
                }}
                multiple
                style={{ display: "none" }}
                onChange={(event) => {
                  showLoading()
                  event.persist()
                  const nameArray = Object.keys(event.target.files).map(
                    (key) => {
                      return event.target.files[key].name
                    }
                  )
                  Files.uploadFiles(event.target.files)
                    .then((data) => {
                      let files = additionalFiles.slice(0)
                      data.forEach((el, index) => {
                        files.push({ name: nameArray[index], url: el.location })
                      })
                      setAdditionalFiles(files)
                      hideLoading()
                    })
                    .catch((err) => {
                      hideLoading()
                    })
                }}
              />
              <p id="file">Друг файл</p>
              <div className="attach-icon" />
            </div>
            <div className="flex-container scroll">
              {additionalFiles.map((file, index) => {
                return (
                  <div className="flex-container">
                    <p id="file" onClick={() => window.open(file.url)}>
                      {file.name}
                    </p>
                    <div
                      className="remove-icon"
                      onClick={() => {
                        let newAdditionalFiles = additionalFiles.slice(0)
                        newAdditionalFiles.splice(index, 1)
                        setAdditionalFiles(newAdditionalFiles)
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="input-element width50 no-margin">
        <p>Коментар</p>
        <Input.Text
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </div>
      <div className="flex-container flex-end">
        <Button.Raised
          text="Заприходи"
          onClick={() => {
            if (
              products.findIndex((product, index) => {
                if (toExisting[index]) {
                  return product.name === ""
                } else {
                  return false
                }
              }) === -1
            ) {
              const payload = products.map((product, index) => {
                if (toExisting[index]) {
                  return {
                    _id: product._id,
                    files: additionalFiles,
                    invoice: {
                      ...invoice,
                      invoiceNumber: invoiceNumber,
                      issueDate: issueDate,
                    },
                    to: product.to,
                    comment: comment,
                  }
                } else {
                  return {
                    _id: product._id,
                    files: additionalFiles,
                    invoice: {
                      ...invoice,
                      invoiceNumber: invoiceNumber,
                      issueDate: issueDate,
                    },
                    comment: comment,
                  }
                }
              })
              accrueHPOrder(payload)
            } else {
              Alerts.error("Грешка!", "Моля изберете продукт.")
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  accrueHPOrder: (payload) => dispatch(accrueHPOrder(payload)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadyProductAccrualModal)
