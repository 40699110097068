import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import {
  setModalWidth,
  hideModal,
  createProduct,
  showLoading,
  hideLoading,
} from "../../../actions"
import { PipeView } from "./PipeView"
import { KMUView } from "./KMUView"
import { MineView } from "./MineView"
import { LinearDrainerView } from "./LinearDrainerView"
import { ReservoirView } from "./ReservoirView"
import { FittingsView } from "./FittingsView"
import { OthersView } from "./OthersView"
import { RadioButton } from "../.."
import "./styles.scss"
import { checkBasicInfo } from "../../../utilities/helpers"

const linearDrainComponents = [
  {
    name: "Чугунена решетка",
    value: "",
    units: "",
    inStock: "",
    nameLock: true,
  },
  {
    name: "Фуния",
    value: "",
    units: "",
    inStock: "",
    nameLock: true,
  },
  {
    name: "Крачета",
    value: "",
    units: "",
    inStock: "",
    nameLock: true,
  },
  {
    name: "Метални колани",
    value: "",
    units: "",
    inStock: "",
    nameLock: true,
  },
]

const handleType = (
  type,
  types,
  productNames,
  setMainUnit,
  setBasicInfo,
  setImages,
  setClientDiagram,
  setProductionDiagram,
  onSetDiagram,
  onSetWarehouseInfo,
  setProductNames,
  loading,
  stopLoading,
  data
) => {
  const currentType = types.find((el) => el.value === type)
  if (!currentType) return null
  switch (currentType.label) {
    case "Тръба":
      return (
        <PipeView
          setMainUnit={setMainUnit}
          onBasicInfoSet={setBasicInfo}
          onSetImages={setImages}
          setClientDiagram={setClientDiagram}
          setProductionDiagram={setProductionDiagram}
          onSetWarehouseInfo={onSetWarehouseInfo}
          loading={loading}
          stopLoading={stopLoading}
          productNames={productNames}
          setProductNames={setProductNames}
          data={data}
        />
      )
    case "КМУ":
      return (
        <KMUView
          setMainUnit={setMainUnit}
          onBasicInfoSet={setBasicInfo}
          onSetImages={setImages}
          setClientDiagram={setClientDiagram}
          setProductionDiagram={setProductionDiagram}
          onSetWarehouseInfo={onSetWarehouseInfo}
          loading={loading}
          stopLoading={stopLoading}
          setProductNames={setProductNames}
          productNames={productNames}
          data={data}
          typeName="КМУ"
        />
      )
    case "Шахти":
      return (
        <MineView
          setMainUnit={setMainUnit}
          onBasicInfoSet={setBasicInfo}
          onSetImages={setImages}
          setClientDiagram={setClientDiagram}
          setProductionDiagram={setProductionDiagram}
          onSetWarehouseInfo={onSetWarehouseInfo}
          loading={loading}
          stopLoading={stopLoading}
          productNames={productNames}
          setProductNames={setProductNames}
          data={data}
        />
      )
    case "Линеен отводнител":
      return (
        <LinearDrainerView
          setMainUnit={setMainUnit}
          onBasicInfoSet={setBasicInfo}
          onSetImages={setImages}
          setClientDiagram={setClientDiagram}
          setProductionDiagram={setProductionDiagram}
          onSetWarehouseInfo={onSetWarehouseInfo}
          loading={loading}
          stopLoading={stopLoading}
          productNames={productNames}
          setProductNames={setProductNames}
          data={data}
        />
      )
    case "Резервоари":
      return (
        <ReservoirView
          setMainUnit={setMainUnit}
          onBasicInfoSet={setBasicInfo}
          onSetImages={setImages}
          setClientDiagram={setClientDiagram}
          setProductionDiagram={setProductionDiagram}
          onSetWarehouseInfo={onSetWarehouseInfo}
          loading={loading}
          stopLoading={stopLoading}
          productNames={productNames}
          setProductNames={setProductNames}
          data={data}
        />
      )
    case "Фитинги":
      return (
        <FittingsView
          setMainUnit={setMainUnit}
          onBasicInfoSet={setBasicInfo}
          onSetImages={setImages}
          setClientDiagram={setClientDiagram}
          setProductionDiagram={setProductionDiagram}
          onSetWarehouseInfo={onSetWarehouseInfo}
          loading={loading}
          stopLoading={stopLoading}
          productNames={productNames}
          setProductNames={setProductNames}
          data={data}
        />
      )
    case "Други":
      return (
        <OthersView
          setMainUnit={setMainUnit}
          onBasicInfoSet={setBasicInfo}
          onSetImages={setImages}
          setClientDiagram={setClientDiagram}
          setProductionDiagram={setProductionDiagram}
          onSetWarehouseInfo={onSetWarehouseInfo}
          loading={loading}
          stopLoading={stopLoading}
          productNames={productNames}
          setProductNames={setProductNames}
          data={data}
        />
      )
    default:
      return null
  }
}

const AddProductTypeModal = ({
  productTypes,
  setWidth,
  hide,
  providers,
  create,
  loading,
  stopLoading,
  data,
}) => {
  const [type, setType] = useState(null)
  const [typeName, setTypeName] = useState("")
  const [providerID, setProvider] = useState(null)
  const [basicInfo, setBasicInfo] = useState([])
  const [images, onSetImages] = useState([])
  const [productNames, setProductNames] = useState([])
  const [warehouseInfo, onSetWarehouseInfo] = useState([
    {
      name: "На склад",
      value: "1",
      units: "бр",
      isCritical: false,
    },
  ])
  const [clientDiagram, setClientDiagram] = useState([])
  const [productionDiagram, setProductionDiagram] = useState([])
  const [diagram, onSetDiagram] = useState([])
  const [ nomenclature, setNomenclature ] = useState('')
  const [mainUnit, setMainUnit] = useState(null)
  const [additionalElements, setAdditionalElements] = useState([
    {
      name: "",
      value: "",
      units: mainUnit,
      inStock: "",
    },
  ])

  React.useEffect(() => {
    if (data) {
      setType(data.productType._id)
      setTypeName(data.productType.name)
    }
  }, [data])

  React.useEffect(() => {
    const components = additionalElements.slice(0).map((el) => {
      const newOne = { ...el, units: mainUnit }
      return newOne
    })
    setAdditionalElements(components)
    //eslint-disable-next-line
  }, [mainUnit])

  React.useEffect(() => {
    if (data && nomenclature !== '') {
      setNomenclature(data?.nomenclature)
    }
  }, [nomenclature])

  React.useEffect(() => {
    if (typeName === "Линеен отводнител") {
      setAdditionalElements(
        linearDrainComponents.map((el) => {
          return { ...el, units: mainUnit }
        })
      )
    } else {
      setAdditionalElements([
        {
          name: "",
          value: "",
          units: mainUnit,
          inStock: "",
        },
      ])
    }
    //eslint-disable-next-line
  }, [typeName])

  const types =
    productTypes &&
    productTypes.map((product) => ({ label: product.name, value: product._id }))

  const nomenclatureList = [ 'Mini', 'Pro', 'Bypass', 'Duo' ]

  return (
    <div className="add-product-type-modal-container">
      <div className="flex-container space-between">
        <h1>Добавяне на продукт</h1>
        <div className="exit-btn" onClick={() => hide()} />
      </div>
      <div className="flex-wrapper">
        <div
          className={`add-product-type-modal-inner-container ${
            type === "Тръба" || type === null ? "" : "max-width"
          }`}
        >
          <div className="form-control">
            <div className="radio-group-horizontal">
              {providers.map((provider) => {
                return (
                  <RadioButton
                    changed={() => {
                      setProvider(provider.id)
                    }}
                    key={provider._id}
                    id={provider._id}
                    isSelected={providerID === provider._id}
                    label={provider.name}
                    value={provider._id}
                  />
                )
              })}
            </div>
          </div>
          <div className="flex-container space-between">
            <div className="input-element">
              <p>Вид продукт</p>
              <Input.Dropdown
                disabled={data}
                placeholder="Изберете вид продукт"
                value={type}
                options={types}
                onChange={({ label, value }) => {
                  setType(value)
                  setTypeName(label)
                  setWidth("1200px")
                  setMainUnit(null)
                }}
              />
            </div>
            {type && (
              <div className="input-element dropdown">
                <p>Номенклатура</p>
                {/* <p>--</p> */}
                {typeName === 'КМУ' ?
                <Input.Dropdown
                placeholder=""
                value={nomenclature || ''}
                options={nomenclatureList}
                onChange={(e) => {
                  setNomenclature(e.value)
                  setWidth("1200px")
                }}
              /> :
              <p>--</p>
                }
              </div>
            )}
          </div>
          {handleType(
            type,
            types,
            productNames,
            setMainUnit,
            setBasicInfo,
            onSetImages,
            setClientDiagram,
            setProductionDiagram,
            onSetDiagram,
            onSetWarehouseInfo,
            setProductNames,
            loading,
            stopLoading,
            data
          )}
        </div>
        {type ? (
          <Button.Raised
            text="Добави"
            disabled={!(mainUnit === null ? false : true) || !(typeName === 'КМУ' ? checkBasicInfo(basicInfo) : true)}
            // disabled={!(mainUnit === null ? false : true) || !(typeName === 'КМУ' ? (basicInfo.length === 11) : true)}
            onClick={() => {
              const details = {
                productType: type,
                provider: providerID,
                mainUnit,
                basicInfo,
                images,
                schematicsClient: clientDiagram,
                schematicsProduction: productionDiagram,
                warehouseInfo,
                components: additionalElements,
                friendlyNames: productNames,
              }
              if (typeName === "Тръба") {
                details.components = []
              }
              if (typeName === "КМУ") {
                details.basicInfo.unshift({
                   type: 'option',
                   value: nomenclature,
                   name: 'Номенклатура',
                   units: '',
                })
              }
              create(details)
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  productTypes: state.products.types,
  providers: state.providers.data,
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  setWidth: (payload) => dispatch(setModalWidth(payload)),
  hide: () => dispatch(hideModal()),
  create: (payload) => dispatch(createProduct(payload)),
  loading: (payload) => dispatch(showLoading(payload)),
  stopLoading: () => dispatch(hideLoading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProductTypeModal)
