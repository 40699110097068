import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "@makedonski/admin-ui"
import moment from "moment"
import { hideModal } from "../../../actions"
import "./styles.scss"

const ProductionHistoryModal = ({ hide, data }) => {
  const [historyType, setHistoryType] = useState("Движение")

  const renderMap = {
    "weight": "Тегло",
    "length": "Дължина",
    "quantity": "Количество"
  }

  const checkRenderValues = (value) => {
    if (value !== "defective" && value !== "produce" && value !== "machineSchedule" && value !== "machineScheduleWork")
    return true

    else {
      return false
    }
  }
  
  return (
    <div className="production-history-modal-container">
      <div className="top-container">
        <h3>История на продукция</h3>
        <div className="close-btn" onClick={hide} />
      </div>
      <Button.UnderlinedTab
        buttons={["Движение", "Цени"]}
        onChange={(name) => setHistoryType(name)}
      />
      {data.history.length === 0 && <h3>Няма история на продукта</h3>}
      <div className="scroll-container">
        {data.history
          .filter((el) => {
            if (historyType === "Движение") {
              return el.type === "movement"
            } else {
              return el.type === "price"
            }
          })
          .map((obj) => {
            return (
              <div className={`history-element ${obj?.data?.produce ? 'produce' : ''}`}>
                <div className="blue-dot" />
                <p id="grey">{moment(obj.createdAt).format("DD.MM.YYYY")}г.</p>
                <p id="title">{obj.title}</p>
                {Object.keys(obj.data).map((key) => {
                  return (
                    <div className="flex-container">
                      {renderMap[key] && <p>{renderMap[key]}:</p>}
                      {(!renderMap[key] && checkRenderValues(key)) && <p>{key}:</p>}

                      {/* <p>{key}:</p> */}
                      {(checkRenderValues(key) && obj.title !== 'Добавена продукция' && obj.title !== 'Бракувана продукция' ) &&
                      <p id="bold">
                        {key === "Файл" ? obj.data[key].name : obj.data[key]}
                      </p>}

                      {(obj.title === 'Добавена продукция' || obj.title === 'Бракувана продукция') && key === 'weight' && <p id="bold">{`${obj.data[key]} кг`}</p>}
                      {(obj.title === 'Добавена продукция' || obj.title === 'Бракувана продукция') && key === 'length' && <p id="bold">{`${obj.data[key]} м`}</p>}
                      {/* {(obj.title === 'Изписана продукция') && key === 'quantity' && <p id="bold">{`${obj.data[key]} бр`}</p>} */}
                    </div>
                  )
                })}
              </div>
            )
          })}
      </div>
      {/* {data.history.length === 0 && <div className="empty-container"/>} */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionHistoryModal)
