import React, { useState } from "react"
import { connect } from "react-redux"
import { getReferences } from "../../actions"
import _ from "lodash"
import "./styles.scss"

const onSearchInit = (event, getReferences) => {
  event.persist()
  onSearchDelayed(event, getReferences)
}

const onSearch = (event, getReferences) => {
  getReferences({ name: event.target.value })
}

const onSearchDelayed = _.debounce(
  (event, getReferences) => onSearch(event, getReferences),
  300
)

const ClientAutocomplete = ({
  clientEdit,
  getReferences,
  searchClients,
  onChooseClient,
  onChange,
}) => {
  const [filter, setFilter] = useState(clientEdit ? clientEdit : "")
  const [focus, setFocus] = useState(false)
  return (
    <div className="client-autocomplete-container">
      <input
        type="text"
        placeholder="Клиент"
        value={filter}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setTimeout(() => {
            setFocus(false)
          }, 150)
        }}
        onChange={(event) => {
          setFilter(event.target.value)
          onSearchInit(event, getReferences)
          onChange(event)
        }}
      />
      {focus && searchClients.length > 0 && (
        <div className="clients-dropdown">
          {searchClients.map((client) => {
            return (
              <div
                className="client-option"
                onClick={() => {
                  setFilter(client.name)
                  onChooseClient(client)
                }}
              >
                {client.name}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  searchClients: state.references.searchClients,
})

const mapDispatchToProps = (dispatch) => ({
  getReferences: (payload) => dispatch(getReferences(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientAutocomplete)
