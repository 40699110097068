import React from 'react';
import './styles.scss';
import { SingleTask } from '../SingleTask';
import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { showModal, hideModal } from '../../../actions';
import { useDispatch } from 'react-redux';
import { emit } from '../../../utilities/helpers';
import { socket } from '../../../actions';

export default function Category({ category, settings, index }) {
  const dispatch = useDispatch()
    const [ isSettingsActive, setIsSettingsActive ] = useState(false)

    const handleEdit = () => {
      dispatch(showModal({
        type: "editModal",
        data: { 
          title: category?.title || "" ,
          marginTop: "20%",
          onEdit: (title) => {
          emit(socket, { action: 'task/updateCategory', payload: { categoryId: category?._id, title: title } })
          emit(socket, { action: 'task/getTasks', payload: { categoryId: category?._id } })
          dispatch(hideModal())
          setIsSettingsActive(false)
        }},
        // hide: () => dispatch(hideModal()),,
        width: "45%",
      }))
    }

    const handleDelete = () => {
      dispatch(showModal({
        width: "45%",
        type: "deleteModal",
        data: {
          marginTop: "15%",
          onDelete: () => {
            emit(socket, { action: 'task/deleteCategory', payload: { categoryId: category?._id } })
          dispatch(hideModal())
        }},
      }))
    
      // emit(socket, { action: 'task/deleteCategory', payload: { categoryId: id } })
      // emit(socket, { action: 'task/getTasks' })
      // setIsSettingsActive(false)
    }

  return (
    <Draggable draggableId={category?._id} index={index} key={category?._id}>
      {(provided) => <div  ref={provided.innerRef} {...provided.draggableProps} aria-label={`${category?._id} quote list`} className="task-management-column-container card">
        <div className={`category-container ${(Object.keys(category)?.length && !category?._tasks?.length) ? 'empty-state' : ''}`} {...provided.dragHandleProps} key={category?._id}>
          {category?._tasks?.length ?
          <div className='category-inner-container' {...provided.dragHandleProps}>
              <div className="category-top-wrapper">
                <h2>{category?.title}</h2>
        
                {settings &&
                <div className="category-settings-menu">
                  <div className='settings-wrapper'>
                      <div className="img-wrapper">
                        <img
                          src={require('../../../assets/icons/plus-icon-blue.svg')}
                          // onClick={() => history.push(`/task/new/${category._id}`)}
                          onClick={() => window.open(`/task/new/${category._id}`)}
                        ></img>
                      </div>
                      <div className="img-wrapper">
                        <img
                          className='small'
                          src={require('../../../assets/icons/settings.svg')}
                          onClick={() => setIsSettingsActive(!isSettingsActive)}
                        ></img>
                      </div>
                  </div>
        
                  {isSettingsActive && (
                    <div className="category-menu">
                      <div className="category-menu-top-wrapper">
                        <h5>Моля изберете</h5>
                        <img
                          src={require("../../../assets/icons/closeIcon.svg")}
                          className="close-icon"
                          onClick={() => setIsSettingsActive(!isSettingsActive)}
                        ></img>
                      </div>
        
                      <ul className="category-nav">
                        <li onClick={handleEdit}>
                          Редактирай име 
                          <img src={require('../../../assets/icons/pen-icon.svg')}></img>
                        </li>
                        <li onClick={handleDelete} className="last">
                          Изтрий колона <img src={require('../../../assets/icons/remove-btn.svg')}></img>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>}
              </div>
              
              <Droppable droppableId={category?._id} type="TASKS" className="scroll-wrapper">
                {(dropProvided) => <div {...dropProvided.droppableProps} className="category-tasks-wrapper">
                  <div className="task-management-tasks-list-drop-zone" ref={dropProvided.innerRef}>
                    {category?._tasks?.map((task, i) => <Draggable key={task?._id} draggableId={task?._id} index={i}>
                      {(dragProvided) => <div
                        className="task-management-task-outer-container" ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps}>
                        <SingleTask task={task} key={task?._id} index={task?.orderIndex} />
                      </div>}
                    </Draggable>)}
                    {dropProvided.placeholder}
                  </div>
                </div>}
              </Droppable>
          </div> :
          <>
          <div className='category-top-wrapper'>
            <h2>{category?.title}</h2>
    
            {settings &&
                <div className="category-settings-menu">
                  <div className='settings-wrapper'>
                      <div className="img-wrapper">
                        <img
                          src={require('../../../assets/icons/plus-icon-blue.svg')}
                          // onClick={() => history.push(`/task/new/${category._id}`)}
                          onClick={() => window.open(`/task/new/${category._id}`)}
                        ></img>
                      </div>
                      <div className="img-wrapper">
                        <img
                          className='small'
                          src={require('../../../assets/icons/settings.svg')}
                          onClick={() => setIsSettingsActive(!isSettingsActive)}
                        ></img>
                      </div>
                  </div>
        
                  {isSettingsActive && (
                    <div className="category-menu">
                      <div className="category-menu-top-wrapper">
                        <h5>Моля изберете</h5>
                        <img
                          src={require("../../../assets/icons/closeIcon.svg")}
                          className="close-icon"
                          onClick={() => setIsSettingsActive(!isSettingsActive)}
                        ></img>
                      </div>
        
                      <ul className="category-nav">
                        <li onClick={handleEdit}>
                          Редактирай име 
                          <img src={require('../../../assets/icons/pen-icon.svg')}></img>
                        </li>
                        <li onClick={handleDelete} className="last">
                          Изтрий колона <img src={require('../../../assets/icons/remove-btn.svg')}></img>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>}
          </div>

          <Droppable droppableId={category?._id} type="TASKS">
            {(dropProvided) => <div {...dropProvided.droppableProps} className='main-content-wrapper'>
              <div className='main-content empty-state' ref={dropProvided.innerRef}>
              <div className='text'>{`Няма налични ${category?.title} задачи`}</div>
              <img src={require('../../../assets/images/settings-empty-state.png')} />
              </div>
            </div>}
          </Droppable>
    
          {/* <div className='main-content empty-state'>
            <div className='text'>{`Няма налични ${category?.title} задачи`}</div>
            <img src={require('../../../assets/images/settings-empty-state.png')} />
          </div> */}
          </>}
        </div>
      </div>}
    </Draggable>
  )
}
