import React from 'react';
import './styles.scss';
import { history } from "../../../config/stores"
import { Button } from "@makedonski/admin-ui";
import { useSelector, useDispatch } from 'react-redux';
import { hideModal, endWorkMachineSchedule, getUnfinishedWorks } from '../../../actions';

export default function EndMachineActivityModal() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.modal.data);

  return (
    <div className='add-machine-activity-modal-wrapper end'>
      <div className='headings-wrapper'>
        <h1>Край на задача</h1>
        <h2>Сигурни ли сте, че искате да завършите тази задача?</h2>
      </div>

      <div className='buttons-wrapper'>
        <Button.Raised text="Завърши задача" className="red" 
        onClick={() => {
          // dispatch(endWorkMachineSchedule({ 
          //   machineSchedule: data.machineSchedule,
          //   machineScheduleWork: data.machineScheduleWork}))
          // dispatch(hideModal())

        async function endWork() {
          return Promise.resolve(dispatch(endWorkMachineSchedule({ machineSchedule: data.machineSchedule, machineScheduleWork: data.machineScheduleWork})));
          // return Promise.resolve(dispatch(getUnfinishedWorks({ machine: localStorage.getItem('machine') })));
        }
        async function loadPage() {
          return Promise.resolve(dispatch(getUnfinishedWorks({ machine: localStorage.getItem('machine') })));
        }
        
        endWork().then(loadPage()).then(history.push('/machine-requests-view'));

          
        }
        }></Button.Raised>
        <Button.Raised text="Откажи" className="white" onClick={() => dispatch(hideModal())}></Button.Raised>
      </div>

    </div>
  )
}
