import React from "react"
import { Icon } from "@makedonski/admin-ui"
import "./styles.scss"

export default ({ name, url, onRemove }) => {
  return (
    <div className="file-item-component">
      <Icon name="chat---add-file" color="#26ACE2" size="13px" />
      <a href={url} target="_blank" rel="noopener noreferrer">
        {name}
      </a>
      <div onClick={onRemove} className="remove-button">
        <Icon name="plus" color="#FF7590" size="13px" />
      </div>
    </div>
  )
}
