import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { OfferProduct } from "../OfferCreationModal/OffectProduct"
import {
  hideModal,
  getProductTypes,
  searchProduct,
  createInvoice,
  createInvoiceEn,
  searchOfferClients,
  resetResults,
  editInvoice,
  editInvoiceEn,
  clearResults,
  deleteInvoice,
  getAvailableProducts,
  sellProduct,
  sellProductHP,
  getHPRequests
} from "../../../actions"
import { ClientAutocomplete, RadioButton } from "../../../components"
import moment from "moment"
import "./styles.scss"
import { isNaN } from "lodash"

const getProductValue = (product) => {
  if (product.singleUnitPrice) {
    return Number(product.singleUnitPrice) || 0
  } else if (product.expenses) {
    const materialCost = product.expenses.material.reduce((acc, val) => {
      return acc + val.total || 0
    }, 0)
    const staffCost =
      product.expenses.staff &&
      product.expenses.staff.reduce((acc, val) => {
        return acc + val.total
      }, 0)
    const otherCost =
      product.expenses.other &&
      product.expenses.other.reduce((acc, val) => {
        return acc + val.total
      }, 0)
    const customCost =
      product.expenses.custom &&
      product.expenses.custom.reduce((acc, val) => {
        return (
          acc +
          val.elements.reduce((acc, val) => {
            return acc + val.total
          }, 0)
        )
      }, 0)
    return (
      (Number(materialCost) +
        Number(staffCost) +
        Number(otherCost) +
        Number(customCost)) /
      100
    )
  } else {
    return 0
  }
}

function InvoiceCreationModal({
  hideModal,
  data,
  productTypes,
  createInvoice,
  createInvoiceEn,
  sellProduct,
  sellProductHP,
  searchOfferClients,
  clientResults,
  resetResults,
  editInvoice,
  editInvoiceEn,
  deleteInvoice,
  availableProducts,
  getAvailableProducts,
  getHPRequests,
  HPProducts
}) {
  const [chosenProducts, setChosenProducts] = useState([])
  const [storageChosenProduct, setStorageChosenProduct] = useState({})
  const [productsListHP, setProductsListHP] = useState([])
  const [currentProduct, setCurrentProduct] = useState({})
  const [bankAccount, setBankAccount] = useState("")
  const [language, setLanguage] = useState("bg")
  const [clientName, setClientName] = useState("")
  const [uic, setUIC] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [attentionOf, setAttentionOf] = useState("")
  const [reason, setReason] = useState("")
  const [productsStorage, setProductsStorage] = useState("")
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [ searchValue, setSearchValue ] = useState('');
  const [ storageType, setStorageType ] = useState('warehouse');
  const [currency, setCurrency] = useState('BGN')
  const [isCurrencyChanged, setIsCurrencyChanged] = useState(false)

  const currencyMap = { 'Лева': 'BGN', 'Евро': 'EUR' }
  const currencyDropdownMap = { 'BGN': 'Лева', 'EUR': 'Евро' }
  

  const isValid = () => {
    if (!chosenProducts?.length) return false

    let productsForCheck = JSON.parse(JSON.stringify(chosenProducts))
    productsForCheck = productsForCheck.filter(p => p?.warehouse?.quantity > 0 ? true : false)

    if (productsForCheck?.length !== chosenProducts?.length) return false
    else return true
  }

  useEffect(() => {
    if (data.forEdit) {
      const products = data.invoice.products.map((product) => {
        const found = data.includedProducts.find(
          (el) => el._id === product.item._id
        )

        if (data?.invoice?.currency === 'EUR') {
          return {
            ...found,
            singleUnitPrice: (product.singleUnitPrice / 1.95583).toFixed(2) * 100,
            // singleUnitPrice: (product.singleUnitPrice / 1.95583).toFixed(2),
            deliveryDate: product.deliveryDate,
            quantity: product.quantity,
          }
        }
        return {
          ...found,
          singleUnitPrice: product.singleUnitPrice,
          deliveryDate: product.deliveryDate,
          quantity: product.quantity,
        }
      })
      setChosenProducts(products)
      setBankAccount(data.invoice.bankData.bankAccount)
      setClientName(data.invoice.client.name)
      setUIC(data.invoice.client.uic)
      setPhone(data.invoice.client.phone)
      setEmail(data.invoice.client.email)
      setAddress(data.invoice.client.address)
      setReason(data.invoice.reason)
      setCurrency(data.invoice.currency)
    }
  }, [data])

  useEffect(() => {
    if (!data.forEdit && data.includedProducts.length) {
      const readyProducts = data.includedProducts.map((prod) => {
        const quantity = prod.quantity ? prod.quantity : 1
        const date = prod.deliveryDate ? prod.deliveryDate : moment().toDate()
        return { ...prod, quantity: quantity, deliveryDate: date }
      })
      setChosenProducts(readyProducts)
    }
  }, [data])

  useEffect(() => {
    getAvailableProducts()
    getHPRequests()
  }, [])

  useEffect(() => {
    let filteredProducts = availableProducts.slice()
    filteredProducts = filteredProducts.filter(product => product.inquiry._id === data?._id)

    setProductsStorage(filteredProducts)
  }, [availableProducts])


  const productsTotal =
    chosenProducts.length &&
    chosenProducts.reduce((acc, val) => {
      return acc + getProductValue(val)
    }, 0)

  let totalPrice =
    chosenProducts.length &&
    chosenProducts.reduce((acc, val) => {
      return acc + getProductValue(val) * val.quantity
    }, 0)
  const initialProductsWithoutQuantity =
    chosenProducts.length &&
    data.includedProducts.reduce((acc, val) => {
      const chosenProduct = chosenProducts.find((prod) => {
        if (prod.item) {
          return prod.item._id === val._id
        } else {
          return prod._id === val._id
        }
      })
      if (!chosenProduct) {
        return acc + 0
      } else {
        return acc + getProductValue(val)
      }
    }, 0)

    useEffect(() => {
      if (!chosenProducts?.length) return
      const newChosenProducts = chosenProducts.slice(0)
      newChosenProducts.map(product => product.singleUnitPrice = 0)
      setChosenProducts(newChosenProducts)

      totalPrice = 0
    }, [isCurrencyChanged])

    console.log('chosenProducts', chosenProducts)

  return (
    <div className="invoice-creation-modal-container">
      <div className="left-container">
        <h3>Създаване на фактура</h3>

        {/* <div className="flex-container space-between">
          <div className="input-container">
            <p>Език</p>
            <Input.Dropdown
              placeholder="Моля изберете"
              value={language === "en" ? "Английски" : "Български"}
              options={["Български", "Английски"]}
              onChange={(value) => value.value === "Английски" ? setLanguage("en") : setLanguage("bg")}
            />
          </div>
        </div> */}

        <div className="flex-container space-between">
          <div className="input-container">
            <p>Клиент</p>
            <ClientAutocomplete
              onChooseClient={(data) => {
                setClientName(data.name)
                setAttentionOf(data.name)
                if (data.uic) {
                  setUIC(data.uic)
                } else {
                  setUIC("")
                }
                if (data.phone) {
                  setPhone(data.phone)
                } else {
                  setPhone("")
                }
                if (data.email) {
                  setEmail(data.email)
                } else {
                  setEmail("")
                }
                if (data.address) {
                  setAddress(data.address)
                } else {
                  setAddress("")
                }
              }}
              onChange={(event) => setClientName(event.target.value)}
            />
            {/* <div className="input-wrapper">
              <Input.Text
                value={clientName}
                onChange={(event) => {
                  onChange(event, searchOfferClients)
                  setClientName(event.target.value)
                }}
              />
              {clientResults.length > 0 && (
                <div className="client-results-dropdown">
                  {clientResults.map((client) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setAddress(client.address)
                          setAttentionOf(client.attentionOf)
                          setEmail(client.email)
                          setClientName(client.name)
                          setPhone(client.phone)
                          setUIC(client.uic)
                          resetResults()
                        }}
                      >
                        {client.name}
                      </div>
                    )
                  })}
                </div>
              )}
            </div> */}
          </div>
          <div className="input-container">
            <p>ЕИК</p>
            <Input.Text
              value={uic}
              onChange={(event) => setUIC(event.target.value)}
            />
          </div>
          <div className="input-container">
            <p>Телефон</p>
            <Input.Text
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container space-between">
          <div className="input-container">
            <p>Имейл</p>
            <Input.Text
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="input-container large">
            <p>Адрес</p>
            <Input.Text
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <p>Основание</p>
        <Input.Text
          value={reason}
          onChange={(event) => setReason(event.target.value)}
        />
        <div className="flex-container flex-start border-bottom">
          <div className="input-container">
            <p>Ваша банкова сметка</p>
            <Input.Dropdown
              placeholder="Моля изберете"
              value={bankAccount}
              options={["ДСК", "УниКредит", "Райфайзен"]}
              onChange={(value) => setBankAccount(value.value)}
            />
          </div>

          <div className='input-container'>
            <p>Валута</p>
            <Input.Dropdown
              placeholder='Моля, изберете'
              value={currencyDropdownMap?.[currency]}
              options={['Лева', 'Евро']}
              onChange={(value) => {
                setCurrency(currencyMap?.[value.value])
                setIsCurrencyChanged(!isCurrencyChanged)
              }}
            />
          </div>
        </div>
        {/* <div className="input-container smaller">
          <p>Вид продукт</p>
          <Input.Dropdown
            placeholder="Моля изберете"
            value={productType}
            options={productTypes.map((type) => type.name)}
            onChange={(value) => {
              setProductResults([])
              setProductType(value.value)
            }}
          />
        </div>
        <div className="flex-container center relative">
          <p
            onClick={() => {
              if (!productType) {
                Alerts.error("Грешка", "Моля изберете тип продукт!")
              } else {
                let type = productTypes.find(
                  (type) => type.name === productType
                )
                searchProduct({
                  productType: type._id,
                  basicInfo: [],
                })
              }
            }}
          >
            Добави към офертa
          </p>
          <div className="arrow-right" />
          {productResults.filter((el) => el.productType.name === productType)
            .length > 0 && (
            <div className="search-results-popup">
              <div className="title-container">
                <h4>Изберете продукт</h4>
                <div
                  className="close-popup-btn"
                  onClick={() => setProductResults([])}
                />
              </div>
              {productResults
                .filter((el) => el.productType.name === productType)
                .map((product) => {
                  let infoString
                  if (product.productType.name === "Тръба") {
                    const typeOfDiameter = product.basicInfo.find(
                      (el) => el.name === "Произвежда се по"
                    )
                    const SN = product.basicInfo.find(
                      (el) => el.name === "Коравина"
                    )
                    if (typeOfDiameter.value === "Вътрешен диаметър") {
                      const diameter = product.basicInfo.find(
                        (el) => el.name === "Вътрешен диаметър"
                      )
                      infoString = `SN${SN.value}_ID${diameter.value}`
                    } else {
                      const diameter = product.basicInfo.find(
                        (el) => el.name === "Външен диаметър"
                      )
                      infoString = `SN${SN.value}_ID${diameter.value}`
                    }
                  } else {
                    infoString = product.basicInfo
                      .map((el) => {
                        return el.value
                      })
                      .join("_")
                  }
                  return (
                    <div className="product-container">
                      <div
                        className="product-name"
                        onClick={() => setProductToAdd(product)}
                      >
                        <p id="large">
                          {product.productType.name === "Тръба"
                            ? infoString
                            : product.productType.name}
                        </p>
                        {product.productType.name !== "Тръба" && (
                          <p id="small">{infoString}</p>
                        )}
                      </div>
                      <SingleRadioButton
                        selected={productToAdd._id === product._id}
                        onClick={() => setProductToAdd(product)}
                      />
                    </div>
                  )
                })}
              <Button.Raised
                text="Добави"
                onClick={() => {
                  if (
                    chosenProducts.findIndex(
                      (el) => el._id === productToAdd._id
                    ) === -1
                  ) {
                    let newChosenProducts = chosenProducts.slice(0)
                    newChosenProducts.push({
                      ...productToAdd,
                      deliveryDate: moment().toDate(),
                      quantity: 1,
                    })
                    setProductResults([])
                    setChosenProducts(newChosenProducts)
                    clearResults()
                  }
                }}
              />
            </div>
          )}
        </div> */}
      </div>
      <div className="right-container">
        <div className="flex-container relative space-between">
          <div className="flex-container gap no-margin">
            <h3 className="no-margin">Продукти към фактура</h3>
            {/* <div className="plus-icon" onClick={() => setIsPopupOpen(!isPopupOpen)}  /> */}
          </div>
          <div className="close-btn" onClick={() => hideModal()} />

          <div className={`products-storage-popup ${isPopupOpen ? 'open' : 'closed'}`}>

              <div className="flex-container space-between no-margin align-start">
                <div className="flex col">
                  <h3 className="title-container">Наличност</h3>
                  <h3 className="title-container small">{storageType === 'warehouse' ? 'Готова продукция' : storageType === 'hp' ? 'ХидроПро' : ''}</h3>
                </div>
            
                 <div className="close-popup-btn" onClick={() => setIsPopupOpen(false)} />
              </div>

              <div className='products-search-wrapper'>
                 <div className='input-wrapper'>
                   <img src={require("../../../assets/icons/searchIcon.svg")} />
                   <input type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value.toLocaleLowerCase())} />
                 </div>
              </div>

              <div className="products-list-wrapper">
                {storageType === 'warehouse' ?
                (availableProducts?.length &&
                availableProducts
                ?.filter(el => searchValue ? (el?.type?.toLowerCase()?.includes(searchValue) || el?.variation?.toLowerCase()?.includes(searchValue)) : true)
                ?.map(productData => {
                  return (
                    <div className="product-row">
                      <div className="table-cell">
                        <p className="product-list-item-title">{productData?.type}</p>
                        <p className="product-list-item-variation">{productData?.variation}</p>
                        <p className="product-list-item-title">{`${productData?.quantity} ${productData?.units}`}</p>
                      </div>
                      <div className="table-cell">
                      {productData?.quantity > 0 &&
                      <RadioButton
                       onClick={() => {
                        setStorageChosenProduct({ warehouseId: productData?._id, quantity: productData?.quantity, type: 'warehouse' })
                        let newChosenProducts = chosenProducts.slice()

                        let productsIds = chosenProducts.slice()
                        productsIds = chosenProducts?.length > 0 ? productsIds.map(prod => prod._id) : []

                        let chosenProduct = JSON.parse(JSON.stringify(currentProduct))
                        chosenProduct.warehouse = { _id: productData?._id, quantity: productData?.quantity, type: 'warehouse' }
                        chosenProduct.storageQuantity = productData?.quantity || 0

                        let index = chosenProducts.findIndex(el => el?._id === currentProduct?._id)
                        newChosenProducts.splice(index, 1, chosenProduct)
                        setChosenProducts(newChosenProducts)
                       }}
                       key={productData?._id}
                       id={productData?._id}
                       isSelected={storageChosenProduct?.warehouseId === productData?._id}
                       value={productData}
                     />}
                      </div>
                    </div>
                  )
                })) : 
                (HPProducts?.length &&
                  HPProducts
                  ?.filter(el => searchValue ? (el?.product?.toLowerCase()?.includes(searchValue)) : true)
                  ?.map(productData => {
                    return (
                      <div className="product-row">
                        <div className="table-cell">
                          <p className="product-list-item-variation big">{productData?.product}</p>
                          <p className="product-list-item-title">{`${productData?.quantity} ${productData?.units}`}</p>
                        </div>
                        <div className="table-cell">
                        {productData?.quantity > 0 &&
                        <RadioButton
                         onClick={() => {
                          setStorageChosenProduct({ warehouseId: productData?._id, quantity: productData?.quantity, type: 'hp' })
                          let newChosenProducts = chosenProducts.slice()
  
                          let productsIds = chosenProducts.slice()
                          productsIds = chosenProducts?.length > 0 ? productsIds.map(prod => prod._id) : []
  
                          let chosenProduct = JSON.parse(JSON.stringify(currentProduct))
                          chosenProduct.warehouse = { _id: productData?._id, quantity: productData?.quantity, type: 'hp' }
                          chosenProduct.storageQuantity = productData?.quantity || 0
  
                          let index = chosenProducts.findIndex(el => el?._id === currentProduct?._id)
                          newChosenProducts.splice(index, 1, chosenProduct)
                          setChosenProducts(newChosenProducts)
                         }}
                         key={productData?._id}
                         id={productData?._id}
                         isSelected={storageChosenProduct?.warehouseId === productData?._id}
                         value={productData}
                       />}
                        </div>
                      </div>
                    )
                  }))
                }
              </div>

              <div className="bottom-wrapper">
                <Button.Raised 
                   text="Избери"
                   className="raised-btn add"
                   disabled={!Object.keys(storageChosenProduct)?.length}
                   onClick={() => {
                     setStorageChosenProduct(storageChosenProduct)
                     setIsPopupOpen(false)
                     setCurrentProduct({})
                     setStorageChosenProduct({})
                     setSearchValue('')
                     }} />
              </div>
              {/* {chosenProducts?.length !== productsStorage?.length &&
              <div className="red">В склада не са налични всички продукти!</div>} */}
          </div>
        </div>
        {/* old version - products from inquiry.orders */}
        {/* <div className="scroll-container">
          {chosenProducts.map((prod, index) => {
            return (
              <OfferProduct
                forEdit={data.forEdit}
                productTypes={productTypes}
                product={prod}
                onSetProductDate={(date) => {
                  let newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].deliveryDate = date
                  setChosenProducts(newChosenProducts)
                }}
                onSetQuantity={(value) => {
                  let newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].quantity = value
                  setChosenProducts(newChosenProducts)
                }}
                onRemoveProduct={() => {
                  let newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts.splice(index, 1)
                  setChosenProducts(newChosenProducts)
                }}
                onSetProductPrice={(value) => {
                  let newChosenProducts = chosenProducts.slice(0)
                  if (!newChosenProducts[index].singleUnitPrice) {
                    newChosenProducts[index] = {
                      ...newChosenProducts[index],
                      singleUnitPrice: Number(value),
                    }
                  } else {
                    newChosenProducts[index].singleUnitPrice = Number(value)
                  }
                  setChosenProducts(newChosenProducts)
                }}
              />
            )
          })}
        </div> */}

        {/* new version - mixed objects */}
        <div className="scroll-container flex">
          {chosenProducts.map((prod, index) => {
            return (
              <OfferProduct
                forEdit={data.forEdit}
                productTypes={productTypes}
                product={prod}
                currency={currency}
                onUnitChange={(unitValue) => {
                  const newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].mainUnit = unitValue
                  setChosenProducts(newChosenProducts)
                }}
                onSetProductDate={(date) => {
                  let newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].deliveryDate = date
                  setChosenProducts(newChosenProducts)
                }}
                onSetQuantity={(value) => {
                  let newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts[index].quantity = value
                  newChosenProducts[index].warehouse.quantity = value
                  setChosenProducts(newChosenProducts)
                }}
                onRemoveProduct={() => {
                  let newChosenProducts = chosenProducts.slice(0)
                  newChosenProducts.splice(index, 1)
                  setChosenProducts(newChosenProducts)
                }}
                onSetProductPrice={(value) => {
                  let newChosenProducts = chosenProducts.slice(0)
                  if (!newChosenProducts[index].singleUnitPrice) {
                    newChosenProducts[index] = {
                      ...newChosenProducts[index],
                      singleUnitPrice: Number(value),
                    }
                  } else {
                    newChosenProducts[index].singleUnitPrice = Number(value)
                  }
                  setChosenProducts(newChosenProducts)
                }}
                isPopupOpen={isPopupOpen}
                setIsPopupOpen={(value) => setIsPopupOpen(value)}
                // currentProduct={currentProduct}
                setCurrentProduct={(value) => setCurrentProduct(value)}
                setStorageChosenProduct={(value) => setStorageChosenProduct(value)}
                availableProducts={availableProducts}
                HPProducts={HPProducts}
                storageType={storageType}
                setStorageType={(value) => setStorageType(value)}
              />
            )
          })}
        </div>

        {/* <p>Продукти в склад</p>
        <div className="scroll-container">
          {productsList?.map((prod, index) => {
            return (
              <OfferProduct
                forEdit={data.forEdit}
                productTypes={productTypes}
                product={prod}
                onSetProductDate={(date) => {
                  let newChosenProducts = productsList.slice(0)
                  newChosenProducts[index].deliveryDate = date
                  setProductsList(newChosenProducts)
                }}
                onSetQuantity={(value) => {
                  let newChosenProducts = productsList.slice(0)
                  newChosenProducts[index].quantity = value
                  setProductsList(newChosenProducts)
                }}
                onRemoveProduct={() => {
                  let newChosenProducts = productsList.slice(0)
                  newChosenProducts.splice(index, 1)
                  setProductsList(newChosenProducts)
                }}
                onSetProductPrice={(value) => {
                  let newChosenProducts = productsList.slice(0)
                  if (!newChosenProducts[index].singleUnitPrice) {
                    newChosenProducts[index] = {
                      ...newChosenProducts[index],
                      singleUnitPrice: Number(value),
                    }
                  } else {
                    newChosenProducts[index].singleUnitPrice = Number(value)
                  }
                  setProductsList(newChosenProducts)
                }}
              />
            )
          })}
        </div> */}

        <div className="margin-value-container">
          <p id="margin">
            Марж: {productsTotal - initialProductsWithoutQuantity}
            лв. |{" "}
            {productsTotal ? (((productsTotal - initialProductsWithoutQuantity) / productsTotal) * 100).toFixed(0) : 0} %
          </p>
        </div>
        <div className="flex-container space-between">
          <p>Стойност на продуктите</p>
          <div className="value-container">{totalPrice.toFixed(2)} лв.</div>
        </div>
        <div className="flex-container">
          {data.forEdit && (
            <Button.Raised
              className="delete-btn"
              text="Изтрий документ"
              onClick={() => {
                deleteInvoice({ _id: data.invoice._id })
                hideModal()
              }}
            />
          )}
          <Button.Raised
            text={`${data.forEdit ? "Редактирай фактура" : "Създай фактура"}`}
            disabled={!isValid()}
            onClick={() => {
              if (data.forEdit) {
                const products = chosenProducts.map((product) => {
                  let cost = 0
                  if (product.expenses) {
                    const materialCost = product.expenses.material.reduce(
                      (acc, val) => {
                        return acc + val.total || 0
                      },
                      0
                    )
                    const staffCost =
                      product.expenses.staff &&
                      product.expenses.staff.reduce((acc, val) => {
                        return acc + val.total
                      }, 0)
                    const otherCost =
                      product.expenses.other &&
                      product.expenses.other.reduce((acc, val) => {
                        return acc + val.total
                      }, 0)
                    const customCost =
                      product.expenses.custom &&
                      product.expenses.custom.reduce((acc, val) => {
                        return (
                          acc +
                          val.elements.reduce((acc, val) => {
                            return acc + val.total
                          }, 0)
                        )
                      }, 0)
                    cost = materialCost + staffCost + otherCost + customCost
                  }

                  if (currency === 'EUR') {
                    cost = cost * 1.95583
                    // product.singleUnitPrice = product.singleUnitPrice * 1.95583
                  }

                  return {
                    item: product.item ? product.item._id : product._id,
                    quantity: parseInt(product.quantity),
                    deliveryDate: moment(product.deliveryDate).toISOString(),
                    singleUnitPrice: product.singleUnitPrice || cost,
                    total: product.singleUnitPrice
                      ? product.singleUnitPrice * parseInt(product.quantity)
                      : cost * parseInt(product.quantity),
                    unit: product?.unit || product?.mainUnit
                  }
                  //added unit
                })

                // old
                // let sales = chosenProducts.map((product) => product.warehouse)

                //new
                let sales = chosenProducts.map((product) => product.warehouse)
                                          .filter(product => product?.type === 'warehouse')
                                          .map(product => { 
                                            delete product.type 
                                            return product})

                let salesHP = chosenProducts.map((product) => product.warehouse)
                              .filter(product => product?.type === 'hp')
                              .map(product => {
                                delete product.type
                                return (
                                  product = { ...product, field: ['quantity'] }
                                )
                              })

                  editInvoice({
                    payload: {
                    _id: data.invoice._id,
                    inquiry: data._id,
                    client: {
                      name: clientName,
                      uic: uic,
                      phone: phone,
                      email: email,
                      address: address,
                      attentionOf: attentionOf,
                    },
                    reason: reason,
                    bankData: {
                      bankAccount: bankAccount,
                      marginFacilities:
                        !isNaN(
                          (productsTotal - initialProductsWithoutQuantity) / 100
                        ) || 0,
                      marginHydro:
                        !isNaN(
                          (
                            ((productsTotal - initialProductsWithoutQuantity) /
                              productsTotal) *
                            100
                          ).toFixed(0)
                        ) || 0,
                    },
                    products: products
                  },
                  onSuccess: () => {
                    if (sales?.length) sellProduct({ sales })
                    if (salesHP?.length) sellProductHP({ sales: salesHP })
                  }
                })
                

                // editInvoice({
                //   _id: data.invoice._id,
                //   inquiry: data._id,
                //   client: {
                //     name: clientName,
                //     uic: uic,
                //     phone: phone,
                //     email: email,
                //     address: address,
                //     attentionOf: attentionOf,
                //   },
                //   reason: reason,
                //   bankData: {
                //     bankAccount: bankAccount,
                //     marginFacilities:
                //       !isNaN(
                //         (productsTotal - initialProductsWithoutQuantity) / 100
                //       ) || 0,
                //     marginHydro:
                //       !isNaN(
                //         (
                //           ((productsTotal - initialProductsWithoutQuantity) /
                //             productsTotal) *
                //           100
                //         ).toFixed(0)
                //       ) || 0,
                //   },
                //   products: products,
                // })
              } else {
                const products = chosenProducts.map((product) => {
                  let cost = 0
                  if (product.expenses) {
                    const materialCost = product.expenses.material.reduce(
                      (acc, val) => {
                        return acc + val.total || 0
                      },
                      0
                    )
                    const staffCost =
                      product.expenses.staff &&
                      product.expenses.staff.reduce((acc, val) => {
                        return acc + val.total
                      }, 0)
                    const otherCost =
                      product.expenses.other &&
                      product.expenses.other.reduce((acc, val) => {
                        return acc + val.total
                      }, 0)
                    const customCost =
                      product.expenses.custom &&
                      product.expenses.custom.reduce((acc, val) => {
                        return (
                          acc +
                          val.elements.reduce((acc, val) => {
                            return acc + val.total
                          }, 0)
                        )
                      }, 0)
                    cost = materialCost + staffCost + otherCost + customCost
                  }

                  if (currency === 'EUR') {
                    cost = cost * 1.95583
                    product.singleUnitPrice = product.singleUnitPrice * 1.95583
                  }

                  return {
                    item: product._id,
                    quantity: parseInt(product.quantity),
                    deliveryDate: product.deliveryDate,
                    singleUnitPrice: product.singleUnitPrice || cost,
                    total: cost * parseInt(product.quantity),
                    unit: product?.mainUnit
                    //added unit
                  }
                })

                //old
                // let sales = chosenProducts.map((product) => product.warehouse)

                //new
                let sales = chosenProducts.map((product) => product.warehouse)
                                          .filter(product => product?.type === 'warehouse')
                                          .map(product => { 
                                            delete product.type 
                                            return product})

                let salesHP = chosenProducts.map((product) => product.warehouse)
                              .filter(product => product?.type === 'hp')
                              .map(product => {
                                delete product.type
                                return (
                                  product = { ...product, field: ['quantity'] }
                                )
                              })

                  createInvoice({
                    payload: {
                    inquiry: data._id,
                    client: {
                      name: clientName,
                      uic: uic,
                      phone: phone,
                      email: email,
                      address: address,
                      attentionOf: attentionOf,
                    },
                    reason: reason,
                    bankData: {
                      bankAccount: bankAccount,
                      marginFacilities:
                        !isNaN(
                          (productsTotal - initialProductsWithoutQuantity) / 100
                        ) || 0,
                      marginHydro:
                        !isNaN(
                          (
                            ((productsTotal - initialProductsWithoutQuantity) /
                              productsTotal) *
                            100
                          ).toFixed(0)
                        ) || 0,
                    },
                    products: products,
                    currency: currency
                  },
                  // onSuccess: () => sellProduct({ sales })
                  onSuccess: () => {
                    if (sales?.length) sellProduct({ sales })
                    if (salesHP?.length) sellProductHP({ sales: salesHP })
                  }
                })
                

                // createInvoice({
                //   inquiry: data._id,
                //   client: {
                //     name: clientName,
                //     uic: uic,
                //     phone: phone,
                //     email: email,
                //     address: address,
                //     attentionOf: attentionOf,
                //   },
                //   reason: reason,
                //   bankData: {
                //     bankAccount: bankAccount,
                //     marginFacilities:
                //       !isNaN(
                //         (productsTotal - initialProductsWithoutQuantity) / 100
                //       ) || 0,
                //     marginHydro:
                //       !isNaN(
                //         (
                //           ((productsTotal - initialProductsWithoutQuantity) /
                //             productsTotal) *
                //           100
                //         ).toFixed(0)
                //       ) || 0,
                //   },
                //   products: products,
                // })
                // sellProduct({ sales })
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  productTypes: state.products.types,
  searchResults: state.products.more,
  searchResult: state.products.found,
  clientResults: state.inquiries.clientResults,
  availableProducts: state.storage.availableProducts,
  HPProducts: state.storage.HPRequests
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  getProductTypes: () => dispatch(getProductTypes()),
  searchProduct: (payload) => dispatch(searchProduct(payload)),
  createInvoice: (payload) => dispatch(createInvoice(payload)),
  createInvoiceEn: (payload) => dispatch(createInvoiceEn(payload)),
  sellProduct: (payload) => dispatch(sellProduct(payload)),
  sellProductHP: (payload) => dispatch(sellProductHP(payload)),
  searchOfferClients: (payload) => dispatch(searchOfferClients(payload)),
  editInvoice: (payload) => dispatch(editInvoice(payload)),
  editInvoiceEn: (payload) => dispatch(editInvoiceEn(payload)),
  resetResults: () => dispatch(resetResults()),
  clearResults: () => dispatch(clearResults()),
  deleteInvoice: (payload) => dispatch(deleteInvoice(payload)),
  getAvailableProducts: () => dispatch(getAvailableProducts()),
  getHPRequests: () => dispatch(getHPRequests()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceCreationModal)
