import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import {
  hideModal,
  getInquiries,
  exportProduct,
  sampleProduct,
  defectProduct,
} from "../../../actions"
import Files from "../../../utilities/files"
import { QuantityDropdownInput, UploadFile } from "../../../components"

import "./styles.scss"

const StorageProductActionModal = ({
  hideModal,
  data,
  getList,
  exportProduct,
  sampleProduct,
  defectProduct,
}) => {
  const [comment, setComment] = useState("")
  const [files, setFiles] = useState([])
  const [productQuantity, setProductQuantity] = useState("")
  const [sellPrice, setSalePrice] = useState()
  const disabled =
    data.type === "Изписване" ? !productQuantity : !productQuantity
  useEffect(() => {
    getList()
  }, [getList])

  useEffect(() => {
    setProductQuantity(data.product.quantity)
    //eslint-disable-next-line
  }, [])

  return (
    <div className="storage-product-action-container">
      <div className="top-container">
        <h3>
          {data.type === "Изписване"
            ? "Изписване"
            : data.type === "Бракуване"
            ? "Бракуване"
            : "За мостра"}
        </h3>
        <div className="exit-btn" onClick={hideModal} />
      </div>
      <div className="flex-container">
        <div className="input-element small">
          <p>Количество</p>
          <QuantityDropdownInput
            noDropdown
            quantityValue={productQuantity}
            quantityTypeValue={{ value: data.product.units }}
            onSetQuantity={(value) => setProductQuantity(value)}
          />
        </div>
        {data.type === "Изписване" && (
          <div className="input-element">
            <p>Цена за продажба</p>
            <QuantityDropdownInput
              noDropdown
              quantityValue={sellPrice}
              quantityTypeValue={{ value: data.product.units }}
              onSetQuantity={(value) => setSalePrice(value)}
            />
          </div>
        )}
      </div>
      <p>Коментар</p>
      <Input.Textarea
        placeholder="text"
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
      <p>Документи</p>
      <div className="flex-container">
        <UploadFile
          multiple
          onChange={(e) => {
            const fileNames = Object.keys(e.target.files).map(
              (key) => e.target.files[key].name
            )
            Files.uploadFiles(e.target.files).then((result) => {
              let newFiles = files.slice(0)
              result.forEach((file, index) =>
                newFiles.push({
                  name: fileNames[index],
                  location: file.location,
                })
              )
              setFiles(newFiles)
            })
          }}
        />
        <div className="files-container">
          {files.map((file) => {
            return <p>{file.name ? file.name : file}</p>
          })}
        </div>
        <Button.Raised
          className={`button-green ${disabled && "disabled"}`}
          text={
            data.type === "Изписване"
              ? "Изпиши"
              : data.type === "Бракуване"
              ? "Бракувай"
              : "За мостра"
          }
          onClick={() => {
            if (productQuantity) {
              if (data.type === "Изписване") {
                exportProduct({
                  _id: data.product._id,
                  type: data.product.type,
                  variation: data.product.variation,
                  quantity: productQuantity,
                  pricing: {
                    type: data.product.pricing.type,
                    singleUnitPrice: data.product.pricing.singleUnitPrice,
                  },
                  inquiry: null,
                  units: data.product.units,
                  files: files,
                  sellPrice: sellPrice,
                  comment: comment,
                })
              } else if (data.type === "Бракуване") {
                defectProduct({
                  _id: data.product._id,
                  type: data.product.type,
                  variation: data.product.variation,
                  quantity: productQuantity,
                  pricing: {
                    type: data.product.pricing.type,
                    singleUnitPrice: data.product.pricing.singleUnitPrice,
                  },
                  inquiry: null,
                  units: data.product.units,
                  files: files,
                  comment: comment,
                })
              } else {
                sampleProduct({
                  _id: data.product._id,
                  type: data.product.type,
                  variation: data.product.variation,
                  quantity: productQuantity,
                  pricing: {
                    type: data.product.pricing.type,
                    singleUnitPrice: data.product.pricing.singleUnitPrice,
                  },
                  inquiry: null,
                  units: data.product.units,
                  files: files,
                  comment: comment,
                })
              }
              hideModal()
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  inquiries: state.inquiries.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  getList: (payload) => dispatch(getInquiries(payload)),
  exportProduct: (payload) => dispatch(exportProduct(payload)),
  sampleProduct: (payload) => dispatch(sampleProduct(payload)),
  defectProduct: (payload) => dispatch(defectProduct(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageProductActionModal)
