import React, { useState, useEffect, useMemo } from "react"
import styles from "./Weekly.module.scss"
import { times } from "lodash"
import moment from "moment"
import { isEmpty } from "lodash"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Paper } from "@mui/material"
import { useParams } from "react-router-dom"

const machines = ["OD800", "OD2000", "OD2400"]

export default function Weekly({
  dateProp = moment().startOf("week").format("DD.MM.YYYY"),
  schedule,
  setDate,
  week,
  contractor = false,
}) {
  const [placeholderSchedule, setPlaceholderSchedule] = useState({})
  useEffect(() => {
    if (contractor === true && isEmpty(schedule)) {
      setPlaceholderSchedule({
        "Изпълнител 1": {},
        "Изпълнител 2": {},
        "Изпълнител 3": {},
      })
    } else if (contractor === true && !isEmpty(schedule)) {
      setPlaceholderSchedule(schedule)
    }
  }, [schedule, contractor])
  const params = useParams()
  const [showWeekend, setShowWeekend] = useState(false)
  const date = useMemo(() => {
    return week.startOfWeek
  }, [week])
  const [daysToShow, setDaysToShow] = useState(showWeekend ? 7 : 5)
  useEffect(() => {
    setDaysToShow(showWeekend ? 7 : 5)
  }, [showWeekend, date])
  return (
    <div className={styles["calendar-weekly-container"]}>
      <div className={styles["inner-container"]}>
        <div className={styles["calendar-navigation"]}>
          <div className={`${styles["day-arrows"]} ${styles.cell}`}>
            <ArrowBackIosIcon
              style={{ color: "#005EA3", cursor: "pointer" }}
              onClick={() => {
                const newWeek = { ...week }
                newWeek.startOfWeek.subtract(1, "weeks").startOf("isoWeek")
                newWeek.endOfWeek.subtract(1, "weeks").endOf("isoWeek")
                setDate(newWeek)
                // history.replace({
                //   pathname: `/productionv2/${params.type}/${
                //     params.period
                //   }/${moment(newWeek.startOfWeek).format("DD")}-${moment(
                //     newWeek.startOfWeek
                //   ).format("DD")}-${moment(newWeek.startOfWeek).format(
                //     "MM"
                //   )}-${moment(newWeek.startOfWeek).format("YYYY")}`,
                // })
              }}
            />
            <ArrowForwardIosIcon
              style={{ color: "#005EA3", cursor: "pointer" }}
              onClick={() => {
                const newWeek = { ...week }
                newWeek.startOfWeek.add(1, "weeks").startOf("isoWeek")
                newWeek.endOfWeek.add(1, "weeks").endOf("isoWeek")
                setDate(newWeek)
              }}
            />
          </div>
          {times(7).map((el, i) => (
            <div
              key={`cell-date-${i}`}
              className={`${styles["cell-date"]} ${
                moment(date).add(i, "days").isSame(moment(), "day") &&
                styles.today
              }`}
            >
              <p className={styles.bold}>
                {moment(date).add(i, "days").format("DD [\n]MMMM")}
              </p>
              <p>{moment(date).add(i, "days").format("dddd")}</p>
            </div>
          ))}
        </div>
        <div
          className={styles["scroll-container"]}
          // style={{ height: scrollHeight }}
        >
          {contractor === false
            ? machines.map((machine, i) => (
                <div className={styles.col}>
                  <div className={styles["cell-machine"]}>
                    <p>Машина {machine}</p>
                  </div>
                  {times(7).map((i) => (
                    <div
                      key={`cell-day-${i}`}
                      className={`${styles.cell} ${styles["cell-day"]}`}
                      // style={{ height: cellHeight }}
                    >
                      {Object.keys(schedule)?.map((session, j) => {
                        const dayOfWeek = moment(
                          schedule[session][0].createdAt
                        ).day()
                        return (
                          <>
                            {session === machine && dayOfWeek === i && (
                              <Paper
                                elevation={2}
                                className={styles["weekly-action"]}
                              >
                                <p>test</p>
                              </Paper>
                            )}
                          </>
                        )
                      })}
                    </div>
                  ))}
                </div>
              ))
            : Object.keys(placeholderSchedule).map((contractor, i) => (
                <div className={styles.col}>
                  <div className={styles["cell-machine"]}>
                    <p>{contractor}</p>
                  </div>
                  {times(7).map((i) => (
                    <div
                      key={`cell-day-${i}`}
                      className={`${styles.cell} ${styles["cell-day"]}`}
                      // style={{ height: cellHeight }}
                    >
                      {Object.keys(schedule)?.map((session, j) => {
                        const dayOfWeek = moment(
                          schedule[session].createdAt
                        ).day()

                        return (
                          <>
                            {session === contractor && dayOfWeek === i && (
                              <Paper
                                elevation={2}
                                className={styles["weekly-action"]}
                              >
                                <p>{session.activity}</p>
                              </Paper>
                            )}
                          </>
                        )
                      })}
                    </div>
                  ))}
                </div>
              ))}
        </div>
      </div>
      {/* <div className={styles["row-weekend"]}>
        Почивни дни
        <KeyboardArrowDownIcon
          className={`${showWeekend && styles.rotate}`}
          onClick={() => setShowWeekend(!showWeekend)}
        />
      </div> */}
    </div>
  )
}
