import React from "react"
import { connect } from "react-redux"
import { getInquiries, showLoading } from "../../actions"
import { ProjectsView } from "../../components/"
import "./styles.scss"

const Projects = ({ inquiries }) => {

  return (
    <div className="main-projects-container">
      <ProjectsView />
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiries: state.inquiries.inquiries,
  inquiriesPage: state.inquiries.inquiriesPage,
  totalDocs: state.inquiries.totalDocs,
  activeThisMonth: state.inquiries.activeThisMonth,
  activeThisWeek: state.inquiries.activeThisWeek,
})

const mapDispatchToProps = (dispatch) => ({
  getList: (payload) => dispatch(getInquiries(payload)),
  showLoading: () => dispatch(showLoading()),
  // searchInquiries: (payload) => dispatch(searchInquiries(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
