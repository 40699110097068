import React from "react"
import { connect } from "react-redux"
import { hideModal } from "../../../actions"
import { FileOffer } from "../.."
import "./styles.scss"

function FileOffersModal({ hide, data }) {
  return (
    <div className="file-offers-modal-container">
      <div className="file-offers-top-container">
        <h2>Оферти:</h2>
        <div className="exit-btn" onClick={hide} />
      </div>
      <div className="scroll-container">
        {data.map((doc) => {
          return (
            <div className="offer-element">
              <div className="graphics">
                <div
                  className="coloured-dot"
                  style={{ backgroundColor: "blue" }}
                />
                <div
                  className="coloured-bar"
                  style={{ backgroundColor: "blue" }}
                />
              </div>
              <FileOffer fkey={doc._id} doc={doc} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FileOffersModal)
