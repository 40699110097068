import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import _ from "lodash"
import {
  postsRequest,
  exportRequest,
  hideModal,
  resetStorageResults,
  searchRequests,
  updateMaterial,
} from "../../../actions"
import { CustomRadio } from "../../../components"
import Files from "../../../utilities/files"

import "./styles.scss"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

const onChange = (event, search) => {
  event.persist()
  onChangeDelayed(event, search)
}

const onChangeDelayed = _.debounce(
  (event, search) => search({ search: event.target.value }),
  300
)

const StorageRawMaterialsFinishOrder = ({
  selectedRequests,
  hideModal,
  postsRequest,
  exportRequest,
  storageMaterialResults,
  resetStorageResults,
  searchStorageMaterials,
  updateMaterial,
  // search
}) => {
  const [typeOfAccrual, setTypeOfAccrual] = useState("existing")
  const [storageMaterialInput, setStorageMaterialInput] = useState("")
  const [storageItem, setStorageItem] = useState(null)
  const [quantity, setQuantity] = useState("")
  const [payload, setPayload] = useState({
    comment: "",
    files: [],
  })

  return (
    <div className="storage-raw-materials-finish-order-container">
      <div className="modal-heading">
        <h3>
          {selectedRequests[0].status === "delivered"
            ? "Заприхождаване"
            : "Изпиши"}
        </h3>
        <div className="exit-btn">
          <Button.Icon
            name="plus"
            color="#152331"
            onClick={hideModal}
            size={24}
          />
        </div>
      </div>
      <div className="modal-content">
        {selectedRequests[0].status === "accrued" && (
          <>
            <p>Количество</p>
            <Input.Text
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
            />
          </>
        )}
        {selectedRequests[0].status === "delivered" && (
          <div className="flex-container">
            <div
              className="flex-container"
              onClick={() => setTypeOfAccrual("existing")}
            >
              <p>Към съществуваща суровина</p>
              <CustomRadio selected={typeOfAccrual === "existing"} />
            </div>
            <div
              className="flex-container"
              onClick={() => setTypeOfAccrual("new")}
            >
              <p>Като нова позиция</p>
              <CustomRadio selected={typeOfAccrual === "new"} />
            </div>
          </div>
        )}
        {typeOfAccrual === "existing" &&
          selectedRequests[0].status === "delivered" && (
            <div className="input-wrapper">
              <Input.Text
                value={storageMaterialInput}
                onChange={(event) => {
                  onChange(event, searchStorageMaterials)
                  setStorageMaterialInput(event.target.value)
                }}
              />
              {storageMaterialResults.length > 0 && (
                <div className="client-results-dropdown">
                  {storageMaterialResults.map((product) => {
                    return (
                      <div
                        className="result"
                        onClick={() => {
                          setStorageItem(product)
                          setStorageMaterialInput(product.material)
                          resetStorageResults()
                        }}
                      >
                        {product.material}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )}
        <div className="item">
          <span>Коментар</span>
          <Input.Textarea
            value={payload.comment}
            onChange={(e) =>
              setPayload({ ...payload, comment: e.target.value })
            }
          />
        </div>
        <div className="files">
          <span>Документи</span>
          {!!payload.files.length &&
            payload.files.map((file, index) => (
              <div className="single-file" key={`single-file-${index}`}>
                <Button.Icon
                  name="plus"
                  color="#f00"
                  onClick={() => {
                    const newFiles = payload.files.slice()
                    newFiles.splice(index, 1)
                    setPayload({ ...payload, files: newFiles })
                  }}
                />
                <span>{file.name}</span>
              </div>
            ))}
          <Button.UploadButton
            text={
              <>
                <span>Прикачи</span>
                <Button.Icon name="chat---add-file" color="#52ABDD" />
              </>
            }
            accept="*"
            multiple
            onChange={(e) => {
              const files = e.target.files
              Files.uploadFiles(files).then((result) => {
                setPayload({
                  ...payload,
                  files: [
                    ...payload.files,
                    ...result.map((file, index) => ({
                      url: file.location,
                      name: files[index].name,
                    })),
                  ],
                })
              })
            }}
          />
        </div>
        <Button.Raised
          text={
            selectedRequests[0].status === "delivered" ? "Заприходи" : "Изпиши"
          }
          className="btn-create"
          onClick={() => {
            if (selectedRequests[0].status === "delivered") {
              if (typeOfAccrual === "existing") {
                postsRequest({
                  to: storageItem._id,
                  ...payload,
                  ids: selectedRequests.map((r) => r._id),
                })

                updateMaterial({
                  to: storageItem._id,
                  from: selectedRequests.map((r) => r._id),
                })
                hideModal()
              } else {
                postsRequest({
                  ...payload,
                  ids: selectedRequests.map((r) => r._id),
                })
                hideModal()
              }
            } else {
              if (isNaN(parseInt(quantity))) {
                Alerts.error("Грешка!", "Моля въведете число в количество")
              } else {
                exportRequest({
                  ...payload,
                  ids: selectedRequests.map((r) => r._id),
                  quantity: parseInt(quantity),
                })
                hideModal()
              }
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  storageMaterialResults: state.storage.search,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  postsRequest: (payload) => dispatch(postsRequest(payload)),
  exportRequest: (payload) => dispatch(exportRequest(payload)),
  searchStorageMaterials: (payload) => dispatch(searchRequests(payload)),
  resetStorageResults: () => dispatch(resetStorageResults()),
  updateMaterial: (payload) => dispatch(updateMaterial(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageRawMaterialsFinishOrder)
