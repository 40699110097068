import React, { useEffect, useState, useCallback } from "react"
import { Input } from "@makedonski/admin-ui"
import { connect } from "react-redux"
import _ from "lodash"
import { getFilteredUsers } from "../../actions"
import "./styles.scss"
import { Button, Radio, Checkbox } from "@mui/material"

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

const UserFilter = ({
  getFilteredUsers,
  users,
  onSelectUsers,
  noReason,
  selectedUsers,
  excludedUsers,
  multipleSelect = false,
  singleSelect = false,
}) => {
  const [value, setValue] = useState("")
  const [checkedUser, setCheckedUser] = useState(null)
  const [multipleUsers, setMultipleUsers] = useState([])
  const [reason, setReason] = useState("")
  const sendQuery = (query) => getFilteredUsers({ fullName: query })
  const delayedQuery = useCallback(
    _.debounce((q) => sendQuery(q), 500),
    []
  )

  useEffect(() => {
    getFilteredUsers({
      ...(selectedUsers && {
        onSuccess: () => setMultipleUsers(selectedUsers),
      }),
    })
  }, [getFilteredUsers])

  const renderUser = (user) => {
    return (
      <div key={user._id} className="user-filter-user-item">
        <div
          className="user-item-avatar"
          style={{
            backgroundImage: `url(${
              user.coverPhoto || global.config.defaultAvatar
            })`,
          }}
        />
        <div className="user-item-full-name">{user.fullName}</div>
        {multipleSelect ? (
          <Checkbox
            sx={radioStyles}
            checked={multipleUsers?.includes(user._id)}
            value={user._id}
            onChange={({ target: { value } }) => {
              if (multipleUsers.includes(value))
                setMultipleUsers(multipleUsers.filter((id) => id !== value))
              else setMultipleUsers([...multipleUsers, value])
            }}
          />
        ) : (
          <Radio
            checked={checkedUser === user._id}
            onChange={(e) => setCheckedUser(e.target.value)}
            value={user._id}
            sx={radioStyles}
          />
        )}
      </div>
    )
  }

  return (
    <div className="user-filter-container">
      {!noReason && (
        <>
          <h3 className="user-filter-title">Основание</h3>
          <Input.Text
            value={reason}
            placeholder="Основание"
            onChange={(event) => setReason(event.target.value)}
          />
        </>
      )}

      <h3 className="user-filter-title">
        {multipleSelect ? "Управление екип" : "Прехвърляне на"}
      </h3>
      <Input.Text
        value={value}
        placeholder="Търси по име"
        onChange={(event) => {
          setValue(event.target.value)
          delayedQuery(event.target.value)
        }}
      />
      <div
        className="user-filter-scroll-container"
        style={{ height: noReason && "260px" }}
      >
        {users
          .slice(0)
          .filter((user) => selectedUsers && excludedUsers ?  !excludedUsers.includes(user._id) : user)
          .sort((a, b) => {
            if (!selectedUsers) return 0
            const aIsSelected = selectedUsers.includes(a?._id)
            const bIsSelected = selectedUsers.includes(b?._id)

            if (aIsSelected && !bIsSelected) return -1
            if (!aIsSelected && bIsSelected) return 1

            return 0
          })
          .map((user) => renderUser(user))}
      </div>
      <div className="user-filter-actions">
        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#26ACE2",
            color: "#fff",
            textTransform: "none",
          }}
          onClick={() => {
            if (onSelectUsers) {
              if (multipleSelect) onSelectUsers(multipleUsers, reason)
              else {
                if (singleSelect) onSelectUsers.call(null, checkedUser, reason)
                else onSelectUsers.call(null, checkedUser, reason)
              }
            }
          }}
        >
          {selectedUsers ? "Запази" : "Прехвърли"}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  users: state.user.filteredUsers,
})

const mapDispatchToProps = (dispatch) => ({
  getFilteredUsers: (payload) => dispatch(getFilteredUsers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserFilter)
