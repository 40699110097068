import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { TaskTypes, GeneralTypes, hideModal } from "../actions"
import { API_URL } from "../config/settings"

export const getTasksEpic = (action$) => {
    return action$.pipe(
      ofType(TaskTypes.GET_TASKS),
      switchMap(() => {
        return Api.get(
          `${API_URL}/tasks`,
          JSON.stringify()
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: TaskTypes.GET_TASKS_SUCCESS,
                payload: response.tasks
              })
              obs.next(hideModal())
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error('Грешка', err.response.message)
            } else {
              Alerts.error('Грешка', 'невалидни данни!')
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }

export const getTaskEpic = (action$) => {
    return action$.pipe(
      ofType(TaskTypes.GET_TASK),
      switchMap(({ payload }) => {
        return Api.get(
          `${API_URL}/task/${payload._id}`,
          JSON.stringify(payload)
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: TaskTypes.GET_TASK_SUCCESS,
                payload: response
              })
              obs.next(hideModal())
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error('Грешка', err.response.message)
            } else {
              Alerts.error('Грешка', 'невалидни данни!')
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }

export const createTaskEpic = (action$) => {
    return action$.pipe(
      ofType(TaskTypes.CREATE_TASK),
      switchMap(({ payload }) => {
        return Api.post(
          `${API_URL}/task/create`,
          JSON.stringify(payload)
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: TaskTypes.CREATE_TASK_SUCCESS,
                payload: response
              })
              obs.next(hideModal())
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error('Грешка', err.response.message)
            } else {
              Alerts.error('Грешка', 'невалидни данни!')
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }

export const updateTaskEpic = (action$) => {
    return action$.pipe(
      ofType(TaskTypes.UPDATE_TASK),
      switchMap(({ payload }) => {
        return Api.put(
          `${API_URL}/task`,
          JSON.stringify(payload)
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: TaskTypes.UPDATE_TASK_SUCCESS,
                payload: response
              })
              obs.next(hideModal())
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error('Грешка', err.response.message)
            } else {
              Alerts.error('Грешка', 'невалидни данни!')
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }

export const deleteTaskEpic = (action$) => {
    return action$.pipe(
      ofType(TaskTypes.DELETE_TASK),
      switchMap(({ payload }) => {
        return Api.delete(
          `${API_URL}/task`,
          JSON.stringify(payload)
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: TaskTypes.DELETE_TASK_SUCCESS,
                payload: response
              })
              obs.next(hideModal())
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error('Грешка', err.response.message)
            } else {
              Alerts.error('Грешка', 'невалидни данни!')
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }

export const reorderTasksEpic = (action$) => {
    return action$.pipe(
      ofType(TaskTypes.REORDER_TASKS),
      switchMap(({ payload }) => {
        return Api.put(
          `${API_URL}/task`,
          JSON.stringify(payload)
        ).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: TaskTypes.REORDER_TASKS_SUCCESS,
                payload: response
              })
              obs.next(hideModal())
              obs.complete()
            })
          }),
          catchError((err) => {
            if (err.response.message) {
              Alerts.error('Грешка', err.response.message)
            } else {
              Alerts.error('Грешка', 'невалидни данни!')
            }
            return ActionsObservable.create((obs) => {
              console.error(err)
              obs.next({ type: GeneralTypes.ERROR, payload: err })
              obs.complete()
            })
          })
        )
      })
    )
  }