import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, Switch, Redirect, Router, withRouter } from "react-router-dom"
import { Auth } from "@makedonski/socourt-utilities"
import { Section } from "@makedonski/admin-ui"
import { isMobile } from "react-device-detect"
import {
  Home,
  HomeOld,
  Login,
  Products,
  Product,
  Projects,
  Storage,
  Production,
  Inquiries,
  SingleProject,
  Schedule,
  ProductionNewRequest,
  ProductionAddRequest,
  ProductionViewRequest,
  ProductionEditRequest,
  PipeScheduleInsertion,
  ProductionViewOtherRequest,
  MachineSettings,
  EndProduct,
  InquiryAdd,
  EditOffer,
  MachineTabletView,
  MachineActivityView,
  MachineRequestsView,
  AddEditTask,
  ReviewTask,
} from "../../screens"
import { Header, Modal } from ".."
import { history } from "../../config/stores"
import { oneSignalAppID, oneSignalSafariID } from "../../config/settings"
import { setPushToken } from "../../actions"
import ProductionPipes from "../ProductionPipes/ProductionPipes"
import RequestForm from "../RequestForm/RequestForm"
import EditProduct from "../../screens/EditProduct/EditProduct"
import Support from "../../screens/Support/Support"
import { getCurrentUserRole } from "../../actions"
import { useSelector } from "react-redux"
import Contacts from "../../screens/Contacts/Contacts"
import AddContactManually from "../../screens/Contacts/ContactsOperations/AddContactManually"
import AddContactImage from "../../screens/Contacts/ContactsOperations/AddContactImage"
import EditContact from "../../screens/Contacts/ContactsOperations/EditContact"
import AddContactImageMobile from "../../screens/Contacts/ContactsOperations/AddContactImageMobile"
import CurrentContactMobile from "../../screens/Contacts/ContactsOperations/CurrentContactMobile"
import EditContactFromImage from "../../screens/Contacts/ContactsOperations/EditContactFromImage"
import EditContactFromImageMobile from "../../screens/Contacts/ContactsOperations/EditContactFromImageMobile"
// import { ScrollRestoration } from "react-router-dom";

// const TaskModal = Tasks.Modal({ React, connect })
// const StatusDetectionView = StatusDetection.View({ React, connect })
// const ChatWrapper = Chat.ChatComponent({ React, connect })

const PrivateRoute = ({ ...rest }) => {
  return (
    <Route
      render={({ location }) => {
        return Auth.isAuthenticated ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }}
    />
  )
}

const LimitedAccessRouteFull = ({ ...rest }) => {
  // const currentUserRole = useSelector(state => state.user.currentUserRole)
  const currentUserRole = localStorage.getItem("currentUserRole")
  return (
    <Route
      render={({ location }) => {
        return Auth.isAuthenticated && currentUserRole?.includes("full") ? (
          <Route {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: Auth.isAuthenticated ? "/" : "/login",
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

const LimitedAccessRouteTablet = ({ ...rest }) => {
  // const currentUserRole = useSelector(state => state.user.currentUserRole)
  const currentUserRole = localStorage.getItem("currentUserRole")
  return (
    <Route
      render={({ location }) => {
        return Auth.isAuthenticated &&
          (currentUserRole?.includes("full") ||
            currentUserRole?.includes("tablet")) ? (
          <Route {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: Auth.isAuthenticated ? "/" : "/login",
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

const LimitedAccessRouteProduction = ({ ...rest }) => {
  // const currentUserRole = useSelector(state => state.user.currentUserRole)
  const currentUserRole = localStorage.getItem("currentUserRole")
  return (
    <Route
      render={({ location }) => {
        return Auth.isAuthenticated &&
          (currentUserRole?.includes("full") ||
            currentUserRole?.includes("production")) ? (
          <Route {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: Auth.isAuthenticated ? "/" : "/login",
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

const LimitedAccessRouteProductionTablet = ({ ...rest }) => {
  // const currentUserRole = useSelector(state => state.user.currentUserRole)
  const currentUserRole = localStorage.getItem("currentUserRole")
  return (
    <Route
      render={({ location }) => {
        return Auth.isAuthenticated &&
          (currentUserRole?.includes("full") ||
            (currentUserRole?.includes("production") &&
              currentUserRole?.includes("tablet"))) ? (
          <Route {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: Auth.isAuthenticated ? "/" : "/login",
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}
// getCurrentUserRole({ _id: Auth.getUser()._id })
class Routes extends Component {
  componentDidMount() {
    if (isMobile && this.props.isAuthenticated) {
      const chatWrap = document.querySelector(".npm-chat-container")
      if (chatWrap?.style) chatWrap.style.top = "20px"
    }

    const OneSignal = window.OneSignal || []
    OneSignal.push(() => {
      OneSignal.init({
        appId: oneSignalAppID,
        safari_web_id: oneSignalSafariID,
        notifyButton: {
          enable: true,
        },
      }).then(() => {
        OneSignal.showSlidedownPrompt({ force: true })
        OneSignal.log.setLevel("trace")
        OneSignal.getUserId().then((data) => {
          if (data && this.props.isAuthenticated) {
            this.props.setToken({ _id: Auth.getUser()._id, pushToken: data })
          }
        })
        OneSignal.on("subscriptionChange", () => {
          OneSignal.getUserId().then((data) => {
            if (this.props.isAuthenticated) {
              this.props.setToken({ _id: Auth.getUser()._id, pushToken: data })
            }
          })
        })
      })
    })

    this.props.getCurrentUserRole()
  }

  render() {
    return (
      <Router history={history}>
        <div className="dashboard-wrap">
          {this.props.isAuthenticated && <Header socket={this.props.socket} />}
          {/* {this.props.isAuthenticated && <StatusDetectionView />} */}
          <Switch>
            {/* <ScrollRestoration
            getKey={(location, matches) => {
              const paths = ["/"];
              return paths.includes(location.pathname)
                ? // home restore scroll position by pathname
                  location.pathname
                : // everything else by location like the browser
                  location.key;
            }}/> */}
            <Route exact path="/login" component={Login} />
            {!this.props.currentUserRole?.includes("tablet") && (
              <PrivateRoute exact path="/" component={Home} />
            )}
            {!this.props.currentUserRole?.includes("tablet") && (
              <PrivateRoute exact path="/task" component={AddEditTask} />
            )}
            {!this.props.currentUserRole?.includes("tablet") && (
              <PrivateRoute exact path="/task/:_id" component={AddEditTask} />
            )}
            {!this.props.currentUserRole?.includes("tablet") && (
              <PrivateRoute
                exact
                path="/task/new/:categoryId"
                component={AddEditTask}
              />
            )}
            {!this.props.currentUserRole?.includes("tablet") && (
              <PrivateRoute exact path="/review-task" component={ReviewTask} />
            )}
            {!this.props.currentUserRole?.includes("tablet") && (
              <PrivateRoute
                exact
                path="/review-task/:_id"
                component={ReviewTask}
              />
            )}
            <LimitedAccessRouteTablet
              exact
              path="/machine-settings"
              component={MachineSettings}
            />
            <LimitedAccessRouteTablet
              exact
              path="/machine-tablet-view/:id"
              component={MachineTabletView}
            />
            <LimitedAccessRouteTablet
              exact
              path="/machine-activity-view/:id"
              component={MachineActivityView}
            />
            <LimitedAccessRouteTablet
              exact
              path="/machine-requests-view"
              component={MachineRequestsView}
            />
            {/* <PrivateRoute><LimitedAccessRoute exact path='/products' component={Products} /></PrivateRoute> */}

            <LimitedAccessRouteFull
              exact
              path="/products"
              component={Products}
            />
            <PrivateRoute exact path="/products/:id" component={Product} />
            <LimitedAccessRouteFull
              exact
              path="/projects"
              component={Projects}
            />
            <LimitedAccessRouteFull
              exact
              path="/storage/:tab/:search"
              component={Storage}
            />
            <LimitedAccessRouteFull exact path="/storage" component={Storage} />
            <LimitedAccessRouteFull
              exact
              path="/production"
              component={Production}
            />
            {/* <PrivateRoute exact path='/production' component={Production} /> */}
            <PrivateRoute
              exact
              path="/production/end-product/:id"
              component={EndProduct}
            />
            {/* <PrivateRoute exact path='/production/end-product/:id/:productId?' component={EndProduct} /> */}
            <PrivateRoute
              exact
              path="/productionv2"
              component={ProductionPipes}
            />
            <PrivateRoute
              exact
              path="/productionv2/request"
              component={RequestForm}
            />
            <PrivateRoute
              exact
              path="/productionv2/edit/:slug"
              component={EditProduct}
            />

            <LimitedAccessRouteProduction
              exact
              path="/production/:type/:period/:date"
              component={Production}
            />
            <PrivateRoute
              exact
              path="/production/product/:id"
              component={Schedule}
            />
            <LimitedAccessRouteProduction
              exact
              path="/production/view/:id"
              component={ProductionViewRequest}
            />
            <LimitedAccessRouteProduction
              exact
              path="/production/edit/:id"
              component={ProductionEditRequest}
            />
            <LimitedAccessRouteProduction
              exact
              path="/production/view-edit/:id"
              component={ProductionViewOtherRequest}
            />
            <LimitedAccessRouteProduction
              exact
              path="/production/newrequest"
              component={ProductionNewRequest}
            />
            <LimitedAccessRouteProduction
              exact
              path="/production/addrequest"
              component={ProductionAddRequest}
            />
            {/* <PrivateRoute exact path="/production/newrequest/pipe" component={PipeScheduleInsertion} /> */}
            <PrivateRoute
              exact
              path="/production/pipe/:id"
              component={PipeScheduleInsertion}
            />
            <LimitedAccessRouteFull
              exact
              path="/inquiries"
              component={Inquiries}
            />
            <LimitedAccessRouteFull
              exact
              path="/inquiries/add"
              component={InquiryAdd}
            />
            <LimitedAccessRouteFull
              exact
              path="/inquiries/edit/:id"
              component={InquiryAdd}
            />
            <LimitedAccessRouteFull
              exact
              path="/inquiries/edit/:id"
              component={InquiryAdd}
            />
            <LimitedAccessRouteFull
              exact
              path="/inquiries/edit-offer/:id"
              component={EditOffer}
            />
            <PrivateRoute exact path="/support">
              <Support socket={this.props.socket} />
            </PrivateRoute>

            <PrivateRoute exact path="/contacts">
              <Contacts />
            </PrivateRoute>
            <PrivateRoute exact path="/current-contact/:id">
              <CurrentContactMobile />
            </PrivateRoute>
            <PrivateRoute exact path="/add-contact/manually">
              <AddContactManually />
            </PrivateRoute>
            <PrivateRoute exact path="/add-contact/scanned">
              <AddContactImage />
            </PrivateRoute>
            <PrivateRoute exact path="/add-contact/mobile-scanned">
              <AddContactImageMobile />
            </PrivateRoute>
            <PrivateRoute exact path="/edit-contact/:id">
              <EditContact />
            </PrivateRoute>
            <PrivateRoute exact path="/edit-contact/image/:id">
              <EditContactFromImage />
            </PrivateRoute>
            <PrivateRoute exact path="/edit-contact/mobile-image/:id">
              <EditContactFromImageMobile />
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/projects/:id"
              component={SingleProject}
            />
          </Switch>
          {/* {this.props.isAuthenticated && <ChatWrapper />} */}
          <Modal
            isOpen={this.props.modal.visible}
            type={this.props.modal.type}
            data={this.props.modal.data}
            width={this.props.modal.width}
            height={this.props.modal.height}
            overflow={this.props.modal.overflow}
            minHeight={this.props.modal.minHeight}
            classNames={this.props.modal.classNames}
          />
          <Section.Loader
            loading={this.props.loading}
            text={this.props.loadingText || "Зареждане..."}
          />
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  modal: state.modal,
  taskModal: state.taskReducer,
  loading: state.general.loading,
  loadingText: state.general.loadingText,
  userRole: state.user.currentUserRole,
})

const mapDispatchToProps = (dispatch) => ({
  setToken: (payload) => dispatch(setPushToken(payload)),
  getCurrentUserRole: () => dispatch(getCurrentUserRole()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
