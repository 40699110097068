import React from 'react';
import './styles.scss';
import moment from 'moment';
import { useState, useEffect } from 'react';

export default function ProductionHistoryTab({ tableHeaderElements, data, tableData, onClose }) {
    const [ sortValue, setSortValue ] = useState("Брой")
    const [ dataSorted, setDataSorted ] = useState([])

    useEffect(() => {
      if (tableData?.length) setDataSorted(tableData)
    }, [tableData])

const handleSort = (sortValue, index, sort) => {
  let newData = JSON.parse(JSON.stringify(dataSorted))

    switch(sortValue) {
      case "Брой":
        newData.reverse()
        break;
      case "Тегло":
        newData.sort((a, b) => {     
          return sort
            ? a.weight - b.weight
            : b.weight - a.weight
        })
        break;
      case "Дължина":
        newData.sort((a, b) => {     
          return sort
            ? a.length - b.length
            : b.length - a.length
        })
        break;
      case "Дата":
        newData.sort((a, b) => {     
          return sort
          ? moment(a.date) - moment(b.date)
          : moment(b.date) - moment(a.date)
        })
        break;
      case "Час":
        newData.sort((a, b) => {     
          return sort
          ? moment(a.date).startOf('hour') - moment(b.date).startOf('hour')
          : moment(b.date).startOf('hour') - moment(a.date).startOf('hour')
        })
        break;
    }

    setDataSorted(newData)
}

  return (
    <div className='history-tab-outer-wrapper'>
      <div className={`production-history-tab-container ${dataSorted?.length === 0 ? 'hidden' : ''}`}>
        <div className='img-wrapper'>
          <img  src={require("../../assets/icons/closeIcon.svg")}  className="close-icon"  onClick={onClose} />
        </div>
      <h4 className='title'>История на дейността в производство</h4>
      
      <div className={`machine-data-wrapper large ${dataSorted?.length === 0 ? 'empty' : ''}`}>
          {dataSorted?.length === 0 ? 
          <div className='data-element regular'>Няма налична дейност</div> :
          <div className='table-wrapper'>
              <div className='table-header-wrapper'>
                  {tableHeaderElements?.map((el, i) => {
                      return (
                          <div className={`element bold ${el?.size}`} key={`element-table-tablet-view-${i}`}>
                            <div>{el?.name}</div>
                            {/* <img
                            src={require('../../assets/icons/arrow-table-down.svg')}
                            style={ el.sort ? { transform: "rotate(180deg)" } : {} }
                            onClick={() => {
                                setSortValue(el?.name)
                                el.sort = !el?.sort
                                handleSort(el?.name, i, el?.sort)
                            }} /> */}
                          </div>)
                  })}
              </div>
  
              <div className='table-content-wrapper'>
                  {dataSorted?.length && dataSorted?.map((el, i) => {
                      return (
                          <div className={`element-wrapper ${el?.defective && 'red'}`} key={el?._id}>
                            <div className='element table medium border'><span>{dataSorted?.length - i}</span> / {data?.quantity}</div>
                            <div className='element table small border'>{el?.weight} кг</div>
                            <div className='element table small border'>{el?.length} м</div>
                            <div className='element table medium border'>{moment(el?.date).format('DD.MM.YYYY')}</div>
                            <div className='element table large'>{moment(el?.date).format('HH:mm')}</div>
                          </div>
                      )
                  })}
              </div>
          </div>}
      </div>
    </div>
    </div>
  )
}
