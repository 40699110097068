import React from "react"
import { Provider } from "react-redux"
import { store } from "./config/stores"
import Routes from "./components/routes/Routes"
import { LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { createTheme, ThemeProvider } from "@mui/material"
import { useSupportSocket } from '@fidweb/customer-support'
import { SUPPORT_URL } from "./config/settings"
import { updateUser } from "./actions"

const theme = createTheme({
  typography: {
    fontFamily: ["light", "Helvetica Neue", "sans-serif"].join(","),
  },
  button: {
    textTransform: "none",
    fontFamily: ["light", "Helvetica Neue", "sans-serif"].join(","),
  },
})

const App = () => {
  const { currentUser } = store.getState().user

  const socket = useSupportSocket({
    URL: SUPPORT_URL,
    platform: 'hydroPro',
    currentUser: currentUser,
    handleNewUser: (data) => store.dispatch(updateUser({ data, isCurrent: true }))
  })

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ThemeProvider theme={theme}>
          <Routes socket={socket} />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  )
}

export default App
