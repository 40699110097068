import React from 'react'
import './styles.scss'

const CustomRadio = ({onClick, selected}) => {
  return (
    <div className="custom-radio" onClick={onClick} >
        <div className="radio-dot" style={{backgroundColor: `${selected ? "#25e083" : "transparent"}`}} />
    </div>
  )
}

export default CustomRadio