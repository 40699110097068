import { StorageTypes } from "../actions"

const initialState = {
  requests: [],
  providers: [],
  search: [],
  availableProducts: [],
  storageProductResults: [],
  HPRequests: [],
  found: [],
  machineElements: [],
}

const editRequest = (state, payload) => {
  const requests = state.requests.slice()
  const index = requests.findIndex((r) => r._id === payload._id)
  requests.splice(index, 1, payload)
  return requests
}

const editRequests = (state, payload) => {
  const requests = state.requests.slice()
  payload.forEach((item, i) => {
    const index = requests.findIndex((r) => r._id === item._id)
    requests.splice(index, 1, item)
  })
  return requests
}

const updateProducts = (state, payload) => {
  let newProducts = state.availableProducts.slice(0)
  const index = newProducts.findIndex((el) => el._id === payload._id)
  newProducts[index] = payload
  return newProducts
}

const addProduct = (state, payload) => {
  let newProducts = state.availableProducts.slice(0)
  newProducts.push(payload)
  return newProducts
}

const updateMaterial = (state, payload) => {
  let newRequests = state.requests.slice(0)
  let index = newRequests.findIndex((el) => el._id === payload.to)
  payload.from.forEach((el) => {
    newRequests.forEach((req) => {
      if (req._id === el) {
        newRequests[index].price = newRequests[index].price + req.price
        newRequests[index].quantity = newRequests[index].quantity + req.quantity
      }
    })
  })
  payload.from.forEach((el) => {
    let index = newRequests.findIndex((req) => req._id === el)
    newRequests.splice(index, 1)
  })
  return newRequests
}

const storage = (state = initialState, { type, payload }) => {
  switch (type) {
    case StorageTypes.GET_REQUESTS_SUCCESS:
      return { ...state, requests: payload }
    case StorageTypes.CREATE_REQUEST_SUCCESS:
      return { ...state, requests: [payload, ...state.requests] }
    case StorageTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return { ...state, requests: editRequest(state, payload) }
    case StorageTypes.EXPORT_REQUEST_SUCCESS:
      return { ...state, requests: editRequests(state, payload) }
    case StorageTypes.SEARCH_PROVIDER_SUCCESS:
      return { ...state, providers: payload }
    case StorageTypes.SEARCH_SUCCESS:
      return { ...state, search: payload }
    case StorageTypes.GET_AVAILABLE_PRODUCTS_SUCCESS:
      return { ...state, availableProducts: payload }
    case StorageTypes.SEARCH_STORAGE_PRODUCTS_SUCCESS:
      return { ...state, storageProductResults: payload }
    case StorageTypes.RESET_STORAGE_RESULTS:
      return { ...state, storageProductResults: [], search: [] }
    case StorageTypes.UPDATE_PRODUCTS:
      return { ...state, availableProducts: updateProducts(state, payload) }
    case StorageTypes.CREATE_STORAGE_PRODUCT_SUCCESS:
      return { ...state, availableProducts: addProduct(state, payload) }
    case StorageTypes.UPDATE_MATERIAL:
      return { ...state, requests: updateMaterial(state, payload) }
    case StorageTypes.FILTER_HP_ORDERS_SUCCESS:
      return { ...state, found: payload.requests }
    case StorageTypes.GET_HP_REQUESTS_SUCCESS:
      return { ...state, HPRequests: payload }
    default:
      return state
  }
}

export default storage
