import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { hideModal, addProductsToInquiry } from "../../../actions"
import "./styles.scss"
import moment from "moment"

const ProductOrderModal = ({ hideModal, data, addProducts }) => {
  const [dueDate, setDueDate] = useState("")
  const [quantity, setQuantity] = useState("")
  const [details, setDetails] = useState("")
  let name
  if (data.product.productType.name === "Тръба") {
    const SN = data.product.basicInfo.find((info) => info.name === "Коравина")
    data.product.basicInfo.forEach((el) => {
      if (el.name === "Произвежда се по") {
        if (el.value === "Вътрешен диаметър") {
          const ID = data.product.basicInfo.find(
            (info) => info.name === "Вътрешен диаметър"
          )
          name = `SN${SN?.value}ID${ID?.value}`
        } else {
          const OD = data.product.basicInfo.find(
            (info) => info.name === "Външен диаметър"
          )
          name = `SN${SN?.value}OD${OD?.value}`
        }
      }
    })
  } else {
    name = data.product.productType.name
  }
  return (
    <div className="product-order-container">
      <div className="top-container">
        <h3>Заявка към производство</h3>
        <div className="close-btn" onClick={hideModal} />
      </div>
      <div className="product-container">
        <div className="product-name">{name}</div>
        <div className="input-element border">
          <p>К-во</p>
          <Input.Text onChange={(event) => setQuantity(event.target.value)} />
        </div>
        <div className="input-element">
          <p>Производство до</p>
          <Input.Datepicker
            placeholder="Дата"
            pickDate={moment().toDate()}
            onChange={(date) => setDueDate(date)}
          />
        </div>
      </div>
      <p id="border">Коментар</p>
      <Input.Textarea onChange={(value) => setDetails(value)} />
      <Button.Raised
        text="Изпратете заявка"
        className="green-button"
        onClick={() => {
          addProducts({
            products: [
              { ...data.product, amount: quantity, deliveryDate: dueDate },
            ],
            _id: data.inquiry,
            details: details,
          })
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  addProducts: (payload) => dispatch(addProductsToInquiry(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrderModal)
