import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import { Alerts } from "@makedonski/socourt-utilities"
import { orderRequest, searchProvider, hideModal } from "../../../actions"
import { Inputs, SupplierAutocomplete } from "../../../components"
import Files from "../../../utilities/files"

import "./styles.scss"

const AddStorageRawMaterialsOrder = ({
  selectedRequest,
  orderRequest,
  hideModal,
  searchProvider,
  providers,
}) => {
  const [payload, setPayload] = useState({
    ids: selectedRequest.map((r) => r._id),
    deliveryDate: new Date(selectedRequest[0].deliveryDate),
    quantity: selectedRequest[0].quantity,
    price: "",
    provider: {
      uic: "",
      mol: "",
      firmName: "",
      address: "",
    },
    payments: [
      {
        dueDate: new Date(),
        percent: 100,
      },
    ],
    invoice: "",
    files: [],
    comment: "",
  })

  useEffect(() => {
    searchProvider()
    //eslint-disable-next-line
  }, [searchProvider])

  return (
    <div className="add-storage-raw-materials-order-container">
      <div className="modal-heading">
        <h3>Нова поръчка</h3>
        <div className="exit-btn">
          <Button.Icon
            name="plus"
            color="#152331"
            onClick={hideModal}
            size={24}
          />
        </div>
      </div>
      <div className="modal-content">
        <div className="order-header">
          <div className="item">
            <span>Суровина</span>
            <Input.Text disabled value={selectedRequest[0].material} />
          </div>
          <div className="item">
            <span>Количество</span>
            <Inputs.UnitDropdown
              textValue={payload.quantity}
              onTextChange={(quantity) => setPayload({ ...payload, quantity })}
              unitsValue={selectedRequest[0].units}
              onUnitsChange={() => {}}
              unitsDisabled
            />
          </div>
          <div className="item">
            <span>Срок за доставка</span>
            <Input.Datepicker
              pickDate={payload.deliveryDate}
              onChange={(deliveryDate) =>
                setPayload({ ...payload, deliveryDate })
              }
            />
          </div>
          <div className="item">
            <span>Цена за единица</span>
            <Input.Text
              value={payload.price}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  price: e.target.value.replace(/,/g, "."),
                })
              }}
            />
          </div>
        </div>
        <div className="details">
          <span>Данни за доставчик и плащане</span>
          <div className="row">
            <div className="item">
              <span>Име на фирмата</span>
              <SupplierAutocomplete
                onChooseClient={(data) => {
                  setPayload({
                    ...payload,
                    provider: {
                      ...payload.provider,
                      firmName: data.firmName,
                      mol: data.mol,
                      uic: data.uic,
                      address: data.address,
                    },
                  })
                }}
                onChange={(event) => {
                  setPayload({
                    ...payload,
                    provider: {
                      ...payload.provider,
                      firmName: event.target.value,
                    },
                  })
                }}
              />
            </div>
            <div className="item">
              <span>ЕИК (БУЛСТАТ):</span>
              <Input.Text
                value={payload.provider.uic}
                onChange={(event) => {
                  setPayload({
                    ...payload,
                    provider: {
                      ...payload.provider,
                      uic: event.target.value,
                    },
                  })
                }}
              />
            </div>
            <div className="item">
              <span>МОЛ</span>
              <Input.Text
                value={payload.provider.mol}
                onChange={(event) => {
                  setPayload({
                    ...payload,
                    provider: {
                      ...payload.provider,
                      mol: event.target.value,
                    },
                  })
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="item address">
              <span>Адрес</span>
              <Input.Text
                value={payload.provider.address}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    provider: {
                      ...payload.provider,
                      address: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="row row-buttons">
            {/* <div className="item">
              <span>Начин на плащане</span>
              <div className="row buttons-payment">
                <Button.Tab
                  buttons={["Цялата сума", "Разсрочено плащане"]}
                  onClick={(value) => {
                    if (value === "Цялата сума")
                      setPayload({
                        ...payload,
                        payments: [
                          {
                            dueDate: new Date(),
                            percent: 100,
                          },
                        ],
                      })
                    else
                      setPayload({
                        ...payload,
                        payments: [
                          {
                            dueDate: new Date(),
                            percent: 50,
                          },
                          {
                            dueDate: new Date(),
                            percent: 50,
                          },
                        ],
                      })
                  }}
                />
                {!!payload.payments.length && (
                  <>
                    <span>Вноски: {payload.payments.length}</span>
                    <Section.Popup
                      ref={(ref) => {
                        payments = ref
                      }}
                      className="profile-status"
                      content={
                        <StorageRawMaterialsOrderPayment
                          currentPayments={payload.payments}
                          onClick={(payments) =>
                            setPayload({ ...payload, payments })
                          }
                          onClose={() => payments.hide()}
                        />
                      }
                    >
                      <Button.Icon
                        name="eye"
                        size={16}
                        color={"#26ACE2"}
                        onClick={() => payments.show()}
                      />
                    </Section.Popup>
                  </>
                )}
              </div>
            </div> */}
            <div className="item item-files">
              <span>Добави файл:</span>
              <div className="row buttons-files">
                <Button.UploadButton
                  text={
                    payload.invoice ? (
                      <>
                        <Button.Icon name="chat---add-file" color="#52ABDD" />
                        <span>{payload.invoice.name}</span>
                      </>
                    ) : (
                      <>
                        <span>Фактура</span>
                        <Button.Icon name="chat---add-file" />
                      </>
                    )
                  }
                  accept="*"
                  onChange={(event) => {
                    const file = event.target.files[0]
                    Files.uploadFile(file).then((data) => {
                      setPayload({
                        ...payload,
                        invoice: { name: file.name, url: data.location },
                      })
                    })
                  }}
                />
                {payload.invoice && (
                  <div
                    className="remove-btn"
                    onClick={() => {
                      setPayload({ ...payload, invoice: "" })
                    }}
                  />
                )}

                <Button.UploadButton
                  className="border-left"
                  text={
                    <>
                      <span>Друг файл</span>
                      <Button.Icon name="chat---add-file" />
                    </>
                  }
                  accept="*"
                  onChange={(event) => {
                    const file = event.target.files[0]
                    Files.uploadFile(file).then((data) => {
                      setPayload({
                        ...payload,
                        files: [
                          ...payload.files,
                          { name: file.name, url: data.location },
                        ],
                      })
                    })
                  }}
                />
                {payload.files.length > 0 && (
                  <div className="horizontal-scroll">
                    {payload.files.map((file) => (
                      <>
                        <div
                          className="remove-btn"
                          onClick={() => {
                            let newFiles = payload.files.slice(0)
                            let index = newFiles.findIndex(
                              (el) => el.name === file.name
                            )
                            newFiles.splice(index, 1)
                            setPayload({ ...payload, files: newFiles })
                          }}
                        />
                        <span>{file.name}</span>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="item comment">
              <span>Коментар</span>
              <Input.Text
                placeholder="Добавете коментар към поръчката"
                value={payload.comment}
                onChange={(e) =>
                  setPayload({ ...payload, comment: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <Button.Raised
          text="Поръчай"
          className="btn-create"
          onClick={() => {
            if (
              !payload.deliveryDate ||
              !payload.quantity ||
              !payload.price ||
              !payload.provider.uic ||
              !payload.provider.mol ||
              !payload.provider.firmName ||
              !payload.provider.address ||
              !payload.payments.length
            ) {
              Alerts.error("Моля попълнете всички данни!")
            } else {
              orderRequest(payload)
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  providers: state.storage.providers,
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  orderRequest: (payload) => dispatch(orderRequest(payload)),
  searchProvider: (payload) => dispatch(searchProvider(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStorageRawMaterialsOrder)
