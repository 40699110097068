import React from 'react'
import './WeeklyMonthly.scss'
import '../Daily/Daily.scss'
import { getProductName } from '../../../utilities/helpers'

export default function ScheduleOrderColumn ({ orderData, onClick }) {

  return (
    <div
      className={`single-order-wrapper ${(orderData?.request?.status === 'finished' || orderData?.state === 'finished'
      || orderData?.status === 'finished') ? 'finished' : ''}`}
      // style={{ borderLeft: (orderData?.isDelayed && (orderData?.state !== 'finished' || orderData?.request?.status !== 'finished')) ? '10px solid #FF7590' : '10px solid #25E083' }}
      style={{ borderLeft: (orderData?.isDelayed && orderData?.state !== 'finished') ? '10px solid #FF7590' : '10px solid #25E083' }}
       key={`single-weekly-order-container-${orderData?._id}`}
      onClick={() => onClick()}
    >
      {(orderData?.isDelayed || orderData?.comment) && 
      <div className='icons-wrapper'>
         {orderData?.isDelayed &&
         !(orderData?.request?.status === 'finished' || orderData?.state === 'finished' || orderData?.status === 'finished') &&
         <img src={require('../../../assets/icons/swap.svg')} className='icon revert' />}
         {orderData?.comment && 
         <>
          {(orderData?.request?.status === 'finished' || orderData?.state === 'finished' || orderData?.status === 'finished') ?
            <img src={require('../../../assets/icons/comment-white.svg')} className='icon comment' /> :
            <img src={require('../../../assets/icons/comment-black.svg')} className='icon comment' />}
          <p className={`${(orderData?.request?.status === 'finished' || orderData?.state === 'finished'
      || orderData?.status === 'finished') ? 'white' : ''}`}>1</p>
         </>}
      </div>}
      <h3 className='product-name'>{orderData?.product?.basicInfo && getProductName(orderData?.product?.basicInfo)}</h3>
      <h3 className='product-name'>{orderData?.request?.product?.basicInfo && getProductName(orderData?.request?.product?.basicInfo)}</h3>
      <h3 className='product-activity'>{orderData?.request?.inquiry?.object || ''}</h3>
      <h3 className='product-activity'>{orderData?.activity || ''}</h3>
    </div>
  )
}
