import React, { useState } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import "./styles.scss"

const paymentNumberMap = {
  0: "Първа вноска",
  1: "Втора вноска",
  2: "Трета вноска",
  3: "Четвърта вноска",
  4: "Пета вноска",
}

const PaymentPopup = ({ onSetPayments }) => {
  const [initialPayment, setInitalPayment] = useState("")
  const [payments, setPayments] = useState([
    {
      percent: "",
      date: "",
    },
    {
      percent: "",
      date: "",
    },
  ])
  return (
    <div className="payment-popup-container">
      {payments.map((payment, index) => {
        return (
          <>
            <p>{paymentNumberMap[index]}</p>
            {index === 0 && (
              <Button.Tab
                buttons={["Сега", "На определена дата"]}
                onClick={(name) => {
                  if (name === "Сега") {
                    const newPayments = payments.slice(0)
                    newPayments[index].date = new Date()
                    setPayments(newPayments)
                  }
                  setInitalPayment(name)
                }}
              />
            )}
            <div className="flex-container">
              <div className="input-wrapper">
                <Input.Text
                  value={payment.percent}
                  onChange={(event) => {
                    const newPayments = payments.slice(0)
                    newPayments[index].percent = event.target.value
                    setPayments(newPayments)
                  }}
                />
                <div className="percent">%</div>
              </div>
              <Input.Datepicker
                disabled={initialPayment === "Сега" && index === 0}
                placeholder="Дата"
                pickDate={payment.date}
                onChange={(date) => {
                  const newPayments = payments.slice(0)
                  newPayments[index].date = date
                  setPayments(newPayments)
                }}
              />
            </div>
          </>
        )
      })}
      <div className="flex-container space-between">
        <Button.Raised
          className="clear-button"
          text="Добави вноска"
          onClick={() => {
            if (payments.length < 5) {
              let newPayments = payments.slice(0)
              newPayments.push({
                percent: "",
                date: "",
              })
              setPayments(newPayments)
            }
          }}
        />
        <Button.Raised
          text="Потвърди"
          onClick={() => {
            onSetPayments(payments)
          }}
        />
      </div>
    </div>
  )
}

export default PaymentPopup
