import React from "react"
import "./styles.scss"

function SingleRadioButton({ label, selected, onClick, disabled }) {
  return (
    <div
      className={`radio-button-container ${disabled && "disabled"}`}
      onClick={() => onClick(label)}
    >
      <div className="radio-button-outer">
        <div className={`radio-button-inner ${selected && "selected"} `} />
      </div>
      <p id={`${selected && "bold"}`}>{label || ""}</p>
    </div>
  )
}

export default SingleRadioButton
