import React, { useEffect, useState, useRef } from "react"
import { TableHeaderInquiry } from "../TableHeaderInquiry"
import _ from "lodash"
import "./styles.scss"
import "../InquiriesClientsComponent/InquiriesHeader.scss"
import { Input } from "@makedonski/admin-ui"
import {
  Button as ButtonMUI,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import styles from "../Projects/Projects.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getProductsProduct, startLoading, stopLoading } from "../../actions"

const headerElements = [
  {
    name: "Продукт",
    size: 1.3,
    fixedWidth: 300,
    filter: false,
    sort: false,
  },
  {
    name: "Планиран край",
    size: 1,
    fixedWidth: 200,
    filter: false,
    sort: false,
  },
  {
    name: "Обект",
    size: 1,
    fixedWidth: 200,
    filter: false,
    sort: false,
  },
  {
    name: "Машина",
    size: 1,
    fixedWidth: 200,
    filter: false,
    sort: false,
  },
  {
    name: "Изпълнители",
    size: 6,
    fixedWidth: 400,
    filter: false,
    sort: false,
  },
]

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

export default function InquiriesProductionProductComponent() {
  const dispatch = useDispatch()
  const { productsProduct } = useSelector(({ inquiries }) => inquiries)
  const removeFilterIcon = require("../../assets/icons/remove-filter-icon.svg")
  const blueArrowDownIcon = require("../../assets/icons/blue-arrow-dropdown.svg")
  const productionRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchType, setSearchType] = useState("product")
  const [searchQuery, setSearchQuery] = useState("")
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "year").startOf("days")._d
  )
  const [endDate, setEndDate] = useState(moment().startOf("days")._d)
  const [currentPage, setCurrentPage] = useState(1)
  const [loadingMore, setLoadingMore] = useState(false)
  const open = Boolean(anchorEl)

  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = (page = 1) => {
    dispatch(startLoading())
    dispatch(
      getProductsProduct({
        pageNumber: page,
        pageSize: 10,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
        onSuccess: () => {
          dispatch(stopLoading())
          setLoadingMore(false)
        },
      })
    )
  }

  const handleScroll = () => {
    if (
      productionRef.current &&
      productionRef.current.scrollTop + productionRef.current.clientHeight >=
        productionRef.current.scrollHeight &&
      productsProduct?.hasNextPage &&
      !loadingMore
    ) {
      setLoadingMore(true)
      const nextPage = productsProduct?.nextPage || currentPage + 1
      fetchProducts(nextPage)
      setCurrentPage(nextPage)
    }
  }

  useEffect(() => {
    const ref = productionRef.current
    if (ref) {
      ref.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleScroll)
      }
    }
  }, [productsProduct, loadingMore])

  const resetFilters = () => {
    setSearchType("product")
    setSearchQuery("")
    setStartDate(moment().subtract(1, "year")._d)
    setEndDate(moment()._d)
    setCurrentPage(1)
    fetchProducts(1)
  }

  const handleOpen = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleSearch = () => {
    if (["product", "object"].includes(searchType) && searchQuery.length > 1) {
      dispatch(
        getProductsProduct({
          pageNumber: 1,
          pageSize: 10,
          ...(searchType === "product"
            ? { product: searchQuery }
            : { object: searchQuery }),
          onSuccess: () => dispatch(stopLoading()),
        })
      )
    }
  }
  const handleFilter = () => {
    dispatch(
      getProductsProduct({
        pageNumber: 1,
        pageSize: 10,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
        onSuccess: () => dispatch(stopLoading()),
      })
    )
  }

  console.log(loadingMore, productsProduct)
  return (
    <div className="inquiries-clients-component products">
      <div style={{ display: "flex", alignItems: "center", height: "80px" }}>
        <div
          className="search-col"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="search-input-wrapper">
            <label
              htmlFor="client-name"
              style={{ fontSize: "12px", fontFamily: "regular", width: "100%" }}
            >
              Търсене
            </label>
            <Input.Text
              placeholder={"Търси"}
              id="client-name"
              value={searchQuery}
              style={{
                borderRight: "none",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div
              className="search-value-img-wrapper expense"
              onClick={handleOpen}
            ></div>
          </div>
          <div
            className="search-value-img-wrapper production"
            onClick={handleOpen}
          >
            <label
              htmlFor="client-name"
              style={{ fontSize: "12px", fontFamily: "regular", width: "100%" }}
            >
              По
            </label>
            <div
              className="flex-container"
              style={{
                margin: "10px 0px",
                padding: "8.6px 15px",
                alignItems: "center",
                fontSize: "13px",
                border: "1px solid #0034704d",
                borderRadius: "7px",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                cursor: "pointer",
              }}
            >
              {searchType === "product" ? "Име на продукт" : "Име на обект"}
              <img src={blueArrowDownIcon} style={{ marginLeft: "10px" }} />
            </div>
          </div>
          <Menu
            PaperProps={{
              style: { padding: "15px" },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div className={styles["filters-options"]}>
              <h5>Търсене по</h5>
              <CloseIcon
                fontSize="small"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
            <FormControl>
              <RadioGroup
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <FormControlLabel
                  value="product"
                  control={<Radio sx={radioStyles} />}
                  label="Име на продукт"
                />
                <FormControlLabel
                  value="object"
                  control={<Radio sx={radioStyles} />}
                  label="Име на обект"
                />
              </RadioGroup>
              <ButtonMUI
                sx={{
                  background: "#26ACE2",
                  "&:hover": {
                    background: "#26ACE2",
                  },
                }}
                onClick={handleClose}
                disableElevation
                disableRipple
                disableFocusRipple
                variant="contained"
                fullWidth
              >
                Задай
              </ButtonMUI>
            </FormControl>
          </Menu>
        </div>
        <ButtonMUI
          variant="contained"
          sx={{
            padding: "6px 25px",
            backgroundColor: "#E6EEF4",
            "&:hover": {
              background: "#E6EEF4",
            },
            border: "0.03px solid #005fa36e",
            boxShadow: "none",
            color: "black",
            height: "37.13px",
            textTransform: "none",
            marginTop: "20px",
            borderRadius: "7px",
            fontFamily: "regular",
            fontSize: "14px",
            marginLeft: "20px",
          }}
          onClick={handleSearch}
        >
          Търси
        </ButtonMUI>

        <div className="search-col" style={{ marginLeft: "60px" }}>
          <label
            htmlFor="client-name"
            style={{ fontSize: "12px", fontFamily: "regular", width: "100%" }}
          >
            Филтрация по период
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Input.Datepicker
              style={{ height: "37.13px" }}
              minDate={moment().subtract(50, "months").toDate()}
              pickDate={startDate}
              onChange={(date) => {
                console.log(date)
                setStartDate(date)
              }}
            />
            <p className="dash">-</p>
            <Input.Datepicker
              style={{ height: "37.13px" }}
              minDate={moment().subtract(50, "months").toDate()}
              pickDate={endDate}
              onChange={(date) => {
                console.log(date)
                setEndDate(date)
              }}
            />
          </div>
        </div>
        <ButtonMUI
          variant="contained"
          sx={{
            padding: "6px 25px",
            backgroundColor: "#E6EEF4",
            "&:hover": {
              background: "#E6EEF4",
            },
            border: "0.03px solid #005fa36e",
            boxShadow: "none",
            color: "black",
            height: "37.13px",
            textTransform: "none",
            marginTop: "20px",
            borderRadius: "7px",
            fontFamily: "regular",
            fontSize: "14px",
            marginLeft: "20px",
          }}
          onClick={handleFilter}
        >
          Търси
        </ButtonMUI>

        <ButtonMUI
          onClick={resetFilters}
          className="remove-filter-btn"
          style={{ marginLeft: "20px", marginTop: "20px" }}
        >
          <img src={removeFilterIcon} alt="remove filter" />
        </ButtonMUI>
      </div>
      <div className="sales-main-container"style={{height:"calc(100% - 70px)"}}>
        <TableHeaderInquiry
          elements={headerElements}
          justifyContent="flex-start"
          width="100%"
          maxWidth
          tab="productionProduct"
        />
        <div className="scroll-container"  ref={productionRef}>
          {productsProduct?.docs?.map((product) => (
            <div
              className="inquiry-client-element invoice production"
              style={{ height: "60px" }}
              key={product?._id}
            >
              <div className="element small" style={{ maxWidth: "300px" }}>
                {product?.product || "---"}
              </div>
              <div
                className="element small overflow"
                style={{ maxWidth: "200px" }}
              >
                {moment(product?.deadline).format("DD.MM.YYYY")}
              </div>
              <div className="element small" style={{ maxWidth: "200px" }}>
                {product?.object || "---"}
              </div>
              <div
                className="element small overflow"
                style={{ maxWidth: "200px" }}
              >
                {product?.machine || "---"}
              </div>
              <div
                className="element large last overflow"
                style={{ maxWidth: "400px" }}
              >
                {product?.performers?.join(", ")}
              </div>
            </div>
          ))}
          {loadingMore && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40px",
              }}
            >
              Моля изчакайте...
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
